import React from "react";

import { useAuth } from "App/components/Auth";
import { TopBanner } from "../TopBanner/TopBanner";
import styles from "./UserDeletionInProgressBanner.module.scss";

export const UserDeletionInProgressBanner = () => {
  const { user } = useAuth();
  if (!user?.requestedAccountDeletionAt) {
    return null;
  }
  return (
    <TopBanner className={styles["user-deletion-in-progress-banner"]}>
      <p>This account is being processed for deletion</p>
    </TopBanner>
  );
};
