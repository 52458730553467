/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router";

import { FileEntity, Track } from "@highnote/server/src/core/entities";
import {
  APP_FEATURES,
  AppFeaturesStatus,
} from "@highnote/server/src/core/features";
import { useGlobalSpaces } from "App/store/spaces/useGlobalSpaces";
import { TRACK_EDITOR_TAB, TrackEditor } from "./TrackEditor";
import { TrackContextProvider, useTrack } from "./useEntities/useTrack";
import { FileEditorProvider } from "./useFileEditor";

export type TrackVersionEditorType = "default" | "swap" | "pin";

interface openTrackEditorConfig {
  track: Track;
  initialTab?: TRACK_EDITOR_TAB;
}

type BaseTrackVersionEditorConfig = {
  track: Track;
};

type DefaultTrackVersionEditorConfig = BaseTrackVersionEditorConfig & {
  type: "default";
};

type PinTrackVersionEditorConfig = BaseTrackVersionEditorConfig & {
  type: "pin";
};

type SwapTrackVersionEditorConfig = BaseTrackVersionEditorConfig & {
  type: "swap";
  selectedVersionToSwap: FileEntity;
};

type TrackVersionEditorConfig =
  | DefaultTrackVersionEditorConfig
  | PinTrackVersionEditorConfig
  | SwapTrackVersionEditorConfig;

type TrackEditorContextType = {
  openTrackEditor: (config: openTrackEditorConfig) => void;
  closeTrackEditor: () => void;
  openTrackVersionEditor: (config: TrackVersionEditorConfig) => void;
  closeTrackVersionEditor: () => void;
  selectedVersionToSwap: FileEntity | undefined;
  setSelectedVersionToSwap: (selectedVersionToSwap: FileEntity) => void;
  isTrackVersionEditorOpen: boolean;
  trackVersionEditorType: TrackVersionEditorType;
};

const TrackEditorContext = createContext<TrackEditorContextType>({
  openTrackEditor: () => {},
  closeTrackEditor: () => {},
  openTrackVersionEditor: () => {},
  closeTrackVersionEditor: () => {},
  selectedVersionToSwap: undefined,
  setSelectedVersionToSwap: () => {},
  isTrackVersionEditorOpen: false,
  trackVersionEditorType: "default",
});

export const TrackEditorProvider = ({
  children,
  respectQueryParam,
}: {
  children: React.ReactNode;
  respectQueryParam?: boolean;
}) => {
  const { manageGlobalSingleSpaceWatcher, getGlobalSpace } = useGlobalSpaces();
  const history = useHistory();
  const { track: contextualTrack } = useTrack();
  const [isOpen, setOpen] = useState(false);
  const [isTrackVersionEditorOpen, setTrackVersionEditorOpen] = useState(false);
  const [initialTab, setInitialTab] = useState<TRACK_EDITOR_TAB>(
    TRACK_EDITOR_TAB.DETAILS,
  );
  const [track, setTrack] = useState<Track>(undefined);
  const [trackVersionEditorType, setTrackVersionEditorType] =
    useState<TrackVersionEditorType>("default");
  const [selectedVersionToSwap, setSelectedVersionToSwap] = useState<
    FileEntity | undefined
  >(undefined);

  const space = getGlobalSpace(track?.spaceId);

  useEffect(() => {
    if (track?.spaceId && !space) {
      manageGlobalSingleSpaceWatcher({
        action: "attach",
        componentId: "space-track-editor",
        spaceId: track.spaceId,
      });

      return () => {
        manageGlobalSingleSpaceWatcher({
          action: "detach",
          componentId: "space-track-editor",
          spaceId: track.spaceId,
        });
      };
    }
  }, [space, track?.spaceId]);

  const openTrackVersionEditor = useCallback(
    (config: TrackVersionEditorConfig) => {
      setTrack(config.track);
      if (AppFeaturesStatus[APP_FEATURES.TRACK_VERSION_EDITOR]) {
        if (config.type) {
          setTrackVersionEditorType(config.type);
        }
        if (config.type === "swap" && config.selectedVersionToSwap) {
          setSelectedVersionToSwap(config.selectedVersionToSwap);
        }
        setTrackVersionEditorOpen(true);
      } else {
        openTrackEditor({
          track: config.track,
          initialTab: TRACK_EDITOR_TAB.VERSIONS,
        });
      }
    },
    [],
  );

  const closeTrackVersionEditor = useCallback(() => {
    if (AppFeaturesStatus[APP_FEATURES.TRACK_VERSION_EDITOR]) {
      if (trackVersionEditorType) {
        setTrackVersionEditorType("default");
      }
      if (selectedVersionToSwap) {
        setSelectedVersionToSwap(undefined);
      }
      setTrackVersionEditorOpen(false);
    } else {
      setTrack(undefined);
      closeTrackEditor();
    }
  }, []);

  const openTrackEditor = useCallback(
    ({
      track,
      initialTab = TRACK_EDITOR_TAB.DETAILS,
    }: openTrackEditorConfig) => {
      setTrack(track);
      setInitialTab(initialTab);
      setOpen(true);
    },
    [],
  );

  const closeTrackEditor = useCallback(() => {
    setTrack(undefined);
    setOpen(false);
  }, []);

  const value = useMemo(
    () => ({
      openTrackEditor,
      closeTrackEditor,
      openTrackVersionEditor,
      closeTrackVersionEditor,
      selectedVersionToSwap,
      setSelectedVersionToSwap,
      isTrackVersionEditorOpen,
      trackVersionEditorType,
    }),
    [
      openTrackEditor,
      closeTrackEditor,
      openTrackVersionEditor,
      closeTrackVersionEditor,
      selectedVersionToSwap,
      isTrackVersionEditorOpen,
      trackVersionEditorType,
    ],
  );

  useEffect(() => {
    if (respectQueryParam) {
      const params = new URLSearchParams(location.search);
      const editorParam = params.get("track-editor") as TRACK_EDITOR_TAB;
      const isParamValid =
        Object.values(TRACK_EDITOR_TAB).includes(editorParam);
      if (isParamValid && contextualTrack) {
        setTrack(contextualTrack);
        setInitialTab(editorParam);
        setOpen(true);
        params.delete("track-editor");
        history.replace({
          pathname: location.pathname,
          search: params.toString(),
        });
      }
    }
  }, [contextualTrack, history.location]);

  return (
    <TrackEditorContext.Provider value={value}>
      <FileEditorProvider>
        {isOpen &&
          (contextualTrack ? (
            <TrackEditor
              space={space}
              initialTab={initialTab}
              onClose={closeTrackEditor}
            />
          ) : (
            <TrackContextProvider id={track.id}>
              <TrackEditor
                space={space}
                initialTab={initialTab}
                onClose={closeTrackEditor}
              />
            </TrackContextProvider>
          ))}
        {children}
      </FileEditorProvider>
    </TrackEditorContext.Provider>
  );
};

export const useTrackEditor = () => useContext(TrackEditorContext);
