import React, { useMemo, useRef, useState } from "react";
import DraggableList from "react-draggable-list";
import classNames from "classnames";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ReactComponent as DragHandleSVG } from "App/common/icons-v2/move-2.svg";
import { DEFAULT_COLUMNS, EntityRowConfig } from "App/components/EntityTable";
import { useTheme } from "./ThemeProvider";
import { useViewport } from "./useViewport";
import { EntityIconExpand } from "App/components/EntityTable/EntityIconExpand";
import "./BaseTable.scss";
import { SORT_TYPE } from "App/routes/Main/Library/config";
import { useIsInView } from "App/components/util";
import { ENTITY_TYPE } from "App/components/EntityTable/config";
import { Checkbox } from "@mui/material";
import {
  APP_FEATURES,
  AppFeaturesStatus,
} from "@highnote/server/src/core/features";
import { Skeleton } from "./Skeleton/Skeleton";
import { COLLECTION_ID } from "@highnote/server/src/core/entities";

export function getEntityCollectionFromType(type: ENTITY_TYPE) {
  return type === ENTITY_TYPE.TRACK
    ? COLLECTION_ID.TRACK
    : type === ENTITY_TYPE.SPACE
      ? COLLECTION_ID.SPACE
      : COLLECTION_ID.FILE;
}

export type ColumnConfig = {
  name: string;
  id: string;
  Component?: React.FC<{ row: RowConfig }>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RowConfig = Record<string, any> & {
  key?: string;
  attributes?: Record<string, string>;
  selected?: boolean;
  // New optional properties for nested row handling
  NestedTable?: React.FC<{
    parentRow: EntityRowConfig;
    tableRowProps: Omit<BaseTableRowProps, "row">;
    sortType?: SORT_TYPE;
  }>;
};

export type TableRowVariant =
  | "no-nesting"
  | "expanded-row"
  | "draggable-expanded-row"
  | "nested-click-thru";

type DraggableRowItem = {
  row: RowConfig;
  columns: ColumnConfig[];
  isLastRow?: boolean;
  onSelect?: (row: RowConfig) => void;
  tableRowVariant?: TableRowVariant;
};

const DefaultColumnComponent = ({ row }: { row: RowConfig }) => {
  return <div>{JSON.stringify(row)}</div>;
};

// 64px white space: Expand button 34px, Column padding 2px * 2 = 4px, 16px for padding between columns
export const EXPAND_COLUMN_WIDTH = 34;
// 50px white space: Expand button 30px, Column padding 2px * 2 = 4px, 16px for padding between columns
export const EXPAND_COLUMN_WIDTH_MOBILE = 30;
const DEFAULT_INDENTATION = 16;
const DEFAULT_INDENTATION_MOBILE = 12;
export const calculateChildIndentation = (
  indentation: number,
  isMobile: boolean,
) => {
  return (
    indentation +
    (isMobile ? DEFAULT_INDENTATION_MOBILE : DEFAULT_INDENTATION) * 2
  );
};
// TODO: Used as a simple flag to keep the nested row logic for mobile, but disable it for now.
const SHOW_MOBILE_EXPANDED_TRACK_ROWS = false;

export interface BaseTableRowProps {
  row: RowConfig;
  parentRow?: RowConfig;
  columns: ColumnConfig[];
  onSelect?: (row: RowConfig, parentEntity?: RowConfig) => void;
  dragHandleProps?: object;
  isLastRow?: boolean;
  indentation?: number;
  tableRowVariant?: TableRowVariant;
}

export const BaseRow = ({
  row,
  parentRow,
  columns = [],
  dragHandleProps,
  isLastRow,
  onSelect,
  indentation = 0,
  tableRowVariant,
}: BaseTableRowProps) => {
  const { isMobile } = useViewport();
  const defaultColumnWidth = useMemo(
    () => (isMobile ? EXPAND_COLUMN_WIDTH_MOBILE : EXPAND_COLUMN_WIDTH),
    [isMobile],
  );

  const [isRowExpanded, setIsRowExpanded] = useState(false);
  const hasNestedRows =
    row?.attributes?.["data-is-nested"] === "true" || row.NestedTable;
  const isExpandableRow =
    tableRowVariant === "expanded-row" ||
    tableRowVariant === "draggable-expanded-row";

  const expandedColumn = {
    name: "Expand",
    id: "expand",
    Component: ({ row }: { row: EntityRowConfig }) => {
      if (isExpandableRow) {
        // TODO: For now, we are not showing the expanded rows on mobile for tracks/versions.
        if (
          isMobile &&
          !SHOW_MOBILE_EXPANDED_TRACK_ROWS &&
          (row.type === ENTITY_TYPE.TRACK ||
            row.type === ENTITY_TYPE.TRACK_VERSION)
        )
          return null;

        if (hasNestedRows) {
          return (
            <div style={{ paddingLeft: isMobile ? 0 : indentation }}>
              <EntityIconExpand
                row={row}
                isExpanded={isRowExpanded}
                toggleExpanded={toggleExpandRow}
              />
            </div>
          );
        }

        if (!isMobile) {
          return (
            <div
              className="entity-icon-expand"
              style={{
                // To keep the table layout consistent, return an
                // empty div the size of the EntityIconExpand button,
                // if the row is not expandable.
                width: defaultColumnWidth,
                height: 30,
                paddingLeft: indentation + defaultColumnWidth,
              }}
            />
          );
        }
      }

      return null;
    },
  };

  const expandedMarginColumn = {
    name: "Expand Margin",
    id: "expand-margin",
    Component: () => {
      return (
        <div
          className="entity-icon-expand"
          style={{
            // To keep the table layout consistent, return an
            // empty div the size of the EntityIconExpand button,
            // if the row is not expandable.
            width: indentation,
            height: 30,
            paddingLeft: indentation,
          }}
        />
      );
    },
  };

  const toggleExpandRow = () => {
    setIsRowExpanded((prev) => {
      return !prev;
    });
  };

  const selectColumn = {
    name: "Check",
    id: "check",
    Component: ({ row }: { row: EntityRowConfig }) => {
      return (
        <Checkbox
          disableRipple
          checked={!!row.selected}
          className="selection-checkbox"
          onClick={() => {
            onSelect(row, parentRow);
          }}
        />
      );
    },
  };

  const renderColumns = useMemo(() => {
    let allColumns = [...columns];

    if (isMobile) {
      // Check if the "actions" column exists
      const actionsColumnIndex = columns.findIndex(
        (column) => column.id === "actions",
      );

      if (actionsColumnIndex >= 0) {
        allColumns.splice(actionsColumnIndex, 0, expandedColumn);
      } else {
        allColumns.push(expandedColumn);
      }

      allColumns = dragHandleProps
        ? [
            {
              name: "Drag To Reorder",
              id: "drag-handle",
              Component: () => (
                <div
                  className="drag-handle"
                  data-is-draggable={!!dragHandleProps}
                  {...dragHandleProps}
                >
                  <DragHandleSVG />
                </div>
              ),
            },
            expandedMarginColumn,
            ...allColumns,
          ]
        : tableRowVariant === "draggable-expanded-row"
          ? [
              {
                name: "Drag To Reorder",
                id: "drag-handle",
                Component: () => {
                  // Return an empty div the size of the drag handle
                  // to keep the table layout consistent.
                  return <div className="drag-handle" />;
                },
              },
              expandedMarginColumn,
              ...allColumns,
            ]
          : [expandedMarginColumn, ...allColumns];
    } else {
      allColumns = dragHandleProps
        ? [
            {
              name: "Drag To Reorder",
              id: "drag-handle",
              Component: () => (
                <div
                  className="drag-handle"
                  data-is-draggable={!!dragHandleProps}
                  {...dragHandleProps}
                >
                  <DragHandleSVG />
                </div>
              ),
            },
            expandedColumn,
            ...columns,
          ]
        : tableRowVariant === "draggable-expanded-row"
          ? [
              {
                name: "Drag To Reorder",
                id: "drag-handle",
                Component: () => {
                  // Return an empty div the size of the drag handle
                  // to keep the table layout consistent.
                  return <div className="drag-handle" />;
                },
              },
              expandedColumn,
              ...columns,
            ]
          : [expandedColumn, ...columns];

      if (AppFeaturesStatus[APP_FEATURES.ENTITIES_SELECTION] && !!onSelect) {
        // placing checkbox as the second item in table cells sequence
        // e.g. [drag-handle, checkbox, epanded-trigger...]
        allColumns.splice(1, 0, selectColumn);
      }
    }

    return (
      <>
        {allColumns.map((column) => {
          const Cmp = column.Component || DefaultColumnComponent;

          return (
            <TableCell
              component={"div"}
              scope="row"
              data-column-id={column.id}
              key={column.id}
            >
              <Cmp row={row} />
            </TableCell>
          );
        })}
      </>
    );
  }, [columns, row, dragHandleProps, isRowExpanded]);

  const renderNestedRows = () => {
    if (isRowExpanded && row.NestedTable) {
      return (
        <row.NestedTable
          parentRow={row as EntityRowConfig}
          tableRowProps={{
            columns,
            indentation: calculateChildIndentation(indentation, isMobile),
            tableRowVariant,
            onSelect,
          }}
        />
      );
    }
    return null;
  };

  const isHoverable =
    !!row.onClick ||
    !!columns.find(
      (c) =>
        c === DEFAULT_COLUMNS.ACTIONS ||
        c === DEFAULT_COLUMNS.SHORTCUT ||
        c === DEFAULT_COLUMNS.COMMENTS,
    );

  const rowRef = useRef<HTMLDivElement>(null);

  const isInView = useIsInView(rowRef);

  return (
    <div ref={rowRef} className="base-table-row-wrapper">
      <TableRow
        key={`${row.type}-${row.id}`}
        component={"div"}
        data-clickable={!!row.onClick}
        data-hoverable={isHoverable}
        aria-selected={!!row.selected}
        data-is-last-row={!!isLastRow}
        data-cypress-id={"base-table-row"}
        data-cypress-row-type={row.type}
        data-id={row.id}
        className={classNames({
          ["draggable-expanded-row"]:
            tableRowVariant === "draggable-expanded-row",
        })}
        {...(row.attributes || {})}
      >
        {isInView ? renderColumns : null}
      </TableRow>
      {isExpandableRow && isInView && renderNestedRows()}
    </div>
  );
};

const DraggableRow = ({
  item,
  // ^ This is because of react-draggable-list's API design.
  // The property is called `item` from within their component.
  // So you have to pass everything though the `item` object.
  dragHandleProps,
  tableRowVariant,
}: {
  item: DraggableRowItem;
  dragHandleProps?: object;
  tableRowVariant?: TableRowVariant;
}) => {
  return (
    <BaseRow
      row={item.row}
      columns={item.columns}
      onSelect={item.onSelect}
      dragHandleProps={dragHandleProps}
      isLastRow={item.isLastRow}
      tableRowVariant={tableRowVariant || item.tableRowVariant}
    />
  );
};

export const BaseTable = ({
  className,
  columns,
  mobileColumns = columns,
  rows,
  EmptyComponent,
  onDragEnd,
  onSelect,
  showDragHandle,
  isSelectionEnabled,
  isLoading,
  tableRowVariant,
}: {
  className?: string;
  columns: ColumnConfig[];
  mobileColumns?: ColumnConfig[];
  rows: RowConfig[];
  EmptyComponent?: React.FC;
  onDragEnd?: (list: RowConfig[]) => void;
  onSelect?: (row: RowConfig) => void;
  isSelectionEnabled?: boolean;
  showDragHandle?: boolean;
  isLoading?: boolean;
  tableRowVariant?: TableRowVariant;
}) => {
  const { theme } = useTheme();
  const { vw } = useViewport();
  const isMobile = useMemo(() => vw <= 450, [vw]);

  const columnsToUse = useMemo(
    () => (isMobile ? mobileColumns : columns),
    [isMobile, columns, mobileColumns],
  );

  if (isLoading) {
    return (
      <div
        className={classNames(
          "highnote-base-table",
          "highnote-base-table-loading-container",
          className,
        )}
      >
        {Array.from({ length: 10 }, (_, i) => {
          return (
            <Skeleton
              key={`highnote-base-table-loading-row-${i}`}
              height={40}
            />
          );
        })}
      </div>
    );
  }

  if (rows.length === 0) {
    if (EmptyComponent)
      return (
        <div className={`highnote-base-table ${className || ""}`}>
          <EmptyComponent />
        </div>
      );

    return null;
  }

  return (
    <TableContainer
      component={Paper}
      data-theme={theme}
      data-is-selection-enabled={!!isSelectionEnabled}
      className={`highnote-base-table ${className || ""}`}
    >
      <Table
        component="div"
        sx={{ minWidth: 650 }}
        aria-label="table"
        stickyHeader={true}
      >
        <TableHead component="div">
          <TableRow component="div">
            {columnsToUse.map((column) => (
              <TableCell
                component="div"
                key={`column-${column.id}`}
                data-column-id={column.id}
              >
                {column.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        {onDragEnd ? (
          // See https://github.com/StreakYC/react-draggable-list#readme
          <DraggableList
            itemKey={(item: DraggableRowItem) => item.row.id}
            list={rows.map(
              (row, i) =>
                ({
                  row,
                  columns: columnsToUse,
                  onSelect,
                  isLastRow: i === rows.length - 1,
                  tableRowVariant,
                }) as DraggableRowItem,
            )}
            // @ts-expect-error; doesn't support React FC
            template={DraggableRow}
            padding={0}
            onMoveEnd={(items: DraggableRowItem[]) =>
              onDragEnd(items.map((i) => i.row))
            }
          />
        ) : (
          <TableBody component="div">
            {rows.map((row, i) => {
              const isLastRow = i === rows.length - 1;

              if (showDragHandle) {
                return (
                  <DraggableRow
                    key={row.key}
                    item={{ row, columns: columnsToUse, isLastRow, onSelect }}
                    tableRowVariant={tableRowVariant}
                  />
                );
              }

              return (
                <BaseRow
                  key={row.key}
                  row={row}
                  onSelect={onSelect}
                  columns={columnsToUse}
                  isLastRow={isLastRow}
                  tableRowVariant={tableRowVariant}
                />
              );
            })}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};
