import "./Breadcrumbs.scss";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import { FileEntity, Space, Track } from "@highnote/server/src/core/entities";
import { Button, BUTTON_THEME } from "App/core/Button";
import { useAuth } from "App/components/Auth";
import { useGlobalSpaces } from "App/store/spaces/useGlobalSpaces";

type Props = {
  activeSpace?: Space;
  activeTrack?: Track;
  activeTrackVersion?: FileEntity;
  onEntityClick?: (space?: Space) => void;
  hideLibrary?: boolean;
};

export const Breadcrumbs = ({
  activeSpace,
  activeTrack,
  activeTrackVersion,
  onEntityClick,
  hideLibrary,
}: Props) => {
  const history = useHistory();
  const { user } = useAuth();
  const isLoggedIn = !!user;
  const {
    manageGlobalSpaceWatchers,
    globalSpacesWatchers,
    globalSpaces,
    getGlobalSpace,
  } = useGlobalSpaces();
  const [fullPathIds, setFullPathIds] = useState<string[]>([]);

  useEffect(() => {
    let isMounted = true;
    const watchSpaces: string[] = []; // This will store spaceIds for which watchers are attached

    // This solution recursively fetches the parent spaces of the activeSpace
    // to be able to display the full path in the breadcrumbs as well as
    // stay synced to any realtime updates to the spaces.
    // TODO(Optimization): Look into an alternative solution that maybe renders a component recursively instead?
    // https://www.notion.so/highnotefm/Breadcrumbs-Optimization-Exploration-Recursive-rendering-of-parents-7d4b81a20a634635b6a23ea044ee91d3?pvs=4
    const getFullPath = async (_activeSpace?: Space) => {
      const findPath = async (
        currentSpace?: Space,
        path: Space[] = [],
      ): Promise<Space[]> => {
        if (!currentSpace?.spaceId) {
          return path;
        }

        let parentSpace = getGlobalSpace(currentSpace.spaceId);

        if (!parentSpace && !globalSpacesWatchers.has(currentSpace.spaceId)) {
          try {
            // Await for the space fetch and ensure the component is still mounted before proceeding
            await new Promise<void>((resolve) => {
              manageGlobalSpaceWatchers({
                action: "attach",
                spaceId: currentSpace.spaceId,
                onSpaceAdd: (space) => {
                  if (isMounted) {
                    parentSpace = space;
                    resolve();
                  }
                },
              });
              watchSpaces.push(currentSpace.spaceId); // Store spaceId for later cleanup
            });
          } catch (error) {
            console.error("Error fetching space:", error);
            return path; // Return current path and stop further recursion
          }
        }

        if (parentSpace) {
          return findPath(parentSpace, [parentSpace, ...path]);
        }

        return path;
      };

      return findPath(_activeSpace);
    };

    getFullPath(activeSpace)
      .then((path) => {
        if (isMounted) {
          const pathIds = path.map((space) => space.id);
          setFullPathIds(pathIds);
        }
      })
      .catch((e) => {
        console.error("Error getting breadcrumb path:", e);
        if (isMounted) {
          setFullPathIds([]); // Reset the path to empty array in case of error
        }
      });

    return () => {
      isMounted = false; // Set the flag to false when the component unmounts
      // Detach all watchers on component unmount
      watchSpaces.forEach((spaceId) => {
        manageGlobalSpaceWatchers({
          action: "detach",
          spaceId: spaceId,
        });
      });
    };
  }, [activeSpace, hideLibrary, globalSpaces.spaces]);

  if (!isLoggedIn) {
    return null;
  }

  return (
    <div
      className="highnote-breadcrumbs"
      data-cypress-id="highnote-breadcrumbs"
    >
      <MuiBreadcrumbs
        separator={<div className="divider">/</div>}
        aria-label="breadcrumb"
        // TODO: Uncomment these props when we have a design for the collapsed breadcrumbs
        // maxItems={4}
        // itemsBeforeCollapse={2}
        // itemsAfterCollapse={1}
      >
        {!hideLibrary && (
          <Button
            theme={BUTTON_THEME.PLAIN}
            onClick={() => {
              onEntityClick
                ? onEntityClick(undefined)
                : history.push(`/library`);
            }}
          >
            Library
          </Button>
        )}

        {fullPathIds.map((entityId) => {
          const entity = getGlobalSpace(entityId);
          const isClickable = activeSpace && entity.id !== activeSpace.id;
          const defaultClickPath = `/space/${entity.id}`;

          return (
            <Button
              key={entity.id}
              theme={BUTTON_THEME.PLAIN}
              disabled={!isClickable}
              onClick={() => {
                if (!isClickable) return;
                onEntityClick
                  ? onEntityClick(entity)
                  : history.push(defaultClickPath);
              }}
            >
              {entity.name}
            </Button>
          );
        })}

        {activeSpace && (
          <Button
            theme={BUTTON_THEME.PLAIN}
            disabled={!activeTrack}
            onClick={() => {
              if (!activeTrack) return;
              history.push(`/space/${activeSpace.id}`);
            }}
          >
            {activeSpace.name}
          </Button>
        )}

        {activeTrack && (
          <Button
            theme={BUTTON_THEME.PLAIN}
            disabled={!activeTrackVersion}
            onClick={() => {
              if (!activeTrackVersion) return;
              history.push(`/space/${activeTrack.spaceId}/${activeTrack.id}`);
            }}
          >
            {activeTrack.title}
          </Button>
        )}

        {activeTrackVersion && (
          <Button theme={BUTTON_THEME.PLAIN} disabled>
            {activeTrackVersion.name}
          </Button>
        )}
      </MuiBreadcrumbs>
    </div>
  );
};
