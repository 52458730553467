import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { highnote } from "@highnote/server/src/sdk";
import {
  Comment,
  CommentCore,
  KNOCK_WORKFLOW,
} from "@highnote/server/src/core/entities";
import { useNotificationsContext } from "./useNotifications";
import { getAuthId } from "App/components/Auth";

export type StatefulComment = Comment & {
  updateError?: string;
  isOptimistic?: boolean;
  isSaving?: boolean;
  isDeleted?: boolean;
};

type SpaceCommentsContextValue = {
  comments: StatefulComment[];
  addSpaceComment: (props: { id: Id; data: CommentCore }) => void;
  updateSpaceComment: (props: { id: Id; data: Partial<CommentCore> }) => void;
  deleteSpaceComment: (id: Id) => void;
  commentsLoading: boolean;
  filteredComments: StatefulComment[];
  getCommentById: (id: Id) => StatefulComment;
  fetchOlderComments: () => void;
  olderCommentsLoading: boolean;
  allCommentsFetched: boolean;
  filters: CommentsFilterFn[];
  setFilters: (filters: CommentsFilterFn[]) => void;
};

const SpaceCommentsContext = createContext<SpaceCommentsContextValue>({
  comments: [],
  addSpaceComment: () => {},
  deleteSpaceComment: () => {},
  updateSpaceComment: () => {},
  commentsLoading: true,
  filteredComments: [],
  getCommentById: () => undefined,
  fetchOlderComments: () => {},
  olderCommentsLoading: false,
  allCommentsFetched: false,
  filters: [],
  setFilters: () => {},
});

export type CommentsFilterFn = (c: Comment) => boolean;

export const SpaceCommentsContextProvider = ({
  spaceId,
  trackId,
  children,
}: {
  spaceId: Id;
  trackId?: Id | null;
  children: React.ReactNode;
}) => {
  const [commentsLoading, setCommentsLoading] = useState<boolean>(true);
  const [olderCommentsLoading, setOlderCommentsLoading] =
    useState<boolean>(false);
  const [comments, setComments] = useState<StatefulComment[]>([]);
  const [filteredComments, setFilteredComments] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { notifications, markAsRead } = useNotificationsContext();
  const [allCommentsFetched, setAllCommentsFetched] = useState<boolean>(false);
  const [filters, setFilters] = useState<CommentsFilterFn[]>([]);
  const commentsCache = useRef<Record<Id, StatefulComment>>({});
  const unmountedRef = useRef<boolean>(false);

  useEffect(
    () => () => {
      unmountedRef.current = true;
    },
    [],
  );

  const updateCommentsFromCache = useCallback(() => {
    if (unmountedRef.current) return;
    const allCommentsById: Record<Id, StatefulComment> = {
      ...commentsCache.current,
    };
    const newComments = Object.values(commentsCache.current);
    newComments.forEach((comment) => {
      if (!comment.parentComment) return;
      const parent = allCommentsById[comment.parentComment];
      if (!parent) return;
      if (parent.replies?.includes(comment.id)) return;
      parent.replies = [...(parent.replies || []), comment.id];
    });

    setComments(
      Object.values(allCommentsById)
        .map((c) => ({ ...c }))
        .filter((c) => !c.isDeleted || !!c.isSaving),
    );
  }, []);

  useEffect(() => {
    if (filters.length < 1) {
      setFilteredComments(comments);
      return;
    }

    let lastFiltered = comments;
    filters.forEach((fn) => {
      lastFiltered = lastFiltered.filter(fn);
    });

    setFilteredComments(lastFiltered);
  }, [comments, filters]);

  useEffect(() => {
    let unmounted: boolean;
    commentsCache.current = {};
    updateCommentsFromCache();

    if (!spaceId || typeof trackId === "undefined") {
      setCommentsLoading(false);
      setFilters([]);
      setAllCommentsFetched(false);
      return;
    }

    setCommentsLoading(true);
    const unsubscribeComments = highnote.watchComments({
      spaceId,
      trackId,
      onChange: (_comments: Comment[]) => {
        if (unmounted) return;
        _comments.forEach((comment) => {
          const cachedComment = commentsCache.current[comment.id];
          if (cachedComment?.isSaving) return;
          commentsCache.current[comment.id] = comment;
        });
        updateCommentsFromCache();
        setCommentsLoading(false);
        setAllCommentsFetched(false);
      },
    });

    return () => {
      unmounted = true;
      unsubscribeComments();
    };
  }, [spaceId, trackId]);

  const getCommentById: (id: Id) => Comment = useCallback(
    (id) => {
      const comment = comments.find((c) => c.id === id);
      const parentComment = comments.find(
        (c) => c.id === comment?.parentComment,
      );
      if (parentComment) {
        comment.isResolved = parentComment.isResolved;
      }
      return comment;
    },
    [comments],
  );

  useMemo(() => {
    return comments.filter((c) => {
      if (c.parentComment) return;
      const matchIds = [c.id, ...(c.replies || [])];
      return notifications.some(
        (n) =>
          n.source.key === KNOCK_WORKFLOW.CHAT_COMMENT_ADDED &&
          matchIds.includes(n.data.commentId) &&
          !n.read_at,
      );
    });
  }, [comments, notifications]);

  const fetchOlderComments = useCallback(async () => {
    setOlderCommentsLoading(true);
    const oldestComment = comments.sort((a, b) => a.createdAt - b.createdAt)[0];
    const olderComments = await highnote.getCommentsBefore({
      spaceId,
      trackId,
      createdAt: oldestComment?.createdAt || Date.now(),
    });

    const commentsById: Record<Id, Comment> = {};
    const allComments = [...olderComments, ...comments];
    allComments.forEach((c) => {
      commentsById[c.id] = c;
    });

    const dedupedComments = Object.values(commentsById);

    setOlderCommentsLoading(false);
    if (dedupedComments.length === comments.length) {
      setAllCommentsFetched(true);
      return;
    }
    setComments(dedupedComments);
  }, [comments]);

  const addSpaceComment = useCallback(
    async ({ id, data }: { id: Id; data: CommentCore }) => {
      commentsCache.current[id] = {
        id,
        createdAt: Date.now(),
        createdBy: getAuthId(),
        ...data,
        isOptimistic: true,
        isSaving: false,
        updateError: undefined,
      };
      updateCommentsFromCache();

      const showSaving = () => {
        commentsCache.current[id].isSaving = true;
        updateCommentsFromCache();
      };

      const timeoutId = setTimeout(showSaving, 200);

      try {
        await highnote.createComment({ id, data });
        clearTimeout(timeoutId);
        commentsCache.current[id].isSaving = false;
        commentsCache.current[id].isOptimistic = false;
        updateCommentsFromCache();
      } catch (e) {
        clearTimeout(timeoutId);
        commentsCache.current[id].updateError =
          "Could not save comment. Please try again.";
        console.log(e);
        updateCommentsFromCache();
        throw new Error(e.message);
      }
    },
    [comments],
  );

  const updateSpaceComment = useCallback(
    async ({ id, data }: { id: Id; data: Partial<CommentCore> }) => {
      if (!commentsCache.current[id]) {
        throw `Update failed: Comment does not exist at ${id}`;
      }

      commentsCache.current[id] = {
        ...commentsCache.current[id],
        ...data,
        isSaving: false,
        updateError: undefined,
      };
      updateCommentsFromCache();

      const showSaving = () => {
        commentsCache.current[id].isSaving = true;
        updateCommentsFromCache();
      };

      const timeoutId = setTimeout(showSaving, 200);

      try {
        await highnote.updateComment({ id, data });
        clearTimeout(timeoutId);
        commentsCache.current[id].isSaving = false;
        updateCommentsFromCache();
      } catch (e) {
        clearTimeout(timeoutId);
        commentsCache.current[id].updateError =
          "Could not save comment. Please try again.";
        updateCommentsFromCache();
        throw new Error(e.message);
      }
    },
    [],
  );

  const deleteSpaceComment = useCallback(async (id: Id) => {
    if (!commentsCache.current[id]) {
      throw `Delete failed: Comment does not exist at ${id}`;
    }

    commentsCache.current[id] = {
      ...commentsCache.current[id],
      isSaving: true,
      isDeleted: true,
      updateError: undefined,
    };
    updateCommentsFromCache();

    try {
      await highnote.deleteComment({ id });
      commentsCache.current[id].isSaving = false;
      updateCommentsFromCache();
    } catch (e) {
      commentsCache.current[id].isDeleted = false;
      commentsCache.current[id].updateError =
        "Could not delete comment. Please try again.";
      updateCommentsFromCache();
      throw new Error(e.message);
    }
  }, []);

  const value = useMemo(
    () => ({
      comments,
      addSpaceComment,
      updateSpaceComment,
      deleteSpaceComment,
      filteredComments,
      fetchOlderComments,
      commentsLoading,
      olderCommentsLoading,
      getCommentById,
      allCommentsFetched,
      setFilters,
      filters,
    }),
    [
      comments,
      addSpaceComment,
      updateSpaceComment,
      deleteSpaceComment,
      filteredComments,
      fetchOlderComments,
      commentsLoading,
      olderCommentsLoading,
      getCommentById,
      allCommentsFetched,
      setFilters,
      filters,
    ],
  );

  return (
    <SpaceCommentsContext.Provider value={value}>
      {children}
    </SpaceCommentsContext.Provider>
  );
};

export const useSpaceCommentsContext = () => useContext(SpaceCommentsContext);
