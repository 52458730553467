import "./PlanLimitCallout.scss";
import React, { useState } from "react";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { usePlanLimitsContext } from "App/common/PlanLimits/usePlanLimits";
import Measure from "react-measure";

export const PlanLimitCallout = () => {
  const [width, setWidth] = useState<number>(0);
  const {
    title,
    subtitle,
    hasReachedStorageLimit,
    hasReachedSpaceLimit,
    hasReachedTrackVersionsLimit,
    showPlanPickerDialog,
  } = usePlanLimitsContext();

  if (
    !hasReachedStorageLimit &&
    !hasReachedSpaceLimit &&
    !hasReachedTrackVersionsLimit
  ) {
    return null;
  }

  return (
    <Measure
      bounds
      onResize={(contentRect) => {
        setWidth(contentRect?.bounds?.width || 0);
      }}
    >
      {({ measureRef }) => {
        return (
          <div
            ref={measureRef}
            className="PlanLimitCallout"
            data-horizontal={width > 600}
          >
            <div className="body">
              <strong>{title}</strong>
              <div>{subtitle}</div>
            </div>
            <Button
              theme={BUTTON_THEME.CTA}
              size={BUTTON_SIZE.MEDIUM}
              type="button"
              onClick={() => {
                showPlanPickerDialog();
              }}
            >
              Upgrade
            </Button>
          </div>
        );
      }}
    </Measure>
  );
};
