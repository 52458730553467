import React, { ReactElement } from "react";
import { Tooltip } from "App/core/Tooltip";
import { PERMISSION } from "@highnote/server/src/core/shared-util";
import { useAuth } from "App/components/Auth";

import { Comment, Space, Track } from "@highnote/server/src/core/entities";

export const PermissionTooltip = ({
  permission,
  hasPermission,
  space,
  track,
  comment,
  title = "You do not have permission to perform this action.",
  children,
}: {
  permission?: PERMISSION;
  hasPermission?: boolean;
  space?: Space;
  track?: Track;
  comment?: Comment;
  title?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: ReactElement<any, any>;
}) => {
  const { isAllowed } = useAuth();

  if (hasPermission === undefined) {
    hasPermission = isAllowed(permission, {
      space,
      track,
      comment,
    });
  }

  return (
    <Tooltip arrow isDisabled={hasPermission} title={title}>
      {children}
    </Tooltip>
  );
};
