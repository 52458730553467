import { useState } from "react";
import { getApiRoot } from "@highnote/server/src/core/shared-util";
import { highnote } from "@highnote/server/src/sdk";
import { useToast } from "App/common/useToast";
import { PublicSpaceData } from "App/components/Auth/useInviteSpaceData";

export const usePublicSpace = () => {
  const [publicSpaceData, setPublicSpaceData] = useState<
    PublicSpaceData | undefined
  >();
  const [isPublicSpaceLoading, setIsPublicSpaceLoading] = useState(false);
  const { addErrorMessage } = useToast();

  const getPublicSpaceData = async (
    spaceId: string,
    shareKey: string,
    options?: RequestInit,
  ) => {
    setIsPublicSpaceLoading(true);

    try {
      const res = await fetch(
        `${getApiRoot()}/api_getSpace?${new URLSearchParams({
          id: spaceId,
          shareKey,
        })}`,
        options,
      );

      if (res.status === 404) {
        throw new Error("Space not found.");
      }
      if (!res.ok) throw new Error("Failed to get space");

      const data = await res.json();
      const spaceOwner = await highnote.getUserPublic({
        id: data.space.createdBy,
      });

      setPublicSpaceData({ ...data, spaceOwner });
    } catch (error) {
      if (error.name !== "AbortError") addErrorMessage("Failed to get Space");
    } finally {
      setIsPublicSpaceLoading(false);
    }
  };

  return { publicSpaceData, isPublicSpaceLoading, getPublicSpaceData };
};
