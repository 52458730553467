/* POLYFILLS & BROWSER OVERRIDES */
const cachedFetch = window.fetch;
window.fetch = async (...args) => {
  try {
    const response = await cachedFetch(...args);
    return response;
  } catch (e) {
    console.log(`Error from fetch: ${e.message}`, ...args);
    throw e;
  }
};

import React from "react";
import ReactDOM from "react-dom";
import "focus-options-polyfill";

import App from "./App/App";
import * as Sentry from "@sentry/browser";
import { checkMobileRedirectAndAuth } from "App/modules/Auth/helpers";

const touchListener = () => {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  if (!(window as any).isTouchDevice) {
    (window as any).isTouchDevice = true;
    /* eslint-enable @typescript-eslint/no-explicit-any */
    window.document.documentElement.classList.add("isTouchDevice");
    window.removeEventListener("touchstart", touchListener);
  }
};

Sentry.init({
  beforeSend(event) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as any).Cypress) {
      // do not send any errors if we are running in Cypress
      return null;
    }
    return event;
  },
  environment: process.env.NODE_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations(integrations) {
    return [
      ...integrations.filter((i) => i.name !== "TryCatch"),
      Sentry.browserTracingIntegration(),
      Sentry.thirdPartyErrorFilterIntegration({
        // This matches the sentryWebpackPlugin applicationKey in webpack.config.js
        filterKeys: ["highnotefm"],
        // Keep all error events, and apply a third_party_code: true tag for errors containing at
        // least one third-party stack frame
        behaviour: "apply-tag-if-contains-third-party-frames",
      }),
    ];
  },
  tracePropagationTargets: [
    "localhost",
    "on.highnote.fm",
    /deploy-preview-(.*)--highnote.netlify.app/,
  ],
  tracesSampleRate: 0.2,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).TEST_PROPERTIES = {};
// Convert current time to Unix timestamp in seconds by rounding down milliseconds since epoch.
// This timestamp will be used for version comparison in the RefreshAppBanner component.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).highnoteCurrentTimestamp = Math.floor(Date.now() / 1000);
window.addEventListener("touchstart", touchListener);

checkMobileRedirectAndAuth()
  .then(() => {
    ReactDOM.render(<App />, document.getElementById("APP_ROOT_EL"));
  })
  .catch((err) => {
    console.log(`mobile authentication failed: ${err}`);
    console.log(`redirecting to ${window.location.href}`);
  });
