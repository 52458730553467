import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { routePaths } from "./modules/urls";
import Main from "./routes/Main/Main";
import AppWrapper from "./common/AppWrapper/AppWrapper";
import "./styles/styles.scss";
import { Test } from "./routes/Test";
import { PlanLimitsContextProvider } from "./common/PlanLimits/usePlanLimits";
import { Login } from "App/routes/Login";
import { LogOut } from "App/routes/LogOut";
import { ViewportContextProvider } from "./common/useViewport";
import { ToastContextProvider } from "./common/useToast";
import { SystemEditingContextProvider } from "./common/useSystemEditing";
import { SegmentContextProvider } from "./modules/useSegment";
import constants from "./modules/consts";
import { daw } from "@highnote/daw/src";
import { AuthContextProvider } from "./components/Auth";
import "./modules/recordStats";
import { PublicUsersContextProvider } from "./components/PublicUsersContext";
import { Checkout } from "./routes/Main/Checkout";
import { IntercomContextProvider } from "./services/intercom/IntercomContext";

const CACHE_VOLUME_LEVEL_KEY = constants.cache.volumeLevelKey;
const CACHE_VOLUME_MUTED_KEY = constants.cache.volumeMutedKey;

class App extends Component {
  static errorListener;

  constructor(props) {
    super(props);
    this.state = { caughtError: false };
  }

  static getDerivedStateFromError = (error) => {};

  componentDidMount() {
    // Init the DAW volume from cached values
    const volumeIsMuted = !!JSON.parse(
      localStorage.getItem(CACHE_VOLUME_MUTED_KEY),
    );
    const volumeLevel = JSON.parse(
      localStorage.getItem(CACHE_VOLUME_LEVEL_KEY),
    );
    if (typeof volumeLevel === "number") daw.volume = volumeLevel;
    if (volumeIsMuted) daw.volume = 0;
  }

  componentDidCatch(error) {
    // Sentry.captureException(error);
  }

  render() {
    return (
      <ViewportContextProvider>
        <AppWrapper className="App">
          <BrowserRouter>
            <ToastContextProvider>
              <AuthContextProvider>
                <PublicUsersContextProvider>
                  <PlanLimitsContextProvider>
                    <SegmentContextProvider>
                      <SystemEditingContextProvider>
                        <IntercomContextProvider>
                          <Switch>
                            <Route path={routePaths.login} component={Login} />
                            <Route
                              path={routePaths.logout}
                              component={LogOut}
                            />
                            <Route path={routePaths.signup} component={Login} />
                            <Route path={routePaths.link} component={Login} />

                            <Route path={routePaths.test} component={Test} />
                            <Route
                              path={routePaths.checkout}
                              component={Checkout}
                            />
                            <Route path={routePaths.root}>
                              <Main />
                            </Route>
                          </Switch>
                        </IntercomContextProvider>
                      </SystemEditingContextProvider>
                    </SegmentContextProvider>
                  </PlanLimitsContextProvider>
                </PublicUsersContextProvider>
              </AuthContextProvider>
            </ToastContextProvider>
          </BrowserRouter>
        </AppWrapper>
      </ViewportContextProvider>
    );
  }
}

export default App;
