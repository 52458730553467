import React, { useMemo, useState } from "react";
import { ReactComponent as InfoSVG } from "App/common/icons/info.svg";
import { ReactComponent as ChatFilledSVG } from "App/common/icons/chat-filled.svg";
import { ReactComponent as UserFilledSVG } from "App/common/icons/user-filled.svg";
import { ReactComponent as LibrarySVG } from "App/common/icons/library.svg";
import { ReactComponent as LightningSVG } from "App/common/icons/lightning.svg";
import { ChannelTypePreferences } from "@knocklabs/client";
import { useNotificationsContext } from "App/common/useNotifications";
import { StatusIndicator } from "App/common/StatusIndicator";
import { CHANNEL, KNOCK_WORKFLOW } from "@highnote/server/src/core/entities";
import { ChannelPicker } from "App/components/Layout/Notifications/ChannelPicker";
import { ReactComponent as BellMutedSVG } from "App/common/icons/bell-muted.svg";
import { useAuth } from "App/components/Auth";
import { getUserContactMethods } from "@highnote/server/src/core/shared-util";
import { Callout, CALLOUT_LAYOUT } from "App/core/Callout";
import { MenuItem } from "App/common/Menu";
import { Switch } from "App/core/Switch";
import { highnote } from "@highnote/server/src/sdk";

const getChannelSummary = (channels: CHANNEL[] = []) => {
  if (!channels) return "Muted";

  const verbs = [];

  if (channels.includes(CHANNEL.SMS)) verbs.push("SMS");
  if (channels.includes(CHANNEL.EMAIL)) verbs.push("Email");
  if (channels.includes(CHANNEL.FEED)) verbs.push("Feed");

  if (verbs.length === 1) return `On: ${verbs[0]}`;
  if (verbs.length === 2) return `On: ${verbs[0]} and ${verbs[1]}`;
  if (verbs.length === 3) {
    return `On: ${verbs[0]}, ${verbs[1]}, and ${verbs[2]}`;
  }

  return (
    <>
      <BellMutedSVG /> Muted
    </>
  );
};

const NotificationOption = ({
  title,
  workflowIds,
  DynamicContent,
}: {
  title: string;
  workflowIds: KNOCK_WORKFLOW[];
  DynamicContent?: React.FC<{ value: CHANNEL[] }>;
}) => {
  const { user } = useAuth();
  const { preferences, setPreference, isPushEnabled } =
    useNotificationsContext();
  const workflows = workflowIds.map(
    (id) =>
      preferences.workflows[id] as { channel_types: ChannelTypePreferences },
  );

  const allChannels = useMemo(() => {
    const contactMethods = getUserContactMethods(user);
    const channels = [];
    if (contactMethods.phone) channels.push(CHANNEL.SMS);
    if (contactMethods.email) channels.push(CHANNEL.EMAIL);
    channels.push(CHANNEL.FEED);
    if (isPushEnabled) {
      channels.push(CHANNEL.PUSH);
    }
    return channels;
  }, [user, isPushEnabled]);

  const value: CHANNEL[] = [];

  workflows.forEach((workflow) => {
    const channelTypes = workflow?.channel_types || {};
    const baseChannels = Object.keys(channelTypes);
    const channels = isPushEnabled
      ? baseChannels.concat(CHANNEL.PUSH)
      : baseChannels;

    allChannels.forEach((channel) => {
      const isOn = !channels.includes(channel) || !!channelTypes[channel];
      if (isOn) value.push(channel);
    });
  });

  const [optimisticValue, setOptimisticValue] = useState<CHANNEL[]>(value);

  return (
    <div className="NotificationOption">
      <div className="NotificationOption-info">
        <h5>{title}</h5>
        <p>{getChannelSummary(optimisticValue)}</p>
      </div>
      <ChannelPicker
        value={optimisticValue}
        options={allChannels}
        onChange={(newVal) => {
          // Track which channels actually changed
          const added = newVal.filter((c) => !optimisticValue.includes(c));
          const removed = optimisticValue.filter((c) => !newVal.includes(c));
          const changedChannels = [...added, ...removed];

          setOptimisticValue(newVal);

          workflowIds.forEach((workflowId) => {
            const data = (preferences.workflows[workflowId] || {}) as {
              channel_types: ChannelTypePreferences;
            };

            // Only update channels that changed
            changedChannels.forEach((channel) => {
              data.channel_types = data.channel_types || {};
              const previousValue = !!data.channel_types[channel];
              const newValue = !!newVal.includes(channel);

              // Only update if the value actually changed
              if (previousValue !== newValue) {
                data.channel_types[channel] = newValue;
              }
            });

            setPreference(workflowId, data);
          });
        }}
        DynamicContent={DynamicContent}
      />
    </div>
  );
};

export const NotificationSettings = () => {
  const { user } = useAuth();
  const { preferences } = useNotificationsContext();
  const contactMethods = getUserContactMethods(user);

  if (!preferences) {
    return (
      <div className="NotificationSettings">
        <section>
          <h2>
            Notifications
            <StatusIndicator isLoading />
          </h2>
        </section>
      </div>
    );
  }

  return (
    <div className="NotificationSettings">
      <section className="NotificationSettings-category">
        <h3>
          <ChatFilledSVG />
          Conversation
        </h3>
        <div className="container">
          <NotificationOption
            title="Someone created a comment card"
            workflowIds={[KNOCK_WORKFLOW.CARD_COMMENT_ADDED]}
          />
          <NotificationOption
            title="Someone replied to or resolved your comment"
            workflowIds={[
              KNOCK_WORKFLOW.CARD_REPLY_ADDED,
              KNOCK_WORKFLOW.CARD_RESOLVED,
            ]}
          />
          <NotificationOption
            title="Someone sent a message in Space chat"
            workflowIds={[KNOCK_WORKFLOW.CHAT_COMMENT_ADDED]}
          />
        </div>
      </section>

      <section className="NotificationSettings-category">
        <h3>
          <UserFilledSVG />
          Collaborators
        </h3>
        <div className="container">
          <NotificationOption
            title="You've been added to a Space"
            workflowIds={[KNOCK_WORKFLOW.SPACE_MEMBER_ADDED]}
          />
        </div>
        <div className="container">
          <NotificationOption
            title="Someone requested access to your space"
            workflowIds={[KNOCK_WORKFLOW.SPACE_MEMBER_REQUESTED]}
          />
        </div>
      </section>

      <section className="NotificationSettings-category">
        <h3>
          <LibrarySVG />
          Library
        </h3>
        <div className="container">
          <NotificationOption
            title="Someone added a Track or Track version to your Space"
            workflowIds={[
              KNOCK_WORKFLOW.TRACK_ADDED,
              KNOCK_WORKFLOW.TRACK_VERSION_ADDED,
            ]}
          />
        </div>
        <div className="container">
          <NotificationOption
            title="Someone uploaded an attachment"
            workflowIds={[
              KNOCK_WORKFLOW.SPACE_ATTACHMENT_ADDED,
              KNOCK_WORKFLOW.TRACK_ATTACHMENT_ADDED,
            ]}
          />
        </div>
      </section>

      <section className="NotificationSettings-category">
        <h3>
          <LightningSVG />
          Activity
        </h3>
        <div className="container">
          <NotificationOption
            title="Someone downloaded something from your space"
            workflowIds={[
              KNOCK_WORKFLOW.SPACE_ATTACHMENT_DOWNLOADED,
              KNOCK_WORKFLOW.SPACE_DOWNLOADED,
              KNOCK_WORKFLOW.TRACK_ATTACHMENT_DOWNLOADED,
              KNOCK_WORKFLOW.TRACK_DOWNLOADED,
              KNOCK_WORKFLOW.TRACK_VERSION_DOWNLOADED,
            ]}
          />
        </div>
        <div className="container">
          <NotificationOption
            title="Someone played a Track"
            workflowIds={[KNOCK_WORKFLOW.TRACK_PLAYED]}
            DynamicContent={({ value }) => {
              if (value.length < 1) return null;
              return (
                <>
                  <div className="divider"></div>
                  <MenuItem
                    className="ChannelPicker-option"
                    onClick={() => {
                      highnote.updateUser({
                        id: user.id,
                        data: {
                          notifyOnAllTrackPlays: !user.notifyOnAllTrackPlays,
                        },
                      });
                    }}
                  >
                    <p style={{ marginRight: "10px" }}>
                      <span className="title">First Time Only</span>
                      Get this notification once per track per listener
                    </p>
                    <Switch checked={!user.notifyOnAllTrackPlays} />
                  </MenuItem>
                </>
              );
            }}
          />
        </div>
      </section>

      <br />

      {(!!contactMethods.email || !!contactMethods.phone) && (
        <Callout
          layout={CALLOUT_LAYOUT.ROW}
          icon={<InfoSVG />}
          body={
            <>
              {contactMethods.email && (
                <>
                  Email notifications are being sent to {contactMethods.email}.{" "}
                </>
              )}
              {contactMethods.phone && (
                <>SMS notifications are being sent to {contactMethods.phone}.</>
              )}
            </>
          }
        />
      )}
    </div>
  );
};
