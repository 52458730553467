import React, { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import { ENTITY_TYPE, EntityRowConfig } from "../config";
import {
  BaseRow,
  BaseTableRowProps,
  EXPAND_COLUMN_WIDTH,
  EXPAND_COLUMN_WIDTH_MOBILE,
  TableRowVariant,
  calculateChildIndentation,
  getEntityCollectionFromType,
} from "App/common/BaseTable";
import {
  COLLECTION_ID,
  FileEntity,
  Space,
  Track,
} from "@highnote/server/src/core/entities";
import {
  AttachmentsContextProvider,
  useAttachmentsContext,
} from "App/common/useAttachments";
import {
  sortRowByAlphabetical,
  sortRowByCreatedAt,
  sortRowByCreatedAtAscending,
} from "App/components/util";
import {
  getEntitySubscribers,
  isSpacePrivateInboxEnabled,
  sortItemsByItemsOrder,
} from "@highnote/server/src/core/shared-util";
import { useUser } from "App/components/useEntities/useUser";
import { getAuthId } from "App/components/Auth";
import { useGlobalSpaces } from "App/store/spaces/useGlobalSpaces";
import { useGlobalTracks } from "App/store/tracks/useGlobalTracks";
import { useCreatedTracks } from "App/components/useEntities/useCreatedTracks";
import { useFileDownload } from "App/common/useFileDownload";
import { useChildEntityWatchers } from "App/store/helpers/useChildEntityWatchers";
import { useGlobalAudioPlayer } from "App/components/GlobalAudioPlayer";
import { DefaultTrackActions } from "App/components/useEntities/useLibraryTracks/TrackActions";
import { DefaultSpaceActions } from "App/common/SpaceActions";
import { DefaultAttachmentActions } from "App/common/AttachmentActions";
import { SORT_TYPE } from "App/routes/Main/Library/config";
import { Confirmator } from "App/common/useConfirmation";
import { BUTTON_THEME } from "App/core/Button";
import { withMobileDeeplinkRoot } from "App/modules/urls";
import { useViewport } from "App/common/useViewport";
import { isTouchDevice } from "App/modules/utils";
import { ExpandableParentTrackRow } from "../ExpandableTrack/ExpandableTrackRow";
import { useEntitiesSelection } from "App/components/useEntitiesSelection";
import { Skeleton } from "App/common/Skeleton/Skeleton";
import styles from "./ExpandableSpaceRow.module.scss";

export const childItemRowsToBulkSelections = (
  rows: EntityRowConfig[],
  parentEntityId: string,
  parentEntityType: COLLECTION_ID.SPACE | COLLECTION_ID.TRACK,
) => {
  const bulkSelections = rows.reduce((acc, itemRow) => {
    const include =
      parentEntityType === COLLECTION_ID.SPACE
        ? itemRow.type === ENTITY_TYPE.SPACE ||
          itemRow.type === ENTITY_TYPE.TRACK ||
          itemRow.type === ENTITY_TYPE.TRACK_VERSION ||
          itemRow.type === ENTITY_TYPE.FILE
        : itemRow.type === ENTITY_TYPE.TRACK_VERSION ||
          itemRow.type === ENTITY_TYPE.FILE;
    if (include) {
      acc.push({
        entityId: (itemRow.entity as Entity).id,
        entityType: getEntityCollectionFromType(itemRow.type),
        parentEntity: {
          entityId: parentEntityId,
          entityType: parentEntityType,
        },
      });
    }
    return acc;
  }, []);
  return bulkSelections;
};

/**
 * 'useFormatSpaceToBaseEntityRow', 'useFormatTrackToBaseEntityRow', and 'useFormatFileToBaseEntityRow' create
 * a row configuration object for entities (Space, Track, File) in the EntityTable component.
 * These functions ensures consistency and ease of maintenance across different components.
 * Used via useSpaceChildItemRows in: ExpandableSpaceRow.tsx, EntityLibrary.tsx, SpaceItems.tsx.
 *
 * Table Rows:
 * - Track Row (Flat, expandable with versions)
 * - Attachment/File Row (Flat)
 * - Space Row (Expanded, Click-Thru, Flat)
 */

export type EntityTableRowOptions = {
  hasPlayableTracks?: boolean;
  tableRowVariant?: TableRowVariant;
};

export const useFormatSpaceToBaseEntityRow = () => {
  const history = useHistory();

  const formatSpaceToBaseEntityRow = ({
    space,
    options,
  }: {
    space: Space;
    options?: EntityTableRowOptions;
  }): EntityRowConfig => {
    const tableRowVariant = options?.tableRowVariant;

    return {
      key: space.id,
      id: space.id,
      entity: space,
      type: ENTITY_TYPE.SPACE,
      createdAt: space.createdAt,
      name: space.name,
      actions: <DefaultSpaceActions space={space} />,
      onClick: () => {
        history.push(`/space/${space.id}`);
      },
      attributes:
        tableRowVariant === "nested-click-thru"
          ? { "data-is-nested": "true" }
          : undefined,
      NestedTable:
        tableRowVariant === "expanded-row" ||
        tableRowVariant === "draggable-expanded-row"
          ? ExpandableParentSpaceRow
          : undefined,
    };
  };

  return { formatSpaceToBaseEntityRow };
};

export const trackDeeplinkConfirmPayload = {
  wrapperClassName: "track-open-in-mobile-confirm-dialog",
  body: "Open Track",
  continueCTAConfig: {
    label: "Open in App",
    theme: BUTTON_THEME.CTA,
  },
  cancelCTAConfig: {
    label: "Continue in Browser",
    theme: BUTTON_THEME.SECONDARY,
  },
};

export const useFormatTrackToBaseEntityRow = () => {
  const { user } = useUser();
  const history = useHistory();
  const { vw } = useViewport();
  const isMobile = vw <= 768 || isTouchDevice();

  const formatTrackToBaseEntityRow = ({
    track,
    options,
  }: {
    track: Track;
    options?: EntityTableRowOptions;
  }): EntityRowConfig => {
    return {
      key: track.id,
      id: track.id,
      entity: track,
      type: ENTITY_TYPE.TRACK,
      actions: <DefaultTrackActions track={track} />,
      createdAt: track.createdAt,
      name: track.title,
      onClick: () => {
        history.push(`/space/${track.spaceId}/${track.id}`);
      },
      isPlayable: options?.hasPlayableTracks,
      ...(user?.isMobileUser &&
        isMobile && {
          confirmOnClick: (confirm: Confirmator) =>
            confirm(trackDeeplinkConfirmPayload),
          onConfirmCancel: () => {
            window.location.href = `${withMobileDeeplinkRoot(`root/library/${track.spaceId}/${track.id}`)}`;
          },
        }),
      NestedTable:
        options?.tableRowVariant === "expanded-row" &&
        track.versionFilesV2.length > 1
          ? ExpandableParentTrackRow
          : undefined,
    };
  };

  const formatTracksListToBaseEntityRows = ({
    tracks,
    options,
  }: {
    tracks: Track[];
    options?: EntityTableRowOptions;
  }) => {
    return tracks.map((track) => {
      return formatTrackToBaseEntityRow({ track, options });
    });
  };

  return { formatTrackToBaseEntityRow, formatTracksListToBaseEntityRows };
};

export const useFormatFileToBaseEntityRow = () => {
  // Assumes all components using this function are wrapped with the AttachmentsContextProvider
  const { canEdit } = useAttachmentsContext();
  const { downloadFile } = useFileDownload();

  const formatFileToBaseEntityRow = (file: FileEntity): EntityRowConfig => {
    return {
      key: file.id,
      id: file.id,
      entity: file,
      type: ENTITY_TYPE.FILE,
      actions: <DefaultAttachmentActions attachment={file} />,
      createdAt: file.createdAt,
      name: file.name,
      onClick: file.url
        ? () => window.open(file.url, "_blank")
        : file.storagePath && canEdit
          ? () => downloadFile(file.id)
          : undefined,
    };
  };

  return { formatFileToBaseEntityRow };
};

export const getSortedChildRows = ({
  childItemRows,
  sortType,
  itemsOrder,
}: {
  childItemRows: EntityRowConfig[];
  sortType: SORT_TYPE;
  // Used to track order changes via drag and drop
  // TODO: Optimistic update benefit, but is this needed, or can we rely on backend to update the order via space.itemsOrder?
  itemsOrder?: string[];
}): EntityRowConfig[] => {
  if (sortType !== SORT_TYPE.CUSTOM) {
    return childItemRows.slice().sort((aRow, bRow) => {
      if (sortType === SORT_TYPE.ALPHABETICAL) {
        return sortRowByAlphabetical(aRow, bRow);
      }
      return sortRowByCreatedAt(aRow, bRow);
    });
  }

  const { orderedItems, unOrderedItems } = sortItemsByItemsOrder(
    itemsOrder || [],
    childItemRows as { id: Id; entity: Entity }[],
  );

  unOrderedItems.sort((aRow, bRow) => {
    return sortRowByCreatedAtAscending(
      aRow as EntityRowConfig,
      bRow as EntityRowConfig,
    );
  });

  return [...orderedItems, ...unOrderedItems];
};

export const useSpaceChildItemRows = ({
  space,
  options,
}: {
  space: Space;
  options?: EntityTableRowOptions;
}) => {
  const { globalSpaces, globalSpacesWatchers, getGlobalSpace } =
    useGlobalSpaces();
  const { globalTracks } = useGlobalTracks();
  const { createdTracks } = useCreatedTracks();
  const { attachments } = useAttachmentsContext();
  const { formatSpaceToBaseEntityRow } = useFormatSpaceToBaseEntityRow();
  const { formatTrackToBaseEntityRow } = useFormatTrackToBaseEntityRow();
  const { formatFileToBaseEntityRow } = useFormatFileToBaseEntityRow();

  const childSpacesLoading = globalSpacesWatchers
    .get(space.id)
    ?.loading.get("space-home-child-item-rows");

  const spaceId = space.id;
  const childSpaces = globalSpaces.childSpaces.get(spaceId) || [];
  const childTracks = globalTracks.childTracks.get(spaceId) || [];

  const childItemRows = useMemo(() => {
    const userId = getAuthId();
    const subscribers = getEntitySubscribers(space);

    const isInboxGuest =
      isSpacePrivateInboxEnabled(space) && !subscribers.includes(userId);

    const tracks = isInboxGuest
      ? createdTracks
      : childTracks.map((globalTrackId) =>
          globalTracks.tracks.get(globalTrackId),
        );

    const spaceTracks =
      (tracks || []).map((track) => {
        return formatTrackToBaseEntityRow({
          track,
          options: {
            hasPlayableTracks: options?.hasPlayableTracks,
            tableRowVariant: options.tableRowVariant,
          },
        });
      }) || [];

    const spaceChildSpaces =
      childSpaces.reduce<EntityRowConfig[]>((acc, childSpaceId) => {
        const childSpace = getGlobalSpace(childSpaceId, true);

        if (childSpace && !childSpace.isArchived) {
          acc.push(
            formatSpaceToBaseEntityRow({
              space: childSpace,
              options: { tableRowVariant: options?.tableRowVariant },
            }),
          );
        }
        return acc;
      }, []) || [];

    const spaceAttachments = isInboxGuest
      ? []
      : attachments?.map(formatFileToBaseEntityRow) || [];

    return [...spaceTracks, ...spaceChildSpaces, ...spaceAttachments];
  }, [childSpaces, childTracks, createdTracks, attachments]);

  // Fetches all child spaces and tracks of the space
  const { manageGlobalChildEntityWatchers } = useChildEntityWatchers();

  useEffect(() => {
    const watcher = manageGlobalChildEntityWatchers({
      spaceId,
      // TODO: Consider using page/componentId as a unique identifier for the child item rows
      // as we can have multiple space-child-item-rows on a page poitning to the same spaceId
      // ie. SpacePicker and SpaceItems are both using space-child-item-rows but are different components
      // and pointing to the same spaceId.
      componentId: `space-home-child-item-rows`,
    });

    // Used to fetch child entities and set realtime watchers/listeners
    // for the current parent spaceId.
    watcher.attach();

    return () => {
      // Used to unsubscribe from realtime watchers/listeners
      // for the current parent spaceId.
      watcher.detach();
    };
  }, []);

  const _getSortedChildRows = useCallback(
    ({
      sortType = SORT_TYPE.CUSTOM,
      itemsOrder,
    }: {
      sortType: SORT_TYPE;
      // Used to track order changes via drag and drop
      // TODO: Optimistic update benefit, but is this needed, or can we rely on backend to update the order via space.itemsOrder?
      itemsOrder?: string[];
    }): EntityRowConfig[] => {
      return useMemo(() => {
        return getSortedChildRows({
          sortType,
          childItemRows,
          itemsOrder: itemsOrder || space.itemsOrder,
        });
      }, [childItemRows, sortType, itemsOrder, space.itemsOrder]);
    },
    [childItemRows, space.itemsOrder],
  );

  return {
    getSortedSpaceChildItemRows: _getSortedChildRows,
    spaceChildRowsLoading: childSpacesLoading,
  };
};

const _ExpandableParentSpaceRow = ({
  space,
  parentRow,
  tableRowProps,
  sortType = SORT_TYPE.CUSTOM,
}: {
  space: Space;
  parentRow: EntityRowConfig;
  tableRowProps: Omit<BaseTableRowProps, "row"> & {
    hasPlayableTracks?: boolean;
  };
  sortType?: SORT_TYPE;
}) => {
  const { isMobile } = useViewport();
  const { fetchAudioFiles } = useGlobalAudioPlayer();
  const { globalSpaces } = useGlobalSpaces();
  const { getSortedSpaceChildItemRows, spaceChildRowsLoading } =
    useSpaceChildItemRows({
      space,
      options: {
        tableRowVariant: tableRowProps.tableRowVariant,
        hasPlayableTracks: tableRowProps.hasPlayableTracks ?? true,
      },
    });
  const { bulkAddToSelection, selectedEntities } = useEntitiesSelection();

  // TODO: `itemsOrder` will need to be passed here as well for optimistically ordering nested rows via the client (client side sorting).
  // Currently, it defaults to the space.itemsOrder (from the server) if not passed in.
  const spaceChildItemRows = getSortedSpaceChildItemRows({
    sortType,
  });

  const isSpaceSelected = space.id in selectedEntities;
  const allChildrenSelected = useMemo(() => {
    return (
      spaceChildItemRows.length > 0 &&
      spaceChildItemRows.every((itemRow) => itemRow.id in selectedEntities)
    );
  }, [spaceChildItemRows, selectedEntities]);

  useEffect(() => {
    // if space is selected, make sure all space children are selected
    if (isSpaceSelected) {
      const bulkSelections = childItemRowsToBulkSelections(
        spaceChildItemRows,
        space.id,
        COLLECTION_ID.SPACE,
      );
      bulkAddToSelection(bulkSelections);
      return;
    }
  }, [isSpaceSelected, allChildrenSelected, spaceChildItemRows]);

  useEffect(() => {
    spaceChildItemRows.forEach((itemRow) => {
      if (itemRow.type === ENTITY_TYPE.TRACK) {
        fetchAudioFiles(itemRow.entity as Track);
      }
    });
  }, [spaceChildItemRows]);

  if (
    spaceChildRowsLoading &&
    spaceChildItemRows.length === 0 &&
    !globalSpaces.childSpaces.get(space.id)
  ) {
    const childIndentation = calculateChildIndentation(
      tableRowProps.indentation,
      isMobile,
    );

    const defaultColumnWidth = isMobile
      ? EXPAND_COLUMN_WIDTH_MOBILE
      : EXPAND_COLUMN_WIDTH;

    return (
      <div
        className={styles["skeleton-container"]}
        style={{
          paddingLeft: `${childIndentation + defaultColumnWidth}px`,
        }}
      >
        {Array.from({ length: 3 }).map((_, index) => (
          <Skeleton key={`skeleton-${index}`} height={40} />
        ))}
      </div>
    );
  }

  return (
    <>
      {spaceChildItemRows.map((childRow, i) => {
        const baseProps = {
          ...tableRowProps,
          parentRow,
          indentation: tableRowProps.indentation + 1,
        };

        return (
          <BaseRow
            key={`expandable-space-row-${childRow.key}-${i}`}
            {...baseProps}
            row={{
              ...childRow,
              ...(childRow.type === ENTITY_TYPE.SPACE && {
                NestedTable: ExpandableParentSpaceRow,
              }),
              selected: childRow.id in selectedEntities,
            }}
          />
        );
      })}
    </>
  );
};

const ExpandableParentSpaceRow = ({
  parentRow,
  tableRowProps,
  sortType,
}: {
  parentRow: EntityRowConfig;
  tableRowProps: Omit<BaseTableRowProps, "row">;
  sortType?: SORT_TYPE;
}) => {
  const parentSpace = parentRow.entity as Space;

  return (
    <AttachmentsContextProvider
      space={parentSpace}
      attachmentIds={parentSpace.files || []}
    >
      <_ExpandableParentSpaceRow
        space={parentSpace}
        parentRow={parentRow}
        tableRowProps={tableRowProps}
        sortType={sortType}
      />
    </AttachmentsContextProvider>
  );
};
