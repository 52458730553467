import React from "react";
import { useHistory, Redirect } from "react-router";
import { useViewport } from "App/common/useViewport";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { ReactComponent as LogoFullSVG } from "App/common/icons-v2/logo-full-beta.svg";
import {
  APP_FEATURES,
  AppFeaturesStatus,
} from "@highnote/server/src/core/features";
import styles from "./MobileAppInstallPrompt.module.scss";
import { BETA_APP_URL } from "App/modules/consts";
import { AuthRedirectRequired } from "App/modules/Auth/AuthRedirectRequired";

export const APP_PROMPT_SESSION_KEY = "highnote-mobile-app-prompt-shown";

const _MobileAppInstallPrompt = () => {
  const history = useHistory();
  const { isMobile } = useViewport();
  const searchParams = new URLSearchParams(window.location.search);
  const returnTo = searchParams.get("returnTo") || "/";

  // Redirect to returnTo if not mobile or feature flag is off
  if (!isMobile || !AppFeaturesStatus[APP_FEATURES.BETA_ACCESS_PROMPT_DIALOG]) {
    return <Redirect to={returnTo} />;
  }

  const handleContinueWeb = () => {
    // Store in session storage to prevent showing again in this session
    sessionStorage.setItem(APP_PROMPT_SESSION_KEY, "true");
    history.replace(returnTo);
  };

  const handleInstallApp = () => {
    window.location.href = BETA_APP_URL;
  };

  return (
    <div className={styles["mobile-prompt-page"]}>
      <div className={styles["mobile-prompt-logo"]}>
        <LogoFullSVG />
      </div>

      <div className={styles["mobile-prompt-image"]}>
        <picture>
          <source
            srcSet="/public/mobile_ios_app_bar_display-min.webp"
            type="image/webp"
          />
          <img
            src="/public/mobile_ios_app_bar_display-min.png"
            alt="Highnote Mobile App"
            className={styles["app-image"]}
          />
        </picture>
      </div>

      <div className={styles["mobile-prompt-text"]}>
        <p>
          Highnote for iOS and Android is now in beta. Install for a better
          experience.
        </p>
      </div>

      <div className={styles["mobile-prompt-buttons"]}>
        <Button
          theme={BUTTON_THEME.CTA}
          size={BUTTON_SIZE.MEDIUM}
          fullWidth
          onClick={handleInstallApp}
        >
          Install the App
        </Button>

        <Button
          theme={BUTTON_THEME.PRIMARY}
          size={BUTTON_SIZE.MEDIUM}
          fullWidth
          onClick={handleContinueWeb}
        >
          Continue on Web
        </Button>
      </div>
    </div>
  );
};

export const MobileAppInstallPrompt = () => {
  return (
    <AuthRedirectRequired>
      <_MobileAppInstallPrompt />
    </AuthRedirectRequired>
  );
};
