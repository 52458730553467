import { useCallback, useEffect, useRef, RefObject } from "react";
import { daw } from "@highnote/daw";

import {
  useGlobalAudioPlayer,
  useHTMLAudioPlayer,
} from "App/components/GlobalAudioPlayer";
import { useTrackVersionInDAW } from "App/routes/Main/Space/SpaceTrack";

// This hook saves a reference to the `seek` and `getCurrentTime` functions
// of our two audio players, abstracting away the swap between
// the HTML audio player and the global Web Audio player.

// A ref is used because it can be passed into the CarouselSync constructor
// and saved as a property on the CarouselSync instance, while still allowing
// `ref.current` to be swapped out with the latest callback functions.
export type SeekRef = RefObject<{
  seek: (time: number) => void;
  getCurrentTime: () => number;
}>;

export const useSeekRef = (): SeekRef => {
  const { seek: globalSeek } = useGlobalAudioPlayer();
  const {
    useHTMLPlayer,
    getCurrentTime: htmlGetCurrentTime,
    onSeek: htmlSeek,
  } = useHTMLAudioPlayer();

  const _dawTrack = useTrackVersionInDAW();

  const seek = useCallback(
    (time: number) => {
      const dawTrack = daw.getTrack(_dawTrack?.id);
      const newTime = time + (dawTrack?.startTime || 0);
      (useHTMLPlayer ? htmlSeek : globalSeek)(newTime);
    },
    [_dawTrack?.id, globalSeek, htmlSeek, useHTMLPlayer],
  );

  // Passing a `getCurrentTime` function instead of passing the `currentTime` state value
  // ensures that CarouselSync can always get the most up-to-date timestamp
  // instead of being stuck with the timestamp from the last render.
  const getCurrentTime = useCallback(() => {
    return useHTMLPlayer
      ? htmlGetCurrentTime()
      : daw.getTrackTime(_dawTrack?.id);
  }, [_dawTrack?.id, htmlGetCurrentTime, useHTMLPlayer]);

  const ref = useRef({ seek, getCurrentTime });

  useEffect(() => {
    ref.current = { seek, getCurrentTime };
  }, [seek, getCurrentTime]);

  return ref;
};
