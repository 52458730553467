import React from "react";

export const PollReplyBlock = (props: {
  data: PollReplyBlock;
  createdBy: Id;
  createdByAlias?: string;
}) => {
  const { data } = props;
  const selection = "AB"[data.option];
  const text = selection ? `selected ${selection}` : "removed their selection";

  return <div className="poll-reply-block">{text}</div>;
};
