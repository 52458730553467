import React, { forwardRef } from "react";
import classNames from "classnames";
import styles from "./Container.module.scss";

type Props = React.HTMLProps<HTMLDivElement> & {
  variant?: "container-full" | "container-xl";
};

export const Container = forwardRef<HTMLDivElement, Props>(
  // eslint-disable-next-line react/prop-types
  ({ className, variant, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={classNames(
          styles.container,
          {
            [styles[variant]]: Boolean(variant),
          },
          className,
        )}
      >
        {children}
      </div>
    );
  },
);

Container.displayName = "Container";
