import "./Switch.scss";
import React from "react";
import {
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from "@mui/material";
import { useTheme } from "App/common/ThemeProvider";

export type SwitchProps = MuiSwitchProps;
export const Switch = (props: SwitchProps) => {
  const { theme } = useTheme();

  const { ...rest } = props;

  return (
    <MuiSwitch
      {...rest}
      classes={{ root: `highnote-switch ${props.className}` }}
      data-theme={theme}
      disabled={props.disabled}
      data-disabled={props.disabled}
      value={props.value}
      onChange={(e, value) => {
        if (props.disabled) {
          e.preventDefault();
          return;
        }
        props.onChange && props.onChange(e, value);
      }}
    />
  );
};
