import "./Notifications.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FeedItem, User } from "@knocklabs/client";
import { v4 as uuidv4 } from "uuid";
import { MenuList, Popover } from "@mui/material";
import { MenuItem } from "App/common/Menu";
import { ReactComponent as BellSVG } from "App/common/icons/bell.svg";
import { useHistory } from "react-router";
import {
  NOTIFICATION_FILTER,
  useNotificationsContext,
} from "App/common/useNotifications";
import {
  ConnectedUserAvatar,
  USER_AVATAR_SIZE,
} from "App/common/UserAvatar/UserAvatar";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { ThemeProvider, THEME } from "App/common/ThemeProvider";
import { UnreadDot } from "App/common/UnreadDot";
import {
  CardCommentAddedData,
  ChatCommentAddedData,
  EVENT_ID,
  KNOCK_WORKFLOW,
  SpaceMemberAddedData,
  SubscriptionUpdateData,
  TrackAddedData,
  TrackAttachmentAddedData,
  TrackPlayedData,
  TrackAttachmentDownloadedData,
  TrackDownloadedData,
  TrackVersionAddedData,
  TrackVersionDownloadedData,
  SpaceMemberJoinedData,
  SpaceMemberRequestedData,
  DownloadLinkReadyData,
  DownloadLinkFailedData,
} from "@highnote/server/src/core/entities";
import { useSegmentContext } from "App/modules/useSegment";
import { getAuthId } from "App/components/Auth";
import { UnreadDotV2 } from "App/common/UnreadDotV2";

const getItemPath = (item: FeedItem) => {
  if (!item || !item.data) return;

  if (item.source.key === KNOCK_WORKFLOW.DOWNLOAD_LINK_READY) {
    return `/download/${item.data.downloadRequestId}`;
  }

  if (item.source.key === KNOCK_WORKFLOW.DOWNLOAD_LINK_FAILED) {
    return "/library";
  }

  if (item.source.key === KNOCK_WORKFLOW.CHAT_COMMENT_ADDED) {
    const baseUrl = `/space/${item.data.spaceId}?space-chat&`;
    return `${baseUrl}commentId=${item.data.commentId}`;
  }

  if (
    item.source.key === KNOCK_WORKFLOW.CARD_COMMENT_ADDED ||
    item.source.key === KNOCK_WORKFLOW.CARD_REPLY_ADDED ||
    item.source.key === KNOCK_WORKFLOW.CARD_RESOLVED
  ) {
    const baseUrl = `/space/${item.data.spaceId}/${item.data.trackId}?`;
    return `${baseUrl}commentId=${item.data.commentId}`;
  }

  if (
    item.source.key === KNOCK_WORKFLOW.SPACE_ATTACHMENT_ADDED ||
    item.source.key === KNOCK_WORKFLOW.SPACE_ATTACHMENT_DOWNLOADED ||
    item.source.key === KNOCK_WORKFLOW.SPACE_DOWNLOADED ||
    item.source.key === KNOCK_WORKFLOW.SPACE_MEMBER_ADDED ||
    item.source.key === KNOCK_WORKFLOW.SPACE_MEMBER_JOINED ||
    item.source.key === KNOCK_WORKFLOW.TRACK_ADDED
  ) {
    return `/space/${item.data.spaceId}`;
  }

  if (item.source.key === KNOCK_WORKFLOW.SPACE_MEMBER_REQUESTED) {
    if (item.data.userId) {
      return `/space/${item.data.spaceId}?requester=${item.data.userId}`;
    }
    return `/space/${item.data.spaceId}`;
  }

  if (item.source.key === KNOCK_WORKFLOW.TRACK_DOWNLOADED) {
    if (item.total_activities > 1) {
      return `/space/${item.data.spaceId}`;
    }
    return `/space/${item.data.spaceId}/${item.data.trackId}`;
  }

  if (
    item.source.key === KNOCK_WORKFLOW.TRACK_ADDED ||
    item.source.key === KNOCK_WORKFLOW.TRACK_PLAYED
  ) {
    const spaceUrl = `/space/${item.data.spaceId}`;
    const trackUrl = `/space/${item.data.spaceId}/${item.data.trackId}`;
    if (item.total_activities === 1) {
      return `${trackUrl}?versionId=${item.data.versionId}`;
    }
    return spaceUrl;
  }

  if (item.source.key === KNOCK_WORKFLOW.TRACK_VERSION_ADDED) {
    const trackUrl = `/space/${item.data.spaceId}/${item.data.trackId}`;
    if (item.total_activities === 1) {
      return `${trackUrl}?versionId=${item.data.versionId}`;
    }
    return trackUrl;
  }

  if (
    item.source.key === KNOCK_WORKFLOW.TRACK_ATTACHMENT_ADDED ||
    item.source.key === KNOCK_WORKFLOW.TRACK_ATTACHMENT_DOWNLOADED
  ) {
    return `/space/${item.data.spaceId}/${item.data.trackId}?track-editor=attachments`;
  }

  if (item.source.key === KNOCK_WORKFLOW.TRACK_VERSION_DOWNLOADED) {
    return `/space/${item.data.spaceId}/${item.data.trackId}?track-editor=versions`;
  }
};

const ChatCommentAddedNotification = ({
  item,
}: {
  item: FeedItem<ChatCommentAddedData>;
}) => {
  const numComments = item.total_activities;

  return (
    <>
      <strong>{numComments}</strong> new{" "}
      {numComments === 1 ? "message" : "messages"} in{" "}
      <strong>{item.data.spaceName}</strong>
    </>
  );
};

const CardCommentAddedNotification = ({
  item,
}: {
  item: FeedItem<CardCommentAddedData>;
}) => {
  const actor = item.actors[0] as User;
  let phrasing = "commented";
  if (item.data.commentType === "custom") phrasing = "added a sticker";
  else if (item.data.commentType === "file-v2") phrasing = "left a voice note";
  else if (item.data.commentType === "poll-v2") phrasing = "made a poll";

  return (
    <>
      <strong>{actor.name || "Someone"}</strong> {phrasing}
      {" on "}
      <strong>
        {item.data.trackName} ({item.data.versionName})
      </strong>
    </>
  );
};

const CardReplyAddedNotification = ({
  item,
}: {
  item: FeedItem<CardCommentAddedData>;
}) => {
  const actor = item.actors[0] as User;

  return (
    <>
      <strong>{actor.name || "Someone"}</strong> replied to you on{" "}
      <strong>
        {item.data.trackName} ({item.data.versionName})
      </strong>
    </>
  );
};

const CardResolvedNotification = ({
  item,
}: {
  item: FeedItem<CardCommentAddedData>;
}) => {
  const text = item.data.commentText;

  return (
    <>
      Your comment&nbsp;
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      {!!text && <>"{text.length > 20 ? `${text.slice(0, 17)}...` : text}" </>}
      on <strong>{item.data.trackName}</strong> was resolved
    </>
  );
};

const SubscriptionUpdatedNotification = ({
  item,
}: {
  item: FeedItem<SubscriptionUpdateData>;
}) => {
  return (
    <>
      <strong>{item.data.updateTitle}</strong>
      <br />
      {item.data.updateDescription}
    </>
  );
};

const SpaceMemberAddedNotification = ({
  item,
}: {
  item: FeedItem<SpaceMemberAddedData>;
}) => {
  const actor = item.actors[0] as User;
  const sharedSpacesCount = item.total_activities;

  if (sharedSpacesCount > 1) {
    return <>{sharedSpacesCount} spaces shared with you</>;
  }

  return (
    <>
      <strong>{actor.name || "Someone"}</strong> shared{" "}
      <strong>{item.data.spaceName}</strong> with you
    </>
  );
};

const SpaceMemberRequestedNotification = ({
  item,
}: {
  item: FeedItem<SpaceMemberRequestedData>;
}) => {
  return (
    <>
      <strong>
        {item.data.displayName || "Someone"}
        {item.data.identifier ? ` (${item.data.identifier})` : ""}
      </strong>{" "}
      is requesting access to <strong>{item.data.spaceName}</strong>
    </>
  );
};

const SpaceMemberJoinedNotification = ({
  item,
}: {
  item: FeedItem<SpaceMemberJoinedData>;
}) => {
  const actor = item.actors[0] as User;

  return (
    <>
      <strong>{actor.name || "Someone"}</strong> just joined your Space:{" "}
      <strong>{item.data.spaceName}</strong>
    </>
  );
};

const SpaceItemsDownloadedNotification = () => {
  return (
    <>
      Items downloaded
      {/* <strong>{actor.name || "Someone"}</strong> shared{" "}
      <strong>{item.data.entityName}</strong> with you */}
    </>
  );
};

const SpaceAttachmentAddedNotification = ({ item }: { item: FeedItem }) => {
  const actor = (item.activities[0].actor as User).name;
  const attachmentsCount = item.total_activities;
  const spaceName = item.activities[0].data.spaceName;
  if (attachmentsCount > 1) {
    return (
      <>
        {attachmentsCount} new attachments added to <strong>{spaceName}</strong>
      </>
    );
  }
  return (
    <>
      <strong>{actor}</strong> added a new attachment to{" "}
      <strong>{spaceName}</strong>
    </>
  );
};

const SpaceDownloadedNotificatioan = ({ item }: { item: FeedItem }) => {
  const actor = (item.activities[0].actor as User).name;
  const spaceName = item.activities[0].data.spaceName;
  return (
    <>
      <strong>{actor}</strong> downloaded <strong>{spaceName}</strong>
    </>
  );
};

const SpaceAttachmentDownloadedNotificatioan = ({
  item,
}: {
  item: FeedItem;
}) => {
  const actor = (item.activities[0].actor as User).name;
  const { fileName, spaceName } = item.activities[0].data;
  const attachmentsCount = item.total_activities;
  if (attachmentsCount > 1) {
    return (
      <>
        {attachmentsCount} attachments were downloaded from{" "}
        <strong>{spaceName}</strong>
      </>
    );
  }
  return (
    <>
      <strong>{actor}</strong> downloaded <strong>{fileName}</strong> from{" "}
      <strong>{spaceName}</strong>
    </>
  );
};

const TrackAddedNotification = ({
  item,
}: {
  item: FeedItem<TrackAddedData>;
}) => {
  const actor = item.actors[0] as User;
  const numTracks = item.total_activities;

  if (numTracks === 1) {
    return (
      <>
        <strong>{actor.name || "Someone"}</strong> added a Track to{" "}
        <strong>{item.data.spaceName}</strong>
      </>
    );
  }

  return (
    <>
      <strong>{numTracks}</strong> Tracks added to{" "}
      <strong>{item.data.spaceName}</strong>
    </>
  );
};

const TrackDownloadedNotification = ({
  item,
}: {
  item: FeedItem<TrackDownloadedData>;
}) => {
  const actor = item.actors[0] as User;
  const numTracks = item.total_activities;

  if (numTracks === 1) {
    return (
      <>
        <strong>{actor.name || "Someone"}</strong> downloaded{" "}
        <strong>{item.data.trackName}</strong> from{" "}
        <strong>{item.data.spaceName}</strong>
      </>
    );
  }

  return (
    <>
      <strong>{numTracks}</strong> tracks downloaded from{" "}
      <strong>{item.data.spaceName}</strong>
    </>
  );
};

const TrackVersionAddedNotification = ({
  item,
}: {
  item: FeedItem<TrackVersionAddedData>;
}) => {
  const names = item.actors.map((a: User) => a.name).filter((n) => !!n);

  let name;
  if (names.length === 1) name = names[0];
  else if (names.length === 2) name = `${names[0]} and ${names[1]} `;
  else {
    const rest = names.length - 2;
    name = `${names[0]}, ${names[1]} + ${rest}`;
  }

  return (
    <>
      <strong>{name}</strong> added{" "}
      {item.total_activities === 1
        ? "a version"
        : `${item.total_activities} versions`}{" "}
      to <strong>{item.data.trackName}</strong>
    </>
  );
};

const TrackPlayedNotification = ({
  item,
}: {
  item: FeedItem<TrackPlayedData>;
}) => {
  const actor = item.actors[0] as User;
  const numPlays = item.total_activities;

  if (numPlays === 1) {
    return (
      <>
        <strong>{actor.name || "Someone"}</strong> played{" "}
        <strong>{item.data.trackName}</strong>
        {" in "}
        <strong>{item.data.spaceName}</strong>
      </>
    );
  }

  return (
    <>
      <strong>{numPlays}</strong> plays in{" "}
      <strong>{item.data.spaceName}</strong>
    </>
  );
};

const TrackVersionDownloadedNotification = ({
  item,
}: {
  item: FeedItem<TrackVersionDownloadedData>;
}) => {
  const actor = (item.activities[0].actor as User).name;
  const activityCount = item.total_activities;
  const { fileName, versionNumber, trackName } = item.activities[0].data;
  if (activityCount === 1) {
    return (
      <>
        <strong>{actor}</strong> downloaded{" "}
        <strong>
          V{versionNumber} ({fileName})
        </strong>{" "}
        from <strong>{trackName}</strong>
      </>
    );
  }
  return (
    <>
      {activityCount} versions were downloaded from <strong>{trackName}</strong>
    </>
  );
};

const TrackAttachmentAddedNotification = ({
  item,
}: {
  item: FeedItem<TrackAttachmentAddedData>;
}) => {
  const actor = (item.activities[0].actor as User).name;
  const attachmentsCount = item.total_activities;
  const trackName = item.activities[0].data.trackName;
  if (attachmentsCount > 1) {
    return (
      <>
        {attachmentsCount} new attachments added to <strong>{trackName}</strong>
      </>
    );
  }
  return (
    <>
      <strong>{actor}</strong> added a new attachment to{" "}
      <strong>{trackName}</strong>
    </>
  );
};

const TrackAttachmentDownloadedNotification = ({
  item,
}: {
  item: FeedItem<TrackAttachmentDownloadedData>;
}) => {
  const actor = (item.activities[0].actor as User).name;
  const activityCount = item.total_activities;
  const { fileName, trackName } = item.activities[0].data;
  if (activityCount === 1) {
    return (
      <>
        <strong>{actor}</strong> downloaded <strong>{fileName}</strong> from{" "}
        <strong>{trackName}</strong>
      </>
    );
  }
  return (
    <>
      {activityCount} attachments were downloaded from{" "}
      <strong>{trackName}</strong>
    </>
  );
};

const DownloadLinkReadyNotification = ({
  item,
}: {
  item: FeedItem<DownloadLinkReadyData>;
}) => {
  const { entityName } = item.activities[0].data;
  return (
    <>
      {entityName} is ready to <strong>download</strong>
    </>
  );
};

const DownloadLinkFailedNotification = ({
  item,
}: {
  item: FeedItem<DownloadLinkFailedData>;
}) => {
  const { entityName } = item.activities[0].data;
  return (
    <>
      Retry download for <strong>{entityName}</strong>
    </>
  );
};

const COMPONENT_BY_ID: Record<KNOCK_WORKFLOW, React.FC<{ item: FeedItem }>> = {
  // [EVENT_TYPE.COMMENT_CREATED]: CommentCreatedNotification,
  // [EVENT_TYPE.SPACE_TRACK_CREATED]: SpaceTrackCreatedNotification,
  // [EVENT_TYPE.SPACE_TRACK_PLAYED]: SpaceTrackPlayedNotification,
  // [EVENT_TYPE.SPACE_VIEWED]: SpaceViewedNotification,
  [KNOCK_WORKFLOW.DOWNLOAD_LINK_FAILED]: DownloadLinkFailedNotification,
  [KNOCK_WORKFLOW.DOWNLOAD_LINK_READY]: DownloadLinkReadyNotification,
  [KNOCK_WORKFLOW.TRACK_VERSION_ADDED]: TrackVersionAddedNotification,
  [KNOCK_WORKFLOW.TRACK_VERSION_DOWNLOADED]: TrackVersionDownloadedNotification,
  [KNOCK_WORKFLOW.TRACK_ATTACHMENT_ADDED]: TrackAttachmentAddedNotification,
  [KNOCK_WORKFLOW.TRACK_ATTACHMENT_DOWNLOADED]:
    TrackAttachmentDownloadedNotification,
  [KNOCK_WORKFLOW.TRACK_ADDED]: TrackAddedNotification,
  [KNOCK_WORKFLOW.TRACK_PLAYED]: TrackPlayedNotification,
  [KNOCK_WORKFLOW.TRACK_DOWNLOADED]: TrackDownloadedNotification,
  [KNOCK_WORKFLOW.SPACE_MEMBER_ADDED]: SpaceMemberAddedNotification,
  [KNOCK_WORKFLOW.SPACE_MEMBER_REQUESTED]: SpaceMemberRequestedNotification,
  [KNOCK_WORKFLOW.SPACE_MEMBER_JOINED]: SpaceMemberJoinedNotification,
  [KNOCK_WORKFLOW.CARD_COMMENT_ADDED]: CardCommentAddedNotification,
  [KNOCK_WORKFLOW.CARD_REPLY_ADDED]: CardReplyAddedNotification,
  [KNOCK_WORKFLOW.CARD_RESOLVED]: CardResolvedNotification,
  [KNOCK_WORKFLOW.CHAT_COMMENT_ADDED]: ChatCommentAddedNotification,
  [KNOCK_WORKFLOW.SUBSCRIPTION_UPDATED]: SubscriptionUpdatedNotification,
  [KNOCK_WORKFLOW.SPACE_ITEMS_DOWNLOADED]: SpaceItemsDownloadedNotification,
  [KNOCK_WORKFLOW.SPACE_ATTACHMENT_ADDED]: SpaceAttachmentAddedNotification,
  [KNOCK_WORKFLOW.SPACE_DOWNLOADED]: SpaceDownloadedNotificatioan,
  [KNOCK_WORKFLOW.SPACE_ATTACHMENT_DOWNLOADED]:
    SpaceAttachmentDownloadedNotificatioan,
};

const Notification = ({ item }: { item: FeedItem }) => {
  const history = useHistory();
  const { trackEvent } = useSegmentContext();
  const { markAsRead } = useNotificationsContext();
  const Component = COMPONENT_BY_ID[item.source.key as KNOCK_WORKFLOW];

  if (!Component) return null;

  const clickPath = getItemPath(item);

  return (
    <MenuItem>
      <div
        className="highnote-notification"
        onClick={() => {
          markAsRead([item]);
          history.push(clickPath);

          trackEvent(
            EVENT_ID.RECIPIENT_OPENED_NOTIFICATION,
            {
              properties: {
                knockWorkflowKey: item.source.key,
                channel: "feed",
                recipientId: getAuthId(),
              },
            },
            item.actors[0].id,
          );
        }}
      >
        <div className="icon-wrapper">
          {!item.read_at && <UnreadDot />}
          <div className="icon">
            <ConnectedUserAvatar
              userId={item.actors[0].id}
              size={USER_AVATAR_SIZE.MEDIUM}
            />
          </div>
        </div>
        <div className="inner">
          <Component item={item} />
        </div>
      </div>
    </MenuItem>
  );
};

export const Notifications = () => {
  const { notifications, markAsRead, markAsSeen, filter, setFilter } =
    useNotificationsContext();
  const [isOpen, setOpen] = useState<boolean>(false);
  const unreadRef = useRef<FeedItem[]>([]);
  const ref = useRef<HTMLButtonElement>();
  const id = useRef<string>(uuidv4()).current;

  const unread = useMemo(() => {
    const _unread = notifications.filter((n) => !n.read_at);
    unreadRef.current = _unread;
    return _unread;
  }, [notifications]);

  const notificationsToShow =
    filter === NOTIFICATION_FILTER.UNREAD ? unread : notifications;

  useEffect(() => {
    if (!isOpen) return;
    setFilter(NOTIFICATION_FILTER.UNREAD);
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) return;
    const unseen = unreadRef.current.filter((n) => !n.seen_at);
    if (unseen.length < 1) return;
    markAsSeen(unseen);
  }, [isOpen]);

  const numUnread = unread.length > 99 ? "99+" : unread.length;

  return (
    <ErrorBoundary name="Notifications">
      <ThemeProvider theme={THEME.LIGHT}>
        <div
          className="highnote-notifications"
          data-is-open={isOpen}
          data-has-overflow={unread.length > 99}
        >
          {unread.length > 0 && (
            <UnreadDotV2
              label={`${numUnread} unread notifications`}
              className="highnote-notifications-unread-dot"
            >
              {numUnread}
            </UnreadDotV2>
          )}
          <button
            className="notifications-trigger"
            ref={ref}
            id={id}
            onClick={() => setOpen(true)}
            aria-label="Notifications"
            data-is-open={isOpen}
          >
            <BellSVG />
          </button>
          <Popover
            className="highnote-menu highnote-notifications-menu"
            data-theme={THEME.LIGHT}
            anchorEl={ref.current}
            onClose={() => setOpen(false)}
            open={isOpen}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div className="header">
              <button
                disabled={filter === NOTIFICATION_FILTER.ALL}
                onClick={() => setFilter(NOTIFICATION_FILTER.ALL)}
              >
                All
              </button>
              <span className="divider">|</span>
              <button
                disabled={filter === NOTIFICATION_FILTER.UNREAD}
                onClick={() => setFilter(NOTIFICATION_FILTER.UNREAD)}
              >
                Unread
              </button>
              {unread.length > 0 && (
                <button
                  className="mark-all-read"
                  onClick={() => {
                    markAsRead(unread);
                  }}
                >
                  Mark all as read
                </button>
              )}
            </div>
            <MenuList
              autoFocusItem={isOpen}
              id="composition-menu"
              aria-labelledby={id}
            >
              {notificationsToShow.map((item) => {
                return <Notification key={item.id} item={item} />;
              })}
            </MenuList>

            {filter === NOTIFICATION_FILTER.UNREAD &&
              notificationsToShow.length < 1 && (
                <div className="empty">
                  You&apos;re all caught up!
                  <br />
                  No new activity to report.
                </div>
              )}

            {filter === NOTIFICATION_FILTER.ALL &&
              notificationsToShow.length < 1 && (
                <div className="empty">
                  Welcome to Highnote! No activity to report.
                </div>
              )}
          </Popover>
        </div>
      </ThemeProvider>
    </ErrorBoundary>
  );
};
