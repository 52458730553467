import "./SpaceNotifications.scss";
import React, { useState } from "react";

import { ArrayRemove, ArrayUnion } from "@highnote/server/src/core/entities";
import { DialogSection } from "App/common/Dialog";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { Link } from "react-router-dom";
import { Switch } from "App/core/Switch";
import { highnote } from "@highnote/server/src/sdk";
import { useAuth } from "../Auth";
import { useSpaceEditor } from "../useSpaceEditor";
import { useTheme } from "App/common/ThemeProvider";
import { useToast } from "App/common/useToast";
import { List, ListItemButton } from "App/common/List";

export const SpaceNotifications = () => {
  const { user } = useAuth();
  const { closeSpaceEditor, space } = useSpaceEditor();
  const { theme } = useTheme();
  const { addErrorMessage } = useToast();
  const isSpaceMuted = (user.mutedSpaces || []).includes(space.id);
  const [isMutedOptimistic, setIsMutedOptimistic] = useState(isSpaceMuted);

  const handleMuteOnChange = async (nowMuted: boolean) => {
    try {
      setIsMutedOptimistic(nowMuted);
      await highnote.updateUser({
        id: user.id,
        data: {
          mutedSpaces: nowMuted
            ? (new ArrayUnion([space.id]) as unknown as string[])
            : (new ArrayRemove([space.id]) as unknown as string[]),
        },
      });
    } catch (err) {
      const newState = nowMuted ? "unmute" : "mute";
      setIsMutedOptimistic(!nowMuted);
      addErrorMessage(`Could not ${newState} space. Please try again.`);
    }
  };

  return (
    <ErrorBoundary name="SpaceNotifications">
      <DialogSection
        className="highnote-space-editor-notification"
        data-theme={theme}
      >
        <List>
          <ListItemButton
            className="highnote-space-editor-listbutton"
            onClick={() => {
              handleMuteOnChange(!isMutedOptimistic);
            }}
          >
            <div className="highnote-space-editor-inner">
              <h3>Mute Space</h3>
              <p>
                All notifications for this Space are{" "}
                {isSpaceMuted ? "muted" : "unmuted"}.
              </p>
              <p>
                Manage specific notification preferences from your{" "}
                <Link
                  onClick={(e) => {
                    e.stopPropagation();
                    closeSpaceEditor();
                  }}
                  style={{ textDecoration: "underline" }}
                  to="/settings/notifications"
                >
                  Account Settings
                </Link>
                .
              </p>
            </div>
            <Switch checked={isMutedOptimistic} />
          </ListItemButton>
        </List>
      </DialogSection>
    </ErrorBoundary>
  );
};
