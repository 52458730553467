import React from "react";
import copy from "copy-to-clipboard";
import { isNumber } from "lodash";

import { PERMISSION } from "@highnote/server/src/core/shared-util";
import { highnote } from "@highnote/server/src/sdk";
import { ReactComponent as CheckSVG } from "App/common/icons/check.svg";
import { ReactComponent as CopySVG } from "App/common/icons/copy.svg";
import { ReactComponent as EditSVG } from "App/common/icons/edit.svg";
import { ReactComponent as RetrySVG } from "App/common/icons/retry.svg";
import { ReactComponent as TrashSVG } from "App/common/icons/trash.svg";
import { useAuth } from "App/components/Auth";
import { PermissionTooltip } from "App/components/PermissionTooltip";
import { Action, ActionItem, Actions } from "../Actions";
import { ErrorBoundary } from "../ErrorBoundary";
import { getValidCommentBlock, useCommentContext } from "../useComment";
import { useFocusOnCard } from "../useFocusedCard";
import { useSpaceContext } from "../useSpace";
import { useToast } from "../useToast";
import { COMMENT_BLOCKS } from "./blocks";
import { BUTTON_SIZE } from "App/core/Button";

enum DEFAULT_COMMENT_ACTION {
  TOGGLE_RESOLVE = "toggle-resolve",
  COPY = "copy",
  EDIT = "edit",
  DELETE = "delete",
}

const DEFAULT_COMMENT_ACTIONS = [
  DEFAULT_COMMENT_ACTION.TOGGLE_RESOLVE,
  DEFAULT_COMMENT_ACTION.EDIT,
  DEFAULT_COMMENT_ACTION.COPY,
  DEFAULT_COMMENT_ACTION.DELETE,
];

export const CommentActions = ({
  actions = DEFAULT_COMMENT_ACTIONS,
}: {
  actions?: (DEFAULT_COMMENT_ACTION | Action)[];
}) => {
  const { focusOnCard } = useFocusOnCard();
  const { isPublished, isEditing, setEditing, comment, deleteComment } =
    useCommentContext();
  const { space } = useSpaceContext();
  const { isAllowed } = useAuth();
  const { toasted } = useToast();

  if (!comment?.id) return null;

  const blockData = getValidCommentBlock(comment);
  const commentType = blockData?.type;

  const canEdit = isAllowed(PERMISSION.TO_EDIT_COMMENT_IN_SPACE, {
    space,
    comment,
  });
  const canResolve = isAllowed(PERMISSION.TO_RESOLVE_COMMENT_IN_SPACE, {
    space,
  });
  const canDelete = isAllowed(PERMISSION.TO_DELETE_COMMENT_IN_SPACE, {
    space,
    comment,
  });

  const isEditable = !!COMMENT_BLOCKS[commentType]?.isEditable;
  const filteredActions = actions.filter((a) => {
    if (
      a === DEFAULT_COMMENT_ACTION.EDIT &&
      (isEditing || !canEdit || !isEditable)
    )
      return false;
    if (a === DEFAULT_COMMENT_ACTION.DELETE && !canDelete) return false;
    return true;
  });

  const copyLinkToComment = () => {
    const loc = window.location;
    const baseUrl = comment.trackId
      ? `${loc.origin}/space/${comment.spaceId}/${comment.trackId}?`
      : `${loc.origin}/space/${comment.spaceId}?space-chat&`;
    const commentLink = `${baseUrl}commentId=${comment.id}`;
    toasted({
      promise: new Promise((resolve) => {
        copy(commentLink);
        resolve(undefined);
      }),
      successMessage: "Copied!",
    });
  };

  const toggleResolved = () => {
    highnote.updateComment({
      id: comment.id,
      data: {
        isResolved: !comment.isResolved,
      },
    });
  };

  if (isPublished && !comment.isSaving && filteredActions.length === 0) {
    return null;
  }

  return (
    <ErrorBoundary name="CommentActions">
      <Actions
        buttonProps={{
          size: BUTTON_SIZE.SMALL,
          disabled: isEditing,
        }}
        actions={filteredActions.map((action) => {
          if (action === DEFAULT_COMMENT_ACTION.COPY) {
            return {
              name: "Copy link",
              icon: <CopySVG />,
              onClick: copyLinkToComment,
            };
          }

          if (action === DEFAULT_COMMENT_ACTION.TOGGLE_RESOLVE) {
            const isResolvable =
              !comment?.parentComment && !!comment?.trackId && canResolve;

            if (!isResolvable) return null;

            return {
              name: comment.isResolved ? "Unresolve" : "Resolve",
              icon: comment.isResolved ? <RetrySVG /> : <CheckSVG />,
              Component: ({ action }: { action: Action }) => {
                return (
                  <PermissionTooltip hasPermission={canResolve}>
                    <div>
                      <ActionItem action={action} disabled={!canResolve} />
                    </div>
                  </PermissionTooltip>
                );
              },
              onClick: toggleResolved,
            };
          }

          if (action === DEFAULT_COMMENT_ACTION.EDIT) {
            return {
              name: "Edit",
              icon: <EditSVG />,
              onClick: () => {
                if (isNumber(comment.timestamp)) {
                  focusOnCard(comment.id, undefined, { edit: true });
                } else {
                  setEditing(true);
                }
              },
            };
          }

          if (action === DEFAULT_COMMENT_ACTION.DELETE) {
            return {
              name: "Delete",
              icon: <TrashSVG />,
              onClick: deleteComment,
            };
          }

          return action;
        })}
      />
    </ErrorBoundary>
  );
};
