import React, { useEffect } from "react";

import LoadingScreen from "App/common/LoadingScreen/LoadingScreen";
import { useAuth } from "App/components/Auth";
import { routePaths } from "App/modules/urls";

export const DropboxLogin = () => {
  const { authLoading, user } = useAuth();

  useEffect(() => {
    if (!authLoading && user) {
      const redirectUri = `${window.location.origin}${routePaths.dropboxCallback}`;
      const queryParams = new URLSearchParams({
        client_id: process.env.REACT_APP_DROPBOX_CLIENT_ID,
        redirect_uri: encodeURI(redirectUri),
        response_type: "code",
        state: user.id,
        token_access_type: "offline",
      });
      window.location.href = `https://www.dropbox.com/oauth2/authorize?${queryParams.toString()}`;
    }
  }, [authLoading, user]);

  return <LoadingScreen />;
};
