import React from "react";
import { Actions } from "App/common/Actions";
import { EntityRowConfig } from "./config";

export const EntityActions = ({ row }: { row: EntityRowConfig }) => {
  const actions = row.actions || [];
  if (!actions || (Array.isArray(actions) && actions.length < 1)) return null;

  return (
    <div className="highnote-entity-actions">
      {Array.isArray(actions) ? <Actions actions={actions} /> : actions}
    </div>
  );
};
