import React from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as PlusSVG } from "App/common/icons/plus.svg";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "../../../core/Button";
import { useHighnote } from "App/common/useHighnote";
import styles from "./NoSpaces.module.scss";

export const NoSpaces = () => {
  const history = useHistory();
  const { createSpace } = useHighnote();

  return (
    <div className={styles["highnote-no-spaces"]}>
      <h1>Create a Space ✨</h1>
      <p>
        Spaces are where you talk about tracks with collaborators, or just make
        private notes for yourself.
      </p>
      <Button
        theme={BUTTON_THEME.CTA}
        size={BUTTON_SIZE.MEDIUM}
        onClick={async () => {
          const space = await createSpace();
          history.push(`/space/${space.id}`);
        }}
      >
        <PlusSVG />
        <span>New Space</span>
      </Button>
    </div>
  );
};
