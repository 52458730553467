import React from "react";
import classNames from "classnames";

import { Dialog, DialogButtons, DialogSection } from "App/common/Dialog";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import {
  ConnectedUserAvatar,
  ConnectedUserName,
  USER_AVATAR_SIZE,
} from "App/common/UserAvatar/UserAvatar";
import { Badge } from "App/common/Badge/Badge";
import { FileSelect } from "App/common/FileSelect";
import styles from "./SpaceDialogs.module.scss";

interface PrivateInboxVisitDialogProps {
  onClose?: () => void;
  isOpen: boolean;
  spaceOwner: string;
  onUploadFiles: (files: File[]) => void;
}

export const PrivateInboxVisitDialog = ({
  isOpen,
  onClose,
  spaceOwner,
  onUploadFiles,
}: PrivateInboxVisitDialogProps) => {
  return (
    <Dialog
      open={isOpen}
      className={styles["SpaceOnboard-dialog"]}
      onClose={onClose}
    >
      <DialogSection className={styles["SpaceOnboard-dialog-content"]}>
        <ConnectedUserAvatar
          userId={spaceOwner}
          size={USER_AVATAR_SIZE.XXXLARGE}
        />
        <Badge>Private Inbox</Badge>
        <div className={styles["SpaceOnboard-dialog-content-description"]}>
          <h3>
            Send files to <ConnectedUserName userId={spaceOwner} />
          </h3>
          <p>
            <ConnectedUserName userId={spaceOwner} /> is using Highnote to
            accept files securely.
          </p>
        </div>
      </DialogSection>
      <DialogButtons>
        <FileSelect fullWidth allowMultiple onSelect={onUploadFiles}>
          <Button size={BUTTON_SIZE.MEDIUM} theme={BUTTON_THEME.CTA}>
            Upload Files
          </Button>
        </FileSelect>
      </DialogButtons>
    </Dialog>
  );
};

export const DraftSpaceOnboardDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Dialog
      open={isOpen}
      className={styles["SpaceOnboard-dialog"]}
      onClose={onClose}
    >
      <DialogSection className={styles["SpaceOnboard-dialog-content"]}>
        <Badge>System</Badge>
        <div
          className={classNames(
            styles["SpaceOnboard-dialog-content-description"],
            styles["SpaceOnboard-dialog-content-description-draftSpace"],
          )}
        >
          <h3>Drafts Folder</h3>
          <p>
            This space was automatically created by Highnote as a holding ground
            for misc. content. It can&apos;t be deleted.
          </p>
        </div>
      </DialogSection>
      <DialogButtons className={styles["SpaceOnboard-dialog-buttons"]}>
        <Button
          size={BUTTON_SIZE.MEDIUM}
          theme={BUTTON_THEME.CTA}
          onClick={onClose}
        >
          Okay
        </Button>
      </DialogButtons>
    </Dialog>
  );
};
