import React from "react";
import { useTheme } from "./ThemeProvider";
import styles from "./ScrollableContainer.module.scss";

export enum SCROLLABLE_CONTAINER_VARIANT {
  FRAMED = "framed",
}

export const ScrollableContainer = ({
  variant,
  maxHeight,
  children,
}: {
  variant?: SCROLLABLE_CONTAINER_VARIANT;
  maxHeight: number;
  children: React.ReactNode;
}) => {
  const { theme } = useTheme();

  return (
    <div
      className={styles["highnote-scrollable-container"]}
      data-variant={variant}
      data-theme={theme}
      style={{ maxHeight: `${maxHeight}px` }}
    >
      {children}
    </div>
  );
};
