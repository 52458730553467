// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oRSKQseT3v1lFOWW5K1G{width:56px !important;height:56px !important;border-radius:50% !important}.GAdrnoojgOEzncmlwTNU{justify-content:flex-start;align-items:center;position:unset !important}.GAdrnoojgOEzncmlwTNU .z4WqEq6VPSSOnHt3ozUS{width:100%}.GAdrnoojgOEzncmlwTNU svg{width:18px;height:18px;margin-left:-2px}", "",{"version":3,"sources":["webpack://./src/App/routes/Main/Space/SpacePageActions/SpacePageActions.module.scss"],"names":[],"mappings":"AAEA,sBACE,qBAAA,CACA,sBAAA,CACA,4BAAA,CAGF,sBACE,0BAAA,CACA,kBAAA,CACA,yBAAA,CAEA,4CACE,UAAA,CAGF,0BACE,UAAA,CACA,WAAA,CACA,gBAAA","sourcesContent":["@import \"App/styles/_variables\";\n\n.highnote-space-actions {\n  width: 56px !important;\n  height: 56px !important;\n  border-radius: 50% !important;\n}\n\n.file-select {\n  justify-content: flex-start;\n  align-items: center;\n  position: unset !important;\n\n  .highnote-space-action-item {\n    width: 100%;\n  }\n\n  svg {\n    width: 18px;\n    height: 18px;\n    margin-left: -2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highnote-space-actions": "oRSKQseT3v1lFOWW5K1G",
	"file-select": "GAdrnoojgOEzncmlwTNU",
	"highnote-space-action-item": "z4WqEq6VPSSOnHt3ozUS"
};
export default ___CSS_LOADER_EXPORT___;
