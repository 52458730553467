import React from "react";
import { ReactComponent as CloseSVG } from "App/common/icons/close.svg";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "../core/Button";
import { ProgressBar } from "./ProgressBar";
import { StatusIndicator } from "./StatusIndicator";
import styles from "./CancelableProgress.module.scss";

export const CancelableProgress = ({
  name,
  progress = 0,
  error,
  onCancel,
}: {
  name?: string;
  progress: number;
  error?: string;
  onCancel?: () => void;
}) => {
  const showStatus = !!error || progress === 1;
  const showCancel = !!onCancel && !error && progress !== 1;

  return (
    <div className={styles["highnote-cancelable-progress"]}>
      <div className={styles["info"]}>
        <ProgressBar progress={progress} />
        {error ? (
          <span>Failed to upload {name || "file"}.</span>
        ) : (
          <span>Uploading {name || "file"}...</span>
        )}
      </div>

      <StatusIndicator isLoading={showStatus} errorMessage={error} />

      {showCancel && (
        <Button
          size={BUTTON_SIZE.SMALL}
          theme={BUTTON_THEME.SECONDARY}
          className={styles["cancel"]}
          type="button"
          onClick={onCancel}
        >
          <CloseSVG />
        </Button>
      )}
    </div>
  );
};
