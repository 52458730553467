import "./FileSelect.scss";
import React, { MouseEventHandler, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { ToastMessageContent, useToast } from "./useToast";
import {
  AUDIO_ACCEPT_TYPES,
  AUDIO_ACCEPT_TYPES_STRING,
} from "@highnote/server/src/core/entities";
import classNames from "classnames";

type FileSelectProps = {
  children: React.ReactElement;
  acceptTypes?: string[];
  acceptTypesString?: string;
  onSelect: (files: File[]) => void;
  uploadProgress?: number;
  allowMultiple?: boolean;
  theme?: "light" | "dark";
  disabled?: boolean;
  fullWidth?: boolean;
};

export const FileSelect = ({
  children,
  allowMultiple,
  acceptTypes,
  acceptTypesString,
  onSelect,
  theme = "light",
  disabled = false,
  fullWidth = false,
}: FileSelectProps) => {
  const id = useRef<string>(uuidv4()).current;
  const inputRef = useRef<HTMLInputElement>();
  const { addMessage } = useToast();

  const onFileSelect: React.ChangeEventHandler<HTMLInputElement> = async (
    e,
  ) => {
    const files = Array.from(e.target.files);
    e.target.value = "";

    if (acceptTypes) {
      const invalid = files.find(
        (file) => !acceptTypes.some((type) => file.type === type),
      );

      if (invalid) {
        const toastId = uuidv4();

        console.log("Could not upload file:", files);

        addMessage({
          id: toastId,
          children: (
            <ToastMessageContent
              type="error"
              id={toastId}
              text={`Could not upload ${
                invalid.name
              }. Please upload a valid audio file (${
                acceptTypesString || acceptTypes.join(", ")
              }) instead.`}
            />
          ),
          expireAt: Date.now() + 10000, // 10 seconds from now
        });
        return;
      }
    }

    onSelect(files);
  };

  const onButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (!inputRef.current) return;
    e.preventDefault();
    inputRef.current.click();
  };

  return (
    <div
      className={classNames("file-select", { "full-width": fullWidth })}
      data-theme={theme}
    >
      {React.cloneElement(children, {
        type: "button",
        className: `${children.props.className || ""} file-select-button`,
        disabled: disabled,
        onClick: onButtonClick,
      })}
      <input
        ref={inputRef}
        id={id}
        type="file"
        accept={acceptTypes ? acceptTypes.join(", ") : "*"}
        onChange={onFileSelect}
        multiple={allowMultiple}
      />
    </div>
  );
};

export const AudioFileSelect = (props: FileSelectProps) => {
  return (
    <FileSelect
      {...props}
      acceptTypes={AUDIO_ACCEPT_TYPES}
      acceptTypesString={AUDIO_ACCEPT_TYPES_STRING}
    />
  );
};

export const ImageFileSelect = (props: FileSelectProps) => {
  return (
    <FileSelect
      {...props}
      acceptTypes={[
        "image/png",
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/webm",
        "image/avif",
        "image/webp",
      ]}
      acceptTypesString=".png, .jpg, .gif, .webm, .avif, .webp"
    />
  );
};
