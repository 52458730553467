import React from "react";
import classNames from "classnames";

import styles from "./TopBanner.module.scss";

export const TopBanner = ({
  children,
  className,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <div className={classNames(styles["top-banner"], className)}>
      {children}
    </div>
  );
};
