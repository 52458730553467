import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { ReactComponent as LogoFullSVG } from "App/common/icons-v2/logo-full-beta.svg";
import { LoadingSpinner } from "App/common/icons/LoadingSpinner";
import { highnote } from "@highnote/server/src/sdk";
import { COLLECTION_ID } from "@highnote/server/src/core/entities";
import styles from "./DownloadRequest.module.scss";

export const initiateDownload = (link: string) => {
  const tempLink = document.createElement("a");
  tempLink.href = link;
  tempLink.setAttribute("download", link.split("/").pop());
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
};

export const DownloadRequestPage = () => {
  const params = useParams<{ requestId?: string }>();
  const requestId = params?.requestId;

  const [isFetchingLink, setIsFetchingLink] = useState(true);
  const [downloadLink, setDownloadLink] = useState<string | null>(null);

  useEffect(() => {
    if (!requestId) return;
    setIsFetchingLink(true);
    highnote
      .getFileUrl({
        id: requestId,
        entityType: COLLECTION_ID.DOWNLOAD_REQUEST,
      })
      .then((downloadLink) => {
        initiateDownload(downloadLink);
        setDownloadLink(downloadLink);
      })
      .catch(() => {})
      .finally(() => {
        setIsFetchingLink(false);
      });
  }, [requestId]);

  return (
    <div className={styles.highnoteDownloadRequest}>
      <div className={styles.highnoteDownloadRequestHeader}>
        <Link to="/" data-cypress-id="highnote-logo" className="logo">
          <LogoFullSVG />
        </Link>
        {isFetchingLink ? (
          <LoadingSpinner />
        ) : (
          <img src="/public/highnote-download.png" />
        )}
      </div>
      {!isFetchingLink && (
        <div className={styles.highnoteDownloadRequestBody}>
          {downloadLink ? (
            <>
              <p>Your download should begin shortly.</p>
              <p>
                If it doesn&apos;t,{" "}
                <a href={downloadLink} rel="noreferrer" target="_blank">
                  click here
                </a>
              </p>
            </>
          ) : (
            <p>The download link is either expired or invalid.</p>
          )}
        </div>
      )}
    </div>
  );
};
