import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

const ViewportContext = createContext({
  vw: 0,
  vh: 0,
  isMobile: false,
  isMediumScreenSize: false,
  isLargeScreenSize: false,
});

export const ViewportContextProvider = ({ children }) => {
  const [vh, setVh] = useState();
  const [vw, setVw] = useState();

  useEffect(() => {
    let unmounted;

    const onResize = () => {
      if (unmounted) return;
      const newVh = window.innerHeight;
      const newVw = window.innerWidth;

      if (newVh !== vh) setVh(window.innerHeight);
      if (newVw !== vw) setVw(window.innerWidth);
    };

    onResize();
    window.addEventListener("resize", onResize);

    return () => {
      unmounted = true;
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const value = useMemo(
    () => ({
      vw,
      vh,
      // Screen size values below are based on _variables.scss screen width breakpoints
      isMobile: vw < 450,
      isMediumScreenSize: vw >= 450 && vw < 768,
      isLargeScreenSize: vw >= 768 && vw < 1024,
    }),
    [vw, vh],
  );

  return (
    <ViewportContext.Provider value={value}>
      {children}
    </ViewportContext.Provider>
  );
};

export const useViewport = () => useContext(ViewportContext);
