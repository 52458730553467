import React, { useEffect } from "react";
import LoadingScreen from "App/common/LoadingScreen/LoadingScreen";
import { highnote } from "@highnote/server/src/sdk";
import { useHistory } from "react-router";
import { getAuthUrl } from "App/components/Auth";

export const LogOut = () => {
  const history = useHistory();

  useEffect(() => {
    highnote.__firebaseAuth
      .signOut()
      .then(() => {
        console.log("LOG OUT SUCCESS");
        history.push(getAuthUrl());
      })
      .catch((e) => {
        console.log("COULD NOT LOG OUT", e);
      });
  }, []);

  return <LoadingScreen />;
};
