import React from "react";
import classNames from "classnames";

import { MenuItem } from "App/common/Menu";
import { Select } from "App/common/Select";
import { SORT_TYPE } from "App/routes/Main/Library/config";
import styles from "./EntitySortMenu.module.scss";

export const EntitySortMenu = ({
  sortType = SORT_TYPE.CUSTOM,
  onChange,
  className,
}: {
  sortType: SORT_TYPE;
  onChange: (sortType: SORT_TYPE) => void;
  className?: React.HTMLAttributes<HTMLDivElement>["className"];
}) => {
  return (
    <span className={styles["highnote-entity-sort-select"]}>
      <Select
        className={(classNames(styles["sort-select"]), className)}
        MenuProps={{
          className: "highnote-sort-menu",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }}
        value={sortType}
        size="x-small"
        noPadding
        onChange={(e) => onChange(e.target.value as SORT_TYPE)}
      >
        {[SORT_TYPE.ALPHABETICAL, SORT_TYPE.CREATED_AT, SORT_TYPE.CUSTOM].map(
          (type) => {
            return (
              <MenuItem key={`sort-${type}`} value={type}>
                <span className="title">{type}</span>
              </MenuItem>
            );
          },
        )}
      </Select>
    </span>
  );
};
