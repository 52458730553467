import React from "react";
import { Entity } from "@highnote/server/src/core/entities";
import { ENTITY_TYPE, EntityRowConfig } from "./config";
import moment from "moment";
import styles from "./EntityCreatedAt.module.scss";

export const EntityCreatedAt = ({ row }: { row: EntityRowConfig }) => {
  const entity = row.entity as Entity;
  let verb = "created";

  if (row.type === ENTITY_TYPE.FILE) {
    verb = "uploaded";
  }

  return (
    <div className={styles["highnote-entity-created-at"]}>
      {verb} {moment(entity.createdAt).fromNow()}
    </div>
  );
};
