import "./StorageUsageEditor.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as DeleteSVG } from "App/common/icons/trash.svg";
import { DEFAULT_COLUMNS, EntityTable } from "App/components/EntityTable";
import { ENTITY_TYPE } from "App/components/EntityTable/config";
import { highnote } from "@highnote/server/src/sdk";
import { FileEntity } from "@highnote/server/src/core/entities";
import { useConfirmation } from "App/common/useConfirmation";
import { useToast } from "App/common/useToast";
import { FileReferences } from "./FileReferences";
import { FileListSkeleton } from "./FileListSkeleton";

const defaultColumns = [
  DEFAULT_COLUMNS.PREVIEW,
  DEFAULT_COLUMNS.CREATED_AT,
  DEFAULT_COLUMNS.ACTIONS,
];

export const ManageStorage = () => {
  const { toasted } = useToast();
  const [loading, setLoading] = useState<boolean>(true);
  const [renderTrigger, setRenderTrigger] = useState<string>();
  const [files, setFiles] = useState<FileEntity[]>([]);
  const { confirm, renderConfirmation } = useConfirmation();

  useEffect(() => {
    let unmounted: boolean;
    highnote.getStorageFiles().then((files) => {
      if (unmounted) return;
      setFiles(files);
      setLoading(false);
    });

    return () => {
      unmounted = true;
    };
  }, [renderTrigger]);

  const deleteFile = useCallback(async (file: FileEntity) => {
    const references = await highnote.getFileReferences({
      id: file.id,
    });

    await confirm({
      title: `Delete ${file.fileName}?`,
      body: (
        <p>
          <FileReferences file={file} references={references} />
          <br />
          <br />
          <strong>
            Are you sure you want to permanently delete this file?
          </strong>{" "}
          Deleting this file may cause related items to break.
        </p>
      ),
    });

    toasted({
      promise: highnote
        .deleteStorageFile({
          storagePath: file.storagePath,
        })
        .then(() => {
          setRenderTrigger(uuidv4());
        }),
      createMessage: `Deleting ${file.fileName}...`,
      successMessage: `${file.fileName} permanently deleted.`,
      errorMessage: `Could not delete ${file.fileName}. Please try again.`,
    });
  }, []);

  const rows = useMemo(() => {
    return files
      .sort((a, b) => b.size - a.size)
      .map((entity) => {
        const fileName = entity.fileName || "Untitled";
        const cleanEntity = { ...entity, fileName };
        return {
          id: entity.id,
          entity: cleanEntity,
          type: ENTITY_TYPE.FILE,
          actions: [
            {
              name: "Delete File",
              icon: <DeleteSVG />,
              warn: true,
              onClick: async () => {
                deleteFile(cleanEntity);
              },
            },
          ],
        };
      });
  }, [files, deleteFile]);

  if (loading) {
    return <FileListSkeleton />;
  }

  return (
    <>
      {renderConfirmation}
      {rows.length > 0 ? (
        <EntityTable rows={rows} columns={defaultColumns} />
      ) : (
        <p>No files found.</p>
      )}
    </>
  );
};
