import { ErrorBoundary } from "App/common/ErrorBoundary";
import { ReactComponent as DownloadSVG } from "App/common/icons/download.svg";
import { ReactComponent as PlusSVG } from "App/common/icons/plus.svg";
import { useAuth } from "App/components/Auth";
import React, { useMemo } from "react";
import "./TrackVersions.scss";

import {
  FileEntity,
  KNOCK_WORKFLOW,
  SUBSCRIPTION_TIER,
  Space,
} from "@highnote/server/src/core/entities";
import {
  getDefaultVersionId,
  getPinnedVersionsLimit,
  PERMISSION,
} from "@highnote/server/src/core/shared-util";
import { highnote } from "@highnote/server/src/sdk";
import { DialogButtons, DialogInfo, DialogSection } from "App/common/Dialog";
import { ListContainer } from "App/common/List";
import { useFileDownload } from "App/common/useFileDownload";
import { useToast } from "App/common/useToast";
import {
  DEFAULT_COLUMNS,
  EntityRowConfig,
  EntityTable,
} from "App/components/EntityTable";
import { PermissionTooltip } from "App/components/PermissionTooltip";
import { TrackVersionActions } from "App/components/TrackEditor/TrackVersions/TrackVersionActions";
import { UPLOAD_GROUP, useFiles } from "App/components/useFiles";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { ENTITY_TYPE, Version } from "../../EntityTable/config";
import { useTrack } from "../../useEntities/useTrack";
import { AddVersionButton } from "./AddVersionButton";
import { LoadingSpinner } from "App/common/icons/LoadingSpinner";
import { PinnedVersionCallout } from "App/components/Callouts/PinnedVersionCallout/PinnedVersionCallout";

//This should be a number from 0-4 to match with the SCSS mixin `version-color`.
export const DEFAULT_VERSION_COLOR_ID = 0;

export const TrackVersions = ({ space }: { space?: Space }) => {
  const { isAllowed, user } = useAuth();
  const { toasted } = useToast();
  const {
    track,
    trackArtworkUrl,
    trackVersions,
    pinnedTrackVersions,
    setPinnedTrackVersions,
  } = useTrack();
  const { downloadFiles, isDownloading } = useFileDownload();
  const { getUploadCache, getUploads } = useFiles();
  const isUserSpaceOwner = space?.createdBy === user?.id;
  const isSpaceFreeSubscription =
    space?.subscriptionTier === SUBSCRIPTION_TIER.FREE;
  const maxPinnedVersions = getPinnedVersionsLimit(space?.subscriptionTier);

  // Listen to uploads
  const uploadCachePinned = getUploadCache(
    UPLOAD_GROUP.TRACK_VERSIONS_PINNED,
    track?.id,
  );
  const uploadCacheUnpinned = getUploadCache(
    UPLOAD_GROUP.TRACK_VERSIONS_UNPINNED,
    track?.id,
  );
  const uploadsPinned = getUploads({ cache: uploadCachePinned });
  const uploadsUnpinned = getUploads({ cache: uploadCacheUnpinned });

  const TRACK_VERSION_COLUMNS = useMemo(() => {
    return [
      DEFAULT_COLUMNS.PREVIEW,
      DEFAULT_COLUMNS.SHORTCUT,
      DEFAULT_COLUMNS.ACTIONS,
    ];
  }, []);

  const canAddToTrack = isAllowed(PERMISSION.TO_ADD_TO_TRACK_IN_SPACE, {
    space,
    track,
  });

  const canAddPinnedVersion =
    canAddToTrack &&
    isAllowed(PERMISSION.TO_ADD_PINNED_TRACK_VERSION, {
      track,
      space,
    }) &&
    pinnedTrackVersions.length < maxPinnedVersions;

  const canDownload = isAllowed(PERMISSION.TO_DOWNLOAD_TRACK_IN_SPACE, {
    track,
    space,
  });

  const versionRows = useMemo(() => {
    const pinned: EntityRowConfig[] = [
      ...uploadsPinned.map((upload) => ({
        id: upload.file.id,
        entity: upload,
        type: ENTITY_TYPE.UPLOAD,
      })),
    ];

    const unpinned: EntityRowConfig[] = [
      ...uploadsUnpinned.map((upload) => ({
        id: upload.file.id,
        entity: upload,
        type: ENTITY_TYPE.UPLOAD,
      })),
    ];

    const defaultVersionId = getDefaultVersionId(track);
    const unpinnedVersions = trackVersions.filter(
      (v) => !pinnedTrackVersions.includes(v.id),
    );
    const pinnedVersions = pinnedTrackVersions.map((id) =>
      trackVersions.find((v) => v.id === id),
    );

    const getVersionRow = (version: FileEntity) => {
      const isDefaultVersion = defaultVersionId === version.id;
      return {
        id: version.id,
        entity: {
          ...version,
          index: track.versionFilesV2.indexOf(version.id),
          isDefaultVersion,
          isVersion: true,
        } as Version,
        type: ENTITY_TYPE.FILE,
        actions: <TrackVersionActions version={version} />,
      } as EntityRowConfig;
    };

    [...pinnedVersions].reverse().forEach((v) => pinned.push(getVersionRow(v)));
    [...unpinnedVersions]
      .reverse()
      .forEach((v) => unpinned.push(getVersionRow(v)));

    return { pinned, unpinned };
  }, [
    track,
    trackVersions,
    pinnedTrackVersions,
    uploadsPinned,
    uploadsUnpinned,
  ]);

  const onMoveEnd = (newList: EntityRowConfig[]) => {
    const newPinnedOrder = newList
      .map((row) => trackVersions.find((v) => v.id === row.id))
      .filter((v) => !!v)
      .map((v) => v.id)
      .reverse();
    setPinnedTrackVersions(newPinnedOrder);

    toasted({
      promise: highnote.updateTrack({
        id: track.id,
        data: {
          pinnedVersionFiles: newPinnedOrder,
        },
      }),
      ErrorContent: ({ error }: { error: Error }) => (
        <>Could not save pinned version order. {error.message}</>
      ),
    });

    // TODO:
    // On catch, reset the order to the last cached order.
  };

  const handleDownloadAll = async (allVersions: FileEntity[]) => {
    const versionsToDownload = allVersions.filter((version) =>
      Boolean(version.storagePath),
    );
    await downloadFiles(
      track.id,
      versionsToDownload.map((version) => version.id),
    );
    highnote.notify({
      workflowId: KNOCK_WORKFLOW.TRACK_VERSION_DOWNLOADED,
      data: {
        files: allVersions.map((version, i) => ({
          fileId: version.id,
          fileName: version.name,
          versionNumber: i + 1,
        })),
        spaceId: space.id,
        spaceName: space.name,
        trackId: track.id,
        trackName: track.title,
        trackArtworkUrl: trackArtworkUrl,
      },
    });
  };

  return (
    <ErrorBoundary name="TrackVersions">
      <div className={`highnote-track-versions`}>
        <DialogSection>
          <header className="section-header">
            <div className="inner">
              <div className="section-title">Pinned Versions</div>
              <div className="section-subtitle">
                These will be visible on your player for easy access.
                <br />
                Limited to {maxPinnedVersions} versions.
              </div>
            </div>
            <AddVersionButton
              space={space}
              disabled={!canAddPinnedVersion}
              pinOnSave
              multiple
              className="add-version"
              theme={BUTTON_THEME.SECONDARY}
              size={BUTTON_SIZE.SMALL}
            >
              <PlusSVG />
            </AddVersionButton>
          </header>
          <ListContainer className="pinned-versions">
            <EntityTable
              rows={versionRows.pinned}
              columns={TRACK_VERSION_COLUMNS}
              onDragEnd={canAddToTrack && onMoveEnd}
              showDragHandle={canAddToTrack}
            />
          </ListContainer>
          {isSpaceFreeSubscription && isUserSpaceOwner && (
            <PinnedVersionCallout className="pinned-version-callout" />
          )}
        </DialogSection>

        <DialogSection>
          <header className="section-header">
            <div className="inner">
              <div className="section-title">Other Versions</div>
              <div className="section-subtitle">
                Additional versions attached to this track.
              </div>
            </div>
            <AddVersionButton
              space={space}
              disabled={!canAddToTrack}
              multiple
              className="add-version"
              theme={BUTTON_THEME.SECONDARY}
              size={BUTTON_SIZE.SMALL}
            >
              <PlusSVG />
            </AddVersionButton>
          </header>
          <ListContainer className="unpinned-versions">
            <EntityTable
              rows={versionRows.unpinned}
              columns={TRACK_VERSION_COLUMNS}
              EmptyComponent={() => <div className="empty-unpinned"></div>}
            />
          </ListContainer>
        </DialogSection>

        <DialogButtons>
          <PermissionTooltip hasPermission={canDownload}>
            <Button
              className="download-all"
              theme={BUTTON_THEME.SECONDARY}
              disabled={
                isDownloading || trackVersions.length < 1 || !canDownload
              }
              onClick={() => handleDownloadAll(trackVersions)}
            >
              <div className="icon-container">
                {isDownloading ? <LoadingSpinner /> : <DownloadSVG />}
              </div>
              <span>Download All</span>
            </Button>
          </PermissionTooltip>
        </DialogButtons>
      </div>

      <DialogInfo>Supported file types: WAV, MP3, MP4, OGG, AIF</DialogInfo>
    </ErrorBoundary>
  );
};
