import "./PlanLimits.scss";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, BUTTON_THEME } from "App/core/Button";
import { DialogButtons, Dialog, DialogSection } from "../Dialog";
import { useAuth } from "App/components/Auth";
import {
  USAGE_EDITOR_TAB,
  USAGE_EDITOR_TAB_KEY,
} from "App/components/Usages/UsageEditor";
import { Link, useHistory } from "react-router-dom";
import { parseSubscription } from "@highnote/server/src/core/shared-util";
import { PLANS } from "App/components/Plans/config";
import { PlanPicker } from "App/components/Plans/Plans";
import {
  SUBSCRIPTION_PRICING,
  SUBSCRIPTION_TIER,
} from "@highnote/server/src/core/entities";
import {
  APP_FEATURES,
  baseUserFeatureGenerators,
} from "@highnote/server/src/core/features";
import { highnote } from "@highnote/server/src/sdk";

export enum LIMIT_TYPE {
  STORAGE = "storage",
  SPACES = "spaces",
  TRACKS = "tracks",
  BLOCKING_TRACKS = "blocking_tracks",
}

const limitTypeCTABtnLabelMap = {
  [LIMIT_TYPE.STORAGE]: "Upgrade",
  [LIMIT_TYPE.SPACES]: "Upgrade",
  [LIMIT_TYPE.TRACKS]: "Upgrade",
  [LIMIT_TYPE.BLOCKING_TRACKS]: "Upgrade for $5/mo",
};
const limitTypeManageBtnLabelMap = {
  [LIMIT_TYPE.STORAGE]: "Manage Storage",
  [LIMIT_TYPE.SPACES]: "Manage Spaces",
  [LIMIT_TYPE.TRACKS]: "Manage Tracks",
  [LIMIT_TYPE.BLOCKING_TRACKS]: "Delete Tracks",
};

const limitTypeEditorTabMap = {
  [LIMIT_TYPE.STORAGE]: USAGE_EDITOR_TAB.STORAGE,
  [LIMIT_TYPE.SPACES]: USAGE_EDITOR_TAB.SPACES,
  [LIMIT_TYPE.TRACKS]: USAGE_EDITOR_TAB.TRACK_VERSIONS,
  [LIMIT_TYPE.BLOCKING_TRACKS]: USAGE_EDITOR_TAB.TRACK_VERSIONS,
};

type PlanLimitsContextValue = {
  title: string;
  subtitle: string;
  hasReachedStorageLimit: boolean;
  hasReachedSpaceLimit: boolean;
  hasReachedTrackVersionsLimit: boolean;
  showPlanLimitsDialog: (type?: LIMIT_TYPE) => void;
  showPlanPickerDialog: () => void;
  isDialogOpen: boolean;
  limitType?: LIMIT_TYPE;
};

const PlanLimitsContext = createContext<PlanLimitsContextValue>({
  title: "",
  subtitle: "",
  hasReachedStorageLimit: false,
  hasReachedSpaceLimit: false,
  hasReachedTrackVersionsLimit: false,
  showPlanLimitsDialog: () => {},
  showPlanPickerDialog: () => {},
  isDialogOpen: false,
  limitType: undefined,
});

export const PlanLimitsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [limitTypeToShow, setLimitTypeToShow] = useState<LIMIT_TYPE>(
    LIMIT_TYPE.TRACKS,
  );
  const [isPlanPickerOpen, setPlanPickerOpen] = useState<boolean>(false);
  const {
    storageLimit,
    activeSpacesLimit,
    trackVersionsLimit,
    user,
    userMetadata,
  } = useAuth();
  const { tier } = parseSubscription(user?.subscriptions?.active);
  const plan = PLANS.find((p) => p.tier === tier);

  useEffect(() => {
    if (!user) return;

    const feature = APP_FEATURES.BLOCKING_TRACKS_DIALOG;
    const base = baseUserFeatureGenerators[feature](user);
    if (
      !base &&
      isDialogOpen &&
      limitTypeToShow === LIMIT_TYPE.BLOCKING_TRACKS
    ) {
      setDialogOpen(false);
      setLimitTypeToShow(LIMIT_TYPE.TRACKS);
      return;
    }
    if (base && userMetadata.features[feature]) {
      // this is to ensure that TrackVersionsUsage dialog is closed when the user
      // refreshes the page while the dialog is open
      const searchParams = new URLSearchParams(window.location.search);
      if (
        limitTypeToShow !== LIMIT_TYPE.BLOCKING_TRACKS &&
        searchParams.get(USAGE_EDITOR_TAB_KEY)
      ) {
        searchParams.delete(USAGE_EDITOR_TAB_KEY);
        history.replace(
          `${window.location.pathname}?${searchParams.toString()}`,
        );
      }
      setDialogOpen(true);
      setLimitTypeToShow(LIMIT_TYPE.BLOCKING_TRACKS);
      return;
    }
  }, [
    isDialogOpen,
    limitTypeToShow,
    user?.subscriptionTier,
    user?.trackVersionsUsed,
    userMetadata,
  ]);

  const value = useMemo(() => {
    const { storageUsed, activeSpacesUsed, trackVersionsUsed } = user || {
      storageUsed: 0,
      spacesUsed: 0,
    };

    const hasReachedStorageLimit = storageUsed >= storageLimit;
    const hasReachedSpaceLimit = activeSpacesUsed >= activeSpacesLimit;
    const hasReachedTrackVersionsLimit =
      trackVersionsUsed >= trackVersionsLimit;

    return {
      title: "",
      subtitle: "",
      hasReachedStorageLimit,
      hasReachedSpaceLimit,
      hasReachedTrackVersionsLimit,
      showPlanLimitsDialog: (typeToShow: LIMIT_TYPE) => {
        setLimitTypeToShow(typeToShow);
        setDialogOpen(true);
      },
      showPlanPickerDialog: () => setPlanPickerOpen(true),
    };
  }, [user, storageLimit, activeSpacesLimit, plan?.name, trackVersionsLimit]);

  const memoizedLimitType = useMemo(() => {
    if (value.hasReachedTrackVersionsLimit) {
      return LIMIT_TYPE.TRACKS;
    }

    if (value.hasReachedSpaceLimit) {
      return LIMIT_TYPE.SPACES;
    }

    return LIMIT_TYPE.STORAGE;
  }, [value]);

  const storageLimitValue = useMemo(() => {
    // gets normalized storage limit
    const isProd = process.env.DEPLOY_ENV === "production";

    if (isProd) {
      if (tier === SUBSCRIPTION_TIER.STUDIO) return "5TB";
      if (tier === SUBSCRIPTION_TIER.PRO) return "1TB";
      if (tier === SUBSCRIPTION_TIER.INDIE) return "200GB";
      if (tier === SUBSCRIPTION_TIER.FREE) return "50GB";
    }

    if (tier === SUBSCRIPTION_TIER.FREE) return "10MB";
    if (tier === SUBSCRIPTION_TIER.INDIE) return "15MB";
    if (tier === SUBSCRIPTION_TIER.PRO) return "20MB";
  }, [tier]);

  let limitType = memoizedLimitType;

  if (limitTypeToShow) limitType = limitTypeToShow;

  value.title = useMemo(() => {
    switch (limitType) {
      case LIMIT_TYPE.SPACES:
        return "Upgrade to share more Spaces";
      case LIMIT_TYPE.TRACKS:
        return "Upgrade for more tracks";
      case LIMIT_TYPE.BLOCKING_TRACKS:
        return "Action Required";
      case LIMIT_TYPE.STORAGE:
        return "Upgrade for more storage";
    }
  }, [limitType]);

  value.subtitle = useMemo(() => {
    const suffix = tier === SUBSCRIPTION_TIER.FREE ? " free" : "";
    if (limitType === LIMIT_TYPE.TRACKS) {
      return `You’ve used your max ${trackVersionsLimit}${suffix} tracks.`;
    }
    if (limitType === LIMIT_TYPE.BLOCKING_TRACKS) {
      return "Upgrade for UNLIMITED tracks or remove unused tracks to continue.";
    }
    if (limitType === LIMIT_TYPE.STORAGE) {
      return `You’ve used your max ${storageLimitValue} of${suffix} storage.`;
    }
    if (limitType === LIMIT_TYPE.SPACES) {
      return `You’ve used your ${activeSpacesLimit}${suffix} shared Space`;
    }
  }, [
    tier,
    limitType,
    trackVersionsLimit,
    storageLimitValue,
    activeSpacesLimit,
  ]);

  const handleUpgradeClick = async () => {
    if (limitType === LIMIT_TYPE.BLOCKING_TRACKS) {
      setIsLoading(true);
      try {
        const { checkoutUrl } = await highnote.getCheckoutUrl({
          priceId: SUBSCRIPTION_PRICING[SUBSCRIPTION_TIER.STUDIO].month,
          source: window.location.pathname,
        });
        window.location.href = checkoutUrl;
      } catch (e) {
        setIsLoading(true);
      }
      return;
    }
    setPlanPickerOpen(true);
    setDialogOpen(false);
  };

  const handleDialogClose = () => {
    if (limitType === LIMIT_TYPE.BLOCKING_TRACKS) {
      return;
    }
    setDialogOpen(false);
  };

  return (
    <PlanLimitsContext.Provider
      value={{
        ...value,
        isDialogOpen,
        ...(isDialogOpen && { limitType }),
      }}
    >
      {children}

      <Dialog
        className="PlanPicker-dialog"
        title={"Upgrade Now"}
        open={isPlanPickerOpen}
        onClose={() => setPlanPickerOpen(false)}
      >
        <PlanPicker />
      </Dialog>

      <Dialog
        className="PlanLimits-dialog"
        open={isDialogOpen}
        onClose={handleDialogClose}
      >
        <div className="PlanLimits-dialog-hero" />
        <DialogSection>
          <p className="heading">
            <strong>{value.title}</strong>
          </p>
          <p>{value.subtitle}</p>
        </DialogSection>
        <DialogButtons>
          <Button
            loading={isLoading}
            theme={BUTTON_THEME.CTA}
            onClick={handleUpgradeClick}
          >
            {limitTypeCTABtnLabelMap[limitType]}
          </Button>
          {Object.values(LIMIT_TYPE).map((type) => {
            if (type !== limitType) {
              return null;
            }
            return (
              <Link
                key={type}
                to={`${location.pathname}?${USAGE_EDITOR_TAB_KEY}=${limitTypeEditorTabMap[type]}`}
              >
                <Button
                  loading={isLoading}
                  theme={BUTTON_THEME.SECONDARY}
                  onClick={handleDialogClose}
                >
                  {limitTypeManageBtnLabelMap[type]}
                </Button>
              </Link>
            );
          })}
        </DialogButtons>
      </Dialog>
    </PlanLimitsContext.Provider>
  );
};

export const usePlanLimitsContext = () => useContext(PlanLimitsContext);
