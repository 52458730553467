import React, { useMemo } from "react";
import { ReactComponent as ArchiveSVG } from "App/common/icons/archive.svg";
import { ReactComponent as DeleteSVG } from "App/common/icons/trash.svg";
import { DEFAULT_COLUMNS, EntityTable } from "App/components/EntityTable";
import { ReactComponent as ShareSVG } from "App/common/icons/share-arrow.svg";
import { ENTITY_TYPE } from "App/components/EntityTable/config";
import { highnote } from "@highnote/server/src/sdk";
import {
  Space,
  Track,
  PUBLIC_ID,
  COLLECTION_ID,
} from "@highnote/server/src/core/entities";
import { useConfirmation } from "App/common/useConfirmation";
import { useToast } from "App/common/useToast";
import { useLibrarySpaces } from "App/components/useEntities/useSpaces";
import { useLibraryTracks } from "App/components/useEntities/useLibraryTracks";
import { useHighnote } from "App/common/useHighnote";
import { useAuth } from "App/components/Auth";
import { isActiveEntity } from "@highnote/server/src/core/shared-util";
import { useShareDialog } from "App/components/ShareDialog";

const defaultColumns = [DEFAULT_COLUMNS.PREVIEW, DEFAULT_COLUMNS.ACTIONS];

// TODO: Remove dependence on useLibrarySpaces + useLibraryTracks
// Fetch data directly from firebase.
export const ManageSpaces = () => {
  const { openShareDialog, renderShareDialog } = useShareDialog();
  const { user } = useAuth();
  const { librarySpaces, librarySpacesLoading } = useLibrarySpaces();
  const { libraryTracks } = useLibraryTracks();
  const { moveToSpace } = useHighnote();
  const { toasted } = useToast();
  const { confirm, renderConfirmation } = useConfirmation();

  const archiveSpace = (space: Space) => {
    const verbRoot = space.isArchived ? "Unarchiv" : "Archiv";
    toasted({
      promise: highnote.updateSpace({
        id: space.id,
        data: { isArchived: !space.isArchived },
      }),
      createMessage: `${verbRoot}ing ${space.name}...`,
      errorMessage: `Could not ${verbRoot.toLowerCase()}e ${
        space.name
      }. Please try again.`,
      successMessage: `${verbRoot}ed ${space.name}.`,
    });
  };

  const deleteSpace = async (space: Space, tracks: Track[]) => {
    try {
      const numPeople = Object.keys(space.rolesV2 || {}).filter(
        (r) => r !== PUBLIC_ID,
      ).length;
      const numTracks = tracks.length;
      const childSpaces = librarySpaces.filter(
        (librarySpace) => librarySpace.spaceId === space.id,
      );
      const numChildSpaces = childSpaces.length;

      await confirm({
        title: `Delete ${space.name}?`,
        body: (
          <p>
            This Space is shared with {numPeople}{" "}
            {numPeople === 1 ? "person" : "people"} and contains {numTracks}{" "}
            {numTracks === 1 ? "track" : "tracks"} and {numChildSpaces}{" "}
            {numChildSpaces === 1 ? "space" : "spaces"}.
            <br />
            <br />
            <strong>Are you sure you want to delete {space.name}?</strong> This
            cannot be undone.
          </p>
        ),
      });

      await toasted({
        // eslint-disable-next-line no-async-promise-executor
        promise: new Promise(async (resolve) => {
          const tracks = libraryTracks.filter((t) => t.spaceId === space.id);

          // Remove all tracks and child spaces from the space before deleting
          await Promise.all([
            tracks.map((track) =>
              moveToSpace({
                entityId: track.id,
                entityType: COLLECTION_ID.TRACK,
                newSpaceId: undefined,
              }),
            ),
            childSpaces.map((childSpace) =>
              moveToSpace({
                entityId: childSpace.id,
                entityType: COLLECTION_ID.SPACE,
                newSpaceId: undefined,
              }),
            ),
          ]);
          await highnote.deleteSpace({ id: space.id });
          resolve(undefined);
        }),
        createMessage: `Deleting Space ${space.name}...`,
        successMessage: `Space ${space.name} deleted.`,
        errorMessage: `Could not delete Space ${space.name}. Please try again.`,
      });
    } catch (e) {
      // empty
    }
  };

  const rows = useMemo(() => {
    return librarySpaces
      .filter((space) => {
        return isActiveEntity(space) && space.createdBy === user.id;
      })
      .map((space) => {
        return {
          key: space.id,
          id: space.id,
          entity: space,
          type: ENTITY_TYPE.SPACE,
          actions: [
            {
              name: "Manage Sharing",
              icon: <ShareSVG />,
              onClick: () => openShareDialog(space),
            },
            {
              name: space.isArchived ? "Unarchive" : "Archive",
              icon: <ArchiveSVG />,
              onClick: () => archiveSpace(space),
            },
            {
              name: "Delete Space",
              icon: <DeleteSVG />,
              onClick: () =>
                deleteSpace(
                  space,
                  libraryTracks.filter((t) => t.spaceId === space.id),
                ),
              warn: true,
            },
          ],
          createdAt: space.createdAt,
          name: space.name,
        };
      });
  }, [librarySpaces, libraryTracks, deleteSpace, archiveSpace]);

  if (librarySpacesLoading) {
    return (
      <>
        {Array(5)
          .fill(null)
          .map((_, i) => {
            return (
              <div key={i} className="skeleton">
                <div className="skeleton-box" />
                <ul className="skeleon-list-wrapper">
                  <div className="skeleton-box" />
                  <div className="skeleton-box" />
                </ul>
                <div className="skeleton-box skeleton-timestamp" />
              </div>
            );
          })}
      </>
    );
  }

  return (
    <>
      {renderShareDialog}
      {renderConfirmation}
      {rows.length > 0 ? (
        <EntityTable rows={rows} columns={defaultColumns} />
      ) : (
        <p>No spaces found.</p>
      )}
    </>
  );
};
