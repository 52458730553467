import "./StorageUsage.scss";
import React from "react";
import { LinearProgress } from "@mui/material";
import { BUTTON_SIZE, BUTTON_THEME, Button } from "App/core/Button";
import { useHistory } from "react-router";
import { PLANS } from "../Plans/config";
import { parseSubscription } from "@highnote/server/src/core/shared-util";
import { useAuth } from "../Auth";

export const TrackVersionsUsage = () => {
  const history = useHistory();
  const { user, trackVersionsLimit } = useAuth();
  const isLimited = trackVersionsLimit < Infinity;
  const { trackVersionsUsed, subscriptions } = user;
  const { tier } = parseSubscription(subscriptions.active);
  const plan = PLANS.find((p) => p.tier === tier);

  return (
    <div className="highnote-storage-usage">
      {isLimited && (
        <div className="storage-bar">
          <LinearProgress
            variant="determinate"
            value={Math.min(
              (trackVersionsUsed / trackVersionsLimit) * 100,
              100,
            )}
          />
        </div>
      )}

      <p className="hint">
        {`You are using ${trackVersionsUsed} out of ${
          isLimited ? trackVersionsLimit : "unlimited"
        } tracks allowed on your `}
        <Button
          theme={BUTTON_THEME.LINK}
          size={BUTTON_SIZE.AUTO}
          type="button"
          onClick={() => history.push(`/settings/plans`)}
        >
          {plan.name} Plan
        </Button>
        .
      </p>
    </div>
  );
};
