import React, { useEffect, useRef, useState } from "react";
import { mic } from "@highnote/daw/src";
import { AudioPlayer } from ".";
import "./RecordingPlayer.scss";
import { useToast } from "../useToast";
import { StatusIndicator } from "../StatusIndicator";
import { FileEntity } from "@highnote/server/src/core/entities";
import { getBlobAudioData } from "App/components/util";

type RecordingPlayerProps = {
  isLoading?: boolean;
  isEditing?: boolean;
  onChange: (file: File) => void;
  file?: FileEntity;
};

export const RecordingPlayer = ({
  isLoading,
  isEditing,
  onChange,
  file,
}: RecordingPlayerProps) => {
  const { addErrorMessage } = useToast();
  const [isSaving, setSaving] = useState(false);
  const [isRecording, setRecording] = useState(false);
  const [isMicLoading, setMicLoading] = useState(false);
  const [audioFile, setAudioFile] = useState<Partial<FileEntity>>();
  const unmountedRef = useRef<boolean>(false);

  const redoRecording = () => {
    setSaving(false);
    setAudioFile(undefined);
    onChange(undefined);
  };

  useEffect(
    () => () => {
      unmountedRef.current = true;
      mic.stop();
    },
    [],
  );

  useEffect(() => {
    if (file) {
      setAudioFile(file);
      setSaving(false);
    }
    if (!isEditing && !file) setAudioFile(undefined);
  }, [isEditing, file]);

  const onRecording = async (blob: Blob) => {
    const audioData = await getBlobAudioData(blob);
    if (unmountedRef.current) return;
    setAudioFile({
      isProcessedV3: true,
      metadata: {
        waveform: audioData.waveform,
        duration: audioData.duration,
      },
    });
  };

  const startRecording = async () => {
    try {
      setMicLoading(true);
      await mic.start({ onRecording });
      setMicLoading(false);
      setRecording(true);
    } catch (e) {
      setMicLoading(false);
      setRecording(false);
      addErrorMessage(`Microphone error: ${e.message}. Please try again.`);
    }
  };

  const stopRecording = async () => {
    setSaving(true);
    setRecording(false);
    const file = await mic.stop();
    onChange(file);
  };

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
      return;
    }

    startRecording();
  };

  return (
    <div
      className="recording-player"
      data-is-loading={!!isLoading}
      data-is-recording={isRecording}
      data-has-file={!!file}
    >
      <AudioPlayer file={audioFile} />
      {!isLoading && isEditing && (
        <div className="recording-controls">
          {isMicLoading || isSaving ? (
            <StatusIndicator isLoading={true} />
          ) : (
            <button
              className="toggle-recording"
              data-cypress-id="toggle-recording"
              onClick={toggleRecording}
            >
              <div className="icon"></div>
              {isRecording ? "Stop" : "Record"}
            </button>
          )}
          {!isRecording && !!audioFile && (
            <button className="clear-recording" onClick={redoRecording}>
              Redo
            </button>
          )}
        </div>
      )}
    </div>
  );
};
