import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Track } from "@highnote/server/src/core/entities";
import { TRACK_EDITOR_TAB, TrackEditor } from "./TrackEditor";
import { FileEditorProvider } from "./useFileEditor";
import { TrackContextProvider, useTrack } from "./useEntities/useTrack";
import { useHistory } from "react-router";

interface openTrackEditorConfig {
  track: Track;
  initialTab?: TRACK_EDITOR_TAB;
}

const TrackEditorContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openTrackEditor: (config: openTrackEditorConfig) => {},
  closeTrackEditor: () => {},
});

export const TrackEditorProvider = ({
  children,
  respectQueryParam,
}: {
  children: React.ReactNode;
  respectQueryParam?: boolean;
}) => {
  const history = useHistory();
  const { track: contextualTrack } = useTrack();
  const [isOpen, setOpen] = useState(false);
  const [initialTab, setInitialTab] = useState<TRACK_EDITOR_TAB>(
    TRACK_EDITOR_TAB.DETAILS,
  );
  const [track, setTrack] = useState<Track>(undefined);

  const openTrackEditor = useCallback(
    ({
      track,
      initialTab = TRACK_EDITOR_TAB.DETAILS,
    }: openTrackEditorConfig) => {
      setTrack(track);
      setInitialTab(initialTab);
      setOpen(true);
    },
    [],
  );

  const closeTrackEditor = useCallback(() => {
    setTrack(undefined);
    setOpen(false);
  }, []);

  const value = useMemo(
    () => ({
      openTrackEditor,
      closeTrackEditor,
    }),
    [openTrackEditor, closeTrackEditor],
  );

  useEffect(() => {
    if (respectQueryParam) {
      const params = new URLSearchParams(location.search);
      const editorParam = params.get("track-editor") as TRACK_EDITOR_TAB;
      const isParamValid =
        Object.values(TRACK_EDITOR_TAB).includes(editorParam);
      if (isParamValid && contextualTrack) {
        setTrack(contextualTrack);
        setInitialTab(editorParam);
        setOpen(true);
        params.delete("track-editor");
        history.replace({
          pathname: location.pathname,
          search: params.toString(),
        });
      }
    }
  }, [contextualTrack, history.location]);

  return (
    <TrackEditorContext.Provider value={value}>
      <FileEditorProvider>
        {isOpen &&
          (contextualTrack ? (
            <TrackEditor initialTab={initialTab} onClose={closeTrackEditor} />
          ) : (
            <TrackContextProvider id={track.id}>
              <TrackEditor initialTab={initialTab} onClose={closeTrackEditor} />
            </TrackContextProvider>
          ))}
        {children}
      </FileEditorProvider>
    </TrackEditorContext.Provider>
  );
};

export const useTrackEditor = () => useContext(TrackEditorContext);
