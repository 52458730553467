import { UserEntity } from "@highnote/server/src/core/entities";
import { UserProviderRaw, useUserRaw } from "App/components/useEntities";
import React, { createContext, useContext, useMemo } from "react";

type UserContextValue = {
  user: UserEntity;
  userLoading: boolean;
};

const UserContext = createContext<UserContextValue>({
  user: undefined,
  userLoading: true,
});

const _UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { entity: user, loading: userLoading } = useUserRaw();

  const value = useMemo(() => {
    return {
      user,
      userLoading,
    };
  }, [user, userLoading]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const UserContextProvider = ({
  id,
  children,
}: {
  id: Id;
  children: React.ReactNode;
}) => {
  return (
    <UserProviderRaw id={id}>
      <_UserContextProvider>{children}</_UserContextProvider>
    </UserProviderRaw>
  );
};

export const useUser = () => useContext(UserContext);
