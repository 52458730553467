import "./TrackEditor.scss";
import React, { useMemo, useState } from "react";
import { Dialog } from "App/common/Dialog";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { TabbedView } from "App/core/TabbedView";
import { TrackDetails } from "./TrackDetails";
import { useTrack } from "../useEntities/useTrack";
import { TrackVersions } from "./TrackVersions";
import { TrackAttachments } from "./TrackAttachments";
import { AttachmentsContextProvider } from "App/common/useAttachments";
import { useSpaceContext } from "App/common/useSpace";

export enum TRACK_EDITOR_TAB {
  DETAILS = "details",
  VERSIONS = "versions",
  ATTACHMENTS = "attachments",
}

export const TrackEditor = ({
  onClose,
  initialTab = TRACK_EDITOR_TAB.DETAILS,
}: {
  onClose?: () => void;
  initialTab?: TRACK_EDITOR_TAB;
}) => {
  const { track } = useTrack();
  const { space } = useSpaceContext();
  const [activeTabId, setActiveTabId] = useState<TRACK_EDITOR_TAB>(initialTab);

  const trackTabs = useMemo(() => {
    return [
      {
        id: TRACK_EDITOR_TAB.DETAILS,
        name: "Details",
        view: <TrackDetails />,
        onClick: () => setActiveTabId(TRACK_EDITOR_TAB.DETAILS),
        keepOnDismount: true,
      },
      {
        id: TRACK_EDITOR_TAB.VERSIONS,
        name: "Versions",
        view: <TrackVersions />,
        onClick: () => setActiveTabId(TRACK_EDITOR_TAB.VERSIONS),
      },
      {
        id: TRACK_EDITOR_TAB.ATTACHMENTS,
        name: "Attachments",
        view: <TrackAttachments />,
        onClick: () => setActiveTabId(TRACK_EDITOR_TAB.ATTACHMENTS),
      },
    ];
  }, []);

  if (!track) {
    return null;
  }

  return (
    <ErrorBoundary name="TrackEditor">
      <Dialog
        title={"Track Details"}
        open
        onClose={onClose}
        className="highnote-track-editor"
      >
        <AttachmentsContextProvider
          space={space}
          attachmentIds={track.files || []}
          track={track}
        >
          <TabbedView tabs={trackTabs} activeTabId={activeTabId} />
        </AttachmentsContextProvider>
      </Dialog>
    </ErrorBoundary>
  );
};
