import React, { useEffect } from "react";
import LoadingScreen from "App/common/LoadingScreen/LoadingScreen";
import { useAuth } from "App/components/Auth";

import { useHistory } from "react-router";

export const AuthRedirectRequired = ({
  children,
  includeSearch,
}: {
  children?: React.ReactNode;
  includeSearch?: boolean;
}) => {
  const history = useHistory();
  const { user, authLoading } = useAuth();

  useEffect(() => {
    if (!!user || authLoading) return;
    const returnTo = includeSearch
      ? encodeURIComponent(location.pathname + location.search)
      : location.pathname;
    history.push(`/login?returnTo=${returnTo}`);
  }, [user, authLoading]);

  if (user) {
    return <>{children || null}</>;
  }

  return <LoadingScreen />;
};
