// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sGokwb5fqfv_VmB0AdzO{font-size:12px !important;font-weight:700 !important}", "",{"version":3,"sources":["webpack://./src/App/common/banners/RefreshAppBanner/RefreshAppBanner.module.scss"],"names":[],"mappings":"AAEA,sBACE,yBAAA,CACA,0BAAA","sourcesContent":["@import \"App/styles/_variables\";\n\n.refresh-button {\n  font-size: 12px !important;\n  font-weight: $heavy-weight !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"refresh-button": "sGokwb5fqfv_VmB0AdzO"
};
export default ___CSS_LOADER_EXPORT___;
