import { TAB_ID } from "App/routes/Settings";
import "./TabbedView.scss";
import React, { useMemo } from "react";
import { useMobileAppParams } from "App/routes/Main/useMobileAppParams";

export type TabConfig = {
  id: string;
  name: string;
  onClick: () => void;
  view?: React.ReactNode;
  keepOnDismount?: boolean;
};

export const TabbedView = ({
  tabs,
  activeTabId,
  hideTabBar,
}: {
  tabs: TabConfig[];
  activeTabId: string;
  hideTabBar?: boolean;
}) => {
  const { isAuthorizedMobileComponent } = useMobileAppParams();

  const tabsBar = useMemo(
    () => (
      <div className="tabs">
        <ul>
          {tabs.map((t) => {
            if (
              (t.id === TAB_ID.NOTIFICATIONS || t.id === TAB_ID.PLANS) &&
              isAuthorizedMobileComponent
            ) {
              return null;
            }

            return (
              <li
                key={t.name}
                role="button"
                onClick={t.onClick}
                data-is-active={activeTabId === t.id}
                data-cypress-id="highnote-tab"
              >
                {t.name}
              </li>
            );
          })}
        </ul>

        <div className="divider" />
      </div>
    ),
    [activeTabId],
  );

  return (
    <div
      className="highnote-tabbed-view"
      data-cypress-id="highnote-tabbed-view"
    >
      {!hideTabBar && tabsBar}

      {tabs.map((tab) => {
        if (!tab.keepOnDismount && tab.id !== activeTabId) {
          return null;
        }
        return (
          <div
            key={tab.id}
            className="content"
            data-active={tab.id === activeTabId ? "active" : "inactive"}
          >
            {tab.view || null}
          </div>
        );
      })}
    </div>
  );
};
