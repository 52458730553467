const getDefaultRecordingName = () => {
  return `Mic Recording - ${new Date().toString()}`;
};

const MIME_TYPE = {
  WAV: "audio/wav",
  MP3: "audio/mp3",
  WEBM: "audio/webm",
  OGG: "audio/ogg",
};

const allMimeTypes = Object.values(MIME_TYPE);

const EXT_BY_MIME_TYPE = {
  [MIME_TYPE.WAV]: ".wav",
  [MIME_TYPE.MP3]: ".mp3",
  [MIME_TYPE.WEBM]: ".webm",
  [MIME_TYPE.OGG]: ".ogg",
};

class Microphone {
  __analyser: AnalyserNode;
  __recorder: MediaRecorder;
  __recording: Blob[];

  constructor() {}

  start = async ({
    onRecording,
  }: {
    onRecording?: (blob: Blob) => void;
  } = {}) => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });

    await this.stop();
    const mimeType = allMimeTypes.filter(MediaRecorder.isTypeSupported)[0];
    this.__recorder = new MediaRecorder(stream, { mimeType });
    this.__recording = null;

    const audioTracks = stream.getAudioTracks();
    if (audioTracks.length === 0) throw new Error("No input detected.");

    const track = audioTracks[0];
    if (track.muted) throw new Error("Input is muted.");
    if (!track.enabled) throw new Error("Input is disabled.");
    if (track.readyState === "ended") throw new Error("Input is disconnected.");

    return new Promise((resolve) => {
      const recordedChunks: Blob[] = [];
      this.__recorder.addEventListener("dataavailable", (e) => {
        if (e.data.size > 0) {
          recordedChunks.push(e.data);
          resolve(undefined);
        }
        const fullBlob = new Blob(recordedChunks);
        onRecording(fullBlob);
        this.__recording = recordedChunks;
      });

      this.__recorder.addEventListener("stop", () => {
        track.stop();
      });

      this.__recorder.start(100);
    });
  };

  stop = async () => {
    let mimeType: string;

    try {
      mimeType = this.__recorder.mimeType;
      this.__recorder.stop();
    } catch (e) {
      // empty
    }

    if (!this.__recording) return null;

    const mimeKey = mimeType.split(";")[0];
    const ext = EXT_BY_MIME_TYPE[mimeKey];
    const fileName = `${getDefaultRecordingName()}${ext || ""}`;
    const file = new File(this.__recording, fileName, {
      type: mimeType,
    });

    return file;
  };
}

export default Microphone;
