import React, { useEffect } from "react";
import {
  ArrayRemove,
  ArrayUnion,
  COLLECTION_ID,
  Space,
  SpaceCore,
  Track,
} from "@highnote/server/src/core/entities";
import { highnote } from "@highnote/server/src/sdk";
import { v4 as uuidv4 } from "uuid";
import { useToast } from "./useToast";
import { setTestProperty } from "App/modules/utils";
import {
  MAX_ACTIVE_SPACES_ERROR,
  getDefaultVersionId,
} from "@highnote/server/src/core/shared-util";
import { LIMIT_TYPE, usePlanLimitsContext } from "./PlanLimits/usePlanLimits";

type HighnoteTools = {
  createSpace: (props?: {
    id?: string;
    data?: Partial<SpaceCore>;
  }) => Promise<Space>;
  moveToSpace: (props: {
    entityId: string;
    entityType: COLLECTION_ID.SPACE | COLLECTION_ID.TRACK;
    newSpaceId?: string;
  }) => Promise<void>;
  pinTrackVersion: (props: {
    track: Track;
    versionId: string;
  }) => Promise<void>;
  unpinTrackVersion: (props: {
    track: Track;
    versionId: string;
  }) => Promise<void>;
};

export const useHighnote: () => HighnoteTools = () => {
  const { toasted } = useToast();
  const { showPlanLimitsDialog } = usePlanLimitsContext();

  useEffect(() => {
    setTestProperty("highnoteSDK", highnote);
  }, []);

  const createSpace = async (_props?: {
    id?: string;
    data?: Partial<SpaceCore>;
  }) => {
    try {
      const props = _props || {};
      const spaceId = props.id || uuidv4();

      let space: Space;
      await toasted({
        promise: highnote
          .createSpace({
            id: spaceId,
            data: {
              name: `New Space`,
              ...(props.data || {}),
            },
          })
          .then((s) => (space = s)),
        createMessage: "Creating new Space...",
        errorMessage: "Could not create a new Space. Please try again.",
      });

      return space;
    } catch (e) {
      if (e.message === MAX_ACTIVE_SPACES_ERROR) {
        showPlanLimitsDialog(LIMIT_TYPE.SPACES);
        return;
      }
      throw e;
    }
  };

  const moveToSpace = async (props: {
    entityId: string;
    entityType: COLLECTION_ID.SPACE | COLLECTION_ID.TRACK;
    newSpaceId?: string;
  }) => {
    try {
      if (props.entityType === COLLECTION_ID.SPACE) {
        await moveSpaceToSpace({
          newSpaceId: props.newSpaceId,
          prevSpaceId: props.entityId,
        });
      } else {
        await moveTrackToSpace({
          trackId: props.entityId,
          spaceId: props.newSpaceId,
        });
      }
    } catch (e) {
      if (e.message === MAX_ACTIVE_SPACES_ERROR) {
        showPlanLimitsDialog(LIMIT_TYPE.SPACES);
        return;
      }
      throw e;
    }
  };

  const moveTrackToSpace = async (props: {
    trackId: string;
    spaceId: string;
  }) => {
    const promise = highnote.updateTrack({
      id: props.trackId,
      data: {
        spaceId: props.spaceId,
      },
    });

    await toasted({
      promise,
      createMessage: `Moving Track...`,
      successMessage: `Track successfully moved.`,
      ErrorContent: ({ error }: { error: Error }) => <>{error.message}</>,
    });
  };

  const moveSpaceToSpace = async (props: {
    prevSpaceId: string;
    newSpaceId: string;
  }) => {
    const promise = highnote.updateSpace({
      id: props.prevSpaceId,
      data: {
        spaceId: props.newSpaceId,
      },
    });

    await toasted({
      promise,
      createMessage: props.newSpaceId ? `Moving Space...` : `Removing Space...`,
      successMessage: props.newSpaceId
        ? `Space successfully moved`
        : `Space successfully removed.`,
      ErrorContent: ({ error }: { error: Error }) => <>{error.message}</>,
    });
  };

  const pinTrackVersion = async (props: {
    track: Track;
    versionId: string;
  }) => {
    const defaultVersionId = getDefaultVersionId(props.track);
    const promise = highnote.updateTrack({
      id: props.track.id,
      data: {
        pinnedVersionFiles: new ArrayUnion([
          defaultVersionId,
          props.versionId,
        ]) as unknown as Id[],
      },
    });

    await toasted({
      promise,
      ErrorContent: ({ error }: { error: Error }) => (
        <>Could not pin track version. {error.message}</>
      ),
    });
  };

  const unpinTrackVersion = async (props: {
    track: Track;
    versionId: string;
  }) => {
    const promise = highnote.updateTrack({
      id: props.track.id,
      data: {
        pinnedVersionFiles: new ArrayRemove([
          props.versionId,
        ]) as unknown as Id[],
      },
    });

    await toasted({
      promise,
      ErrorContent: ({ error }: { error: Error }) => (
        <>Could not unpin track version. {error.message}</>
      ),
    });
  };

  return {
    createSpace,
    moveToSpace,
    pinTrackVersion,
    unpinTrackVersion,
  };
};
