import { Dialog, DialogButtons, DialogSection } from "App/common/Dialog";
import { GifsResult, GifResult } from "@giphy/js-fetch-api";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { giphy } from "App/services/giphy";
import { TextInput } from "App/common/TextInput";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import "./StickerSelectionDialog.scss";
import { LoadingSpinner } from "App/common/icons/LoadingSpinner";
import { StickerIds } from "@highnote/server/src/core/entities";
import { ReactComponent as SearchSVG } from "App/common/icons-v2/search.svg";
import debounce from "lodash/debounce";

export enum STICKER_SELECTION_TAB {
  GIFS = "gifs",
  STICKERS = "stickers",
}

const GIPHY_ATTRIBUTION_MARK = "/public/giphy-attribution-mark.png";

interface StickerSelectionDialogProps {
  onClose: () => void;
  onSelect: (props: { id?: StickerIds; imageUrl?: string }) => void;
}

export const StickerSelectionDialog = ({
  onClose,
  onSelect,
}: StickerSelectionDialogProps) => {
  const [selectedGif, setSelectedGif] = useState<GifResult["data"] | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);

  const onConfirm = () => {
    setIsLoading(true);
    onSelect({ imageUrl: selectedGif?.images.fixed_height.url });
    setIsLoading(false);
  };

  return (
    <Dialog
      open
      className="highnote-sticker-dialog"
      title={
        <div className="highnote-sticker-dialog-header">
          <h3>Choose a sticker</h3>
          <img src={GIPHY_ATTRIBUTION_MARK} height={14} alt="Giphy" />
        </div>
      }
      onClose={onClose}
    >
      <GifTab selectedGif={selectedGif} setSelectedGif={setSelectedGif} />
      <DialogButtons className="highnote-sticker-dialog-footer">
        <Button
          theme={BUTTON_THEME.PRIMARY}
          onClick={onClose}
          size={BUTTON_SIZE.MEDIUM}
        >
          Cancel
        </Button>
        <Button
          theme={BUTTON_THEME.CTA}
          loading={isLoading}
          disabled={!selectedGif}
          size={BUTTON_SIZE.MEDIUM}
          onClick={onConfirm}
        >
          Choose
        </Button>
      </DialogButtons>
    </Dialog>
  );
};

const GifTab = ({
  selectedGif,
  setSelectedGif,
}: {
  selectedGif: GifResult["data"] | null;
  setSelectedGif: (gif: GifResult["data"] | null) => void;
}) => {
  const [giphyResults, setGiphyResults] = useState<GifsResult | null>(null);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [offset, setOffset] = useState(0);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const resultsRef = useRef<HTMLDivElement>(null);
  const OFFSET_SIZE = 15;

  const fetchGifs = async (query: string, isNewSearch = false) => {
    if (isNewSearch) {
      setIsSearchLoading(true);
    } else {
      setIsLoadingMore(true);
    }

    try {
      const currentOffset = isNewSearch ? 0 : offset;
      const response = query
        ? await giphy.search(query, {
            limit: OFFSET_SIZE,
            offset: currentOffset,
          })
        : await giphy.trending({ limit: OFFSET_SIZE, offset: currentOffset });

      setGiphyResults((prev) => {
        if (isNewSearch || !prev) return response;
        return {
          ...response,
          data: [...prev.data, ...response.data],
        };
      });

      setTotalCount(response.pagination.total_count);
      setOffset(currentOffset + OFFSET_SIZE);
    } finally {
      if (isNewSearch) {
        setIsSearchLoading(false);
      } else {
        setIsLoadingMore(false);
      }
    }
  };

  const debouncedFetchGifs = useCallback(
    debounce((query: string) => {
      setOffset(0);
      setGiphyResults(null);
      fetchGifs(query, true);
    }, 500),
    [],
  );

  useEffect(() => {
    if (!searchQuery) {
      fetchGifs("", true);
      return;
    }

    debouncedFetchGifs(searchQuery);
    return () => {
      debouncedFetchGifs.cancel();
    };
  }, [searchQuery]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    const hasMoreData = totalCount > offset;

    if (
      scrollHeight - scrollTop <= clientHeight + 50 &&
      !isLoadingMore &&
      !isSearchLoading &&
      hasMoreData
    ) {
      fetchGifs(searchQuery);
    }
  };

  return (
    <>
      <DialogSection className="highnote-sticker-dialog-content">
        <TextInput
          startIcon={<SearchSVG />}
          endIcon={isSearchLoading ? <LoadingSpinner /> : null}
          value={searchQuery}
          placeholder="Search GIPHY"
          onChange={(value) => setSearchQuery(value)}
        />
        <div
          className="highnote-sticker-dialog-content-results"
          onScroll={handleScroll}
        >
          {giphyResults && giphyResults.data.length === 0 && (
            <div className="highnote-sticker-dialog-content-results-empty">
              <span>No gifs found</span>
            </div>
          )}
          {giphyResults && (
            <div
              className="highnote-sticker-dialog-content-results-list"
              ref={resultsRef}
            >
              {giphyResults.data.map((gif) => (
                <div
                  key={gif.id}
                  className="highnote-sticker-dialog-content-results-list-item"
                >
                  <img
                    src={gif.images.fixed_height.url}
                    alt={gif.title}
                    data-selected={selectedGif?.id === gif.id}
                    loading="lazy"
                    onClick={() => setSelectedGif(gif)}
                  />
                </div>
              ))}
            </div>
          )}
          {isSearchLoading && (
            <span className="highnote-sticker-dialog-content-results-searching">
              Searching...
            </span>
          )}
          {isLoadingMore && (
            <div className="highnote-sticker-dialog-content-results-loading">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </DialogSection>
    </>
  );
};
