import React from "react";
import { Dialog, DialogButtons, DialogSection } from "App/common/Dialog";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import "./JoinSpaceRequestDialog.scss";
import {
  ConnectedUserAvatar,
  ConnectedUserIdentifier,
  ConnectedUserName,
  USER_AVATAR_SIZE,
} from "App/common/UserAvatar/UserAvatar";

interface JoinSpaceRequestDialogProps {
  onClose?: () => void;
  requesterId: string;
  spaceName: string;
  onApprove: (userId: string) => void;
  onDeny: () => void;
}

export const JoinSpaceRequestDialog = ({
  onClose,
  requesterId,
  spaceName,
  onApprove,
  onDeny,
}: JoinSpaceRequestDialogProps) => {
  return (
    <Dialog open className="JoinSpaceRequest-dialog" onClose={onClose}>
      <DialogSection className="JoinSpaceRequest-dialog-content">
        <ConnectedUserAvatar
          userId={requesterId}
          size={USER_AVATAR_SIZE.XXXLARGE}
        />
        <div className="JoinSpaceRequest-dialog-content-description">
          <h3>
            Add <ConnectedUserName ignoreVerifiedStatus userId={requesterId} />{" "}
            to {spaceName}
          </h3>
          <p>
            <ConnectedUserIdentifier
              compact={false}
              inline
              useNonCached
              userId={requesterId}
            />
          </p>
        </div>
      </DialogSection>
      <DialogButtons>
        <Button
          size={BUTTON_SIZE.MEDIUM}
          theme={BUTTON_THEME.CTA}
          onClick={() => onApprove(requesterId)}
        >
          Approve
        </Button>
        <Button
          size={BUTTON_SIZE.MEDIUM}
          theme={BUTTON_THEME.SECONDARY}
          onClick={onDeny}
        >
          Deny Access
        </Button>
      </DialogButtons>
    </Dialog>
  );
};
