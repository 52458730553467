import React from "react";
import classNames from "classnames";
import { Player } from "@lottiefiles/react-lottie-player";
import { useMobileAppParams } from "App/routes/Main/useMobileAppParams";
import "./styles.scss";

const LoadingScreen = ({ fixed }: { fixed?: boolean }) => {
  const { isAuthorizedMobileComponent } = useMobileAppParams();

  return (
    <div
      className={classNames("LoadingScreen", {
        "is-fixed": fixed,
        "is-authorized-mobile-component": isAuthorizedMobileComponent,
      })}
    >
      <div className="LoadingScreen-container">
        <div className="iconWrapper">
          <Player
            autoplay={true}
            loop={true}
            src="/public/icons/lottie_loader_1_alt_alpha.json"
            style={{ width: "110px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
