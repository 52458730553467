import React, { useEffect } from "react";
import classNames from "classnames";

import { EVENT_ID } from "@highnote/server/src/core/entities";
import { THEME, ThemeProvider } from "App/common/ThemeProvider";
import { Navigation } from "App/components/Navigation";
import { Plans } from "App/components/Plans/Plans";
import { TabbedView } from "App/core/TabbedView";
import { AuthRedirectRequired } from "App/modules/Auth/AuthRedirectRequired";
import { useSegmentContext } from "App/modules/useSegment";
import { useHistory, useLocation } from "react-router";
import { useMobileAppParams } from "../Main/useMobileAppParams";
import { useUrlContext } from "../Main/useUrlContext";
import { NotificationSettings } from "./NotificationSettings";
import { Profile } from "./Profile";
import { UsageDetails } from "./UsageDetails";

import "./Settings.scss";

export enum TAB_ID {
  PROFILE = "profile",
  PLANS = "plans",
  USAGE = "usage",
  NOTIFICATIONS = "notifications",
}

const SettingsUI = () => {
  const history = useHistory();
  const location = useLocation();
  const { settingsTabId } = useUrlContext();
  const { isAuthorizedMobileComponent } = useMobileAppParams();
  const { trackEvent } = useSegmentContext();
  const existingQueryParams = location.search;

  useEffect(() => {
    trackEvent(EVENT_ID.ENTERED_ACCOUNT_SETTINGS);

    return () => {
      trackEvent(EVENT_ID.EXITED_ACCOUNT_SETTINGS);
    };
  }, []);

  return (
    <div className="highnote-settings">
      {!isAuthorizedMobileComponent && (
        <Navigation showIndent hideBackButton>
          <h1>Account</h1>
        </Navigation>
      )}

      <div
        className={classNames("inner", {
          "is-authorized-mobile-component": isAuthorizedMobileComponent,
        })}
      >
        <TabbedView
          hideTabBar={
            isAuthorizedMobileComponent &&
            settingsTabId === TAB_ID.NOTIFICATIONS
          }
          activeTabId={settingsTabId || TAB_ID.PROFILE}
          tabs={[
            {
              id: TAB_ID.PROFILE,
              name: "Details",
              onClick: () =>
                history.push({
                  pathname: `/settings/${TAB_ID.PROFILE}`,
                  search: existingQueryParams,
                }),
              view: <Profile />,
            },
            {
              id: TAB_ID.PLANS,
              name: "Plan",
              onClick: () =>
                history.push({
                  pathname: `/settings/${TAB_ID.PLANS}`,
                  search: existingQueryParams,
                }),
              view: <Plans />,
            },
            {
              id: TAB_ID.USAGE,
              name: "Usage",
              onClick: () =>
                history.push({
                  pathname: `/settings/${TAB_ID.USAGE}`,
                  search: existingQueryParams,
                }),
              view: <UsageDetails />,
            },
            {
              id: TAB_ID.NOTIFICATIONS,
              name: "Notifications",
              onClick: () =>
                history.push({
                  pathname: `/settings/${TAB_ID.NOTIFICATIONS}`,
                  search: existingQueryParams,
                }),
              view: <NotificationSettings />,
            },
          ]}
        />
      </div>
    </div>
  );
};

export const Settings = () => {
  return (
    <AuthRedirectRequired>
      <ThemeProvider theme={THEME.DARK}>
        <SettingsUI />
      </ThemeProvider>
    </AuthRedirectRequired>
  );
};
