import React, { useCallback, useRef, useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router";
import { v4 as uuidv4 } from "uuid";

import { FileEntity, Space } from "@highnote/server/src/core/entities";
import {
  getEntitySubscribers,
  isSpacePrivateInboxEnabled,
  PERMISSION,
  stripExtension,
} from "@highnote/server/src/core/shared-util";
import { highnote } from "@highnote/server/src/sdk";
import { AudioFileSelect, FileSelect } from "App/common/FileSelect";
import { ReactComponent as AddSVG } from "App/common/icons-v2/add.svg";
import { ReactComponent as SpaceFileSVG } from "App/common/icons-v2/folder-add-line.svg";
import { ReactComponent as AddToLibrarySVG } from "App/common/icons-v2/play-list-line.svg";
import { ReactComponent as RecordSVG } from "App/common/icons-v2/record-circle-fill.svg";
import { ReactComponent as UploadSVG } from "App/common/icons-v2/upload.svg";
import { ReactComponent as DropboxSVG } from "App/common/icons/dropbox.svg";
import { Menu, MenuItem } from "App/common/Menu";
import { useAttachmentsContext } from "App/common/useAttachments";
import { useDropboxChooser } from "App/common/useDropboxChooser";
import { useHighnote } from "App/common/useHighnote";
import { useSpaceContext } from "App/common/useSpace";
import { useToast } from "App/common/useToast";
import { useAuth } from "App/components/Auth";
import { JOIN_ENTITY_TRIGGER } from "App/components/Auth/util";
import { RecordDialog } from "App/components/RecordDialog";
import { useAddToSpace } from "App/components/useEntities/useLibraryTracks/useAddToSpace";
import { useFileEditor } from "App/components/useFileEditor";
import { Button, BUTTON_THEME, ButtonProps } from "App/core/Button";
import { routePaths } from "App/modules/urls";
import { matchAppPath } from "App/modules/utils";
import styles from "./SpacePageActions.module.scss";
import { useDropboxJobsContext } from "App/components/useEntities/useDropboxDownloads";

type Props = {
  triggerProps?: ButtonProps;
  onUploadFiles: (files: File[]) => Promise<void>;
};

export const SpacePageActions = ({ triggerProps, onUploadFiles }: Props) => {
  const triggerRef = useRef<HTMLButtonElement>();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { user } = useAuth();
  const { space } = useSpaceContext();
  const { openAddToSpace } = useAddToSpace();
  const {
    isProcessing: isChooserProcessing,
    dropboxChooserReady,
    openDropboxChooser,
  } = useDropboxChooser();
  const { resetPromptStatus } = useDropboxJobsContext();

  const {
    addAttachment,
    canAddAttachmentsToSpace,
    canAddSpaceToSpace,
    handleCreateSpace,
    isRecorderOpen,
    setRecorderOpen,
    onSaveRecording,
  } = useAddToSpaceActions(space);

  const subscribers = getEntitySubscribers(space);
  const isMember = subscribers.includes(user?.id);
  const isInboxGuest = isSpacePrivateInboxEnabled(space) && !isMember;

  const handleOpenAddToSpaceDialog = () => {
    openAddToSpace(space);
  };

  const addFromDropboxToSpace = () => {
    openDropboxChooser({
      onSuccess: async (fileIds: string[]) => {
        await highnote.cloneDropboxEntities({
          fileIds,
          spaceId: space.id,
        });
        resetPromptStatus();
      },
    });
  };

  const handleUploadFiles = useCallback(
    async (files: File[]) => {
      await onUploadFiles(files);

      // Manually close the menu to ensure that this function can run.
      // This is necessary because the menu is closed when the user clicks on the menu item
      // and therefore can not upload files.
      setIsMenuOpen(false);
    },
    [space?.id, user],
  );

  return (
    <>
      <Button
        {...triggerProps}
        ref={triggerRef}
        className={classNames(
          styles["highnote-space-actions"],
          triggerProps?.className,
        )}
        data-cypress-id="highnote-space-actions"
        data-is-active={isMenuOpen}
        theme={BUTTON_THEME.CTA}
        type="button"
        onClick={() => {
          setIsMenuOpen(true);
        }}
      >
        <AddSVG />
      </Button>
      <Menu
        className="highnote-space-actions-menu"
        anchorEl={triggerRef.current}
        // Do not add onClick to the menu to prevent the menu from closing when the user clicks on the menu
        // We are manually closing to allow for the Upload function to work, see onUploadFiles.
        onClose={() => {
          setIsMenuOpen(false);
        }}
        open={isMenuOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <SpaceActionItem
          icon={<RecordSVG />}
          onClick={() => {
            // We are manually closing the menu to allow for the Upload option to work
            // See `onUploadFiles` function.
            setIsMenuOpen(false);
            setRecorderOpen(true);
          }}
        >
          Record
        </SpaceActionItem>

        {isInboxGuest ? (
          <AudioFileSelect
            allowMultiple
            onSelect={handleUploadFiles}
            className={styles["file-select"]}
          >
            <SpaceActionItem icon={<UploadSVG />}>Upload</SpaceActionItem>
          </AudioFileSelect>
        ) : (
          <FileSelect
            allowMultiple
            onSelect={handleUploadFiles}
            className={styles["file-select"]}
          >
            <SpaceActionItem icon={<UploadSVG />}>Upload</SpaceActionItem>
          </FileSelect>
        )}

        <SpaceActionItem
          icon={<SpaceFileSVG />}
          onClick={async () => {
            // We are manually closing the menu to allow for the Upload option to work
            // See `onUploadFiles` function.
            setIsMenuOpen(false);

            await handleCreateSpace();
          }}
          disabled={!canAddSpaceToSpace}
        >
          <span data-cypress-id="highnote-add-to-space-new-space-menu-item">
            New Space
          </span>
        </SpaceActionItem>

        <SpaceActionItem
          icon={<UploadSVG />}
          disabled={!canAddAttachmentsToSpace}
          onClick={() => {
            setIsMenuOpen(false);
            addAttachment();
          }}
        >
          Create Attachment
        </SpaceActionItem>

        {!!user && (
          <SpaceActionItem
            icon={<AddToLibrarySVG />}
            onClick={() => {
              // We are manually closing the menu to allow for the Upload option to work
              // See `onUploadFiles` function.
              setIsMenuOpen(false);
              handleOpenAddToSpaceDialog();
            }}
          >
            Add from Library
          </SpaceActionItem>
        )}

        {dropboxChooserReady && (
          <SpaceActionItem
            icon={<DropboxSVG />}
            disabled={isChooserProcessing}
            onClick={addFromDropboxToSpace}
          >
            Add from Dropbox
          </SpaceActionItem>
        )}
      </Menu>

      {isRecorderOpen && (
        <RecordDialog
          onClose={() => setRecorderOpen(false)}
          onSave={onSaveRecording}
        />
      )}
    </>
  );
};

export const SpaceActionItem = ({
  className,
  icon,
  disabled,
  warn,
  onClick,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLLIElement>;
  disabled?: boolean;
  warn?: boolean;
}) => {
  return (
    <MenuItem
      className={classNames(styles["highnote-space-action-item"], className)}
      disabled={disabled}
      data-warn={!!warn}
      onClick={onClick}
    >
      {icon}
      <span className={styles["highnote-space-action-item-label"]}>
        {children}
      </span>
    </MenuItem>
  );
};

const useAddToSpaceActions = (space: Space) => {
  const history = useHistory();
  const { addErrorMessage } = useToast();
  const { createSpace } = useHighnote();
  const [isRecorderOpen, setRecorderOpen] = useState<boolean>(false);
  const { user, joinEntity, isAllowed } = useAuth();
  const matchSpaceHome = matchAppPath(routePaths.spaceHome);
  const { attachFileToContext } = useAttachmentsContext();
  const { openFileEditor } = useFileEditor();

  const canAddSpaceToSpace = isAllowed(PERMISSION.TO_ADD_SPACE_TO_SPACE, {
    space,
  });

  const canAddAttachmentsToSpace = isAllowed(
    PERMISSION.TO_ADD_ATTACHMENT_TO_SPACE,
    {
      space,
    },
  );

  const handleCreateSpace = async () => {
    try {
      await createSpace({
        data: {
          spaceId: space.id,
        },
      });
    } catch (e) {
      addErrorMessage(e.message || "Failed to create a new space.");
    }
  };

  const onSaveRecording = async (file: FileEntity) => {
    const trackId = uuidv4();
    await highnote.createTrack({
      id: trackId,
      data: {
        artistName: user.name,
        title: `${space.name} - ${stripExtension(file.name)}`,
        versionFilesV2: [file.id],
        spaceId: space.id,
      },
    });

    joinEntity({
      entity: space,
      entityType: "Space",
      trigger: JOIN_ENTITY_TRIGGER.UPLOAD,
    });

    if (matchSpaceHome) return;
    history.push(`/space/${space.id}/${trackId}?track-details`);
  };

  const addAttachment = () => {
    openFileEditor({
      onSave: (fileEntity) => attachFileToContext(fileEntity.id),
    });
  };

  return {
    canAddSpaceToSpace,
    canAddAttachmentsToSpace,
    isRecorderOpen,
    handleCreateSpace,
    addAttachment,
    setRecorderOpen,
    onSaveRecording,
  };
};
