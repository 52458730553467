import { chunk } from "lodash";
import {
  COLLECTION_ID,
  DropboxSyncJob,
  Space,
} from "@highnote/server/src/core/entities";
import { highnote } from "@highnote/server/src/sdk";
import {
  collection,
  getCountFromServer,
  where,
  query,
  getDocs,
} from "firebase/firestore";

const getChildSpaces = async (space: Space) => {
  const childSpacesQuery = query(
    collection(highnote.__firebaseFirestore, COLLECTION_ID.SPACE),
    where("spaceId", "==", space.id),
    where("createdBy", "==", space.createdBy),
  );

  const snapshot = await getDocs(childSpacesQuery);
  return snapshot.docs.map((s) => s.data()) as Space[];
};

export const getNumTracksInSpace = async (space: Space) => {
  const coll = collection(highnote.__firebaseFirestore, COLLECTION_ID.TRACK);
  const q = query(
    coll,
    where("spaceId", "==", space.id),
    where("createdBy", "==", space.createdBy),
  );
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
};

export const getNumSpacesInSpace = async (spaceId: string) => {
  const coll = collection(highnote.__firebaseFirestore, COLLECTION_ID.SPACE);
  const q = query(coll, where("spaceId", "==", spaceId));
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
};

export const countAllSpaceChildren = async (space: Space): Promise<number> => {
  const spaceFiles = space?.files || [];
  const tracksCount = await getNumTracksInSpace(space);
  const childSpaces = await getChildSpaces(space);
  const childEntitiesCount =
    tracksCount + spaceFiles.length + childSpaces.length;
  const childSpaceEntitiesCount = await Promise.all(
    childSpaces.map((childSpace) => {
      return countAllSpaceChildren(childSpace);
    }),
  );
  return (
    childEntitiesCount + childSpaceEntitiesCount.reduce((a, b) => a + b, 0)
  );
};

export const getUserDropboxJobsByFileIds = async ({
  fileIds,
  userId,
}: {
  fileIds: string[];
  userId: string;
}) => {
  const fildIdsChunks = chunk(Array.from(new Set(fileIds)), 10);
  const jobs: DropboxSyncJob[] = [];
  for (const fileIds of fildIdsChunks) {
    const existingJobs = await getDocs(
      query(
        collection(highnote.__firebaseFirestore, COLLECTION_ID.DROPBOX_JOBS),
        where("fileId", "in", fileIds),
        where("createdBy", "==", userId),
      ),
    );
    jobs.push(...existingJobs.docs.map((doc) => doc.data() as DropboxSyncJob));
  }
  return jobs;
};
