import "./AddButton.scss";
import React, { forwardRef } from "react";
import { ReactComponent as PlusSVG } from "App/common/icons/plus.svg";
import { useTheme } from "../ThemeProvider";
import {
  Button,
  ButtonProps,
  BUTTON_SIZE,
  BUTTON_THEME,
} from "../../core/Button";

export const AddButton = forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    theme?: BUTTON_THEME;
    size?: BUTTON_SIZE;
    loading?: boolean;
    children: React.ReactNode;
  }
>((props: ButtonProps, ref) => {
  const { theme } = useTheme();

  return (
    <Button
      {...props}
      ref={ref}
      className="highnote-add-button"
      data-theme={theme}
      size={props.size || BUTTON_SIZE.MEDIUM}
      theme={props.theme || BUTTON_THEME.PLAIN}
    >
      <span className="icon">
        <PlusSVG />
      </span>
      {props.children}
    </Button>
  );
});

AddButton.displayName = "AddButton";
