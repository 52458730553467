import "./Badge.scss";
import React from "react";
import { useTheme } from "../ThemeProvider";

export interface BadgeProps {
  children: React.ReactNode;
  theme?: "light" | "dark";
  size?: "small" | "medium" | "large";
}

export const Badge: React.FC<BadgeProps> = ({
  children,
  theme: propTheme,
  size = "medium",
}) => {
  const { theme: contextTheme } = useTheme();
  const theme = propTheme || contextTheme;

  return (
    <span
      className={`highnote-badge highnote-badge--${size}`}
      data-theme={theme}
    >
      {children}
    </span>
  );
};

Badge.displayName = "Badge";
