import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { TextInput } from "App/common/TextInput";
import { highnote } from "@highnote/server/src/sdk";
import { ToastMessageContent, useToast } from "App/common/useToast";
import { FileEntity } from "@highnote/server/src/core/entities";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { Button, BUTTON_THEME } from "App/core/Button";
import { useSpaceContext } from "App/common/useSpace";
import { useAuth } from "App/components/Auth";
import { PERMISSION } from "@highnote/server/src/core/shared-util";
import { PermissionTooltip } from "App/components/PermissionTooltip";
import { useTrack } from "App/components/useEntities/useTrack";
import { DialogButtons, DialogInfo, DialogSection } from "App/common/Dialog";
import moment from "moment";
import { ConnectedUserName } from "App/common/UserAvatar/UserAvatar";
import { EditableArtwork } from "App/components/EditableArtwork";

type TrackEditState = {
  title: string;
  artistName: string;
  file: FileEntity;
};

export const TrackDetails = () => {
  const { isAllowed } = useAuth();
  const { track, trackArtworkUrl, setTrackArtworkUrl } = useTrack();
  const { space } = useSpaceContext();
  const { toasted, addMessage } = useToast();
  const [artworkUrl, setArtworkUrl] = useState(trackArtworkUrl);
  const [trackState, setTrackState] = useState<TrackEditState>({
    title: track.title || "",
    artistName: track.artistName || "",
    file: undefined,
  });
  const canEditTrack = isAllowed(PERMISSION.TO_EDIT_TRACK_IN_SPACE, {
    space,
    track,
  });
  const unmountedRef = useRef<boolean>(false);

  useEffect(
    () => () => {
      unmountedRef.current = true;
    },
    [],
  );

  const onSubmit = async () => {
    if (!trackState.title) {
      const toastId = uuidv4();

      addMessage({
        id: toastId,
        children: (
          <ToastMessageContent
            type="error"
            id={toastId}
            text={`Track must have a name.`}
          />
        ),
      });

      return;
    }

    const requestUpdate = async () => {
      await highnote.updateTrack({
        id: track.id,
        data: {
          title: trackState.title,
          artistName: trackState.artistName,
          artworkFile: trackState.file?.id || null,
        },
      });
    };

    await toasted({
      promise: requestUpdate(),
      createMessage: "Updating track...",
      successMessage: "Track saved.",
      errorMessage: "Could not update track. Please try again.",
    });

    setTrackArtworkUrl(artworkUrl);
  };

  const createdAtStr = moment(track.createdAt).fromNow();

  return (
    <ErrorBoundary name="TrackDetails">
      <div className="highnote-track-details">
        <DialogSection>
          <h3>Track Artwork</h3>

          <EditableArtwork
            disabled={!canEditTrack}
            artworkUrl={artworkUrl}
            fallbackArtworkUrl={"/public/default-track-artwork.png"}
            onChange={async (file, tempUrl) => {
              setArtworkUrl(tempUrl);
              setTrackState({ ...trackState, file });
            }}
          />
        </DialogSection>

        <DialogSection>
          <h3>Track Name</h3>
          <PermissionTooltip
            permission={PERMISSION.TO_EDIT_TRACK_IN_SPACE}
            space={space}
            track={track}
          >
            <div className="input-field">
              <TextInput
                isSingleLine
                isReadOnly={!canEditTrack}
                value={trackState.title}
                required
                placeholder="New Track"
                onChange={(val) => setTrackState({ ...trackState, title: val })}
              />
            </div>
          </PermissionTooltip>
        </DialogSection>

        <DialogSection>
          <h3>Track Artist</h3>
          <PermissionTooltip
            permission={PERMISSION.TO_EDIT_TRACK_IN_SPACE}
            space={space}
            track={track}
          >
            <div className="input-field">
              <TextInput
                isSingleLine
                isReadOnly={!canEditTrack}
                value={trackState.artistName || ""}
                placeholder="Track Artist"
                onChange={(val) =>
                  setTrackState({ ...trackState, artistName: val })
                }
              />
            </div>
          </PermissionTooltip>
        </DialogSection>

        <DialogButtons>
          <Button
            theme={BUTTON_THEME.CTA}
            disabled={!canEditTrack}
            onClick={onSubmit}
          >
            Save Changes
          </Button>
        </DialogButtons>
      </div>

      <DialogInfo>
        Created {createdAtStr} by <ConnectedUserName userId={track.createdBy} />
      </DialogInfo>
    </ErrorBoundary>
  );
};
