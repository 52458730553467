import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router";

import { PreviewText } from "@highnote/preview-text/src";
import { Space, Track } from "@highnote/server/src/core/entities";
import { sortItemsByItemsOrder } from "@highnote/server/src/core/shared-util";
import { highnote } from "@highnote/server/src/sdk";
import { ReactComponent as CaretDownSVG } from "App/common/icons-v2/carat-down.svg";
import { ReactComponent as CaretRightSVG } from "App/common/icons-v2/carat-right.svg";
import { ReactComponent as FolderHollowSVG } from "App/common/icons-v2/folder-fill.svg";
import { ReactComponent as MusicFillSVG } from "App/common/icons-v2/music-fill.svg";
import { ReactComponent as TrackVersionSVG } from "App/common/icons-v2/waveform.svg";
import { LoadingSpinner } from "App/common/icons/LoadingSpinner";
import { List, ListItem, ListItemButton, ListSubheader } from "App/common/List";
import { PREVIEW_ICON_SIZE, PreviewIcon } from "App/common/PreviewIcon";
import { DefaultSpaceActions } from "App/common/SpaceActions";
import { useConfirmation } from "App/common/useConfirmation";
import { useViewport } from "App/common/useViewport";
import { useAuth } from "App/components/Auth";
import { EntityRowConfig } from "App/components/EntityTable";
import { Version } from "App/components/EntityTable/config";
import { trackDeeplinkConfirmPayload } from "App/components/EntityTable/ExpandableSpace/ExpandableSpaceRow";
import { useTrackChildItemRows } from "App/components/EntityTable/ExpandableTrack/ExpandableTrackRow";
import {
  ALT_TRACK_VERSION_ACTIONS,
  TrackVersionActions,
} from "App/components/TrackEditor/TrackVersions/TrackVersionActions";
import { DefaultTrackActions } from "App/components/useEntities/useLibraryTracks/TrackActions";
import { useTopLevelSpaces } from "App/components/useEntities/useTopLevelSpaces";
import { useUser } from "App/components/useEntities/useUser";
import { useFiles } from "App/components/useFiles";
import { TrackEditorProvider } from "App/components/useTrackEditor";
import { sortRowByCreatedAtAscending } from "App/components/util";
import { Button, BUTTON_THEME } from "App/core/Button";
import { withMobileDeeplinkRoot } from "App/modules/urls";
import { isTouchDevice } from "App/modules/utils";
import { useUrlContext } from "App/routes/Main/useUrlContext";
import { useChildEntityWatchers } from "App/store/helpers/useChildEntityWatchers";
import { useGlobalSpaces } from "App/store/spaces/useGlobalSpaces";
import { useGlobalTracks } from "App/store/tracks/useGlobalTracks";
import { WatcherComponentId } from "App/store/watchers/useWatcherV2";
import { useSidebarContext } from "..";
import { MuteToggle } from "./MuteToggle";
import { PinToggle } from "./PinToggle/PinToggle";
import "./SpacePicker.scss";

// this is to make the column to have 22px width and 4px gutter as per figma mock
const nestedSpaceColumnSize = 26;

const EmptyListItem = ({
  indentation = 0,
  style,
}: {
  indentation?: number;
  style?: CSSProperties;
}) => {
  return (
    <ListItem
      className="empty-list-item"
      data-indentation={indentation}
      style={style}
    >
      <div className="row">Empty</div>
    </ListItem>
  );
};

const SpaceTrackItem = ({
  entity,
  indentation,
  isExpandable,
}: {
  entity: Track | Version;
  indentation: number;
  isExpandable?: boolean;
}) => {
  const history = useHistory();
  const { user } = useUser();
  const { confirm, renderConfirmation } = useConfirmation();
  const { trackId, versionIdPath } = useUrlContext();
  const { closeSidebar } = useSidebarContext();
  const { vw } = useViewport();
  const isMobile = vw <= 768 || isTouchDevice();

  const isTrackVersion = "track" in entity;
  const isTrack = "spaceId" in entity;

  const [isExpanded, setExpanded] = useState<boolean>(
    isExpandable && isTrack && trackId && entity?.id === trackId,
  );

  const navigateToTrackPage = () => {
    if (isTrackVersion) {
      history.push(
        `/space/${entity.track.spaceId}/${entity.track.id}/${entity.id}`,
      );
    } else if (isTrack) {
      history.push(`/space/${entity.spaceId}/${entity.id}`);
    }
    closeSidebar();
  };

  const isSelected =
    !isMobile && isTrackVersion
      ? versionIdPath === entity.id
      : !isMobile && trackId === entity?.id && !versionIdPath;

  return (
    <>
      <ListItemButton
        className={classNames("space-track-list-item", {
          "track-version": isTrackVersion,
        })}
        selected={isSelected}
        style={
          indentation > 0
            ? {
                paddingLeft: `${nestedSpaceColumnSize + indentation * nestedSpaceColumnSize}px`,
              }
            : undefined
        }
        onClick={() => {
          if (user?.isMobileUser && isMobile) {
            return confirm(trackDeeplinkConfirmPayload)
              .then(() => {
                const path = isTrackVersion
                  ? `${entity.track.spaceId}/${entity.track.id}/${entity.id}`
                  : `${(entity as Track).spaceId}/${(entity as Track).id}`;
                window.location.href = withMobileDeeplinkRoot(
                  `root/library/${path}`,
                );
              })
              .catch(navigateToTrackPage);
          }
          navigateToTrackPage();
        }}
      >
        <div className="row">
          {isExpandable && isTrack && (
            <Button
              theme={BUTTON_THEME.ICON}
              type="button"
              className="expander"
              onClick={(event) => {
                event.stopPropagation();
                setExpanded((prev) => !prev);
              }}
            >
              {isExpanded ? <CaretDownSVG /> : <CaretRightSVG />}
            </Button>
          )}
          <div
            className={classNames("column", {
              "track-version-icon": isTrackVersion,
              "track-icon": !isTrackVersion,
            })}
            data-id="icon"
          >
            {isTrackVersion ? <TrackVersionSVG /> : <MusicFillSVG />}
          </div>
          <div className="column" data-id="name">
            <PreviewText>
              {isTrackVersion ? entity.name : (entity as Track)?.title}
            </PreviewText>
          </div>
          <div className="column actions" data-id="actions">
            {isTrackVersion ? (
              <TrackVersionActions
                version={entity}
                actions={ALT_TRACK_VERSION_ACTIONS}
              />
            ) : (
              <DefaultTrackActions track={entity as Track} />
            )}
          </div>
        </div>
        {renderConfirmation}
      </ListItemButton>
      {isExpandable && isTrack && isExpanded && (
        <ExpandableTrackList track={entity} indentation={indentation + 1} />
      )}
    </>
  );
};

const ExpandableTrackList = ({
  track,
  indentation = 0,
}: {
  track?: Track;
  indentation?: number;
}) => {
  const { trackVersionsLoading, sortedTrackVersions } =
    useTrackChildItemRows(track);

  const childItemRows = useMemo(() => {
    const childTrackVersions =
      sortedTrackVersions.map((version) => {
        return {
          id: version.id,
          entity: version,
          component: (
            <SpaceTrackItem
              key={`${track?.id ? `${track.id}-` : ""}version-${version.id}`}
              entity={version.entity as Version}
              indentation={indentation}
            />
          ),
        };
      }) || [];

    return childTrackVersions;
  }, [sortedTrackVersions]);

  return (
    <List
      className="expandable-space-list"
      data-cypress-id="expandable-space-list"
    >
      {trackVersionsLoading && (
        <div className="highnote-base-table">
          <LoadingSpinner />
        </div>
      )}
      {childItemRows.length === 0 && !trackVersionsLoading ? (
        <EmptyListItem
          indentation={indentation}
          style={
            indentation > 0
              ? {
                  paddingLeft: `${nestedSpaceColumnSize + indentation * nestedSpaceColumnSize}px`,
                }
              : undefined
          }
        />
      ) : (
        childItemRows.map((spaceItem) => {
          return spaceItem.component;
        })
      )}
    </List>
  );
};

const ExpandableSpaceItem = ({
  space,
  indentation = 0,
  artworkUrl,
  componentId,
}: {
  space: Space;
  componentId: WatcherComponentId;
  indentation?: number;
  artworkUrl?: string;
}) => {
  const history = useHistory();
  const { spaceId, trackId } = useUrlContext();
  const { closeSidebar } = useSidebarContext();
  const [isExpanded, setExpanded] = useState<boolean>(
    spaceId && space?.id === spaceId,
  );
  const { isMobile } = useViewport();

  return (
    <>
      <ListItemButton
        className="expandable-space-item"
        selected={!isMobile && spaceId === space.id && !trackId}
        style={
          indentation > 0
            ? {
                paddingLeft: `${nestedSpaceColumnSize + indentation * nestedSpaceColumnSize}px`,
              }
            : undefined
        }
        data-cypress-id="expandable-space-item"
        data-cypress-space-id={space.id}
        onClick={() => {
          history.push(`/space/${space.id}`);
          closeSidebar();
        }}
      >
        <div className="row">
          <Button
            theme={BUTTON_THEME.ICON}
            type="button"
            className="expander"
            onClick={(event) => {
              event.stopPropagation();
              setExpanded(!isExpanded);
            }}
          >
            {isExpanded ? <CaretDownSVG /> : <CaretRightSVG />}
          </Button>
          {space.artworkFile ? (
            <div className="column space-artwork" data-id="icon">
              <PreviewIcon
                size={PREVIEW_ICON_SIZE.SMALL}
                src={artworkUrl}
                fallback={<FolderHollowSVG />}
              />
            </div>
          ) : (
            <div className={classNames("column space-icon")} data-id="icon">
              <FolderHollowSVG />
            </div>
          )}
          <div className="column" data-id="name">
            <PreviewText>{space.name}</PreviewText>
          </div>

          <div className="column" data-id="mute">
            <MuteToggle entity={space} />
          </div>

          <div className="column" data-id="pinned">
            <PinToggle entity={space} className="PinToggle" />
          </div>

          <div className="column actions" data-id="actions">
            <DefaultSpaceActions space={space} />
          </div>
        </div>
      </ListItemButton>
      {isExpanded && (
        <ExpandableSpaceListItem
          space={space}
          indentation={indentation + 1}
          isExpanded={isExpanded}
          componentId={componentId}
        />
      )}
    </>
  );
};

const ExpandableSpaceListItem = ({
  space,
  indentation = 0,
  isExpanded,
  componentId,
}: {
  space?: Space;
  indentation?: number;
  isExpanded?: boolean;
  componentId: WatcherComponentId;
}) => {
  const { topLevelSpaces } = useTopLevelSpaces();
  const { globalSpaces } = useGlobalSpaces();
  const { globalTracks } = useGlobalTracks();
  const { getDownloadUrl } = useFiles();
  const [spacesArtworkUrls, setSpacesArtworkUrls] = useState(
    new Map<string, string>(),
  );
  const [spacesArtworkUrlsArray, setSpacesArtworkUrlsArray] = useState<
    Set<string>
  >(new Set());

  const { itemsOrder = [] } = space || {};

  // Fetches all child spaces and tracks of the space
  const { manageGlobalChildEntityWatchers } = useChildEntityWatchers();

  useEffect(() => {
    if (space?.id && isExpanded) {
      const watcher = manageGlobalChildEntityWatchers({
        spaceId: space.id,
        componentId,
      });
      watcher.attach();
      return () => {
        watcher.detach();
      };
    }
  }, [space?.id, isExpanded, manageGlobalChildEntityWatchers]);

  useEffect(() => {
    const fetchArtworkUrls = async () => {
      if (spacesArtworkUrlsArray.size === 0) return;

      const fileIds = Array.from(spacesArtworkUrlsArray);
      const files = await highnote.getFiles({ ids: fileIds });

      const mappedUrls = new Map<string, string>();
      for (const file of files) {
        const url = await getDownloadUrl(file);
        if (url) {
          mappedUrls.set(file.id, url);
        }
      }
      setSpacesArtworkUrls(mappedUrls);
    };

    fetchArtworkUrls();
  }, [spacesArtworkUrlsArray, getDownloadUrl]);

  // Update artwork file IDs in state
  const updateArtworkFileIds = (artworkFileId: string) => {
    setSpacesArtworkUrlsArray((prev) => {
      if (!prev.has(artworkFileId)) {
        const newSet = new Set(prev);
        newSet.add(artworkFileId);
        return newSet;
      }
      return prev;
    });
  };

  const spaceTracks = useMemo(() => {
    // this means that this component is being rendered at the top level,
    // so we are hiding anything that is not a space.
    if (!space || !globalTracks?.childTracks?.get(space.id)) return [];
    return globalTracks?.childTracks?.get(space.id).map((trackId) => {
      const currentTrack = globalTracks.tracks?.get(trackId);
      return {
        id: trackId,
        entity: currentTrack,
        component:
          currentTrack.versionFilesV2.length > 1 ? (
            <SpaceTrackItem
              key={`${space?.id ? `${space.id}-` : ""}expandable-track-${trackId}`}
              indentation={indentation}
              entity={currentTrack}
              isExpandable
            />
          ) : (
            <SpaceTrackItem
              key={`${space?.id ? `${space.id}-` : ""}track-${trackId}`}
              entity={currentTrack}
              indentation={indentation}
            />
          ),
      };
    });
  }, [space, globalTracks.childTracks, globalTracks.tracks]);

  const childSpaces = useMemo(() => {
    const processSpaces = (spaces: Space[]) =>
      spaces.map((currentSpace) => {
        if (currentSpace.artworkFile) {
          updateArtworkFileIds(currentSpace.artworkFile);
        }
        return {
          id: currentSpace.id,
          entity: currentSpace,
          component: (
            <ExpandableSpaceItem
              key={currentSpace.id}
              space={currentSpace}
              indentation={indentation}
              artworkUrl={spacesArtworkUrls.get(currentSpace.artworkFile)}
              componentId={componentId}
            />
          ),
        };
      });

    if (!space) {
      const filteredTopLevelSpaces = topLevelSpaces.filter(
        (currentSpace) => !currentSpace.isArchived,
      );
      return processSpaces(filteredTopLevelSpaces);
    }

    const currentSpaces = globalSpaces?.childSpaces?.get(space?.id) || [];
    const filteredChildSpaces = currentSpaces
      .map((id) => globalSpaces.spaces.get(id))
      .filter((currentSpace) => currentSpace && !currentSpace.isArchived);

    return processSpaces(filteredChildSpaces as Space[]);
  }, [
    space,
    topLevelSpaces,
    globalSpaces.childSpaces,
    globalSpaces.spaces,
    spacesArtworkUrls,
    indentation,
  ]);

  const spaceItems = useMemo(() => {
    if (!space) {
      return childSpaces;
    }
    const allItems = [...spaceTracks, ...childSpaces];
    const { orderedItems, unOrderedItems } = sortItemsByItemsOrder(
      itemsOrder,
      allItems,
    );
    unOrderedItems.sort((aRow, bRow) => {
      return sortRowByCreatedAtAscending(
        aRow as unknown as EntityRowConfig,
        bRow as unknown as EntityRowConfig,
      );
    });
    return [...orderedItems, ...unOrderedItems];
  }, [itemsOrder, spaceTracks, childSpaces]);

  return (
    <List
      className="expandable-space-list"
      data-cypress-id="expandable-space-list"
    >
      {spaceItems.map((spaceItem) => {
        return spaceItem.component;
      })}
      {spaceItems.length === 0 && (
        <EmptyListItem
          indentation={indentation}
          style={
            indentation > 0
              ? {
                  paddingLeft: `${nestedSpaceColumnSize + indentation * nestedSpaceColumnSize}px`,
                }
              : undefined
          }
        />
      )}
    </List>
  );
};

const PinnedSpacesList = () => {
  const { user } = useAuth();
  const pinnedSpaces = user?.pinnedSpaces;
  const { getDownloadUrl } = useFiles();
  const [isLoadingSpaces, setIsLoadingSpaces] = useState(false);
  const [spacesArtworkUrls, setSpacesArtworkUrls] = useState(
    new Map<string, string>(),
  );
  const { globalSpaces, manageGlobalSingleSpaceWatcher } = useGlobalSpaces();

  const globalPinnedSpaces = useMemo(
    () =>
      (pinnedSpaces ?? []).reduce<Space[]>((acc, id) => {
        const space = globalSpaces.spaces.get(id);
        if (space) acc.push(space);
        return acc;
      }, []),
    [pinnedSpaces, globalSpaces.spaces],
  );

  // Derive artwork file IDs from spaces
  const artworkFileIds = useMemo(
    () => globalPinnedSpaces.map((space) => space.artworkFile).filter(Boolean),
    [globalPinnedSpaces],
  );

  // Fetch artwork URLs when file IDs change
  useEffect(() => {
    if (artworkFileIds.length === 0) return;

    const fetchArtworkUrls = async () => {
      const files = await highnote.getFiles({ ids: artworkFileIds });
      const mappedUrls = new Map<string, string>();

      await Promise.all(
        files.map(async (file) => {
          const url = await getDownloadUrl(file);
          if (url) mappedUrls.set(file.id, url);
        }),
      );

      setSpacesArtworkUrls(mappedUrls);
    };

    fetchArtworkUrls();
  }, [artworkFileIds, getDownloadUrl]);

  // Fetch missing spaces
  useEffect(() => {
    let isMounted = true;
    const spacesToFetch = pinnedSpaces?.filter(
      (id) => !globalPinnedSpaces.some((space) => space.id === id),
    );

    if (!spacesToFetch?.length) {
      if (isMounted) {
        setIsLoadingSpaces(false);
        return;
      }
    }

    if (isMounted) {
      setIsLoadingSpaces(true);
    }

    // Use Promise.all to handle all space fetches in parallel
    // TODO(GlobalSpace): Find a way to fetch in one batch/transaction instead of multiple separate requests
    Promise.all(
      spacesToFetch.map(
        (spaceId) =>
          new Promise((resolve) =>
            manageGlobalSingleSpaceWatcher({
              action: "attach",
              spaceId,
              componentId: "space-picker-pinned-space",
              onSpaceAdd: resolve,
            }),
          ),
      ),
    ).finally(() => {
      if (isMounted) {
        setIsLoadingSpaces(false);
      }
    });

    return () => {
      isMounted = false;
      setIsLoadingSpaces(false);
      // Clean up watchers on unmount or when pinnedSpaces changes
      spacesToFetch.forEach((spaceId) =>
        manageGlobalSingleSpaceWatcher({
          action: "detach",
          spaceId,
          componentId: "space-picker-pinned-space",
        }),
      );
    };
  }, [pinnedSpaces, globalPinnedSpaces]);

  return (
    <>
      {isLoadingSpaces && (
        <div className="highnote-base-table">
          <LoadingSpinner />
        </div>
      )}
      {globalPinnedSpaces.map((space) => (
        <ExpandableSpaceItem
          key={space.id}
          space={space}
          indentation={0}
          artworkUrl={spacesArtworkUrls.get(space.artworkFile)}
          componentId="space-picker-pinned-space"
        />
      ))}
    </>
  );
};

export const SpacePicker = () => {
  return (
    <div className="space-picker" data-cypress-id="space-picker">
      <span className="spaces-header">Spaces</span>

      <div className="inner">
        <List className="space-list">
          <ListSubheader className="sidebar-spaces-list-header">
            Pinned
          </ListSubheader>
          <TrackEditorProvider>
            <PinnedSpacesList />
          </TrackEditorProvider>
        </List>

        <br />
      </div>

      <div className="inner">
        <List className="space-list">
          <ListSubheader className="sidebar-spaces-list-header">
            All
          </ListSubheader>
          <TrackEditorProvider>
            <ExpandableSpaceListItem componentId="space-picker-all-spaces" />
          </TrackEditorProvider>
        </List>

        <br />
      </div>
    </div>
  );
};
