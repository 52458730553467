import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import { ErrorBoundary } from "App/common/ErrorBoundary";
import { ReactComponent as AddSVG } from "App/common/icons-v2/add.svg";
import { ReactComponent as EllipsisVerticalSVG } from "App/common/icons-v2/dots-vertical.svg";
import { ReactComponent as LogoMiniSVG } from "App/common/icons-v2/logo-mini-beta.svg";
import { List, ListItemButton } from "App/common/List";
import { Menu, MenuItem } from "App/common/Menu";
import { useHighnote } from "App/common/useHighnote";
import {
  ConnectedUserAvatar,
  USER_AVATAR_SIZE,
} from "App/common/UserAvatar/UserAvatar";
import { useViewport } from "App/common/useViewport";
import { useAuth } from "App/components/Auth";
import { CustomIntercomLauncher } from "App/components/CustomIntercomLauncher/CustomIntercomLauncher";
import { Notifications } from "App/components/Layout/Notifications";
import { Navigation } from "App/components/Navigation";
import { PlanLimitCallout } from "App/components/Plans/PlanLimitCallout";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { routePaths } from "App/modules/urls";
import { matchAppPath } from "App/modules/utils";
import { useSidebarContext } from "../Sidebar";
import { SpacePicker } from "./SpacePicker";
import { SUBSCRIPTION_TIER } from "@highnote/server/src/core/entities";

import "./GlobalSidebar.scss";

let firstLoad = true;

export const GlobalSidebar = () => {
  const history = useHistory();
  const { closeSidebar, openSidebar } = useSidebarContext();
  const { user: currentUser } = useAuth();
  const userMenuRef = useRef<HTMLButtonElement>();
  const [isUserMenuOpen, setUserMenuOpen] = useState(false);
  const { isMobile } = useViewport();
  const { createSpace } = useHighnote();

  const isLoggedIn = !!currentUser;

  useEffect(() => {
    if (!firstLoad) return;
    if (isLoggedIn) {
      if (isMobile && !matchAppPath(routePaths.root)) return;
      openSidebar(true);
      firstLoad = false;
    }
  }, [isLoggedIn]);

  if (!isLoggedIn) return null;

  return (
    <div
      className="highnote-global-side-nav"
      data-cypress-id="highnote-global-side-nav"
    >
      <Navigation className="highnote-sidebar-header" hideBackButton>
        <Link to="/" data-cypress-id="highnote-logo" className="logo">
          <LogoMiniSVG />
        </Link>

        <CustomIntercomLauncher
          size={BUTTON_SIZE.LARGE}
          className="global-sidebar-custom-intercom-launcher"
        />

        <Notifications />

        <button
          ref={userMenuRef}
          className="open-user-menu"
          onClick={() => setUserMenuOpen(true)}
        >
          <ConnectedUserAvatar
            userId={currentUser.id}
            // alias={currentUser.isAnonymous && currentUser.name}
            size={USER_AVATAR_SIZE.LARGE}
          />
          <EllipsisVerticalSVG />
        </button>
        <Menu
          className="primary-navigation-user-menu"
          anchorEl={userMenuRef.current}
          onClose={() => {
            setUserMenuOpen(false);
          }}
          open={isUserMenuOpen}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem disabled>{currentUser.name}</MenuItem>
          <MenuItem
            className="bottom-spacer"
            onClick={() => {
              setUserMenuOpen(false);
              closeSidebar();
            }}
          >
            <Link to={"/settings/profile"}>Account</Link>
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push("/logout");
            }}
          >
            Log out
          </MenuItem>
        </Menu>
      </Navigation>

      <ErrorBoundary name="GlobalSidebar">
        <div className="highnote-global-side-nav-content">
          <section className="library-link">
            <List>
              <ListItemButton
                selected={
                  !isMobile && matchAppPath(routePaths.library)?.isExact
                }
                onClick={() => {
                  closeSidebar();
                }}
              >
                <Link to={"/library"}>
                  <span className="library-text">Library</span>
                </Link>
              </ListItemButton>
            </List>
          </section>

          <section className="spaces-section">
            <SpacePicker />
          </section>
        </div>
      </ErrorBoundary>

      <section className="plan-limit-callout">
        <PlanLimitCallout
          forceShowCallout={
            currentUser.subscriptionTier === SUBSCRIPTION_TIER.FREE
          }
          theme="monetization"
        />
      </section>
      <div className="highnote-global-side-nav-footer">
        <Button
          startIcon={<AddSVG />}
          theme={BUTTON_THEME.PRIMARY_INVERSE}
          size={BUTTON_SIZE.MEDIUM}
          fullWidth
          data-cypress-id="create-new-space"
          onClick={async () => {
            const space = await createSpace();
            history.push(`/space/${space.id}`);
            closeSidebar();
          }}
        >
          New Space
        </Button>
      </div>
    </div>
  );
};
