import { useCallback } from "react";
import { QueryConstraint, where } from "firebase/firestore";

import { COLLECTION_ID } from "@highnote/server/src/core/entities";
import { useGlobalSpaces } from "../spaces/useGlobalSpaces";
import { useGlobalTracks } from "../tracks/useGlobalTracks";

/**
 * Fetches all child spaces and tracks of the space.
 * Controlled by an expanded row state, or the mounted component it's initialized in.
 */
export const useChildEntityWatchers = () => {
  const {
    manageGlobalSpacesWatchers,
    globalSpacesDispatch,
    globalSpacesWatchers,
    globalSpaces,
  } = useGlobalSpaces();
  const {
    manageGlobalTracksWatchers,
    globalTracksDispatch,
    globalTracksWatchers,
    globalTracks,
  } = useGlobalTracks();

  const setGlobalChildEntityWatchers = ({
    collectionId,
    action,
    parentSpaceId,
    constraints,
    limit,
  }: {
    collectionId: COLLECTION_ID;
    action: "attach" | "detach";
    parentSpaceId: string;
    constraints?: QueryConstraint[];
    limit?: number;
  }) => {
    if (collectionId === COLLECTION_ID.SPACE) {
      return manageGlobalSpacesWatchers({
        action,
        collectionId,
        watcherKey: parentSpaceId,
        constraints,
        limit,
        setEntities: (entities) => {
          globalSpacesDispatch({
            type: "ADD_CHILD_SPACES",
            payload: { parentSpaceId, entities: entities },
          });
        },
      });
    }

    if (collectionId === COLLECTION_ID.TRACK) {
      return manageGlobalTracksWatchers({
        action,
        collectionId,
        watcherKey: parentSpaceId,
        constraints,
        limit,
        setEntities: (entities) => {
          globalTracksDispatch({
            type: "ADD_CHILD_TRACKS",
            payload: {
              parentSpaceId,
              entities: entities,
            },
          });
        },
      });
    }
  };

  const manageGlobalChildEntityWatchers = useCallback(
    ({
      spaceId,
      collectionIdsToWatch = [COLLECTION_ID.SPACE, COLLECTION_ID.TRACK],
    }: {
      spaceId?: string;
      collectionIdsToWatch?: COLLECTION_ID[];
    }) => {
      return {
        attach: async (constraints?: QueryConstraint[], limit?: number) => {
          if (spaceId) {
            return Promise.all(
              collectionIdsToWatch.map((collectionIdToTrack) => {
                return setGlobalChildEntityWatchers({
                  action: "attach",
                  collectionId: collectionIdToTrack,
                  parentSpaceId: spaceId,
                  constraints: constraints || [where("spaceId", "==", spaceId)],
                  limit,
                });
              }),
            );
          }

          return;
        },
        detach: async (constraints?: QueryConstraint[]) => {
          if (spaceId) {
            return Promise.all(
              collectionIdsToWatch.map((collectionIdToTrack) => {
                return setGlobalChildEntityWatchers({
                  action: "detach",
                  collectionId: collectionIdToTrack,
                  parentSpaceId: spaceId,
                  constraints: constraints || [where("spaceId", "==", spaceId)],
                });
              }),
            );
          }

          return;
        },
      };
    },
    [],
  );

  /**
   * Used to check if there are any active watchers for the parent space
   * @param parentSpaceId - string
   * @returns boolean
   */
  const hasActiveChildEntityWatchers = (parentSpaceId: string) => {
    return (
      // Has existing Child Space Watchers
      globalSpacesWatchers.has(parentSpaceId) ||
      // Has existing Child Track Watchers
      globalTracksWatchers.has(parentSpaceId)

      // TODO: Has existing Child Files/Attachment Watchers
    );
  };

  const getChildTracks = (parentSpaceId: string) => {
    const childTracks = globalTracks.childTracks.get(parentSpaceId) || [];
    return childTracks.map((childTrackId) =>
      globalTracks.tracks.get(childTrackId),
    );
  };

  const getChildSpaces = (parentSpaceId: string) => {
    const childSpaces = globalSpaces.childSpaces.get(parentSpaceId) || [];
    return childSpaces.map((childSpaceId) =>
      globalSpaces.spaces.get(childSpaceId),
    );
  };

  return {
    manageGlobalChildEntityWatchers,
    hasActiveChildEntityWatchers,
    getChildTracks,
    getChildSpaces,
  };
};
