import React, { useEffect, useState } from "react";

import { AUDIO_ACCEPT_TYPES_LIST } from "@highnote/server/src/core/entities";
import {
  AUDIO_UPLOAD_SIZE_LIMIT_IN_BYTES,
  DropboxManagerBase,
} from "@highnote/server/src/core/shared-util";
import { useAuth } from "App/components/Auth";
import { getUserDropboxJobsByFileIds } from "App/services/firebase/entities/helpers";
import { useToast } from "./useToast";

type DropboxFilesFromChooser = Array<{
  id: string;
  bytes: number;
  name: string;
}>;

const chooserScriptId = "dropboxjs";

const getDropbox = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (window as any).Dropbox;
};

// https://www.dropbox.com/developers/chooser
const baseChooserOptions = {
  // called when a user selects an item in the Chooser.
  success: () => {},
  // called when the Chooser dialog is closed
  cancel: () => {},
  multiselect: true,
  extensions: [...AUDIO_ACCEPT_TYPES_LIST],
  folderselect: false,
  sizeLimit: AUDIO_UPLOAD_SIZE_LIMIT_IN_BYTES,
};

export const useDropboxChooser = () => {
  const { user } = useAuth();
  const { addErrorMessage } = useToast();

  const [dropboxReady, setDropboxReady] = useState(Boolean(getDropbox()));
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (!user?.dropboxAccountId) {
      return;
    }
    if (document.getElementById(chooserScriptId)) {
      return;
    }
    const script = document.createElement("script");
    script.setAttribute("id", chooserScriptId);
    script.setAttribute(
      "data-app-key",
      process.env.REACT_APP_DROPBOX_CLIENT_ID,
    );
    script.src = "https://www.dropbox.com/static/api/2/dropins.js";
    script.async = true;
    script.addEventListener("load", () => {
      setDropboxReady(getDropbox());
    });
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [user?.dropboxAccountId]);

  useEffect(() => {
    const closeChooser = () => {
      getDropbox()?.cancelChooser();
    };

    window.addEventListener("beforeunload", closeChooser);
    return () => {
      window.removeEventListener("beforeunload", closeChooser);
    };
  }, []);

  const handleOnSelectError = (error: React.ReactNode | string) => {
    addErrorMessage(error);
    setIsProcessing(false);
  };

  const onSelect = async (
    files: DropboxFilesFromChooser,
    onSuccess: (fileIds: string[]) => Promise<void>,
    onCancel?: () => void,
  ) => {
    try {
      setIsProcessing(true);
      // make sure that the user has not selected more than 10 files
      if (
        files.length >
        DropboxManagerBase.getNumberOfFilesLimit(user?.subscriptionTier)
      ) {
        handleOnSelectError(
          DropboxManagerBase.getTooManyFilesErrorMsg(user?.subscriptionTier),
        );
        return;
      }
      // make sure that the files have not been cloned before
      const existingJobs = await getUserDropboxJobsByFileIds({
        fileIds: files.map((file) => file.id),
        userId: user.id,
      });
      if (existingJobs.length > 0) {
        const dupeFiles = existingJobs
          .map((job) => files.find((file) => job.fileId === file.id))
          .filter(Boolean)
          .map((file) => file.name);
        handleOnSelectError(
          <div>
            <p>The following files have already been cloned:</p>
            <ul>
              {dupeFiles.map((dupeFile, i) => (
                <li key={`${dupeFile}-${i}`}>{dupeFile}</li>
              ))}
            </ul>
          </div>,
        );
        return;
      }
      await onSuccess(files.map((file) => file.id));
    } catch (e) {
      handleOnSelectError("Something went wrong");
      console.error(
        "Something went wrong while checking the Dropbox files: ",
        e,
      );
    } finally {
      setIsProcessing(false);
      onCancel?.();
    }
  };

  return {
    isProcessing,
    dropboxChooserReady: user?.dropboxAccountId && dropboxReady,
    openDropboxChooser: ({
      onCancel,
      onSuccess,
    }: {
      onCancel?: () => void;
      onSuccess: (fileIds: string[]) => Promise<void>;
    }) => {
      if (!user?.dropboxAccountId || isProcessing) {
        return;
      }
      setIsProcessing(true);
      getDropbox().choose({
        ...baseChooserOptions,
        cancel: () => {
          setIsProcessing(false);
          onCancel?.();
        },
        success: (files: DropboxFilesFromChooser) =>
          onSelect(files, onSuccess, onCancel),
      });
    },
  };
};
