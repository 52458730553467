import { Track } from "@highnote/server/src/core/entities";
import { TracksProvider, useTracks } from "App/components/useEntities";
import React, { createContext, useContext, useMemo } from "react";
import { useAuth } from "../Auth";
import { where } from "firebase/firestore";
import { useSpaceContext } from "App/common/useSpace";

type CreatedTracksContextValue = {
  createdTracks: Track[];
  createdTracksLoading: boolean;
  totalLimitCreatedTracks: number;
  loadMoreCreatedTracks: () => void;
};

const CreatedTracksContext = createContext<CreatedTracksContextValue>({
  createdTracks: [],
  createdTracksLoading: true,
  totalLimitCreatedTracks: 0,
  loadMoreCreatedTracks: () => undefined,
});

const _CreatedTracksContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    entities: createdTracks,
    loading: createdTracksLoading,
    loadMore: loadMoreCreatedTracks,
    totalLimit: totalLimitCreatedTracks,
  } = useTracks();

  const value = useMemo(() => {
    return {
      createdTracks,
      createdTracksLoading,
      loadMoreCreatedTracks,
      totalLimitCreatedTracks,
    };
  }, [
    createdTracks,
    createdTracksLoading,
    loadMoreCreatedTracks,
    totalLimitCreatedTracks,
  ]);
  return (
    <CreatedTracksContext.Provider value={value}>
      {children}
    </CreatedTracksContext.Provider>
  );
};

export const CreatedTracksContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user } = useAuth();
  const { spaceId } = useSpaceContext();

  if (!user?.id || !spaceId) return <>{children}</>;

  const constraints = useMemo(() => {
    return [
      where("createdBy", "==", user?.id),
      where("spaceId", "==", spaceId),
    ];
  }, [user?.id, spaceId]);

  return (
    <TracksProvider constraints={constraints}>
      <_CreatedTracksContextProvider>{children}</_CreatedTracksContextProvider>
    </TracksProvider>
  );
};

export const useCreatedTracks = () => useContext(CreatedTracksContext);
