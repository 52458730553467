// FN_GROUP_NAME is an environment variable that is set based on the github pull request
// number. And it will be made available only when a given PR has changes inside
// @highnote/server directory (via netlify-build.command.sh).
// The purpose of this environment variable is to group cloud functions together so that
// existing cloud functions are not overwritten by other PRs, giving each PR's preview link
// its own backend environment. It also allows us to delete all cloud functions associated
// with a PR when the PR is merged or closed.
export const functionGroupName = process.env.FN_GROUP_NAME || "";

// IMPORTANT: ensure that all function IDs are unique and do not contain hyphens,
// as hyphens are used to prefix IDs with the group name.
export const functionIds: Record<string, string> = {
  REFRESH_DROPBOX_TOKEN: "refreshDropboxToken",
  CLONE_DROPBOX_ENTITITES: "cloneDropboxEntities",
  HANDLE_DROPBOX_JOB: "handleDropboxJob",
  RETRY_DROPBOX_JOB: "retryDropboxJob",
  DELETE_DROPBOX_JOB: "deleteDropboxJob",

  ASSOCIATE_OAUTH_ACCOUNT: "associateOAuthAccount",

  CHECK_PROTECTED_ENTITY: "checkProtectedEntity",
  JOIN_PROTECTED_ENTITY: "joinProtectedEntity",

  DELETE_OR_REMOVE_ENTITIES: "deleteOrRemoveEntities",

  CREATE_SPACE: "createSpace",
  UPDATE_SPACE: "updateSpace",
  DELETE_SPACE: "deleteSpace",

  CREATE_TRACK: "createTrack",
  UPDATE_TRACK: "updateTrack",
  DELETE_TRACK: "deleteTrack",
  GET_TRACK: "getTrack",
  ADD_TRACK_PLAYED: "addTrackPlayed",

  CREATE_OR_UPDATE_FILE: "createOrUpdateFile",
  PROCESS_AUDIO_FILE: "processAudioFile",
  GENERATE_DOWNLOAD_LINK: "generateDownloadLink",
  ACKNOWLEDGE_DOWNLOAD_REQUEST: "acknowledgeDownloadRequest",
  GET_FILES: "getFiles",
  GET_STORAGE_FILES: "getStorageFiles",
  DELETE_STORAGE_FILE: "deleteStorageFile",
  GET_FILE_REFERENCES: "getFileReferences",

  GET_COMMENT: "getComment",
  CREATE_COMMENT: "createComment",
  UPDATE_COMMENT: "updateComment",
  DELETE_COMMENT: "deleteComment",

  GET_KNOCK_AUTH_TOKEN: "getKnockAuthToken",

  GET_FILE_URL: "getFileUrl",
  NOTIFY: "notify",

  UPDATE_USER: "updateUser",
  UPDATE_AUTH0_USER: "updateAuth0User",
  VERIFY_AUTH0_EMAIL: "verifyAuth0Email",
  RESET_AUTH0_PASSWORD: "resetAuth0Password",

  UPDATE_USER_SUBSCRIPTION: "payments_updateUserSubscription",
  GET_PRICE: "payments_getPrice",
  GET_PAYMENT_PORTAL_URL: "payments_getCustomerPortalUrl",
  GET_CHECKOUT_URL: "payments_getCheckoutUrl",

  AUTHENTICATE_USER: "authenticateUser",
  SEND_VERIFICATION_CODE: "sendVerificationCode",
  GET_USER_PUBLIC: "getUserPublic",
  GET_USERS_PUBLIC: "getUsersPublic",
  SEARCH_USERS: "searchUsers",
  UNLINK_ACCOUNT: "unlinkAccount",

  API_GET_SPACE: "api_getSpace",
  API_GET_TRACK: "api_getTrack",
  API_RESET_TEST_DATA: "api_resetTestData",

  // these are for functions that are automatically triggered by firestore events
  ON_USER_WRITE: "onUserWrite",
  ON_DOWNLOAD_REQUEST_UPDATE: "onDownloadRequestUpdate",
  ON_FILE_UPDATE: "onFileUpdate",
  ON_SPACE_UPDATE: "onSpaceUpdate",
  ON_TRACK_UPDATE: "onTrackUpdate",
  ON_ENTITY_CREATE: "onEntityCreate",
  ON_ENTITY_DELETE: "onEntityDelete",
  ON_ENTITY_WRITE: "onEntityWrite",

  // this is triggered automatically by uploads to cloud storage bucket
  ON_FILE_UPLOAD: "onFileUpload",

  WEBHOOK_WELCOME_SURVEY: "webhook_welcomeSurvey",
  WEBHOOK_STRIPE: "webhook_stripe",
  WEBHOOK_KNOCK: "webhook_knock",

  MOVE_SELECTION_TO_ENTITY: "moveSelectionToEntity",

  // tasks that are triggered in Cloud Tasks
  TASK_SYNC_ENTITIES: "taskSyncEntities",
};

const onEventFunctionIDs = [
  functionIds.ON_USER_WRITE,
  functionIds.ON_DOWNLOAD_REQUEST_UPDATE,
  functionIds.ON_FILE_UPDATE,
  functionIds.ON_SPACE_UPDATE,
  functionIds.ON_TRACK_UPDATE,
  functionIds.ON_ENTITY_CREATE,
  functionIds.ON_ENTITY_DELETE,
  functionIds.ON_ENTITY_WRITE,
  functionIds.ON_FILE_UPLOAD,
  functionIds.WEBHOOK_WELCOME_SURVEY,
  functionIds.WEBHOOK_STRIPE,
  functionIds.WEBHOOK_KNOCK,
  functionIds.TASK_SYNC_ENTITIES,
];

export const FUNCTION_ID = Object.keys(functionIds).reduce<
  Record<string, string>
>((acc, key) => {
  // we should have only one instance of a function within a given GCP
  // if it is a function triggered by events (e.g., upload to bucket or webhooks).
  // so we just exclude these functions from the group to prevent having multiple
  // instances of these functions in the same project.
  if (onEventFunctionIDs.includes(functionIds[key])) {
    acc[key] = functionIds[key];
    return acc;
  }
  if (functionIds[key].includes("-")) {
    throw new Error("function ID cannot contain hyphens.");
  }
  acc[key] = functionGroupName
    ? `${functionGroupName}-${functionIds[key]}`
    : functionIds[key];
  return acc;
}, {});

export const MIN_FIREBASE_INSTANCES =
  process.env.DEPLOY_ENV === "production" ? 2 : 0;
