import "./SpacePicker.scss";
import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { List, ListItem, ListItemButton } from "App/common/List";
import {
  COLLECTION_ID,
  Space,
  Track,
} from "@highnote/server/src/core/entities";
import { DefaultSpaceActions } from "App/common/SpaceActions";
import { PreviewText } from "@highnote/preview-text/src";
import { Button, BUTTON_THEME } from "App/core/Button";
import { ReactComponent as CaretDownSVG } from "App/common/icons/toggle-filled-down.svg";
import { ReactComponent as CaretRightSVG } from "App/common/icons/toggle-hollow-right.svg";
import { ReactComponent as FolderHollowSVG } from "App/common/icons/folder-hollow.svg";
import { ReactComponent as MusicNoteHollowSVG } from "App/common/icons/music-note-hollow.svg";
import { useHistory } from "react-router";
import { useUrlContext } from "App/routes/Main/useUrlContext";
import { sortRowByCreatedAtAscending } from "App/components/util";
import { useSidebarContext } from "..";
import { useViewport } from "App/common/useViewport";
import { NewEntityMenu } from "App/common/NewEntityMenu/NewEntityMenu";
import { MuteToggle } from "./MuteToggle";
import { DefaultTrackActions } from "App/components/useEntities/useLibraryTracks/TrackActions";
import { useTopLevelSpaces } from "App/components/useEntities/useTopLevelSpaces";
import { TrackEditorProvider } from "App/components/useTrackEditor";
import { EntityRowConfig } from "App/components/EntityTable";
import { useGlobalSpaces } from "App/store/spaces/useGlobalSpaces";
import { useGlobalTracks } from "App/store/tracks/useGlobalTracks";
import { useChildEntityWatchers } from "App/store/helpers/useChildEntityWatchers";
import { useConfirmation } from "App/common/useConfirmation";
import { trackDeeplinkConfirmPayload } from "App/components/EntityTable/ExpandableSpace/ExpandableSpaceRow";
import { useUser } from "App/components/useEntities/useUser";
import { withMobileDeeplinkRoot } from "App/modules/urls";
import { isTouchDevice } from "App/modules/utils";
import { sortItemsByItemsOrder } from "@highnote/server/src/core/shared-util";
import { useTrackChildItemRows } from "App/components/EntityTable/ExpandableTrack/ExpandableTrackRow";
import {
  ALT_TRACK_VERSION_ACTIONS,
  TrackVersionActions,
} from "App/components/TrackEditor/TrackVersions/TrackVersionActions";
import { Version } from "App/components/EntityTable/config";
import { TrackVersionIcon } from "App/components/TrackEditor/TrackVersions/TrackVersionIcon";
import { LoadingSpinner } from "App/common/icons/LoadingSpinner";
import classNames from "classnames";

// this is to make the column to have 16px width and 4px gutter as per figma mock
const nestedSpaceColumnSize = 20;

const EmptyListItem = ({
  indentation = 0,
  style,
}: {
  indentation?: number;
  style?: CSSProperties;
}) => {
  return (
    <ListItem
      className="empty-list-item"
      data-indentation={indentation}
      style={style}
    >
      <div className="row">Empty</div>
    </ListItem>
  );
};

const SpaceTrackItem = ({
  entity,
  indentation,
  isExpandable,
}: {
  entity: Track | Version;
  indentation: number;
  isExpandable?: boolean;
}) => {
  const history = useHistory();
  const { user } = useUser();
  const { confirm, renderConfirmation } = useConfirmation();
  const { trackId, versionIdPath } = useUrlContext();
  const { closeSidebar } = useSidebarContext();
  const { vw } = useViewport();
  const isMobile = vw <= 768 || isTouchDevice();

  const isTrackVersion = "track" in entity;
  const isTrack = "spaceId" in entity;

  const [isExpanded, setExpanded] = useState<boolean>(
    isExpandable && isTrack && trackId && entity?.id === trackId,
  );

  const navigateToTrackPage = () => {
    if (isTrackVersion) {
      history.push(
        `/space/${entity.track.spaceId}/${entity.track.id}/${entity.id}`,
      );
    } else if (isTrack) {
      history.push(`/space/${entity.spaceId}/${entity.id}`);
    }
    closeSidebar();
  };

  const isSelected =
    !isMobile && isTrackVersion
      ? versionIdPath === entity.id
      : !isMobile && trackId === entity?.id && !versionIdPath;

  return (
    <>
      <ListItemButton
        className="space-track-list-item"
        selected={isSelected}
        style={{
          paddingLeft: `${nestedSpaceColumnSize + indentation * nestedSpaceColumnSize}px`,
        }}
        onClick={() => {
          if (user?.isMobileUser && isMobile) {
            return confirm(trackDeeplinkConfirmPayload)
              .then(() => {
                const path = isTrackVersion
                  ? `${entity.track.spaceId}/${entity.track.id}/${entity.id}`
                  : `${(entity as Track).spaceId}/${(entity as Track).id}`;
                window.location.href = withMobileDeeplinkRoot(
                  `root/library/${path}`,
                );
              })
              .catch(navigateToTrackPage);
          }
          navigateToTrackPage();
        }}
      >
        <div className="row">
          {isExpandable && isTrack && (
            <Button
              theme={BUTTON_THEME.ICON}
              type="button"
              className="expander"
              onClick={(event) => {
                event.stopPropagation();
                setExpanded((prev) => !prev);
              }}
            >
              {isExpanded ? <CaretDownSVG /> : <CaretRightSVG />}
            </Button>
          )}
          <div
            className={classNames("column", {
              ["track-version-icon"]: isTrackVersion,
            })}
            data-id="icon"
          >
            {isTrackVersion ? (
              <TrackVersionIcon version={entity} textSize="small" />
            ) : (
              <MusicNoteHollowSVG />
            )}
          </div>
          <div className="column" data-id="name">
            <PreviewText>
              {isTrackVersion ? entity.name : (entity as Track)?.title}
            </PreviewText>
          </div>
          <div className="column actions" data-id="actions">
            {isTrackVersion ? (
              <TrackVersionActions
                version={entity}
                actions={ALT_TRACK_VERSION_ACTIONS}
              />
            ) : (
              <DefaultTrackActions track={entity as Track} />
            )}
          </div>
        </div>
        {renderConfirmation}
      </ListItemButton>
      {isExpandable && isTrack && isExpanded && (
        <ExpandableTrackList track={entity} indentation={indentation + 1} />
      )}
    </>
  );
};

const ExpandableTrackList = ({
  track,
  indentation = 0,
}: {
  track?: Track;
  indentation?: number;
}) => {
  const { trackVersionsLoading, sortedTrackVersions } =
    useTrackChildItemRows(track);

  const childItemRows = useMemo(() => {
    const childTrackVersions =
      sortedTrackVersions.map((version) => {
        return {
          id: version.id,
          entity: version,
          component: (
            <SpaceTrackItem
              key={`${track?.id ? `${track.id}-` : ""}version-${version.id}`}
              entity={version.entity as Version}
              indentation={indentation}
            />
          ),
        };
      }) || [];

    return childTrackVersions;
  }, [sortedTrackVersions]);

  return (
    <List
      className="expandable-space-list"
      data-cypress-id="expandable-space-list"
    >
      {trackVersionsLoading && (
        <div className="highnote-base-table">
          <LoadingSpinner />
        </div>
      )}
      {childItemRows.length === 0 && !trackVersionsLoading ? (
        <EmptyListItem
          indentation={indentation}
          style={{
            paddingLeft: `${nestedSpaceColumnSize + indentation * nestedSpaceColumnSize}px`,
          }}
        />
      ) : (
        childItemRows.map((spaceItem) => {
          return spaceItem.component;
        })
      )}
    </List>
  );
};

const ExpandableSpaceItem = ({
  space,
  indentation = 0,
}: {
  space: Space;
  indentation?: number;
}) => {
  const history = useHistory();
  const { spaceId, trackId } = useUrlContext();
  const { closeSidebar } = useSidebarContext();
  const [isExpanded, setExpanded] = useState<boolean>(
    spaceId && space?.id === spaceId,
  );
  const { vw } = useViewport();
  const isMobile = vw < 450;

  return (
    <>
      <ListItemButton
        className="expandable-space-item"
        selected={!isMobile && spaceId === space.id && !trackId}
        style={{
          paddingLeft: `${nestedSpaceColumnSize + indentation * nestedSpaceColumnSize}px`,
        }}
        data-cypress-id="expandable-space-item"
        data-cypress-space-id={space.id}
        onClick={() => {
          history.push(`/space/${space.id}`);
          closeSidebar();
        }}
      >
        <div className="row">
          <Button
            theme={BUTTON_THEME.ICON}
            type="button"
            className="expander"
            onClick={(event) => {
              event.stopPropagation();
              setExpanded(!isExpanded);
            }}
          >
            {isExpanded ? <CaretDownSVG /> : <CaretRightSVG />}
          </Button>
          <div className="column" data-id="name">
            <FolderHollowSVG />
            <PreviewText>{space.name}</PreviewText>
          </div>

          <div className="column" data-id="mute">
            <MuteToggle entity={space} />
          </div>

          <div className="column actions" data-id="actions">
            <DefaultSpaceActions space={space} />
          </div>
        </div>
      </ListItemButton>
      {isExpanded && (
        <ExpandableSpaceListItem
          space={space}
          indentation={indentation + 1}
          isExpanded={isExpanded}
        />
      )}
    </>
  );
};

const ExpandableSpaceListItem = ({
  space,
  indentation = 0,
  isExpanded,
}: {
  space?: Space;
  indentation?: number;
  isExpanded?: boolean;
}) => {
  const { topLevelSpaces } = useTopLevelSpaces();
  const { globalSpaces } = useGlobalSpaces();
  const { globalTracks } = useGlobalTracks();

  const { itemsOrder = [] } = space || {};

  // Fetches all child spaces and tracks of the space
  const { manageGlobalChildEntityWatchers } = useChildEntityWatchers();

  useEffect(() => {
    // Used to fetch child entities and set realtime watchers/listeners
    // for the current parent spaceId.
    if (space?.id && isExpanded) {
      manageGlobalChildEntityWatchers({
        spaceId: space.id,
      }).attach();
    }

    return () => {
      // Used to unsubscribe from realtime watchers/listeners
      // for the current parent spaceId.
      if (space?.id) {
        manageGlobalChildEntityWatchers({
          spaceId: space.id,
        }).detach();
      }
    };
  }, [isExpanded]);

  const spaceTracks = useMemo(() => {
    // this means that this component is being rendered at the top level,
    // so we are hiding anything that is not a space.
    if (!space || !globalTracks?.childTracks?.get(space.id)) return [];
    return globalTracks?.childTracks?.get(space.id).map((trackId) => {
      const currentTrack = globalTracks.tracks?.get(trackId);
      return {
        id: trackId,
        entity: currentTrack,
        component:
          currentTrack.versionFilesV2.length > 1 ? (
            <SpaceTrackItem
              key={`${space?.id ? `${space.id}-` : ""}expandable-track-${trackId}`}
              indentation={indentation}
              entity={currentTrack}
              isExpandable
            />
          ) : (
            <SpaceTrackItem
              key={`${space?.id ? `${space.id}-` : ""}track-${trackId}`}
              entity={currentTrack}
              indentation={indentation}
            />
          ),
      };
    });
  }, [space, globalTracks.childTracks, globalTracks.tracks]);

  const childSpaces = useMemo(() => {
    if (!space) {
      return topLevelSpaces
        .filter((currentSpace) => !currentSpace.isArchived)
        .map((currentSpace) => {
          return {
            id: currentSpace.id,
            entity: currentSpace,
            component: (
              <ExpandableSpaceItem
                key={currentSpace.id}
                space={currentSpace}
                indentation={indentation}
              />
            ),
          };
        });
    }
    const currentSpaces = globalSpaces?.childSpaces?.get(space?.id) || [];
    return currentSpaces
      .filter(
        (currentSpaceId) => !globalSpaces.spaces.get(currentSpaceId).isArchived,
      )
      .map((currentSpaceId) => {
        const currentSpace = globalSpaces.spaces.get(currentSpaceId);
        return {
          id: currentSpaceId,
          entity: currentSpace,
          component: (
            <ExpandableSpaceItem
              key={`${space?.id ? `${space.id}-` : ""}-${currentSpaceId}`}
              space={currentSpace}
              indentation={indentation}
            />
          ),
        };
      });
  }, [space, globalSpaces.childSpaces, globalSpaces.spaces, topLevelSpaces]);

  const spaceItems = useMemo(() => {
    if (!space) {
      return childSpaces;
    }
    const allItems = [...spaceTracks, ...childSpaces];
    const { orderedItems, unOrderedItems } = sortItemsByItemsOrder(
      itemsOrder,
      allItems,
    );
    unOrderedItems.sort((aRow, bRow) => {
      return sortRowByCreatedAtAscending(
        aRow as unknown as EntityRowConfig,
        bRow as unknown as EntityRowConfig,
      );
    });
    return [...orderedItems, ...unOrderedItems];
  }, [itemsOrder, spaceTracks, childSpaces]);

  return (
    <List
      className="expandable-space-list"
      data-cypress-id="expandable-space-list"
    >
      {spaceItems.map((spaceItem) => {
        return spaceItem.component;
      })}
      {spaceItems.length === 0 && (
        <EmptyListItem
          indentation={indentation}
          style={{
            paddingLeft: `${nestedSpaceColumnSize + indentation * nestedSpaceColumnSize}px`,
          }}
        />
      )}
    </List>
  );
};

export const SpacePicker = () => {
  const { closeSidebar } = useSidebarContext();

  return (
    <div className="space-picker" data-cypress-id="space-picker">
      <span className="spaces-header">
        <NewEntityMenu
          hideIcon
          buttonText="Spaces"
          buttonTheme={BUTTON_THEME.TEXT}
          showEntityTypes={[COLLECTION_ID.SPACE]}
          onCreate={() => {
            closeSidebar();
          }}
        />
      </span>

      <div className="inner">
        <List className="space-list">
          <TrackEditorProvider>
            <ExpandableSpaceListItem />
          </TrackEditorProvider>
        </List>

        <br />
      </div>
    </div>
  );
};
