import React, { useState } from "react";

import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { Dialog, DialogSection } from "App/common/Dialog";
import { ImageWithFallback } from "App/common/ImageWithFallback";
import { useAuth } from "App/components/Auth";
import { useSpaceContext } from "App/common/useSpace";
import { AppCheckbox } from "App/core/Checkbox/AppCheckbox";
import styles from "./MobileAppPromptDialog.module.scss";
import { AUTH_TYPE, AuthForm } from "App/components/Auth/AuthForm";
import { isSpacePrivateInboxEnabled } from "@highnote/server/src/core/shared-util";
import { useViewport } from "App/common/useViewport";
import { useUrlContext } from "../useUrlContext";

const LS_MOBILE_APP_PROMPT_DISMISSAL_ID = "hignote-mobile-app-prompt-dismissal";

const AnonUserAppPrompt = ({
  spaceName,
  spaceDescription,
  dismissChecked,
  onListenInApp,
  onContinueOnWeb,
  onDismissToggled,
}: {
  spaceName: string;
  spaceDescription?: string;
  dismissChecked: boolean;
  onListenInApp: () => void;
  onContinueOnWeb: () => void;
  onDismissToggled: () => void;
}) => {
  return (
    <>
      <DialogSection className={styles.mobileAppPrompt__header}>
        <h3>{spaceName}</h3>
        {spaceDescription && <p>{spaceDescription}</p>}
      </DialogSection>
      <DialogSection>
        <Button
          className={styles.mobileAppPrompt__buttons}
          size={BUTTON_SIZE.MEDIUM}
          theme={BUTTON_THEME.CTA}
          onClick={onListenInApp}
        >
          Listen in App
        </Button>
        <Button
          className={styles.mobileAppPrompt__buttons}
          size={BUTTON_SIZE.MEDIUM}
          theme={BUTTON_THEME.SECONDARY}
          onClick={onContinueOnWeb}
        >
          Continue on Web
        </Button>
        <div className={styles.mobileAppPrompt__checkboxContainer}>
          <AppCheckbox
            className={styles.mobileAppPrompt__checkbox}
            checked={dismissChecked}
            size="small"
            onChange={onDismissToggled}
          />
          <p>{"Don't ask me again"}</p>
        </div>
      </DialogSection>
    </>
  );
};

export const MobileAppPromptDialog = () => {
  const { user } = useAuth();
  const { space, spaceArtworkUrl } = useSpaceContext();
  const { spaceId, shareKey, trackId } = useUrlContext();
  const { isMobile } = useViewport();

  const [ignoreOnNextVisit, setIgnoreOnNextVisit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(
    localStorage.getItem(LS_MOBILE_APP_PROMPT_DISMISSAL_ID) !== "true",
  );
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const mobileSpaceHandoffUrl = React.useMemo(() => {
    const params = new URLSearchParams();

    if (!shareKey || !spaceId) {
      return null;
    }

    params.set("shareKey", shareKey);
    params.set("spaceId", spaceId);

    if (trackId) {
      params.set("trackId", trackId);
    }

    return `/space-handoff?${params.toString()}`;
  }, [shareKey, spaceId, trackId]);

  if (
    !isMobile ||
    !space ||
    Boolean(user) ||
    isSpacePrivateInboxEnabled(space)
  ) {
    return null;
  }

  const onClose = () => {
    setIsModalOpen(false);
  };

  const onDismissToggled = () => {
    if (!ignoreOnNextVisit) {
      localStorage.setItem(LS_MOBILE_APP_PROMPT_DISMISSAL_ID, "true");
    }
    setIgnoreOnNextVisit(!ignoreOnNextVisit);
  };

  return (
    <Dialog
      className={styles.mobileAppPrompt__container}
      open={isModalOpen}
      onClose={onClose}
    >
      <DialogSection className={styles.mobileAppPrompt__artwork}>
        <ImageWithFallback
          src={spaceArtworkUrl}
          fallback="/public/default-space-artwork.png"
        />
      </DialogSection>
      {!isLoggingIn ? (
        <AnonUserAppPrompt
          spaceName={space.name}
          spaceDescription={space.description}
          dismissChecked={ignoreOnNextVisit}
          onListenInApp={() => {
            setIsLoggingIn(true);
          }}
          onContinueOnWeb={onClose}
          onDismissToggled={onDismissToggled}
        />
      ) : (
        <>
          <h3 className={styles.mobileAppPrompt__title}>Get beta app access</h3>
          <p>
            Car Play, lossless audio, versions and timestamped notes in the app
          </p>
          <AuthForm
            className={styles.mobileAppPrompt__authForm}
            type={AUTH_TYPE.JOIN}
            hideSubtitle
            returnTo={mobileSpaceHandoffUrl}
            hideWelcomeSurvey
          />
        </>
      )}
    </Dialog>
  );
};
