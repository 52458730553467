import "./Callout.scss";
import React, { ReactElement } from "react";
import { useTheme } from "App/common/ThemeProvider";

export enum CALLOUT_LAYOUT {
  COLUMN_LEFT_ALIGNED = "column-left-aligned",
  COLUMN_CENTERED = "column-centered",
  ROW = "row",
}

export type CalloutProps = {
  layout: CALLOUT_LAYOUT;
  icon: ReactElement;
  title?: string;
  body: string | React.ReactNode;
  width?: string;
  className?: string;
  actionButton?: ReactElement;
};

export const Callout = (props: CalloutProps) => {
  const {
    layout,
    icon,
    title,
    body,
    width = "100%",
    className,
    actionButton,
  } = props;
  const { theme } = useTheme();

  return (
    <div
      className={`highnote-callout ${className}`}
      style={{ width }}
      data-theme={theme}
      data-cypress-id="highnote-callout"
      data-callout-layout={layout}
    >
      <div className="inner">
        <div className="icon">{icon}</div>
        <div className="contents">
          {!!title && <div className="title">{title}</div>}
          <div className="description">{body}</div>
        </div>

        {!!actionButton && <div className="actionButton">{actionButton}</div>}
      </div>
    </div>
  );
};
