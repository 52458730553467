import React from "react";
import { THEME } from "App/common/ThemeProvider";
import { GlobalSidebar } from "./GlobalSidebar";
import { SpaceChatSidebar } from "./SpaceChatSidebar";
import { EVENT_ID } from "@highnote/server/src/core/entities";

export enum SIDEBAR_SIDE {
  LEFT = "left",
  RIGHT = "right",
}

export enum SIDEBAR_TYPE {
  OVERLAY = "overlay",
  PUSH = "push",
}

export enum SIDEBAR_ID {
  SPACE_CHAT = "space-chat",
  GLOBAL_NAV = "nav",
}

export const SIDEBAR_IDS: SIDEBAR_ID[] = [
  SIDEBAR_ID.SPACE_CHAT,
  SIDEBAR_ID.GLOBAL_NAV,
];

export type SidebarConfig = {
  id: SIDEBAR_ID;
  side: SIDEBAR_SIDE;
  type: SIDEBAR_TYPE;
  theme: THEME;
  sidebar: React.ReactNode;
  segmentEnterId?: EVENT_ID;
  segmentExitId?: EVENT_ID;
  keepOpenOnWideScreens?: boolean;
};

export const SIDEBAR_CONFIG: Record<SIDEBAR_ID, SidebarConfig> = {
  [SIDEBAR_ID.SPACE_CHAT]: {
    id: SIDEBAR_ID.SPACE_CHAT,
    side: SIDEBAR_SIDE.RIGHT,
    type: SIDEBAR_TYPE.PUSH,
    theme: THEME.DARK,
    sidebar: <SpaceChatSidebar />,
  },
  [SIDEBAR_ID.GLOBAL_NAV]: {
    id: SIDEBAR_ID.GLOBAL_NAV,
    side: SIDEBAR_SIDE.LEFT,
    type: SIDEBAR_TYPE.PUSH,
    theme: THEME.DARK,
    sidebar: <GlobalSidebar />,
    keepOpenOnWideScreens: true,
  },
};
