import React, { forwardRef } from "react";
import classNames from "classnames";
import { useTheme } from "App/common/ThemeProvider";
import { LoadingSpinner } from "App/common/icons/LoadingSpinner";
import "./Button.scss";

export enum BUTTON_THEME {
  PLAIN = "plain",
  TEXT = "text",
  LINK = "link",
  ICON = "icon",
  PRIMARY = "primary",
  PRIMARY_INVERSE = "primary-inverse",
  SECONDARY = "secondary",
  CTA = "cta",
  WARN = "warn",
  MONETIZATION = "monetization",
}

export enum BUTTON_SIZE {
  LARGE = "large",
  MEDIUM = "medium",
  SMALL = "small",
  XSMALL = "xsmall",
  AUTO = "auto",
}

export type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  theme?: BUTTON_THEME;
  size?: BUTTON_SIZE;
  loading?: boolean;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, ref) => {
    /* eslint-disable react/prop-types */
    const {
      theme: buttonTheme,
      size,
      loading,
      startIcon,
      fullWidth,
      ...rest
    } = props;
    const { theme } = useTheme();

    return (
      <button
        {...rest}
        ref={ref}
        className={classNames(
          "highnote-button",
          { "full-width": fullWidth },
          props.className,
        )}
        style={{ pointerEvents: "auto", ...(props.style || {}) }}
        data-theme={theme}
        data-button-theme={buttonTheme || BUTTON_THEME.PRIMARY}
        data-size={size || BUTTON_SIZE.MEDIUM}
        disabled={props.disabled || loading}
        data-disabled={props.disabled || loading}
        onClick={(e) => {
          if (props.disabled) {
            e.preventDefault();
            return;
          }

          props.onClick && props.onClick(e);
        }}
      >
        {startIcon && <span className="start-icon">{startIcon}</span>}
        {props.children}
        {!!loading && <LoadingSpinner />}
      </button>
    );
  },
);
Button.displayName = "Button";
