import { matchPath } from "react-router";
import UAParser from "ua-parser-js";

const parser = new UAParser();

export const spaceTrackToMediaMetadata = (track, space, artwork) => {
  const mediaMetadataSupported =
    "mediaSession" in navigator && "MediaMetadata" in window;
  if (!mediaMetadataSupported) {
    return null;
  }
  return new MediaMetadata({
    title: track.title,
    artist: track.title || space.name || "",
    album: track.title || space.name || "",
    artwork: [
      {
        src: artwork,
        type: `image/${artwork.split(/[#?]/)[0].split(".").pop().trim()}`,
      },
    ],
  });
};

export const isAndroid = () => {
  const os = parser.getOS();
  return os.name.includes("Android");
};

export const setTestProperty = (key, value) => {
  window.TEST_PROPERTIES = window.TEST_PROPERTIES || {};
  window.TEST_PROPERTIES[key] = value;
};

export const formatCount = (n, singular, plural = `${singular}s`) => {
  if (n === 1) return `${n} ${singular}`;
  return `${n} ${plural}`;
};

// Oct. '24: Paulina has asked that we keep this code in here for now
// todo: type! (category: "feature" | "bug")
/*
export const getHelpFormURL = (category, user) => {
  const emailQueryParam = user?.email ? `&email=${user.email}` : "";
  return `https://help.highnote.fm/hc/en-us/requests/new?ticket_form_id=9650575523085${emailQueryParam}&category=${category}`;
};
*/

export const matchAppPath = (path) =>
  matchPath(location.pathname, {
    path,
    exact: true,
    strict: false,
  });

export const formatFileSize = (size = 0) => {
  const bytes = Math.abs(size);
  const prefix = size < 0 ? "-" : "";

  if (bytes < 1024) return `${prefix}${bytes}B`;
  const kb = bytes / 1024;
  if (kb < 1024) return `${prefix}${parseFloat(kb.toFixed(1))}KB`;
  const mb = kb / 1024;
  if (mb < 1024) return `${prefix}${parseFloat(mb.toFixed(1))}MB`;
  const gb = mb / 1024;
  if (gb < 1024) return `${prefix}${parseFloat(gb.toFixed(2))}GB`;
  const tb = gb / 1024;
  return `${prefix}${parseFloat(tb.toFixed(0))}TB`;
};

const FAVICONS = {
  "figma.com": "https://static.figma.com/app/icon/1/favicon.png",
  "notion.so": "https://www.notion.so/images/favicon.ico",
  "highnote.fm": "https://on.highnote.fm/public/share-image-logo.png",
};

export const getFaviconUrl = (_url) => {
  // Set `url` to the passed prop value ONLY if it's a valid URL string.
  let url;
  try {
    url = new URL(_url);
  } catch (e) {
    // don't care
  }

  // Try and guess the favicon url.
  let faviconUrl = `https://s2.googleusercontent.com/s2/favicons?domain=${url.origin}&sz=64`;

  // For some couple commonly used url patterns, use a known url we've stored.
  const faviconKey = Object.keys(FAVICONS).find((match) => {
    return faviconUrl.includes(match);
  });

  if (faviconKey) {
    faviconUrl = FAVICONS[faviconKey];
  }

  return faviconUrl;
};

export const isTouchDevice = () => {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
};
