import { v4 as uuidv4 } from "uuid";
import React, { useState } from "react";
import {
  Entity,
  ROLE,
  Roles,
  UserEntity,
} from "@highnote/server/src/core/entities";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { UserAutocomplete } from "App/common/UserAutocomplete";
import { RolePicker } from "../RolePicker";
import { getRoleSummary } from "../util";
import { ToastMessageContent, useToast } from "App/common/useToast";
import { useShareDialogContext } from "../ShareDialogContext";
import {
  CONTROLLED_SHARE_FEATURES,
  ControlledFeatureTooltipLabel,
} from "App/core/ControlledFeatureCallout";

export const InviteByEmail = ({
  roles,
  onChange,
}: {
  roles: Roles;
  onChange: (val: Roles) => void;
}) => {
  const {
    isEntityOwner,
    canManageDownloadControl,
    defaultFeatureAlertMessage,
  } = useShareDialogContext();
  const { addMessage } = useToast();
  const [inviteRoles, setInviteRoles] = useState<ROLE[]>([
    ROLE.COMMENT,
    ROLE.VIEW,
    ROLE.DOWNLOAD,
  ]);
  const [invitedUsers, setInvitedUsers] = useState<UserEntity[]>([]);

  const sendInvites = async () => {
    const cachedInvitees = invitedUsers;
    const inviteData: Entity["rolesV2"] = {};
    invitedUsers.forEach((user) => {
      const primaryId = user.primaryAccountId || user.id;
      inviteData[primaryId] = inviteRoles;
    });

    setInvitedUsers([]);

    try {
      await onChange(inviteData);
    } catch (e) {
      setInvitedUsers(cachedInvitees);
      throw e;
    }
  };

  const onAutocompleteChange = (users: UserEntity[]) => {
    const toastId = uuidv4();

    const alreadyAdded = users.find((u) => {
      const primaryId = u.primaryAccountId || u.id;
      const inviteId = `invite:${u.email}`;
      return !!(roles[primaryId] || roles[inviteId]);
    });

    if (alreadyAdded) {
      addMessage({
        id: toastId,
        children: (
          <ToastMessageContent
            type="error"
            id={toastId}
            text={`This Space is already shared with ${alreadyAdded.email}.`}
          />
        ),
        expireAt: Date.now() + 3000, // 3 seconds from now
      });
      return;
    }

    setInvitedUsers(users);
  };
  const disabledRoles =
    inviteRoles.includes(ROLE.DOWNLOAD) && !canManageDownloadControl
      ? [
          {
            role: ROLE.DOWNLOAD,
            message: isEntityOwner
              ? ControlledFeatureTooltipLabel[
                  CONTROLLED_SHARE_FEATURES.DOWNLOAD
                ]
              : defaultFeatureAlertMessage,
          },
        ]
      : [];

  return (
    <div className="InviteByEmail">
      <div className="InviteByEmail-editor">
        <UserAutocomplete
          placeholder="Invite by email..."
          value={invitedUsers}
          onChange={onAutocompleteChange}
        />
        {invitedUsers.length > 0 && (
          <div className="InviteByEmail-roles">
            <span>{getRoleSummary(inviteRoles)}</span>

            <RolePicker
              value={inviteRoles}
              options={[ROLE.MANAGE, ROLE.COMMENT, ROLE.UPLOAD, ROLE.DOWNLOAD]}
              disabledRoles={disabledRoles}
              onChange={(value: ROLE[]) => {
                setInviteRoles(value);
              }}
            />
          </div>
        )}
      </div>
      <Button
        className="InviteByEmail-button"
        size={BUTTON_SIZE.MEDIUM}
        theme={BUTTON_THEME.PRIMARY}
        disabled={invitedUsers?.length < 1}
        onClick={sendInvites}
      >
        Invite
      </Button>
    </div>
  );
};
