import "./SpaceDetails.scss";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { DialogButtons, DialogInfo, DialogSection } from "App/common/Dialog";
import { BUTTON_THEME, Button } from "App/core/Button";
import { useSpaceEditor } from "../useSpaceEditor";
import { EditableArtwork } from "../EditableArtwork";
import { PERMISSION } from "@highnote/server/src/core/shared-util";
import { useAuth } from "../Auth";
import { highnote } from "@highnote/server/src/sdk";
import { useFiles } from "../useFiles";
import { TextInput } from "App/common/TextInput";
import { PermissionTooltip } from "../PermissionTooltip";
import { ToastMessageContent, useToast } from "App/common/useToast";
import { ConnectedUserName } from "App/common/UserAvatar/UserAvatar";

export const SpaceDetails = () => {
  const { getDownloadUrl } = useFiles();
  const { isAllowed } = useAuth();
  const { toasted, addMessage } = useToast();
  const { space } = useSpaceEditor();
  const [isSaving, setIsSaving] = useState(false);
  const [spaceState, setSpaceState] = useState({
    file: undefined,
    name: space.name,
    description: space.description,
  });
  const [artworkUrl, setArtworkUrl] = useState("");

  useEffect(() => {
    if (space.artworkFile) {
      highnote.getFiles({ ids: [space.artworkFile] }).then(async (files) => {
        const file = files.find((f) => f.id === space.artworkFile);
        if (file) {
          const url = await getDownloadUrl(file);
          setArtworkUrl(url);
        }
      });
    }
  }, [space.artworkFile]);

  const canEditSpace = isAllowed(PERMISSION.TO_MANAGE_SPACE, { space });

  const onSubmit = async () => {
    setIsSaving(true);
    if (!spaceState.name) {
      const toastId = uuidv4();

      addMessage({
        id: toastId,
        children: (
          <ToastMessageContent
            type="error"
            id={toastId}
            text="Space must have a name."
          />
        ),
      });

      setIsSaving(false);
      return;
    }

    const requestUpdate = async () => {
      await highnote.updateSpace({
        id: space.id,
        data: {
          name: spaceState.name,
          description: spaceState.description,
          artworkFile: spaceState.file?.id || null,
        },
      });
    };

    await toasted({
      promise: requestUpdate(),
      createMessage: "Updating space...",
      successMessage: "Space saved.",
      errorMessage: "Could not update space. Please try again.",
    });

    setArtworkUrl(artworkUrl);
    setIsSaving(false);
  };

  const createdAtStr = moment(space.createdAt).fromNow();

  return (
    <ErrorBoundary name="SpaceDetails">
      <div className="highnote-space-editor-details">
        <DialogSection>
          <h3>Space Artwork</h3>
          <EditableArtwork
            disabled={!canEditSpace}
            artworkUrl={artworkUrl}
            fallbackArtworkUrl={"/public/default-track-artwork.png"}
            onChange={async (file, tempUrl) => {
              setArtworkUrl(tempUrl);
              setSpaceState({ ...spaceState, file });
              setIsSaving(false);
            }}
            onFileSelected={(file: File) => {
              setArtworkUrl(URL.createObjectURL(file));
              setIsSaving(true);
            }}
          />
        </DialogSection>
        <DialogSection>
          <h3>Name</h3>
          <PermissionTooltip
            permission={PERMISSION.TO_MANAGE_SPACE}
            space={space}
          >
            <div className="input-field">
              <TextInput
                isSingleLine
                isReadOnly={!canEditSpace}
                value={spaceState.name}
                required
                placeholder="Space Name"
                onChange={(val) => {
                  setSpaceState({ ...spaceState, name: val });
                }}
              />
            </div>
          </PermissionTooltip>
        </DialogSection>
        <DialogSection>
          <h3>Description</h3>
          <PermissionTooltip
            permission={PERMISSION.TO_MANAGE_SPACE}
            space={space}
          >
            <div className="input-field">
              <TextInput
                isSingleLine
                isReadOnly={!canEditSpace}
                value={spaceState.description}
                required
                placeholder="Space Description"
                onChange={(val) => {
                  setSpaceState({ ...spaceState, description: val });
                }}
              />
            </div>
          </PermissionTooltip>
        </DialogSection>
        <DialogButtons>
          <Button
            theme={BUTTON_THEME.CTA}
            disabled={!canEditSpace || isSaving}
            onClick={onSubmit}
          >
            Save Changes
          </Button>
        </DialogButtons>
      </div>

      <DialogInfo>
        Created {createdAtStr} by <ConnectedUserName userId={space.createdBy} />
      </DialogInfo>
    </ErrorBoundary>
  );
};
