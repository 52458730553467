import "./EntityPreview.scss";
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  collection,
  where,
  query,
  getCountFromServer,
} from "firebase/firestore";
import classNames from "classnames";
import { ReactComponent as CloseSVG } from "App/common/icons-v2/close.svg";
import { ReactComponent as SuccessSVG } from "App/common/icons-v2/check.svg";
import { ReactComponent as MusicNoteSVG } from "App/common/icons-v2/music-fill.svg";
import { ReactComponent as FolderFillSVG } from "App/common/icons-v2/folder-fill.svg";
import { ReactComponent as FileReduceFillSVG } from "App/common/icons-v2/file-reduce-fill.svg";
import { ReactComponent as ExternalLinkSVG } from "App/common/icons-v2/external-link.svg";
import { ReactComponent as DefaultSpaceFolderSVG } from "App/common/icons-v2/default-space-folder.svg";
import { ReactComponent as DefaultUploadFileSVG } from "App/common/icons-v2/default-upload-file.svg";
import { PreviewText } from "@highnote/preview-text/src";
import {
  COLLECTION_ID,
  FileEntity,
  Space,
  Track,
} from "@highnote/server/src/core/entities";
import { FileUpload, useFiles } from "App/components/useFiles";
import { highnote } from "@highnote/server/src/sdk";
import { formatCount, formatFileSize, getFaviconUrl } from "App/modules/utils";
import { ENTITY_TYPE, EntityData, Version } from "./config";
import { ProgressBar } from "App/common/ProgressBar";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { useSpaceContext } from "App/common/useSpace";
import { ThumbnailPreview } from "App/common/ThumbnailPreview";
import { formatDuration } from "@highnote/server/src/core/shared-util";
import { TrackVersionIcon } from "App/components/TrackEditor/TrackVersions/TrackVersionIcon";
import { TrackVersionStar } from "../TrackEditor/TrackVersions/TrackVersionStar";
import { useSpaces } from "../useEntities";
import {
  GlobalAudioCurrentFormattedTime,
  useTrackRow,
} from "../GlobalAudioPlayer/GlobalAudioPlayer";
import { useGlobalTracks } from "App/store/tracks/useGlobalTracks";
import { useGlobalSpaces } from "App/store/spaces/useGlobalSpaces";
import { useConfirmation } from "App/common/useConfirmation";
import { useTrackFiles } from "../useEntities/useTrack";
import moment from "moment";
import { EntityMetadata } from "./EntityMetadata";
import { useViewport } from "App/common/useViewport";
import { StatusIndicator } from "App/common/StatusIndicator";
import { LoadingSpinner } from "App/common/icons/LoadingSpinner";
import { Tooltip } from "App/core/Tooltip";

const downloadUrlCache: Record<string, string> = {};

const getFallback = (row: EntityData) => {
  if (row.type === ENTITY_TYPE.TRACK) {
    return "/public/default-track-artwork.png";
  }

  if (row.type === ENTITY_TYPE.SPACE) {
    return <DefaultSpaceFolderSVG />;
  }

  if (row.type === ENTITY_TYPE.TRACK_VERSION) {
    const file = row.entity as Version;

    return <TrackVersionIcon version={file} />;
  }

  if (row.type === ENTITY_TYPE.FILE) {
    const file = row.entity as FileEntity;

    if ((file as Version).isVersion) {
      return <TrackVersionIcon version={file as Version} />;
    }

    if (file.url) {
      return "/public/icons/default-file-url.svg";
    }

    if (String(file.fileType).includes("image/")) {
      return "/public/icons/default-file-img.png";
    }

    const specialExtensions = ["mp3", "ogg", "aif", "mp4", "zip", "wav"];
    const ext = specialExtensions.find((e) => file.fileName?.endsWith(e));

    if (ext) {
      return `/public/icons/default-file-${ext}.svg`;
    }
  }

  return <DefaultUploadFileSVG />;
};

const getCachedArtwork = (artworkFile?: string, url?: string) => {
  if (artworkFile) {
    return downloadUrlCache[artworkFile];
  }

  if (url) {
    return getFaviconUrl(url) || "/public/icons/default-file-url.svg";
  }
};

const useArtworkUrl = ({ row }: { row: EntityData }) => {
  const { getDownloadUrl } = useFiles();
  const entity = row.entity as Track | Space;
  const file = row.entity as FileEntity;
  const { entities: spaces } = useSpaces();
  const { space } = useSpaceContext();
  let parentArtworkFile: string;
  if (row.type === ENTITY_TYPE.TRACK) {
    const spaceId = (entity as Track).spaceId;
    if (spaceId) {
      parentArtworkFile = spaces.find((s) => s.id === spaceId)?.artworkFile;
    }
    if (!parentArtworkFile) {
      parentArtworkFile = space?.artworkFile;
    }
  }
  const artworkFile = entity.artworkFile || parentArtworkFile;
  const [source, setSource] = useState<string>(
    getCachedArtwork(artworkFile, file.url),
  );

  useEffect(() => {
    let unmounted: boolean;

    const cachedUrl = getCachedArtwork(artworkFile, file.url);
    if (cachedUrl) {
      setSource(cachedUrl);
      return;
    }

    if (!artworkFile) {
      setSource(undefined);
      return;
    }

    highnote.getFiles({ ids: [artworkFile] }).then(async (files) => {
      const artworkFileEntity = files.find((f) => f.id === artworkFile);
      const url = await getDownloadUrl(artworkFileEntity);
      if (unmounted) return;
      downloadUrlCache[artworkFile] = url;
      setSource(url);
    });

    return () => {
      unmounted = true;
    };
  }, [row.type, artworkFile, file.url]);

  return {
    source: source,
    fallback: getFallback(row),
  };
};

export const TrackTitle = ({
  track,
  onClick,
}: {
  track: Track;
  onClick: () => void;
}) => {
  return (
    <span className="TrackTitle" onClick={() => onClick()}>
      <PreviewText>{track.title}</PreviewText>
    </span>
  );
};

export const TrackDescription = ({
  track,
  onClick,
  showTrackTime,
}: {
  track: Track;
  onClick?: () => void;
  showTrackTime?: boolean;
}) => {
  const { currentTrackPageVersion } = useTrackFiles(track);
  const [spaceStr, setSpaceStr] = useState<string>();
  const { spaceId } = useSpaceContext();
  const versionsStr =
    currentTrackPageVersion?.name ??
    formatCount((track.versionFilesV2 || []).length, "Version");
  useEffect(() => {
    let unmounted: boolean;
    if (!track?.spaceId || track.spaceId === spaceId) {
      setSpaceStr(undefined);
      return;
    }

    highnote
      .getSpace({ id: track.spaceId, fromCache: true })
      .then((space) => {
        if (unmounted) return;
        setSpaceStr(` • ${space.name}`);
      })
      .catch((e) => {
        // Track you uploaded can belong to a space you don't have access to.
        // ie. User 1 has a space, and makes User 2 admin. User 2 creates their own space
        // that User 1 is not apart of yet, and moves a track/entity from User 1 Space -> User 2 Space.
        // User 1 will get an error for fetching space data of that new track's location.
        console.error("Error getting parent space", e);
      });

    return () => {
      unmounted = true;
    };
  }, [track?.spaceId, spaceId]);

  return (
    <span
      className={classNames("track-description", { showTrackTime })}
      data-clickable={!!onClick}
      onClick={() => onClick && onClick()}
    >
      {showTrackTime && (
        <span className="track-description-time">
          <GlobalAudioCurrentFormattedTime />
        </span>
      )}
      <span className="track-description-main">
        {versionsStr}
        {spaceStr}
      </span>
    </span>
  );
};

const getNumTracksInSpace = async (spaceId: string) => {
  const coll = collection(highnote.__firebaseFirestore, COLLECTION_ID.TRACK);
  const q = query(coll, where("spaceId", "==", spaceId));
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
};

const getNumSpacesInSpace = async (spaceId: string) => {
  const coll = collection(highnote.__firebaseFirestore, COLLECTION_ID.SPACE);
  const q = query(coll, where("spaceId", "==", spaceId));
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
};

const SpaceDescription = ({ space }: { space: Space }) => {
  const { globalTracks, globalTracksWatchers } = useGlobalTracks();
  const { globalSpaces, globalSpacesWatchers, hasGlobalSpace } =
    useGlobalSpaces();
  const [description, setDescription] = useState<string>();

  useEffect(() => {
    let unmounted: boolean;

    if (!space) {
      setDescription("");
      return;
    }

    if (globalTracksWatchers.has(space.id) || hasGlobalSpace(space.id)) {
      const childTracks = globalTracks.childTracks.get(space.id);
      const childSpaces = globalSpaces.childSpaces.get(space.id);
      const totalChildTracksCount = childTracks ? childTracks.length : 0;
      const totalChildSpacesCount = childSpaces ? childSpaces.length : 0;
      const totalChildItemsCount =
        totalChildTracksCount + totalChildSpacesCount;
      setDescription(`${formatCount(totalChildItemsCount, "Item")}`);
    } else {
      const itemsPromise = Promise.all([
        getNumTracksInSpace(space.id),
        getNumSpacesInSpace(space.id),
      ]).then((counts) => {
        const totalCount =
          counts.reduce((acc, count) => acc + count, 0) +
          (space.files || []).length;
        return `${formatCount(totalCount || 0, "Item")}`;
      });

      itemsPromise.then((summary) => {
        if (unmounted) return;
        setDescription(summary);
      });
    }

    return () => {
      unmounted = true;
    };
  }, [
    space?.id,
    globalTracks.childTracks.get(space.id),
    globalSpaces.childSpaces.get(space.id),
    globalTracksWatchers.get(space.id),
    globalSpacesWatchers.get(space.id),
  ]);

  return <>{description}</>;
};

export const EntityPreview = ({ row }: { row: EntityData }) => {
  const { isMobile } = useViewport();
  const { source, fallback } = useArtworkUrl({ row });
  const { confirm, renderConfirmation } = useConfirmation();

  let title: string | React.ReactNode;
  let data: React.ReactNode;

  const space = row.entity as Space;
  const track = row.entity as Track;
  const file = row.entity as FileEntity;
  const version = (file as Version).isVersion && (file as Version);
  const upload = row.entity as FileUpload;

  if (row.type === ENTITY_TYPE.SPACE) {
    title = space.name;
    const spaceDate = moment(space.createdAt).format("MMMM D, YYYY");
    data = (
      <div className="entity-preview-metadata">
        <span className="entity-preview-metadata-icon">
          <FolderFillSVG />
        </span>
        {spaceDate}
        <span className="dot-text-divider" />
        <SpaceDescription space={space} />
      </div>
    );
  }

  if (row.type === ENTITY_TYPE.TRACK) {
    title = track.title;
    const trackDate = moment(track.createdAt).format("MMMM D, YYYY");

    data = (
      <div className="entity-preview-metadata">
        <span className="entity-preview-metadata-icon">
          <MusicNoteSVG />
        </span>
        {trackDate}
        <span className="dot-text-divider" />
        <EntityMetadata row={row} className="entity-preview-metadata" />
        <span className="dot-text-divider" />
        <TrackDescription track={track} />
      </div>
    );
  }

  if (row.type === ENTITY_TYPE.TRACK_VERSION) {
    title = version.name || "";
    data = <>TRACK VERSION{version.isDefaultVersion && ` • DEFAULT`}</>;
  }

  if (row.type === ENTITY_TYPE.FILE) {
    title = file.name || file.fileName;
    const fileDate = moment(file.createdAt).format("MMMM D, YYYY");

    if (version) {
      title = (
        <span className="version-title">
          <PreviewText>{file.name || file.fileName}</PreviewText>
          <TrackVersionStar version={version} />
        </span>
      );

      data = `VERSION ${
        file.metadata?.duration
          ? // eslint-disable-next-line no-irregular-whitespace
            `• ${formatDuration(file.metadata?.duration, true)}`
          : ""
      }`;
    } else if (file.url) {
      data = (
        <div className="entity-preview-metadata">
          <span className="entity-preview-metadata-icon">
            <ExternalLinkSVG />
          </span>
          {fileDate}
          <span className="dot-text-divider" />
          {file.url}
        </div>
      );
    } else {
      // eslint-disable-next-line no-irregular-whitespace
      data = (
        <div className="entity-preview-metadata">
          <span className="entity-preview-metadata-icon">
            <FileReduceFillSVG />
          </span>
          {fileDate}
          <span className="dot-text-divider" />
          {file.fileType}
          {file.size && (
            <>
              <span className="dot-text-divider" />
              {formatFileSize(file.size)}
            </>
          )}
        </div>
      );
    }
  }

  if (row.type === ENTITY_TYPE.UPLOAD) {
    const hasError = !!upload.error;
    const isUploadComplete =
      upload.status === "complete" && upload.progress === 1 && !hasError;
    let description = "Uploading...";

    if (isUploadComplete) description = "Uploading complete";
    if (upload.status === "processing")
      description = "Upload complete, now processing file...";
    if (hasError) description = "Failed";

    title = (
      <>
        <div className="entity-preview-upload">
          <div className="entity-preview-upload-name">
            {upload.file.fileName}
          </div>
          <span className="description">
            <div
              className={classNames("entity-preview-metadata", {
                error: hasError,
              })}
            >
              {description}
            </div>
          </span>
        </div>

        <div className="upload-row-ctas">
          {hasError &&
            // Currently, we only pass onRetry for dropbox uploads.
            // Meaning, we only want to show retry for files that are passed a callback fn.
            upload.onRetry && (
              <StatusIndicator
                errorMessage={upload.error}
                retryCallback={upload.onRetry} // Ensure retry functionality works for dropbox only
                isLoading={upload.progress < 1 && !upload.error}
                className="upload-status-indicator"
              />
            )}

          {upload.status === "processing" && !hasError && (
            <Tooltip title="Upload complete, now processing file...">
              <Button theme={BUTTON_THEME.ICON} className="processing-upload">
                <LoadingSpinner />
              </Button>
            </Tooltip>
          )}

          {isUploadComplete && (
            <Button
              type="button"
              className="success-upload"
              theme={BUTTON_THEME.ICON}
              size={BUTTON_SIZE.XSMALL}
            >
              <SuccessSVG />
            </Button>
          )}

          {!isUploadComplete &&
            upload.status !== "processing" &&
            upload.cancelUpload && (
              <Button
                type="button"
                className="cancel-upload"
                theme={BUTTON_THEME.ICON}
                size={BUTTON_SIZE.XSMALL}
                onClick={upload.cancelUpload}
              >
                <CloseSVG />
              </Button>
            )}
        </div>
      </>
    );
    data = (
      <ProgressBar
        progress={
          hasError
            ? typeof upload.progress === "number" && upload.progress > 0
              ? upload.progress
              : 0.1
            : upload.progress
        }
        className="highnote-preview-progress-bar"
        hasError={hasError}
      />
    );
  }
  const handleOnClick = () => {
    if (row.confirmOnClick) {
      row
        .confirmOnClick(confirm)
        .then(() => {
          row.onConfirmCancel?.();
        })
        .catch(() => {
          row?.onClick?.();
        });
    } else {
      row?.onClick?.();
    }
  };

  const renderThumbnailPreview = useCallback(
    (onClick?: () => void) => (
      <>
        <ThumbnailPreview
          onClick={onClick} // onClick controls the Preview Title click
          iconSource={source}
          iconFallback={fallback}
          title={title}
          description={data}
          row={row}
        />
        {renderConfirmation}
      </>
    ),
    [source, fallback, title, data, renderConfirmation, row],
  );

  if (isMobile && row.type !== ENTITY_TYPE.UPLOAD) {
    if (row.type === ENTITY_TYPE.TRACK) {
      return (
        <TrackPreviewMobileWrapper row={row} version={version}>
          {renderThumbnailPreview()}
        </TrackPreviewMobileWrapper>
      );
    }

    const allowedOnClick =
      row.type === ENTITY_TYPE.SPACE || row.type === ENTITY_TYPE.TRACK_VERSION;

    return (
      <Button
        theme={BUTTON_THEME.ICON}
        size={BUTTON_SIZE.AUTO}
        className="highnote-entity-preview highnote-entity-preview-button"
        // We don't want to allow or push for downloading files on mobile
        // which is their default click event. So only allow Space clicks.
        onClick={allowedOnClick ? handleOnClick : undefined}
        {...(version
          ? {
              "data-is-default-version": !!version.isDefaultVersion,
              "data-version-color-id": version.colorId,
            }
          : {})}
      >
        {renderThumbnailPreview()}
      </Button>
    );
  }

  return (
    <div
      className="highnote-entity-preview"
      {...(version
        ? {
            "data-is-default-version": !!version.isDefaultVersion,
            "data-version-color-id": version.colorId,
          }
        : {})}
    >
      {renderThumbnailPreview(handleOnClick)}
    </div>
  );
};

// This component was created separately to handle the mobile version of the TrackPreview
// which requires useTrackRow to handle the play/pause functionality.
// To avoid adding the useTrackRow hook to the EntityPreview component, we created this wrapper.
const TrackPreviewMobileWrapper = ({
  row,
  version,
  children,
}: PropsWithChildren<{
  row: EntityData;
  version?: Version;
}>) => {
  const track = row.entity as Track;
  const { handleOnTogglePlay } = useTrackRow(
    version?.track?.id || track.id,
    version ? track.id : undefined,
  );

  return (
    <Button
      theme={BUTTON_THEME.ICON}
      size={BUTTON_SIZE.AUTO}
      className="highnote-entity-preview highnote-entity-preview-button"
      {...(version
        ? {
            "data-is-default-version": !!version.isDefaultVersion,
            "data-version-color-id": version.colorId,
          }
        : {})}
      onClick={handleOnTogglePlay}
    >
      {children}
    </Button>
  );
};
