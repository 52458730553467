import React from "react";
import classNames from "classnames";
import moment from "moment";

import { ReactComponent as StarFilledSVG } from "App/common/icons/star-filled.svg";
import { AppCheckbox } from "App/core/Checkbox/AppCheckbox";
import { AppRadio } from "App/core/Radio/AppRadio";
import { ENTITY_TYPE } from "../EntityTable/config";
import { EntityPreview } from "../EntityTable/EntityPreview";
import { useTrack } from "../useEntities/useTrack";
import { useTrackEditor } from "../useTrackEditor";
import { useTrackVersionEditorContext } from "./utils/context/TrackVersionEditorContext";
import { useTrackVersionSelectionContext } from "./utils/context/TrackVersionSelectionContext";

import styles from "./TrackVersionEditor.module.scss";

export const TrackVersionsList = React.memo(() => {
  const { track } = useTrack();
  const { trackVersionEditorType } = useTrackEditor();
  const { allRows } = useTrackVersionEditorContext();
  const {
    isSelectionVisible,
    selectedEntities,
    singleSelectedVersion,
    onSingleSelectRow,
    onMultiSelectRow,
  } = useTrackVersionSelectionContext();

  const formatDate = (timestamp: number) => {
    const date = moment(timestamp);
    return date.isSame(moment(), "day") ? "Today" : date.format("MMMM D, YYYY");
  };

  return (
    <div className={styles.versionsContainer}>
      {allRows.map(({ date, rows }) => (
        <div key={date} className={styles["date-group"]}>
          <h3 className={styles["date-header"]}>{formatDate(date)}</h3>
          <ul className={styles["track-version-list"]}>
            {rows.map((row) => {
              let isDisabledSelectionOption =
                row.isDefaultVersion && isSelectionVisible;

              if (
                trackVersionEditorType === "swap" ||
                trackVersionEditorType === "pin"
              ) {
                isDisabledSelectionOption =
                  row.isDefaultVersion || row.isPinned;
              }

              return (
                <li key={row.id} className={styles["track-version-list-item"]}>
                  {trackVersionEditorType === "default" &&
                    isSelectionVisible && (
                      <AppCheckbox
                        checked={!!selectedEntities[row.id]}
                        onChange={() => onMultiSelectRow(row, track.id)}
                        disabled={isDisabledSelectionOption}
                      />
                    )}

                  {(trackVersionEditorType === "swap" ||
                    trackVersionEditorType === "pin") && (
                    <AppRadio
                      checked={singleSelectedVersion?.id === row.id}
                      onChange={() => onSingleSelectRow(row)}
                      disabled={
                        isDisabledSelectionOption ||
                        row.type === ENTITY_TYPE.UPLOAD
                      }
                    />
                  )}

                  <EntityPreview
                    hideThumbnail
                    row={{
                      id: row.id,
                      entity: row.entity,
                      type: row.type,
                    }}
                    className={classNames(
                      styles["track-version-list-item-preview"],
                      isDisabledSelectionOption &&
                        styles["default-version-selection"],
                    )}
                  />

                  {/* Default/Pinned Indicator */}
                  {row.isDefaultVersion && (
                    <span className={styles["pill-indicator"]}>
                      <StarFilledSVG />
                      Default
                    </span>
                  )}
                  {row.isPinned && !row.isDefaultVersion && (
                    <span className={styles["pill-indicator"]}>Pinned</span>
                  )}

                  {/* Actions */}
                  <div className={styles.versionActions}>{row.actions}</div>
                </li>
              );
            })}
          </ul>
        </div>
      ))}
    </div>
  );
});

TrackVersionsList.displayName = "TrackVersionsList";
