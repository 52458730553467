import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { FileEntity } from "@highnote/server/src/core/entities";
import { FileEditor } from "./FileEditor";

const FileEditorContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openFileEditor: (props: {
    file?: Partial<FileEntity>;
    tempFile?: File;
    onSave?: (fileEntity: FileEntity) => void;
  }) => {},
  closeFileEditor: () => {},
});

export const FileEditorProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isOpen, setOpen] = useState(false);
  const [file, setFile] = useState<Partial<FileEntity>>(undefined);
  const [tempFile, setTempFile] = useState<File>(undefined);
  const onSaveRef = useRef(undefined);

  const openFileEditor = useCallback(
    ({
      file,
      tempFile,
      onSave,
    }: {
      file?: Partial<FileEntity>;
      tempFile?: File;
      onSave?: (fileEntity: FileEntity) => void;
    }) => {
      setFile(file);
      setTempFile(tempFile);
      onSaveRef.current = onSave;
      setOpen(true);
    },
    [],
  );

  const closeFileEditor = useCallback(() => {
    setFile(undefined);
    setTempFile(undefined);
    setOpen(false);
  }, []);

  const value = useMemo(
    () => ({
      openFileEditor,
      closeFileEditor,
    }),
    [openFileEditor, closeFileEditor],
  );

  return (
    <FileEditorContext.Provider value={value}>
      {isOpen && (
        <FileEditor
          file={file}
          tempFile={tempFile}
          onSave={onSaveRef.current}
          onClose={closeFileEditor}
        />
      )}
      {children}
    </FileEditorContext.Provider>
  );
};

export const useFileEditor = () => useContext(FileEditorContext);
