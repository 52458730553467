// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".S7lhsyheYujKm6cOGP9F{display:flex;flex-direction:column;gap:12px;padding:12px 0}", "",{"version":3,"sources":["webpack://./src/App/components/EntityTable/ExpandableTrack/ExpandableTrackRow.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,cAAA","sourcesContent":[".skeleton-container {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  padding: 12px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeleton-container": "S7lhsyheYujKm6cOGP9F"
};
export default ___CSS_LOADER_EXPORT___;
