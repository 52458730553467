import React, { useEffect, useState } from "react";
import { ListItemButton } from "App/common/List";
import { Switch } from "App/core/Switch";
import { ReactComponent as CopySVG } from "App/common/icons/copy.svg";
import { ReactComponent as EyeSVG } from "App/common/icons/eye.svg";
import { ReactComponent as EyeSlashedSVG } from "App/common/icons/eye-slashed.svg";
import { ReactComponent as LockSVG } from "App/common/icons/locked.svg";
import { TextInput } from "App/common/TextInput";
import { BUTTON_THEME, Button } from "App/core/Button";
import { useToast } from "App/common/useToast";
import { PermissionTooltip } from "./PermissionTooltip";
import { CopyButton } from "App/common/CopyButton";
import { LoadingSpinner } from "App/common/icons/LoadingSpinner";
import { useAuth } from "./Auth";
import {
  CONTROLLED_SHARE_FEATURES,
  ControlledFeatureTooltipLabel,
} from "App/core/ControlledFeatureCallout";
import styles from "./EntityPasswordEditor.module.scss";

interface PasswordEditorProps {
  isEntityShared: boolean;
  entity: Entity;
  entityHasChildren: boolean;
  canManagePassword: boolean;
  password?: string;
  passwordEnabled: boolean;
  className?: string;
  updatePassword: (props: {
    password?: string;
    enabled?: boolean;
  }) => Promise<void>;
}

function generateRandomPassword() {
  const chars =
    "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const passwordLength = 12;
  let password = "";
  for (let i = 0; i <= passwordLength; i++) {
    const randomNumber = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomNumber, randomNumber + 1);
  }
  return password;
}

export const EntityPasswordEditor = ({
  isEntityShared,
  entity,
  entityHasChildren,
  canManagePassword,
  password,
  passwordEnabled,
  className,
  updatePassword,
}: PasswordEditorProps) => {
  const { user } = useAuth();
  const { addSuccessMessage, addErrorMessage } = useToast();

  const [inputType, setInputType] = useState("password");
  const [newPassword, setNewPassword] = useState(password || "");

  useEffect(() => {
    setNewPassword(password || "");
  }, [password, passwordEnabled]);

  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const togglePassword = async () => {
    setIsLoading(true);
    const shouldEnable = !passwordEnabled;
    const newPwd = shouldEnable ? newPassword || generateRandomPassword() : "";
    if (!shouldEnable) {
      setInputType("password");
    }
    try {
      await updatePassword({
        enabled: shouldEnable,
        ...(newPwd && { password: newPwd }),
      });
    } catch (err) {
      addErrorMessage(err.message || "Failed to update password settings");
    } finally {
      setIsLoading(false);
    }
  };

  const savePassword = async () => {
    if (!newPassword) {
      addErrorMessage("Password cannot be empty.");
      return;
    }
    setIsSaving(true);
    try {
      await updatePassword({
        password: newPassword,
      });
      addSuccessMessage("Successfully updated the password.");
    } catch (err) {
      addErrorMessage(err.message || "Failed to update the password.");
    } finally {
      setIsSaving(false);
    }
  };

  const togglePasswordVisibility = () => {
    setInputType(inputType === "password" ? "text" : "password");
  };
  const baseDisabled = isSaving || isLoading || entityHasChildren;

  return (
    <>
      <ListItemButton
        className={className}
        disabled={!canManagePassword || baseDisabled}
        onClick={togglePassword}
      >
        <LockSVG />
        <p>
          <span className="title">Require Password</span>
        </p>
        <PermissionTooltip
          title={
            entity?.createdBy === user.id
              ? entityHasChildren
                ? "Spaces with nested spaces cannot be password protected."
                : isEntityShared
                  ? ControlledFeatureTooltipLabel[
                      CONTROLLED_SHARE_FEATURES.PASSWORD
                    ]
                  : "Sharing is disabled for this resource."
              : "Contact the space owner"
          }
          hasPermission={canManagePassword && !entityHasChildren}
        >
          <div>
            <Switch
              disabled={!canManagePassword || baseDisabled}
              checked={canManagePassword && passwordEnabled}
            />
          </div>
        </PermissionTooltip>
      </ListItemButton>
      {canManagePassword && passwordEnabled && (
        <div className={styles["password-input"]}>
          <div className={styles["password-input-inner"]}>
            <TextInput
              isSingleLine
              maxLength={20}
              isDisabled={baseDisabled}
              type={inputType}
              value={newPassword}
              onChange={(value) => {
                // remove any whitespace
                setNewPassword(value?.replace(/\s/g, ""));
              }}
            />
            <button onClick={togglePasswordVisibility}>
              {inputType === "password" ? <EyeSVG /> : <EyeSlashedSVG />}
            </button>
          </div>
          {isSaving || newPassword !== password ? (
            <Button
              className={styles["password-input-btn"]}
              theme={BUTTON_THEME.CTA}
              disabled={baseDisabled || !newPassword}
              onClick={savePassword}
            >
              {isSaving ? (
                <>
                  <LoadingSpinner /> Saving
                </>
              ) : (
                "Save Changes"
              )}
            </Button>
          ) : (
            <CopyButton disabled={baseDisabled} value={newPassword}>
              <CopySVG /> Copy Password
            </CopyButton>
          )}
        </div>
      )}
    </>
  );
};
