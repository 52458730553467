import "./Plans.scss";
import Radio from "@mui/material/Radio";
import { v4 as uuidv4 } from "uuid";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import React, { useEffect, useState } from "react";
import { highnote } from "@highnote/server/src/sdk";
import Stripe from "stripe";
import { useTheme } from "App/common/ThemeProvider";
import { Button, BUTTON_THEME, BUTTON_SIZE } from "App/core/Button";
import { PlanCard } from "./PlanCard";
import { ReactComponent as ExternalLinkSVG } from "App/common/icons/external-link.svg";
import {
  formatStripeDate,
  parseScheduledSubscription,
  parseSubscription,
} from "@highnote/server/src/core/shared-util";
import { PLANS } from "./config";
import {
  STRIPE_INTERVAL_FORMATTED,
  SUBSCRIPTION_INTERVAL,
  SUBSCRIPTION_TIER,
  SUBSCRIPTION_TIER_FORMATTED,
  UserSubscriptions,
} from "@highnote/server/src/core/entities";
import { ToastMessageContent, useToast } from "App/common/useToast";
import { useAuth } from "../Auth";
import { Switch } from "App/core/Switch";
import { List, ListItemButton } from "App/common/List";
import {
  APP_FEATURES,
  AppFeaturesStatus,
} from "@highnote/server/src/core/features";

const getPlanHeading = (
  subscriptions: UserSubscriptions,
): Record<string, string> => {
  if (!subscriptions?.active) {
    return {
      "Current Plan": "Your current plan is Highnote Free.",
    };
  }

  const headingsMap: Record<string, string> = {};
  const active = parseSubscription(subscriptions.active);
  const scheduled = parseScheduledSubscription(subscriptions.scheduled);
  const cancelAt = subscriptions.active.cancel_at;

  const currentPrice =
    subscriptions.active.items.data[0]?.price.unit_amount || 0;
  headingsMap["Current Plan"] = `Highnote ${
    SUBSCRIPTION_TIER_FORMATTED[active.tier]
  },billed ${STRIPE_INTERVAL_FORMATTED[active.interval]}.`;
  headingsMap["Next Payment Due"] = `${formatStripeDate(
    subscriptions.active.current_period_end,
  )} for $${(currentPrice / 100).toFixed(0)}`;

  const getSuffix = (plan: {
    tier: SUBSCRIPTION_TIER;
    interval: SUBSCRIPTION_INTERVAL;
  }) => {
    return plan.tier !== SUBSCRIPTION_TIER.FREE
      ? `, billed ${STRIPE_INTERVAL_FORMATTED[plan.interval]}`
      : "";
  };

  if (!cancelAt) {
    return {
      "Current Plan": `Highnote ${
        SUBSCRIPTION_TIER_FORMATTED[active.tier]
      }${getSuffix(active)}.`,
      "Next Payment Due": `${formatStripeDate(
        subscriptions.active.current_period_end,
      )} for $${(currentPrice / 100).toFixed(0)}`,
    };
  }

  return {
    "Upcoming Plan": `Highnote ${
      SUBSCRIPTION_TIER_FORMATTED[scheduled.tier]
    }${getSuffix(scheduled)}.`,
    "Expiring Plan": `Highnote ${
      SUBSCRIPTION_TIER_FORMATTED[active.tier]
    }${getSuffix(active)}.`,
  };
};

export const PlanPicker = ({
  onUpgradeClick,
}: {
  onUpgradeClick?: () => void;
}) => {
  const { user } = useAuth();

  const [interval, setInterval] =
    useState<Stripe.Price.Recurring.Interval>("month");
  const [loading, setLoading] = useState(true);
  const [priceId, setPriceId] = useState(
    (user.subscriptions?.scheduled
      ? parseScheduledSubscription(user.subscriptions.scheduled)
      : parseSubscription(user.subscriptions?.active)
    )?.priceId,
  );

  useEffect(() => {
    if (loading) setLoading(false);
    if (user.subscriptions?.scheduled || user.subscriptions?.active) {
      setPriceId(
        (user.subscriptions?.scheduled
          ? parseScheduledSubscription(user.subscriptions.scheduled)
          : parseSubscription(user.subscriptions?.active)
        )?.priceId,
      );
    }
  }, [user.subscriptions]);

  useEffect(() => {
    if (!priceId) return;

    const fetchPrice = async () => {
      const price = await highnote.getPrice({ priceId });
      setInterval(price.recurring.interval);
    };

    fetchPrice().catch(() => console.error("Failed to fetch current price."));
  }, [priceId]);

  return (
    <div className="PlanPicker">
      <section className="interval-picker-row">
        <FormControl>
          <RadioGroup
            row
            value={interval}
            onChange={(_e, value) =>
              setInterval(value as Stripe.Price.Recurring.Interval)
            }
          >
            <FormControlLabel
              value="month"
              control={<Radio />}
              label={STRIPE_INTERVAL_FORMATTED[SUBSCRIPTION_INTERVAL.MONTHLY]}
            />

            <div className="annual-container">
              <FormControlLabel
                value="year"
                control={<Radio />}
                label={STRIPE_INTERVAL_FORMATTED[SUBSCRIPTION_INTERVAL.ANNUAL]}
              />
              <div className="highlighted-hint">Save 20%</div>
            </div>
          </RadioGroup>
        </FormControl>
      </section>

      <div className="cards">
        {PLANS.map((plan) => (
          <PlanCard
            key={plan.tier}
            plan={plan}
            interval={interval}
            onUpgradeClick={onUpgradeClick}
          />
        ))}
      </div>
    </div>
  );
};

export const Plans = () => {
  const { addMessage } = useToast();
  const { theme } = useTheme();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const planStatus = getPlanHeading(user.subscriptions);

  const [experimentalFeatures, setExperimentalFeatures] = useState({
    isMobileUser: Boolean(user.isMobileUser),
    experimentalPrivateInboxEnabled: Boolean(
      user.experimentalPrivateInboxEnabled,
    ),
  });

  const openCustomerPortal = async () => {
    setLoading(true);
    try {
      const url = await highnote.getCustomerPortalUrl({
        source: window.location.pathname,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).location.href = url;
    } catch (e) {
      const toastId = uuidv4();
      addMessage({
        id: toastId,
        children: (
          <ToastMessageContent
            type="error"
            id={toastId}
            text={`Could not open customer portal. Please try again.`}
          />
        ),
        expireAt: Date.now() + 10000, // 10 seconds from now
      });
      console.log("[Plans > openCustomerPortal]", e);
      setLoading(false);
    }
  };

  const handleExperimentalFeatureToggle = async (
    featureName: "isMobileUser" | "experimentalPrivateInboxEnabled",
  ) => {
    if (
      !AppFeaturesStatus[APP_FEATURES.PRIVATE_INBOX] &&
      featureName === "experimentalPrivateInboxEnabled"
    ) {
      return;
    }
    const prevState = experimentalFeatures;
    try {
      setExperimentalFeatures((prev) => ({
        ...prev,
        [featureName]: !prev[featureName],
      }));
      await highnote.updateUser({
        id: user.id,
        data: {
          [featureName]: !user[featureName],
        },
      });
    } catch (e) {
      setExperimentalFeatures(prevState);
    }
  };

  return (
    <div className="highnote-plans settings-tab-contents" data-theme={theme}>
      <div className="section-header">Billing</div>
      <ul className="section-sub-header">
        {Object.keys(planStatus).map((subHeading) => {
          return (
            <li
              className="section-sub-header-item"
              data-cypress-id={"plans-subheading"}
              key={subHeading}
            >
              <strong>{subHeading}:</strong> {planStatus[subHeading]}
            </li>
          );
        })}
      </ul>
      <Button
        className="highnote-plans-manage-billing"
        loading={loading}
        theme={BUTTON_THEME.SECONDARY}
        size={BUTTON_SIZE.SMALL}
        type="button"
        onClick={openCustomerPortal}
      >
        Manage Billing
      </Button>
      <div className="section-header">Change Your Plan</div>
      <PlanPicker />

      <div className="external-links experimental-features">
        <div className="section-header">Experimental Features</div>
        <List>
          <ListItemButton
            onClick={() => {
              handleExperimentalFeatureToggle("isMobileUser");
            }}
          >
            <div>
              <p>Enable App Handoff</p>
              <Switch checked={experimentalFeatures.isMobileUser} />
            </div>
          </ListItemButton>
          {AppFeaturesStatus[APP_FEATURES.PRIVATE_INBOX] && (
            <ListItemButton
              onClick={() => {
                handleExperimentalFeatureToggle(
                  "experimentalPrivateInboxEnabled",
                );
              }}
            >
              <div>
                <p>Enable Private Inbox Mode</p>
                <Switch
                  checked={experimentalFeatures.experimentalPrivateInboxEnabled}
                />
              </div>
            </ListItemButton>
          )}
        </List>
      </div>
      <div className="external-links">
        <div className="section-header">Learn More</div>
        <a
          target="_blank"
          href="https://help.highnote.fm/hc/en-us/articles/13136910346509"
          rel="noopener noreferrer"
        >
          <ExternalLinkSVG />
          Subscription FAQ
        </a>
        <a
          target="_blank"
          href="https://www.highnote.fm/pricing"
          rel="noopener noreferrer"
        >
          <ExternalLinkSVG />
          Compare Plans
        </a>
      </div>
    </div>
  );
};
