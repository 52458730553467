import React, { createContext, useContext, useReducer } from "react";
import { COLLECTION_ID, Space } from "@highnote/server/src/core/entities";
import { useTopLevelSpaces } from "App/components/useEntities/useTopLevelSpaces";
import { QueryConstraint } from "firebase/firestore";
import {
  WatcherComponentId,
  WatcherState,
  useWatcherManagementV2,
} from "../watchers/useWatcherV2";

type GlobalSpacesAction =
  | { type: "SET_SPACE"; payload: Space }
  | { type: "REMOVE_SPACE"; payload: Space["id"] }
  | { type: "ADD_SPACES"; payload: Space[] }
  | {
      type: "ADD_CHILD_SPACES";
      payload: { parentSpaceId: string; entities: Space[] };
    };

type GlobalSpacesState = {
  spaces: Map<string, Space>;
  // Map of parent space id to array of child space ids
  childSpaces: Map<string, Array<Space["id"]>>;
};

function spacesReducer(
  state: GlobalSpacesState,
  action: GlobalSpacesAction,
): GlobalSpacesState {
  switch (action.type) {
    case "SET_SPACE": {
      const newSpaces = new Map(state.spaces);
      newSpaces.set(action.payload.id, action.payload);
      return { ...state, spaces: newSpaces };
    }
    case "REMOVE_SPACE": {
      const newSpaces = new Map(state.spaces);
      newSpaces.delete(action.payload);
      return { ...state, spaces: newSpaces };
    }
    case "ADD_SPACES": {
      const newSpaces = new Map(state.spaces);
      action.payload.forEach((space) => {
        newSpaces.set(space.id, space);
      });
      return {
        ...state,
        spaces: newSpaces,
      };
    }
    case "ADD_CHILD_SPACES": {
      const { parentSpaceId, entities } = action.payload;
      const newChildSpacesState = new Map(state.childSpaces);
      const newSpacesState = new Map(state.spaces);

      // `action.payload` returns a new array of entities, so we reset the child space id's
      // here to ensure we're not adding duplicates, or keeping stale data.
      const newChildSpaceIds = new Set<string>();

      entities.forEach((space) => {
        // Add new children to the Set to ensure all entries are unique
        newChildSpaceIds.add(space.id);
        // Add or update child spaces in the global spaces map
        newSpacesState.set(space.id, space);
      });

      // Update child spaces map with the unique list of child IDs
      newChildSpacesState.set(parentSpaceId, Array.from(newChildSpaceIds));

      return {
        ...state,
        spaces: newSpacesState,
        childSpaces: newChildSpacesState,
      };
    }
    default:
      return state;
  }
}

type GlobalSpacesContext = {
  globalSpaces: GlobalSpacesState;
  globalSpacesWatchers: WatcherState;
  globalSpacesDispatch: React.Dispatch<GlobalSpacesAction>;
  manageGlobalSingleSpaceWatcher: ({
    action,
    spaceId,
    onSpaceAdd,
    componentId,
  }: {
    action: "attach" | "detach";
    spaceId: string;
    componentId: WatcherComponentId;
    onSpaceAdd?: (space: Space) => void;
  }) => Promise<void>;
  manageGlobalMultipleSpacesWatcher: ({
    action,
    watcherKey,
    constraints,
    componentId,
    limit,
  }: {
    action: "attach" | "detach";
    watcherKey: string;
    componentId: WatcherComponentId;
    constraints?: QueryConstraint[];
    limit?: number;
  }) => Promise<void>;
  getGlobalSpace: (spaceId: string, dontUseWatcherCheck?: boolean) => Space;
  hasGlobalSpace: (spaceId: string) => boolean;
};

const GlobalSpacesContext = createContext<GlobalSpacesContext>(
  null as GlobalSpacesContext,
);

export const GlobalSpacesContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { topLevelSpaces } = useTopLevelSpaces();
  const [globalSpaces, globalSpacesDispatch] = useReducer(spacesReducer, {
    spaces: new Map(),
    childSpaces: new Map(),
  });

  const { activeWatchers: globalSpacesWatchers, manageWatcher } =
    useWatcherManagementV2<Space>();

  const manageGlobalSingleSpaceWatcher = async ({
    action,
    spaceId,
    onSpaceAdd,
    componentId,
  }: {
    action: "attach" | "detach";
    spaceId: string;
    componentId: WatcherComponentId;
    onSpaceAdd?: (space: Space) => void;
  }) => {
    await manageWatcher({
      watcherType: "single",
      action,
      componentId,
      entityId: spaceId,
      collectionId: COLLECTION_ID.SPACE,
      ...(action === "attach" && {
        onEntityChange: {
          add: (space) => {
            globalSpacesDispatch({
              type: "SET_SPACE",
              payload: space,
            });
            onSpaceAdd?.(space);
          },
          remove: (spaceId) => {
            globalSpacesDispatch({
              type: "REMOVE_SPACE",
              payload: spaceId,
            });
          },
        },
      }),
    });
  };

  const manageGlobalMultipleSpacesWatcher = async ({
    action,
    watcherKey,
    constraints,
    componentId,
    limit,
  }: {
    action: "attach" | "detach";
    watcherKey: string;
    componentId: WatcherComponentId;
    constraints?: QueryConstraint[];
    limit?: number;
  }) => {
    await manageWatcher({
      watcherType: "multiple",
      action,
      componentId,
      watcherKey,
      collectionId: COLLECTION_ID.SPACE,
      ...(action === "attach" && {
        constraints: constraints || [],
        onEntitiesChange: (spaces) => {
          globalSpacesDispatch({
            type: "ADD_CHILD_SPACES",
            payload: { parentSpaceId: watcherKey, entities: spaces },
          });
        },
        limit,
      }),
    });
  };

  const hasGlobalSpace = (spaceId: string) => {
    return (
      globalSpacesWatchers.has(spaceId) && globalSpaces.spaces.has(spaceId)
    );
  };

  const getGlobalSpace = (spaceId: string, dontUseWatcherCheck?: boolean) => {
    if (!dontUseWatcherCheck && hasGlobalSpace(spaceId)) {
      return globalSpaces.spaces.get(spaceId);
    }

    if (globalSpaces.spaces.has(spaceId)) {
      return globalSpaces.spaces.get(spaceId);
    }

    // TODO(GlobalSpace): This is a temporary solution to get the space from the top level spaces.
    // We should be able to get the space from the global spaces in the future, once they are synced.
    return topLevelSpaces.find((space) => space.id === spaceId);
  };

  return (
    <GlobalSpacesContext.Provider
      value={{
        globalSpaces,
        globalSpacesWatchers,
        globalSpacesDispatch,
        manageGlobalSingleSpaceWatcher,
        manageGlobalMultipleSpacesWatcher,
        getGlobalSpace,
        hasGlobalSpace,
      }}
    >
      {children}
    </GlobalSpacesContext.Provider>
  );
};

export const useGlobalSpaces = () => {
  const context = useContext(GlobalSpacesContext);

  if (!context) {
    throw new Error(
      "useGlobalSpaces must be used within a GlobalSpacesContextProvider",
    );
  }

  return context;
};
