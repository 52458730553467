// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".y5ZLR9DHXxkFxSr_PuqL:first-child{padding-left:0 !important}.y5ZLR9DHXxkFxSr_PuqL:last-child{padding-right:0 !important}.mTb7yKqqW9tJ2XvVUywo{padding-right:32.5px !important}.GE49MwIFgSqs3gc0aMKI{display:flex;justify-content:space-between}", "",{"version":3,"sources":["webpack://./src/App/routes/Dropbox/dropboxSpacePicker.module.scss"],"names":[],"mappings":"AAII,kCACE,yBAAA,CAEF,iCACE,0BAAA,CAEF,sBACE,+BAAA,CAGJ,sBACE,YAAA,CACA,6BAAA","sourcesContent":["@import \"App/styles/_variables.scss\";\n\n.dropboxSpacePicker {\n  &__button {\n    &:first-child {\n      padding-left: 0 !important;\n    }\n    &:last-child {\n      padding-right: 0 !important;\n    }\n    &__loading {\n      padding-right: 32.5px !important;\n    }\n  }\n  &__buttons {\n    display: flex;\n    justify-content: space-between;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropboxSpacePicker__button": "y5ZLR9DHXxkFxSr_PuqL",
	"dropboxSpacePicker__button__loading": "mTb7yKqqW9tJ2XvVUywo",
	"dropboxSpacePicker__buttons": "GE49MwIFgSqs3gc0aMKI"
};
export default ___CSS_LOADER_EXPORT___;
