import "./Settings.scss";
import React, { useState } from "react";
import { Button, BUTTON_THEME, BUTTON_SIZE } from "App/core/Button";
import LoadingScreen from "App/common/LoadingScreen/LoadingScreen";
import { PlanLimitCallout } from "App/components/Plans/PlanLimitCallout";
import { StorageUsage } from "App/components/Usages/StorageUsage";
import { SpaceUsage } from "App/components/Usages/SpaceUsage";
import { useUsageEditor } from "App/components/Usages/UsageEditor";
import { TrackVersionsUsage } from "App/components/Usages/TrackVersionsUsage";

export const UsageDetails = () => {
  const [loading] = useState(false);
  const {
    openStorageUsageEditor,
    openSpacesUsageEditor,
    openTrackVersionsUsageEditor,
  } = useUsageEditor();

  if (loading) return <LoadingScreen />;

  return (
    <div className="settings-tab-contents">
      <span className="usage-details">
        <section className="details">
          <div className="section-header">Usage</div>
          <label>
            <h3>Tracks</h3>
          </label>

          <TrackVersionsUsage />
          <br style={{ lineHeight: "10px" }} />
          <Button
            theme={BUTTON_THEME.SECONDARY}
            size={BUTTON_SIZE.SMALL}
            type="button"
            onClick={openTrackVersionsUsageEditor}
          >
            Manage Tracks
          </Button>

          <label>
            <h3>Storage</h3>
          </label>

          <StorageUsage />
          <br style={{ lineHeight: "10px" }} />
          <Button
            theme={BUTTON_THEME.SECONDARY}
            size={BUTTON_SIZE.SMALL}
            type="button"
            onClick={openStorageUsageEditor}
          >
            Manage Storage
          </Button>

          <label>
            <h3>Spaces</h3>
          </label>

          <SpaceUsage />
          <br style={{ lineHeight: "10px" }} />
          <Button
            theme={BUTTON_THEME.SECONDARY}
            size={BUTTON_SIZE.SMALL}
            type="button"
            onClick={openSpacesUsageEditor}
          >
            Manage Shared Spaces
          </Button>
        </section>

        <section className="plan-limit-callout">
          <PlanLimitCallout />
        </section>
      </span>
    </div>
  );
};
