// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OWVB8dfT1vzWfOUJ5Jug{background-color:var(--color-background-skeleton-primary);border-radius:8px;height:1rem;margin:0;width:100%;overflow:hidden}.OWVB8dfT1vzWfOUJ5Jug.VJeTotHW4HSfhRPtAvCi{background:linear-gradient(90deg, var(--color-background-skeleton-primary), var(--color-background-skeleton-secondary) 50%, var(--color-background-skeleton-primary));background-size:400% 100%;animation:ceKQfGE6LSqSpsFr_Y0r 2s infinite linear alternate}@keyframes ceKQfGE6LSqSpsFr_Y0r{0%{background-position:100% 0}100%{background-position:0 0}}", "",{"version":3,"sources":["webpack://./src/App/common/Skeleton/Skeleton.module.scss"],"names":[],"mappings":"AAAA,sBACE,yDAAA,CACA,iBAAA,CACA,WAAA,CACA,QAAA,CACA,UAAA,CACA,eAAA,CAEA,2CAEE,qKAAA,CAMA,yBAAA,CACA,2DAAA,CAIJ,gCACE,GACE,0BAAA,CAEF,KACE,uBAAA,CAAA","sourcesContent":[".skeleton {\n  background-color: var(--color-background-skeleton-primary);\n  border-radius: 8px;\n  height: 1rem;\n  margin: 0;\n  width: 100%; /* Adjust width as needed */\n  overflow: hidden;\n\n  &.skeleton-animation {\n    /* Background gradient to create the shimmer effect */\n    background: linear-gradient(\n      90deg,\n      var(--color-background-skeleton-primary),\n      var(--color-background-skeleton-secondary) 50%,\n      var(--color-background-skeleton-primary)\n    );\n    background-size: 400% 100%; /* Size to accommodate the animation movement */\n    animation: shimmer 2s infinite linear alternate;\n  }\n}\n\n@keyframes shimmer {\n  0% {\n    background-position: 100% 0;\n  }\n  100% {\n    background-position: 0 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeleton": "OWVB8dfT1vzWfOUJ5Jug",
	"skeleton-animation": "VJeTotHW4HSfhRPtAvCi",
	"shimmer": "ceKQfGE6LSqSpsFr_Y0r"
};
export default ___CSS_LOADER_EXPORT___;
