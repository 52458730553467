import "./VolumeControl.scss";
import React, { useCallback, useState } from "react";
import { ReactComponent as Volume } from "App/common/icons/volume.svg";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { Slider } from "App/core/Slider";
import constants from "App/modules/consts";
import { daw } from "@highnote/daw/src";
import { ReactComponent as CloseSVG } from "App/common/icons/close.svg";
import { throttle } from "lodash";

const CACHE_VOLUME_LEVEL_KEY = constants.cache.volumeLevelKey;
const CACHE_VOLUME_MUTED_KEY = constants.cache.volumeMutedKey;

export const VolumeControl = () => {
  const [isMuted, setIsMuted] = useState<boolean>(daw.state.volume === 0);
  const [level, setLevel] = useState<number>(daw.state.volume);

  const mute = useCallback(() => {
    localStorage.setItem(CACHE_VOLUME_MUTED_KEY, "true");
    daw.setVolume(0);
    setIsMuted(true);
  }, []);

  const unmute = useCallback(() => {
    localStorage.setItem(CACHE_VOLUME_MUTED_KEY, "false");
    daw.setVolume(level);
    setIsMuted(false);
  }, []);

  const changeLevel = useCallback(
    throttle((e: Event, newLevel: number) => {
      localStorage.setItem(CACHE_VOLUME_LEVEL_KEY, JSON.stringify(newLevel));
      daw.setVolume(newLevel);
      setLevel(newLevel);
    }, 30),
    [],
  );

  const iconVolumeIndicator = isMuted ? (
    <CloseSVG className="mute-icon" />
  ) : (
    <div className="volume-level-bars">
      <div className="vertical-bar left" />
      <div
        className="vertical-bar middle"
        style={{ height: `${80 * (level / 100) + 60}%` }}
      />
      <div
        className="vertical-bar right"
        style={{ height: `${160 * (level / 100) + 20}%` }}
      />
    </div>
  );

  return (
    <div className="highnote-volume-control">
      <Button
        size={BUTTON_SIZE.XSMALL}
        theme={BUTTON_THEME.PRIMARY}
        className="volume"
        onClick={isMuted ? unmute : mute}
      >
        <Volume />
        {iconVolumeIndicator}
      </Button>
      <Slider
        disabled={isMuted}
        value={isMuted ? 0 : level}
        onChange={changeLevel}
      />
    </div>
  );
};
