import React, { useEffect, useState } from "react";
import { daw, useDAW } from "@highnote/daw/src";
import { FileEntity } from "@highnote/server/src/core/entities";
import { FILE_NOT_FOUND_ERROR } from "@highnote/server/src/core/shared-util";
import { highnote } from "@highnote/server/src/sdk";
import { StatusIndicator } from "App/common/StatusIndicator";
import { Tooltip } from "App/core/Tooltip";

export const AudioProcessingIcon = ({
  file,
  dawTrackId,
  onRetry,
}: {
  file: FileEntity;
  dawTrackId?: string;
  onRetry?: () => void;
}) => {
  useDAW(["tracks"]);
  const dawTrack = daw.getTrack(dawTrackId);
  const [isRetrying, setRetrying] = useState(false);
  const error = file?.processingErrorV3 || dawTrack?.error;
  const canRetry = !!file && error !== FILE_NOT_FOUND_ERROR && !!onRetry;
  const tooltipText = isRetrying ? "Retrying..." : error || "Processing audio.";

  useEffect(() => {
    setRetrying(false);
  }, [file]);

  const retry = async () => {
    if (!file) return;
    setRetrying(true);
    await highnote.processAudioFile({ id: file.id });
    onRetry && onRetry();
  };

  return (
    <Tooltip title={tooltipText}>
      <span className="highnote-audio-processing-icon">
        <StatusIndicator
          isLoading
          errorMessage={isRetrying ? undefined : error}
          retryCallback={canRetry ? retry : undefined}
        />
      </span>
    </Tooltip>
  );
};
