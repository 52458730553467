import { Space } from "@highnote/server/src/core/entities";
import { SpacesProvider, useSpaces } from "App/components/useEntities";
import React, { createContext, useContext, useMemo } from "react";
import { orderBy, where } from "firebase/firestore";
import { useAuth } from "App/components/Auth";

type TopLevelSpacesContextValue = {
  topLevelSpaces: Space[];
  topLevelSpacesLoading: boolean;
  totalLimitTopLevelSpaces: number;
  loadMoreTopLevelSpaces: () => void;
};

const TopLevelSpacesContext = createContext<TopLevelSpacesContextValue>({
  topLevelSpaces: [],
  topLevelSpacesLoading: true,
  totalLimitTopLevelSpaces: 0,
  loadMoreTopLevelSpaces: () => undefined,
});

const _TopLevelSpacesContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    entities: topLevelSpaces,
    loading: topLevelSpacesLoading,
    loadMore: loadMoreTopLevelSpaces,
    totalLimit: totalLimitTopLevelSpaces,
  } = useSpaces();

  const value = useMemo(() => {
    return {
      topLevelSpaces,
      topLevelSpacesLoading,
      loadMoreTopLevelSpaces,
      totalLimitTopLevelSpaces,
    };
  }, [
    topLevelSpaces,
    topLevelSpacesLoading,
    loadMoreTopLevelSpaces,
    totalLimitTopLevelSpaces,
  ]);

  return (
    <TopLevelSpacesContext.Provider value={value}>
      {children}
    </TopLevelSpacesContext.Provider>
  );
};

export const TopLevelSpacesContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user } = useAuth();
  const constraints = useMemo(() => {
    if (!user?.id) return [];
    // PUBLIC_ID should not be used here as doing so will fetch all spaces that are public
    return [
      where("topLevelReadableBy", "array-contains-any", [user.id]),
      orderBy("name", "asc"),
    ];
  }, [user?.id]);

  return (
    <SpacesProvider constraints={constraints} limit={100}>
      <_TopLevelSpacesContextProvider>
        {children}
      </_TopLevelSpacesContextProvider>
    </SpacesProvider>
  );
};

export const useTopLevelSpaces = () => useContext(TopLevelSpacesContext);
