import {
  ref,
  uploadBytesResumable,
  UploadTaskSnapshot,
  UploadTask,
} from "firebase/storage";
import { storage } from "./firebase";

type UploadFileProps = {
  file: Blob | Uint8Array | ArrayBuffer;
  storagePath: string;
  onStateChange?: (snapshot: UploadTaskSnapshot) => void;
  metadata?: Record<string, string>;
};

export const uploadFile: (props: UploadFileProps) => Promise<void> = ({
  file,
  storagePath,
  onStateChange,
  metadata,
}) => {
  return new Promise((resolve, reject) => {
    let lastUpdate = Date.now();
    let unsubscribe: () => void;
    let uploadTask: UploadTask;

    const interval = setInterval(() => {
      const now = Date.now();
      // If there hasn't been an update in 20 seconds, assume that all hope is lost.
      if (now - lastUpdate > 20000) {
        uploadTask && uploadTask.cancel();
        unsubscribe && unsubscribe();
        clearInterval(interval);
        reject(
          new Error("File upload was not able to complete. Please try again."),
        );
      }
    }, 5000);

    try {
      const storageRef = ref(storage, storagePath);
      uploadTask = uploadBytesResumable(storageRef, file, {
        customMetadata: metadata || {},
      });
      unsubscribe = uploadTask.on("state_changed", {
        next: (snapshot) => {
          onStateChange && onStateChange(snapshot);
          lastUpdate = Date.now();
        },
        error: (error) => {
          reject(error);
        },
        complete: () => {
          resolve();
        },
      });
    } catch (error) {
      reject(error);
    }
  });
};
