import { FirebaseError } from "@firebase/util";
import { Unsubscribe } from "@firebase/firestore";
import { HttpsError } from "firebase-functions/v1/https";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { highnote } from "@highnote/server/src/sdk";
import { FileEntity, Space } from "@highnote/server/src/core/entities";
import {
  SPACE_GUEST_MODE_DISABLED,
  SPACE_INBOX_PERMISSION_DENIED,
  SPACE_NOT_FOUND,
  SPACE_PERMISSION_DENIIED,
  SPACE_SHARING_DISABLED,
} from "./util/ERRORS";
import { v4 as uuidv4 } from "uuid";
import { useUrlContext } from "App/routes/Main/useUrlContext";
import { useFiles } from "App/components/useFiles";
import { useHistory } from "react-router";
import { getAuthId, useAuth } from "App/components/Auth";
import {
  GUEST_MODE_DISABLED_ERR_MESSAGE,
  INBOX_PERMISSION_DENIED_ERR_MESSAGE,
  getEntityShareKeys,
} from "@highnote/server/src/core/shared-util";
import { useChildEntityWatchers } from "App/store/helpers/useChildEntityWatchers";
import { SORT_TYPE } from "App/routes/Main/Library/config";

const MIN_LOAD_TIME = 1000;

type SpaceContextValue = {
  itemSortType: SORT_TYPE;
  refreshSubscription: (skipCheck: boolean, count: number) => void;
  setItemSortType: React.Dispatch<React.SetStateAction<SORT_TYPE>>;
  setSpaceName: (name: string) => void;
  setSpaceOwner: (owner: string) => void;
  setSpaceArtworkUrl: (url: string) => void;
  space: Space;
  spaceName: string;
  spaceOwner: string;
  spaceArtworkUrl: string;
  spaceError: string;
  spaceId: Id;
  spaceLoading: boolean;
};

const SpaceContext = createContext<SpaceContextValue>({
  itemSortType: SORT_TYPE.CUSTOM,
  refreshSubscription: () => {},
  setItemSortType: () => {},
  setSpaceName: () => {},
  setSpaceOwner: () => {},
  setSpaceArtworkUrl: () => undefined,
  space: undefined,
  spaceName: undefined,
  spaceOwner: undefined,
  spaceArtworkUrl: undefined,
  spaceError: undefined,
  spaceId: undefined,
  spaceLoading: true,
});

const getSpaceFiles = async (space: Space) => {
  const files: {
    artworkFile?: FileEntity;
  } = {};

  if (!space) return files;

  const artworkFiles = space.artworkFile
    ? await highnote.getFiles({ ids: [space.artworkFile] })
    : undefined;

  const artworkFile = (artworkFiles || [])[0];
  files.artworkFile = artworkFile;
  return files;
};

export const useSpaceFiles = (space: Space) => {
  const [artwork, setArtwork] = useState<FileEntity>();

  useEffect(() => {
    if (!space) {
      setArtwork(undefined);
      return;
    }

    getSpaceFiles(space).then(({ artworkFile }) => {
      setArtwork(artworkFile);
    });
  }, [space]);

  return {
    artwork,
  };
};

export const SpaceContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const history = useHistory();
  const { refreshShareKey } = useAuth();
  const { getDownloadUrl } = useFiles();
  const { spaceId } = useUrlContext();
  const lastSpaceIdRef = useRef<Id>(spaceId);
  const [spaceError, setSpaceError] = useState<string>();
  const [spaceName, setSpaceName] = useState<string>();
  const [spaceOwner, setSpaceOwner] = useState<string>();
  const [space, setSpace] = useState<Space>();
  const [itemSortType, setItemSortType] = useState<SORT_TYPE>(SORT_TYPE.CUSTOM);

  const { hasActiveChildEntityWatchers, manageGlobalChildEntityWatchers } =
    useChildEntityWatchers();

  const { artwork } = useSpaceFiles(space);
  const [spaceArtworkUrl, setSpaceArtworkUrl] = useState<string>();
  const [retryTrigger, setRetryTrigger] = useState<{
    count: number;
    skipProtectionCheck: boolean;
    trigger: string;
  }>({
    count: 0,
    skipProtectionCheck: false,
    trigger: uuidv4(),
  });
  const unmountedRef = useRef<boolean>(false);
  const retryCountRef = useRef<number>(0);

  useEffect(
    () => () => {
      unmountedRef.current = true;
    },
    [],
  );

  const updateURLWithShareKey = (space: Space) => {
    const query = new URLSearchParams(location.search);
    const shareKeyInEntity = getEntityShareKeys(space)[0];
    if (shareKeyInEntity) {
      query.set("shareKey", shareKeyInEntity);
    } else {
      query.delete("shareKey");
    }
    history.replace({
      pathname: location.pathname,
      search: query.toString(),
    });
  };

  useEffect(() => {
    let unmounted: boolean;
    const loadStart = Date.now();

    if (lastSpaceIdRef.current !== spaceId) {
      setSpaceError(undefined);
      setSpace(undefined);
      setRetryTrigger({
        count: 0,
        skipProtectionCheck: false,
        trigger: uuidv4(),
      });
      lastSpaceIdRef.current = spaceId;
      return;
    }

    if (!spaceId) {
      return;
    }
    let unsubscribeSpace: Unsubscribe;

    const checkPwdProtection = (): Promise<{ redirectUrl?: string } | null> => {
      const query = new URLSearchParams(location.search);
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (retryTrigger.count > 5) {
            reject(new FirebaseError("internal error", ""));
            return;
          }
          if (retryTrigger.skipProtectionCheck) {
            resolve(null);
            return;
          }
          highnote
            .checkProtectedEntity({
              entityId: spaceId,
              shareKey: query.get("shareKey") || null,
            })
            .then(resolve)
            .catch(reject);
        }, retryTrigger.count * 750);
      });
    };

    checkPwdProtection()
      .then(async (resp) => {
        await refreshShareKey();
        if (unsubscribeSpace) {
          unsubscribeSpace();
        }
        if (resp?.redirectUrl) {
          history.replace(resp.redirectUrl);
          setRetryTrigger({
            count: 0,
            skipProtectionCheck: false,
            trigger: uuidv4(),
          });
          return;
        }
        unsubscribeSpace = highnote.watchSpace({
          id: spaceId,
          onChange: (_space: Space) => {
            if (unmounted) return;
            retryCountRef.current = 0;
            setSpaceError(undefined);

            /* Force a load time because if it's too fast, it looks
            flickery and broken (even though it's not) */
            const now = Date.now();
            const loadTime = now - loadStart;
            const extraWaitTime =
              loadTime >= MIN_LOAD_TIME ? 0 : MIN_LOAD_TIME - loadTime;

            setTimeout(() => {
              if (unmounted) return;
              setSpace((oldSpace) => {
                if (!oldSpace || oldSpace.id !== _space.id) {
                  updateURLWithShareKey(_space);
                }
                return _space;
              });
            }, extraWaitTime);
          },
          onError: (code) => {
            if (unmounted) return;

            if (code === "permission-denied") {
              setRetryTrigger((oldTrigger) => {
                const userId = getAuthId();
                const isAnonymous = !userId.includes("|");
                // if your access was revoked from the space you are currently viewing,
                // remove share key from the URL so that you do not get added back to the space
                if (!isAnonymous) {
                  setSpace((oldSpace) => {
                    // make sure to remove the share key only if your access was
                    // revoked while you were viewing the space.
                    if (oldSpace) {
                      history.replace(`/space/${spaceId}`);
                    }
                    return undefined;
                  });
                }
                return {
                  count: (oldTrigger.count || 0) + 1,
                  skipProtectionCheck: false,
                  trigger: uuidv4(),
                };
              });
              return;
            }
            setSpaceError(SPACE_NOT_FOUND);
          },
        });
      })
      .catch((err: FirebaseError) => {
        console.log("ERR: ", err);

        if (err.code === "functions/permission-denied") {
          setSpaceError(SPACE_PERMISSION_DENIIED);
          return;
        }

        if (err.code === "functions/failed-precondition") {
          if (err.message === GUEST_MODE_DISABLED_ERR_MESSAGE) {
            const details = (err as HttpsError).details as {
              owner: string;
              spaceName: string;
              spaceArtworkUrl: string;
            };
            setSpaceName(details.spaceName);
            setSpaceOwner(details.owner);
            setSpaceArtworkUrl(details.spaceArtworkUrl);
            setSpaceError(SPACE_GUEST_MODE_DISABLED);
            return;
          }

          if (err.message === INBOX_PERMISSION_DENIED_ERR_MESSAGE) {
            const details = (err as HttpsError).details as {
              owner: string;
            };
            setSpaceOwner(details.owner);
            setSpaceError(SPACE_INBOX_PERMISSION_DENIED);
            return;
          }

          setSpaceError(SPACE_SHARING_DISABLED);
          return;
        }

        setSpaceError(SPACE_NOT_FOUND);
      });

    return () => {
      unmounted = true;
      if (unsubscribeSpace) {
        unsubscribeSpace();
      }
    };
  }, [spaceId, retryTrigger]);

  useEffect(() => {
    if (!artwork) {
      setSpaceArtworkUrl(undefined);
      return;
    }

    getDownloadUrl(artwork).then((url) => {
      if (unmountedRef.current) return;
      setSpaceArtworkUrl(url);
    });
  }, [artwork]);

  useEffect(() => {
    const _hasActiveChildEntityWatchers = hasActiveChildEntityWatchers(
      spaceId || space?.id,
    );

    if ((spaceId || space?.id) && !_hasActiveChildEntityWatchers) {
      // Used to fetch child entities and set realtime watchers/listeners
      // for the current parent spaceId.
      manageGlobalChildEntityWatchers({
        spaceId: space?.id,
      }).attach();
    }

    return () => {
      if ((spaceId || space?.id) && !_hasActiveChildEntityWatchers) {
        // Used to unsubscribe from realtime watchers/listeners
        // for the current parent spaceId.
        manageGlobalChildEntityWatchers({
          spaceId: space?.id,
        }).detach();
      }
    };
  }, [space?.id, spaceId]);

  const value = useMemo(
    () => ({
      spaceId,
      space,
      spaceName,
      setSpaceName,
      spaceOwner,
      setSpaceOwner,
      spaceArtworkUrl,
      setSpaceArtworkUrl,
      spaceError,
      spaceLoading: !space && !spaceError,
      itemSortType,
      setItemSortType,
      refreshSubscription: (skipCheck: boolean, count: number) => {
        setRetryTrigger({
          count,
          skipProtectionCheck: skipCheck,
          trigger: uuidv4(),
        });
      },
    }),
    [
      spaceId,
      space,
      spaceName,
      spaceOwner,
      spaceError,
      spaceArtworkUrl,
      itemSortType,
    ],
  );

  return (
    <SpaceContext.Provider value={value}>{children}</SpaceContext.Provider>
  );
};

export const useSpaceContext = () => useContext(SpaceContext);
