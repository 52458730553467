import { firestore } from "./firebase";
import {
  collection,
  onSnapshot,
  Unsubscribe,
  doc,
  FirestoreErrorCode,
} from "firebase/firestore";
import { Space, COLLECTION_ID } from "../core/entities";

export const watchSpace: (props: {
  id: Id;
  onChange: (space: Space) => void;
  onError?: (errCode: FirestoreErrorCode) => void;
}) => Unsubscribe = ({ id, onChange, onError }) => {
  const spaceQuery = doc(collection(firestore, COLLECTION_ID.SPACE), id);

  return onSnapshot(
    spaceQuery,
    (snapshot) => {
      const space = snapshot.data();

      if (!space) {
        console.log(`No Space data found: [${id}]`);
        onError && onError("not-found");
        return;
      }

      onChange(space as Space);
    },
    (error) => {
      console.log(`Could not watch Space: [${id}] - ${error.message}`);
      onError && onError(error.code);
    },
  );
};
