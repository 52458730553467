import classNames from "classnames";
import "./ImageWithFallback.scss";
import React, { useEffect, useRef, useState } from "react";

export const ImageWithFallback = ({
  backgroundStyle,
  src,
  fallback,
  isAnonymous,
}: {
  backgroundStyle?: string;
  src?: string;
  fallback: React.ReactNode | string;
  isAnonymous?: boolean;
}) => {
  const [loadedSrc, setLoadedSrc] = useState<string>();
  const imageRef = useRef<HTMLImageElement>();

  useEffect(() => {
    let unmounted: boolean;
    if (!src) return;

    // Give it a 1s buffer to swap out seamlessly
    setTimeout(() => {
      if (unmounted) return;
      const img = imageRef.current;
      setLoadedSrc(img?.complete ? img?.src : undefined);
    }, 1000);

    return () => {
      unmounted = true;
    };
  }, [src]);

  const fullyLoaded = !!loadedSrc && !!src;

  return (
    <div className="highnote-image-with-fallback" data-loaded={fullyLoaded}>
      {!!src && (
        <img
          ref={imageRef}
          src={src}
          onLoad={() => {
            setLoadedSrc(src);
          }}
          onError={() => {
            setLoadedSrc(undefined);
          }}
        />
      )}
      <div
        className="background-image"
        style={{
          backgroundImage:
            loadedSrc && src
              ? `${
                  backgroundStyle ? backgroundStyle + ", " : ""
                }url(${loadedSrc})`
              : undefined,
          opacity: loadedSrc ? 1 : 0,
        }}
      ></div>
      {!fullyLoaded &&
        (typeof fallback === "string" ? (
          <div
            className={classNames("fallback", { isAnonymous })}
            style={{
              backgroundImage: `${
                backgroundStyle ? backgroundStyle + ", " : ""
              }url(${fallback})`,
            }}
          >
            {isAnonymous && fallback}
          </div>
        ) : (
          fallback
        ))}
    </div>
  );
};
