import "./List.scss";
import React, { forwardRef, useRef } from "react";
import {
  List as MuiList,
  ListProps,
  ListItem as MuiListItem,
  ListItemProps,
  ListItemButton as MuiListItemButton,
  ListItemButtonProps,
  ListSubheader as MuiListSubheader,
  ListSubheaderProps,
} from "@mui/material";
import { useTheme } from "../ThemeProvider";

export const List = (props: ListProps) => {
  const { theme } = useTheme();
  return (
    <MuiList
      {...props}
      className={`highnote-list ${props.className}`}
      data-theme={theme}
    >
      {props.children}
    </MuiList>
  );
};

export const ListItem = (props: ListItemProps) => {
  const { theme } = useTheme();
  return (
    <MuiListItem
      {...props}
      className={`highnote-list-item ${props.className}`}
      data-theme={theme}
    >
      {props.children}
    </MuiListItem>
  );
};

export const ListItemButton = (props: ListItemButtonProps) => {
  const ref = useRef<HTMLDivElement>();
  const { theme } = useTheme();

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (props.disabled) return;

    const path = Array.from(e.nativeEvent.composedPath());

    if (!path.some((el) => el === ref.current)) {
      e.stopPropagation();
      return;
    }

    props.onClick && props.onClick(e);
  };

  return (
    <MuiListItemButton
      {...props}
      ref={ref}
      onClick={handleClick}
      className={`highnote-list-item-button ${props.className}`}
      data-theme={theme}
    >
      {props.children}
    </MuiListItemButton>
  );
};

type ListContainerProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export const ListContainer = forwardRef<HTMLDivElement, ListContainerProps>(
  (props, ref) => {
    const { theme } = useTheme();
    return (
      <div
        {...props}
        ref={ref}
        // eslint-disable-next-line react/prop-types
        className={`highnote-list-container ${props.className}`}
        data-theme={theme}
      >
        {props.children}
      </div>
    );
  },
);
ListContainer.displayName = "DisplayName";

export const ListSubheader = (props: ListSubheaderProps) => {
  const { theme } = useTheme();
  return (
    <MuiListSubheader
      {...props}
      className={`highnote-list-subheader ${props.className}`}
      data-theme={theme}
    />
  );
};
