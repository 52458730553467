import React, { useEffect, useMemo } from "react";
import { useTopLevelSpaces } from "./useEntities/useTopLevelSpaces";
import {
  EntityLibrary,
  EntityLibraryProps,
} from "./EntityLibrary/EntityLibrary";
import { Space } from "@highnote/server/src/core/entities";
import { useChildEntityWatchers } from "App/store/helpers/useChildEntityWatchers";
import { useGlobalSpaces } from "App/store/spaces/useGlobalSpaces";
import { useGlobalTracks } from "App/store/tracks/useGlobalTracks";
import {
  useFormatSpaceToBaseEntityRow,
  useFormatTrackToBaseEntityRow,
} from "./EntityTable/ExpandableSpace/ExpandableSpaceRow";

const CLICK_THRU_TABLE_ROW_VARIANT = "nested-click-thru";

export const ClickThruSpaceRowComponent = ({
  parentSpace,
  tableBaseProps,
}: {
  parentSpace?: Space;
  tableBaseProps: Omit<Omit<EntityLibraryProps, "spaceRows">, "trackRows">;
}) => {
  const { topLevelSpaces } = useTopLevelSpaces();

  const { manageGlobalChildEntityWatchers } = useChildEntityWatchers();
  const { globalSpaces } = useGlobalSpaces();
  const { globalTracks } = useGlobalTracks();
  const { formatSpaceToBaseEntityRow } = useFormatSpaceToBaseEntityRow();
  const { formatTrackToBaseEntityRow } = useFormatTrackToBaseEntityRow();

  const spaceRows = useMemo(() => {
    if (parentSpace) {
      return (globalSpaces.childSpaces.get(parentSpace.id) || []).map(
        (spaceId) => {
          return formatSpaceToBaseEntityRow({
            space: globalSpaces.spaces.get(spaceId),
            options: {
              tableRowVariant: CLICK_THRU_TABLE_ROW_VARIANT,
            },
          });
        },
      );
    }

    return topLevelSpaces.map((topLevelSpace) => {
      return formatSpaceToBaseEntityRow({
        space: topLevelSpace,
        options: {
          tableRowVariant: CLICK_THRU_TABLE_ROW_VARIANT,
        },
      });
    });
  }, [
    parentSpace,
    topLevelSpaces,
    globalSpaces.childSpaces.get(parentSpace?.id),
  ]);

  const trackRows = (globalTracks.childTracks.get(parentSpace?.id) || []).map(
    (globalTrackId) => {
      return formatTrackToBaseEntityRow({
        track: globalTracks.tracks.get(globalTrackId),
        options: {
          hasPlayableTracks: false,
          tableRowVariant: CLICK_THRU_TABLE_ROW_VARIANT,
        },
      });
    },
  );

  useEffect(() => {
    if (parentSpace) {
      // Used to fetch child entities and set realtime watchers/listeners
      // for the current parent spaceId.
      manageGlobalChildEntityWatchers({
        spaceId: parentSpace.id,
        componentId: "click-thru-space-row",
      }).attach();
    }

    return () => {
      if (parentSpace) {
        // Used to unsubscribe from realtime watchers/listeners
        // for the current parent spaceId.
        manageGlobalChildEntityWatchers({
          spaceId: parentSpace.id,
          componentId: "click-thru-space-row",
        }).detach();
      }
    };
  }, [parentSpace?.id]);

  return (
    <EntityLibrary
      {...tableBaseProps}
      spaceRows={spaceRows}
      trackRows={trackRows}
    />
  );
};
