import React, {
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./index.scss";

export const PreviewText = ({
  children,
  watchRefs,
}: {
  children?: string;
  watchRefs?: MutableRefObject<HTMLElement>[];
}) => {
  const fullText = children || "";
  const [isTesting, setTesting] = useState(true);
  const testRef = useRef<HTMLElement>();
  const fullTextRef = useRef<HTMLDivElement>();
  const measureRef = useRef<HTMLDivElement>();
  const textByWidth = useRef<Record<number, string>>({});
  const [renderWidth, setRenderWidth] = useState<number>();

  useEffect(() => {
    let unmounted: boolean;
    let measuring: boolean;

    const onResize = () => {
      if (unmounted) return;
      if (!fullTextRef.current || !measureRef.current) return;
      measuring = true;
      fullTextRef.current.style.display = "block";
      setRenderWidth(measureRef.current.clientWidth);
      fullTextRef.current.style.removeProperty("display");
      measuring = false;
    };

    const resizeObserver = new ResizeObserver(() => {
      window.requestAnimationFrame(() => {
        if (measuring) return;
        onResize();
      });
    });

    onResize();

    (watchRefs || []).forEach((ref) => {
      if (!ref.current) return;
      resizeObserver.observe(ref.current);
    });

    resizeObserver.observe(measureRef.current);

    return () => {
      unmounted = true;
      (watchRefs || []).forEach((ref) => {
        ref.current && resizeObserver.unobserve(ref.current);
      });
      measureRef.current && resizeObserver.unobserve(measureRef.current);
    };
  }, [watchRefs]);

  useEffect(() => {
    let unmounted: boolean;
    textByWidth.current = {};
    if (!testRef.current) return;

    setTesting(true);

    let testLength = fullText.length - 3;
    const measure = () => {
      if (testLength < 4) {
        testLength = 0;
      }

      const midpoint = Math.floor(testLength / 2);
      const chars = fullText.split("");
      const part1 = chars.slice(0, midpoint).join("");
      const part2 = chars.slice(chars.length - midpoint).join("");
      const testText = `${part1}...${part2}`;

      testRef.current.textContent = testText;

      requestAnimationFrame(() => {
        if (unmounted || !testRef.current) return;
        const width = testRef.current.clientWidth;
        textByWidth.current[width] = testText;

        if (testLength < 1) {
          setTesting(false);
          return;
        }

        testLength -= 1;
        measure();
      });
    };

    testRef.current.textContent = fullText;
    requestAnimationFrame(() => {
      if (unmounted || !testRef.current) return;
      textByWidth.current[testRef.current.clientWidth] = fullText;
      measure();
    });

    return () => {
      unmounted = true;
    };
  }, [testRef.current, fullText]);

  const previewText = useMemo(() => {
    if (isTesting) return "";

    let lastWidth: number = 0;
    let longestPossibleText: string;
    Object.entries(textByWidth.current).forEach(([_width, text]) => {
      const width = Number(_width);
      if (width <= renderWidth && width > lastWidth) {
        longestPossibleText = text;
        lastWidth = width;
      }
    });

    return longestPossibleText;
  }, [renderWidth, isTesting]);

  return (
    <div
      className="highnote-preview-text"
      data-cypress-id="highnote-preview-text"
      data-is-testing={isTesting}
      ref={measureRef}
    >
      <span className="preview-text">{previewText}</span>
      <span className="placeholder-text">{fullText}</span>
      <span className="full-text" ref={fullTextRef}>
        {fullText}
      </span>
      <span className="test" ref={testRef}></span>
    </div>
  );
};
