import React from "react";

export enum THEME {
  DARK = "dark",
  LIGHT = "light",
}

const ThemeContext = React.createContext({ theme: THEME.LIGHT });

export const ThemeProvider = ({
  children,
  theme,
}: {
  children: React.ReactNode;
  theme: THEME;
}) => {
  return (
    <ThemeContext.Provider value={{ theme }}>{children}</ThemeContext.Provider>
  );
};

export const useTheme = () => React.useContext(ThemeContext);
