import React from "react";
import {
  FileEntity,
  KNOCK_WORKFLOW,
  Space,
} from "@highnote/server/src/core/entities";
import { PERMISSION } from "@highnote/server/src/core/shared-util";
import { highnote } from "@highnote/server/src/sdk";
import { DialogButtons, DialogSection } from "App/common/Dialog";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { ReactComponent as DownloadSVG } from "App/common/icons/download.svg";
import { ReactComponent as PlusSVG } from "App/common/icons/plus.svg";
import { useAttachmentsContext } from "App/common/useAttachments";
import { useFileDownload } from "App/common/useFileDownload";
import { useAuth } from "App/components/Auth";
import { BUTTON_THEME, Button } from "App/core/Button";
import { Attachments } from "../Attachments";
import { PermissionTooltip } from "../PermissionTooltip";
import { useTrack } from "../useEntities/useTrack";
import { useFileEditor } from "../useFileEditor";
import "./TrackAttachments.scss";
import { LoadingSpinner } from "App/common/icons/LoadingSpinner";

export const TrackAttachments = ({ space }: { space?: Space }) => {
  const { isAllowed } = useAuth();
  const { track, trackArtworkUrl } = useTrack();
  const { attachments, attachFileToContext } = useAttachmentsContext();
  const { downloadFiles, isDownloading } = useFileDownload();
  const { openFileEditor } = useFileEditor();

  const canAddAttachmentsToTrack = isAllowed(
    track?.spaceId
      ? PERMISSION.TO_ADD_TO_TRACK_IN_SPACE
      : PERMISSION.TO_ADD_TO_TRACK,
    {
      space,
      track,
    },
  );

  const canDownload = isAllowed(PERMISSION.TO_DOWNLOAD_TRACK_IN_SPACE, {
    track,
    space,
  });

  const handleDownloadAll = async (filesToDownload: FileEntity[]) => {
    const filteredDownloads = filesToDownload.filter((file) => !file.url);
    await downloadFiles(
      track.id,
      filteredDownloads.map((file) => file.id),
    );
    highnote.notify({
      workflowId: KNOCK_WORKFLOW.TRACK_ATTACHMENT_DOWNLOADED,
      data: {
        files: filteredDownloads.map((file) => ({
          fileId: file.id,
          fileName: file.name,
        })),
        spaceId: space.id,
        spaceName: space.name,
        trackArtworkUrl: trackArtworkUrl,
        trackId: track.id,
        trackName: track.title,
      },
    });
  };

  return (
    <ErrorBoundary name="TrackAttachments">
      <DialogSection>
        <Attachments hideLegacyAddButton showFileDrop />
      </DialogSection>

      <DialogButtons>
        <PermissionTooltip hasPermission={canDownload}>
          <Button
            className="download-all"
            theme={BUTTON_THEME.SECONDARY}
            disabled={isDownloading || attachments.length < 1 || !canDownload}
            onClick={() => handleDownloadAll(attachments)}
          >
            <div className="icon-container">
              {isDownloading ? <LoadingSpinner /> : <DownloadSVG />}
            </div>
            <span>Download All</span>
          </Button>
        </PermissionTooltip>

        <PermissionTooltip hasPermission={canAddAttachmentsToTrack}>
          <Button
            disabled={!canAddAttachmentsToTrack}
            onClick={() => {
              openFileEditor({
                onSave: (fileEntity) => attachFileToContext(fileEntity.id),
              });
            }}
          >
            <PlusSVG />
            <span>Add Attachment</span>
          </Button>
        </PermissionTooltip>
      </DialogButtons>
    </ErrorBoundary>
  );
};
