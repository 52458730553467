export const getTimeFromPosition = ({
  position,
  width,
  duration,
}: {
  position: number;
  width: number;
  duration: number;
}) => {
  const time = (position / width) * duration;
  const sanitizedTime = Math.min(duration, Math.max(time, 0));
  return sanitizedTime;
};
