import "./SpaceChatSidebar.scss";
import React, { useEffect, useMemo, useRef } from "react";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { SidebarHeader, useSidebarContext } from "../Sidebar";
import { useSpaceContext } from "App/common/useSpace";
import { ReactComponent as ArrowDownSVG } from "App/common/icons/arrow-down.svg";
import { ReactComponent as ArrowUpSVG } from "App/common/icons-v2/arrow-up.svg";
import { ReactComponent as DisableChatSVG } from "App/common/icons-v2/disable-chat.svg";
import {
  SpaceCommentsContextProvider,
  useSpaceCommentsContext,
} from "App/common/useSpaceComments";
import {
  CommentContent,
  CommentContentEditActions,
} from "App/common/Comment/CommentContent";
import { CommentContextProvider } from "App/common/useComment";
import { NewCommentForm } from "App/common/Comment/NewCommentForm";
import { CommentMeta } from "App/common/Comment/CommentMeta";
import { UserAvatarGroup } from "App/common/UserAvatar/UserAvatarGroup";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "../../../core/Button";
import { useScrollingComments } from "./useScrollingComments";
import { useAuth } from "App/components/Auth";

import { PERMISSION } from "@highnote/server/src/core/shared-util";
import { PermissionTooltip } from "App/components/PermissionTooltip";
import { usePinnedCommentId } from "App/components/usePinnedCommentId";
import classNames from "classnames";
import moment from "moment";
import { CommentActions } from "App/common/Comment/CommentActions";
import { useIntercomContext } from "App/services/intercom/IntercomContext";
import { SIDEBAR_ID } from "../types";
import { useHistory } from "react-router";
import { useToast } from "App/common/useToast";
import { highnote } from "@highnote/server/src/sdk";

const ChatComments = ({ isOutsideSidebar }: { isOutsideSidebar?: boolean }) => {
  const { isAllowed, isPublicView, user } = useAuth();
  const { space, spaceId } = useSpaceContext();
  const {
    comments,
    fetchOlderComments,
    olderCommentsLoading,
    allCommentsFetched,
  } = useSpaceCommentsContext();
  const scrollableRef = useRef<HTMLDivElement>();
  const highlightedCommentRef = useRef<HTMLDivElement>();
  const {
    showNewerCommentsPrompt,
    showOlderCommentsPrompt,
    oldestUnread,
    scrollToNewest,
  } = useScrollingComments({
    scrollableRef,
    highlightedCommentRef,
    isOutsideSidebar,
  });
  const pinnedCommentId = usePinnedCommentId();
  const canCommentInSpace = isAllowed(PERMISSION.TO_COMMENT_IN_SPACE, {
    space,
  });

  const commentElements = useMemo(() => {
    let lastAuthorId: Id;
    let lastCreatedAt: number;
    let nextAuthorId: Id;

    return comments
      .sort((a, b) => {
        return a.createdAt - b.createdAt;
      })
      .map((comment, index) => {
        const commentFormattedDate = moment(comment.createdAt).format(
          "MMMM DD, YYYY",
        );
        const commentDay = new Date(comment.createdAt).getDay();
        const isNewDay = commentDay !== new Date(lastCreatedAt).getDay();
        nextAuthorId = comments[index + 1]?.createdBy;
        const isCurrentUser = comment.createdBy === user?.id;
        const sameAuthorAsPrevious = comment.createdBy === lastAuthorId;
        const sameAuthorAsNext = comment.createdBy === nextAuthorId;
        const isOldestUnread = oldestUnread && comment.id === oldestUnread.id;
        const isHighlighted = pinnedCommentId === comment.id;
        const ref = isHighlighted ? highlightedCommentRef : undefined;
        lastAuthorId = comment.createdBy;
        lastCreatedAt = comment.createdAt;

        const groupWithNext = sameAuthorAsNext;
        const createdAtStr = moment(comment?.createdAt).format("h:mm A");

        return (
          <CommentContextProvider key={comment.id} id={comment.id}>
            {isNewDay && (
              <aside
                className="chat-comment-group-date"
                aria-label={`Messages from ${commentFormattedDate}`}
              >
                {commentFormattedDate}
              </aside>
            )}
            <div
              className={classNames("chat-comment-container", {
                "chat-comment-current-user": isCurrentUser,
              })}
            >
              <div
                className="chat-comment"
                data-is-highlighted={isHighlighted}
                data-is-deleted={comment.isDeleted}
                data-has-meta={!sameAuthorAsPrevious}
                ref={ref}
              >
                {isOldestUnread && (
                  <div className="unread-marker">
                    <ArrowDownSVG />
                    New Messages
                  </div>
                )}
                <div className="comment-content">
                  <CommentContent hideEditActions />
                </div>
                <aside
                  className="chat-comment-timestamp"
                  aria-label={`Message created at ${createdAtStr}, of ${commentDay}`}
                >
                  {createdAtStr}
                </aside>
              </div>
              <CommentActions />
            </div>
            <CommentContentEditActions
              variant="outside-comment"
              className="chat-comment-edit-actions"
            />
            {!groupWithNext && (
              <CommentMeta
                hideCreatedAtTimestamp
                hideUsername={isCurrentUser}
                type="space-chat"
              />
            )}
          </CommentContextProvider>
        );
      });
  }, [comments, oldestUnread, pinnedCommentId]);

  const commentAuthors = Object.keys(space.rolesV2 || {}).map((id) => ({
    id,
  }));

  const showNewCommentForm =
    !isPublicView(space) ||
    isAllowed(PERMISSION.TO_COMMENT_IN_SPACE, { space });

  return (
    <>
      {!isOutsideSidebar && (
        <section className="context-info">
          <p>You are chatting with everyone in {space.name}</p>
          <UserAvatarGroup users={commentAuthors} />
        </section>
      )}
      <section className="highnote-chat-comments">
        <div className="older-comments-overlay">
          {showOlderCommentsPrompt &&
            !olderCommentsLoading &&
            !allCommentsFetched && (
              <Button
                size={BUTTON_SIZE.XSMALL}
                theme={BUTTON_THEME.SECONDARY}
                onClick={fetchOlderComments}
              >
                See older messages <ArrowUpSVG />
              </Button>
            )}

          {olderCommentsLoading && (
            <Button
              size={BUTTON_SIZE.XSMALL}
              theme={BUTTON_THEME.SECONDARY}
              disabled
            >
              Loading...
            </Button>
          )}
        </div>

        <div className="scrollable-comments-wrapper">
          <div className="scrollable-comments" ref={scrollableRef}>
            {allCommentsFetched && (
              <p className="no-older-comments">No older comments found.</p>
            )}
            {commentElements}
          </div>
          <div className="newer-comments-overlay">
            {showNewerCommentsPrompt && !showOlderCommentsPrompt && (
              <Button
                size={BUTTON_SIZE.XSMALL}
                theme={BUTTON_THEME.SECONDARY}
                onClick={scrollToNewest}
              >
                See newer messages <ArrowDownSVG />
              </Button>
            )}
          </div>
        </div>

        {showNewCommentForm && (
          <PermissionTooltip
            permission={PERMISSION.TO_COMMENT_IN_SPACE}
            space={space}
          >
            <div>
              <NewCommentForm
                spaceId={spaceId}
                saveButtonChildren={<ArrowUpSVG />}
                isDisabled={!canCommentInSpace}
              />
            </div>
          </PermissionTooltip>
        )}
      </section>
    </>
  );
};

export const SpaceChatSidebar = ({
  isOutsideSidebar,
}: {
  isOutsideSidebar?: boolean;
}) => {
  const history = useHistory();
  const { isAllowed } = useAuth();
  const { addErrorMessage } = useToast();
  const { space } = useSpaceContext();
  const { closeSidebar, isSidebarOpen } = useSidebarContext();
  const { setShowLauncher } = useIntercomContext();

  const canManageSpace = isAllowed(PERMISSION.TO_MANAGE_SPACE, { space });

  useEffect(() => {
    if (space && !space.chatEnabled) {
      closeSidebar();
    }
  }, [space?.chatEnabled]);

  useEffect(() => {
    if (isSidebarOpen) {
      setShowLauncher(false);
    } else {
      setShowLauncher(true);
    }
  }, [isSidebarOpen]);

  const disableChat = async () => {
    try {
      await highnote.updateSpace({
        id: space?.id,
        data: {
          chatEnabled: false,
        },
      });
      closeSidebar();
    } catch (e) {
      console.error(e);
      addErrorMessage("Failed to disable chat for the space.");
    }
  };

  if (!space || !space?.chatEnabled) return null;

  return (
    <ErrorBoundary name="SpaceChatSidebar">
      <div
        className={classNames("highnote-space-chat", {
          "chat-inline": isOutsideSidebar,
        })}
      >
        <SidebarHeader
          actions={[
            ...(canManageSpace
              ? [
                  {
                    name: "Disable Chat",
                    icon: <DisableChatSVG />,
                    onClick: disableChat,
                  },
                ]
              : []),
          ]}
          title="Chat"
          subtitle={isOutsideSidebar ? undefined : space.name}
          hideCloseButton={isOutsideSidebar}
          onGoBack={() => {
            const searchParams = new URLSearchParams(location.search);
            searchParams.delete(SIDEBAR_ID.SPACE_CHAT);
            history.replace(location.pathname + "?" + searchParams.toString());
          }}
        />

        <SpaceCommentsContextProvider spaceId={space.id} trackId={null}>
          <ChatComments isOutsideSidebar={isOutsideSidebar} />
        </SpaceCommentsContextProvider>
      </div>
    </ErrorBoundary>
  );
};
