import React from "react";
import { v4 as uuidv4 } from "uuid";
import { AudioFileSelect } from "App/common/FileSelect";
import {
  AudioUploadFileSizeErr,
  UPLOAD_GROUP,
  useFiles,
} from "App/components/useFiles";
import { highnote } from "@highnote/server/src/sdk";
import { stripExtension } from "@highnote/server/src/core/shared-util";
import { useToast } from "App/common/useToast";
import {
  LIMIT_TYPE,
  usePlanLimitsContext,
} from "App/common/PlanLimits/usePlanLimits";
import { MAX_TRACKS_ERROR } from "@highnote/server/src/core/shared-util";

export const UploadTrackButton = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { addErrorMessage } = useToast();
  const { uploadFiles, getUploadCache, removeUpload } = useFiles();
  const { showPlanLimitsDialog } = usePlanLimitsContext();

  const onFileSelect = async (files: File[]) => {
    if (!files) {
      return;
    }
    const uploadPayloads = files.map((file) => {
      const cache = getUploadCache(UPLOAD_GROUP.TRACKS_BY_SPACE, null);
      return {
        file,
        cache,
      };
    });
    await uploadFiles({
      payloads: uploadPayloads,
      onSuccess: async (_, fileEntity) => {
        try {
          const trackId = uuidv4();
          await highnote.createTrack({
            id: trackId,
            data: {
              title: stripExtension(fileEntity.fileName),
              versionFilesV2: [fileEntity.id],
            },
          });
          removeUpload(fileEntity.id);
        } catch (e) {
          if (e.message === MAX_TRACKS_ERROR) {
            showPlanLimitsDialog(LIMIT_TYPE.TRACKS);
            return;
          }
          throw e;
        }
      },
      onError: (e) => {
        if (e instanceof AudioUploadFileSizeErr) {
          addErrorMessage(e.message, { title: e.title });
          return;
        }
        throw e;
      },
    });
  };

  return (
    <AudioFileSelect onSelect={onFileSelect} theme="dark" allowMultiple>
      {children}
    </AudioFileSelect>
  );
};
