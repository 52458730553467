import { v4 as uuidv4 } from "uuid";
import { ARCHIVABLE_ENTITY_TYPES } from "@highnote/server/src/core/entities";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useToast } from "./useToast";
import { useSpaceContext } from "./useSpace";
import { getAuthId, useAuth } from "App/components/Auth";
import { JOIN_ENTITY_TRIGGER } from "App/components/Auth/util";
import { checkIsUserAnonymous } from "@highnote/server/src/core/shared-util";
import { highnote } from "@highnote/server/src/sdk";
import { Dialog, DialogButtons, DialogSection } from "./Dialog";
import { BUTTON_THEME, Button } from "App/core/Button";
import { JoinEntityDialog } from "../components/Auth/JoinEntityDialog";

const initiateDownload = (link: string) => {
  const tempLink = document.createElement("a");
  tempLink.href = link;
  tempLink.setAttribute("download", link.split("/").pop());
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
};

export const useFileDownload = () => {
  const [renderDownloadOptionDialog, setRenderDownloadOptionDialog] =
    useState<boolean>(false);
  const [renderJoinEntityDialog, setRenderJoinEntityDialog] =
    useState<boolean>(false);
  const { user } = useAuth();
  const { addErrorMessage, addSuccessMessage } = useToast();
  const { joinEntity } = useAuth();
  const { space } = useSpaceContext();
  const [isDownloading, setDownloading] = useState<boolean>(false);
  const unmountedRef = useRef(false);

  useEffect(
    () => () => {
      unmountedRef.current = true;
    },
    [],
  );

  const downloadEntity = async ({
    entityId,
    entityType,
    fileIds = [],
  }: {
    entityId: string;
    entityType:
      | ARCHIVABLE_ENTITY_TYPES.SPACE
      | ARCHIVABLE_ENTITY_TYPES.TRACK
      | ARCHIVABLE_ENTITY_TYPES.TRACK_FILES;
    fileIds?: string[];
  }) => {
    setDownloading(true);
    const waitFor = 10000;
    const deadline = Date.now() + waitFor;
    let timeoutId: NodeJS.Timeout;
    try {
      const previousUserId = getAuthId();
      timeoutId = setTimeout(() => {
        setDownloading(false);
        if (user) {
          addSuccessMessage(
            "Large files take longer to process. You'll get a notification when your download link is ready.",
            {
              isPersistent: true,
              title: "We're processing this large download",
            },
          );
          return;
        }
        setRenderDownloadOptionDialog(true);
      }, waitFor);

      const { downloadLink, requestId } = await highnote.generateDownloadLink(
        {
          requestId: uuidv4(),
          deadline,
          entityType,
          entityId,
          fileIds,
        },
        // we wait for up to 1 hour since that is the timeout limit of cloud function that
        // we use for generating download archives.
        { timeout: 3600000 },
      );

      joinEntity({
        entity: space,
        entityType: "Space",
        trigger: JOIN_ENTITY_TRIGGER.DOWNLOAD,
      });

      // if anonymous user gets a download link eventually, close the dialog
      setRenderDownloadOptionDialog(false);

      const nowAuthId = getAuthId();
      const nowAnonymous = checkIsUserAnonymous(nowAuthId);
      // acknowledge the download link only if past the deadline so that
      // notifications are not sent to the user.
      if (nowAnonymous && Date.now() > deadline) {
        highnote.acknowledgeDownloadRequest({
          requestId,
        });
      }

      // open the download link only if your auth status has not changed
      // 1) logged-in -> logged-out: you'll get a notification
      // 2) logged-out -> logged-in: you'll also get a notification
      if (previousUserId === nowAuthId) {
        initiateDownload(downloadLink);
      }
    } catch (e) {
      if (e.code === "functions/failed-precondition") {
        addErrorMessage(e.message, { title: "Download limit exceeded" });
        return;
      }

      const entityKey =
        entityType === ARCHIVABLE_ENTITY_TYPES.SPACE ? "space" : "track";
      console.error(`Failed to initiate ${entityKey} download: ${e}`);
      addErrorMessage(`Failed to download the ${entityKey}: ${e.message}`);
    } finally {
      setDownloading(false);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    }
  };

  const downloadSpace = async (spaceId: string) => {
    await downloadEntity({
      entityId: spaceId,
      entityType: ARCHIVABLE_ENTITY_TYPES.SPACE,
    });
  };

  const downloadTrack = async (trackId: string) => {
    await downloadEntity({
      entityId: trackId,
      entityType: ARCHIVABLE_ENTITY_TYPES.TRACK,
    });
  };

  const downloadFiles = async (trackId: string, fileIds: string[]) => {
    await downloadEntity({
      entityId: trackId,
      fileIds,
      entityType: ARCHIVABLE_ENTITY_TYPES.TRACK_FILES,
    });
  };

  const downloadFile = async (fileId: string) => {
    try {
      setDownloading(true);
      const { downloadLink } = await highnote.generateDownloadLink({
        entityId: fileId,
        entityType: ARCHIVABLE_ENTITY_TYPES.FILE,
      });
      initiateDownload(downloadLink);
    } catch (e) {
      addErrorMessage(`Failed to download the file ${fileId}: ${e.message}`);
      setDownloading(false);
      throw e;
    } finally {
      setDownloading(false);
    }
  };

  const renderDownloadOption = useMemo(() => {
    if (user || !renderDownloadOptionDialog) {
      return null;
    }
    if (renderJoinEntityDialog) {
      return (
        <JoinEntityDialog
          open
          title="Get Download Alert"
          config={{
            entity: space,
            entityType: "Space",
            trigger: JOIN_ENTITY_TRIGGER.DOWNLOAD,
          }}
          onDismiss={() => {
            setRenderJoinEntityDialog(false);
            setRenderDownloadOptionDialog(false);
          }}
        />
      );
    }
    return (
      <Dialog
        title="Preparing Download"
        open
        onClose={() => {
          setRenderDownloadOptionDialog(false);
          setRenderJoinEntityDialog(false);
        }}
      >
        <DialogSection>
          <p>
            We&apos;re preparing your download link. This may take a while. Wait
            here, or get a notification when your link is ready (sign up
            required)
          </p>
        </DialogSection>
        <DialogButtons>
          <Button
            theme={BUTTON_THEME.SECONDARY}
            onClick={() => {
              setRenderDownloadOptionDialog(false);
            }}
          >
            Keep Waiting
          </Button>
          <Button
            theme={BUTTON_THEME.CTA}
            onClick={() => {
              setRenderJoinEntityDialog(true);
            }}
          >
            Notify Me
          </Button>
        </DialogButtons>
      </Dialog>
    );
  }, [renderDownloadOptionDialog, renderJoinEntityDialog, space]);

  return {
    renderDownloadOption,
    downloadSpace,
    downloadTrack,
    downloadFile,
    downloadFiles,
    isDownloading,
    endDownload: () => {
      setDownloading(false);
    },
    startDownload: () => {
      setDownloading(true);
    },
  };
};
