import React, { useCallback, useState } from "react";
import {
  ArrayUnion,
  FileEntity,
  KNOCK_WORKFLOW,
  SelectionType,
  Space,
  SUBSCRIPTION_TIER,
  Track,
} from "@highnote/server/src/core/entities";
import { MAX_TRACKS_ERROR } from "@highnote/server/src/core/shared-util";
import { highnote } from "@highnote/server/src/sdk";
import { ReactComponent as CheckCircleSVG } from "App/common/icons-v2/check-circle.svg";
import {
  LIMIT_TYPE,
  usePlanLimitsContext,
} from "App/common/PlanLimits/usePlanLimits";
import { useConfirmation } from "App/common/useConfirmation";
import { useFileDownload } from "App/common/useFileDownload";
import { useHighnote } from "App/common/useHighnote";
import { useToast } from "App/common/useToast";
import { useAuth } from "App/components/Auth";
import { JOIN_ENTITY_TRIGGER } from "App/components/Auth/util";
import {
  AudioUploadFileSizeErr,
  UPLOAD_GROUP,
  useFiles,
} from "App/components/useFiles";
import { useTrackEditor } from "App/components/useTrackEditor";

export const useVersionActions = () => {
  const { joinEntity } = useAuth();
  const { addErrorMessage, toasted } = useToast();
  const { confirm, renderConfirmation } = useConfirmation();
  const { downloadFiles, isDownloading } = useFileDownload();
  const { showPlanLimitsDialog } = usePlanLimitsContext();
  const { getUploadCache, uploadFiles } = useFiles();
  const { setSelectedVersionToSwap } = useTrackEditor();
  const [isUploading, setIsUploading] = useState(false);
  const {
    swapPinnedVersions: swapPinnedVersionsHighnote,
    unpinTrackVersion,
    deleteOrRemoveSelections,
    pinTrackVersion,
  } = useHighnote();
  const [isSwappingVersions, setIsSwappingVersions] = useState(false);
  const [isPinningVersion, setIsPinningVersion] = useState(false);

  const downloadAllVersions = async (
    trackId: string,
    allVersions: FileEntity[],
    options?: {
      trackArtworkUrl?: string;
      spaceId?: string;
      spaceName?: string;
      trackName?: string;
    },
  ) => {
    if (!trackId) return;
    const versionsToDownload = allVersions.filter((version) =>
      Boolean(version.storagePath),
    );
    await downloadFiles(
      trackId,
      versionsToDownload.map((version) => version.id),
    );

    highnote.notify({
      workflowId: KNOCK_WORKFLOW.TRACK_VERSION_DOWNLOADED,
      data: {
        files: allVersions.map((version, i) => ({
          fileId: version.id,
          fileName: version.name,
          versionNumber: i + 1,
        })),
        spaceId: options.spaceId || "",
        spaceName: options.spaceName || "",
        trackId: trackId,
        trackName: options.trackName || "",
        trackArtworkUrl: options.trackArtworkUrl || "",
      },
    });
  };

  const deleteSelectedVersions = useCallback(
    async ({
      track,
      versionsToRemove,
      localTrackVersions,
      localPinnedTrackVersions,
      setLocalTrackVersions,
      setLocalPinnedTrackVersions,
    }: {
      track: Track;
      versionsToRemove: SelectionType[];
      localTrackVersions: FileEntity[];
      localPinnedTrackVersions: string[];
      setLocalTrackVersions: React.Dispatch<React.SetStateAction<FileEntity[]>>;
      setLocalPinnedTrackVersions: React.Dispatch<
        React.SetStateAction<string[]>
      >;
    }) => {
      if (!track) return;
      const versionLabel =
        versionsToRemove.length === 1 ? "this version" : "these versions";

      await confirm({
        title: "Remove from Track",
        body: (
          <p>
            <strong>
              Are you sure you want to remove {versionLabel} from this track?
            </strong>
            <br />
            <br />
            All comments assigned to this version will be lost. This action
            cannot be undone.
          </p>
        ),
      });

      const initialLocalTrackVersions = [...localTrackVersions];
      const initialLocalPinnedVersions = [...localPinnedTrackVersions];

      const pinnedVersionsToRemove = versionsToRemove.reduce<string[]>(
        (acc, version) => {
          if (track.pinnedVersionFiles.includes(version.entityId)) {
            acc.push(version.entityId);
          }
          return acc;
        },
        [],
      );

      // Update local pinned versions
      if (pinnedVersionsToRemove.length > 0) {
        setLocalPinnedTrackVersions((prev) =>
          prev.filter((version) => !pinnedVersionsToRemove.includes(version)),
        );
      }

      // Update local track versions
      setLocalTrackVersions((prev) =>
        prev.filter(
          (version) =>
            !versionsToRemove.some((item) => item.entityId === version.id),
        ),
      );

      try {
        await deleteOrRemoveSelections({
          entities: versionsToRemove,
          toastMessages: {
            createMessage: "Versions being removed from track...",
            successMessage: "Versions removed from track",
            errorMessage: "Could not remove versions from track",
          },
        });

        if (pinnedVersionsToRemove.length > 0) {
          await unpinTrackVersion({
            track,
            versionIds: pinnedVersionsToRemove,
          });
        }
      } catch (error) {
        console.error(error);
        setLocalTrackVersions(initialLocalTrackVersions);
        setLocalPinnedTrackVersions(initialLocalPinnedVersions);
      }
    },
    [],
  );

  const addVersionToTrack = async (
    track: Track,
    space: Space,
    file: FileEntity,
    pinOnSave?: boolean,
  ) => {
    const updateData: Partial<Track> = {
      versionFilesV2: new ArrayUnion([file.id]) as unknown as Id[],
    };

    if (pinOnSave) {
      updateData.pinnedVersionFiles = new ArrayUnion([
        file.id,
      ]) as unknown as Id[];
    }

    try {
      await highnote.updateTrack({
        id: track.id,
        data: updateData,
      });

      joinEntity({
        entity: space,
        entityType: "Space",
        trigger: JOIN_ENTITY_TRIGGER.UPLOAD,
      });
    } catch (e) {
      if (e.message === MAX_TRACKS_ERROR) {
        showPlanLimitsDialog(LIMIT_TYPE.TRACKS);
        return;
      }
      throw e;
    }
  };

  const uploadVersions = async ({
    track,
    space,
    files,
  }: {
    track: Track;
    space: Space;
    files: File[];
  }) => {
    if (!files || !track) {
      return;
    }

    const uploadCache = getUploadCache(
      UPLOAD_GROUP.TRACK_VERSIONS_UNPINNED,
      track.id,
    );

    setIsUploading(true);
    try {
      const uploadPayloads = files.map((file) => {
        return {
          file,
          cache: uploadCache,
        };
      });
      const label = uploadPayloads.length === 1 ? "Version" : "Versions";

      // Note: The upload cache already handles optimistic UI updates for uploads
      await toasted({
        promise: uploadFiles({
          payloads: uploadPayloads,
          onSuccess: async (_, fileEntity) => {
            await addVersionToTrack(track, space, fileEntity);
          },
          onError: (e) => {
            if (e instanceof AudioUploadFileSizeErr) {
              addErrorMessage(e.message, { title: e.title });
              return;
            }
            throw e;
          },
        }),
        createMessage: `Uploading ${uploadPayloads.length} ${label} to track...`,
        successMessage: (
          <>
            <span className="toasted-icon">
              <CheckCircleSVG />
            </span>
            {uploadPayloads.length} {label}{" "}
            {uploadPayloads.length === 1 ? "has" : "have"} been added.
          </>
        ),
      });
    } catch (e) {
      console.log(e);
    } finally {
      setIsUploading(false);
    }
  };

  const swapPinnedVersions = async ({
    track,
    currentVersionId,
    nextVersion,
    localPinnedTrackVersions,
    setLocalPinnedTrackVersions,
  }: {
    track: Track;
    currentVersionId: string;
    nextVersion: FileEntity;
    localPinnedTrackVersions: string[];
    setLocalPinnedTrackVersions: React.Dispatch<React.SetStateAction<string[]>>;
  }) => {
    setIsSwappingVersions(true);

    // Store original state for rollback
    const originalPinnedVersions = [...localPinnedTrackVersions];

    // Optimistically update UI
    setLocalPinnedTrackVersions((prev) => {
      const updated = [...prev];
      const index = updated.indexOf(currentVersionId);
      if (index !== -1) {
        updated[index] = nextVersion.id;
      }
      return updated;
    });

    try {
      await swapPinnedVersionsHighnote({
        track,
        currentVersionId,
        nextVersionId: nextVersion.id,
      });

      // We need to update the selected version to swap
      // in case we want to swap again in the future without breaking the state
      // This is currently irrelevant though, since we close the dialog after swapping
      // TODO(Swap): Confirm if this is the desired behavior
      setSelectedVersionToSwap(nextVersion);
    } catch (error) {
      console.error("Error swapping versions:", error);
      // Restore original state on error
      setLocalPinnedTrackVersions(originalPinnedVersions);
    } finally {
      setIsSwappingVersions(false);
    }
  };

  const pinVersion = async ({
    track,
    version,
    subscriptionTier,
    localPinnedTrackVersions,
    setLocalPinnedTrackVersions,
  }: {
    track: Track;
    version: FileEntity;
    subscriptionTier: SUBSCRIPTION_TIER;
    localPinnedTrackVersions: string[];
    setLocalPinnedTrackVersions: React.Dispatch<React.SetStateAction<string[]>>;
  }) => {
    setIsPinningVersion(true);

    // Store original state for rollback
    const originalPinnedVersions = [...localPinnedTrackVersions];

    // Optimistically update UI
    setLocalPinnedTrackVersions((prev) => {
      if (!prev.includes(version.id)) {
        return [...prev, version.id];
      }
      return prev;
    });

    try {
      await pinTrackVersion({
        track,
        versionId: version.id,
        subscriptionTier,
      });
    } catch (error) {
      console.error("Error pinning version:", error);
      // Restore original state on error
      setLocalPinnedTrackVersions(originalPinnedVersions);
    } finally {
      setIsPinningVersion(false);
    }
  };

  return {
    isDownloadingVersions: isDownloading,
    isSwappingVersions,
    isUploading,
    isPinningVersion,
    renderConfirmation,
    downloadAllVersions,
    deleteSelectedVersions,
    uploadVersions,
    swapPinnedVersions,
    pinVersion,
  };
};
