import React from "react";
import {
  COLLECTION_ID,
  Comment,
  FileBlockV2,
  FileEntity,
  FileReferences,
  PollBlockV2,
  Space,
  Track,
} from "@highnote/server/src/core/entities";
import { Link } from "react-router-dom";

const TrackFileReference = ({
  file,
  track,
}: {
  file: FileEntity;
  track: Track;
}) => {
  let role: string;
  if (file.id === track.artworkFile) {
    role = "artwork";
  }

  if ((track.files || []).includes(file.id)) {
    role = "an attachment";
  }

  if ((track.versionFilesV2 || []).includes(file.id)) {
    role = "a version";
  }

  const url = track.spaceId
    ? `/space/${track.spaceId}/${track.id}`
    : "/library";

  return (
    <span>
      {role ? `as ${role} ` : ""}in the Track{" "}
      <Link style={{ textDecoration: "underline" }} to={url}>
        {track.title}.
      </Link>
    </span>
  );
};

const SpaceFileReference = ({
  file,
  space,
}: {
  file: FileEntity;
  space: Space;
}) => {
  let role: string;
  if (file.id === space.artworkFile) {
    role = "artwork";
  }

  if ((space.files || []).includes(file.id)) {
    role = "an attachment";
  }

  return (
    <span>
      {role ? `as ${role} ` : ""}in the Space{" "}
      <Link style={{ textDecoration: "underline" }} to={`/space/${space.id}`}>
        {space.name}.
      </Link>
    </span>
  );
};

const CommentFileReference = ({
  file,
  comment,
}: {
  file: FileEntity;
  comment: Comment;
}) => {
  let role: string;

  if (comment.blocks) {
    const fileBlock = comment.blocks[0] as FileBlockV2;
    if (file.id === fileBlock?.file) {
      role = "a file reference";
    }

    const pollBlock = comment.blocks[0] as PollBlockV2;
    const pollOptions = pollBlock?.options || [];
    const pollFiles = pollOptions.map((o) => (o as FileBlockV2)?.file);
    if (pollFiles.includes(file.id)) {
      role = "a poll option";
    }
  }

  let url: string;

  if (comment.spaceId && comment.trackId) {
    url = `/space/${comment.spaceId}/${comment.trackId}?commentId=${comment.id}`;
  } else if (comment.spaceId) {
    url = `/space/${comment.spaceId}/?space-chat&commentId=${comment.id}`;
  }

  return (
    <span>
      {role ? `as ${role} ` : ""}in a{" "}
      {url ? (
        <Link style={{ textDecoration: "underline" }} to={url}>
          Space Comment.
        </Link>
      ) : (
        "Comment."
      )}
    </span>
  );
};

const FileReferences = ({
  file,
  references,
}: {
  file: FileEntity;
  references: FileReferences;
}) => {
  const total =
    references[COLLECTION_ID.TRACK].length +
    references[COLLECTION_ID.SPACE].length +
    references[COLLECTION_ID.COMMENT].length;

  if (total < 1) {
    return (
      <div>This File is not currently being used in any Highnote entities.</div>
    );
  }

  return (
    <div>
      This file is currently being used{" "}
      {references[COLLECTION_ID.TRACK].map((track) => (
        <TrackFileReference file={file} key={track.id} track={track} />
      ))}
      {references[COLLECTION_ID.SPACE].map((space) => (
        <SpaceFileReference file={file} key={space.id} space={space} />
      ))}
      {references[COLLECTION_ID.COMMENT].map((comment) => (
        <CommentFileReference file={file} key={comment.id} comment={comment} />
      ))}
    </div>
  );
};

export { FileReferences };
