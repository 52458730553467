import { SUBSCRIPTION_TIER, UserEntity } from "./entities";
import { getTrackVersionsLimitByTier } from "./shared-util";

export enum APP_FEATURES {
  BLOCKING_TRACKS_DIALOG = "blockingTracksDialog",
  PRIVATE_INBOX = "privateInbox",
}

export const AppFeaturesStatus: Record<APP_FEATURES, boolean> = {
  [APP_FEATURES.BLOCKING_TRACKS_DIALOG]: false,
  [APP_FEATURES.PRIVATE_INBOX]: false,
};

export const baseUserFeatureGenerators: {
  [key in APP_FEATURES]?: (user: UserEntity) => boolean;
} = {
  [APP_FEATURES.BLOCKING_TRACKS_DIALOG]: (user: UserEntity) => {
    if (
      !user.trackVersionsUsed ||
      user.trackVersionsUsed < getTrackVersionsLimitByTier()
    ) {
      return false;
    }
    if (
      [
        SUBSCRIPTION_TIER.INDIE,
        SUBSCRIPTION_TIER.PRO,
        SUBSCRIPTION_TIER.STUDIO,
      ].includes(user.subscriptionTier)
    ) {
      return false;
    }
    return true;
  },
};
