import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { orderBy, where } from "firebase/firestore";

import { DropboxSyncJob, JOB_STATUS } from "@highnote/server/src/core/entities";
import {
  DropboxJobsProvider,
  useDropboxJobs,
} from "App/components/useEntities";
import { useAuth } from "App/components/Auth";
import {
  DropboxManagerBase,
  MAX_TRACKS_ERROR,
  isDropboxJobStale,
} from "@highnote/server/src/core/shared-util";
import { highnote } from "@highnote/server/src/sdk";
import {
  LIMIT_TYPE,
  usePlanLimitsContext,
} from "App/common/PlanLimits/usePlanLimits";

type DropboxJobsContextValue = {
  dropboxJobs: DropboxSyncJob[];
  dropboxJobsLoading: boolean;
  totalLimitDropboxJobs: number;
  loadMoreDropboxJobs: () => void;
  resetPromptStatus: () => void;
};

const DropboxJobsContext = createContext<DropboxJobsContextValue>({
  dropboxJobs: [],
  dropboxJobsLoading: true,
  totalLimitDropboxJobs: 0,
  loadMoreDropboxJobs: () => {},
  resetPromptStatus: () => {},
});

const _DropboxJobsProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    entities: dropboxJobs,
    loading: dropboxJobsLoading,
    loadMore: loadMoreDropboxJobs,
    totalLimit: totalLimitDropboxJobs,
  } = useDropboxJobs();
  const { showPlanLimitsDialog } = usePlanLimitsContext();
  const [currentJob, setCurrentJob] = useState(dropboxJobs[0]);
  const [planLimitPrompted, setPlanLimitPrompted] = useState(false);
  const [trackLimitPrompted, setTrackLimitPrompted] = useState(false);

  useEffect(() => {
    const nowCurrentJob = dropboxJobs[0];
    if (!nowCurrentJob) {
      return;
    }
    if (!currentJob) {
      setCurrentJob(nowCurrentJob);
      return;
    }
    if (currentJob.processingError !== nowCurrentJob.processingError) {
      if (
        nowCurrentJob.processingError?.includes(
          DropboxManagerBase.storageLimitExceedsErrorMsg,
        ) &&
        !planLimitPrompted
      ) {
        showPlanLimitsDialog(LIMIT_TYPE.STORAGE);
        setPlanLimitPrompted(true);
        return;
      }
      if (
        nowCurrentJob.processingError?.includes(MAX_TRACKS_ERROR) &&
        !trackLimitPrompted
      ) {
        showPlanLimitsDialog(LIMIT_TYPE.TRACKS);
        setTrackLimitPrompted(true);
        return;
      }
    }
    setCurrentJob(nowCurrentJob);
  }, [currentJob, dropboxJobs[0], planLimitPrompted]);

  useEffect(() => {
    if (!dropboxJobs[0]) return;
    const interval = setInterval(() => {
      const isStale = isDropboxJobStale(dropboxJobs[0], [
        JOB_STATUS.IN_PROGRESS,
        JOB_STATUS.PENDING,
      ]);
      if (isStale) {
        // if the first job in the queue is stale, trigger the handler manually
        // to ensure that the stale job is picked up and processed.
        highnote.handleDropboxJob({ userId: dropboxJobs[0].createdBy });
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [dropboxJobs[0]]);

  const resetPromptStatus = () => {
    setPlanLimitPrompted(false);
    setTrackLimitPrompted(false);
  };

  const value = useMemo(() => {
    return {
      dropboxJobs,
      dropboxJobsLoading,
      loadMoreDropboxJobs,
      totalLimitDropboxJobs,
      resetPromptStatus,
    };
  }, [
    dropboxJobs,
    dropboxJobsLoading,
    loadMoreDropboxJobs,
    totalLimitDropboxJobs,
    resetPromptStatus,
  ]);

  return (
    <DropboxJobsContext.Provider value={value}>
      {children}
    </DropboxJobsContext.Provider>
  );
};

export const DropboxJobsContextProvider = ({
  children,
  spaceId,
  statuses = [],
}: {
  children: React.ReactNode;
  spaceId: string;
  statuses: JOB_STATUS[];
}) => {
  const { user } = useAuth();
  const constraints = useMemo(() => {
    if (!user?.id || !spaceId) return [];
    return [
      where("spaceId", "==", spaceId),
      where("status", "in", statuses),
      where("createdBy", "==", user.id),
      orderBy("updatedAt", "desc"),
    ];
  }, [user?.id, spaceId, statuses]);

  if (!user?.id || !spaceId) {
    return <>{children}</>;
  }

  return (
    <DropboxJobsProvider constraints={constraints} limit={100}>
      <_DropboxJobsProvider>{children}</_DropboxJobsProvider>
    </DropboxJobsProvider>
  );
};

export const useDropboxJobsContext = () => useContext(DropboxJobsContext);
