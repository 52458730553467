import "./index.scss";
import React from "react";
import { Dialog } from "App/common/Dialog";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { SpaceDetails } from "./SpaceDetails";
import { SpaceNotifications } from "./SpaceNotifications";
import { TabbedView } from "App/core/TabbedView";
import { useSpaceEditor } from "../useSpaceEditor";
import { useAuth } from "../Auth";
import { SpaceSettings } from "./SpaceSettings";

export enum SPACE_EDITOR_TAB {
  DETAILS = "details",
  NOTIFICATIONS = "notifications",
  SETTINGS = "settings",
}

export const SpaceEditor = ({
  activeTabId = SPACE_EDITOR_TAB.DETAILS,
  setActiveTabId,
}: {
  activeTabId: SPACE_EDITOR_TAB;
  setActiveTabId: (tabId: SPACE_EDITOR_TAB) => void;
}) => {
  const { user } = useAuth();
  const { closeSpaceEditor } = useSpaceEditor();
  const spaceEditorTabs = [
    {
      id: SPACE_EDITOR_TAB.DETAILS,
      name: "Details",
      view: <SpaceDetails />,
      onClick: () => setActiveTabId(SPACE_EDITOR_TAB.DETAILS),
      keepOnDismount: true,
    },
    user
      ? {
          id: SPACE_EDITOR_TAB.NOTIFICATIONS,
          name: "Notifications",
          view: <SpaceNotifications />,
          onClick: () => setActiveTabId(SPACE_EDITOR_TAB.NOTIFICATIONS),
        }
      : undefined,
    user
      ? {
          id: SPACE_EDITOR_TAB.SETTINGS,
          name: "Settings",
          view: <SpaceSettings />,
          onClick: () => setActiveTabId(SPACE_EDITOR_TAB.SETTINGS),
        }
      : undefined,
  ].filter(Boolean);

  return (
    <ErrorBoundary name="SpaceEditor">
      <Dialog
        onClose={closeSpaceEditor}
        open
        title="Space Details"
        className="highnote-space-editor"
      >
        <TabbedView activeTabId={activeTabId} tabs={spaceEditorTabs} />
      </Dialog>
    </ErrorBoundary>
  );
};
