import "./PreviewIcon.scss";
import React from "react";
import { ImageWithFallback } from "./ImageWithFallback";
import { useTheme } from "./ThemeProvider";

export enum PREVIEW_ICON_SIZE {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  XLARGE = "xlarge",
  JUMBO = "jumbo",
}

export const PreviewIcon = ({
  src,
  size,
  fallback,
  className,
}: {
  src?: string;
  size?: PREVIEW_ICON_SIZE;
  fallback: React.ReactNode | string;
  className?: string;
}) => {
  const { theme } = useTheme();

  return (
    <div
      className={`highnote-preview-icon ${className || ""}`}
      data-theme={theme}
      data-size={size || PREVIEW_ICON_SIZE.MEDIUM}
    >
      <ImageWithFallback src={src} fallback={fallback} />
    </div>
  );
};
