import React, { useMemo } from "react";
import { FileEntity } from "@highnote/server/src/core/entities";
import { PERMISSION } from "@highnote/server/src/core/shared-util";
import { Action, ActionInfo, Actions } from "App/common/Actions";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { ReactComponent as DownloadSVG } from "App/common/icons/download.svg";
import { ReactComponent as EditSVG } from "App/common/icons/edit.svg";
import { ReactComponent as InfoSVG } from "App/common/icons/info.svg";
import { ReactComponent as RemoveSVG } from "App/common/icons/remove.svg";
import { useAuth } from "App/components/Auth";
import { useFileEditor } from "App/components/useFileEditor";
import { useAttachmentsContext } from "./useAttachments";
import { useSpaceContext } from "./useSpace";
import { useEntityDownload } from "./useEntityDownload";
import { ConnectedUserName } from "./UserAvatar/UserAvatar";

enum DEFAULT_ATTACHMENT_ACTION {
  EDIT = "edit",
  REMOVE = "remove",
  DOWNLOAD = "download",
  INFO = "info",
  // Not an action, but a way to include a separator in the actions menus
  SEPARATOR = "separator",
}

const AttachmentActions = ({
  attachment,
  actions,
}: {
  attachment: FileEntity;
  actions: (DEFAULT_ATTACHMENT_ACTION | Action)[];
}) => {
  const { openFileEditor } = useFileEditor();
  const { refetchAttachments, detachFileFromContext } = useAttachmentsContext();
  const { downloadAttachmentFile } = useEntityDownload();

  const removeAttachment = () => {
    detachFileFromContext(attachment.id);
  };

  const editAttachment = () =>
    openFileEditor({ file: attachment, onSave: refetchAttachments });

  if (!attachment) return null;

  return (
    <ErrorBoundary name="AttachmentActions">
      <Actions
        actions={actions.map((action) => {
          if (action === DEFAULT_ATTACHMENT_ACTION.SEPARATOR) {
            return { name: "SEPARATOR" };
          }

          if (action === DEFAULT_ATTACHMENT_ACTION.EDIT) {
            return {
              name: "Edit",
              icon: <EditSVG />,
              onClick: editAttachment,
            };
          }

          if (action === DEFAULT_ATTACHMENT_ACTION.REMOVE) {
            return {
              name: "Remove",
              icon: <RemoveSVG />,
              onClick: removeAttachment,
            };
          }

          if (action === DEFAULT_ATTACHMENT_ACTION.DOWNLOAD) {
            return {
              name: "Download",
              icon: <DownloadSVG />,
              onClick: () => downloadAttachmentFile(attachment),
            };
          }

          if (action === DEFAULT_ATTACHMENT_ACTION.INFO) {
            return {
              Component: () => (
                <ActionInfo>
                  <InfoSVG /> Added by&nbsp;
                  <ConnectedUserName userId={attachment.createdBy} />
                </ActionInfo>
              ),
              disabled: true,
            };
          }

          return action;
        })}
      />
    </ErrorBoundary>
  );
};

export const DefaultAttachmentActions = ({
  attachment,
}: {
  attachment: FileEntity;
}) => {
  const { isAllowed } = useAuth();
  const { space } = useSpaceContext();

  const actions = useMemo(() => {
    const canEdit = isAllowed(PERMISSION.TO_EDIT_FILE, { file: attachment });
    const canManageParent = isAllowed(PERMISSION.TO_MANAGE_SPACE, { space });
    const canDownloadAttachment = isAllowed(
      PERMISSION.TO_DOWNLOAD_FILE_IN_SPACE,
      { space },
    );

    // Defining action arrays based on permissions
    const downloadAction =
      canDownloadAttachment && attachment.storagePath
        ? [DEFAULT_ATTACHMENT_ACTION.DOWNLOAD]
        : [];

    const editAction = canEdit ? [DEFAULT_ATTACHMENT_ACTION.EDIT] : [];

    const removeAction =
      canEdit || canManageParent ? [DEFAULT_ATTACHMENT_ACTION.REMOVE] : [];

    const ownerInfo = canEdit
      ? [DEFAULT_ATTACHMENT_ACTION.SEPARATOR, DEFAULT_ATTACHMENT_ACTION.INFO]
      : [];

    return [...downloadAction, ...editAction, ...removeAction, ...ownerInfo];
  }, [isAllowed, attachment, space]);

  return <AttachmentActions attachment={attachment} actions={actions} />;
};
