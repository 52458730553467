import React from "react";
import classNames from "classnames";

import "./UnreadDot.scss";

/**
 * @deprecated
 * Use UnreadDotV2
 * This component is legacy and still used in places (Notifications/index)
 */
export const UnreadDot = ({
  inline,
  children,
  className,
  innerProps,
}: {
  inline?: boolean;
  children?: React.ReactNode;
  className?: string;
  innerProps?: {
    className?: string;
  };
}) => {
  return (
    <div
      className={classNames("highnote-unread-dot", className)}
      data-inline={inline}
      data-has-children={!!children}
    >
      <div className={classNames("inner", innerProps?.className)}>
        {children}
      </div>
    </div>
  );
};
