import React, { MouseEvent, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { PreviewText } from "@highnote/preview-text/src";
import { Track } from "@highnote/server/src/core/entities";
import { highnote } from "@highnote/server/src/sdk";
import { EntityRowConfig } from "App/components/EntityTable";
import { ENTITY_TYPE } from "App/components/EntityTable/config";
import { EntityIconPlayable } from "App/components/EntityTable/EntityIcon";
import { useSpaces } from "App/components/useEntities";
import { useFiles } from "App/components/useFiles";
import { Button, BUTTON_THEME } from "App/core/Button";
import { PREVIEW_ICON_SIZE, PreviewIcon } from "./PreviewIcon";
import { useSpaceContext } from "./useSpace";
import "./ThumbnailPreview.scss";

export const useTrackArtworkUrls = ({ tracks }: { tracks: Track[] }) => {
  const { getDownloadUrl } = useFiles();
  const [sources, setSources] = useState<Record<string, string>>({});

  const { entities: spaces } = useSpaces();
  const { space } = useSpaceContext();

  useEffect(() => {
    let unmounted: boolean;
    if (tracks.length === 0) return;
    const ids = tracks
      .map((track) => {
        return (
          track.artworkFile ||
          spaces.find(({ id }) => id === track.spaceId)?.artworkFile ||
          space?.artworkFile
        );
      })
      .filter(Boolean);
    if (ids.length === 0) return;
    const uniqueIds = Array.from(new Set(ids));

    highnote.getFiles({ ids: uniqueIds }).then(async (files) => {
      if (unmounted) return;
      setSources(
        await files.reduce<Promise<Record<string, string>>>(
          async (acc, curr) => {
            const next = await acc;
            try {
              next[curr.id] = await getDownloadUrl(curr);
            } catch (e) {
              next[curr.id] = "/public/default-track-artwork.png";
            }
            return next;
          },
          Promise.resolve({}),
        ),
      );
    });

    return () => {
      unmounted = true;
    };
  }, [tracks]);

  return { sources, fallback: "/public/default-track-artwork.png" };
};

export const useTrackArtworkUrl = ({ track }: { track: Track }) => {
  const { getDownloadUrl } = useFiles();
  const [source, setSource] = useState<string>();
  const [fallback, setFallback] = useState<string>(
    "/public/default-track-artwork.png",
  );

  const { entities: spaces } = useSpaces();
  const { space } = useSpaceContext();
  const spaceArtworkFile = space?.artworkFile;
  const parentArtworkFile =
    spaces.find((s) => s.id === track?.spaceId)?.artworkFile ||
    spaceArtworkFile;
  const artworkFile = track?.artworkFile || parentArtworkFile;

  useEffect(() => {
    let unmounted: boolean;

    if (!artworkFile) {
      setFallback("/public/default-track-artwork.png");
      return;
    }

    highnote.getFiles({ ids: [artworkFile] }).then(async (files) => {
      if (unmounted) return;
      const artworkFileEntity = files.find((f) => f.id === artworkFile);
      const url = await getDownloadUrl(artworkFileEntity);
      setSource(url);
    });

    return () => {
      unmounted = true;
    };
  }, [artworkFile]);

  return { source, fallback };
};

export const ThumbnailPreview = ({
  className,
  onClick,
  iconSource,
  iconFallback,
  title,
  description,
  row,
  hideThumbnail,
}: {
  className?: string;
  onClick?: () => void;
  iconSource?: string;
  iconFallback: React.ReactNode;
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  row?: EntityRowConfig;
  hideThumbnail?: boolean;
}) => {
  const resizeRef = useRef<HTMLDivElement>();
  const isTitleString = typeof title === "string";

  const handleClick = (e: MouseEvent) => {
    const path = Array.from(e.nativeEvent.composedPath());
    const isFromRow = path.some((el: HTMLElement) =>
      el?.classList?.contains("highnote-thumbnail-preview"),
    );
    if (!isFromRow) return;
    onClick && onClick();
  };

  if (row?.type === ENTITY_TYPE.UPLOAD) {
    return (
      <div
        ref={resizeRef}
        className={classNames(
          "highnote-thumbnail-preview highnote-thumbnail-preview-upload",
          className,
        )}
      >
        <div className="info">
          <div className="thumbnail-preview-image">
            <PreviewIcon
              size={PREVIEW_ICON_SIZE.LARGE}
              src={iconSource}
              fallback={iconFallback}
            />
          </div>

          <span className="title">
            {isTitleString ? (
              <PreviewText watchRefs={[resizeRef]}>{title}</PreviewText>
            ) : (
              title
            )}
          </span>
        </div>
        <span className="description">{description}</span>
      </div>
    );
  }

  return (
    <div
      ref={resizeRef}
      className={classNames("highnote-thumbnail-preview", className)}
    >
      {!hideThumbnail && (
        <div className="thumbnail-preview-track">
          <PreviewIcon
            size={PREVIEW_ICON_SIZE.LARGE}
            src={iconSource}
            fallback={iconFallback}
          />
          {row?.type === ENTITY_TYPE.TRACK && (
            <EntityIconPlayable
              row={row}
              className="thumbnail-preview-playable"
            />
          )}
        </div>
      )}

      <div className="info">
        {onClick ? (
          <Button
            className="title"
            data-is-clickable={!!onClick}
            onClick={handleClick}
            theme={BUTTON_THEME.ICON}
          >
            {isTitleString ? (
              <PreviewText watchRefs={[resizeRef]}>{title}</PreviewText>
            ) : (
              title
            )}
          </Button>
        ) : (
          <span className="title">
            {isTitleString ? (
              <PreviewText watchRefs={[resizeRef]}>{title}</PreviewText>
            ) : (
              title
            )}
          </span>
        )}
        <span className="description">{description}</span>
      </div>
    </div>
  );
};
