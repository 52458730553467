import React from "react";
import classNames from "classnames";
import { Version } from "App/components/EntityTable/config";
import { DEFAULT_VERSION_COLOR_ID } from "App/components/TrackEditor/TrackVersions/index";
import { useTrack } from "App/components/useEntities/useTrack";
import styles from "./TrackVersionIcon.module.scss";

export const TrackVersionIcon = ({
  version,
  textSize = "responsive",
}: {
  version: Version;
  textSize?: "responsive" | "small";
}) => {
  const { trackVersions } = useTrack();

  const versionNumber =
    version.index ??
    trackVersions.indexOf(trackVersions.find((v) => v.id === version.id));

  return (
    <div
      className={styles["track-version-thumbnail"]}
      data-color-id={DEFAULT_VERSION_COLOR_ID}
    >
      <div
        className={classNames(styles["version-number"], {
          [styles["small-text"]]: textSize === "small",
        })}
      >
        V{versionNumber + 1}
      </div>
    </div>
  );
};
