import Stripe from "stripe";
import {
  EXISTING_AUTH_WITH_APPLE_ERR,
  EXISTING_AUTH_WITH_GOOGLE_ERR,
} from "./shared-util";
import { APP_FEATURES } from "./features";

export const AUDIO_ACCEPT_TYPES = [
  "audio/mp3",
  "audio/x-mp3",
  "audio/x-m4a",
  "audio/mpeg",
  "audio/x-mpeg",
  "audio/x-mpeg3",
  "audio/x-mpegaudio",
  "audio/x-mpg",
  "audio/mp4",
  "audio/wav",
  "audio/x-wav",
  "audio/ogg",
  "audio/webm",
  "audio/aif",
  "audio/aiff",
  "audio/x-aif",
  "audio/x-aiff",
];

export const AUDIO_ACCEPT_TYPES_LIST = [
  ".mp3",
  ".mp4",
  ".m4a",
  ".wav",
  ".ogg",
  ".aif",
  ".aiff",
  ".webm",
];

export const AUDIO_ACCEPT_TYPES_STRING =
  ".mp3, .mp4, .m4a, .wav, .ogg, .aif, .aiff, or .webm";

/* SHARED
---------------------------------- */
export const PUBLIC_ID = "public";
export const SHARE_KEY_PREFIX = "share_";
// eslint-disable-next-line no-useless-escape
export const SHARE_KEY_REGEX = /^share[\|\_]/;
export type Id = string | "public";
export const DELETE_FIRESTORE_FIELD = "DELETE_FIRESTORE_FIELD";

export type ArrayOperation<T> = ArrayUnion<T> | ArrayRemove<T>;

export class IncrementValue {
  increment: number;
  constructor(inc: number) {
    this.increment = inc;
  }
}

export class ArrayUnion<T = void> {
  arrayUnion: T[];
  constructor(arr: T[]) {
    this.arrayUnion = arr;
  }
}

export class ArrayRemove<T = void> {
  arrayRemove: T[];
  constructor(arr: T[]) {
    this.arrayRemove = arr;
  }
}

export enum ROLE {
  VIEW = "role_VIEW",
  COMMENT = "role_COMMENT",
  DOWNLOAD = "role_DOWNLOAD",
  UPLOAD = "role_UPLOAD",
  MANAGE = "role_MANAGE",
  ADMIN = "role_ADMIN",

  // Not tied to any action that users can take
  // Used to check if share links are accessible to anonymous users
  GUESTS_ALLOWED = "role_GUESTS_ALLOWED",
}

export const COMPATIBLE_ROLES: Record<ROLE, ROLE[]> = Object.freeze({
  [ROLE.VIEW]: [
    ROLE.VIEW,
    ROLE.COMMENT,
    ROLE.DOWNLOAD,
    ROLE.UPLOAD,
    ROLE.MANAGE,
    ROLE.ADMIN,
  ],
  [ROLE.COMMENT]: [ROLE.COMMENT, ROLE.MANAGE, ROLE.ADMIN],
  [ROLE.DOWNLOAD]: [ROLE.DOWNLOAD, ROLE.MANAGE, ROLE.ADMIN],
  [ROLE.UPLOAD]: [ROLE.UPLOAD, ROLE.MANAGE, ROLE.ADMIN],
  [ROLE.GUESTS_ALLOWED]: [ROLE.GUESTS_ALLOWED, ROLE.MANAGE, ROLE.ADMIN],
  [ROLE.MANAGE]: [ROLE.MANAGE, ROLE.ADMIN],
  [ROLE.ADMIN]: [ROLE.ADMIN],
});

export type Roles = Record<Id, ROLE[]>;

export enum PASSWORD_TYPE {
  Share = "share",
  Public = "public",
}

type EntityCore = {
  id: Id;
  createdBy: Id;
  createdAt: number;
  updatedAt?: number;
};

export type Entity = EntityCore &
  PasswordProtectableEntity & {
    createdByAlias?: string;
    lastUpdatedBy?: Id;

    /* PERMISSIONS-RELATED - only affects Collections & Spaces for now */
    rolesV2?: Roles;
    inheritedRolesV3?: Roles;
    readableByV2?: Id[];
    topLevelReadableBy?: Id[];

    /* For each of these users, this entity was affected during an
  account link. We use this to know what to un-merge if they unlink. */
    mergedUserIds?: string[];

    /* this should just be a mirror of subscription tier of `createdBy` user */
    subscriptionTier?: SUBSCRIPTION_TIER;
  };

export type Auth0User = {
  created_at?: string;
  phone_number?: string;
  email?: string;
  email_verified?: boolean;
  last_login?: string;
  logins_count?: number;
  name?: string;
  nickname?: string;
  picture?: string;
  updated_at?: string;
  user_id?: string;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  app_metadata?: Record<string, any>;
  user_metadata?: Record<string, any>;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  identities?: {
    connection: string;
    user_id: string;
    provider: string;
    profileData?: {
      email?: string;
      phone_number?: string;
    };
  }[];
};

/* KNOCK EVENTS
---------------------------------- */
// Must match IDs in Knock dashboard to trigger workflows
export enum KNOCK_WORKFLOW {
  DOWNLOAD_LINK_FAILED = "download-link-failed",
  DOWNLOAD_LINK_READY = "download-link-ready",
  TRACK_ADDED = "track-added",
  TRACK_PLAYED = "track-played",
  TRACK_DOWNLOADED = "track-downloaded",
  TRACK_VERSION_ADDED = "track-version-added",
  TRACK_ATTACHMENT_ADDED = "track-attachment-added",
  SPACE_ITEMS_DOWNLOADED = "space-items-downloaded",
  CARD_COMMENT_ADDED = "card-comment-added",
  CARD_REPLY_ADDED = "card-reply-added",
  CARD_RESOLVED = "card-resolved",
  CHAT_COMMENT_ADDED = "chat-comment-added",
  SUBSCRIPTION_UPDATED = "subscription-updated",
  SPACE_MEMBER_ADDED = "space-member-added",
  SPACE_ATTACHMENT_ADDED = "space-attachment-added",
  SPACE_MEMBER_JOINED = "space-member-joined",
  SPACE_MEMBER_REQUESTED = "space-member-requested",
  SPACE_DOWNLOADED = "space-downloaded",
  SPACE_ATTACHMENT_DOWNLOADED = "space-attachment-downloaded",
  TRACK_ATTACHMENT_DOWNLOADED = "track-attachment-downloaded",
  TRACK_VERSION_DOWNLOADED = "track-version-downloaded",
}

export type NotificationDataCore = {
  origin?: string;
  artworkUrl?: string;
};

export type DownloadLinkFailedData = NotificationDataCore & {
  downloadRequestId: string;
  entityId: string;
  entityName: string;
  entityType: string;
  createdBy: string;
  subscribers: string[];
};

export type DownloadLinkReadyData = DownloadLinkFailedData;

export type SpaceMemberAddedData = NotificationDataCore &
  SpaceMemberJoinedData & {
    spaces: Array<{
      id: string;
      name: string;
      artworkUrl: string;
    }>;
  };

export type SpaceMemberRequestedData = NotificationDataCore & {
  displayName: string;
  identifier?: string;
  spaceId: string;
  spaceName?: string;
};

export type SpaceMemberJoinedData = NotificationDataCore & {
  memberId: string;
  spaceId: string;
  spaceName: string;
  spaceDescription: string;
  tracks: { trackId: string; trackName: string; trackArtworkUrl: string }[];
};

export type CardCommentAddedData = NotificationDataCore & {
  spaceId: string;
  spaceName: string;

  trackId: string;
  trackName: string;

  versionId: string;
  versionName: string;

  commentId: string;
  commentType: string;
  commentText: string;
  commentTimestamp: string;
};

export type CardReplyAddedData = CardCommentAddedData & {
  rootCommentId: string;
  rootCommentType: string;
  rootCommentText: string;
  rootCommentTimestamp: string;
};

export type CardResolvedData = CardCommentAddedData;

export type ChatCommentAddedData = NotificationDataCore & {
  spaceId: Id;
  spaceName: string;

  commentId: Id;
  commentText: string;
  commentDatetime: string;
};

export type SubscriptionUpdateData = NotificationDataCore & {
  userId: string;
  updateTitle: string;
  updateDescription: string;
};

type baseTrackData = {
  spaceId: string;
  spaceName: string;

  trackId: string;
  trackName: string;
  trackArtworkUrl: string;
};

export type TrackAddedData = NotificationDataCore & baseTrackData;

export type TrackDownloadedData = TrackAddedData;

export type TrackAttachmentAddedData = NotificationDataCore & {
  fileId: string;
  fileName: string;
  spaceId: string;
  trackArtworkUrl: string;
  trackId: string;
  trackName: string;
};

export type TrackAttachmentsDownloadedData = NotificationDataCore &
  baseTrackData & {
    files: Array<{
      fileId: string;
      fileName: string;
    }>;
  };

export type TrackAttachmentDownloadedData = NotificationDataCore &
  baseTrackData & {
    fileId: string;
    fileName: string;
  };

export type TrackVersionAddedData = NotificationDataCore &
  baseTrackData & {
    versionId: string;
  };

export type TrackPlayedData = NotificationDataCore & {
  spaceId: string;
  spaceName: string;

  trackArtworkUrl: string;
  trackId: string;
  trackName: string;

  listenerId: string;
  numPlays: number;
};

export type TrackVersionDownloadedData = NotificationDataCore &
  baseTrackData & {
    fileId: string;
    fileName: string;
    versionNumber: number;
  };

export type TrackVersionsDownloadedData = NotificationDataCore &
  baseTrackData & {
    files: Array<{
      fileId: string;
      fileName: string;
      versionNumber: number;
    }>;
  };

export type SpaceAttachmentAddedData = NotificationDataCore & {
  fileId: string;
  fileName: string;
  spaceId: string;
  spaceName: string;
};

export type SpaceDownloadedData = NotificationDataCore & {
  spaceId: string;
  spaceName: string;
};

export type SpaceAttachmentDownloadedData = SpaceAttachmentAddedData;

// eslint-disable-next-line @typescript-eslint/ban-types
export type SpaceItemsDownloadedData = NotificationDataCore & {};

export type NotificationData =
  | TrackVersionAddedData
  | TrackVersionDownloadedData
  | TrackVersionsDownloadedData
  | TrackAddedData
  | TrackDownloadedData
  | TrackAttachmentAddedData
  | TrackAttachmentDownloadedData
  | TrackAttachmentsDownloadedData
  | SpaceMemberAddedData
  | CardReplyAddedData
  | CardResolvedData
  | SpaceMemberJoinedData
  | SpaceMemberRequestedData
  | CardCommentAddedData
  | ChatCommentAddedData
  | SubscriptionUpdateData
  | SpaceItemsDownloadedData
  | SpaceAttachmentAddedData
  | SpaceAttachmentDownloadedData
  | SpaceDownloadedData;

// Must match IDs in Knock dashboard (see Preferences)
export enum CHANNEL {
  FEED = "in_app_feed",
  EMAIL = "email",
  SMS = "sms",
  PUSH = "push",
}

/* FILES
---------------------------------- */
export type FileCore = {
  name: string;
  url?: string;
  storagePath?: string;
  fileType?: string;
  fileName?: string;
  size?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata?: Record<string, any>;
  uploadedBy?: string;

  isProcessing?: boolean;
  isProcessedV3?: boolean;
  processingErrorV3?: string;
};

export type FileEntity = Entity & FileCore;

export type FileReferences = {
  [COLLECTION_ID.DROPBOX_JOBS]: Track[];
  [COLLECTION_ID.TRACK]: Track[];
  [COLLECTION_ID.SPACE]: Space[];
  [COLLECTION_ID.COMMENT]: Comment[];
};

export enum AUDIO_QUALITY {
  LOW = "low",
  HIGH = "high",
  ORIGINAL = "original",
}

export const AUDIO_QUALITY_LEVEL = {
  [AUDIO_QUALITY.LOW]: 1,
  [AUDIO_QUALITY.HIGH]: 2,
  [AUDIO_QUALITY.ORIGINAL]: 3,
};

export type AudioQualityOption = {
  quality: AUDIO_QUALITY;
  format: string;
  filename: string;
  bitRate?: number;
  samplingRate?: number;
};

export const audioQualityOptions: AudioQualityOption[] = [
  {
    quality: AUDIO_QUALITY.ORIGINAL,
    format: "flac",
    filename: `${AUDIO_QUALITY.ORIGINAL}.flac`,
  },
  {
    quality: AUDIO_QUALITY.ORIGINAL,
    format: "mp3",
    filename: `${AUDIO_QUALITY.ORIGINAL}.mp3`,
  },
  {
    quality: AUDIO_QUALITY.HIGH,
    format: "mp3",
    filename: `${AUDIO_QUALITY.HIGH}.mp3`,
    bitRate: 320000,
    samplingRate: 44100,
  },
  {
    quality: AUDIO_QUALITY.LOW,
    format: "mp3",
    filename: `${AUDIO_QUALITY.LOW}.mp3`,
    bitRate: 96000,
    samplingRate: 44100,
  },
];

type PasswordProtectableEntity = {
  // PASSWORD PROTECTION RELATED - only affects Spaces for now
  sharePassword?: string;
  sharePasswordEnabled?: boolean;
  publicPassword?: string;
  publicPasswordEnabled?: boolean;
};

// Dropbox integrations
export enum JOB_STATUS {
  PENDING = "pending",
  IN_PROGRESS = "inProgress",
  COMPLETED = "completed",
  FAILED = "failed",
}
export enum DROPBOX_JOB_TYPE {
  // download from Dropbox
  DOWNLOAD = "download",

  // upload to Dropbox
  // UPLOAD = "upload",
}

export type DropboxSyncJob = EntityCore & {
  jobType: DROPBOX_JOB_TYPE;
  // this is to reference a file document in COLLECTION_ID.FILE
  fileEntityId: string;
  // this is a file ID from dropbox
  fileId: string;
  // this is a file name from dropbox at the time of download
  fileName: string;
  spaceId: string;
  progress: number;
  processingError?: string;
  status: JOB_STATUS;
};

/* SPACES
---------------------------------- */

export type SpaceCore = PasswordProtectableEntity & {
  spaceId?: Id;
  spaceColor?: string;
  name: string;
  description?: string;
  artworkFile?: Id;
  files?: Id[];
  isArchived?: boolean;
  tracksOrder?: Id[];
  itemsOrder?: Id[];
  privateInboxEnabled?: boolean;
  privateInboxRedirectUrl?: string;

  audioQualityLocked?: boolean;
  subscriptionTier?: SUBSCRIPTION_TIER;

  isDraftSpace?: boolean;

  // (MOBILE ONLY) used to indicate whether the space chat feature is hidden or not
  chatEnabled?: boolean;
  // (MOBILE ONLY) used to know if/when to show the user an easy getting-started templates for populating an empty space
  spaceSetup?: boolean;

  // keeps track of the last time a file for a space was updated at
  // (e.g., artwork and attachments, etc.)
  fileUpdatedAt?: number;

  // experimental feature flag based on owner's experimentalPrivateInboxEnabled
  // - turning this ON makes `privateInboxEnabled` toggleable by space managers
  // - turning this OFF makes `privateInboxEnabled` NOT toggleable and remain OFF
  // - however, this field itself should NOT be modified directly. instead, it should
  //   be synced with space owner's `experimentalPrivateInboxEnabled` field
  experimentalPrivateInboxEnabled?: boolean;
};

export type Space = Entity & SpaceCore;

export type DownloadRequestCore = {
  entityId: Id;
  entityType: ARCHIVABLE_ENTITY_TYPES;
  isProcessing?: boolean;
  processingError?: string;
  storagePath?: string;
  subscribers?: string[];
  subscriptions?: Record<string, number>;
};

export type DownloadRequest = Entity & DownloadRequestCore;

/* TRACKS
---------------------------------- */
export type TrackCore = {
  title: string;
  artistName?: string;
  versionFilesV2: Id[];
  pinnedVersionFiles?: Id[];
  defaultVersionId?: Id;
  files?: Id[];
  artworkFile?: Id;
  path?: string; // todo: get rid of this?
  spaceId?: Id;

  // keeps track of the last time a file for a track was updated at
  // (e.g., artwork, versions, or attachments, etc.)
  fileUpdatedAt?: number;
};

export type Track = Entity & TrackCore;

export type TrackPlayCore = {
  firstPlayedAt: number;
  lastPlayedAt: number;
  numPlays: number;
};

export type TrackPlay = Entity & TrackPlayCore;

/* COMMENTS
---------------------------------- */
export enum COMMENT_BLOCK_TYPE {
  TEXT = "text",
  FILE = "file-v2",
  POLL = "poll-v2",
  POLL_REPLY = "poll-reply",
  CUSTOM = "custom",
}

type TextBlock = {
  type: COMMENT_BLOCK_TYPE.TEXT;
  text: string;
};

export type FileBlockV2 = {
  type: COMMENT_BLOCK_TYPE.FILE;
  text: string;
  file: Id;
};

export type PollOption = TextBlock | FileBlockV2;

export type PollBlockV2 = {
  type: COMMENT_BLOCK_TYPE.POLL;
  text: string;
  options: PollOption[];
};

export type CustomBlock = {
  type: COMMENT_BLOCK_TYPE.CUSTOM;
  id: "heart-sticker";
};

export type PollReplyBlock = {
  type: COMMENT_BLOCK_TYPE.POLL_REPLY;
  option: number;
};

export type CommentBlock =
  | FileBlockV2
  | TextBlock
  | PollBlockV2
  | CustomBlock
  | PollReplyBlock;

export type CommentCore = {
  spaceId: Id;
  trackId: Id;
  trackVersionIds?: Id[];
  timestamp?: number;
  duration?: number;
  replies?: Id[];
  blocks: CommentBlock[];
  commentType?: COMMENT_BLOCK_TYPE;
  parentComment?: Id;
  isResolved?: boolean;
};

export type Comment = Entity &
  CommentCore & {
    /* Post-creation data for indexing */
    parentComment?: Id;
    rootComment?: Id;
  };

/* USERS
---------------------------------- */
export type UserSubscriptions = {
  active: Stripe.Subscription | null;
  scheduled: Stripe.SubscriptionSchedule | null;
};

export enum AUTH_PREFIX {
  APPLE = "apple",
  EMAIL = "auth0",
  SMS = "sms",
  GOOGLE = "google-oauth2",
}

export const DUPE_EMAIL_ACCOUNT_ERROR = {
  [AUTH_PREFIX.APPLE]: EXISTING_AUTH_WITH_APPLE_ERR,
  [AUTH_PREFIX.GOOGLE]: EXISTING_AUTH_WITH_GOOGLE_ERR,
};

export enum AUTH_ACTION {
  LINK = "link",
  UNLINK = "unlink",
}

export type DropboxAuthTokens = {
  access_token: string;
  token_type: "bearer";
  expires_in: number;
  refresh_token: string;
  scope: string;
  uid: string;
  account_id: string;
};

export type UserCore = {
  isVerified?: boolean;
  name?: string;
  picture?: string;
  hasAcceptedTOS?: boolean;

  // Credentials
  email?: string;
  appleId?: string;
  googleId?: string;
  phone?: string;
  connection?: string;

  // Account Linking
  primaryAccountId?: Id;
  linkedAccountsV2?: { id: string; connection: string; identifier: string }[];
  isMergingWith?: string[];
  isUnmergingFrom?: string[];
  checkedDuplicates?: boolean;
  deletedAuth0?: boolean;

  // Subcription Details
  storageUsed?: number;
  spacesUsed?: number;
  activeSpacesUsed?: number;
  tracksUsed?: number;
  trackVersionsUsed?: number;
  subscriptions?: UserSubscriptions;
  subscriptionTier?: SUBSCRIPTION_TIER;
  previousActiveSubscription?: Stripe.Subscription;
  stripeCustomerId?: string;
  starterSpaceGenerated?: boolean;
  hasIntroducedToPrivateInbox?: boolean;

  // Used for tracking only.
  firstStripeChargeId?: string;
  firstStripeChargeAmount?: number;
  firstLogIn?: boolean;
  completedWelcomeSurvey?: boolean;

  // From Auth0
  user_metadata?: object;

  // Custom Notification Settings
  draftSpaceId?: string;
  mutedSpaces?: string[];
  mutedCollections?: string[];
  notifyOnAllTrackPlays?: boolean;
  lockModePlayerOnboarded?: boolean;
  draftSpaceOnboarded?: boolean;

  // controlled feature callout status
  passwordProtectionCalloutDismissed?: boolean;
  downloadControlCalloutDismissed?: boolean;
  audioQualityLockCalloutDismissed?: boolean;

  // dropbox integration
  dropboxLinkedFrom?: string;
  dropboxAccountId?: string;
  dropboxTokens?: DropboxAuthTokens;

  // experimental features
  isMobileUser?: boolean;
  experimentalPrivateInboxEnabled?: boolean;

  pinnedSpaces?: string[];
};

export type UserEntity = UserCore & Entity;

export type UserMetadata = {
  HMACUserId?: string;
  features: Record<APP_FEATURES, boolean>;
};

export type PublicUser = {
  id: string;
  isVerified?: boolean;
  name?: string;
  picture?: string;
};

/* ALL COLLECTIONS
---------------------------------- */

export enum COLLECTION_ID {
  DOWNLOAD_REQUEST = "download-request",
  DROPBOX_JOBS = "dropbox-jobs",
  TRACK = "tracks-v2",
  SPACE = "spaces-v1",
  COMMENT = "comments-v1",
  FILE = "files-v1",
  USER = "users-v1",
  PUBLIC_USER = "public-users-v1",
  COLLECTION = "collections-v1",
  TRACK_PLAY = "track-plays-v1",
}

export enum ARCHIVABLE_ENTITY_TYPES {
  SPACE = COLLECTION_ID.SPACE,
  TRACK = COLLECTION_ID.TRACK,
  FILE = COLLECTION_ID.FILE,
  TRACK_FILES = "trackFiles",
}

export enum OAUTH_PROVIDERS {
  DROPBOX = "dropbox",
}

/* SUBSCRIPTIONS
---------------------------------- */
// WARNING!!!!
// If you edit this enum, you must edit the matching values in
// @highnote/server/storage.rules!!!!
export enum SUBSCRIPTION_TIER {
  FREE = "FREE",
  INDIE = "INDIE",
  PRO = "PRO",
  STUDIO = "STUDIO",
}

export const SUBSCRIPTION_TIER_FORMATTED: Record<SUBSCRIPTION_TIER, string> = {
  [SUBSCRIPTION_TIER.FREE]: "Free",
  [SUBSCRIPTION_TIER.INDIE]: "Indie",
  [SUBSCRIPTION_TIER.PRO]: "Pro",
  [SUBSCRIPTION_TIER.STUDIO]: "Studio",
};

export enum SUBSCRIPTION_INTERVAL {
  MONTHLY = "month",
  ANNUAL = "year",
}

export const STRIPE_INTERVAL_FORMATTED: Partial<
  Record<Stripe.Price.Recurring.Interval, string>
> = {
  [SUBSCRIPTION_INTERVAL.MONTHLY]: "Monthly",
  [SUBSCRIPTION_INTERVAL.ANNUAL]: "Annual",
};

export const useProductionPlans = process.env.DEPLOY_ENV === "production";

type SubscriptionPricing = Record<
  SUBSCRIPTION_TIER,
  Record<SUBSCRIPTION_INTERVAL, string | null>
>;

const SUBSCRIPTION_PRICING_DEV: SubscriptionPricing = {
  [SUBSCRIPTION_TIER.FREE]: {
    [SUBSCRIPTION_INTERVAL.MONTHLY]: null,
    [SUBSCRIPTION_INTERVAL.ANNUAL]: null,
  },
  [SUBSCRIPTION_TIER.INDIE]: {
    [SUBSCRIPTION_INTERVAL.MONTHLY]: "price_1OeIymABNxgmdkGv86vHSnNe",
    [SUBSCRIPTION_INTERVAL.ANNUAL]: "price_1OeIzOABNxgmdkGvYKkGV6Lz",
  },
  [SUBSCRIPTION_TIER.PRO]: {
    [SUBSCRIPTION_INTERVAL.MONTHLY]: "price_1MX1rcABNxgmdkGvz9RF7gNM",
    [SUBSCRIPTION_INTERVAL.ANNUAL]: "price_1Mb9CpABNxgmdkGvccSUtsy5",
  },
  [SUBSCRIPTION_TIER.STUDIO]: {
    [SUBSCRIPTION_INTERVAL.MONTHLY]: "price_1Mb9EdABNxgmdkGvUpfnibVG",
    [SUBSCRIPTION_INTERVAL.ANNUAL]: "price_1Mb9DjABNxgmdkGvIteGgjeN",
  },
};

const SUBSCRIPTION_PRICING_PROD: SubscriptionPricing = {
  [SUBSCRIPTION_TIER.FREE]: {
    [SUBSCRIPTION_INTERVAL.MONTHLY]: null,
    [SUBSCRIPTION_INTERVAL.ANNUAL]: null,
  },
  [SUBSCRIPTION_TIER.INDIE]: {
    [SUBSCRIPTION_INTERVAL.MONTHLY]: "price_1OeJ1CABNxgmdkGvPsuro1jJ",
    [SUBSCRIPTION_INTERVAL.ANNUAL]: "price_1OeJ1SABNxgmdkGvH3VpYroM",
  },
  [SUBSCRIPTION_TIER.PRO]: {
    [SUBSCRIPTION_INTERVAL.MONTHLY]: "price_1MMvwKABNxgmdkGvYEonjhp4",
    [SUBSCRIPTION_INTERVAL.ANNUAL]: "price_1Mb98RABNxgmdkGvkmFZ7m4O",
  },
  [SUBSCRIPTION_TIER.STUDIO]: {
    [SUBSCRIPTION_INTERVAL.MONTHLY]: "price_1Mb9HtABNxgmdkGv6fcAPaap",
    [SUBSCRIPTION_INTERVAL.ANNUAL]: "price_1Mb98RABNxgmdkGviEEOTYHJ",
  },
};

export const SUBSCRIPTION_PRICING: SubscriptionPricing = useProductionPlans
  ? SUBSCRIPTION_PRICING_PROD
  : SUBSCRIPTION_PRICING_DEV;

export const SUBSCRIPTION_TIER_HIERARCHY = [
  SUBSCRIPTION_TIER.FREE,
  SUBSCRIPTION_TIER.INDIE,
  SUBSCRIPTION_TIER.PRO,
  SUBSCRIPTION_TIER.STUDIO,
];

/* SEGMENT IDS
---------------------------------- */
export enum EVENT_ID {
  CREATED_COMMENT = "Created Comment",
  UPDATED_COMMENT = "Updated Comment",
  REPLIED_TO_COMMENT = "Replied to Comment",
  DELETED_COMMENT = "Deleted Comment",

  TRCK_VERSIONS_USED_UPDATED = "trackVersionsUsed Updated",
  CREATED_TRACK = "Created Track",
  CREATED_DEMO_TRACK = "Created Demo Track",
  ADDED_TRACK_VERSION = "Updated Track: Added Version",
  REMOVED_TRACK_VERSION = "Updated Track: Removed Version",
  PLAYED_TRACK = "Played Track",

  CREATED_SPACE = "Created Space",
  CREATED_DEMO_SPACE = "Created Demo Space",
  ADDED_SPACE_MEMBER_NEW = "Updated Space: Invited New User",
  ADDED_SPACE_MEMBER_EXISTING = "Updated Space: Invited Existing User",
  ADDED_SPACE_TRACK = "Updated Space: Added Track",
  REMOVED_SPACE_TRACK = "Updated Space: Removed Track",
  DELETED_SPACE = "Deleted Space",
  ASSIGNED_SPACE_ROLE = "Updated Space: Assigned Role",
  UPDATED_SPACE_LINK = "Updated Space: Updated Link Permissions",

  UPDATED_USER_EMAIL = "Updated Profile: Updated Email",
  UPDATED_USER_NAME = "Updated Profile: Updated Name",
  SET_ANON_ALIAS = "Updated Profile: Set Anon Alias",
  SAW_ANON_PROMPT = "Viewed Dialog: Anon Warning",

  ENTERED_SPACE = "Entered Page: Space",
  EXITED_SPACE = "Exited Page: Space",
  ENTERED_LIBRARY = "Entered Page: Library",
  EXITED_LIBRARY = "Exited Page: Library",
  ENTERED_ACCOUNT_SETTINGS = "Entered Page: Account Settings",
  EXITED_ACCOUNT_SETTINGS = "Exited Page: Account Settings",

  ENTERED_SIDEBAR_SPACE_SHARE = "Opened Sidebar: Share Space",
  EXITED_SIDEBAR_SPACE_SHARE = "Closed Sidebar: Share Space",

  UPLOADED_FILE = "Uploaded File",
  DELETED_FILE = "Deleted File",

  STRIPE_INVOICE_CREATED = "Invoice Created",
  SUBSCRIPTION_UPDATED = "Updated Subscription",
  SUBSCRIPTION_CANCELED = "Canceled Subscription",
  ABANDONED_CHECKOUT = "Abandoned Checkout",

  LOGGED_IN = "Logged In",
  SIGNED_UP = "Signed Up",

  HELPER_ARCHIVE_TRACK = "Helper: Archive Track",

  CREATED_INVITE_LINK = "Invite Link Flow: Created Invite Link",
  COPIED_INVITE_LINK = "Invite Link Flow: Copied Invite Link",
  RECIPIENT_OPENED_LINK = "Invite Link Flow: Recipient Opened Link",
  RECIPIENT_OPENED_JOIN_DIALOG = "Invite Link Flow: Recipient Triggered Display Name Dialog",
  RECIPIENT_ADDED_NAME = "Invite Link Flow: Recipient Created Display Name",
  RECIPIENT_STAYED_ANON = "Invite Link Flow: Recipient Declined Display Name",
  RECIPIENT_LOGGED_IN = "Invite Link Flow: Recipient Logged In",
  RECIPIENT_SIGNED_UP = "Invite Link Flow: Recipient Registered",
  RECIPIENT_JOINED_ENTITY = "Invite Link Flow: Recipient Joined Entity",
  RECIPIENT_CLICKED_JOIN = "Invite Link Flow: Recipient Clicked Join Button",

  SENT_NOTIFICATION = "Sent Notification",
  RECIPIENT_OPENED_NOTIFICATION = "Recipient Opened Notification Link",

  TRACK_LIMIT_EXCEEDED_PAYWALL_DISPLAYED = "Track Limit Exceeded: Paywall Displayed",
  TRACK_LIMIT_EXCEEDED_CLICKED_UPGRADE = "Track Limit Exceeded: Clicked Upgrade",
  TRACK_LIMIT_EXCEEDED_CLICKED_MANAGE_TRACKS = "Track Limit Exceeded: Clicked Manage Tracks",

  TRACK_LIMIT_REACHED_VIEWED_PAYWALL = "Track Limit Reached: Viewed Paywall",
  TRACK_LIMIT_REACHED_CLICKED_UPGRADE = "Track Limit Reached: Clicked Upgrade",
  TRACK_LIMIT_REACHED_CLICKED_MANAGE_TRACKS = "Track Limit Reached: Clicked Manage Tracks",
  TRACK_LIMIT_REACHED_DISMISSED_PAYWALL = "Track Limit Reached: Dismissed Paywall",
}

export enum GROUP_ID {
  PAGES = "pages",
  DIALOGS = "dialogs",
  SIDEBARS = "sidebars",
  USER_PROFILE = "user_profile",
  USER_SUBSCRIPTION = "user_subscription",
  SPACES = "spaces",
  TRACKS = "tracks",
  COMMENTS = "comments",
  FILES = "files",
  HELPERS = "helpers",
  AUTHENTICATION = "authentication",
  INVITE_LINK_FLOW = "invite_link_flow",
  NOTIFICATION_FLOW = "notification_flow",
}

export const GROUP_BY_EVENT: Record<EVENT_ID, GROUP_ID> = {
  [EVENT_ID.CREATED_COMMENT]: GROUP_ID.COMMENTS,
  [EVENT_ID.UPDATED_COMMENT]: GROUP_ID.COMMENTS,
  [EVENT_ID.REPLIED_TO_COMMENT]: GROUP_ID.COMMENTS,
  [EVENT_ID.DELETED_COMMENT]: GROUP_ID.COMMENTS,

  [EVENT_ID.CREATED_TRACK]: GROUP_ID.TRACKS,
  [EVENT_ID.CREATED_DEMO_TRACK]: GROUP_ID.TRACKS,
  [EVENT_ID.ADDED_TRACK_VERSION]: GROUP_ID.TRACKS,
  [EVENT_ID.REMOVED_TRACK_VERSION]: GROUP_ID.TRACKS,
  [EVENT_ID.PLAYED_TRACK]: GROUP_ID.TRACKS,

  [EVENT_ID.CREATED_SPACE]: GROUP_ID.SPACES,
  [EVENT_ID.CREATED_DEMO_SPACE]: GROUP_ID.SPACES,
  [EVENT_ID.ADDED_SPACE_MEMBER_NEW]: GROUP_ID.SPACES,
  [EVENT_ID.ADDED_SPACE_MEMBER_EXISTING]: GROUP_ID.SPACES,
  [EVENT_ID.ADDED_SPACE_TRACK]: GROUP_ID.SPACES,
  [EVENT_ID.REMOVED_SPACE_TRACK]: GROUP_ID.SPACES,
  [EVENT_ID.DELETED_SPACE]: GROUP_ID.SPACES,
  [EVENT_ID.ASSIGNED_SPACE_ROLE]: GROUP_ID.SPACES,
  [EVENT_ID.UPDATED_SPACE_LINK]: GROUP_ID.SPACES,

  [EVENT_ID.UPDATED_USER_EMAIL]: GROUP_ID.USER_PROFILE,
  [EVENT_ID.UPDATED_USER_NAME]: GROUP_ID.USER_PROFILE,
  [EVENT_ID.SET_ANON_ALIAS]: GROUP_ID.USER_PROFILE,

  [EVENT_ID.SAW_ANON_PROMPT]: GROUP_ID.DIALOGS,

  [EVENT_ID.ENTERED_SPACE]: GROUP_ID.PAGES,
  [EVENT_ID.EXITED_SPACE]: GROUP_ID.PAGES,
  [EVENT_ID.ENTERED_LIBRARY]: GROUP_ID.PAGES,
  [EVENT_ID.EXITED_LIBRARY]: GROUP_ID.PAGES,
  [EVENT_ID.ENTERED_ACCOUNT_SETTINGS]: GROUP_ID.PAGES,
  [EVENT_ID.EXITED_ACCOUNT_SETTINGS]: GROUP_ID.PAGES,

  [EVENT_ID.ENTERED_SIDEBAR_SPACE_SHARE]: GROUP_ID.SIDEBARS,
  [EVENT_ID.EXITED_SIDEBAR_SPACE_SHARE]: GROUP_ID.SIDEBARS,

  [EVENT_ID.UPLOADED_FILE]: GROUP_ID.FILES,
  [EVENT_ID.DELETED_FILE]: GROUP_ID.FILES,

  [EVENT_ID.STRIPE_INVOICE_CREATED]: GROUP_ID.USER_SUBSCRIPTION,
  [EVENT_ID.SUBSCRIPTION_UPDATED]: GROUP_ID.USER_SUBSCRIPTION,
  [EVENT_ID.SUBSCRIPTION_CANCELED]: GROUP_ID.USER_SUBSCRIPTION,
  [EVENT_ID.ABANDONED_CHECKOUT]: GROUP_ID.USER_SUBSCRIPTION,

  [EVENT_ID.LOGGED_IN]: GROUP_ID.AUTHENTICATION,
  [EVENT_ID.SIGNED_UP]: GROUP_ID.AUTHENTICATION,

  [EVENT_ID.HELPER_ARCHIVE_TRACK]: GROUP_ID.HELPERS,

  [EVENT_ID.CREATED_INVITE_LINK]: GROUP_ID.INVITE_LINK_FLOW,
  [EVENT_ID.COPIED_INVITE_LINK]: GROUP_ID.INVITE_LINK_FLOW,
  [EVENT_ID.RECIPIENT_OPENED_LINK]: GROUP_ID.INVITE_LINK_FLOW,
  [EVENT_ID.RECIPIENT_OPENED_JOIN_DIALOG]: GROUP_ID.INVITE_LINK_FLOW,
  [EVENT_ID.RECIPIENT_ADDED_NAME]: GROUP_ID.INVITE_LINK_FLOW,
  [EVENT_ID.RECIPIENT_STAYED_ANON]: GROUP_ID.INVITE_LINK_FLOW,
  [EVENT_ID.RECIPIENT_LOGGED_IN]: GROUP_ID.INVITE_LINK_FLOW,
  [EVENT_ID.RECIPIENT_SIGNED_UP]: GROUP_ID.INVITE_LINK_FLOW,
  [EVENT_ID.RECIPIENT_JOINED_ENTITY]: GROUP_ID.INVITE_LINK_FLOW,
  [EVENT_ID.RECIPIENT_CLICKED_JOIN]: GROUP_ID.INVITE_LINK_FLOW,

  [EVENT_ID.SENT_NOTIFICATION]: GROUP_ID.NOTIFICATION_FLOW,
  [EVENT_ID.RECIPIENT_OPENED_NOTIFICATION]: GROUP_ID.NOTIFICATION_FLOW,

  [EVENT_ID.TRCK_VERSIONS_USED_UPDATED]: GROUP_ID.TRACKS,

  [EVENT_ID.TRACK_LIMIT_EXCEEDED_PAYWALL_DISPLAYED]: GROUP_ID.TRACKS,
  [EVENT_ID.TRACK_LIMIT_EXCEEDED_CLICKED_UPGRADE]: GROUP_ID.TRACKS,
  [EVENT_ID.TRACK_LIMIT_EXCEEDED_CLICKED_MANAGE_TRACKS]: GROUP_ID.TRACKS,

  [EVENT_ID.TRACK_LIMIT_REACHED_VIEWED_PAYWALL]: GROUP_ID.TRACKS,
  [EVENT_ID.TRACK_LIMIT_REACHED_CLICKED_UPGRADE]: GROUP_ID.TRACKS,
  [EVENT_ID.TRACK_LIMIT_REACHED_CLICKED_MANAGE_TRACKS]: GROUP_ID.TRACKS,
  [EVENT_ID.TRACK_LIMIT_REACHED_DISMISSED_PAYWALL]: GROUP_ID.TRACKS,
};
