import "./ToggleAudioQuality.scss";
import React, { useEffect, useMemo, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { ReactComponent as LockSVG } from "App/common/icons/locked.svg";
import { SKIP_AUDIO_MODE, useGlobalAudioPlayer } from ".";
import {
  audioQualityOptions,
  AUDIO_QUALITY,
  SUBSCRIPTION_TIER,
} from "@highnote/server/src/core/entities";
import { MenuItem } from "App/common/Menu";
import { Select } from "App/common/Select";
import { daw } from "@highnote/daw/src";
import { ReactComponent as QualityStandardSVG } from "App/common/icons/quality-standard.svg";
import { ReactComponent as QualityHighSVG } from "App/common/icons/quality-high.svg";
import { ReactComponent as JumpBack5SVG } from "App/common/icons/jumpBack5.svg";
import { ReactComponent as JumpBack10SVG } from "App/common/icons/jumpBack10.svg";
import { ReactComponent as SkipBackwardSVG } from "App/common/icons/skip-backward.svg";
import { useSpaceRaw } from "../useEntities";

type Stats = {
  description?: string;
  bitRate?: number;
  sampleRate?: number;
  format?: string;
};

export const useAudioQualityStats = () => {
  const { quality, nowPlaying } = useGlobalAudioPlayer();
  const [stats, setStats] = useState<Stats>({});

  useEffect(() => {
    const dawTrack = daw.getTrack(nowPlaying?.id);
    if (!dawTrack) return;

    const newStats: Stats = {};
    const option = audioQualityOptions.find((o) =>
      dawTrack.source?.includes(o.filename),
    );

    if (!option || option.quality === AUDIO_QUALITY.ORIGINAL) {
      const { bit_rate, sample_rate } = nowPlaying?.file?.metadata || {};
      newStats.bitRate = bit_rate;
      newStats.sampleRate = sample_rate;
      newStats.format = option?.format;
      newStats.description = "Original Quality";
    } else {
      newStats.bitRate = option.bitRate;
      newStats.sampleRate = option.samplingRate;
      newStats.format = option.format;
      newStats.description = `${
        quality === AUDIO_QUALITY.LOW ? "Standard" : "High"
      } Quality`;
    }

    if (option?.quality !== quality) {
      if (quality === AUDIO_QUALITY.HIGH) {
        newStats.description = "Highest Available";
      }
    }

    setStats(newStats);
  }, [nowPlaying]);

  return {
    description: stats.description,
    detail: (
      <>
        {stats.bitRate ? `${Math.floor(stats.bitRate / 1000)}kbps` : "--"}
        {" / "}
        {stats.sampleRate ? `${(stats.sampleRate / 1000).toFixed(1)}kHz` : "--"}
        {stats.format ? ` ${stats.format.toUpperCase()}` : ""}
      </>
    ),
  };
};

const NowPlayingStats = ({ qualityLocked }: { qualityLocked: boolean }) => {
  const audioQualityStats = useAudioQualityStats();

  return (
    <div className="NowPlayingStats" data-cypress-id="now-playing-stats">
      <span>
        {qualityLocked ? "Audio quality locked by a space manager. " : ""}
      </span>
      <span className="quality">{audioQualityStats.description}</span>
      <br />
      {audioQualityStats.detail}
    </div>
  );
};

interface Option {
  label: string;
  value?: string;
  icon?: React.ReactNode;
}

interface OptionGroup {
  type: "group";
  label: string;
  options: Option[];
}

interface SingleOption extends Option {
  type?: "item";
}

type OptionConfigItem = OptionGroup | SingleOption;

export const ToggleAudioQuality = React.memo(() => {
  const { quality, setAudioQuality, skipAudioMode, setSkipAudioMode } =
    useGlobalAudioPlayer();
  const { entity: currentSpace, loading: currentSpaceLoading } = useSpaceRaw();

  // This state is used to manage the multi-select state for the Select component.
  // selectedAudioControls is of type string[] to allow for multiple selections.
  const [selectedAudioControls, setSelectedAudioControls] = useState<string[]>([
    quality,
    skipAudioMode,
  ]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value: newValues },
    } = event;

    const valueChanged =
      (newValues as string[]).find(
        (value) => !selectedAudioControls.includes(value),
      ) || selectedAudioControls.find((value) => !newValues.includes(value));

    if (!valueChanged) return;

    // Find which group the changed selection belongs to
    const changedGroup = optionsList.find(
      (group) =>
        group.type === "group" &&
        group.options.some((option) => option.value === valueChanged),
    );

    if (!changedGroup) return;

    // Filter out any selections from the same group to enforce single selection per group
    const filteredValues = selectedAudioControls.filter(
      (audioControlOption) =>
        !optionsList.some(
          (group) =>
            group.type === "group" &&
            group.label === changedGroup.label &&
            group.options.some((option) => option.value === audioControlOption),
        ),
    );

    // Update the audio controls state
    setSelectedAudioControls([...filteredValues, valueChanged]);

    // Update external state based on the group of the changed selection
    if (changedGroup.label === "Audio Quality") {
      // Update external state for "Audio Quality"
      setAudioQuality(valueChanged as AUDIO_QUALITY);
    } else if (changedGroup.label === "Controls") {
      // Update external state for "Controls"
      setSkipAudioMode(valueChanged as SKIP_AUDIO_MODE);
    }
  };

  const isAudioQualityLocked = useMemo(() => {
    if (!currentSpaceLoading && currentSpace) {
      return (
        currentSpace.subscriptionTier === SUBSCRIPTION_TIER.PRO &&
        currentSpace.audioQualityLocked
      );
    }
    return false;
  }, [currentSpace, currentSpaceLoading]);

  const optionsList: OptionConfigItem[] = !isAudioQualityLocked
    ? [
        {
          type: "group",
          label: "Audio Quality",
          options: [
            { value: AUDIO_QUALITY.ORIGINAL, label: "Original" },
            { value: AUDIO_QUALITY.HIGH, label: "High" },
            { value: AUDIO_QUALITY.LOW, label: "Standard" },
          ],
        },
        {
          type: "group",
          label: "Controls",
          options: [
            {
              value: SKIP_AUDIO_MODE.FULL_SKIP,
              label: "Full Skip",
              icon: <SkipBackwardSVG />,
            },
            {
              value: SKIP_AUDIO_MODE.NUDGE_10S,
              label: "10s Nudge",
              icon: <JumpBack10SVG />,
            },
            {
              value: SKIP_AUDIO_MODE.NUDGE_5S,
              label: "5s Nudge",
              icon: <JumpBack5SVG />,
            },
          ],
        },
      ]
    : [{ type: "item", icon: <LockSVG />, label: "Original" }];

  return (
    <div className="ToggleAudioQuality" data-cypress-id="toggle-audio-quality">
      <Select
        multiple
        className="ToggleAudioQuality-select"
        renderValue={() => (
          <div className="display" data-quality={quality}>
            <span className="quality">
              {quality === AUDIO_QUALITY.ORIGINAL && <>ORIG</>}
              {quality === AUDIO_QUALITY.HIGH && <>HIGH</>}
            </span>
            {quality === AUDIO_QUALITY.LOW ? (
              <QualityStandardSVG />
            ) : (
              <QualityHighSVG />
            )}
          </div>
        )}
        MenuProps={{
          className: "ToggleAudioQuality-menu",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }}
        size="small"
        value={selectedAudioControls}
        onChange={handleChange}
      >
        {optionsList.flatMap((option, i) =>
          option.type === "group" ? (
            [
              <MenuItem
                disabled
                key={option.label}
                className="ToggleAudioQuality-menu-group-title"
              >
                {option.label}
              </MenuItem>,
              ...option.options.map((item) => (
                <MenuItem key={`${item.value}-${i}`} value={item.value}>
                  {item.label}
                  {item.icon}
                </MenuItem>
              )),
              <div className="divider" key={`${option.label}-divider-${i}`} />,
            ]
          ) : (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
              {option.icon}
            </MenuItem>
          ),
        )}
        <div>
          <NowPlayingStats
            qualityLocked={Boolean(currentSpace?.audioQualityLocked)}
          />
        </div>
      </Select>
    </div>
  );
});

ToggleAudioQuality.displayName = "ToggleAudioQuality (Memoized)";
