import React, { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  ArrayRemove,
  ArrayUnion,
  COLLECTION_ID,
  SelectionType,
  Space,
  SpaceCore,
  SUBSCRIPTION_TIER,
  Track,
} from "@highnote/server/src/core/entities";
import {
  getDefaultVersionId,
  getPinnedVersionsLimit,
  MAX_ACTIVE_SPACES_ERROR,
} from "@highnote/server/src/core/shared-util";
import { highnote } from "@highnote/server/src/sdk";
import { ReactComponent as CheckCircleSVG } from "App/common/icons-v2/check-circle.svg";
import { ReactComponent as ErrorSVG } from "App/common/icons-v2/stop-sign.svg";
import { setTestProperty } from "App/modules/utils";
import { LIMIT_TYPE, usePlanLimitsContext } from "./PlanLimits/usePlanLimits";
import { useToast } from "./useToast";

type HighnoteTools = {
  createSpace: (props?: {
    id?: string;
    data?: Partial<SpaceCore>;
  }) => Promise<Space>;
  moveToSpace: (props: {
    entityId: string;
    entityType: COLLECTION_ID.SPACE | COLLECTION_ID.TRACK;
    newSpaceId?: string;
  }) => Promise<void>;
  pinTrackVersion: (props: {
    track: Track;
    versionId: string;
    subscriptionTier: SUBSCRIPTION_TIER;
  }) => Promise<void>;
  unpinTrackVersion: (props: {
    track: Track;
    versionIds: string[];
  }) => Promise<void>;
  deleteOrRemoveSelections: (props: {
    entities: SelectionType[];
    toastMessages?: {
      createMessage?: string;
      successMessage?: string;
      errorMessage?: string;
    };
  }) => Promise<void>;
  moveSelectionToEntity: (props: {
    targetEntity: {
      entityId: string;
      entityType: COLLECTION_ID.SPACE | COLLECTION_ID.TRACK;
    };
    selectedEntities: SelectionType[];
  }) => Promise<void>;
  setAsDefaultVersion: (props: {
    track: Track;
    versionId: string;
  }) => Promise<void>;
  swapPinnedVersions: (props: {
    track: Track;
    currentVersionId: string;
    nextVersionId: string;
  }) => Promise<void>;
};

export const useHighnote: () => HighnoteTools = () => {
  const { toasted, addErrorMessage } = useToast();
  const { showPlanLimitsDialog } = usePlanLimitsContext();

  useEffect(() => {
    setTestProperty("highnoteSDK", highnote);
  }, []);

  const createSpace = async (_props?: {
    id?: string;
    data?: Partial<SpaceCore>;
  }) => {
    try {
      const props = _props || {};
      const spaceId = props.id || uuidv4();

      let space: Space;
      await toasted({
        promise: highnote
          .createSpace({
            id: spaceId,
            data: {
              name: `New Space`,
              ...(props.data || {}),
            },
          })
          .then((s) => (space = s)),
        createMessage: "Creating new Space...",
        errorMessage: "Could not create a new Space. Please try again.",
      });

      return space;
    } catch (e) {
      if (e.message === MAX_ACTIVE_SPACES_ERROR) {
        showPlanLimitsDialog(LIMIT_TYPE.SPACES);
        return;
      }
      throw e;
    }
  };

  const moveToSpace = async (props: {
    entityId: string;
    entityType: COLLECTION_ID.SPACE | COLLECTION_ID.TRACK;
    newSpaceId?: string;
  }) => {
    try {
      if (props.entityType === COLLECTION_ID.SPACE) {
        await moveSpaceToSpace({
          newSpaceId: props.newSpaceId,
          prevSpaceId: props.entityId,
        });
      } else {
        await moveTrackToSpace({
          trackId: props.entityId,
          spaceId: props.newSpaceId,
        });
      }
    } catch (e) {
      if (e.message === MAX_ACTIVE_SPACES_ERROR) {
        showPlanLimitsDialog(LIMIT_TYPE.SPACES);
        return;
      }
      throw e;
    }
  };

  const moveSelectionToEntity = async (props: {
    targetEntity: {
      entityId: string;
      entityType: COLLECTION_ID.SPACE | COLLECTION_ID.TRACK;
    };
    selectedEntities: SelectionType[];
  }) => {
    const promise = highnote.moveSelectionToEntity(props);
    await toasted({
      promise,
      createMessage: `Moving ${props.selectedEntities.length} items...`,
      successMessage: `Items successfully moved.`,
      ErrorContent: ({ error }: { error: Error }) => <>{error.message}</>,
    });
  };

  const moveTrackToSpace = async (props: {
    trackId: string;
    spaceId: string;
  }) => {
    const promise = highnote.updateTrack({
      id: props.trackId,
      data: {
        spaceId: props.spaceId,
      },
    });

    await toasted({
      promise,
      createMessage: `Moving Track...`,
      successMessage: `Track successfully moved.`,
      ErrorContent: ({ error }: { error: Error }) => <>{error.message}</>,
    });
  };

  const moveSpaceToSpace = async (props: {
    prevSpaceId: string;
    newSpaceId: string;
  }) => {
    const promise = highnote.updateSpace({
      id: props.prevSpaceId,
      data: {
        spaceId: props.newSpaceId,
      },
    });

    await toasted({
      promise,
      createMessage: props.newSpaceId ? `Moving Space...` : `Removing Space...`,
      successMessage: props.newSpaceId
        ? `Space successfully moved`
        : `Space successfully removed.`,
      ErrorContent: ({ error }: { error: Error }) => <>{error.message}</>,
    });
  };

  const setAsDefaultVersion = async (props: {
    track: Track;
    versionId: string;
  }) => {
    await toasted({
      promise: highnote.updateTrack({
        id: props.track.id,
        data: {
          defaultVersionId: props.versionId,
        },
      }),
      createMessage: "Setting default version...",
      successMessage: (
        <>
          <span className="toasted-icon">
            <CheckCircleSVG />
          </span>
          Default version set
        </>
      ),
      ErrorContent: ({ error }: { error: Error }) => (
        <>
          <span className="toasted-icon">
            <ErrorSVG />
          </span>
          Could not set default version. {error.message}
        </>
      ),
    });
  };

  const pinTrackVersion = async (props: {
    track: Track;
    versionId: string;
    subscriptionTier: SUBSCRIPTION_TIER;
  }) => {
    const isCurrentlyPinned = props.track.pinnedVersionFiles.includes(
      props.versionId,
    );

    if (
      isCurrentlyPinned ||
      props.track.pinnedVersionFiles.length >=
        getPinnedVersionsLimit(props.subscriptionTier)
    ) {
      addErrorMessage(
        "You have reached the maximum number of pinned versions.",
      );
      return;
    }

    await toasted({
      promise: highnote.updateTrack({
        id: props.track.id,
        data: {
          pinnedVersionFiles: new ArrayUnion([
            props.versionId,
          ]) as unknown as Id[],
        },
      }),
      createMessage: "Pinning track version...",
      successMessage: (
        <>
          <span className="toasted-icon">
            <CheckCircleSVG />
          </span>
          Track version pinned
        </>
      ),
      ErrorContent: ({ error }: { error: Error }) => (
        <>
          <span className="toasted-icon">
            <ErrorSVG />
          </span>
          Could not pin track version. {error.message}
        </>
      ),
    });
  };

  const unpinTrackVersion = async (props: {
    track: Track;
    versionIds: string[];
  }) => {
    const promise = highnote.updateTrack({
      id: props.track.id,
      data: {
        pinnedVersionFiles: new ArrayRemove(
          props.versionIds,
        ) as unknown as Id[],
      },
    });

    await toasted({
      promise,
      createMessage: "Unpinning track version...",
      successMessage: (
        <>
          <span className="toasted-icon">
            <CheckCircleSVG />
          </span>
          Track version unpinned
        </>
      ),
      ErrorContent: ({ error }: { error: Error }) => (
        <>
          <span className="toasted-icon">
            <ErrorSVG />
          </span>
          Could not unpin track version. {error.message}
        </>
      ),
    });
  };

  const swapPinnedVersions = async (props: {
    track: Track;
    currentVersionId: string;
    nextVersionId: string;
  }) => {
    const pinnedVersionFiles = [...props.track.pinnedVersionFiles];

    if (!props.track || pinnedVersionFiles.includes(props.nextVersionId)) {
      return;
    }

    const currentIndex = props.track.pinnedVersionFiles.indexOf(
      props.currentVersionId,
    );

    if (currentIndex === -1) {
      return;
    }

    pinnedVersionFiles[currentIndex] = props.nextVersionId;

    const defaultVersionId = getDefaultVersionId(props.track);
    const isCurrentDefaultVersion = defaultVersionId === props.currentVersionId;

    await toasted({
      promise: highnote.updateTrack({
        id: props.track.id,
        data: {
          pinnedVersionFiles: pinnedVersionFiles,
          ...(isCurrentDefaultVersion && {
            defaultVersionId: props.nextVersionId,
          }),
        },
      }),
      createMessage: "Swapping versions...",
      successMessage: (
        <>
          <span className="toasted-icon">
            <CheckCircleSVG />
          </span>
          A version has been swapped
        </>
      ),
      ErrorContent: ({ error }: { error: Error }) => (
        <>
          <span className="toasted-icon">
            <ErrorSVG />
          </span>
          Could not swap versions. {error.message}
        </>
      ),
    });
  };

  const deleteOrRemoveSelections = async (props: {
    entities: SelectionType[];
    toastMessages?: {
      createMessage?: string;
      successMessage?: string;
      errorMessage?: string;
    };
  }) => {
    await toasted({
      promise: highnote.deleteOrRemoveEntities({
        entities: props.entities,
      }),
      createMessage:
        props.toastMessages?.createMessage || "Deleting selections...",
      successMessage: (
        <>
          <span className="toasted-icon">
            <CheckCircleSVG />
          </span>
          {props.toastMessages?.successMessage || "All selections deleted."}
        </>
      ),
      ErrorContent: ({ error }: { error: Error }) => {
        const errorDescription =
          // eslint-disable-next-line react/prop-types
          props.toastMessages?.errorMessage || "Could not delete selections.";

        return (
          <>
            <span className="toasted-icon">
              <ErrorSVG />
            </span>
            {errorDescription} {error.message}
          </>
        );
      },
    });
  };

  return {
    createSpace,
    moveToSpace,
    moveSelectionToEntity,
    pinTrackVersion,
    unpinTrackVersion,
    deleteOrRemoveSelections,
    setAsDefaultVersion,
    swapPinnedVersions,
  };
};
