import React from "react";
import { Space } from "@highnote/server/src/core/entities";
import { ReactComponent as EnterInheritedCircleSVG } from "App/common/icons/enter-inherited-circle.svg";
import { Tooltip } from "App/core/Tooltip";

export const InheritanceIndicator = ({
  entity,
  userId,
}: {
  entity: Space;
  userId: string;
}) => {
  const inheritedRoles = entity?.inheritedRolesV3 || {};
  const entityRoles = entity?.rolesV2 || {};
  const hasEntityRoles = !!entityRoles[userId];
  const isInheriting = !!inheritedRoles[userId];

  if (!isInheriting || !!hasEntityRoles) return null;

  return (
    <Tooltip title="This level of access is based on permissions given to the parent Collection(s). Changing it here will only affect access to this Space.">
      <div className="InheritanceIndicator">
        <EnterInheritedCircleSVG />
      </div>
    </Tooltip>
  );
};
