import { v4 as uuidv4 } from "uuid";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { ReactComponent as CloseSVG } from "App/common/icons/close.svg";

type ToastMessageType = "error" | "warning" | "standard" | "success";

type ToastMessageOptions = {
  expireAt?: number;
  isPersistent?: boolean;
  title?: string;
};

type ToastMessageProps = ToastMessageOptions & {
  id: string;
  children: React.ReactNode;
  type?: ToastMessageType;
};

type ToastContextProps = {
  messages: ToastMessageProps[];
  addMessage: (msg: ToastMessageProps) => void;
  addSuccessMessage: (
    msg: React.ReactNode,
    options?: ToastMessageOptions,
  ) => void;
  addErrorMessage: (
    msg: React.ReactNode,
    options?: ToastMessageOptions,
  ) => void;
  removeMessage: (id: string) => void;

  /**
   * Convenience message for handling promises. Use `addSuccessMessage` or `addErrorMessage` for the
   * simplest messaging API.
   */
  toasted: (props: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    promise: Promise<any>;
    createMessage?: ToastMessageProps["children"];
    successMessage?: ToastMessageProps["children"];
    errorMessage?: ToastMessageProps["children"];
    ErrorContent?: React.FC<{ error: Error }>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) => Promise<any>;
};

const ToastContext = createContext<ToastContextProps>({
  messages: [],
  addMessage: () => {},
  addSuccessMessage: () => {},
  addErrorMessage: () => {},
  removeMessage: () => {},
  toasted: async () => {},
});

export const ToastMessageContent = ({
  text,
  title,
  type = "standard",
  id,
  isPersistent,
}: {
  title?: React.ReactNode;
  text: React.ReactNode;
  type?: ToastMessageType;
  id: string;
  isPersistent?: boolean;
}) => {
  const { removeMessage } = useToast();

  return (
    <>
      <div className="content" data-type={type} data-has-title={Boolean(title)}>
        {title && (
          <div className="title" data-type={type}>
            {title}
          </div>
        )}
        {text}
      </div>
      {isPersistent ? (
        <button className="close" onClick={() => removeMessage(id)}>
          <CloseSVG />
        </button>
      ) : null}
    </>
  );
};

const ToastMessage = ({ children, type }: ToastMessageProps) => {
  return (
    <div
      className="toast-message"
      data-cypress-id="toast-message"
      data-type={type}
    >
      {children}
    </div>
  );
};

export const ToastContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const messagesRef = useRef<ToastMessageProps[]>([]);
  const [, setRenderId] = useState<string>(uuidv4());

  useEffect(() => {
    let unmounted: boolean;
    const intervalId = setInterval(() => {
      if (unmounted) return;

      const messages = messagesRef.current;
      const newMessages = messages.filter(
        (m) => !m.isPersistent && (!m.expireAt || m.expireAt >= Date.now()),
      );

      if (newMessages.length === messages.length) return;

      messagesRef.current = newMessages;
      setRenderId(uuidv4());
    }, 1000);

    return () => {
      unmounted = true;
      clearInterval(intervalId);
    };
  }, []);

  const addMessage = useCallback((props: ToastMessageProps) => {
    const messages = messagesRef.current;
    messagesRef.current = [...messages, props];
    setRenderId(uuidv4());
  }, []);

  const addSuccessMessage = useCallback(
    (message: React.ReactNode, options: ToastMessageOptions = {}) => {
      const successToastId = uuidv4();
      addMessage({
        id: successToastId,
        children: (
          <ToastMessageContent
            title={options.title}
            id={successToastId}
            text={message}
            type="success"
            isPersistent={options.isPersistent}
          />
        ),
        type: "success",
        ...(!options.isPersistent && {
          expireAt: Date.now() + 3000, // 3 seconds from now
        }),
      });
    },
    [],
  );

  const addErrorMessage = useCallback(
    (message: React.ReactNode, options: ToastMessageOptions = {}) => {
      const errorToastId = uuidv4();
      addMessage({
        id: errorToastId,
        children: (
          <ToastMessageContent
            title={options.title}
            type="error"
            id={errorToastId}
            text={message}
            isPersistent={options.isPersistent}
          />
        ),
        type: "error",
        ...(!options.isPersistent && {
          expireAt: Date.now() + 3000, // 3 seconds from now
        }),
      });
    },
    [],
  );

  const removeMessage = useCallback((id: string) => {
    const messages = messagesRef.current;
    const match = messages.find((m) => m.id === id);
    if (!match) return;
    const index = messages.indexOf(match);
    const newMessages = messages.slice();
    newMessages.splice(index, 1);
    messagesRef.current = newMessages;
    setRenderId(uuidv4());
  }, []);

  const toasted = async ({
    promise,
    createMessage,
    successMessage,
    errorMessage,
    ErrorContent,
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    promise: Promise<any>;
    createMessage?: React.ReactNode;
    successMessage?: React.ReactNode;
    errorMessage?: React.ReactNode;
    ErrorContent?: React.FC<{ error: Error }>;
  }) => {
    const createToastId = uuidv4();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let result: any;

    try {
      if (createMessage) {
        addMessage({
          id: createToastId,
          children: (
            <ToastMessageContent id={createToastId} text={createMessage} />
          ),
        });
        result = await promise;

        removeMessage(createToastId);
      } else {
        result = await promise;
      }

      if (successMessage) {
        addSuccessMessage(successMessage);
      }
    } catch (e) {
      if (createMessage) {
        removeMessage(createToastId);
      }

      if (errorMessage || ErrorContent) {
        let text = errorMessage;

        if (ErrorContent) text = <ErrorContent error={e} />;

        addErrorMessage(text);
      }

      return Promise.reject(e);
    }

    return result;
  };

  const value = useMemo(
    () => ({
      messages: messagesRef.current,
      addMessage,
      addSuccessMessage,
      addErrorMessage,
      removeMessage,
      toasted,
    }),
    [addMessage, addSuccessMessage, addErrorMessage, removeMessage, toasted],
  );

  return createPortal(
    <ToastContext.Provider value={value}>
      {children}

      <div className="toast-messages" data-cypress-id="toast-messages">
        {messagesRef.current.map((message) => {
          return (
            <ToastMessage key={message.id} id={message.id} type={message.type}>
              {message.children}
            </ToastMessage>
          );
        })}
      </div>
    </ToastContext.Provider>,
    document.body,
  );
};

export const useToast = () => useContext(ToastContext);
