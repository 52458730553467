import { useEffect, useState } from "react";
import { Track } from "@highnote/server/src/core/entities";
import { highnote } from "@highnote/server/src/sdk";
import { StatefulComment } from "App/common/useSpaceComments";

export const useTrackComments = (track: Track) => {
  const [commentsLoading, setCommentsLoading] = useState<boolean>(true);
  const [fetchedComments, setFetchedComments] = useState<
    Record<string, StatefulComment[]>
  >({});

  useEffect(() => {
    let unmounted: boolean;

    if (!track.spaceId || typeof track.id === "undefined") {
      setCommentsLoading(false);
      return;
    }

    setCommentsLoading(true);
    const unsubscribeComments = highnote.watchComments({
      spaceId: track.spaceId,
      trackId: track.id,
      onChange: (_comments: StatefulComment[]) => {
        if (unmounted) return;
        setFetchedComments((prev) => {
          const newComments = { ...prev };

          _comments.forEach((comment) => {
            const trackVersionId = comment.trackVersionIds[0];

            if (!newComments[trackVersionId]) {
              newComments[trackVersionId] = [];
            }

            newComments[trackVersionId] = [
              ...newComments[trackVersionId],
              comment,
            ];
          });

          return newComments;
        });
        setCommentsLoading(false);
      },
    });

    return () => {
      unmounted = true;
      unsubscribeComments();
      setCommentsLoading(false);
    };
  }, [track.spaceId, track.id]);

  return { fetchedComments, commentsLoading };
};
