import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { highnote } from "@highnote/server/src/sdk";
import {
  ArrayRemove,
  ArrayUnion,
  FileEntity,
  Space,
  Track,
} from "@highnote/server/src/core/entities";
import { useToast } from "./useToast";
import { useAuth } from "App/components/Auth";
import { PERMISSION } from "@highnote/server/src/core/shared-util";
import { JOIN_ENTITY_TRIGGER } from "App/components/Auth/util";

type AttachmentsContextValue = {
  attachments: FileEntity[];
  refetchAttachments: () => void;
  isLoading: boolean;
  canEdit: boolean;
  attachFileToContext: (fileId: Id) => Promise<void>;
  detachFileFromContext: (fileId: Id) => void;
};

const AttachmentsContext = createContext<AttachmentsContextValue>({
  attachments: [],
  refetchAttachments: () => {},
  isLoading: true,
  canEdit: false,
  attachFileToContext: () => Promise.resolve(),
  detachFileFromContext: () => {},
});

export const AttachmentsContextProvider = ({
  attachmentIds,
  onChange,
  track,
  space,
  children,
}: {
  attachmentIds: Id[];
  onChange?: () => void;
  track?: Track;
  space?: Space;
  children: React.ReactNode;
}) => {
  const { isAllowed, joinEntity } = useAuth();
  const { toasted, addErrorMessage } = useToast();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [attachments, setAttachments] = useState<FileEntity[]>([]);
  const [renderTrigger, setRenderTrigger] = useState<string>();

  const canAddToSpace = isAllowed(PERMISSION.TO_ADD_ATTACHMENT_TO_SPACE, {
    space,
  });
  const canAddToTrack = isAllowed(
    track?.spaceId
      ? PERMISSION.TO_ADD_TO_TRACK_IN_SPACE
      : PERMISSION.TO_ADD_TO_TRACK,
    {
      track,
      space,
    },
  );

  useEffect(() => {
    let unmounted: boolean;

    if (!attachmentIds) {
      setAttachments([]);
      setLoading(false);
      return;
    }

    highnote
      .getFiles({ ids: attachmentIds })
      .then((_attachments: FileEntity[]) => {
        if (unmounted) return;
        setAttachments(_attachments.filter((a) => !!a));
        setLoading(false);
      });

    return () => {
      unmounted = true;
    };
  }, [attachmentIds, renderTrigger]);

  const refetchAttachments = useCallback(async () => {
    onChange && onChange();
    setRenderTrigger(uuidv4());
  }, [onChange]);

  const attachFileToContext = useCallback(
    async (fileId: Id) => {
      // eslint-disable-next-line no-async-promise-executor
      const fullPromise = new Promise(async (resolve, reject) => {
        if ((track && !canAddToTrack) || (space && !canAddToSpace)) {
          addErrorMessage("You do not have permission to add attachments.");
          return;
        }

        try {
          if (track) {
            await highnote.updateTrack({
              id: track.id,
              data: {
                files: new ArrayUnion([fileId]) as unknown as Id[],
              },
            });
          }

          if (space) {
            await highnote.updateSpace({
              id: space.id,
              data: {
                files: new ArrayUnion([fileId]) as unknown as Id[],
              },
            });
          }
        } catch (e) {
          reject(e);
        }

        joinEntity({
          entity: space,
          entityType: "Space",
          trigger: JOIN_ENTITY_TRIGGER.UPLOAD,
        });

        resolve(undefined);
      });

      await toasted({
        promise: fullPromise,
        errorMessage: "Could not attach file. Please try again.",
      });

      refetchAttachments();
    },
    [space?.id, track?.id, refetchAttachments],
  );

  const detachFileFromContext = useCallback(
    async (fileId: Id) => {
      // eslint-disable-next-line no-async-promise-executor
      const fullPromise = new Promise(async (resolve, reject) => {
        try {
          if (track) {
            await highnote.updateTrack({
              id: track.id,
              data: {
                files: new ArrayRemove([fileId]) as unknown as Id[],
              },
            });
          }

          if (space) {
            await highnote.updateSpace({
              id: space.id,
              data: {
                files: new ArrayRemove([fileId]) as unknown as Id[],
              },
            });
          }
        } catch (e) {
          reject(e);
        }

        resolve(undefined);
      });

      await toasted({
        promise: fullPromise,
        errorMessage: "Could not detach file. Please try again.",
      });

      refetchAttachments();
    },
    [space?.id, track?.id, refetchAttachments],
  );

  return (
    <AttachmentsContext.Provider
      value={{
        attachments,
        isLoading,
        refetchAttachments,
        attachFileToContext,
        detachFileFromContext,
        canEdit: track ? canAddToTrack : canAddToSpace,
      }}
    >
      {children}
    </AttachmentsContext.Provider>
  );
};

export const useAttachmentsContext = () => {
  const context = useContext(AttachmentsContext);

  if (!context) {
    throw new Error(
      "useAttachmentsContext must be used within a AttachmentsContextProvider",
    );
  }

  return context;
};
