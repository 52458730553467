import React from "react";
import classNames from "classnames";
import "./styles.scss";

const UntouchableArea = (props) => {
  const { className, ...remainingProps } = props;
  const combinedClassName = classNames(
    { UntouchableArea: window.isTouchDevice },
    className
  );

  return (
    <div className={combinedClassName} {...remainingProps}>
      {props.children}
    </div>
  );
};

export { UntouchableArea };
