import "./Dialog.scss";
import React from "react";
import { Dialog as MuiDialog, DialogProps } from "@mui/material";
import { ReactComponent as InfoSVG } from "App/common/icons/info.svg";
import { THEME, ThemeProvider } from "./ThemeProvider";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { ReactComponent as CloseSVG } from "App/common/icons/close.svg";
import { ReactComponent as CaretLeftSVG } from "App/common/icons/caret-left.svg";

export const DialogSection = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  >,
) => {
  // eslint-disable-next-line react/prop-types
  const { children, className, ...rest } = props;
  return (
    <section className={`highnote-dialog-section ${className || ""}`} {...rest}>
      {children}
    </section>
  );
};

export const DialogButtons = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >,
) => {
  // eslint-disable-next-line react/prop-types
  const { children, className, ...rest } = props;
  return (
    <div className={`highnote-dialog-buttons ${className || ""}`} {...rest}>
      {children}
    </div>
  );
};

export const DialogInfo = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >,
) => {
  const { children, ...rest } = props;
  return (
    <div className="highnote-dialog-info" {...rest}>
      <InfoSVG />
      {children}
    </div>
  );
};

export const Dialog = (
  props: Omit<DialogProps, "title"> & {
    title?: React.ReactNode | string;
    hideCloseButton?: boolean;
  },
) => {
  const { title, className, onClose, children, hideCloseButton, ...rest } =
    props;

  // TODO: Replace with actual app theme
  return (
    <ThemeProvider theme={THEME.DARK}>
      <MuiDialog
        {...rest}
        className={`highnote-dialog ${className || ""}`}
        data-cypress-id="highnote-dialog"
        data-theme={THEME.DARK}
        onClose={onClose}
      >
        {!!title && (
          <div className="title">
            <Button
              className="back"
              theme={BUTTON_THEME.SECONDARY}
              size={BUTTON_SIZE.LARGE}
              onClick={(e) => onClose && onClose(e, "escapeKeyDown")}
            >
              <CaretLeftSVG />
            </Button>
            {title}
            {!hideCloseButton && (
              <Button
                className="close"
                theme={BUTTON_THEME.ICON}
                size={BUTTON_SIZE.LARGE}
                onClick={(e) => onClose && onClose(e, "escapeKeyDown")}
                data-cypress-id="dialog-close-button"
              >
                <CloseSVG />
              </Button>
            )}
          </div>
        )}
        {children}
      </MuiDialog>
    </ThemeProvider>
  );
};
