import "./FileEditor.scss";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { Dialog, DialogButtons, DialogSection } from "App/common/Dialog";
import { TextInput } from "App/common/TextInput";
import { FileEntity } from "@highnote/server/src/core/entities";
import { FileSelect } from "App/common/FileSelect";
import { ErrorBoundary } from "App/common/ErrorBoundary";

import { Button, BUTTON_THEME } from "App/core/Button";
import { useFiles } from "./useFiles";
import { EntityTable, DEFAULT_COLUMNS } from "./EntityTable";
import { ENTITY_TYPE } from "./EntityTable/config";
import { useToast } from "App/common/useToast";
import { highnote } from "@highnote/server/src/sdk";

export enum FILE_EDITOR_FIELD {
  NAME = "NAME",
  ADD_FILE = "ADD_FILE",
  ADD_URL = "ADD_URL",
}

export const FileEditor = ({
  file,
  title = "Attachment",
  tempFile,
  onSave,
  onClose,
  fields,
}: {
  file: Partial<FileEntity>;
  title?: string;
  tempFile?: File;
  onSave?: (file: FileEntity) => void;
  onClose: () => void;
  fields?: FILE_EDITOR_FIELD[];
}) => {
  const { toasted } = useToast();
  const { uploadFile, uploadsById, removeUpload } = useFiles();
  const [editState, setEditState] = useState<Partial<FileEntity>>(
    file || { id: uuidv4() },
  );
  const [submitted, setSubmitted] = useState(false);
  const upload = uploadsById[editState.id];
  // Leaving this so it's easy uncommenting for local testing
  // const upload = {
  //   id: "123124",
  //   file: {
  //     fileName: "adfadf.awdf",
  //   },
  //   progress: 0.5,
  //   cancelUpload: () => {},
  // };

  const fileToUpload = useRef<File>();
  const formRef = useRef<HTMLFormElement>();

  const updateFile = (_file: File) => {
    fileToUpload.current = _file;
    setEditState({
      ...editState,
      url: null,
      fileName: _file?.name,
      fileType: _file?.type,
      name: _file?.name || editState.name || "",
    });
  };

  const updateUrl = (url: string) => {
    fileToUpload.current = undefined;
    setEditState({
      ...editState,
      url,
      fileName: null,
      fileType: null,
      storagePath: null,
    });
  };

  const updateName = (name: string) => {
    setEditState({ ...editState, name });
  };

  const cancelUpload = () => {
    if (upload) upload.cancelUpload();
    updateFile(null);
  };

  const onFileSelect = async (files: File[]) => {
    if (!files) {
      cancelUpload();
      return;
    }

    const selectedFile = files[0];
    updateFile(selectedFile);
  };

  useEffect(() => {
    if (!tempFile) return;
    onFileSelect([tempFile]);
  }, [tempFile]);

  const onCancel = useCallback(() => {
    setEditState(file || { name: "" });
    onClose();
  }, [file]);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    let fileEntity: FileEntity;
    setSubmitted(true);

    const submitForm = async () => {
      const fileId = editState.id;

      if (fileToUpload.current) {
        fileEntity = await uploadFile({
          file: fileToUpload.current,
          props: {
            id: fileId,
            name: editState.name,
            url: null,
          },
        });
      } else {
        fileEntity = await highnote.createOrUpdateFile({
          id: fileId,
          data: {
            name: editState.name,
            url: editState.url,
          },
        });
      }

      removeUpload(fileId);
    };

    try {
      await toasted({
        promise: submitForm(),
        errorMessage: "Could not save attachment. Please try again.",
      });

      onSave && onSave(fileEntity);
      onClose();
    } catch (e) {
      setSubmitted(false);
    }
  };

  const fileRows = useMemo(() => {
    if (upload) {
      return [
        {
          id: upload.file.id,
          entity: upload,
          type: ENTITY_TYPE.UPLOAD,
        },
      ];
    }

    if (editState.fileName) {
      return [
        {
          id: editState.id,
          entity: editState as FileEntity,
          type: ENTITY_TYPE.FILE,
          actions: [
            {
              name: "Remove",
              onClick: () => {
                if (upload) upload.cancelUpload();
                updateFile(null);
              },
            },
          ],
        },
      ];
    }

    return [];
  }, [editState, upload, updateFile]);
  const columns = [DEFAULT_COLUMNS.PREVIEW, DEFAULT_COLUMNS.ACTIONS];
  const showField = (fieldID: FILE_EDITOR_FIELD) => {
    if (!fields?.length) return true;
    return fields.includes(fieldID);
  };

  const isDisabled = !!upload || submitted;

  return (
    <ErrorBoundary name="FileEditor">
      <Dialog
        open
        onClose={onClose}
        className="highnote-file-editor"
        title={title}
      >
        <DialogSection />
        <form ref={formRef} onSubmit={onSubmit}>
          {showField(FILE_EDITOR_FIELD.NAME) && (
            <DialogSection>
              <h3>Name</h3>
              <TextInput
                isSingleLine
                value={editState.name || ""}
                required
                placeholder="New Attachment"
                isDisabled={isDisabled}
                onChange={updateName}
                onSubmit={() => formRef.current.requestSubmit()}
              />
            </DialogSection>
          )}

          {showField(FILE_EDITOR_FIELD.ADD_FILE) && (
            <DialogSection>
              <h3>Add an attachment</h3>
              {!upload && (
                <FileSelect onSelect={onFileSelect}>
                  <button>Upload a file</button>
                </FileSelect>
              )}
              <EntityTable rows={fileRows} columns={columns} />
            </DialogSection>
          )}

          {showField(FILE_EDITOR_FIELD.ADD_URL) && (
            <DialogSection>
              <h3>{editState.url ? "Add" : "Or add"} an external link</h3>
              <TextInput
                isSingleLine
                type="url"
                required={!editState.fileName}
                value={editState.url || ""}
                onChange={updateUrl}
                onSubmit={() => formRef.current.requestSubmit()}
                placeholder="https://www.my-attachment.com/12345.jpg"
              />
            </DialogSection>
          )}

          <DialogButtons>
            <Button
              type="button"
              theme={BUTTON_THEME.SECONDARY}
              disabled={isDisabled}
              className="cancel"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              theme={BUTTON_THEME.CTA}
              className="save"
              disabled={isDisabled}
              onSubmit={() => formRef.current.requestSubmit()}
            >
              Save
            </Button>
          </DialogButtons>
        </form>
      </Dialog>
    </ErrorBoundary>
  );
};
