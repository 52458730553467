import React from "react";
import { ReactComponent as LoaderSvg } from "App/common/icons/loader.svg";
import "./LoadingSpinner.scss";

export const LoadingSpinner = () => {
  return (
    <div className="LoadingSpinner">
      <LoaderSvg />
    </div>
  );
};
