import React from "react";
import { Dialog, DialogButtons, DialogSection } from "App/common/Dialog";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import "./PrivateInboxUploadSuccesfullyDialog.scss";
import {
  ConnectedUserAvatar,
  ConnectedUserName,
  USER_AVATAR_SIZE,
} from "App/common/UserAvatar/UserAvatar";
import { Link } from "react-router-dom";

interface PrivateInboxUploadSuccessfullyDialogProps {
  onClose?: () => void;
  isOpen: boolean;
  spaceOwner: string;
  redirectUrl: string;
}

export const PrivateInboxUploadSuccessfullyDialog = ({
  isOpen,
  onClose,
  spaceOwner,
  redirectUrl,
}: PrivateInboxUploadSuccessfullyDialogProps) => {
  const LinkWrapper = ({ children }: { children: React.ReactNode }) => {
    if (redirectUrl) {
      return <a href={redirectUrl}>{children}</a>;
    }
    return <Link to="/library">{children}</Link>;
  };

  return (
    <Dialog
      open={isOpen}
      className="PrivateInboxUploadSuccessfully-dialog"
      onClose={onClose}
    >
      <DialogSection className="PrivateInboxUploadSuccessfully-dialog-content">
        <ConnectedUserAvatar
          userId={spaceOwner}
          size={USER_AVATAR_SIZE.XXXLARGE}
        />
        <div className="PrivateInboxUploadSuccessfully-dialog-content-description">
          <h3>You’re all set</h3>
          <p>
            <ConnectedUserName userId={spaceOwner} /> has received your files.
          </p>
        </div>
      </DialogSection>
      <DialogButtons className="PrivateInboxUploadSuccessfully-dialog-buttons">
        <LinkWrapper>
          <Button size={BUTTON_SIZE.MEDIUM} theme={BUTTON_THEME.CTA}>
            Go Somewhere Else
          </Button>
        </LinkWrapper>
      </DialogButtons>
    </Dialog>
  );
};
