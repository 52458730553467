// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YOWWIfMz0ClSZRHeG6Jn{position:relative}", "",{"version":3,"sources":["webpack://./src/App/components/CustomIntercomLauncher/CustomIntercomLauncher.module.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA","sourcesContent":["@import \"App/styles/_variables.scss\";\n\n.root {\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "YOWWIfMz0ClSZRHeG6Jn"
};
export default ___CSS_LOADER_EXPORT___;
