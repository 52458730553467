import "./StickyDraggable.scss";
import React, { useState, useRef, useMemo } from "react";
import { throttle } from "lodash";
import Draggable, { DraggableEventHandler } from "react-draggable";

type Position = {
  x: number;
  y: number;
};

export type Stats = {
  x: number;
  y: number;
  xPercent: number;
  isOnTarget: boolean;
};

export const StickyDraggable = ({
  targetEl,
  onStart,
  onStop,
  onDrag,
  onClick,
  children,
  disabled,
}: {
  targetEl: HTMLElement;
  onDrag?: (props: Stats) => void;
  onStart?: (props: Stats) => void;
  onStop?: (props: Stats) => void;
  onClick?: (props: Stats) => void;
  children: React.ReactElement;
  disabled?: boolean;
}) => {
  const [isDragging, setDragging] = useState<boolean>(false);
  const [position, setPosition] = useState<Position>({ x: 0, y: 0 });
  const ref = useRef<HTMLDivElement>();
  const [selfRect, setSelfRect] = useState<DOMRect>();
  const [targetRect, setTargetRect] = useState<DOMRect>();

  const { minX, maxX, maxY } = useMemo(() => {
    const relativeMinX = targetRect?.left || 0;
    const relativeMaxX = (targetRect?.width || 0) + (targetRect?.left || 0);
    const relativeMaxY = targetRect?.top || 0;

    return {
      minX: relativeMinX - (selfRect?.left || 0),
      maxX: relativeMaxX - (selfRect?.left || 0),
      maxY: relativeMaxY - (selfRect?.top || 0),
    };
  }, [selfRect, targetRect]);

  const stats = useMemo(() => {
    if (!targetRect || !selfRect) {
      return { ...position, xPercent: 0, isOnTarget: false, isClick: false };
    }

    const relX = position.x + selfRect.left - targetRect.left;

    return {
      ...position,
      xPercent: targetRect.width ? relX / targetRect.width : 0,
      isOnTarget: position.y === maxY,
    };
  }, [position, selfRect, targetRect, minX, maxY]);

  const handleDrag: DraggableEventHandler = (e) => {
    if (!targetRect || !selfRect) return;
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);

    const touchEvent = e as TouchEvent;
    const event = touchEvent.touches
      ? touchEvent.touches[0]
      : (e as MouseEvent);

    const offsetX = selfRect.left;
    const offsetY = selfRect.top;
    const posX = event.clientX - offsetX;
    const posY = event.clientY - offsetY;
    const sanitizedX = Math.min(maxX, Math.max(posX, minX));
    const sanitizedY = Math.min(maxY, posY);

    setPosition({
      x: sanitizedX,
      y: sanitizedY,
    });

    onDrag && onDrag(stats);
  };

  return (
    <div className="highnote-sticky-draggable">
      <div className="static-render" ref={ref}>
        {children}
      </div>
      <Draggable
        position={position}
        onStart={() => {
          if (disabled) return;
          const _selfRect = ref.current?.getBoundingClientRect();
          setSelfRect(_selfRect);
          setTargetRect(targetEl?.getBoundingClientRect());
          setPosition({ x: 0, y: 0 });
          onStart && onStart(stats);
        }}
        onStop={() => {
          if (disabled) return;
          onStop && onStop(stats);

          if (stats.x === 0 && stats.y === 0) {
            onClick && onClick(stats);
          }

          setPosition({ x: 0, y: 0 });
          setDragging(false);
        }}
        onDrag={throttle((e, _position) => {
          if (disabled) return;
          handleDrag(e, _position);
          onDrag && onDrag(stats);
        }, 50)}
      >
        <div className="container handle-invisible">
          {React.cloneElement(children, {
            "data-cypress-id": "highnote-new-comment-button",
          })}
        </div>
      </Draggable>
      <div
        className="container handle-visible"
        data-is-dragging={isDragging}
        data-is-on-target={stats.isOnTarget}
        style={{
          transform: `translate3d(${position.x}px, ${position.y}px, 0)`,
        }}
      >
        <div className="inner">
          {children}
          {stats.isOnTarget && (
            <svg className="arrow" width="10" height="10" viewBox="0 0 10 10">
              <rect x="0" y="0" width="10" height="5" />
              <path d="M0,4.5 5,10 10,4.5" />
            </svg>
          )}
        </div>
      </div>
    </div>
  );
};
