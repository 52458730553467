import { TextInput } from "App/common/TextInput";
import { Button, BUTTON_THEME } from "App/core/Button";
import React, { useRef, useState } from "react";
import { highnote } from "@highnote/server/src/sdk";
import { useToast } from "App/common/useToast";
import { WithBackButton } from "./BackButton";
import { InputPhone } from "./InputPhone";

export const PhoneAsAuthMethod = ({
  identifier,
  setIdentifier,
  onSubmit,
}: {
  identifier: string;
  setIdentifier: (val: string) => void;
  onSubmit: (needsSignUp: boolean) => void;
}) => {
  const { toasted } = useToast();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const submitPhone = async () => {
    setSubmitting(true);
    try {
      await toasted({
        promise: highnote.sendVerificationCode({ phone: identifier }),
        successMessage: "Verification code sent!",
        errorMessage: "Verification code could not be sent. Please try again.",
      });

      const users = await highnote.searchUsers({ phone: identifier });
      const needsSignUp = (users || []).length < 1;
      onSubmit(needsSignUp);
    } catch {
      // empty
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <InputPhone
        value={identifier}
        onChange={setIdentifier}
        onSubmit={submitPhone}
      />
      <div className="spacer" />
      <Button
        type="button"
        loading={isSubmitting}
        style={{ width: "100%" }}
        theme={BUTTON_THEME.CTA}
        onClick={submitPhone}
      >
        Continue
      </Button>
    </>
  );
};

export const AuthByPhone = ({
  name,
  setName,
  identifier,
  password,
  setPassword,
  onBack,
  needsSignUp,
  isSubmitting,
  onSubmit,
  signUpCta,
}: {
  identifier: string;
  name: string;
  setName: (val: string) => void;
  password: string;
  setPassword: (val: string) => void;
  onBack: () => void;
  isSubmitting: boolean;
  needsSignUp: boolean;
  onSubmit: () => void;
  signUpCta?: string;
}) => {
  const nameInputRef = useRef<HTMLInputElement>();
  const passwordInputRef = useRef<HTMLInputElement>();

  return (
    <>
      {needsSignUp ? (
        <p className="direction">
          Create a display name and enter the verification code sent to{" "}
          <strong style={{ display: "inline-block" }}>{identifier}</strong>.
        </p>
      ) : (
        <p className="direction">
          Enter the verification code sent to{" "}
          <strong style={{ display: "inline-block" }}>{identifier}</strong>.
        </p>
      )}

      {needsSignUp && (
        <>
          <TextInput
            type="text"
            placeholder="Display Name"
            isSingleLine
            value={name}
            onChange={setName}
            inputRef={nameInputRef}
            onSubmit={() => passwordInputRef.current?.focus()}
          />
          <div className="spacer" />
        </>
      )}

      <TextInput
        type="text"
        placeholder="Verification Code"
        isSingleLine
        value={password}
        onChange={setPassword}
        inputRef={passwordInputRef}
        onSubmit={onSubmit}
        required
      />
      <div className="spacer" />
      <WithBackButton onBack={onBack}>
        <Button
          loading={isSubmitting}
          disabled={!password}
          type="button"
          theme={BUTTON_THEME.CTA}
          onClick={onSubmit}
        >
          {needsSignUp ? signUpCta || "Sign Up" : "Log In"}
        </Button>
      </WithBackButton>
    </>
  );
};
