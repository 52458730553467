import React from "react";
import ReactDOM from "react-dom";
import { useDAW, useDAWTrack, daw } from ".";

// copied from @highnote/server/src/core/shared-util
export const formatDuration = (time: number, roundUp?: boolean) => {
  const _min = Math.floor(time / 60);
  let _sec = Math.floor(time % 60);

  if (roundUp && _min === 0 && _sec === 0) {
    // EDGE CASE!
    // If the duration is teeny tiny (less than 1 sec)
    // Round up to 1 second.
    if (time > 0) _sec = 1;
  }

  const min = isNaN(_min) ? 0 : _min;
  const sec = isNaN(_sec) ? 0 : _sec;
  const secString = sec < 10 ? `0${sec}` : String(sec);
  return `${min}:${secString}`;
};

const Track = ({ id }: { id: string }) => {
  const track = useDAWTrack(id);

  if (!track) {
    return null;
  }

  const trackVolume = daw.getTrackVolume(id);

  return (
    <div className="track">
      <div className="labels">
        <label>
          {formatDuration(daw.getTrackTime(id))}
          {" / "}
          {formatDuration(track.duration, true)}
          {" (+"}
          {formatDuration(track.startTime)}
          {")"}
        </label>

        <label>{track.id}</label>
        {track.error ? (
          <label>⚠️ {String(track.error)}</label>
        ) : (
          <>
            <label>{(track.loadProgress * 100).toFixed(1)}%</label>
            <label>{trackVolume > 0 ? "🔊" : "🔇"}</label>
            <label style={{ fontSize: "8px" }}>
              source: {track.source.split("?")[0]}
            </label>
          </>
        )}
      </div>

      <div
        className="bar"
        style={{
          width: `${(track.duration / daw.totalDuration) * 100}%`,
          left: `${(track.startTime / daw.totalDuration) * 100}%`,
        }}
      >
        <div
          className="progress"
          style={{
            left: `${(daw.getTrackTime(id) / track.duration) * 100}%`,
          }}
        ></div>
      </div>
    </div>
  );
};

const VisualDAW = () => {
  useDAW();

  return (
    <div>
      <h2>Visual DAW</h2>
      <div className="daw">
        <div>{daw.state.isPlaying ? "Playing" : "Paused"}</div>
        <div>{daw.state.isLoading ? "Loading..." : ""}</div>

        <div>
          {formatDuration(daw.state.currentTime)} /{" "}
          {formatDuration(daw.totalDuration, true)}
        </div>
        <div
          className="marker"
          style={{
            left: `${(daw.state.currentTime / daw.totalDuration) * 100}%`,
          }}
        />
        <div>
          {daw.state.tracks.map((track) => (
            <Track key={track.id} id={track.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export const showVisualDAW = () => {
  import("./visual-daw.scss").then(() => {
    const containerEl = document.createElement("div");
    containerEl.id = "visual-daw";
    document.body.appendChild(containerEl);
    ReactDOM.render(<VisualDAW />, containerEl);
  });
};
