import "./Library.scss";
import { UserEntity } from "@highnote/server/src/core/entities";
import { RowConfig } from "App/common/BaseTable";
import { ENTITY_TYPE } from "App/components/EntityTable/config";

export enum TAB_ID {
  HOME = "Home",
  SPACES = "Spaces",
  TRACKS = "Tracks",
}

export type FILTER_TYPE = {
  predicate: (row: RowConfig, user: UserEntity) => boolean;
  name?: string;
  ignoreRootPredicate?: boolean;
  subFilters?: FILTER_TYPE[];
};

export const FILTERS: { [key: string]: FILTER_TYPE } = {
  [TAB_ID.HOME]: {
    predicate: (row) =>
      row.type === ENTITY_TYPE.SPACE && !row.entity.isArchived,
    name: "Home",
  },
  [TAB_ID.SPACES]: {
    predicate: (row) =>
      row.type === ENTITY_TYPE.SPACE && !row.entity?.isArchived,
    name: "Spaces",
    subFilters: [
      {
        predicate: (row, user) => row.entity?.createdBy === user.id,
        name: "Owned by Me",
      },
      {
        predicate: (row, user) => row.entity?.createdBy !== user.id,
        name: "Shared with Me",
      },
      {
        predicate: (row) =>
          row.type === ENTITY_TYPE.SPACE && row.entity?.isArchived,
        ignoreRootPredicate: true,
        name: "Archived",
      },
    ],
  },
  [TAB_ID.TRACKS]: {
    // We can currently restrict uploads so we know they are tracks
    predicate: (row) =>
      row.type === ENTITY_TYPE.TRACK || row.type === ENTITY_TYPE.UPLOAD,
    name: "Tracks",
  },
};

export enum SORT_TYPE {
  ALPHABETICAL = "Alphabetical",
  CREATED_AT = "Recently Created",
  CUSTOM = "Custom Order",
}
