import { History } from "history";
import { SIDEBAR_ID, SIDEBAR_IDS } from "./types";

export const isSidebarUrl: (id?: SIDEBAR_ID) => boolean = (id) => {
  if (!id) {
    return SIDEBAR_IDS.some((sidebarId) => isSidebarUrl(sidebarId));
  }

  const query = new URLSearchParams(location.search);
  return query.has(id);
};

export const setSidebarUrl = (sidebarId: SIDEBAR_ID, history: History) => {
  const searchParams = new URLSearchParams(location.search);
  searchParams.set(sidebarId, "");
  history.push(`${location.pathname}?${searchParams.toString()}`);
};

export const unsetSidebarUrl = (history: History) => {
  const searchParams = new URLSearchParams(location.search);

  // Remove only sidebar-related parameters
  SIDEBAR_IDS.forEach((sidebarId) => {
    searchParams.delete(sidebarId);
  });

  const search = searchParams.toString();
  history.push(`${location.pathname}${search ? `?${search}` : ""}`);
};
