import React from "react";
import classNames from "classnames";
import styles from "./Skeleton.module.scss";

type Props = {
  width?: string | number;
  height?: string | number;
  lines?: number;
  borderRadius?: string | number;
  shape?: "rectangle" | "circle" | "text";
  className?: string;
  hideAnimation?: boolean;
};

export const Skeleton = ({
  width,
  height,
  borderRadius,
  lines = 1,
  shape = "rectangle",
  hideAnimation = false,
  className,
}: Props) => {
  const _styles = {
    width: typeof width === "number" ? `${width}px` : width,
    height:
      shape === "circle"
        ? width
        : typeof height === "number"
          ? `${height * lines}px`
          : height,
    borderRadius: shape === "circle" ? "50%" : borderRadius,
  };

  return (
    <div
      className={classNames(
        styles.skeleton,
        {
          [styles["skeleton-animation"]]: !hideAnimation,
        },
        className,
      )}
      style={_styles}
      aria-hidden="true"
    />
  );
};
