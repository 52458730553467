import React, { Component } from "react";
import classNames from "classnames";

import { isAndroid } from "App/modules/utils";
import { UntouchableArea } from "../TouchableArea/TouchableArea";

import "./styles.scss";

const APP_ROOT_EL = document.getElementById("APP_ROOT_EL");

const VIEWPORT_METATAG_EL = document.querySelector("meta[name=viewport]");

const INITIAL_VIEWPORT_METATAG_CONTENT =
  VIEWPORT_METATAG_EL.getAttribute("content");

const HTML_STYLE = `
width: 100%;
height: calc(100% + env(safe-area-inset-top));
`;

const BODY_STYLE = `
width: 100%;
height: 100%;
overflow: hidden;
background-color: #0c0c0e;
`;

const APP_ROOT_EL_STYLE = `
width: 100%;
height: 100%;
position: fixed;
inset: 0;
padding-right: env(safe-area-inset-right, 0);
padding-bottom: env(safe-area-inset-bottom, 0);
padding-left: env(safe-area-inset-left, 0);
 `;

class AppWrapper extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.embedded) return;

    // this.animationFrameDidMount();
    this.initAppWrapperStyle();
  }

  animationFrameDidMount = () => {
    // if (!this.frameUpdating) {
    //   this.frameUpdating = window.requestAnimationFrame(
    //     this.animationFrameDidUpdate
    //   );
    // }
  };

  animationFrameDidUpdate = () => {
    this.android_monitorActiveElement();
    // this.frameUpdating = window.requestAnimationFrame(
    //   this.animationFrameDidUpdate
    // );
  };

  componentWillUnmount() {
    if (this.props.embedded) return;

    this.animationFrameWillUnmount();
    this.unmountAppWrapperStyle();
  }

  animationFrameWillUnmount = () => {
    window.cancelAnimationFrame(this.frameUpdating);
  };

  initAppWrapperStyle = () => {
    /*
     * iOS is notorious for its rubber band effect
     * in WebViews, setting overflow to hidden on HTML and BODY
     * prevent this and create more of a native effect.
     * */

    APP_ROOT_EL.style.cssText = APP_ROOT_EL_STYLE;
    document.querySelector("html").style.cssText = HTML_STYLE;
    document.querySelector("body").style.cssText = BODY_STYLE;
  };

  unmountAppWrapperStyle = () => {
    document.querySelector("html").style.cssText = "";
    document.querySelector("body").style.cssText = "";
    document.querySelector("#APP_ROOT_EL").style.cssText = "";
  };

  android_monitorActiveElement = () => {
    /*
     * When the soft keyboard appears on Android devices,
     * the entire viewport is resized which can have a
     * disruptive visual effect. Here, we watch out
     * for a focused text element, set the viewport
     * to a fixed height, and ensure the window is scrollable.
     * */

    if (!isAndroid()) return;

    const activeElement = document.activeElement;
    const activeElementIsTextInput = ["INPUT", "TEXTAREA"].includes(
      activeElement.nodeName,
    );
    const windowIsScrollable =
      document.documentElement.style.overflow !== "hidden";

    const shouldMakeWindowScrollable =
      activeElement && activeElementIsTextInput && !windowIsScrollable;

    const shouldMakeWindowNonScrollable = !activeElement && windowIsScrollable;

    if (shouldMakeWindowScrollable) {
      VIEWPORT_METATAG_EL.setAttribute(
        "content",
        `${INITIAL_VIEWPORT_METATAG_CONTENT}, height=${window.innerHeight}px`,
      );

      document.documentElement.style.overflow = "auto";
    } else if (shouldMakeWindowNonScrollable) {
      VIEWPORT_METATAG_EL.setAttribute(
        "content",
        INITIAL_VIEWPORT_METATAG_CONTENT,
      );

      document.documentElement.style.overflow = "hidden";
    }
  };

  render() {
    const { className, ...remainingProps } = this.props;

    const combinedClassName = classNames(className, "AppWrapper");

    return (
      <UntouchableArea style={{ width: "100%", height: "100%" }}>
        <div {...remainingProps} className={combinedClassName}>
          {this.props.children}
        </div>
      </UntouchableArea>
    );
  }
}

export default AppWrapper;
