import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { Divider } from "@mui/material";
import { Comment, PUBLIC_ID } from "@highnote/server/src/core/entities";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { MenuItem } from "App/common/Menu";
import { Select } from "App/common/Select";
import { THEME, ThemeProvider } from "App/common/ThemeProvider";
import {
  ConnectedUserAvatar,
  ConnectedUserName,
  USER_AVATAR_SIZE,
} from "App/common/UserAvatar/UserAvatar";
import { UserAvatarGroup } from "App/common/UserAvatar/UserAvatarGroup";
import { ReactComponent as CheckSVG } from "App/common/icons/check.svg";
import { ReactComponent as FilterSVG } from "App/common/icons-v2/filter.svg";
import { useSpaceContext } from "App/common/useSpace";
import {
  CommentsFilterFn,
  useSpaceCommentsContext,
} from "App/common/useSpaceComments";
import { BUTTON_THEME, Button } from "App/core/Button";
import { useTrack } from "./useEntities/useTrack";
import "./SpaceTools.scss";
import { isShareId } from "@highnote/server/src/core/shared-util";
import { useViewport } from "App/common/useViewport";

const USER_FILTER_PREFIX = "user|";

type FilterConfig = {
  id: string;
  label: string | React.ReactNode;
  filter: CommentsFilterFn;
};

enum FILTER_OPTION {
  SHOW_UNRESOLVED = "show-unresolved",
  SHOW_RESOLVED = "show-resolved",
  SHOW_ALL_STATES = "show-all-states",
}

const FILTER_OPTIONS: Record<string, FilterConfig> = {
  [FILTER_OPTION.SHOW_UNRESOLVED]: {
    id: FILTER_OPTION.SHOW_UNRESOLVED,
    label: "Show Unresolved",
    filter: (comment) => !comment.isResolved,
  },
  [FILTER_OPTION.SHOW_RESOLVED]: {
    id: FILTER_OPTION.SHOW_RESOLVED,
    label: "Show Resolved",
    filter: (comment) => !!comment.isResolved,
  },
  [FILTER_OPTION.SHOW_ALL_STATES]: {
    id: FILTER_OPTION.SHOW_ALL_STATES,
    label: "Show All",
    filter: () => true,
  },
};

const CLEAR_ALL = "clear-all";

type Commenter = {
  id: Id;
  alias?: string;
  lastCreatedAt: number;
  numComments: number;
};

export const CommentFilters = () => {
  const { track, trackLoading, currentTrackVersion } = useTrack();
  const { comments, setFilters, filteredComments } = useSpaceCommentsContext();
  const [filterIds, setFilterIds] = useState<string[]>([
    FILTER_OPTION.SHOW_UNRESOLVED,
  ]);
  const [userIds, setUserIds] = useState<string[]>([]);
  const { space } = useSpaceContext();

  const commenters = useMemo(() => {
    const commentersById: Record<Id, Commenter> = {};

    comments
      .sort((a: Comment, b: Comment) => b.createdAt - a.createdAt)
      .forEach((comment: Comment) => {
        if (commentersById[comment.createdBy]) {
          commentersById[comment.createdBy].numComments += 1;
          return;
        }

        commentersById[comment.createdBy] = {
          id: comment.createdBy,
          alias: comment.createdByAlias,
          lastCreatedAt: comment.createdAt,
          numComments: 0,
        };
      });

    return Object.values(commentersById);
  }, [comments]);

  useEffect(() => {
    const allFilters = [];
    const stateFilters = filterIds.map((id) => FILTER_OPTIONS[id]);
    const stateFilter = stateFilters[0];

    if (stateFilter) {
      allFilters.push(stateFilter.filter);
    }

    const userFilter = (c: Comment) => {
      if (userIds.length === 0) return true;
      return userIds.includes(c.createdBy);
    };
    allFilters.push(userFilter);

    setFilters(allFilters);
  }, [filterIds, userIds]);

  const visibleComments = useMemo(
    () =>
      filteredComments.filter(
        (c) =>
          !c.parentComment &&
          c.trackId === track?.id &&
          c.trackVersionIds?.includes(currentTrackVersion?.id),
      ),
    [filteredComments, currentTrackVersion?.id, track?.id],
  );

  const selectedFilters = useMemo(() => {
    return [...filterIds, ...userIds.map((id) => `${USER_FILTER_PREFIX}${id}`)];
  }, [filterIds, userIds]);

  if (!space) return null;

  if (!track && !trackLoading) return null;

  return (
    <ErrorBoundary name="SpaceFilters">
      <ThemeProvider theme={THEME.DARK}>
        <div
          className="highnote-space-filters"
          data-is-active={
            selectedFilters.filter((f) => f !== FILTER_OPTION.SHOW_UNRESOLVED)
              .length > 0
          }
        >
          <Select
            className="highnote-space-filters-select"
            MenuProps={{
              className: "highnote-space-filters-menu",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            }}
            multiple
            size="x-small"
            value={selectedFilters}
            endAdornment={<FilterSVG />}
            renderValue={() => {
              return (
                <div className="filter-summary">
                  <p>
                    <span className="count">{visibleComments.length}</span>{" "}
                    <span className="label">
                      Comment{visibleComments.length === 1 ? "" : "s"}
                    </span>
                  </p>
                </div>
              );
            }}
            onChange={(e) => {
              const requestedIds = e.target.value as string[];

              if (requestedIds.includes(CLEAR_ALL)) {
                setFilterIds([FILTER_OPTION.SHOW_UNRESOLVED]);
                setUserIds([]);
                return;
              }

              let newFilterIds = [...requestedIds]
                .reverse()
                .filter((id) => !!FILTER_OPTIONS[id]);

              if (newFilterIds.length > 0) {
                newFilterIds = [newFilterIds[0]];
              } else {
                newFilterIds = [FILTER_OPTION.SHOW_UNRESOLVED];
              }

              setFilterIds(newFilterIds);

              const newUserIds = requestedIds
                .filter((id) => id.includes(USER_FILTER_PREFIX))
                .map((id) => id.split(USER_FILTER_PREFIX)[1]);

              setUserIds(newUserIds);
            }}
          >
            <MenuItem disabled>Filter By State</MenuItem>
            {Object.values(FILTER_OPTIONS).map((config) => {
              const selected = filterIds.includes(config.id);
              return (
                <MenuItem key={config.id} value={config.id}>
                  {config.label}
                  {selected && <CheckSVG />}
                </MenuItem>
              );
            })}

            <Divider />
            <MenuItem disabled>Filter By Commenter</MenuItem>
            {commenters.map((c: Commenter) => {
              const id = `${USER_FILTER_PREFIX}${c.id}`;
              const selected = userIds.includes(c.id);
              return (
                <MenuItem key={id} value={id}>
                  <ConnectedUserAvatar
                    userId={c.id}
                    alias={c.alias}
                    size={USER_AVATAR_SIZE.SMALL}
                  ></ConnectedUserAvatar>
                  <div className="user-info">
                    <ConnectedUserName userId={c.id} alias={c.alias} />
                    <span>{` • ${c.numComments}`}</span>
                  </div>
                  {selected && <CheckSVG />}
                </MenuItem>
              );
            })}

            <Divider />
            <MenuItem
              style={{ color: "red", paddingBottom: "10px" }}
              value={CLEAR_ALL}
            >
              Clear All
            </MenuItem>
          </Select>
        </div>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export const SpaceMemberPreview = ({ onClick }: { onClick?: () => void }) => {
  const { space } = useSpaceContext();
  const { isMobile } = useViewport();

  const users = useMemo(() => {
    if (!space) return [];
    return [...Object.keys(space.rolesV2 || {}), space.createdBy]
      .filter((userId) => userId !== PUBLIC_ID && !isShareId(userId))
      .map((userId) => ({ id: userId }));
  }, [space]);

  if (!space) return null;

  return (
    <ErrorBoundary name="SpaceShare">
      <ThemeProvider theme={THEME.DARK}>
        <Button
          theme={BUTTON_THEME.PLAIN}
          className={classNames(
            "highnote-space-members top-right-toolbar-button",
            "highnote-space-members-button",
          )}
          {...(onClick && { onClick })}
        >
          <UserAvatarGroup
            size={USER_AVATAR_SIZE.MEDIUM}
            users={users}
            showMinimalVersion={isMobile}
          />
        </Button>
      </ThemeProvider>
    </ErrorBoundary>
  );
};
