import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { QueryConstraint, limit as firestoreLimit } from "firebase/firestore";
import { highnote } from "@highnote/server/src/sdk";
import {
  COLLECTION_ID,
  UserEntity,
  Space,
  Track,
  DownloadRequest,
  DropboxSyncJob,
} from "@highnote/server/src/core/entities";
import { ENTITY_NOT_FOUND } from "App/common/util/ERRORS";
import { v4 as uuidv4 } from "uuid";

const generateEntitiesWatcher = <EntityType,>(collectionId: COLLECTION_ID) => {
  type EntitiesContextValue = {
    entities: EntityType[];
    loading: boolean;
    error: string;
    totalLimit: number;
    loadMore: () => void;
  };

  const EntitiesContext = createContext<EntitiesContextValue>({
    entities: [],
    loading: true,
    error: undefined,
    totalLimit: 0,
    loadMore: () => undefined,
  });

  const EntitiesContextProvider = ({
    constraints,
    limit = 0,
    children,
  }: {
    constraints: QueryConstraint[];
    limit?: number;
    children: React.ReactNode;
  }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [entities, setEntities] = useState<EntityType[]>([]);
    const [error, setError] = useState<string>();
    const [totalLimit, setTotalLimit] = useState<number>(limit);
    const [retryTrigger, setRetryTrigger] = useState<string>();
    const unmountedRef = useRef<boolean>(false);

    useEffect(
      () => () => {
        unmountedRef.current = true;
      },
      [],
    );

    useEffect(() => {
      if (!constraints || constraints.length < 1) {
        setLoading(false);
        setEntities([]);
        setError(undefined);
        return;
      }

      setLoading(true);
      const unsubscribe = highnote.watchEntities({
        collectionId,
        constraints: totalLimit
          ? [...constraints, firestoreLimit(totalLimit)]
          : constraints,
        onChange: (_entities: EntityType[]) => {
          if (unmountedRef.current) return;
          setError(undefined);
          setEntities(_entities);
          setLoading(false);
        },
        onError: () => {
          setEntities([]);
          setLoading(false);
          setError(ENTITY_NOT_FOUND);

          // Retry after 5 seconds.
          setTimeout(() => {
            setRetryTrigger(uuidv4());
          }, 5000);
        },
      });

      return () => {
        unsubscribe();
      };
    }, [collectionId, constraints, totalLimit, retryTrigger]);

    const value = useMemo(
      () => ({
        entities,
        loading,
        error,
        totalLimit,
        loadMore: () => setTotalLimit(totalLimit + limit),
      }),
      [entities, loading, error, totalLimit],
    );

    return (
      <EntitiesContext.Provider value={value}>
        {children}
      </EntitiesContext.Provider>
    );
  };

  const useEntitiesContext = () => useContext(EntitiesContext);

  return {
    EntitiesContextProvider,
    useEntitiesContext,
  };
};

const generateEntityWatcher = <EntityType,>(collectionId: COLLECTION_ID) => {
  type EntityContextValue = {
    entity: EntityType;
    loading: boolean;
  };

  const EntityContext = createContext<EntityContextValue>({
    entity: undefined,
    loading: true,
  });

  const EntityContextProvider = ({
    id,
    children,
  }: {
    id: string;
    children: React.ReactNode;
  }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [entity, setEntity] = useState<EntityType>();
    const [retryTrigger, setRetryTrigger] = useState<string>();
    const retryCountRef = useRef<number>(0);
    const unmountedRef = useRef<boolean>(false);

    useEffect(() => {
      return () => {
        unmountedRef.current = true;
      };
    }, []);

    useEffect(() => {
      setLoading(true);

      if (!id) {
        setEntity(undefined);
        setLoading(false);
        return;
      }

      let timeout: number;
      const unsubscribe = highnote.watchEntity({
        entityId: id,
        collectionId,
        onChange: (_entity: EntityType) => {
          if (unmountedRef.current) return;
          setEntity(_entity);
          setLoading(false);
        },
        onError: () => {
          if (unmountedRef.current) return;

          // Only set the error if we've already missed this twice.
          if (retryCountRef.current > 2) {
            setEntity(undefined);
            setLoading(false);
          }

          // Retry after 5 seconds.
          timeout = window.setTimeout(() => {
            if (unmountedRef.current) return;
            retryCountRef.current += 1;
            setRetryTrigger(uuidv4());
          }, 5000);
        },
      });

      return () => {
        unsubscribe();
        clearTimeout(timeout);
      };
    }, [collectionId, id, retryTrigger]);

    const value = useMemo(() => {
      return {
        entity,
        loading,
      };
    }, [entity, loading]);

    return (
      <EntityContext.Provider value={value}>{children}</EntityContext.Provider>
    );
  };

  const useEntityContext = () => useContext(EntityContext);

  return {
    EntityContextProvider,
    useEntityContext,
  };
};

// The query properties supported for Tracks are:
// - `spaceId`
// - `createdBy`
// - `id`
// To add new query properties, you'll need to amend firestore.rules.
export const {
  EntitiesContextProvider: TracksProvider,
  useEntitiesContext: useTracks,
} = generateEntitiesWatcher<Track>(COLLECTION_ID.TRACK);

export const {
  EntityContextProvider: UserProviderRaw,
  useEntityContext: useUserRaw,
} = generateEntityWatcher<UserEntity>(COLLECTION_ID.USER);

export const {
  EntitiesContextProvider: DownloadRequestsProvider,
  useEntitiesContext: useDownloadRequests,
} = generateEntitiesWatcher<DownloadRequest>(COLLECTION_ID.DOWNLOAD_REQUEST);

export const {
  EntityContextProvider: PublicUserProviderRaw,
  useEntityContext: usePublicUserRaw,
} = generateEntityWatcher<UserEntity>(COLLECTION_ID.PUBLIC_USER);

export const {
  EntityContextProvider: TrackProviderRaw,
  useEntityContext: useTrackRaw,
} = generateEntityWatcher<Track>(COLLECTION_ID.TRACK);

export const {
  EntitiesContextProvider: SpacesProvider,
  useEntitiesContext: useSpaces,
} = generateEntitiesWatcher<Space>(COLLECTION_ID.SPACE);

export const {
  EntitiesContextProvider: DropboxJobsProvider,
  useEntitiesContext: useDropboxJobs,
} = generateEntitiesWatcher<DropboxSyncJob>(COLLECTION_ID.DROPBOX_JOBS);

export const {
  EntityContextProvider: SpaceProviderRaw,
  useEntityContext: useSpaceRaw,
} = generateEntityWatcher<Space>(COLLECTION_ID.SPACE);
