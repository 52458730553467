import { useMemo } from "react";
import {
  routePaths,
  FROM_MOBILE_APP_FLAG,
  SHOULD_REDIRECT_TO_MOBILE_FLAG,
  WEB_APP_COMPONENT_FLAG,
  MOBILE_RETURN_TO_ON_ERR_FLAG,
} from "App/modules/urls";
import { matchPath, useLocation } from "react-router";

export const useUrlContext = () => {
  useLocation();

  const matchSpacePage = matchPath(location.pathname, {
    path: routePaths.space,
    exact: true,
    strict: false,
  });

  const matchSettingsPage = matchPath(location.pathname, {
    path: routePaths.settings,
    exact: true,
    strict: false,
  });

  const params: {
    spaceId?: string;
    trackId?: string;
    trackVersionId?: string;
    settingsTabId?: string;
  } = {
    ...(matchSpacePage?.params || {}),
    ...(matchSettingsPage?.params || {}),
  };

  const query = new URLSearchParams(location.search);
  const commentId = query.get("commentId") || undefined;
  const versionId = query.get("versionId") || undefined;
  const shareKey = query.get("shareKey") || undefined;
  const shouldRedirectToMobile =
    query.get(SHOULD_REDIRECT_TO_MOBILE_FLAG) || undefined;
  const fromMobileApp = query.get(FROM_MOBILE_APP_FLAG) || undefined;
  const webAppComponent = query.get(WEB_APP_COMPONENT_FLAG) || undefined;
  const returnTo = query.get("returnTo") || undefined;
  const returnToOnError = query.get(MOBILE_RETURN_TO_ON_ERR_FLAG) || undefined;

  const { spaceId, trackId, settingsTabId } = params;

  const value = useMemo(
    () => ({
      spaceId,
      trackId,
      versionId,
      versionIdPath: params.trackVersionId,
      commentId,
      settingsTabId,
      shareKey,
      shouldRedirectToMobile: shouldRedirectToMobile === "true",
      fromMobileApp,
      returnTo,
      webAppComponent,
      returnToOnError,
    }),
    [
      spaceId,
      trackId,
      versionId,
      commentId,
      settingsTabId,
      shareKey,
      params.trackVersionId,
      webAppComponent,
      shouldRedirectToMobile,
      fromMobileApp,
      returnTo,
      returnToOnError,
    ],
  );

  return value;
};
