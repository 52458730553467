import "./Attachments.scss";
import React, { useCallback, useMemo, useRef } from "react";
import { ReactComponent as IllustratedFilesSVG } from "App/common/icons/illustrated-files.svg";
import { ReactComponent as InfoSVG } from "App/common/icons/info.svg";
import { ListContainer } from "App/common/List";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { Action, ActionDivider, ActionInfo } from "App/common/Actions";
import { useAttachmentsContext } from "App/common/useAttachments";
import { AddButton } from "App/common/Button/AddButton";
import { Button, BUTTON_THEME } from "../core/Button";
import { PermissionTooltip } from "./PermissionTooltip";
import { EmptyContainer } from "App/core/EmptyContainer";
import { LoadingSpinner } from "App/common/icons/LoadingSpinner";
import { FileDrop } from "App/common/FileDrop";
import { getAuthId } from "App/components/Auth";

import { ConnectedUserName } from "App/common/UserAvatar/UserAvatar";
import { EntityTable } from "App/components/EntityTable";
import {
  ENTITY_TYPE,
  DEFAULT_COLUMNS,
  EntityRowConfig,
} from "App/components/EntityTable/config";
import { EmptyTable } from "./EntityTable/EmptyTable";
import { useFileEditor } from "./useFileEditor";
import { FileEntity } from "@highnote/server/src/core/entities";
import { useFileDownload } from "App/common/useFileDownload";

export const Attachments = ({
  sortFn,
  showSpecialEmptyState,
  hideLegacyAddButton,
  hideEmptyContainer,
  showFileDrop,
  onDragEnd,
  showDragHandle,
  uploads = [],
}: {
  sortFn?: (a: FileEntity, b: FileEntity) => number;
  showSpecialEmptyState?: boolean;
  hideLegacyAddButton?: boolean;
  hideEmptyContainer?: boolean;
  showFileDrop?: boolean;
  onDragEnd?: (newRowOrder: EntityRowConfig[]) => void;
  showDragHandle?: boolean;
  uploads?: EntityRowConfig[];
}) => {
  const { openFileEditor } = useFileEditor();
  const { downloadFile } = useFileDownload();
  const {
    attachments,
    isLoading,
    refetchAttachments,
    canEdit,
    attachFileToContext,
    detachFileFromContext,
  } = useAttachmentsContext();
  const dragRef = useRef<HTMLDivElement>();

  const columns = useMemo(() => {
    return [
      DEFAULT_COLUMNS.ICON,
      DEFAULT_COLUMNS.PREVIEW,
      DEFAULT_COLUMNS.SHORTCUT,
      DEFAULT_COLUMNS.ACTIONS,
    ];
  }, []);

  const authId = getAuthId();
  const fileRows = useMemo(() => {
    const attachmentRows = attachments.sort(sortFn).map((file) => {
      const isOwner = file.createdBy === authId;

      let actions: Action[] = [];

      if (isOwner) {
        actions = [
          {
            name: "Edit",
            onClick: () => {
              openFileEditor({ file, onSave: refetchAttachments });
            },
          },
          {
            name: "Remove",
            onClick: () => {
              detachFileFromContext(file.id);
            },
          },
        ];
      } else if (canEdit) {
        actions = [
          {
            name: "Remove",
            onClick: () => {
              detachFileFromContext(file.id);
            },
          },
          ActionDivider,
          {
            Component: () => (
              <ActionInfo>
                <InfoSVG /> Added by&nbsp;
                <ConnectedUserName userId={file.createdBy} />
              </ActionInfo>
            ),
            disabled: true,
          },
        ];
      }

      const row: EntityRowConfig = {
        key: file.id,
        id: file.id,
        entity: file,
        type: ENTITY_TYPE.FILE,
        actions,
        createdAt: file.createdAt,
        name: file.fileName,
      };

      if (file.url) {
        row.onClick = () => window.open(file.url, "_blank");
      } else if (file.storagePath && canEdit) {
        row.onClick = () => downloadFile(file.id);
      }

      return row;
    });
    return [...attachmentRows, ...uploads];
  }, [attachments, sortFn, canEdit, authId, uploads]);

  const onFileDrop = useCallback((files: File[]) => {
    const file = files[0];
    if (!file) return;
    openFileEditor({
      tempFile: file,
      onSave: (fileEntity) => attachFileToContext(fileEntity.id),
    });
  }, []);

  if (isLoading) {
    return (
      <ListContainer className="highnote-attachments">
        <div className="loading">
          <LoadingSpinner />
        </div>
      </ListContainer>
    );
  }

  return (
    <ErrorBoundary name="Attachments">
      <div className="highnote-attachments" ref={dragRef}>
        {showFileDrop && (
          <FileDrop
            dragRef={dragRef}
            onDrop={onFileDrop}
            instruction={"Add attachments by dropping them here"}
          />
        )}
        <EntityTable
          rows={fileRows}
          columns={columns}
          showDragHandle={showDragHandle}
          onDragEnd={onDragEnd}
          EmptyComponent={() =>
            hideEmptyContainer ? null : showSpecialEmptyState ? (
              <EmptyContainer ref={dragRef}>
                {showFileDrop && (
                  <FileDrop
                    dragRef={dragRef}
                    onDrop={onFileDrop}
                    instruction={"Add attachments by dropping them here"}
                  />
                )}
                <IllustratedFilesSVG />
                <p className="instruction">Drag and drop to upload</p>
                <PermissionTooltip hasPermission={canEdit}>
                  <Button
                    type="button"
                    theme={BUTTON_THEME.CTA}
                    disabled={!canEdit}
                    className="add-new-attachment"
                    onClick={() =>
                      openFileEditor({
                        onSave: (fileEntity) =>
                          attachFileToContext(fileEntity.id),
                      })
                    }
                  >
                    or Create
                  </Button>
                </PermissionTooltip>
              </EmptyContainer>
            ) : (
              <EmptyTable action="Drop files or click Add" />
            )
          }
        />
      </div>

      {!hideLegacyAddButton && fileRows.length > 0 && (
        <PermissionTooltip hasPermission={canEdit}>
          <AddButton
            disabled={!canEdit}
            type="button"
            onClick={() =>
              openFileEditor({
                onSave: (fileEntity) => attachFileToContext(fileEntity.id),
              })
            }
          >
            Add Attachment
          </AddButton>
        </PermissionTooltip>
      )}
    </ErrorBoundary>
  );
};
