import axios from "axios";

const MAX_RETRIES = 3;
export const loadAudioSource = (source: string) => {
  let retryCount = 0;

  const getAudioBuffer = ({
    abortController,
    onDownloadProgress,
  }: {
    abortController?: AbortController;
    onDownloadProgress?: (progress: ProgressEvent) => void;
  }) =>
    new Promise<AudioBuffer>((resolve, reject) => {
      if (!source) {
        reject("[loadAudioSource] no source provided");
        return;
      }
      const ctx = new AudioContext();

      const loadBuffer = async () => {
        try {
          // console.log("[DEBUG] Loading array buffer from:", source);
          const arrayBuffer = await getArrayBuffer(source, {
            abortController,
            onDownloadProgress,
          });
          // console.log("[DEBUG] Decoding audio data from:", source);
          const audioBuffer = await ctx.decodeAudioData(arrayBuffer);
          resolve(audioBuffer);
        } catch (error) {
          retryCount += 1;
          if (retryCount < MAX_RETRIES) loadBuffer();
          else {
            console.log(`[loadAudioSource] error loading ${source}:`, error);
            reject(error);
          }
        } finally {
          await ctx.close();
        }
      };

      loadBuffer();
    });

  return {
    getAudioBuffer,
  };
};

/* Return the last endTime out of all the tracks. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getLatestEndTime = (tracks: any[]) => {
  return tracks.reduce((lastEndTime, t) => {
    const endTime = t.state.startTime + t.audioElement.duration;
    if (endTime > lastEndTime) return endTime;
    return lastEndTime;
  }, 0);
};

export const limitedPromise: (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  promise: Promise<any>,
  timeLimit: number,
) => Promise<void> = (promise, timeLimit) => {
  return new Promise((resolve) => {
    let stillWaiting = true;

    const timeoutId = setTimeout(() => {
      stillWaiting = false;
      resolve();
    }, timeLimit);

    promise.then(() => {
      if (stillWaiting) {
        clearTimeout(timeoutId);
        resolve();
      }
    });
  });
};

export const getBuffers = (audioElement: HTMLAudioElement) => {
  const allBuffers = [];
  for (let i = 0; i < audioElement.buffered.length; i++) {
    const start = audioElement.buffered.start(i);
    const end = audioElement.buffered.end(i);
    allBuffers.push({ start, end });
  }
  return allBuffers;
};

export const hasBufferedEnough: (props: {
  audioElement: HTMLAudioElement;
  startTime?: number;
  debug?: boolean;
}) => boolean = ({ audioElement, startTime = 0, debug = false }) => {
  if (!audioElement) return false;

  const normalizedStartTime = Math.max(0, startTime);
  const endTime = audioElement.duration || Infinity;

  // If we're past the full clip, we don't need to check buffers.
  if (normalizedStartTime >= endTime) return true;

  const buffered = audioElement.buffered.length > 0;
  if (!buffered) return false;

  const allBuffers = getBuffers(audioElement);

  allBuffers.sort((a, b) => b.start - a.start);
  const relevantBuffer = allBuffers.find(
    (b) => b.start <= normalizedStartTime && b.end >= normalizedStartTime,
  );

  if (!relevantBuffer) return false;
  const bufferedAmount = relevantBuffer.end - normalizedStartTime;
  const bufferedEnough = bufferedAmount > 0;

  if (debug) {
    console.log(
      `Buffer status for audio source [${audioElement.src}]:\n`,
      `${bufferedAmount}s buffered so far at ${normalizedStartTime}\n`,
      `Ready: ${bufferedEnough}`,
    );
  }

  return !!bufferedEnough;
};

export const getArrayBuffer = async (
  source: Blob | string,
  opts: {
    abortController?: AbortController;
    onDownloadProgress?: (progress: ProgressEvent) => void;
  },
) => {
  // eslint-disable-next-line no-useless-catch
  try {
    if (source instanceof Blob) {
      const arrayBuffer = await source.arrayBuffer();
      return arrayBuffer;
    }

    const signal = opts.abortController?.signal;

    const response = await axios.get(source, {
      signal,
      responseType: "arraybuffer",
      onDownloadProgress: (progressEvent) => {
        opts.onDownloadProgress && opts.onDownloadProgress(progressEvent);
      },
    });

    return response.data;
  } catch (e) {
    throw e;
  }
};

export const unlockSilentMode = () => {
  // See https://stackoverflow.com/questions/21122418/ios-webaudio-only-works-on-headphones/46839941#46839941
  let isWebAudioUnlocked = false;
  let isHTMLAudioUnlocked = false;

  function unlock() {
    if (isWebAudioUnlocked && isHTMLAudioUnlocked) return;

    // Create an audio context instance if WebAudio is supported
    const context =
      /* eslint-disable @typescript-eslint/no-explicit-any */
      window.AudioContext || (window as any).webkitAudioContext
        ? new (window.AudioContext || (window as any).webkitAudioContext)()
        : null;
    /* eslint-enable @typescript-eslint/no-explicit-any */

    // Unlock WebAudio - create short silent buffer and play it
    // This will allow us to play web audio at any time in the app
    const buffer = context.createBuffer(1, 1, 22050); // 1/10th of a second of silence
    const source = context.createBufferSource();
    source.buffer = buffer;
    source.connect(context.destination);
    source.onended = function () {
      isWebAudioUnlocked = true;
      if (isWebAudioUnlocked && isHTMLAudioUnlocked) {
        window.removeEventListener("mousedown", unlock);
      }
    };
    source.start();

    // Unlock HTML5 Audio - load a data url of short silence and play it
    // This will allow us to play web audio when the mute toggle is on
    const silenceDataURL =
      "data:audio/mp3;base64,//MkxAAHiAICWABElBeKPL/RANb2w+yiT1g/gTok//lP/W/l3h8QO/OCdCqCW2Cw//MkxAQHkAIWUAhEmAQXWUOFW2dxPu//9mr60ElY5sseQ+xxesmHKtZr7bsqqX2L//MkxAgFwAYiQAhEAC2hq22d3///9FTV6tA36JdgBJoOGgc+7qvqej5Zu7/7uI9l//MkxBQHAAYi8AhEAO193vt9KGOq+6qcT7hhfN5FTInmwk8RkqKImTM55pRQHQSq//MkxBsGkgoIAABHhTACIJLf99nVI///yuW1uBqWfEu7CgNPWGpUadBmZ////4sL//MkxCMHMAH9iABEmAsKioqKigsLCwtVTEFNRTMuOTkuNVVVVVVVVVVVVVVVVVVV//MkxCkECAUYCAAAAFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";
    const tag = document.createElement("audio");
    tag.controls = false;
    tag.preload = "auto";
    tag.loop = false;
    tag.src = silenceDataURL;
    tag.onended = function () {
      isHTMLAudioUnlocked = true;
      if (isWebAudioUnlocked && isHTMLAudioUnlocked) {
        window.removeEventListener("mousedown", unlock);
      }
    };
    try {
      tag.play();
    } catch (e) {
      // empty
    } finally {
      context?.close();
    }
    // if (p)
    //   p.then(
    //     function () {
    //       console.log("play success");
    //     },
    //     function (reason) {
    //       console.log("play failed", reason);
    //     }
    //   );
  }

  window.addEventListener("mousedown", unlock);
};
