import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";

import { CommentBlock } from "@highnote/server/src/core/entities";
import { PERMISSION } from "@highnote/server/src/core/shared-util";
import { ExpandablePreview } from "App/common/ExpandablePreview";
import { getValidCommentBlock, useCommentContext } from "App/common/useComment";
import { useSpaceContext } from "App/common/useSpace";
import { useAuth } from "App/components/Auth";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { Tooltip } from "App/core/Tooltip";
import { useCarouselSync } from "App/routes/Main/Space/Carousel/useCarouselSync";
import { useFocusOnCard } from "../useFocusedCard";
import { useSystemEditing } from "../useSystemEditing";
import { COMMENT_BLOCKS } from "./blocks";
import "./CommentContent.scss";

export const CommentContent = ({
  onCancel,
  isCollapsible,
  hideEditActions,
}: {
  onCancel?: () => void;
  isCollapsible?: boolean;
  hideEditActions?: boolean;
}) => {
  const { comment, replies, isEditing, updateComment, saveComment } =
    useCommentContext();
  const { setSystemEditing } = useSystemEditing();
  const { focusOnCard } = useFocusOnCard();
  const sync = useCarouselSync();
  const { spaceId } = useSpaceContext();
  const [isExpanded, setExpanded] = useState(isEditing);

  useEffect(() => {
    if (!isEditing) return;
    setExpanded(true);
  }, [isEditing]);

  const handleExpand = useCallback((expanded: boolean) => {
    setExpanded(expanded);
  }, []);

  const onBlockChange = async (block: CommentBlock) => {
    updateComment({ blocks: [block] });
  };

  const onBlockSave = async (block: CommentBlock) => {
    await saveComment({ blocks: [block] });
    focusOnCard().then(() => {
      sync.onCardIdClick(comment.id);
    });
    setSystemEditing(`comment-${comment.id}`, false);
  };

  const blockData = getValidCommentBlock(comment);
  const blockType = blockData?.type as keyof typeof COMMENT_BLOCKS;
  const blockConfig = COMMENT_BLOCKS[blockType];

  if (!blockConfig) {
    console.log(
      "[CommentContent] Could not find matching block config:",
      blockType,
    );
    return null;
  }

  if (!blockConfig.Component || !blockConfig.Icon) {
    return null;
  }

  const showEditButtons = !hideEditActions && blockConfig.isEditable;

  return (
    <div
      className="highnote-comment-content"
      data-id={comment.id}
      data-is-expanded={isExpanded}
    >
      <div className="comment-data">
        {isCollapsible ? (
          <ExpandablePreview
            disabled={isEditing}
            isExpanded={isExpanded}
            setExpanded={handleExpand}
          >
            <blockConfig.Component
              id={comment.id}
              createdBy={comment.createdBy}
              replies={replies}
              isEditing={isEditing}
              onChange={onBlockChange}
              onSave={onBlockSave}
              timestamp={comment.timestamp}
              spaceId={spaceId}
              data={blockData}
            />
          </ExpandablePreview>
        ) : (
          <blockConfig.Component
            id={comment.id}
            createdBy={comment.createdBy}
            replies={replies}
            isEditing={isEditing}
            onChange={onBlockChange}
            onSave={onBlockSave}
            timestamp={comment.timestamp}
            spaceId={spaceId}
            data={blockData}
          />
        )}
      </div>

      {showEditButtons && <CommentContentEditActions onCancel={onCancel} />}
    </div>
  );
};

export const CommentContentEditActions = ({
  onCancel,
  variant = "inside-comment",
  className,
}: {
  onCancel?: () => void;
  variant?: "inside-comment" | "outside-comment";
  className?: string;
}) => {
  const { comment, isEditing, setEditing, saveComment, resetComment } =
    useCommentContext();
  const { setSystemEditing } = useSystemEditing();
  const { focusOnCard } = useFocusOnCard();
  const { space } = useSpaceContext();
  const { isAllowed } = useAuth();
  const canCommentInSpace = isAllowed(PERMISSION.TO_COMMENT_IN_SPACE, {
    space,
  });

  const isInsideCommentVariant = variant === "inside-comment";

  useEffect(() => {
    if (!isEditing) return;
    // Focus the text area immediately
    const commentElements = document
      .querySelector(`[data-id="${comment.id}"]`)
      ?.getElementsByTagName("textarea");
    if (commentElements?.length) commentElements[0].focus();
  }, [isEditing]);

  const handleCancel = () => {
    setSystemEditing(`comment-${comment.id}`, false);
    onCancel?.();
    resetComment();
    setEditing(false);
  };

  const blockData = getValidCommentBlock(comment);
  const blockType = blockData?.type as keyof typeof COMMENT_BLOCKS;
  const blockConfig = COMMENT_BLOCKS[blockType];

  if (!blockConfig) {
    console.log(
      "[CommentContent] Could not find matching block config:",
      blockType,
    );
    return null;
  }

  if (!blockConfig.Component || !blockConfig.Icon) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validationError = blockConfig.validate(blockData as unknown as any);

  if (canCommentInSpace && isEditing) {
    return (
      <div
        className={classNames(
          "editor-buttons",
          {
            "editor-buttons-outside": variant === "outside-comment",
          },
          className,
        )}
      >
        <Button
          size={BUTTON_SIZE.XSMALL}
          theme={
            isInsideCommentVariant ? BUTTON_THEME.TEXT : BUTTON_THEME.PRIMARY
          }
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Tooltip title={validationError} arrow isDisabled={!validationError}>
          <Button
            size={BUTTON_SIZE.XSMALL}
            theme={
              isInsideCommentVariant ? BUTTON_THEME.PRIMARY : BUTTON_THEME.CTA
            }
            onClick={async () => {
              await saveComment();
              focusOnCard();
            }}
            disabled={!!validationError}
          >
            Save
          </Button>
        </Tooltip>
      </div>
    );
  }

  return null;
};
