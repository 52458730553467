import { hasBufferedEnough } from "./util";

export class ControlledAudioElement {
  element: HTMLAudioElement;
  playPromise: Promise<boolean | void>;

  constructor(source: string) {
    this.element = new Audio();
    if (source) this.element.src = source;
    this.element.crossOrigin = "anonymous";
    this.element.preload = "metadata";
    // this.element.controls = true;
    // document.body.appendChild(this.element);
  }

  get error() {
    return this.element.error;
  }

  get duration() {
    if (this.element.duration && isFinite(this.element.duration)) {
      return this.element.duration;
    }

    return 0;
  }

  get currentTime() {
    if (!isNaN(this.element.currentTime)) return this.element.currentTime;
    return 0;
  }

  get raw() {
    return this.element;
  }

  isBufferedAt(time: number) {
    return hasBufferedEnough({
      audioElement: this.element,
      startTime: time,
    });
  }

  play(muted?: boolean) {
    this.element.muted = true;
    (this.playPromise || Promise.resolve()).then(() => {
      this.playPromise = this.element
        .play()
        .then(() => (this.element.muted = !!muted))
        .then(() => delete this.playPromise);
    });
  }

  playAt(time: number) {
    this.element.currentTime = time;
    this.play();
  }

  pause() {
    this.element.muted = true;
    (this.playPromise || Promise.resolve()).then(() => {
      this.element.pause();
    });
  }

  /**
   * @param volume - number between 0 and 1
   */
  setVolume(volume: number = 0) {
    this.element.volume = volume;
  }

  destroy() {
    this.element.src = null;
    this.element.srcObject = null;
  }
}
