import React from "react";
import classNames from "classnames";
import { ReactComponent as MusicNoteSVG } from "App/common/icons/music-note.svg";
import { ReactComponent as FileSVG } from "App/common/icons/file.svg";
import { ReactComponent as BookmarkSVG } from "App/common/icons/bookmark.svg";
import { ReactComponent as FolderHollowSVG } from "App/common/icons/folder-hollow.svg";
import { ReactComponent as PlayCircleHollowSVG } from "App/common/icons/play-circle-hollow.svg";
import { EntityRowConfig, ENTITY_TYPE, SpaceTrack, Version } from "./config";
import { StatusIndicator } from "App/common/StatusIndicator";
import { PreviewIcon, PREVIEW_ICON_SIZE } from "App/common/PreviewIcon";
import { FileEntity, Track } from "@highnote/server/src/core/entities";
import { FileUpload } from "../useFiles";
import { TrackAudioIcon } from "../GlobalAudioPlayer/GlobalAudioPlayer";

export const EntityIcon = ({
  row,
  isPlayable,
  className,
}: {
  row: EntityRowConfig;
  isPlayable?: boolean;
  className?: string;
}) => {
  let icon: React.ReactNode;

  const track = row.entity as Track;
  const spaceTrack =
    (track as SpaceTrack).isSpaceTrack && (track as SpaceTrack);
  const file = row.entity as FileEntity;
  const version = (file as Version).isVersion && (file as Version);
  const upload = row.entity as FileUpload;

  if (row.type === ENTITY_TYPE.SPACE) {
    icon = <FolderHollowSVG />;
  }

  if (
    row.type === ENTITY_TYPE.TRACK ||
    row.type === ENTITY_TYPE.TRACK_VERSION
  ) {
    icon = <MusicNoteSVG />;
  }

  if (row.type === ENTITY_TYPE.FILE) {
    if (file.url) {
      icon = <BookmarkSVG />;
    } else {
      icon = <FileSVG />;
    }
  }

  if (row.type === ENTITY_TYPE.UPLOAD) {
    icon = <FileSVG />;
  }

  if ((spaceTrack || version) && row.type !== ENTITY_TYPE.TRACK_VERSION) {
    icon = <PlayCircleHollowSVG />;
  }

  const useUploadIcon = row.type === ENTITY_TYPE.UPLOAD;
  // TODO: Add `(row.type === ENTITY_TYPE.TRACK || row.type === ENTITY_TYPE.TRACK_VERSION)`
  // to `usePlayToggle` for playable track versions
  const usePlayToggle =
    row.isPlayable && isPlayable && row.type === ENTITY_TYPE.TRACK;
  const usePlainIcon = row.type !== ENTITY_TYPE.UPLOAD && !usePlayToggle;

  return (
    <div className={classNames("highnote-entity-icon", className)}>
      {useUploadIcon && (
        <StatusIndicator isLoading errorMessage={upload.error} />
      )}

      {usePlainIcon && (
        <PreviewIcon
          className="entity-icon"
          size={PREVIEW_ICON_SIZE.LARGE}
          fallback={icon}
        />
      )}

      {usePlayToggle && (
        <TrackAudioIcon
          dawTrackId={
            row.type === ENTITY_TYPE.TRACK_VERSION ? version.track.id : track.id
          }
          trackVersionId={
            row.type === ENTITY_TYPE.TRACK_VERSION ? version.id : undefined
          }
        />
      )}
    </div>
  );
};

export const EntityIconPlayable = ({
  row,
  className,
}: {
  row: EntityRowConfig;
  className?: string;
}) => <EntityIcon row={row} isPlayable className={className} />;
