import React from "react";
import classNames from "classnames";

import { ReactComponent as RetrySVG } from "App/common/icons-v2/redo.svg";
import { ReactComponent as ExclamationSVG } from "App/common/icons/exclamation.svg";
import { LoadingSpinner } from "App/common/icons/LoadingSpinner";
import { Tooltip } from "App/core/Tooltip";
import styles from "./StatusIndicator.module.scss";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";

export const StatusIndicator = ({
  isLoading,
  errorMessage,
  retryCallback,
  className,
}: {
  isLoading?: boolean;
  errorMessage?: string;
  retryCallback?: () => void;
  className?: string;
}) => {
  if (errorMessage) {
    return (
      <Tooltip title={errorMessage}>
        <Button
          className={classNames(styles["highnote-status-indicator"], className)}
          theme={BUTTON_THEME.ICON}
          size={BUTTON_SIZE.SMALL}
          onClick={retryCallback}
          aria-label={retryCallback ? "Retry" : "Error"}
        >
          {retryCallback ? (
            <RetrySVG className={styles.retry} />
          ) : (
            <ExclamationSVG className={styles.error} />
          )}
        </Button>
      </Tooltip>
    );
  }

  if (isLoading) {
    return (
      <div className={styles["highnote-status-indicator"]}>
        <LoadingSpinner />
      </div>
    );
  }

  return null;
};
