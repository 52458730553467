import React, { useState } from "react";
import { LinearProgress } from "@mui/material";
import classNames from "classnames";
import Measure from "react-measure";

import { ReactComponent as GiftFillSVG } from "App/common/icons-v2/gift-fill.svg";
import { usePlanLimitsContext } from "App/common/PlanLimits/usePlanLimits";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { useMobileAppParams } from "App/routes/Main/useMobileAppParams";
import { useAuth } from "../Auth";

import "./PlanLimitCallout.scss";

export const PlanLimitCallout = ({
  forceShowCallout,
  theme = "default",
}: {
  forceShowCallout?: boolean;
  theme?: "default" | "monetization";
}) => {
  const { isAuthorizedMobileComponent } = useMobileAppParams();
  const { trackVersionsLimit, user } = useAuth();
  const [width, setWidth] = useState<number>(0);
  const {
    title,
    subtitle,
    hasReachedStorageLimit,
    hasReachedSpaceLimit,
    hasReachedTrackVersionsLimit,
    showPlanPickerDialog,
  } = usePlanLimitsContext();

  if (
    isAuthorizedMobileComponent ||
    (!forceShowCallout &&
      !hasReachedStorageLimit &&
      !hasReachedSpaceLimit &&
      !hasReachedTrackVersionsLimit)
  ) {
    return null;
  }

  return (
    <Measure
      bounds
      onResize={(contentRect) => {
        setWidth(contentRect?.bounds?.width || 0);
      }}
    >
      {({ measureRef }) => {
        return (
          <div
            ref={measureRef}
            className={classNames("PlanLimitCallout", {
              [`theme-${theme}`]: theme,
            })}
            data-horizontal={width > 600}
          >
            <div className="body">
              <strong>{title}</strong>
              <div>{subtitle}</div>
              <div className="storage-bar">
                <LinearProgress
                  variant="determinate"
                  value={Math.min(
                    (user.trackVersionsUsed / trackVersionsLimit) * 100,
                    100,
                  )}
                />
              </div>
            </div>
            <Button
              theme={
                theme === "monetization"
                  ? BUTTON_THEME.MONETIZATION
                  : BUTTON_THEME.CTA
              }
              size={BUTTON_SIZE.MEDIUM}
              startIcon={<GiftFillSVG />}
              type="button"
              onClick={() => {
                showPlanPickerDialog();
              }}
            >
              Upgrade
            </Button>
          </div>
        );
      }}
    </Measure>
  );
};
