import "./MuteToggle.scss";
import { highnote } from "@highnote/server/src/sdk";
import { Button, BUTTON_THEME } from "App/core/Button";
import { ReactComponent as BellSVG } from "App/common/icons/bell.svg";
import { ReactComponent as BellMutedSVG } from "App/common/icons/bell-muted.svg";
import React, { useEffect, useState } from "react";
import {
  ArrayRemove,
  ArrayUnion,
  Space,
} from "@highnote/server/src/core/entities";
import { useAuth } from "App/components/Auth";
import { Tooltip } from "App/core/Tooltip";

export const MuteToggle = ({ entity }: { entity: Space }) => {
  const { user } = useAuth();
  const [mutedSpaces, setMutedSpaces] = useState(user?.mutedSpaces || []);
  const muted = mutedSpaces.includes(entity.id);

  useEffect(() => {
    setMutedSpaces(user?.mutedSpaces || []);
  }, [user?.mutedCollections, user?.mutedSpaces]);

  return (
    <>
      <Tooltip
        enterDelay={600}
        className="MuteToggle-tooltip"
        isDisabled={!muted}
        title="Notifications for this Space are muted. Click to unmute."
      >
        <Button
          className="MuteToggle"
          data-muted={muted}
          theme={BUTTON_THEME.ICON}
          onClick={(e) => {
            e.stopPropagation();

            setMutedSpaces(
              muted
                ? [...mutedSpaces].filter((id) => id !== entity.id)
                : [...mutedSpaces, entity.id],
            );

            highnote.updateUser({
              id: user.id,
              data: {
                mutedSpaces: muted
                  ? (new ArrayRemove([entity.id]) as unknown as string[])
                  : (new ArrayUnion([entity.id]) as unknown as string[]),
              },
            });
          }}
        >
          <BellSVG data-muted="false" />
          <BellMutedSVG data-muted="true" />
        </Button>
      </Tooltip>
    </>
  );
};
