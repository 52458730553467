import React, { useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { useAuth } from "App/components/Auth";
import { PERMISSION } from "@highnote/server/src/core/shared-util";
import { SORT_TYPE } from "../Library/config";
import { DEFAULT_COLUMNS, EntityTable } from "App/components/EntityTable";
import { useAttachmentsContext } from "App/common/useAttachments";
import {
  ENTITY_TYPE,
  EntityRowConfig,
  SpaceTrack,
} from "App/components/EntityTable/config";
import { highnote } from "@highnote/server/src/sdk";
import { useGlobalAudioPlayer } from "App/components/GlobalAudioPlayer";
import { UPLOAD_GROUP, useFiles } from "App/components/useFiles";
import { useSpaceContext } from "App/common/useSpace";
import { useGlobalSpaces } from "App/store/spaces/useGlobalSpaces";
import { useGlobalTracks } from "App/store/tracks/useGlobalTracks";
import { useSpaceChildItemRows } from "App/components/EntityTable/ExpandableSpace/ExpandableSpaceRow";
import { useLocation } from "react-router";
import { useIntercomContext } from "App/services/intercom/IntercomContext";
import { useDropboxJobsContext } from "App/components/useEntities/useDropboxDownloads";
import { Entity, JOB_STATUS } from "@highnote/server/src/core/entities";
import { useEntitiesSelection } from "App/components/useEntitiesSelection";
import { getEntityCollectionFromType } from "App/common/BaseTable";

const DESKTOP_COLUMNS = [
  DEFAULT_COLUMNS.PREVIEW,
  DEFAULT_COLUMNS.COMMENTS,
  DEFAULT_COLUMNS.SHORTCUT,
  DEFAULT_COLUMNS.ACTIONS,
];

const MOBILE_COLUMNS = [
  DEFAULT_COLUMNS.PREVIEW,
  DEFAULT_COLUMNS.COMMENTS,
  DEFAULT_COLUMNS.ACTIONS,
];

export const SpaceItems = ({
  sortType = SORT_TYPE.CUSTOM,
}: {
  sortType?: SORT_TYPE;
}) => {
  const { setShowLauncher } = useIntercomContext();
  const { dropboxJobs, resetPromptStatus } = useDropboxJobsContext();
  const { pathname } = useLocation();
  const { isAllowed } = useAuth();
  const { getUploadCache, getUploads } = useFiles();
  const { space, spaceLoading } = useSpaceContext();
  const { globalSpacesWatchers } = useGlobalSpaces();
  const { globalTracksWatchers } = useGlobalTracks();
  const spaceTracksLoading = globalTracksWatchers.get(space?.id)?.loading;
  const childSpacesLoading = globalSpacesWatchers.get(space?.id)?.loading;

  const [itemsOrder, setItemsOrder] = useState(space?.itemsOrder || []);
  const { getSortedSpaceChildItemRows } = useSpaceChildItemRows({
    space,
    options: {
      hasPlayableTracks: true,
      tableRowVariant: "expanded-row",
    },
  });

  const {
    selectedEntities,
    addToSelection,
    removeFromSelection,
    isSelectionVisible,
  } = useEntitiesSelection();

  const spaceChildItemRows = getSortedSpaceChildItemRows({
    sortType,
    itemsOrder,
  });

  const { isLoading: attachmentsLoading } = useAttachmentsContext();
  const { setQueue } = useGlobalAudioPlayer();

  const trackUploadCache = getUploadCache(
    UPLOAD_GROUP.TRACKS_BY_SPACE,
    space?.id,
  );
  const attachmentUploadCache = getUploadCache(
    UPLOAD_GROUP.ATTACHMENTS_BY_SPACE,
    space?.id,
  );
  const dropboxUpload = useMemo(() => {
    return dropboxJobs.map((job) => {
      return {
        file: {
          id: job.id,
          name: job.fileName,
          fileName: job.fileName,
        },
        progress: job.progress / 100,
        error: job.processingError,
        onRetry: () => {
          resetPromptStatus();
          highnote.retryDropboxJob({ jobId: job.id });
        },
        ...(job.status === JOB_STATUS.FAILED && {
          cancelUpload: () => {
            highnote.deleteDropboxJob({ jobId: job.id });
          },
        }),
      };
    });
  }, [dropboxJobs]);
  const trackUploads = getUploads({ cache: trackUploadCache });
  const attachmentUploads = getUploads({ cache: attachmentUploadCache });

  useEffect(() => {
    setShowLauncher(true);
  }, []);

  useEffect(() => {
    setItemsOrder(space?.itemsOrder || []);
  }, [space?.itemsOrder]);

  const canAddToSpace = isAllowed(PERMISSION.TO_ADD_TO_SPACE, { space });

  const uploadRows = useMemo(() => {
    const allUploads = [
      ...(dropboxUpload || []),
      ...(trackUploads || []),
      ...(attachmentUploads || []),
    ];
    return allUploads.map((uploadItem) => {
      return {
        id: uploadItem.file.id,
        entity: uploadItem,
        type: ENTITY_TYPE.UPLOAD,
      };
    });
  }, [dropboxUpload, trackUploads, attachmentUploads]);

  useEffect(() => {
    if (spaceTracksLoading) return;

    setQueue({
      type: "SET",
      _tracks: spaceChildItemRows
        .filter((item) => item.type === ENTITY_TYPE.TRACK)
        .map((item) => item.entity as SpaceTrack),
      currentPageId: pathname,
    });
  }, [spaceChildItemRows, spaceTracksLoading, pathname]);

  const onMoveEnd = (newList: EntityRowConfig[]) => {
    const newOrder = newList.map((item) => item.id);
    setItemsOrder(newOrder);
    highnote.updateSpace({
      id: space?.id,
      data: {
        itemsOrder: newOrder,
      },
    });
  };

  const onSelectRow = (row: EntityRowConfig, parentRow?: EntityRowConfig) => {
    const entityId = (row.entity as Entity).id;

    const isAlreadySelected = selectedEntities[entityId];

    if (isAlreadySelected) {
      removeFromSelection(entityId);
      return;
    }

    addToSelection({
      entityId,
      entityType: getEntityCollectionFromType(row.type),
      ...(parentRow && {
        parentEntity: {
          entityId: (parentRow.entity as Entity).id,
          entityType: getEntityCollectionFromType(parentRow.type),
        },
      }),
    });

    return;
  };

  const isDraggable = canAddToSpace && sortType === SORT_TYPE.CUSTOM;

  const allRows = useMemo(() => {
    return [...spaceChildItemRows, ...uploadRows].map((row) => {
      return {
        ...row,
        selected: row.id in selectedEntities,
      };
    });
  }, [spaceChildItemRows, uploadRows, selectedEntities]);

  return (
    <ErrorBoundary name="SpaceItems">
      <EntityTable
        tableRowVariant="draggable-expanded-row"
        isLoading={
          spaceLoading ||
          spaceTracksLoading ||
          childSpacesLoading ||
          attachmentsLoading
        }
        rows={allRows}
        onSelect={onSelectRow}
        isSelectionEnabled={isSelectionVisible}
        columns={DESKTOP_COLUMNS}
        mobileColumns={MOBILE_COLUMNS}
        onDragEnd={isDraggable ? onMoveEnd : undefined}
        showDragHandle={isDraggable}
        EmptyComponent={() => (
          <div className="highnote-empty-table">
            <p>
              <strong>It&apos;s empty in here.</strong>
              {canAddToSpace && "Create or add content to begin."}
            </p>
          </div>
        )}
      />
    </ErrorBoundary>
  );
};
