import React from "react";
import { Checkbox, CheckboxProps } from "@mui/material";
import classNames from "classnames";
import styles from "./AppCheckbox.module.scss";
export const AppCheckbox = ({ className, ...props }: CheckboxProps) => {
  return (
    <Checkbox
      {...props}
      disableRipple
      className={classNames(styles.checkbox, className)}
      icon={<div className={styles["checkbox-custom"]} />}
      checkedIcon={
        <div
          className={classNames(
            styles["checkbox-custom"],
            styles["checkbox-custom-checked"],
          )}
        >
          {/* Material UI doesn't allow us to import the SVG icon, so we have to use an inline SVG */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
          >
            <path d="M0 4.99941L5.25031 10L14 1.66647L12.2491 0L5.25031 6.66706L1.74969 3.33294L0 4.99941Z" />
          </svg>
        </div>
      }
      indeterminateIcon={
        <div
          className={classNames(
            styles["checkbox-custom"],
            styles["checkbox-custom-indeterminate"],
          )}
        >
          -
        </div>
      }
    />
  );
};
