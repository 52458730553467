import { firestore } from "./firebase";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
  Unsubscribe,
  FirestoreError,
} from "firebase/firestore";
import { Space, COLLECTION_ID } from "../core/entities";

export const watchSpaces: (props: {
  userId: Id;
  onChange: (spaces: Space[]) => void;
  onError?: (error: FirestoreError) => void;
}) => Unsubscribe = ({ userId, onChange, onError }) => {
  const spacesQuery = query(
    collection(firestore, COLLECTION_ID.SPACE),
    where("readableByV2", "array-contains-any", [userId]),
    orderBy("createdAt", "asc"),
  );

  return onSnapshot(
    spacesQuery,
    (snapshot) => {
      const spaces = snapshot.docs.map((s) => s.data()) as Space[];
      onChange(spaces);
    },
    (error) => {
      console.log("Could not watch Spaces.", error);
      onError && onError(error);
    },
  );
};
