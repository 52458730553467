import "./styles.scss";
import React, { useEffect, useState } from "react";
import { useUserCached } from "../useUserCached";
import { useTheme } from "../ThemeProvider";
import { Tooltip } from "../../core/Tooltip";
import { highnote } from "@highnote/server/src/sdk";
import { AUTH_PREFIX, UserEntity } from "@highnote/server/src/core/entities";
import { ReactComponent as AuthEmailSVG } from "App/common/icons/auth-mail.svg";
import { ReactComponent as AuthAppleSVG } from "App/common/icons/auth-apple.svg";
import { ReactComponent as AuthPhoneSVG } from "App/common/icons/auth-phone.svg";
import { ReactComponent as AuthGoogleSVG } from "App/common/icons/auth-google.svg";
import { ImageWithFallback } from "../ImageWithFallback";
import { isInviteId } from "@highnote/server/src/core/shared-util";
import {
  PublicUserProviderRaw,
  usePublicUserRaw,
} from "App/components/useEntities";
import { useAuth } from "App/components/Auth";
import { User } from "@sentry/types";

export enum USER_AVATAR_SIZE {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  XLARGE = "xlarge",
  XXLARGE = "xxlarge",
  XXXLARGE = "xxxlarge",
  JUMBO = "jumbo",
}

const getName = (user: Partial<UserEntity>, _alias?: string) => {
  const alias = _alias || "Anonymous";
  if (!user) return alias;
  return user?.name || alias;
};

const UserAvatar = ({
  alias,
  size,
}: {
  alias?: string;
  size?: USER_AVATAR_SIZE;
}) => {
  const { entity: user } = usePublicUserRaw();
  const { theme } = useTheme();
  const isAnonymous = !user;
  const name = getName(user, alias);

  let miscId = 1;
  if (user) {
    const n = user.id?.toLowerCase()[0];
    miscId = n < "x" ? (n < "l" ? 1 : 2) : 3;
  }

  const fallback = isAnonymous
    ? name.slice(0, 2).toUpperCase()
    : name[0].toUpperCase();

  return (
    <div
      className={"highnote-user-avatar"}
      data-cypress-id="highnote-user-avatar"
      data-size={size || USER_AVATAR_SIZE.SMALL}
      data-misc-id={miscId}
      data-theme={theme}
    >
      <ImageWithFallback
        src={highnote.getLocalFileUrl(user?.picture)}
        fallback={fallback}
        isAnonymous={isAnonymous}
      />
    </div>
  );
};

export const ConnectedUserAvatar = ({
  userId,
  alias,
  size,
}: {
  userId: Id;
  alias?: string;
  size?: USER_AVATAR_SIZE;
}) => {
  useUserCached({ id: userId });

  return (
    <PublicUserProviderRaw id={userId}>
      <UserAvatar alias={alias} size={size} />
    </PublicUserProviderRaw>
  );
};

const UserName = ({
  alias,
  ignoreVerifiedStatus,
}: {
  alias?: string;
  ignoreVerifiedStatus?: boolean;
}) => {
  const { theme } = useTheme();
  const { entity: user, loading: userLoading } = usePublicUserRaw();

  if (userLoading) {
    return (
      <span className="highnote-inline-loading" data-theme={theme}>
        ...
      </span>
    );
  }

  const name = getName(user, alias);
  const isInvite = isInviteId(user?.id);
  const isAnonymous = !user?.id.includes("|");
  const unverified = !!user && !user.isVerified && !isInvite && !isAnonymous;

  return (
    <span className="highnote-user-name" data-unverified={unverified}>
      {name}

      {!isInvite && unverified && !ignoreVerifiedStatus && (
        <Tooltip arrow title={`This user's account has not yet been verified.`}>
          <span className="warning">⚠️</span>
        </Tooltip>
      )}
    </span>
  );
};

export const ConnectedUserName = ({
  userId,
  alias,
  ignoreVerifiedStatus,
}: {
  userId: Id;
  alias?: string;
  ignoreVerifiedStatus?: boolean;
}) => {
  useUserCached({ id: userId });

  return (
    <PublicUserProviderRaw id={userId}>
      <UserName ignoreVerifiedStatus={ignoreVerifiedStatus} alias={alias} />
    </PublicUserProviderRaw>
  );
};

export const ConnectedUserIdentifier = ({
  userId,
  identifier,
  inline,
  compact,
  useNonCached,
}: {
  userId: Id;
  identifier?: string;
  inline?: boolean;
  compact?: boolean;
  useNonCached?: boolean;
}) => {
  const { user } = useAuth();
  const { theme } = useTheme();
  const [copied, setCopied] = useState<boolean>();
  const { user: cachedUser, userLoading } = useUserCached({ id: userId });

  const currentUser = user?.id === cachedUser?.id ? user : cachedUser;

  const [userRAW, setUserRAW] = useState<Partial<UserEntity> | null>(null);

  useEffect(() => {
    if (userId !== user?.id && useNonCached) {
      highnote.getUserPublic({ id: userId }).then(setUserRAW);
    }
  }, [userId, user?.id]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  if (userLoading) {
    return (
      <span
        className="highnote-inline-loading"
        data-theme={theme}
        data-inline={!!inline}
      >
        ...
      </span>
    );
  }

  if (!user || isInviteId(userId)) {
    return (
      <div className="highnote-user-identifier" data-inline={!!inline}>
        {!compact && (
          <span>{(currentUser as User)?.email || identifier || userId}</span>
        )}
        <AuthEmailSVG />
      </div>
    );
  }

  const prefix = userId.split("|")[0];
  const text = (currentUser as User).email || currentUser.name;
  const email = (currentUser as User).email || userRAW?.email;
  // displaying user ID here instead of phone number for privacy when confidential is true
  const phone = useNonCached ? userRAW?.phone : (currentUser as User).id;

  return (
    <Tooltip
      placement="top"
      // TODO: this was disabled to hide user's phone number on tooltip, and we'll likely
      // need a different approach for handling sensitive information like users phone number
      // isDisabled={!compact && !copied}
      isDisabled
      title={copied ? "Copied!" : text}
    >
      <div
        className="highnote-user-identifier"
        data-inline={!!inline}
        // onClick={() => {
        //   copy(text);
        //   setCopied(true);
        // }}
      >
        {(prefix === AUTH_PREFIX.EMAIL ||
          prefix === AUTH_PREFIX.GOOGLE ||
          prefix === AUTH_PREFIX.APPLE) && (
          <>
            {!compact ? (
              email ? (
                <span>{email}</span>
              ) : text ? (
                <span>{text}</span>
              ) : null
            ) : null}
            {prefix === AUTH_PREFIX.EMAIL ? (
              <AuthEmailSVG />
            ) : prefix === AUTH_PREFIX.GOOGLE ? (
              <AuthGoogleSVG />
            ) : (
              <AuthAppleSVG />
            )}
          </>
        )}
        {prefix === AUTH_PREFIX.SMS && (
          <>
            {!compact && <span>{phone}</span>}
            <AuthPhoneSVG />
          </>
        )}
      </div>
    </Tooltip>
  );
};

export const UserInfo = ({
  userId,
  alias,
  children,
}: {
  userId?: string;
  alias?: string;
  children: string | React.ReactNode;
}) => {
  return (
    <div className="highnote-user-info">
      <ConnectedUserAvatar
        userId={userId}
        alias={alias}
        size={USER_AVATAR_SIZE.XLARGE}
      />
      <div className="meta">
        <span className="name">
          <ConnectedUserName userId={userId} alias={alias} />
          <ConnectedUserIdentifier userId={userId} compact />
        </span>
        <span className="description">{children}</span>
      </div>
    </div>
  );
};
