import { firestore } from "./firebase";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
  limit,
  Unsubscribe,
} from "firebase/firestore";
import { COLLECTION_ID, Comment } from "../core/entities";
import { sanitizeCommentData } from "./utils";

export const watchComments: (props: {
  spaceId: Id;
  trackId: Id;
  onChange: (comments: Comment[]) => void;
}) => Unsubscribe = ({ spaceId, trackId, onChange }) => {
  const commentQuery = query(
    collection(firestore, COLLECTION_ID.COMMENT),
    where("spaceId", "==", spaceId),
    where("trackId", "==", trackId),
    orderBy("createdAt", "desc"),
    limit(100),
  );

  // TODO: Reinsert a span to capture this code
  return onSnapshot(
    commentQuery,
    (snapshot) => {
      const comments = snapshot.docs.map((s) =>
        sanitizeCommentData(s.data()),
      ) as Comment[];
      onChange(comments);
    },
    (error) => {
      console.log("Could not watch comments.", error);
    },
  );
};
