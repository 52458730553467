import {
  FileEntity,
  KNOCK_WORKFLOW,
  Space,
  Track,
} from "@highnote/server/src/core/entities";
import { highnote } from "@highnote/server/src/sdk";
import { useFiles } from "App/components/useFiles";
import { useFileDownload } from "./useFileDownload";
import { useSpaceFiles } from "./useSpace";
import { getDefaultVersionId } from "@highnote/server/src/core/shared-util";

export type TrackFileDownloadType = "all" | "default";

export const useEntityDownload = (entitySpace?: Space) => {
  const { downloadTrack, downloadFile, endDownload, startDownload } =
    useFileDownload();
  const { getDownloadUrl } = useFiles();
  const { artwork: spaceArtwork } = useSpaceFiles(entitySpace);

  const downloadTrackFiles = async (
    type: TrackFileDownloadType = "all",
    track: Track,
    trackArtworkUrl: string,
  ) => {
    startDownload();
    if (type === "default") {
      const defaultFileId = getDefaultVersionId(track);
      await downloadFile(defaultFileId);
    } else {
      await downloadTrack(track.id);
    }
    const artworkUrl = await getDownloadUrl(spaceArtwork);
    await highnote.notify({
      workflowId: KNOCK_WORKFLOW.TRACK_DOWNLOADED,
      data: {
        artworkUrl,
        spaceId: entitySpace?.id,
        spaceName: entitySpace?.name,
        trackId: track.id,
        trackName: track.title,
        trackArtworkUrl: trackArtworkUrl,
      },
    });
    endDownload();
  };

  const downloadAttachmentFile = async (attachment: FileEntity) => {
    if (attachment.url) {
      window.open(attachment.url, "_blank");
      return;
    }
    if (!attachment.storagePath) {
      return;
    }
    await downloadFile(attachment.id);
  };

  return { downloadAttachmentFile, downloadTrackFiles };
};
