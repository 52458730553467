import React, { useMemo } from "react";
import { LinearProgress } from "@mui/material";
import { useHistory } from "react-router";

import { parseSubscription } from "@highnote/server/src/core/shared-util";
import { BUTTON_SIZE, BUTTON_THEME, Button } from "App/core/Button";
import { formatFileSize } from "App/modules/utils";
import { useMobileAppParams } from "App/routes/Main/useMobileAppParams";
import { useAuth } from "../Auth";
import { PLANS } from "../Plans/config";

import "./StorageUsage.scss";

export const StorageUsage = () => {
  const history = useHistory();
  const { isAuthorizedMobileComponent } = useMobileAppParams();
  const { user, storageLimit } = useAuth();
  const { storageUsed, subscriptions } = user;
  const { tier } = parseSubscription(subscriptions.active);
  const plan = PLANS.find((p) => p.tier === tier);

  const storageLimitLabel = useMemo(
    () =>
      `You are using ${formatFileSize(storageUsed)} out of ${formatFileSize(storageLimit)} of storage allowed on your ${isAuthorizedMobileComponent ? `${plan.name} plan.` : ""}`,
    [storageLimit, isAuthorizedMobileComponent, plan],
  );

  return (
    <div className="highnote-storage-usage">
      <div className="storage-bar">
        <LinearProgress
          variant="determinate"
          value={Math.min((storageUsed / storageLimit) * 100, 100)}
        />
      </div>

      <p className="hint">
        {storageLimitLabel}
        {!isAuthorizedMobileComponent && (
          <>
            <Button
              theme={BUTTON_THEME.LINK}
              size={BUTTON_SIZE.AUTO}
              type="button"
              onClick={() => history.push(`/settings/plans`)}
            >
              {`${plan.name} Plan`}
            </Button>
            .
          </>
        )}
      </p>
    </div>
  );
};
