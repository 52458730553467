import React, { createContext, useContext, useEffect, useState } from "react";

import { useTrack } from "App/components/useEntities/useTrack";
import { EntitiesSelectionProvider } from "App/components/useEntitiesSelection";
import { useVersionRows } from "../hooks/useVersionRows";
import { TrackVersionSelectionProvider } from "./TrackVersionSelectionContext";
import { FileEntity } from "@highnote/server/src/core/entities";
import isEqual from "lodash/isEqual";

type TrackVersionEditorContext = ReturnType<typeof useVersionRows> & {
  localTrackVersions: FileEntity[];
  setLocalTrackVersions: React.Dispatch<React.SetStateAction<FileEntity[]>>;
  localPinnedTrackVersions: string[];
  setLocalPinnedTrackVersions: React.Dispatch<React.SetStateAction<string[]>>;
};

const TrackVersionEditorContext =
  createContext<TrackVersionEditorContext>(null);

type TrackVersionEditorProviderProps = {
  children: React.ReactNode;
};

const _TrackVersionEditorProvider = ({
  children,
}: TrackVersionEditorProviderProps) => {
  const { track, trackVersions, pinnedTrackVersions } = useTrack();
  const [localTrackVersions, setLocalTrackVersions] = useState(
    trackVersions || [],
  );
  const [localPinnedTrackVersions, setLocalPinnedTrackVersions] = useState(
    pinnedTrackVersions || [],
  );

  const versionRows = useVersionRows({
    track,
    trackVersions: localTrackVersions,
    pinnedTrackVersions,
  });

  useEffect(() => {
    if (trackVersions?.length && !isEqual(trackVersions, localTrackVersions)) {
      setLocalTrackVersions(trackVersions);
    }
  }, [trackVersions]);

  useEffect(() => {
    if (
      pinnedTrackVersions?.length &&
      !isEqual(pinnedTrackVersions, localPinnedTrackVersions)
    ) {
      setLocalPinnedTrackVersions(pinnedTrackVersions);
    }
  }, [pinnedTrackVersions]);

  return (
    <TrackVersionEditorContext.Provider
      value={{
        ...versionRows,
        localTrackVersions,
        setLocalTrackVersions,
        localPinnedTrackVersions,
        setLocalPinnedTrackVersions,
      }}
    >
      {children}
    </TrackVersionEditorContext.Provider>
  );
};

// This is a wrapper that provides the EntitiesSelectionProvider to the
// TrackVersionEditorProvider. This is necessary because the TrackVersionEditor
// uses the EntitiesSelectionProvider to manage the selection of versions.
export const TrackVersionEditorProvider = ({
  children,
}: TrackVersionEditorProviderProps) => {
  return (
    <EntitiesSelectionProvider>
      <TrackVersionSelectionProvider>
        <_TrackVersionEditorProvider>{children}</_TrackVersionEditorProvider>
      </TrackVersionSelectionProvider>
    </EntitiesSelectionProvider>
  );
};

export const useTrackVersionEditorContext = () => {
  const context = useContext(TrackVersionEditorContext);
  if (!context) {
    throw new Error(
      "useTrackVersionEditor must be used within TrackVersionEditorProvider",
    );
  }
  return context;
};
