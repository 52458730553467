import React from "react";

export const EmptyTable = ({ action }: { action?: string }) => {
  return (
    <div className="highnote-empty-table">
      <p>
        <strong>It&apos;s empty in here.</strong>
        {action && `${action} to begin.`}
      </p>
    </div>
  );
};
