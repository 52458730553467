import React from "react";

import constants from "App/modules/consts";
import { TextInput } from "../../TextInput";

export const TextBlock = (props: {
  isEditing: boolean;
  data: TextBlock;
  onChange: (block: TextBlock) => void;
  onSave: (block: TextBlock) => void;
}) => {
  const { isEditing, onChange, onSave, data } = props;

  const onInputChange = (value: string) => {
    onChange({ ...data, text: value });
  };

  const onSubmit = () => {
    if (!data.text) return;
    onSave(data);
  };

  return (
    <div className="text-block" data-is-editing={isEditing}>
      <TextInput
        isDisabled={!isEditing}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        autoFocus={!(window as any).isTouchDevice}
        onSubmit={onSubmit}
        placeholder="Type here..."
        value={data.text}
        maxLength={constants.longMaxLength}
        onChange={onInputChange}
      />
    </div>
  );
};
