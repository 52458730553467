import React, { useMemo } from "react";
import classNames from "classnames";

import { Select as MuiSelect, SelectProps } from "@mui/material";
import { ReactComponent as CaretDownSVG } from "App/common/icons-v2/carat-down.svg";
import { useTheme } from "./ThemeProvider";
import "./Menu/styles.scss";
import "./Select.scss";

export const Select = ({
  endAdornment,
  className,
  disabled,
  size,
  noPadding,
  ...props
}: Omit<SelectProps, "size"> & {
  size?: "no-padding" | "x-small" | "small" | "medium";
  noPadding?: boolean;
}) => {
  const { theme } = useTheme();

  const MenuProps = useMemo(
    () => ({
      ...(props.MenuProps || {}),
      className: `highnote-menu ${props.MenuProps?.className || ""}`,
      "data-theme": theme,
    }),
    [props.MenuProps, theme],
  );

  return (
    <MuiSelect
      {...props}
      className={classNames(
        "highnote-select",
        {
          [`highnote-select-trigger-size-${size}`]: !!size,
          "no-padding": noPadding,
        },
        className,
      )}
      MenuProps={MenuProps}
      endAdornment={endAdornment || <CaretDownSVG />}
      data-theme={theme}
      data-disabled={!!disabled}
    />
  );
};
