import "./Settings.scss";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { TextInput } from "App/common/TextInput";
import { useAuth } from "App/components/Auth";

import { Button, BUTTON_THEME, BUTTON_SIZE } from "App/core/Button";
import { ImageFileSelect } from "App/common/FileSelect";
import { highnote } from "@highnote/server/src/sdk";
import { useToast } from "App/common/useToast";
import { UploadTaskSnapshot } from "firebase/storage";
import {
  ConnectedUserAvatar,
  USER_AVATAR_SIZE,
} from "App/common/UserAvatar/UserAvatar";
import { TOSCallout } from "./TOSCallout";
import { AUTH_PREFIX } from "@highnote/server/src/core/entities";
import { LinkedAccounts } from "./LinkedAccounts";
import { UserDeletionRequest } from "./UserDeletionRequest";
import { useMobileAppParams } from "../Main/useMobileAppParams";

export const Profile = () => {
  const { user } = useAuth();
  const { isAuthorizedMobileComponent } = useMobileAppParams();
  const [name, setName] = useState<string>(user.name);
  const [email, setEmail] = useState<string>(user.email);
  const [, setUploadProgress] = useState<number>();
  const [isSendingVerificationEmail, setIsSendingVerificationEmail] =
    useState(false);
  const { toasted } = useToast();
  const userPrefix = user?.id?.split("|")[0];

  useEffect(() => {
    setName(user.name);
  }, [user.name]);

  useEffect(() => {
    setEmail(user.email);
  }, [user.email]);

  const updateUser = async (data: {
    picture?: string;
    name?: string;
    email?: string;
  }) => {
    const updateData = { ...data };
    if (data.picture) updateData.picture = data.picture;
    if (!updateData.email) delete updateData.email;
    if (!updateData.name) delete updateData.name;

    await toasted({
      promise: highnote.updateAuth0User(updateData),
      createMessage: "Updating user...",
      successMessage: `User updated`,
      // ErrorContent: DynamicErrorContent,
    });
    highnote.getUserPublic({ id: user.id });
  };

  const resendVerificationEmail = async () => {
    setIsSendingVerificationEmail(true);
    await toasted({
      promise: highnote.verifyAuth0Email(),
      errorMessage: "Failed to send verification email.",
      successMessage: "Verification email has successfully been sent.",
    });
    setIsSendingVerificationEmail(false);
  };

  const onFileSelect = async (files: File[]) => {
    if (!files) {
      setUploadProgress(undefined);
      return;
    }

    const file = files[0];
    const fileId = uuidv4();
    const storagePath = `PUBLIC/${fileId}-${file.name}`;
    setUploadProgress(0);

    await toasted({
      promise: highnote
        .uploadFile({
          file,
          storagePath,
          onStateChange: (snapshot: UploadTaskSnapshot) => {
            setUploadProgress(snapshot.bytesTransferred / snapshot.totalBytes);
          },
        })
        .finally(() => {}),
      errorMessage: `Could not upload file. Please try again.`,
    });

    setUploadProgress(undefined);

    const url = await highnote.getPublicFileUrl({ storagePath });
    await updateUser({ picture: url });
  };

  return (
    <div className="settings-tab-contents profile">
      <section className="profile-details">
        <div className="section-header">Profile</div>

        <TOSCallout />

        {!isAuthorizedMobileComponent && (
          <div className="input-field">
            <label>
              <h3>Profile Photo</h3>
            </label>
            <div className="input-wrapper">
              <ConnectedUserAvatar
                userId={user.id}
                size={USER_AVATAR_SIZE.XXXLARGE}
              />
              <div className="picture-buttons">
                <ImageFileSelect onSelect={onFileSelect}>
                  <Button
                    theme={BUTTON_THEME.PRIMARY}
                    size={BUTTON_SIZE.SMALL}
                    type="button"
                  >
                    Upload an Image
                  </Button>
                </ImageFileSelect>
                {user.picture && (
                  <Button
                    theme={BUTTON_THEME.LINK}
                    size={BUTTON_SIZE.AUTO}
                    type="button"
                    onClick={() => {
                      updateUser({ picture: null });
                    }}
                  >
                    Remove Image
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="input-field">
          <label>
            <h3>Display Name</h3>
          </label>
          <div className="input-wrapper">
            <TextInput
              isSingleLine
              maxLength={70}
              onChange={(val) => setName(val)}
              onSubmit={() => {
                updateUser({ name, email });
              }}
              value={name}
            />
          </div>
          <p className="hint">Your name as it appears to others.</p>
        </div>

        {[AUTH_PREFIX.EMAIL, AUTH_PREFIX.GOOGLE].includes(
          userPrefix as AUTH_PREFIX,
        ) && (
          <div className="input-field">
            <label className="flex-label">
              <h3>
                Email
                {!user.isVerified && (
                  <span className="unverified">⚠️ Unverified</span>
                )}
              </h3>
              {!user.isVerified && (
                <Button
                  className="verify-email"
                  disabled={isSendingVerificationEmail}
                  theme={BUTTON_THEME.LINK}
                  size={BUTTON_SIZE.AUTO}
                  onClick={resendVerificationEmail}
                >
                  Resend verification email
                </Button>
              )}
            </label>
            <div className="input-wrapper">
              <TextInput
                isSingleLine
                value={email}
                isReadOnly={!!user.googleId}
                onChange={setEmail}
                onSubmit={() => {
                  updateUser({ email, name });
                }}
              />
            </div>
            {user.googleId && (
              <p className="hint">
                This email is connected via Google and can&apos;t be changed
                here.
              </p>
            )}
          </div>
        )}

        {userPrefix === AUTH_PREFIX.SMS && (
          <div className="input-field">
            <label className="flex-label">
              <h3>Phone Number</h3>
            </label>
            <div className="input-wrapper">
              <TextInput isSingleLine value={user.phone} isReadOnly />
            </div>
          </div>
        )}

        {userPrefix === AUTH_PREFIX.EMAIL && (
          <div className="input-field">
            <label className="flex-label">
              <h3>Password</h3>
              <Button
                className="reset-password-button"
                theme={BUTTON_THEME.LINK}
                size={BUTTON_SIZE.AUTO}
                onClick={() => {
                  toasted({
                    promise: highnote.resetAuth0Password(),
                    createMessage: "Sending reset password email...",
                    successMessage: "Sent!",
                    errorMessage:
                      "Could not send reset password email. Please try again.",
                  });
                }}
              >
                Reset Password
              </Button>
            </label>
            <div className="input-wrapper">
              <TextInput
                type="password"
                isSingleLine
                value="**********"
                isReadOnly={true}
              />
            </div>
          </div>
        )}

        <div className="section-actions">
          <Button
            theme={BUTTON_THEME.SECONDARY}
            type="button"
            disabled={
              name === user?.name && (!!user.googleId || email === user?.email)
            }
            onClick={() => {
              setEmail(user.email);
              setName(user.name);
            }}
          >
            Cancel
          </Button>
          <Button
            theme={BUTTON_THEME.PRIMARY}
            type="button"
            onClick={() => {
              if (user.googleId) updateUser({ name });
              else updateUser({ email, name });
            }}
          >
            Save Changes
          </Button>
        </div>
      </section>

      <div className="spacer" />

      <section>
        <LinkedAccounts />
        <UserDeletionRequest />
      </section>
    </div>
  );
};
