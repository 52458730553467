import React from "react";
import classNames from "classnames";

import styles from "./UnreadDotV2.module.scss";

type Props = {
  inline?: boolean;
  position?: "left" | "right";
  children?: React.ReactNode;
  className?: string;
  innerProps?: {
    className?: string;
  };
  label?: string;
};

export const UnreadDotV2 = ({
  inline,
  position = "right",
  children,
  className,
  innerProps,
  label,
}: Props) => {
  return (
    <div
      className={classNames(
        styles["highnote-unread-dot"],
        { [styles[position]]: !!position },
        className,
      )}
      data-inline={inline}
      data-has-children={!!children}
    >
      <div
        className={classNames(styles.inner, innerProps?.className)}
        aria-label={label || `${children} unread messages`}
      >
        {children}
      </div>
    </div>
  );
};
