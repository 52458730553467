import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { UserCore } from "@highnote/server/src/core/entities";
import { highnote } from "@highnote/server/src/sdk";
import LoadingScreen from "App/common/LoadingScreen/LoadingScreen";
import { useAuth } from "App/components/Auth";
import { AuthRedirectRequired } from "App/modules/Auth/AuthRedirectRequired";
import { BETA_APP_URL } from "App/modules/consts";
import { routePaths } from "App/modules/urls";

const Handoff: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const { spaceId, shareKey, trackId } = Object.fromEntries(
      searchParams.entries(),
    );

    if (!spaceId || !shareKey) {
      history.replace(routePaths.login);
      return;
    }

    if (spaceId && shareKey) {
      const data: UserCore["mobileHandoffSpace"] = {
        spaceId,
        shareKey,
      };

      if (trackId) {
        data.trackId = trackId;
      }

      highnote
        .updateUser({
          id: user?.id,
          data: {
            mobileHandoffSpace: data,
          },
        })
        .then(() => {
          window.location.href = BETA_APP_URL;
        });
    }
  }, []);

  return <LoadingScreen />;
};

export const SpaceHandoff = () => (
  <AuthRedirectRequired>
    <Handoff />
  </AuthRedirectRequired>
);
