import { Space } from "@highnote/server/src/core/entities";
import { SpacesProvider, useSpaces } from "App/components/useEntities";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { where } from "firebase/firestore";
import { useAuth } from "App/components/Auth";

type LibrarySpacesContextValue = {
  librarySpaces: Space[];
  librarySpacesLoading: boolean;
  totalLimitLibrarySpaces: number;
  loadMoreLibrarySpaces: () => void;
};

const LibrarySpacesContext = createContext<LibrarySpacesContextValue>({
  librarySpaces: [],
  librarySpacesLoading: true,
  totalLimitLibrarySpaces: 0,
  loadMoreLibrarySpaces: () => undefined,
});

const _LibrarySpacesContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [librarySpaces, setLibrarySpaces] = useState<Space[]>([]);
  const {
    entities: spaces,
    loading: librarySpacesLoading,
    loadMore: loadMoreLibrarySpaces,
    totalLimit: totalLimitLibrarySpaces,
  } = useSpaces();

  useEffect(() => {
    const sortedSpaces = spaces.sort((a, b) => (a.name < b.name ? 1 : -1));
    setLibrarySpaces(sortedSpaces);
  }, [spaces]);

  const value = useMemo(
    () => ({
      librarySpaces,
      librarySpacesLoading,
      loadMoreLibrarySpaces,
      totalLimitLibrarySpaces,
    }),
    [
      librarySpaces,
      librarySpacesLoading,
      loadMoreLibrarySpaces,
      totalLimitLibrarySpaces,
    ],
  );

  return (
    <LibrarySpacesContext.Provider value={value}>
      {children}
    </LibrarySpacesContext.Provider>
  );
};

export const LibrarySpacesContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user } = useAuth();
  const constraints = useMemo(() => {
    if (!user?.id) return [];
    // PUBLIC_ID should not be used here as doing so will fetch all spaces that are public
    return [where("readableByV2", "array-contains-any", [user.id])];
  }, [user?.id]);

  return (
    <SpacesProvider constraints={constraints} limit={150}>
      <_LibrarySpacesContextProvider>{children}</_LibrarySpacesContextProvider>
    </SpacesProvider>
  );
};

export const useLibrarySpaces = () => useContext(LibrarySpacesContext);
