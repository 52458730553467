import React, { useMemo } from "react";
import without from "lodash/without";

import {
  COLLECTION_ID,
  FileEntity,
  SelectionType,
  Track,
} from "@highnote/server/src/core/entities";
import { getDefaultVersionId } from "@highnote/server/src/core/shared-util";
import { ENTITY_TYPE } from "App/components/EntityTable/config";
import { transformFileToVersion } from "App/components/EntityTable/ExpandableTrack/helpers";
import {
  DEFAULT_TRACK_VERSION_ACTIONS,
  TRACK_VERSION_ACTIONS,
  TrackVersionActions,
} from "App/components/TrackEditor/TrackVersions/TrackVersionActions";
import { FileUpload, UPLOAD_GROUP, useFiles } from "App/components/useFiles";

/**
 * Configuration for a single track version row in the UI
 * Represents either a file entity or an in-progress upload
 */
export type TrackVersionRowConfig = {
  id: string;
  entity: FileEntity | FileUpload;
  type: ENTITY_TYPE;
  isDefaultVersion: boolean;
  isPinned: boolean;
  actions?: React.ReactNode;
};

interface UseVersionRowsProps {
  track: Track;
  trackVersions: FileEntity[];
  pinnedTrackVersions: string[];
}

/**
 * Hook to manage track version rows, grouping them by date and handling both
 * existing versions and in-progress uploads.
 *
 * @returns {Object} containing:
 *   - allRows: Array of date-grouped track versions, sorted newest to oldest
 *   - allRowSelections: Flat array of all row IDs for selection state management
 */
export const useVersionRows = ({
  track,
  trackVersions,
  pinnedTrackVersions,
}: UseVersionRowsProps) => {
  const { getUploadCache, getUploads } = useFiles();

  // Get any in-progress uploads for this track
  const uploadCache = getUploadCache(
    UPLOAD_GROUP.TRACK_VERSIONS_UNPINNED,
    track?.id,
  );
  const uploadsUnpinned = getUploads({ cache: uploadCache });

  return useMemo(() => {
    if (!track) {
      return {
        allRows: [],
        allRowSelections: [],
      };
    }

    const defaultVersionId = getDefaultVersionId(track);
    const allRowSelections: SelectionType[] = [];

    /**
     * Normalizes a timestamp to midnight of the given day
     * This ensures all versions from the same day are grouped together
     */
    const normalizeDate = (timestamp?: number) => {
      return new Date(timestamp ?? new Date()).setHours(0, 0, 0, 0);
    };

    // Sort versions by creation date before grouping
    const sortedTrackVersions = [...trackVersions].sort(
      (a, b) => b.createdAt - a.createdAt,
    );

    // Use Map for efficient grouping by date
    // Key: normalized timestamp (midnight)
    // Value: array of versions for that day
    const groupedByDate = new Map<number, TrackVersionRowConfig[]>();

    // Process both uploads and existing versions
    [...uploadsUnpinned, ...sortedTrackVersions].forEach((version) => {
      const isUpload = "file" in version;
      const timestamp = normalizeDate(isUpload ? undefined : version.createdAt);
      const versionId = isUpload ? version.file.id : version.id;

      // Track all versions for selection state
      allRowSelections.push({
        entityId: versionId,
        entityType: isUpload ? COLLECTION_ID.FILE : COLLECTION_ID.TRACK,
      });

      // Initialize array for this date if needed
      if (!groupedByDate.has(timestamp)) {
        groupedByDate.set(timestamp, []);
      }

      // Add version to its date group
      groupedByDate.get(timestamp)!.push({
        id: versionId,
        entity: isUpload
          ? version
          : transformFileToVersion({
              file: version,
              track,
              isDefaultVersion: defaultVersionId === versionId,
              index: 0,
            }),
        type: isUpload ? ENTITY_TYPE.UPLOAD : ENTITY_TYPE.FILE,
        isDefaultVersion: defaultVersionId === versionId,
        isPinned: pinnedTrackVersions.includes(versionId),
        ...(!isUpload
          ? {
              actions: (
                <TrackVersionActions
                  actions={without(
                    DEFAULT_TRACK_VERSION_ACTIONS,
                    TRACK_VERSION_ACTIONS.PIN,
                    TRACK_VERSION_ACTIONS.SET_AS_DEFAULT,
                  )}
                  version={version}
                />
              ),
            }
          : {}),
      });
    });

    // Convert Map to final array structure
    // Each item contains a date and its associated versions
    const allRows = Array.from(groupedByDate.entries())
      .sort(([dateA], [dateB]) => dateB - dateA) // Sort groups newest first
      .map(([date, rows]) => ({
        date,
        rows,
      }));

    return {
      allRows,
      allRowSelections,
    };
  }, [track, trackVersions, pinnedTrackVersions, uploadsUnpinned]);
};
