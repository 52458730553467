import { FileEntity } from "@highnote/server/src/core/entities";
import { FILE_NOT_FOUND_ERROR } from "@highnote/server/src/core/shared-util";
import { highnote } from "@highnote/server/src/sdk";

// we have a timeout of 30 minutes for processing an audio file.
// we wait for 5 seconds between each retry and retry up to 360 times.
const MAX_RETRIES = 360;

export const watchAudioFiles = (
  ids: string[],
  onChange: (files: FileEntity[]) => void,
) => {
  let timeout: number;
  let stop = false;
  const filesById: Record<string, FileEntity> = {};
  let retries = 0;

  const fetch = (fileIds: string[]) => {
    if (fileIds.length < 1) return;
    highnote.getFiles({ ids: fileIds }).then((files) => {
      const unprocessed: string[] = [];
      retries += 1;

      files.forEach((file) => {
        filesById[file.id] = file;

        if (!file.isProcessedV3 && !file.processingErrorV3) {
          const processingTimedOut =
            Date.now() - file.createdAt > 30 * 60 * 1000;
          if (processingTimedOut || retries > MAX_RETRIES) {
            filesById[file.id].processingErrorV3 =
              "Processing audio file timed out.";
            return;
          }

          unprocessed.push(file.id);
        }
      });

      const missingIds = fileIds.filter((id) => !filesById[id]);
      missingIds.forEach((id) => {
        filesById[id] = {
          id,
          createdBy: undefined,
          createdAt: undefined,
          name: "",
          processingErrorV3: FILE_NOT_FOUND_ERROR,
        };
      });

      onChange(Object.values(filesById));

      if (unprocessed.length < 1) return;
      if (stop) return;
      clearTimeout(timeout);
      timeout = window.setTimeout(() => {
        fetch(unprocessed);
      }, 5000);
    });
  };

  fetch(ids);

  return () => {
    clearTimeout(timeout);
    stop = true;
  };
};
