import React, { useState } from "react";
import {
  COLLECTION_ID,
  FileEntity,
  KNOCK_WORKFLOW,
} from "@highnote/server/src/core/entities";
import {
  APP_FEATURES,
  AppFeaturesStatus,
} from "@highnote/server/src/core/features";
import {
  getDefaultVersionId,
  PERMISSION,
} from "@highnote/server/src/core/shared-util";
import { highnote } from "@highnote/server/src/sdk";
import { MenuProps } from "@mui/material";
import {
  ActionDivider,
  ActionInfo,
  ActionItem,
  Actions,
} from "App/common/Actions";
import { ReactComponent as DownloadSVG } from "App/common/icons-v2/download.svg";
import { ReactComponent as EditSVG } from "App/common/icons-v2/edit.svg";
import { ReactComponent as UnpinSVG } from "App/common/icons-v2/no-pushpin.svg";
import { ReactComponent as SwapSVG } from "App/common/icons-v2/refresh-line.svg";
import { ReactComponent as StarFilledSVG } from "App/common/icons-v2/star-fill.svg";
import { ReactComponent as InfoSVG } from "App/common/icons/info.svg";
import { ReactComponent as PinSVG } from "App/common/icons/pin.svg";
import { ReactComponent as RemoveSVG } from "App/common/icons/remove.svg";
import { useConfirmation } from "App/common/useConfirmation";
import { useFileDownload } from "App/common/useFileDownload";
import { useHighnote } from "App/common/useHighnote";
import { ConnectedUserName } from "App/common/UserAvatar/UserAvatar";
import { useSpaceContext } from "App/common/useSpace";
import { useAuth } from "App/components/Auth";
import { FILE_EDITOR_FIELD, FileEditor } from "App/components/FileEditor";
import { useTrack } from "App/components/useEntities/useTrack";
import { useTrackEditor } from "App/components/useTrackEditor";

export enum TRACK_VERSION_ACTIONS {
  DOWNLOAD = "DOWNLOAD",
  RENAME = "RENAME",
  SET_AS_DEFAULT = "SET_AS_DEFAULT",
  REMOVE = "REMOVE",
  PIN = "PIN",
  SWAP = "SWAP",
}

export const DEFAULT_TRACK_VERSION_ACTIONS = [
  TRACK_VERSION_ACTIONS.DOWNLOAD,
  TRACK_VERSION_ACTIONS.RENAME,
  TRACK_VERSION_ACTIONS.SET_AS_DEFAULT,
  TRACK_VERSION_ACTIONS.REMOVE,
  TRACK_VERSION_ACTIONS.PIN,
];

export const ALT_TRACK_VERSION_ACTIONS = [
  TRACK_VERSION_ACTIONS.DOWNLOAD,
  TRACK_VERSION_ACTIONS.RENAME,
];

const SetAsDefaultVersion = ({ version }: { version: FileEntity }) => {
  const { setAsDefaultVersion } = useHighnote();
  const { track, setCurrentTrackVersion } = useTrack();
  const [isDefaultChangeLoading, setIsDefaultChangeLoading] = useState(false);
  const defaultTrackVersionId = getDefaultVersionId(track);
  const isDefaultVersion = defaultTrackVersionId === version.id;

  // Don't show the action if this is already the default version
  if (isDefaultVersion) return null;

  return (
    <ActionItem
      action={{
        icon: <StarFilledSVG />,
        name: `Set as Default Version`,
        onClick: async () => {
          setIsDefaultChangeLoading(true);
          await setAsDefaultVersion({ track, versionId: version.id });
          setCurrentTrackVersion(version);
          setIsDefaultChangeLoading(false);
        },
        disabled: isDefaultChangeLoading,
      }}
    />
  );
};

// Memoize to prevent unnecessary re-renders when parent components update their row configurations.
// This is critical for performance since version data is passed as props and used in multiple
// table contexts that frequently refresh their state.
export const TrackVersionActions = React.memo(
  ({
    actions = [...DEFAULT_TRACK_VERSION_ACTIONS],
    version,
    menuProps,
  }: {
    actions?: TRACK_VERSION_ACTIONS[];
    version: FileEntity;
    menuProps?: Partial<MenuProps>;
  }) => {
    const { confirm, renderConfirmation } = useConfirmation();
    const { openTrackVersionEditor } = useTrackEditor();
    const [fileEditorOpen, setFileEditorOpen] = useState(false);
    const { isAllowed } = useAuth();
    const { space, spaceArtworkUrl } = useSpaceContext();
    const {
      track,
      trackArtworkUrl,
      refetchTrackFiles,
      pinnedTrackVersions,
      trackVersions,
    } = useTrack();
    const { downloadFile, isDownloading } = useFileDownload();
    const { pinTrackVersion, unpinTrackVersion, deleteOrRemoveSelections } =
      useHighnote();
    const canEditFile = isAllowed(PERMISSION.TO_EDIT_FILE, { file: version });
    const canAddToTrack = isAllowed(PERMISSION.TO_ADD_TO_TRACK_IN_SPACE, {
      space,
      track,
    });
    const canEditTrack = isAllowed(PERMISSION.TO_EDIT_TRACK_IN_SPACE, {
      space,
      track,
    });
    const canDownload = isAllowed(PERMISSION.TO_DOWNLOAD_TRACK_IN_SPACE, {
      track,
      space,
    });

    const isCurrentlyPinned = pinnedTrackVersions.includes(version.id);
    const isOnlyPin = isCurrentlyPinned && pinnedTrackVersions.length === 1;
    const isOnlyVersion = trackVersions.length === 1;
    const isDefaultVersion = version.id === getDefaultVersionId(track);

    const menuActions = [];

    if (canDownload) {
      actions.includes(TRACK_VERSION_ACTIONS.DOWNLOAD) &&
        menuActions.push({
          icon: <DownloadSVG />,
          name: "Download",
          disabled: isDownloading,
          disabledReason: "We're processing this download.",
          onClick: async () => {
            await downloadFile(version.id);
            highnote.notify({
              workflowId: KNOCK_WORKFLOW.TRACK_VERSION_DOWNLOADED,
              data: {
                files: [
                  {
                    fileId: version.id,
                    fileName: version.name,
                    versionNumber: track.versionFilesV2.indexOf(version.id) + 1,
                  },
                ],
                artworkUrl: spaceArtworkUrl,
                spaceId: space?.id,
                spaceName: space?.name,
                trackId: track.id,
                trackName: track.title,
                trackArtworkUrl: trackArtworkUrl,
              },
            });
          },
        });
    }

    if (canAddToTrack) {
      actions.includes(TRACK_VERSION_ACTIONS.SET_AS_DEFAULT) &&
        !isDefaultVersion &&
        menuActions.push({
          Component: () => <SetAsDefaultVersion version={version} />,
        });

      actions.includes(TRACK_VERSION_ACTIONS.PIN) &&
        !isOnlyPin &&
        menuActions.push({
          Component: () => (
            <ActionItem
              action={{
                icon: isCurrentlyPinned ? <UnpinSVG /> : <PinSVG />,
                name: `${isCurrentlyPinned ? "Unpin" : "Pin"}`,
                onClick: () => {
                  if (isCurrentlyPinned) {
                    unpinTrackVersion({ track, versionIds: [version.id] });
                    return;
                  }

                  pinTrackVersion({
                    track,
                    versionId: version.id,
                    subscriptionTier: space.subscriptionTier,
                  });
                },
              }}
            />
          ),
        });

      if (AppFeaturesStatus[APP_FEATURES.TRACK_VERSION_EDITOR]) {
        actions.includes(TRACK_VERSION_ACTIONS.SWAP) &&
          isCurrentlyPinned &&
          menuActions.push({
            Component: () => (
              <ActionItem
                action={{
                  icon: <SwapSVG />,
                  name: "Swap",
                  onClick: () => {
                    openTrackVersionEditor({
                      type: "swap",
                      track,
                      selectedVersionToSwap: version,
                    });
                  },
                }}
              />
            ),
          });
      }
    }

    if (canEditFile) {
      actions.includes(TRACK_VERSION_ACTIONS.RENAME) &&
        menuActions.push({
          icon: <EditSVG />,
          name: "Rename",
          onClick: () => {
            setFileEditorOpen(true);
          },
        });
    }

    if (canEditTrack && !isDefaultVersion && !isOnlyVersion) {
      actions.includes(TRACK_VERSION_ACTIONS.REMOVE) &&
        menuActions.push({
          icon: <RemoveSVG />,
          name: "Remove from Track",
          onClick: async () => {
            await confirm({
              title: "Remove from Track",
              body: (
                <p>
                  <strong>
                    Are you sure you want to remove {version.name} from this
                    track?
                  </strong>
                  <br />
                  <br />
                  All comments assigned to this version will be lost. This
                  action cannot be undone.
                </p>
              ),
            });

            await deleteOrRemoveSelections({
              entities: [
                {
                  entityId: version.id,
                  entityType: COLLECTION_ID.FILE,
                  parentEntity: {
                    entityId: track.id,
                    entityType: COLLECTION_ID.TRACK,
                  },
                },
              ],
              toastMessages: {
                createMessage: "Version being removed from track...",
                successMessage: "Version removed from track",
                errorMessage: "Could not remove version from track",
              },
            });

            if (pinnedTrackVersions.includes(version.id)) {
              await unpinTrackVersion({
                track,
                versionIds: [version.id],
              });
            }
          },
        });
    }

    if (!canEditFile && menuActions.length > 0) {
      menuActions.push(ActionDivider, {
        Component: () => (
          <ActionInfo>
            <InfoSVG /> Added by&nbsp;
            <ConnectedUserName userId={version.createdBy} />
          </ActionInfo>
        ),
        disabled: true,
      });
    }

    if (!menuActions.length) {
      return null;
    }

    return (
      <>
        {renderConfirmation}
        {fileEditorOpen && (
          <FileEditor
            file={version}
            title="Rename Version"
            fields={[FILE_EDITOR_FIELD.NAME]}
            onSave={refetchTrackFiles}
            onClose={() => setFileEditorOpen(false)}
          />
        )}
        <Actions actions={menuActions} menuProps={menuProps} />
      </>
    );
  },
);

TrackVersionActions.displayName = "TrackVersionActions";
