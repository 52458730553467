import React from "react";

import { FileEntity, Track } from "@highnote/server/src/core/entities";
import {
  ALT_TRACK_VERSION_ACTIONS,
  TrackVersionActions,
} from "App/components/TrackEditor/TrackVersions/TrackVersionActions";
import { useHistory } from "react-router";
import { ENTITY_TYPE, EntityRowConfig, Version } from "../config";
import { EntityTableRowOptions } from "../ExpandableSpace/ExpandableSpaceRow";

export const transformFileToVersion = ({
  file,
  track,
  isDefaultVersion,
  index,
  commentCount,
}: {
  file: FileEntity;
  track: Track;
  isDefaultVersion: boolean;
  index: number;
  commentCount?: number;
}) => {
  return {
    ...file,
    index,
    isDefaultVersion,
    isVersion: true,
    track,
    commentCount,
  } as Version;
};

export const useFormatTrackVersionToBaseEntityRow = () => {
  const { push } = useHistory();

  const formatTrackVersionToBaseEntityRow = ({
    track,
    version,
    defaultVersionId,
    index,
    commentCount,
    options,
  }: {
    track: Track;
    version: FileEntity;
    defaultVersionId: string;
    index: number;
    commentCount?: number;
    options?: EntityTableRowOptions;
  }): EntityRowConfig => {
    const isDefaultVersion = defaultVersionId === version.id;
    const entityVersion = transformFileToVersion({
      file: version,
      track,
      isDefaultVersion,
      commentCount,
      index,
    });

    return {
      key: version.id,
      id: version.id,
      entity: entityVersion,
      type: ENTITY_TYPE.TRACK_VERSION,
      actions: (
        <TrackVersionActions
          version={entityVersion}
          actions={ALT_TRACK_VERSION_ACTIONS}
        />
      ),
      createdAt: version.createdAt,
      name: version.name,
      onClick: () => {
        push(`/space/${track.spaceId}/${track.id}/${version.id}`);
      },
      isPlayable: options?.hasPlayableTracks,
    };
  };

  return {
    formatTrackVersionToBaseEntityRow,
  };
};
