import React from "react";
import classNames from "classnames";

import { FileEntity, Space } from "@highnote/server/src/core/entities";
import { PERMISSION } from "@highnote/server/src/core/shared-util";
import {
  Dialog,
  DialogButtons,
  DialogInfo,
  DialogSection,
} from "App/common/Dialog";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { AudioFileSelect } from "App/common/FileSelect";
import { ReactComponent as SelectSVG } from "App/common/icons-v2/checkbox-multiple-line.svg";
import { ReactComponent as DownloadSVG } from "App/common/icons/download.svg";
import { LoadingSpinner } from "App/common/icons/LoadingSpinner";
import { useAuth } from "App/components/Auth";
import { PermissionTooltip } from "App/components/PermissionTooltip";
import {
  Button,
  BUTTON_SIZE,
  BUTTON_THEME,
  ButtonProps,
} from "App/core/Button";
import { AppCheckbox } from "App/core/Checkbox/AppCheckbox";
import { useTrack } from "../useEntities/useTrack";
import { useTrackEditor } from "../useTrackEditor";
import { TrackVersionsList } from "./TrackVersionsList";
import { useTrackVersionEditorContext } from "./utils/context/TrackVersionEditorContext";
import { useTrackVersionSelectionContext } from "./utils/context/TrackVersionSelectionContext";
import { useVersionActions } from "./utils/hooks/useVersionActions";

import styles from "./TrackVersionEditor.module.scss";

const VERSION_EDITOR_TITLE = {
  default: "Versions",
  swap: "Select a version",
  pin: "Pin a version",
  manage: "Select versions",
};

type Props = {
  space: Space;
  onClose: () => void;
};

const TrackVersionEditorUI = ({ space, onClose }: Props) => {
  const { isAllowed } = useAuth();
  const { track, trackVersions, trackArtworkUrl } = useTrack();
  const { selectedVersionToSwap, trackVersionEditorType } = useTrackEditor();

  const {
    isDownloadingVersions,
    isSwappingVersions,
    isPinningVersion,
    renderConfirmation,
    downloadAllVersions,
    uploadVersions,
    deleteSelectedVersions,
    swapPinnedVersions,
    pinVersion,
  } = useVersionActions();

  const {
    singleSelectedVersion,
    isSelectionVisible,
    selectedEntities,
    selectedCount,
    bulkAddToSelection,
    getIsAllSelected,
    getIsIndeterminate,
    getAllSelectableRows,
    toggleSelectionToolbar,
    resetSelection,
  } = useTrackVersionSelectionContext();

  const {
    allRowSelections,
    localPinnedTrackVersions,
    setLocalPinnedTrackVersions,
    setLocalTrackVersions,
  } = useTrackVersionEditorContext();

  const canDownload = isAllowed(PERMISSION.TO_DOWNLOAD_TRACK_IN_SPACE, {
    track,
    space,
  });

  const isIndeterminate = getIsIndeterminate(allRowSelections, track);
  const isAllSelected = getIsAllSelected(allRowSelections, track);

  return (
    <ErrorBoundary name="TrackVersionEditor">
      <Dialog
        title={
          <div className={styles["header-title"]}>
            {isSelectionVisible
              ? VERSION_EDITOR_TITLE.manage
              : VERSION_EDITOR_TITLE[trackVersionEditorType]}
            {trackVersionEditorType === "default" && (
              <Button
                onClick={() => toggleSelectionToolbar()}
                theme={BUTTON_THEME.PRIMARY}
                size={BUTTON_SIZE.SMALL}
                startIcon={isSelectionVisible ? null : <SelectSVG />}
              >
                {isSelectionVisible ? "Cancel" : "Select"}
              </Button>
            )}
          </div>
        }
        open
        onClose={onClose}
        className={styles["highnote-track-version-editor"]}
        TitleProps={{
          className: styles["header"],
        }}
        PaperProps={{
          className: styles["paper-root"],
        }}
      >
        {isSelectionVisible && (
          <div className={styles["bulk-manage-actions"]}>
            <AppCheckbox
              checked={isAllSelected}
              onChange={() => {
                if (isAllSelected || isIndeterminate) {
                  resetSelection();
                } else {
                  bulkAddToSelection(
                    getAllSelectableRows(allRowSelections, track),
                  );
                }
              }}
              indeterminate={isIndeterminate}
            />
            <span>
              {isAllSelected || isIndeterminate ? "Deselect All" : "Select All"}
            </span>
          </div>
        )}
        <div className={styles.content}>
          <DialogSection className={styles["version-list-section"]}>
            <TrackVersionsList />
          </DialogSection>
        </div>
        <DialogButtons className={styles["footer"]}>
          <DialogInfo className={styles["footer-info"]}>
            Supported file types: WAV, MP3, MP4, OGG, AIF
          </DialogInfo>
          <div
            className={classNames(styles["footer-action-bar"], {
              [styles["justify-end"]]:
                trackVersionEditorType === "default" && isSelectionVisible,
            })}
          >
            {trackVersionEditorType === "default" && isSelectionVisible && (
              <Button
                fullWidth
                className={styles["cta-button"]}
                theme={BUTTON_THEME.WARN}
                disabled={!selectedCount}
                onClick={async () => {
                  await deleteSelectedVersions({
                    track,
                    versionsToRemove: Object.values(selectedEntities),
                    localTrackVersions: trackVersions,
                    localPinnedTrackVersions: track.pinnedVersionFiles,
                    setLocalTrackVersions,
                    setLocalPinnedTrackVersions,
                  });
                  resetSelection();
                }}
              >
                Delete
              </Button>
            )}
            {trackVersionEditorType === "default" && !isSelectionVisible && (
              <>
                <PermissionTooltip hasPermission={canDownload}>
                  <Button
                    fullWidth
                    className={classNames(
                      styles["download-all"],
                      styles["cta-button"],
                    )}
                    theme={BUTTON_THEME.SECONDARY}
                    disabled={
                      isDownloadingVersions ||
                      trackVersions.length < 1 ||
                      !canDownload
                    }
                    onClick={() =>
                      downloadAllVersions(track.id, trackVersions, {
                        trackArtworkUrl,
                        spaceId: space.id,
                        spaceName: space.name,
                        trackName: track.title,
                      })
                    }
                  >
                    <div className={styles["icon-container"]}>
                      {isDownloadingVersions ? (
                        <LoadingSpinner />
                      ) : (
                        <DownloadSVG />
                      )}
                    </div>
                    <span>Download All</span>
                  </Button>
                </PermissionTooltip>

                <UploadVersionButton
                  onSelect={(files) => uploadVersions({ track, space, files })}
                />
              </>
            )}
            {trackVersionEditorType === "swap" && (
              <>
                <UploadVersionButton
                  buttonProps={{
                    theme: BUTTON_THEME.PRIMARY,
                    disabled: isSwappingVersions,
                  }}
                  onSelect={(files) => uploadVersions({ track, space, files })}
                />
                <Button
                  fullWidth
                  className={styles["cta-button"]}
                  theme={BUTTON_THEME.CTA}
                  disabled={
                    !singleSelectedVersion ||
                    isSwappingVersions ||
                    !selectedVersionToSwap?.id ||
                    selectedVersionToSwap.id === singleSelectedVersion.id
                  }
                  onClick={async () => {
                    if (!selectedVersionToSwap?.id || !singleSelectedVersion)
                      return;
                    await swapPinnedVersions({
                      track,
                      currentVersionId: selectedVersionToSwap.id,
                      nextVersion: singleSelectedVersion.entity as FileEntity,
                      localPinnedTrackVersions,
                      setLocalPinnedTrackVersions,
                    });

                    onClose();
                  }}
                >
                  Swap
                </Button>
              </>
            )}
            {trackVersionEditorType === "pin" && (
              <>
                <UploadVersionButton
                  onSelect={(files) => uploadVersions({ track, space, files })}
                  buttonProps={{
                    theme: BUTTON_THEME.PRIMARY,
                    children: "Upload",
                  }}
                />
                <Button
                  theme={BUTTON_THEME.CTA}
                  fullWidth
                  className={styles["cta-button"]}
                  disabled={!singleSelectedVersion || isPinningVersion}
                  onClick={async () => {
                    if (!singleSelectedVersion) return;

                    await pinVersion({
                      track,
                      version: singleSelectedVersion.entity as FileEntity,
                      subscriptionTier: space.subscriptionTier,
                      localPinnedTrackVersions,
                      setLocalPinnedTrackVersions,
                    });

                    onClose();
                  }}
                >
                  Pin
                </Button>
              </>
            )}
          </div>
        </DialogButtons>

        {renderConfirmation}
      </Dialog>
    </ErrorBoundary>
  );
};

const UploadVersionButton = ({
  buttonProps,
  onSelect,
}: {
  buttonProps?: Omit<ButtonProps, "ref">;
  onSelect: (files: File[]) => void;
}) => {
  return (
    <AudioFileSelect onSelect={onSelect} allowMultiple>
      <Button
        {...buttonProps}
        className={classNames(styles["cta-button"], buttonProps?.className)}
        fullWidth={buttonProps?.fullWidth || true}
        theme={buttonProps?.theme || BUTTON_THEME.CTA}
      >
        {buttonProps?.children || "Upload a version"}
      </Button>
    </AudioFileSelect>
  );
};

export const TrackVersionEditor = ({ space, onClose }: Props) => {
  return <TrackVersionEditorUI space={space} onClose={onClose} />;
};
