import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { QueryConstraint } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

import { COLLECTION_ID } from "@highnote/server/src/core/entities";
import { highnote } from "@highnote/server/src/sdk";
import { ENTITY_NOT_FOUND } from "App/common/util/ERRORS";

type WatcherAction =
  | {
      type: "ATTACH_LISTENER";
      key: string;
      watcher: () => void;
      componentId: WatcherComponentId;
    }
  | { type: "DETACH_LISTENER"; key: string; componentId: WatcherComponentId }
  | {
      type: "SET_LOADING";
      key: string;
      componentId?: WatcherComponentId;
      loading: boolean;
    }
  | { type: "SET_ERROR"; key: string; error: string };

export type WatcherState = Map<
  string,
  {
    watcher?: () => void;
    loading?: Map<WatcherComponentId, boolean>;
    error?: string;
    components?: Set<string>;
  }
>;

// Reducer function for managing watchers
function watcherReducer(
  state: WatcherState,
  action: WatcherAction,
): WatcherState {
  switch (action.type) {
    case "ATTACH_LISTENER": {
      const newState = new Map(state);
      const existingWatcher = state.get(action.key);
      const isExistingComponent = existingWatcher?.components?.has(
        action.componentId,
      );

      if (existingWatcher) {
        if (isExistingComponent) {
          // To avoid re-renders, use the existing state
          return state;
        }

        newState.set(action.key, {
          ...existingWatcher,
          // Add the new component to the set
          components: new Set([
            ...Array.from(existingWatcher.components || []),
            action.componentId,
          ]),
        });
      } else {
        // If the watcher does not exist, create a new one
        newState.set(action.key, {
          watcher: action.watcher,
          components: new Set([action.componentId]),
        });
      }
      return newState;
    }

    case "DETACH_LISTENER": {
      const newState = new Map(state);
      const existingWatcher = state.get(action.key);
      if (!existingWatcher) return state;

      const newComponents = new Set(existingWatcher.components);
      newComponents.delete(action.componentId);

      if (newComponents.size === 0) {
        // If there are no components left, unsubscribe from the watcher
        existingWatcher.watcher?.();
        // Then delete the watcher from the state
        newState.delete(action.key);
      } else {
        // If there are still components left, update the watcher state
        // with the new components
        newState.set(action.key, {
          ...existingWatcher,
          components: newComponents,
        });
      }
      return newState;
    }

    case "SET_LOADING": {
      const existingWatcher = state.get(action.key);
      const currentLoading = existingWatcher?.loading?.get(action.componentId);

      // Skip update if loading state hasn't changed
      if (currentLoading === action.loading) return state;

      const newState = new Map(state);
      const newLoadingMap = new Map(existingWatcher?.loading);

      if (action.loading) {
        newLoadingMap.set(action.componentId, action.loading);
      } else {
        newLoadingMap.delete(action.componentId);
      }

      newState.set(
        action.key,
        existingWatcher
          ? {
              ...existingWatcher,
              loading: newLoadingMap,
            }
          : {
              loading: newLoadingMap,
            },
      );
      return newState;
    }

    case "SET_ERROR": {
      const newState = new Map(state);
      const existingWatcher = state.get(action.key);

      newState.set(
        action.key,
        existingWatcher
          ? {
              ...existingWatcher,
              error: action.error,
            }
          : {
              error: action.error,
            },
      );
      return newState;
    }

    default:
      return state;
  }
}

export type WatcherActionType = "attach" | "detach";

// All our component ID types
type SpaceWatcherIds =
  | "space-home"
  | "space-home-child-item-rows"
  | "space-picker-all-spaces"
  | "space-picker-pinned-space";

// TODO: Add moveToSpace + addToSpace dialog watcher ids
type SpaceDialogWatcherIds =
  | "share-dialog"
  | "click-thru-space-row"
  | "space-track-editor";

type SpaceBreadcrumbWatcherIds =
  | "breadcrumbs-space-home"
  | "breadcrumbs-space-track";

type SpaceRelatedWatcherIds =
  | SpaceWatcherIds
  | SpaceDialogWatcherIds
  | SpaceBreadcrumbWatcherIds;

type LibraryBreadcrumbWatcherIds = "breadcrumbs-library";
type LibraryRelatedWatcherIds = LibraryBreadcrumbWatcherIds;

// TODO: Add 'TrackRelatedWatcherIds`
export type WatcherComponentId =
  | SpaceRelatedWatcherIds
  | LibraryRelatedWatcherIds;

// Apply to base config
type BaseWatcherConfig = {
  componentId: WatcherComponentId;
  collectionId: COLLECTION_ID;
};

// Attach configurations
type SingleEntityAttachConfig<EntityType> = BaseWatcherConfig & {
  watcherType: "single";
  action: "attach";
  entityId: string;
  onEntityChange: {
    add: (entity: EntityType) => void;
    remove: (entityId: string) => void;
  };
};

type MultipleEntitiesAttachConfig<EntityType> = BaseWatcherConfig & {
  watcherType: "multiple";
  action: "attach";
  watcherKey: string;
  constraints: QueryConstraint[];
  onEntitiesChange: (entities: EntityType[]) => void;
  limit?: number;
};

// Split detach configurations
type SingleEntityDetachConfig = {
  action: "detach";
  watcherType: "single";
  componentId: WatcherComponentId;
  entityId: string;
};

type MultipleEntitiesDetachConfig = {
  action: "detach";
  watcherType: "multiple";
  componentId: WatcherComponentId;
  watcherKey: string;
};

type WatcherConfig<EntityType> =
  | SingleEntityAttachConfig<EntityType>
  | MultipleEntitiesAttachConfig<EntityType>
  | SingleEntityDetachConfig
  | MultipleEntitiesDetachConfig;

/**
 * Enhanced watcher management system that provides improved type safety,
 * component tracking, and error handling.
 *
 * Key improvements over original useWatcher:
 * - Type-safe configurations using discriminated unions
 * - Component-based watcher tracking
 * - Improved error handling and retry logic
 * - More efficient cleanup
 *
 * TODO:
 * 1. Update global tracks to use this pattern
 * 2. Remove useWatcher.tsx once migration is complete
 */
export const useWatcherManagementV2 = <EntityType,>() => {
  const [totalLimit, setTotalLimit] = useState<number>(0);
  const [requestedLimit, setRequestedLimit] = useState<number>(0);
  const [retryTrigger, setRetryTrigger] = useState<string>();
  const retryCountRef = useRef<number>(0);
  const unmountedRef = useRef<boolean>(false);

  const [activeWatchers, setActiveWatchers] = useReducer(
    watcherReducer,
    new Map(),
  );

  useEffect(() => {
    return () => {
      // This is used to prevent the watcher from being called after the component has unmounted
      unmountedRef.current = true;
    };
  }, []);

  const manageWatcher = useCallback(
    async (config: WatcherConfig<EntityType>) => {
      let timeout: number;
      // Get the appropriate watcherKey based on config type
      const watcherKey =
        config.watcherType === "single" ? config.entityId : config.watcherKey;

      if (config.action === "attach") {
        const existingWatcher = activeWatchers.get(watcherKey);
        const isExistingComponent = existingWatcher?.components?.has(
          config.componentId,
        );

        // If this component is already watching, do nothing
        if (isExistingComponent) {
          return;
        }

        // If we have an existing watcher, just add the new component
        if (existingWatcher?.watcher) {
          setActiveWatchers({
            type: "ATTACH_LISTENER",
            key: watcherKey,
            watcher: existingWatcher.watcher,
            componentId: config.componentId,
          });
          return;
        }

        // Only create new Firebase connection if no existing watcher
        setActiveWatchers({
          type: "SET_LOADING",
          key: watcherKey,
          componentId: config.componentId,
          loading: true,
        });

        if (config.watcherType === "single") {
          const unsubscribe = highnote.watchEntity({
            entityId: config.entityId,
            collectionId: config.collectionId,
            onChange: (entity: EntityType) => {
              if (unmountedRef.current) return;
              clearTimeout(timeout);
              config.onEntityChange.add(entity);
              resetWatcher(watcherKey, config.componentId);
              retryCountRef.current = 0;
            },
            onError: (error) => {
              if (unmountedRef.current) return;
              if (retryCountRef.current > 2) {
                config.onEntityChange.remove(config.entityId);
                setActiveWatchers({
                  type: "SET_ERROR",
                  key: watcherKey,
                  error: `Error loading entity: ${error.message}`,
                });
                setActiveWatchers({
                  type: "SET_LOADING",
                  key: watcherKey,
                  componentId: config.componentId,
                  loading: false,
                });
              } else {
                clearTimeout(timeout);
                timeout = window.setTimeout(() => {
                  if (unmountedRef.current) return;
                  retryCountRef.current += 1;
                  setRetryTrigger(uuidv4());
                }, 5000);
              }
            },
          });

          setActiveWatchers({
            type: "ATTACH_LISTENER",
            key: watcherKey,
            watcher: unsubscribe,
            componentId: config.componentId,
          });
        }
        // Handle multiple entities watch
        else if (config.watcherType === "multiple") {
          if (config.limit) {
            setRequestedLimit(config.limit);
          }

          const unsubscribe = highnote.watchEntities({
            collectionId: config.collectionId,
            constraints: config.constraints,
            onChange: (entities: EntityType[]) => {
              if (unmountedRef.current) return;
              clearTimeout(timeout);
              config.onEntitiesChange(entities);
              resetWatcher(watcherKey, config.componentId);
              retryCountRef.current = 0;
            },
            onError: () => {
              if (unmountedRef.current) return;
              if (retryCountRef.current > 2) {
                setActiveWatchers({
                  type: "SET_LOADING",
                  key: watcherKey,
                  componentId: config.componentId,
                  loading: false,
                });
                setActiveWatchers({
                  type: "SET_ERROR",
                  key: watcherKey,
                  error: ENTITY_NOT_FOUND,
                });
              } else {
                clearTimeout(timeout);
                timeout = window.setTimeout(() => {
                  if (unmountedRef.current) return;
                  retryCountRef.current += 1;
                  setRetryTrigger(uuidv4());
                }, 5000);
              }
            },
          });

          setActiveWatchers({
            type: "ATTACH_LISTENER",
            key: watcherKey,
            watcher: unsubscribe,
            componentId: config.componentId,
          });
        }
      } else if (config.action === "detach") {
        setActiveWatchers({
          type: "DETACH_LISTENER",
          key: watcherKey,
          componentId: config.componentId,
        });
        clearTimeout(timeout);
      }

      return () => {
        setActiveWatchers({
          type: "DETACH_LISTENER",
          key: watcherKey,
          componentId: config.componentId,
        });

        clearTimeout(timeout);
      };
    },
    [totalLimit, retryTrigger],
  );

  const resetWatcher = useCallback(
    (watcherKey: string, componentId: WatcherComponentId) => {
      setActiveWatchers({
        type: "SET_ERROR",
        key: watcherKey,
        error: "",
      });
      setActiveWatchers({
        type: "SET_LOADING",
        key: watcherKey,
        componentId,
        loading: false,
      });
    },
    [],
  );

  useEffect(() => {
    if (requestedLimit) {
      // Ability to set the totalLimit via the manageEnititiesWatchers args
      // We use requestedLimit to set the initial totalLimit.
      setTotalLimit((prev) => prev + requestedLimit);
    }
  }, [requestedLimit]);

  return {
    activeWatchers,
    manageWatcher,
    // TODO: (GLOBAL SPACE): This is not used now, but may be used in the future.
    // Reconsider current implementation, and maybe adding into each watcher object.
    loadMore: () => setTotalLimit((prev) => prev + requestedLimit),
  };
};
