import React, { createContext, useContext, useReducer } from "react";
import { Space, Track } from "@highnote/server/src/core/entities";
import {
  ManageEntitiesWatchers,
  ManageEntityWatchers,
  WatcherState,
  useWatcherManagement,
} from "../watchers/useWatcher";

type GlobalTracksAction =
  | { type: "SET_TRACK"; payload: Track }
  | { type: "ADD_TRACKS"; payload: Track[] }
  | {
      type: "ADD_CHILD_TRACKS";
      payload: {
        parentSpaceId: string;
        entities: Track[];
        // TODO: Use trackOrder in the future to set orders.
        // Currently itemsOrder is an array of both attachments + tracks, but we only want tracks.
        // Need an easier/efficient way to determine and isolate the tracks in order from it.
        trackOrder?: string[];
      };
    };

type GlobalTracksState = {
  tracks: Map<string, Track>;
  // Map of parent space id to array of child track ids
  childTracks: Map<Space["id"], Array<Track["id"]>>;
};

function tracksReducer(
  state: GlobalTracksState,
  action: GlobalTracksAction,
): GlobalTracksState {
  switch (action.type) {
    case "SET_TRACK": {
      const newTracks = new Map(state.tracks);
      newTracks.set(action.payload.id, action.payload);
      return { ...state, tracks: newTracks };
    }
    case "ADD_TRACKS": {
      const newTracks = new Map(state.tracks);
      action.payload.forEach((track) => {
        newTracks.set(track.id, track);
      });
      return { ...state, tracks: newTracks };
    }
    case "ADD_CHILD_TRACKS": {
      const { parentSpaceId, entities } = action.payload;
      const newChildTracksState = new Map(state.childTracks);
      const newTrackState = new Map(state.tracks);

      // `action.payload` returns a new array of entities, so we reset the child track id's
      // here to ensure we're not adding duplicates, or keeping stale data.
      const newChildTrackIds = new Set<string>();

      entities.forEach((track) => {
        // Add new children to the Set to ensure all entries are unique
        newChildTrackIds.add(track.id);
        // Add or update child tracks in the global tracks map
        newTrackState.set(track.id, track);
      });

      // Update child spaces map with the unique list of child IDs
      newChildTracksState.set(parentSpaceId, Array.from(newChildTrackIds));

      return {
        ...state,
        tracks: newTrackState,
        childTracks: newChildTracksState,
      };
    }
    default:
      return state;
  }
}

type GlobalTracksContext = {
  globalTracks: GlobalTracksState;
  globalTracksWatchers: WatcherState;
  globalTracksDispatch: React.Dispatch<GlobalTracksAction>;
  manageGlobalTrackWatchers: ManageEntityWatchers<Track>;
  manageGlobalTracksWatchers: ManageEntitiesWatchers<Track>;
};

const GlobalTracksContext = createContext<GlobalTracksContext>(
  null as GlobalTracksContext,
);

export const GlobalTracksContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [globalTracks, globalTracksDispatch] = useReducer(tracksReducer, {
    tracks: new Map(),
    childTracks: new Map(),
  });

  const {
    activeWatchers: globalTracksWatchers,
    manageEntityWatchers: manageGlobalTrackWatchers,
    manageEnititiesWatchers: manageGlobalTracksWatchers,
  } = useWatcherManagement<Track>();

  return (
    <GlobalTracksContext.Provider
      value={{
        globalTracks,
        globalTracksWatchers,
        globalTracksDispatch,
        manageGlobalTrackWatchers,
        manageGlobalTracksWatchers,
      }}
    >
      {children}
    </GlobalTracksContext.Provider>
  );
};

export const useGlobalTracks = () => {
  const context = useContext(GlobalTracksContext);

  if (!context) {
    throw new Error(
      "useGlobalTracks must be used within a GlobalTracksContextProvider",
    );
  }

  return context;
};
