import "./PasswordRequired.scss";
import React, { useState } from "react";
import { ReactComponent as LogoFullSVG } from "App/common/icons-v2/logo-full-beta.svg";
import { Link } from "react-router-dom";
import { TextInput } from "App/common/TextInput";
import { BUTTON_THEME, Button } from "App/core/Button";
import { useToast } from "App/common/useToast";
import { LoadingSpinner } from "App/common/icons/LoadingSpinner";

interface PasswordRequiredProps {
  onSubmit: (password: string) => Promise<void>;
}

export const PasswordRequired = ({ onSubmit }: PasswordRequiredProps) => {
  const { addErrorMessage } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const handlePasswordChange = async () => {
    setIsLoading(true);
    try {
      await onSubmit(password);
    } catch (err) {
      addErrorMessage(err.message);
      setIsLoading(false);
    }
  };

  return (
    <div className="highnote-password-required">
      <div className="highnote-password-required-header">
        <Link to="/" data-cypress-id="highnote-logo" className="logo">
          <LogoFullSVG />
        </Link>
        <img src="/public/highnote-locked.png" />
      </div>
      <div className="highnote-password-required-body">
        <h2>Enter the password for this link</h2>
        <div>
          <TextInput
            isSingleLine
            isDisabled={isLoading}
            placeholder="Password"
            type="password"
            value={password}
            required
            onChange={setPassword}
            onSubmit={handlePasswordChange}
          />
          <Button
            disabled={isLoading || password.length < 1}
            theme={BUTTON_THEME.CTA}
            onClick={handlePasswordChange}
          >
            Continue
            {isLoading && <LoadingSpinner />}
          </Button>
        </div>
      </div>
    </div>
  );
};
