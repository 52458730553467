// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".luZHGq8qAiaPmd01_IOz{display:block;width:min-content;background-color:transparent;border-radius:3px}.luZHGq8qAiaPmd01_IOz:hover{border-color:#d0ced9;background-color:#33333d;color:#fff}.luZHGq8qAiaPmd01_IOz .L2ysWSfRpmIw1Kf_t471,.luZHGq8qAiaPmd01_IOz .zsV_SsPht1uqlYk99tYV{font-size:12px !important;line-height:17px !important;height:30px !important}.luZHGq8qAiaPmd01_IOz .L2ysWSfRpmIw1Kf_t471 svg:first-child,.luZHGq8qAiaPmd01_IOz .zsV_SsPht1uqlYk99tYV svg:first-child{position:relative !important;left:6px;fill:none;width:22px}", "",{"version":3,"sources":["webpack://./src/App/components/EntityLibrary/EntitySortMenu.module.scss"],"names":[],"mappings":"AAEA,sBACE,aAAA,CACA,iBAAA,CACA,4BAAA,CACA,iBAAA,CAEA,4BACE,oBAAA,CACA,wBAAA,CACA,UAAA,CAGF,wFAEE,yBAAA,CACA,2BAAA,CACA,sBAAA,CAEA,wHACE,4BAAA,CACA,QAAA,CACA,SAAA,CACA,UAAA","sourcesContent":["@import \"App/styles/_variables\";\n\n.highnote-entity-sort-select {\n  display: block;\n  width: min-content;\n  background-color: transparent;\n  border-radius: 3px;\n\n  &:hover {\n    border-color: #d0ced9;\n    background-color: #33333d;\n    color: white;\n  }\n\n  .sort-select,\n  .MuiSelect-select {\n    font-size: 12px !important;\n    line-height: 17px !important;\n    height: 30px !important;\n\n    svg:first-child {\n      position: relative !important;\n      left: 6px;\n      fill: none;\n      width: 22px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highnote-entity-sort-select": "luZHGq8qAiaPmd01_IOz",
	"sort-select": "L2ysWSfRpmIw1Kf_t471",
	"MuiSelect-select": "zsV_SsPht1uqlYk99tYV"
};
export default ___CSS_LOADER_EXPORT___;
