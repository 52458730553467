import "./ReplyStats.scss";
import { Comment } from "@highnote/server/src/core/entities";
import moment from "moment";
import React from "react";

export const ReplyStats = ({ replies }: { replies: Comment[] }) => {
  const counted: Record<Id, boolean> = {};
  const aliases: Record<Id, string> = {};
  const lastThreeRepliers: Id[] = [];

  // Order replies from newest to oldest.
  const orderedReplies = [...replies].reverse();

  orderedReplies.forEach((reply) => {
    const userId = reply.createdBy;
    if (counted[userId]) return;
    if (lastThreeRepliers.length === 3) return;
    lastThreeRepliers.push(userId);
    aliases[userId] = reply.createdByAlias;
    counted[userId] = true;
  });

  const lastCreatedAt = orderedReplies[0].createdAt;
  const momentStr = moment(lastCreatedAt).fromNow();

  return (
    <div className="reply-stats">
      <span className="count">
        {replies.length} {replies.length === 1 ? "reply" : "replies"}
      </span>
      <span className="moment">{momentStr}</span>
    </div>
  );
};
