import { useState, useEffect, useRef } from "react";
import { OnStateChange } from "./types";

export class Container<State> {
  state: State;
  __stateChangeCallbacks: OnStateChange<State>[];

  constructor() {
    this.__stateChangeCallbacks = [];
  }

  setState(update: Partial<State>) {
    const newState = { ...this.state } as State;

    const propsChanged: (keyof State)[] = [];
    Object.keys(update).forEach((_key: string) => {
      const key = _key as keyof State;

      if (update[key] !== this.state[key]) {
        propsChanged.push(key);
        newState[key] = update[key];
      }
    });

    if (propsChanged.length > 0) {
      this.state = newState;
      this.__stateChangeCallbacks.forEach((cb) => {
        cb(this.state, propsChanged);
      });
    }
  }

  onStateChange(callback: OnStateChange<State>) {
    const index = this.__stateChangeCallbacks.indexOf(callback);
    if (index < 0) {
      this.__stateChangeCallbacks.push(callback);
    }
  }

  offStateChange(callback: OnStateChange<State>) {
    const index = this.__stateChangeCallbacks.indexOf(callback);
    if (index > -1) {
      this.__stateChangeCallbacks.splice(index, 1);
    }
  }
}

export const useContainer = <T,>(container: Container<T>, props?: string[]) => {
  const [state, setState] = useState<T>(container?.state || ({} as T));
  const lastStateRef = useRef<T>(state);

  useEffect(() => {
    if (!container) return;

    setState(container.state);

    const onStateChange: OnStateChange<T> = (newState) => {
      let propsChanged = 0;

      Object.keys(newState).forEach((key) => {
        if (
          (!props || props.includes(key)) &&
          lastStateRef.current[key as keyof T] !== newState[key as keyof T]
        ) {
          propsChanged += 1;
        }
      });

      if (propsChanged) {
        setState(newState);
        lastStateRef.current = newState;
      }
    };

    container.onStateChange(onStateChange);

    return () => {
      container.offStateChange(onStateChange);
    };
  }, [container]);

  return state;
};
