import { v4 as uuidv4 } from "uuid";
import React, {
  ChangeEventHandler,
  MutableRefObject,
  useEffect,
  useRef,
} from "react";
import { useTheme } from "../ThemeProvider";
import { ToastMessageContent, useToast } from "../useToast";

import "./TextInput.scss";

const VARIANT = {
  DEFAULT: "default",
  INLINE: "inline",
};

export const TextInput = ({
  isDisabled = false,
  isReadOnly = false,
  value = "",
  onChange,
  onFocus,
  onSubmit,
  onClick,
  autoFocus,
  placeholder,
  maxLength,
  isSingleLine,
  variant = VARIANT.DEFAULT,
  type,
  required,
  startIcon,
  endIcon,
  inputId,
  inputRef: customInputRef,
}: {
  isDisabled?: boolean;
  isReadOnly?: boolean;
  value: string;
  onFocus?: () => void;
  onChange?: (val: string) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  onSubmit?: (
    e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  autoFocus?: boolean;
  placeholder?: string;
  maxLength?: number;
  isSingleLine?: boolean;
  variant?: string;
  type?: React.HTMLInputTypeAttribute;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  required?: boolean;
  inputId?: string;
  inputRef?: MutableRefObject<HTMLInputElement>;
}) => {
  const { theme } = useTheme();
  const { addMessage } = useToast();
  const textareaRef = useRef<HTMLTextAreaElement>();
  const inputRef = useRef<HTMLInputElement>();
  const elementRef = isSingleLine ? inputRef : textareaRef;
  const sanitizedValue = value ?? "";

  useEffect(() => {
    if (!elementRef.current) return;
    if (!autoFocus) return;
    if (isDisabled) return;
    elementRef.current.focus();
  }, [autoFocus, isDisabled]);

  const handleChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    const newValue = e.target.value;

    if (!maxLength || newValue.length <= maxLength) {
      onChange(newValue);
    }

    if (
      maxLength &&
      newValue.length >= maxLength &&
      value.length === maxLength
    ) {
      const toastId = uuidv4();
      addMessage({
        id: toastId,
        children: (
          <ToastMessageContent
            type="error"
            id={toastId}
            text={`Text exceeds maximum length of ${maxLength} characters.`}
          />
        ),
        expireAt: Date.now() + 3000, // 3 seconds from now
      });
      return;
    }
  };

  const onKeyDown: React.KeyboardEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    const enterKeyDown = e.key === "Enter";
    const shiftKeyDown = e.shiftKey;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isTouch = (window as any).isTouchDevice;

    if (!enterKeyDown) return;
    if (shiftKeyDown || isTouch) {
      if (variant === VARIANT.INLINE) e.preventDefault();
      return;
    }

    e.preventDefault();
    onSubmit && onSubmit(e);
  };

  const trimmedValue = maxLength
    ? sanitizedValue.slice(0, maxLength)
    : sanitizedValue;
  const htmlValue = trimmedValue.replace(/\n$/g, "\n\u00A0");
  const showCharCount = maxLength && sanitizedValue.length > maxLength * 0.5;

  return (
    <div
      className="text-input"
      data-is-disabled={isDisabled}
      data-show-char-count={showCharCount}
      data-is-read-only={isReadOnly}
      data-variant={variant}
      data-has-start-icon={!!startIcon}
      data-has-end-icon={!!endIcon}
      data-theme={theme}
    >
      <div className="invisible-text" data-is-single-line={isSingleLine}>
        {htmlValue}
      </div>
      {startIcon && <div className="start-icon">{startIcon}</div>}
      {isSingleLine ? (
        <input
          id={inputId}
          dir="auto"
          type={type || "text"}
          disabled={isDisabled || isReadOnly}
          ref={customInputRef || inputRef}
          placeholder={isDisabled ? "" : placeholder}
          onChange={handleChange}
          onFocus={onFocus}
          onClick={onClick}
          onKeyDown={onKeyDown}
          value={trimmedValue}
          required={required}
          name={inputId}
        />
      ) : (
        <textarea
          id={inputId}
          dir="auto"
          disabled={isDisabled || isReadOnly}
          ref={textareaRef}
          placeholder={isDisabled ? "" : placeholder}
          onFocus={onFocus}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          value={trimmedValue}
          required={required}
          name={inputId}
        ></textarea>
      )}
      {showCharCount && (
        <div className="char-count">
          {sanitizedValue.length} / {maxLength}
        </div>
      )}
      {endIcon && <div className="end-icon">{endIcon}</div>}
    </div>
  );
};

TextInput.VARIANT = VARIANT;
