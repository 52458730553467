import { ROLE } from "@highnote/server/src/core/entities";

export const getRoleSummary = (roles: ROLE[] = []) => {
  if (!roles) return "No access";
  if (roles.includes(ROLE.ADMIN) || roles.includes(ROLE.MANAGE)) {
    return "Can manage, comment, upload, and download";
  }

  const verbs = [];

  if (roles.includes(ROLE.COMMENT)) verbs.push("comment");
  if (roles.includes(ROLE.UPLOAD)) verbs.push("upload");
  if (roles.includes(ROLE.DOWNLOAD)) verbs.push("download");

  if (verbs.length === 0 && roles.includes(ROLE.VIEW)) {
    return "Can only view";
  }

  if (verbs.length === 1) return `Can only ${verbs[0]}`;
  if (verbs.length === 2) return `Can ${verbs[0]} and ${verbs[1]}`;
  if (verbs.length === 3) return "Can comment, upload, and download";

  return "No access";
};
