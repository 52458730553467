import {
  SUBSCRIPTION_PRICING,
  SUBSCRIPTION_TIER,
} from "@highnote/server/src/core/entities";

export type PlanConfig = {
  name: string;
  tier: SUBSCRIPTION_TIER;
  prices: string[];
  features: string[];
};

export const PLANS: PlanConfig[] = [
  {
    name: "Free",
    tier: SUBSCRIPTION_TIER.FREE,
    prices: [],
    features: ["15 total tracks", "50GB cloud storage", "unlimited sharing"],
  },
  {
    name: "Indie",
    tier: SUBSCRIPTION_TIER.INDIE,
    prices: Object.values(SUBSCRIPTION_PRICING[SUBSCRIPTION_TIER.INDIE]),
    features: [
      "500 total tracks",
      "1TB cloud storage",
      "Download prevention",
      "Password protected links",
    ],
  },
  {
    name: "Pro",
    tier: SUBSCRIPTION_TIER.PRO,
    prices: Object.values(SUBSCRIPTION_PRICING[SUBSCRIPTION_TIER.PRO]),
    features: ["1500 total tracks", "1TB cloud storage"],
  },
  {
    name: "Studio",
    tier: SUBSCRIPTION_TIER.STUDIO,
    prices: Object.values(SUBSCRIPTION_PRICING[SUBSCRIPTION_TIER.STUDIO]),
    features: ["Unlimited tracks", "5TB cloud storage", "Audio quality lock"],
  },
];
