import { firestore } from "./firebase";
import {
  collection,
  orderBy,
  query,
  where,
  getDocs,
  limit,
  startAfter,
} from "firebase/firestore";
import { COLLECTION_ID, Comment } from "../core/entities";

export const getCommentsBefore = async ({
  spaceId,
  trackId,
  createdAt,
}: {
  spaceId: Id;
  trackId: Id;
  createdAt: number;
}) => {
  const commentQuery = query(
    collection(firestore, COLLECTION_ID.COMMENT),
    where("spaceId", "==", spaceId),
    where("trackId", "==", trackId),
    orderBy("createdAt", "desc"),
    startAfter(createdAt),
    limit(50),
  );

  const snapshot = await getDocs(commentQuery);
  return snapshot.docs.map((s) => s.data()) as Comment[];
};
