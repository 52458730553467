import { AudioConfig, AudioSource } from "./types";
import { loadAudioSource } from "./util";

export class AudioLoader {
  onChange: () => void;
  audioSourcesById: Record<string, AudioSource>;
  abortControllersById: Record<string, AbortController>;
  audioSources: AudioSource[];

  constructor({ onChange }: { onChange?: () => void }) {
    this.onChange = onChange || (() => {});
    this.audioSourcesById = {};
    this.abortControllersById = {};
    this.audioSources = [];
  }

  loadAudio = async (config: AudioConfig) => {
    const existingSource = this.audioSourcesById[config.id];
    if (
      existingSource &&
      existingSource.sourceId === config.sourceId &&
      existingSource.startTime === config.startTime
    ) {
      return;
    }

    const { getAudioBuffer } = loadAudioSource(config.source);

    const newSource: AudioSource = {
      ...config,
    };
    this.audioSourcesById[config.id] = newSource;
    this.updateSources();

    // If you had previously initiated fetching a file for this track,
    // abort that request! New one incoming.
    const existingController = this.abortControllersById[config.id];
    if (existingController) existingController.abort();
    const abortController = new AbortController();
    this.abortControllersById[config.id] = abortController;

    getAudioBuffer({
      abortController,
      onDownloadProgress: (p) => {
        const loadProgress = p.loaded / p.total;
        // Don't let it go to 100% if we don't have the audio buffer yet.
        newSource.loadProgress = Math.min(0.99, loadProgress);
        this.updateSources();
      },
    })
      .then((audioBuffer) => {
        newSource.loadProgress = 1;
        newSource.audioBuffer = audioBuffer;
        this.updateSources();
      })
      .catch((error) => {
        newSource.error = `Could not load audio. ${error}`;
        this.updateSources();
      });
  };

  removeAudio = async (id: string) => {
    const source = this.audioSourcesById[id];
    if (!source) return;
    delete this.audioSourcesById[id];
    this.updateSources();
  };

  updateSources = () => {
    this.audioSources = Object.values(this.audioSourcesById);
    this.onChange();
  };
}
