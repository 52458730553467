import React, { useMemo, useState } from "react";
import Measure, { ContentRect } from "react-measure";
import styles from "./ExpandablePreview.module.scss";

const MAX_HEIGHT = 135;

export const ExpandablePreview = ({
  disabled,
  isExpanded,
  setExpanded,
  children,
}: {
  disabled: boolean;
  isExpanded: boolean;
  setExpanded: (value: boolean) => void;
  children: React.ReactNode;
}) => {
  const [outerHeight, setOuterHeight] = useState(0);
  const [innerHeight, setInnerHeight] = useState(0);

  const onOuterResize = (contentRect: ContentRect) => {
    setOuterHeight(contentRect.client.height);
  };

  const onInnerResize = (contentRect: ContentRect) => {
    setInnerHeight(contentRect.client.height);
  };

  const hasOverflow = useMemo(() => {
    return outerHeight < innerHeight;
  }, [outerHeight, innerHeight]);

  if (disabled) return <>{children}</>;

  return (
    <Measure client onResize={onOuterResize}>
      {({ measureRef: outerRef }) => (
        <div
          className={styles["expandable-preview"]}
          ref={outerRef}
          data-has-overflow={hasOverflow}
          data-is-expanded={isExpanded && innerHeight > MAX_HEIGHT}
          style={{
            maxHeight: isExpanded ? "none" : MAX_HEIGHT,
          }}
        >
          <Measure client onResize={onInnerResize}>
            {({ measureRef: innerRef }) => (
              <div className={styles.inner} ref={innerRef}>
                {children}
              </div>
            )}
          </Measure>

          <button
            className={styles["toggle-expanded"]}
            onClick={() => setExpanded(!isExpanded)}
          >
            See {isExpanded ? "less" : "more"}
          </button>
        </div>
      )}
    </Measure>
  );
};
