import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { TextInput } from "../../TextInput";
import { ReactComponent as AddAudioFileSVG } from "App/common/icons/add-audio-file.svg";
import { AudioFileSelect } from "../../FileSelect";
import { useSpaceContext } from "../../useSpace";
import {
  COMMENT_BLOCK_TYPE,
  Comment,
  DELETE_FIRESTORE_FIELD,
  FileBlockV2,
  FileEntity,
  PollBlockV2,
  PollOption,
  PollReplyBlock,
} from "@highnote/server/src/core/entities";
import { useTrack } from "App/components/useEntities/useTrack";
import { useSpaceCommentsContext } from "App/common/useSpaceComments";
import { useAuth } from "App/components/Auth";
import { PERMISSION } from "@highnote/server/src/core/shared-util";
import { PermissionTooltip } from "App/components/PermissionTooltip";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { getValidCommentBlock } from "App/common/useComment";
import { highnote } from "@highnote/server/src/sdk";
import { useFiles } from "App/components/useFiles";
import { CancelableProgress } from "App/common/CancelableProgress";
import { AudioPlayer } from "App/common/AudioPlayer";
import { watchAudioFiles } from "App/components/watchAudioFiles";
import { JOIN_ENTITY_TRIGGER } from "App/components/Auth/util";

const PollOption = ({
  isEditing,
  isSelected,
  index,
  data,
  votePercent,
  onChange,
  onSelect,
}: {
  isSelected: boolean;
  isEditing: boolean;
  index: number;
  votePercent: number;
  data: PollOption;
  onChange: (index: number, data: PollOption) => void;
  onSelect: (index: number) => void;
}) => {
  const { uploadFile, uploadsById } = useFiles();
  const { isAllowed } = useAuth();
  const { space } = useSpaceContext();
  const optionRef = useRef<HTMLDivElement>();
  const canVote = isAllowed(PERMISSION.TO_COMMENT_IN_SPACE, { space });
  const id = "abcdefghijklmnopqrstuvwxyz"[index];
  const savedFileId = (data as FileBlockV2).file;
  const [newFileId, setNewFileId] = useState<string>();
  const [file, setFile] = useState<FileEntity>();
  const unmounted = useRef<boolean>(false);
  const optionData = useRef<PollOption>(data);
  const fileOptionData = data as FileBlockV2;

  useEffect(
    () => () => {
      unmounted.current = true;
    },
    [],
  );

  useEffect(() => {
    optionData.current = data;
  }, [data]);

  useEffect(() => {
    if (!fileOptionData.file) return;

    const unwatch = watchAudioFiles([fileOptionData.file], (files) => {
      if (unmounted.current) return;
      const matchingFile = files.find((f) => f.id === fileOptionData.file);
      setFile(matchingFile);
    });

    return () => {
      unwatch();
    };
  }, [fileOptionData.file]);

  const onFileSelect = async (files: File[]) => {
    const selectedFile = files[0];
    if (!selectedFile) return;

    const fileId = uuidv4();
    setNewFileId(fileId);
    await uploadFile({
      file: selectedFile,
      props: { id: fileId },
    });

    optionData.current.type = COMMENT_BLOCK_TYPE.FILE;
    (optionData.current as FileBlockV2).file = fileId;
    onChange(index, optionData.current);
  };

  const onInputChange = (value: string) => {
    optionData.current.text = value;
    onChange(index, optionData.current);
  };

  const onClick: React.MouseEventHandler<HTMLDivElement> = () => {
    onSelect(index);
  };

  const upload = uploadsById[newFileId];

  return (
    <PermissionTooltip hasPermission={canVote}>
      <div
        className="option"
        ref={optionRef}
        onClick={canVote ? onClick : undefined}
        data-is-clickable={true}
        data-is-editing={isEditing}
        data-has-votes={votePercent > 0}
        data-is-selected={isSelected}
      >
        <div
          className="vote-percent"
          style={{ width: `${votePercent * 100}%` }}
        ></div>
        <div className="icon">{id}</div>
        <div className="option-content">
          {(!!data.text || isEditing) && (
            <TextInput
              isDisabled={!isEditing}
              placeholder={`Option ${index + 1}...`}
              value={data.text}
              maxLength={30}
              onChange={onInputChange}
              variant={TextInput.VARIANT.INLINE}
            />
          )}

          {isEditing && (
            <AudioFileSelect onSelect={onFileSelect}>
              <button>
                <AddAudioFileSVG />
              </button>
            </AudioFileSelect>
          )}

          {(savedFileId || file) && (
            <div className="file-preview" data-is-ready={!!file}>
              <AudioPlayer variant={AudioPlayer.VARIANT.COMPACT} file={file} />
            </div>
          )}

          {!file && upload && (
            <CancelableProgress
              name={upload.file.fileName}
              progress={upload.progress}
              error={upload.error}
              onCancel={upload.cancelUpload}
            />
          )}
        </div>
      </div>
    </PermissionTooltip>
  );
};

type Vote = {
  option: number;
  userId: string;
};

export const PollBlock = (props: {
  id: Id;
  createdBy: Id;
  replies: Comment[];
  timestamp: number;
  isEditing: boolean;
  data: PollBlockV2;
  onChange: (block: PollBlockV2, autosave?: boolean) => void;
  onSave?: (block: PollBlockV2) => void;
}) => {
  const { user: currentUser, joinEntity } = useAuth();
  const { spaceId, space } = useSpaceContext();
  const { addSpaceComment } = useSpaceCommentsContext();
  const { track, currentTrackVersion } = useTrack();
  const dataObj = useRef<PollBlockV2>(props.data);
  const [data, setData] = useState<PollBlockV2>(dataObj.current);

  useEffect(() => {
    dataObj.current = props.data;
    setData(dataObj.current);
  }, [props.data]);

  useEffect(() => {
    props.onChange(data);
  }, [data]);

  const onInputChange = (value: string) => {
    setData({ ...data, text: value });
  };

  const onOptionChange = (index: number, option: PollOption) => {
    dataObj.current.options[index] = option;
    setData({ ...dataObj.current });
  };

  const allVotes = (props.replies || [])
    .filter((reply) => {
      const block = getValidCommentBlock(reply);
      return block?.type === "poll-reply";
    })
    .map((reply) => {
      const block = getValidCommentBlock(reply) as PollReplyBlock;
      return {
        id: reply.id,
        option: block.option,
        userId: reply.createdBy,
      };
    });

  const votesByUserId: Record<string, Vote> = {};

  allVotes.forEach((vote) => {
    votesByUserId[vote.userId] = vote;
  });
  const validVotes = Object.values(votesByUserId).filter(
    (v) => v.option !== null,
  );

  // TODO: Clean up auth
  const currentUserId = currentUser
    ? currentUser.id
    : highnote.__firebaseAuth.currentUser?.uid;
  const currentUserVote = votesByUserId[currentUserId];

  const onOptionSelect = async (index: number) => {
    if (props.isEditing) return;
    let option = index;

    if (currentUserVote && currentUserVote.option === index) {
      option = DELETE_FIRESTORE_FIELD as unknown as number;
    }

    await addSpaceComment({
      id: uuidv4(),
      data: highnote.utils.generateCommentData({
        timestamp: props.timestamp,
        spaceId,
        trackId: track.id,
        trackVersionIds: [currentTrackVersion.id],
        blocks: [{ type: COMMENT_BLOCK_TYPE.POLL_REPLY, option }],
        parentComment: props.id,
      }),
    });

    joinEntity({
      entity: space,
      entityType: "Space",
      trigger: JOIN_ENTITY_TRIGGER.COMMENT,
    });
  };

  return (
    <ErrorBoundary name="PollBlock">
      <div className="poll-block" data-is-editing={props.isEditing}>
        <TextInput
          isDisabled={!props.isEditing}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          autoFocus={!(window as any).isTouchDevice}
          onSubmit={() => {
            props.onSave(data);
          }}
          placeholder="Type your question here..."
          value={data.text}
          onChange={onInputChange}
        />

        <div className="options">
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {(data.options || []).map((option: any, i) => {
            const numVotes = validVotes.filter((v) => v.option === i).length;
            const votePercent = numVotes / validVotes.length;
            const isSelected = currentUserVote && currentUserVote.option === i;

            return (
              <PollOption
                key={i}
                votePercent={votePercent}
                isSelected={isSelected}
                isEditing={props.isEditing}
                index={i}
                data={option}
                onChange={onOptionChange}
                onSelect={onOptionSelect}
              />
            );
          })}
        </div>
      </div>
    </ErrorBoundary>
  );
};
