import "./AuthForm.scss";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { ReactComponent as WarningSVG } from "App/common/icons/warning-hollow.svg";
import { ReactComponent as AuthEmailSVG } from "App/common/icons/auth-mail.svg";
import { ReactComponent as AuthGoogleSVG } from "App/common/icons/auth-google.svg";
import { ReactComponent as AuthAppleSVG } from "App/common/icons/auth-apple.svg";
import { ReactComponent as AuthPhoneSVG } from "App/common/icons/auth-phone.svg";
import { ReactComponent as UserSVG } from "App/common/icons/user-hollow.svg";

import { Button, ButtonProps, BUTTON_THEME } from "App/core/Button";
import {
  getIsInvitedUserLogin,
  linkAccount,
  logIn,
  loginWithProvider,
  useAuth,
} from ".";
import { THEME, ThemeProvider } from "App/common/ThemeProvider";
import { Callout, CALLOUT_LAYOUT } from "App/core/Callout";
import { AuthByPhone, PhoneAsAuthMethod } from "./AuthByPhone";
import {
  AUTH_PREFIX,
  SUBSCRIPTION_INTERVAL,
  SUBSCRIPTION_TIER,
} from "@highnote/server/src/core/entities";
import { Dialog } from "App/common/Dialog";
import { AuthByEmail } from "./AuthByEmail";
import LoadingScreen from "App/common/LoadingScreen/LoadingScreen";
import { useHistory } from "react-router";
import { JOIN_ENTITY_TRIGGER } from "./util";
import { EXISTING_AUTH_WITH_GOOGLE_ERR } from "@highnote/server/src/core/shared-util";
import { useToast } from "App/common/useToast";
import { PublicSpaceData } from "./useInviteSpaceData";

export enum AUTH_TYPE {
  JOIN = "join",
  LOG_IN = "log-in",
  SIGN_UP = "sign-up",
  LINK = "link",
}

export const AuthForm = ({
  returnTo,
  name: _name,
  email,
  getDirection,
  type = AUTH_TYPE.LOG_IN,
  hideWelcomeSurvey,
  signUpMetadata,
  subscription,
  couponCode,
  frequency,
  onAuth,
  trigger,
  title,
  subtitle,
  inviteSpaceData,
}: {
  returnTo?: string;
  name?: string;
  email?: string;
  getDirection?: (showEmail: boolean) => string;
  type?: AUTH_TYPE;
  hideWelcomeSurvey?: boolean;
  signUpMetadata?: object;
  subscription?: SUBSCRIPTION_TIER;
  couponCode?: string;
  frequency?: SUBSCRIPTION_INTERVAL;
  onAuth?: () => void;
  trigger?: JOIN_ENTITY_TRIGGER;
  title?: string;
  subtitle?: string;
  inviteSpaceData?: PublicSpaceData;
}) => {
  const history = useHistory();
  const { setHideWelcomeSurvey, refreshAuth } = useAuth();
  const { addErrorMessage } = useToast();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [isSignUp, setSignUp] = useState<boolean>(false);
  const [authMethod, setAuthMethod] = useState<AUTH_PREFIX>();
  const [highlightPhone, setHighlightPhone] = useState<boolean>(
    type !== AUTH_TYPE.LINK,
  );

  const [showEmail, setShowEmail] = useState<boolean>(
    type !== AUTH_TYPE.JOIN || trigger === JOIN_ENTITY_TRIGGER.BUTTON,
  );
  const emailDirection = type === AUTH_TYPE.JOIN && getDirection?.(showEmail);

  const submitButtonRef = useRef<HTMLButtonElement>();

  const [identifier, setIdentifier] = useState<string>("");
  const [name, setName] = useState<string>(_name || "");
  const [password, setPassword] = useState<string>("");

  const auth0Params = new URLSearchParams(location.hash.substring(1));
  const auth0Error = auth0Params.get("error")
    ? auth0Params.get("error_description") ||
      "Something went wrong. Please try again."
    : undefined;
  const [error, setError] = useState<string>(auth0Error);

  useEffect(() => {
    if (authMethod === AUTH_PREFIX.EMAIL && !!email) {
      setIdentifier(email);
    }
  }, [authMethod, email]);

  useEffect(() => {
    if (getIsInvitedUserLogin()) {
      setAuthMethod(AUTH_PREFIX.EMAIL);
    }
  }, []);

  useEffect(() => {
    setHighlightPhone(type !== AUTH_TYPE.LINK);
  }, [authMethod, type]);

  const submitForm = () => {
    submitButtonRef.current?.click();
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setError("");
      setSubmitting(true);

      let returnToPath = returnTo || "/";
      let method: "email" | "sms";
      if (authMethod === AUTH_PREFIX.EMAIL) method = "email";
      if (authMethod === AUTH_PREFIX.SMS) method = "sms";
      if (!method) {
        setError("No auth method provided.");
        setSubmitting(false);
      }

      if (isSignUp && !name && (method === "sms" || method === "email")) {
        setError("Create a display name to continue");
        setSubmitting(false);
        return;
      }
      if (isSignUp && method === "email" && password.length < 8) {
        setError("Your password must contain at least 8 characters");
        setSubmitting(false);
        return;
      }

      setLoading(true);
      try {
        if (type === AUTH_TYPE.LINK) {
          await linkAccount({
            method,
            identifier,
            password,
          });
        } else {
          const { redirectPath } = await logIn({
            method,
            name,
            identifier,
            password,
            metadata: signUpMetadata,
            subscription,
            coupon_code: couponCode,
            trigger,
            frequency: frequency?.toLowerCase() as SUBSCRIPTION_INTERVAL,
          });

          if (
            !returnToPath.startsWith("/space") &&
            !returnToPath.startsWith("/dropbox") &&
            redirectPath
          ) {
            returnToPath = redirectPath;
          }
        }
        if (hideWelcomeSurvey) {
          setHideWelcomeSurvey(hideWelcomeSurvey);
        }

        if (location.pathname !== returnToPath) {
          if (returnToPath.startsWith("http")) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).location.href = returnToPath;
          } else {
            history.push(returnToPath);
            refreshAuth();
          }
        }
        onAuth && onAuth();
      } catch (e) {
        if (e.message === EXISTING_AUTH_WITH_GOOGLE_ERR) {
          addErrorMessage(e.message, {
            title: "Account mismatch",
          });
          return;
        }
        console.log(e);
        setError(e.message);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }

      return false;
    },
    [type, identifier, password, name, signUpMetadata, returnTo],
  );

  const tokenProviders = useMemo(() => {
    return [
      {
        label: `${type === AUTH_TYPE.LINK ? "Link a" : "Continue with"} Google Account`,
        icon: <AuthGoogleSVG />,
        onClick: () => {
          setSubmitting(true);
          loginWithProvider({ connection: AUTH_PREFIX.GOOGLE, type });
        },
      },
      {
        label:
          type === AUTH_TYPE.LINK
            ? "Link an Apple Account"
            : "Continue with Apple",
        icon: <AuthAppleSVG />,
        onClick: () => {
          setSubmitting(true);
          loginWithProvider({ connection: AUTH_PREFIX.APPLE, type });
        },
      },
    ];
  }, [
    type,
    subscription,
    couponCode,
    frequency,
    returnTo,
    trigger,
    hideWelcomeSurvey,
  ]);

  return (
    <ThemeProvider theme={THEME.DARK}>
      {isLoading && <LoadingScreen fixed />}

      <div className="AuthForm" data-cypress-id="auth-form">
        <form onSubmit={onSubmit}>
          {error && (
            <Callout
              className="highnote-auth-form-error"
              layout={CALLOUT_LAYOUT.ROW}
              icon={<WarningSVG />}
              body={error}
            />
          )}

          {!authMethod && (
            <>
              {(type === AUTH_TYPE.SIGN_UP || type === AUTH_TYPE.LOG_IN) && (
                <>
                  <p className="auth-direction">
                    {title || "Log in or create a free account"}
                  </p>
                  <p className="auth-headline">
                    {subtitle || "The best way to share and discuss audio"}
                  </p>
                </>
              )}

              {emailDirection && (
                <p className="direction">{getDirection(showEmail)}</p>
              )}

              {highlightPhone ? (
                <>
                  <PhoneAsAuthMethod
                    identifier={identifier}
                    setIdentifier={setIdentifier}
                    onSubmit={(_needsSignUp) => {
                      setSignUp(_needsSignUp);
                      setAuthMethod(AUTH_PREFIX.SMS);
                    }}
                  />
                  {showEmail && <div className="divider">OR</div>}
                </>
              ) : (
                <>
                  <Button
                    type="button"
                    disabled={isSubmitting}
                    className="select-auth-method"
                    theme={BUTTON_THEME.SECONDARY}
                    onClick={() => {
                      setHighlightPhone(true);
                    }}
                  >
                    <AuthPhoneSVG />{" "}
                    {type === AUTH_TYPE.LINK ? "Link a" : "Continue with"} Phone
                    Number
                  </Button>
                  <div className="spacer" />
                </>
              )}

              {showEmail ? (
                <>
                  {tokenProviders.map(({ label, icon, onClick }) => {
                    return (
                      <React.Fragment key={label}>
                        <Button
                          type="button"
                          disabled={isSubmitting}
                          className="select-auth-method"
                          theme={BUTTON_THEME.SECONDARY}
                          onClick={onClick}
                        >
                          {icon} {label}
                        </Button>
                        <div className="spacer" />
                      </React.Fragment>
                    );
                  })}
                  <Button
                    type="button"
                    disabled={isSubmitting}
                    className="select-auth-method"
                    theme={BUTTON_THEME.SECONDARY}
                    onClick={() => {
                      setIdentifier(email || "");
                      setAuthMethod(AUTH_PREFIX.EMAIL);
                    }}
                  >
                    <AuthEmailSVG />{" "}
                    {type === AUTH_TYPE.LINK ? "Link an" : "Continue with"}{" "}
                    Email and Password
                  </Button>
                </>
              ) : (
                <>
                  <br />
                  <p className="hint">
                    <Button
                      theme={BUTTON_THEME.LINK}
                      onClick={() => setShowEmail(true)}
                    >
                      Use an email address instead
                    </Button>
                  </p>
                </>
              )}
            </>
          )}

          {authMethod === AUTH_PREFIX.EMAIL && (
            <AuthByEmail
              name={name}
              setName={setName}
              isSignUp={isSignUp}
              setSignUp={setSignUp}
              identifier={identifier}
              setIdentifier={setIdentifier}
              signUpCta={type === AUTH_TYPE.JOIN ? "Done" : "Sign Up"}
              password={password}
              setPassword={setPassword}
              onBack={() => {
                setAuthMethod(undefined);
                setIdentifier("");
                setPassword("");
              }}
              isSubmitting={isSubmitting}
              onSubmit={submitForm}
              inviteSpaceData={inviteSpaceData}
            />
          )}

          {authMethod === AUTH_PREFIX.SMS && (
            <AuthByPhone
              name={name}
              setName={setName}
              identifier={identifier}
              needsSignUp={isSignUp}
              signUpCta={type === AUTH_TYPE.JOIN ? "Done" : "Sign Up"}
              password={password}
              setPassword={setPassword}
              onBack={() => {
                setAuthMethod(undefined);
                setIdentifier("");
                setPassword("");
              }}
              isSubmitting={isSubmitting}
              onSubmit={submitForm}
            />
          )}

          <div style={{ visibility: "hidden", height: 0 }}>
            <button ref={submitButtonRef} type="submit"></button>
          </div>

          <div className="tos-note">
            By continuing you are registering and/or authenticating with
            Highnote and thereby accepting our{" "}
            <a
              style={{ textDecoration: "underline" }}
              href="https://www.highnote.fm/terms"
              target="_blank"
              rel="noreferrer"
            >
              terms of service
            </a>
            {" and "}
            <a
              style={{ textDecoration: "underline" }}
              href="https://www.highnote.fm/privacy"
              target="_blank"
              rel="noreferrer"
            >
              privacy&nbsp;policy
            </a>
            .
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
};

export const AuthFormDialogButton = ({
  returnTo,
  email,
  authType = AUTH_TYPE.LOG_IN,
  signUpMetadata,
  children,
  // Take this out to appease Typescript.
  // One day we may need to wrap this in a `forwardRef` instead.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref,
  ...rest
}: {
  returnTo?: string;
  email?: string;
  authType?: AUTH_TYPE;
  signUpMetadata?: object;
} & ButtonProps) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <Dialog
        className="AuthFormDialog"
        title={
          <>
            <UserSVG />
            &nbsp;Link Account
          </>
        }
        open={isOpen}
        onClose={() => setOpen(false)}
      >
        <AuthForm
          returnTo={returnTo}
          email={email}
          type={authType}
          signUpMetadata={signUpMetadata}
          onAuth={() => setOpen(false)}
        />
      </Dialog>

      <Button
        {...rest}
        onClick={async () => {
          setOpen(true);
        }}
      >
        {children}
      </Button>
    </>
  );
};
