// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".t0p13014vh2aN3KVC_6W{align-items:center;display:flex;justify-content:space-between;margin-top:10px}.t0p13014vh2aN3KVC_6W>.uUohsGLn1Z68hYoRcWy5.roTDWIB_K8sYZj2Ijqie{min-width:123px;width:123px}.ZjIhWJ0k4SEtSlc3ugnT{position:relative;margin-right:10px;width:100%}.ZjIhWJ0k4SEtSlc3ugnT>button{background-color:transparent;border:none;position:absolute;height:100%;top:0;right:0;padding-right:10px;cursor:pointer}.ZjIhWJ0k4SEtSlc3ugnT>button>svg{height:18px;width:18px}.ZjIhWJ0k4SEtSlc3ugnT>button>svg>path{fill:#fff}", "",{"version":3,"sources":["webpack://./src/App/components/EntityPasswordEditor.module.scss"],"names":[],"mappings":"AAEA,sBACE,kBAAA,CACA,YAAA,CACA,6BAAA,CACA,eAAA,CACA,iEACE,eAAA,CACA,WAAA,CAIJ,sBACE,iBAAA,CACA,iBAAA,CACA,UAAA,CACA,6BACE,4BAAA,CACA,WAAA,CACA,iBAAA,CACA,WAAA,CACA,KAAA,CACA,OAAA,CACA,kBAAA,CACA,cAAA,CACA,iCACE,WAAA,CACA,UAAA,CACA,sCACE,SAAA","sourcesContent":["@import \"App/styles/_variables.scss\";\n\n.password-input {\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n  margin-top: 10px;\n  > .highnote-button.password-input-btn {\n    min-width: 123px;\n    width: 123px;\n  }\n}\n\n.password-input-inner {\n  position: relative;\n  margin-right: 10px;\n  width: 100%;\n  > button {\n    background-color: transparent;\n    border: none;\n    position: absolute;\n    height: 100%;\n    top: 0;\n    right: 0;\n    padding-right: 10px;\n    cursor: pointer;\n    > svg {\n      height: 18px;\n      width: 18px;\n      > path {\n        fill: #fff;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"password-input": "t0p13014vh2aN3KVC_6W",
	"highnote-button": "uUohsGLn1Z68hYoRcWy5",
	"password-input-btn": "roTDWIB_K8sYZj2Ijqie",
	"password-input-inner": "ZjIhWJ0k4SEtSlc3ugnT"
};
export default ___CSS_LOADER_EXPORT___;
