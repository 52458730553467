import "./SpaceHomeV2.scss";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { THEME, ThemeProvider } from "App/common/ThemeProvider";
import { useSpaceContext } from "App/common/useSpace";
import { ReactComponent as LibrarySVG } from "App/common/icons/library.svg";
import { ReactComponent as UploadSVG } from "App/common/icons/upload.svg";
import { ReactComponent as DropboxSVG } from "App/common/icons/dropbox.svg";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { useAuth } from "App/components/Auth";
import {
  getEntitySubscribers,
  isSpacePrivateInboxEnabled,
  PERMISSION,
  stripExtension,
} from "@highnote/server/src/core/shared-util";
import { useHistory } from "react-router";
import {
  useGlobalAudioPlayer,
  useHTMLAudioPlayer,
} from "App/components/GlobalAudioPlayer";
import {
  GlobalAudioPlayer,
  GlobalAudioTrackPreview,
} from "App/components/GlobalAudioPlayer/GlobalAudioPlayer";
import { AddToSpaceMenu } from "./AddToSpace";
import {
  AttachmentsContextProvider,
  useAttachmentsContext,
} from "App/common/useAttachments";
import { EntitySortMenu } from "App/components/EntityLibrary/EntitySortMenu";
import { FileDrop } from "App/common/FileDrop";
import { AudioFileSelect, FileSelect } from "App/common/FileSelect";
import { UPLOAD_GROUP, useFiles } from "App/components/useFiles";
import { highnote } from "@highnote/server/src/sdk";
import { useAddToSpace } from "App/components/useEntities/useLibraryTracks/useAddToSpace";
import { SpaceHomeNavigation } from "./SpaceNavigation";
import { useToast } from "App/common/useToast";
import { useNotificationsContext } from "App/common/useNotifications";
import {
  AUDIO_ACCEPT_TYPES,
  COLLECTION_ID,
  JOB_STATUS,
  KNOCK_WORKFLOW,
  ROLE,
  Roles,
} from "@highnote/server/src/core/entities";
import { useEntityShareKeyInUrl } from "App/components/ShareDialog/Members/InviteByLink";
import { JOIN_ENTITY_TRIGGER } from "App/components/Auth/util";
import { SpaceItems } from "./SpaceItems";
import { useChildEntityWatchers } from "App/store/helpers/useChildEntityWatchers";
import { DownloadRequestsContextProvider } from "App/components/useEntities/useDownloadRequests";
import { useGlobalSpaces } from "App/store/spaces/useGlobalSpaces";
import { useGlobalTracks } from "App/store/tracks/useGlobalTracks";
import {
  DropboxJobsContextProvider,
  useDropboxJobsContext,
} from "App/components/useEntities/useDropboxDownloads";
import {
  DraftSpaceOnboardDialog,
  PrivateInboxVisitDialog,
} from "./SpaceDialogs";
import {
  APP_FEATURES,
  AppFeaturesStatus,
} from "@highnote/server/src/core/features";
import { useCreatedTracks } from "App/components/useEntities/useCreatedTracks";
import { JoinSpaceRequestDialog } from "./JoinSpaceRequestDialog";
import { ConnectedUserName } from "App/common/UserAvatar/UserAvatar";
import { useDropboxChooser } from "App/common/useDropboxChooser";
import { useUrlContext } from "../useUrlContext";
import { useMoveToSpace } from "App/components/useEntities/useLibraryTracks/useMoveToSpace";
import { useMobileAppParams } from "../useMobileAppParams";
import { SpaceHero } from "./SpaceHero/SpaceHero";
import { Container } from "App/common/Container/Container";
import { SpaceChatSidebar } from "App/components/Sidebar/SpaceChatSidebar";

const SpaceHomeUI = () => {
  const {
    isProcessing: isChooserProcessing,
    dropboxChooserReady,
    openDropboxChooser,
  } = useDropboxChooser();
  const { resetPromptStatus } = useDropboxJobsContext();
  const { spaceId, trackId } = useUrlContext();
  const { showAddToSpaceDialog, showMoveToSpaceDialog } = useMobileAppParams();
  const { openMoveToSpace } = useMoveToSpace();
  const { user, joinEntity } = useAuth();
  const { toasted } = useToast();
  const { space, itemSortType, setItemSortType } = useSpaceContext();
  const { globalSpaces } = useGlobalSpaces();
  const { globalTracks } = useGlobalTracks();
  const { createdTracks } = useCreatedTracks();
  const history = useHistory();
  const { getChildSpaces, getChildTracks } = useChildEntityWatchers();

  const searchParams = new URLSearchParams(location.search);
  const requester = searchParams.get("requester");

  const subscribers = getEntitySubscribers(space);
  const isMember = subscribers.includes(user?.id);
  const isInboxGuest = isSpacePrivateInboxEnabled(space) && !isMember;

  const childSpaces = useMemo(
    () => getChildSpaces(space?.id),
    [globalSpaces.childSpaces.get(space.id)],
  );

  const spaceTracks = useMemo(() => {
    return isInboxGuest ? createdTracks : getChildTracks(space?.id);
  }, [isInboxGuest, globalTracks.childTracks.get(space.id), createdTracks]);

  const spaceAttachments = useMemo(() => {
    return isInboxGuest ? [] : space?.files || [];
  }, [isInboxGuest, space?.files]);

  const { isAllowed } = useAuth();
  const { markAsRead, notifications } = useNotificationsContext();
  const { uploadFiles, getUploadCache, removeUpload } = useFiles();
  const { attachFileToContext } = useAttachmentsContext();
  const { openAddToSpace } = useAddToSpace();
  useEntityShareKeyInUrl(space);

  const [isPrivateInboxModalOpen, setIsPrivateInboxModalOpen] = useState(false);
  const [isDraftSpaceModalOpen, setIsDraftSpaceModalOpen] = useState(false);
  const [isJoinSpaceRequestDialogOpen, setIsJoinSpaceRequestDialogOpen] =
    useState(Boolean(requester));

  useEffect(() => {
    const unlisten = history.listen(() => {
      const searchParams = new URLSearchParams(location.search);
      setIsJoinSpaceRequestDialogOpen(Boolean(searchParams.get("requester")));
    });
    return () => {
      unlisten();
    };
  }, []);

  const fileDragRef = useRef<HTMLDivElement>();
  const unmountedRef = useRef<boolean>(false);

  const canAdd = isAllowed(PERMISSION.TO_ADD_TO_SPACE, { space });

  const handleOpenAddToSpaceDialog = () => {
    openAddToSpace(space);
  };

  useEffect(
    () => () => {
      unmountedRef.current = true;
    },
    [],
  );

  useEffect(() => {
    if (showAddToSpaceDialog && space) {
      handleOpenAddToSpaceDialog();
    }
  }, [showAddToSpaceDialog, space]);

  useEffect(() => {
    if (showMoveToSpaceDialog && spaceId && !trackId && space) {
      openMoveToSpace({
        entity: space,
        entityType: COLLECTION_ID.SPACE,
      });
    }
  }, [spaceId, trackId, showMoveToSpaceDialog, space]);

  useEffect(() => {
    if (!space) return;
    const unreads = notifications.filter(
      (n) =>
        n.source.key === KNOCK_WORKFLOW.SPACE_MEMBER_ADDED &&
        n.data.spaceId === space?.id &&
        !n.read_at,
    );
    markAsRead(unreads);
  }, [space, notifications]);

  const onApproveJoinSpaceRequest = useCallback(
    async (userId: string) => {
      const newRoles: Roles = {
        [`rolesV2,${userId}`]: [
          ROLE.VIEW,
          ROLE.COMMENT,
          ROLE.UPLOAD,
          ROLE.DOWNLOAD,
        ],
      };

      await toasted({
        promise: highnote.updateSpace({
          id: space.id,
          data: newRoles,
        }),
        successMessage: (
          <span>
            <ConnectedUserName userId={searchParams.get("requester")} />
            {"  "}
            {`was added to ${space.name}.`}
          </span>
        ),
        errorMessage: (
          <span>
            <ConnectedUserName userId={searchParams.get("requester")} />
            {"  "}
            {`could not be added to ${space.name}.`}
          </span>
        ),
      })
        .then(() => setIsJoinSpaceRequestDialogOpen(undefined))
        .finally(() =>
          setTimeout(() => {
            history.replace(`/space/${space.id}`);
          }, 500),
        );
    },
    [toasted, space],
  );

  const onUploadFiles = useCallback(
    async (files: File[]) => {
      // if the user has already been introduced to the Private Inbox,
      if (isPrivateInboxModalOpen) {
        setIsPrivateInboxModalOpen(false);
      }

      if (files.length < 1) return;
      const uploadPayloads = files.map((file) => {
        const asTrack = AUDIO_ACCEPT_TYPES.includes(file.type);
        const cache = asTrack
          ? getUploadCache(UPLOAD_GROUP.TRACKS_BY_SPACE, space?.id)
          : getUploadCache(UPLOAD_GROUP.ATTACHMENTS_BY_SPACE, space?.id);
        return {
          file,
          cache,
        };
      });

      await uploadFiles({
        payloads: uploadPayloads,
        onSuccess: async (file, fileEntity) => {
          const asTrack = AUDIO_ACCEPT_TYPES.includes(file.type);
          if (asTrack) {
            await highnote.createTrack({
              id: uuidv4(),
              data: {
                title: stripExtension(fileEntity.fileName),
                versionFilesV2: [fileEntity.id],
                spaceId: space?.id,
              },
            });
          } else {
            await attachFileToContext(fileEntity.id);
          }

          joinEntity({
            entity: space,
            entityType: "Space",
            trigger: JOIN_ENTITY_TRIGGER.UPLOAD,
          });

          removeUpload(fileEntity.id);
        },
      });
    },
    [space?.id, user],
  );

  const spaceItems = [...spaceTracks, ...childSpaces, ...spaceAttachments];

  useEffect(() => {
    if (!user || !space) return;

    const draftSpaceOnboarded = Boolean(user?.draftSpaceOnboarded);
    if (
      !draftSpaceOnboarded &&
      space.isDraftSpace &&
      space.createdBy === user.id
    ) {
      setIsDraftSpaceModalOpen(true);
    }

    const hasIntroducedToPrivateInbox = Boolean(
      user?.hasIntroducedToPrivateInbox,
    );
    const isPrivateInboxEnabled = isSpacePrivateInboxEnabled(space);
    if (!hasIntroducedToPrivateInbox && isPrivateInboxEnabled) {
      setIsPrivateInboxModalOpen(true);
    }
  }, [user, space]);

  const addFromDropboxToSpace = () => {
    openDropboxChooser({
      onSuccess: async (fileIds: string[]) => {
        await highnote.cloneDropboxEntities({
          fileIds,
          spaceId: space.id,
        });
        resetPromptStatus();
      },
    });
  };

  return (
    <ThemeProvider theme={THEME.DARK}>
      <div
        className="highnote-space-home"
        data-cypress-id="highnote-space-home"
      >
        {canAdd && (
          <FileDrop
            dragRef={fileDragRef}
            onDrop={onUploadFiles}
            instruction={"Add files to this Space by dropping them here"}
          />
        )}

        {AppFeaturesStatus[APP_FEATURES.PRIVATE_INBOX] && (
          <PrivateInboxVisitDialog
            spaceOwner={space.createdBy}
            isOpen={isPrivateInboxModalOpen}
            onUploadFiles={onUploadFiles}
            onClose={() => {
              setIsPrivateInboxModalOpen(false);
              highnote.updateUser({
                id: user.id,
                data: { hasIntroducedToPrivateInbox: true },
              });
            }}
          />
        )}
        <DraftSpaceOnboardDialog
          isOpen={isDraftSpaceModalOpen}
          onClose={() => {
            setIsDraftSpaceModalOpen(false);

            highnote.updateUser({
              id: user.id,
              data: { draftSpaceOnboarded: true },
            });
          }}
        />

        {isJoinSpaceRequestDialogOpen && (
          <JoinSpaceRequestDialog
            onClose={() => {
              const searchParams = new URLSearchParams(location.search);
              searchParams.delete("requester");
              history.replace(
                location.pathname + "?" + searchParams.toString(),
              );
              setIsJoinSpaceRequestDialogOpen(false);
            }}
            requesterId={requester}
            spaceName={space.name}
            onApprove={onApproveJoinSpaceRequest}
            onDeny={() => {
              setIsJoinSpaceRequestDialogOpen(false);
              history.replace(`/space/${space.id}`);
            }}
          />
        )}

        <SpaceHomeNavigation />

        <div className="content-container">
          <div className="scrollable">
            <Container variant="container-xl">
              <SpaceHero spaceItemsCount={spaceItems.length} />
            </Container>

            {canAdd && (
              <div className="space-action-buttons">
                <div className="inner">
                  {isInboxGuest ? (
                    <AudioFileSelect allowMultiple onSelect={onUploadFiles}>
                      <Button size={BUTTON_SIZE.SMALL} theme={BUTTON_THEME.CTA}>
                        <UploadSVG /> Upload
                      </Button>
                    </AudioFileSelect>
                  ) : (
                    <FileSelect allowMultiple onSelect={onUploadFiles}>
                      <Button size={BUTTON_SIZE.SMALL} theme={BUTTON_THEME.CTA}>
                        <UploadSVG />
                        Upload
                      </Button>
                    </FileSelect>
                  )}

                  <AddToSpaceMenu />

                  {!!user && (
                    <Button
                      size={BUTTON_SIZE.SMALL}
                      theme={BUTTON_THEME.SECONDARY}
                      onClick={handleOpenAddToSpaceDialog}
                    >
                      <LibrarySVG />
                      Add from Library
                    </Button>
                  )}
                  {dropboxChooserReady && (
                    <Button
                      size={BUTTON_SIZE.SMALL}
                      theme={BUTTON_THEME.SECONDARY}
                      disabled={isChooserProcessing}
                      onClick={addFromDropboxToSpace}
                    >
                      <DropboxSVG />
                      Add from Dropbox
                    </Button>
                  )}
                </div>
              </div>
            )}

            <div className="outer-library-container" ref={fileDragRef}>
              <div
                className="entity-library-container"
                data-cypress-id="library-container"
              >
                {spaceItems.length > 0 && (
                  <div className="count-sort-container">
                    <div className="entity-count">
                      {spaceItems.length} Items
                    </div>
                    {isMember && (
                      <EntitySortMenu
                        sortType={itemSortType}
                        onChange={setItemSortType}
                      />
                    )}
                  </div>
                )}
                <SpaceItems sortType={itemSortType} />
              </div>
            </div>
          </div>
          <div className="entity-space-chat-container">
            <div className="entity-space-chat">
              <SpaceChatSidebar isOutsideSidebar />
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

const SpaceHomePlayer = () => {
  const history = useHistory();
  const { spaceId } = useSpaceContext();
  const { goToTrack, nowPlaying, prevTrackId } = useGlobalAudioPlayer();
  const { useHTMLPlayer, currentTime, onSeek } = useHTMLAudioPlayer();

  const playbackProps = {
    ...(useHTMLPlayer && {
      onPrev: () => {
        if (currentTime > 1) {
          onSeek(0);
          return;
        }
        goToTrack(prevTrackId);
      },
    }),
  };

  const preview = useMemo(() => {
    const track = nowPlaying.track;

    return (
      <GlobalAudioTrackPreview
        track={track}
        onClick={() => {
          history.push(`/space/${spaceId}/${track.id}`);
        }}
      />
    );
  }, [nowPlaying.track, spaceId]);

  return <GlobalAudioPlayer preview={preview} {...playbackProps} />;
};

export const SpaceHome = () => {
  const { space } = useSpaceContext();
  return (
    <AttachmentsContextProvider space={space} attachmentIds={space?.files}>
      <DownloadRequestsContextProvider isProcessing>
        <DropboxJobsContextProvider
          spaceId={space?.id}
          statuses={[
            JOB_STATUS.PENDING,
            JOB_STATUS.IN_PROGRESS,
            JOB_STATUS.FAILED,
          ]}
        >
          <SpaceHomeUI />
        </DropboxJobsContextProvider>
      </DownloadRequestsContextProvider>
      <SpaceHomePlayer />
    </AttachmentsContextProvider>
  );
};
