import "./CommentCardPreview.scss";
import React, { useRef } from "react";
import { useCarouselSync } from "App/routes/Main/Space/Carousel/useCarouselSync";
import { useSpaceContext } from "../useSpace";
import { CommentContent } from "./CommentContent";
import { CommentContextProvider, useCommentContext } from "../useComment";
import { ReplyStats } from "./ReplyStats";
import { CommentMeta } from "./CommentMeta";
import { useAuth } from "App/components/Auth";

import { PERMISSION } from "@highnote/server/src/core/shared-util";
import { useFocusOnCard } from "../useFocusedCard";
import { useViewport } from "../useViewport";

const _CommentCardPreview = () => {
  const { focusOnCard } = useFocusOnCard();
  const { vw } = useViewport();
  const sync = useCarouselSync();
  const centeredCardId = sync.getCenteredCardId();
  const { comment, replies } = useCommentContext();
  const { isAllowed } = useAuth();
  const { space } = useSpaceContext();
  const scrollableRef = useRef<HTMLDivElement>();
  const isMobile = vw <= 768;

  if (!comment) return null;

  const canReplyToCommentInSpace = isAllowed(
    PERMISSION.TO_REPLY_TO_COMMENT_IN_SPACE,
    { space, comment },
  );

  const openReplies: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    focusOnCard(comment.id, undefined, { expand: true });
  };

  return (
    <div
      className="highnote-comment-card-preview"
      data-cypress-id="comment-card-preview"
      data-comment-id={comment.id}
      data-is-centered={centeredCardId === comment.id}
      data-is-resolved={!!comment.isResolved}
      data-is-deleted={comment.isDeleted}
      onClick={() => {
        if (!isMobile && centeredCardId === comment.id) {
          focusOnCard(comment.id);
        } else {
          focusOnCard().then(() => {
            sync.onCardIdClick(comment.id);
          });
        }
      }}
    >
      <div className="comment-wrapper">
        <div className="meta-wrapper">
          <CommentMeta />
        </div>

        <div className="scrollable" ref={scrollableRef}>
          <div className="root-comment">
            <CommentMeta />
            <CommentContent isCollapsible />
          </div>
        </div>

        <div className="reply-buttons">
          {!!replies.length && (
            <button className="reply-stats-button" onClick={openReplies}>
              <ReplyStats replies={replies} />
            </button>
          )}
          {canReplyToCommentInSpace && (
            <button className="reply-button" onClick={openReplies}>
              Reply
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export const CommentCardPreview = ({ id }: { id: string }) => {
  return (
    <CommentContextProvider id={id}>
      <_CommentCardPreview />
    </CommentContextProvider>
  );
};
