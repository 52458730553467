import React, { Fragment, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router";
import { KNOCK_WORKFLOW, ROLE } from "@highnote/server/src/core/entities";
import {
  getEntityShareKeys,
  getEntitySubscribers,
  isSpacePrivateInboxEnabled,
  PERMISSION,
} from "@highnote/server/src/core/shared-util";
import { useJoinEntityButton } from "App/common/AuthButtons";
import { Breadcrumbs } from "App/common/Breadcrumbs";
import { ReactComponent as ChatSVG } from "App/common/icons/chat.svg";
import { ReactComponent as TrackCommentSVG } from "App/common/icons-v2/comment-stack-line.svg";
import { ReactComponent as LinkSVG } from "App/common/icons-v2/link.svg";
import { useNotificationsContext } from "App/common/useNotifications";
import { useSpaceContext } from "App/common/useSpace";
import { useAuth } from "App/components/Auth";
import { TrackDownloadButton } from "App/components/EntityTable/EntityShortcut";
import { Navigation, NavigationToolbar } from "App/components/Navigation";
import { useShareDialog } from "App/components/ShareDialog";
import { setSidebarUrl, SIDEBAR_ID } from "App/components/Sidebar";
import { SpaceMemberPreview } from "App/components/SpaceTools";
import { useCreatedTracks } from "App/components/useEntities/useCreatedTracks";
import { useTrack, useTrackFiles } from "App/components/useEntities/useTrack";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { PrivateInboxUploadSuccessfullyDialog } from "./PrivateInboxUploadSuccesfullyDialog";
import { useMobileAppParams } from "../useMobileAppParams";
import "./SpaceNavigation.scss";
import { UnreadDotV2 } from "App/common/UnreadDotV2";
import { TrackCommentSidebar } from "App/components/Sidebar/TrackCommentSidebar";
import { DefaultSpaceActions } from "App/common/SpaceActions";

const SpaceDetailsButton = () => {
  const { space } = useSpaceContext();
  return <DefaultSpaceActions space={space} />;
};

const SpaceChatToolbarButton = () => {
  const history = useHistory();
  const { space } = useSpaceContext();
  const { notifications } = useNotificationsContext();

  const unreads = useMemo(
    () =>
      notifications.filter(
        (n) =>
          n.source.key === KNOCK_WORKFLOW.CHAT_COMMENT_ADDED &&
          n.data.spaceId === space?.id &&
          !n.read_at,
      ),
    [space?.id, notifications],
  );

  return (
    <span className="space-navigation-chat">
      {unreads.length > 0 && <UnreadDotV2 position="left" />}
      <Button
        className="chat-button"
        size={BUTTON_SIZE.SMALL}
        theme={BUTTON_THEME.SECONDARY}
        onClick={() => setSidebarUrl(SIDEBAR_ID.SPACE_CHAT, history)}
      >
        <ChatSVG />
        <span className="label">Chat</span>
      </Button>
    </span>
  );
};

const TrackCommentsToolbarButton = ({
  onOpenTrackComments,
}: {
  onOpenTrackComments: () => void;
}) => {
  return (
    <Button
      className="chat-button track-comment-toolbar-button svg-stroke-only-container"
      size={BUTTON_SIZE.LARGE}
      theme={BUTTON_THEME.ICON}
      onClick={onOpenTrackComments}
      aria-label="Toggle Track Comments"
    >
      <TrackCommentSVG />
    </Button>
  );
};

type Props = {
  hasChatButton?: boolean;
  onOpenTrackComments?: () => void;
};

const SpaceNavigationToolbar = ({
  hasChatButton,
  onOpenTrackComments,
}: Props) => {
  const { showShareDialog } = useMobileAppParams();
  const { space } = useSpaceContext();
  const { track, trackArtworkUrl } = useTrack();
  const { openShareDialog, renderShareDialog } = useShareDialog();
  const { user, isAllowed, isPublicView } = useAuth();
  const { createdTracks } = useCreatedTracks();
  const isLoggedIn = !!user;

  const subscribers = getEntitySubscribers(space);
  const isMember = subscribers.includes(user?.id);
  const isInboxEnabled = isSpacePrivateInboxEnabled(space);
  const joinSpaceButton = useJoinEntityButton(space, "Space");

  const [showUploadSuccessDialog, setShowUploadSuccessDialog] =
    useState<boolean>(false);

  const canDownload = isAllowed(PERMISSION.TO_DOWNLOAD_TRACK_IN_SPACE, {
    track,
    space,
  });

  const handleOpenShareDialog = () => openShareDialog(space);

  useEffect(() => {
    if (showShareDialog && space) {
      handleOpenShareDialog();
    }
  }, [showShareDialog, space]);

  const {
    isSpaceShared,
    hasMembersSpaceNotShared,
    hasMembersSpaceShared,
    noMembersSpaceNotShared,
  } = useMemo(() => {
    // Get all the valid share keys (must have roles attached).
    const entityRoles = space?.rolesV2 || {};
    const [shareKey] = getEntityShareKeys(space);
    const shareRoles = entityRoles[shareKey] || [];
    const isSpaceShared = shareRoles.includes(ROLE.VIEW);

    // Filter out the "share_" prefixed keys to identify actual user members
    const memberKeys = Object.keys(entityRoles).filter(
      (key) => !key.startsWith("share_"),
    );
    const hasMembers = memberKeys.some(
      (key) => Array.isArray(entityRoles[key]) && entityRoles[key].length > 0,
    );

    const hasMembersSpaceShared = hasMembers && isSpaceShared;
    const hasMembersSpaceNotShared = hasMembers && !isSpaceShared;
    const noMembersSpaceNotShared = !hasMembers && !isSpaceShared;

    return {
      isSpaceShared,
      hasMembersSpaceNotShared,
      hasMembersSpaceShared,
      noMembersSpaceNotShared,
    };
  }, [space]);

  const toolbarItems = useMemo(() => {
    const items: React.ReactElement[] = [];
    if (isMember) {
      if (hasMembersSpaceNotShared || hasMembersSpaceShared) {
        items.push(<SpaceMemberPreview onClick={handleOpenShareDialog} />);
      } else {
        items.push(
          <div className="share-label">
            {noMembersSpaceNotShared ? "Only you" : "Link on"}
          </div>,
        );
      }
    } else if (isInboxEnabled && createdTracks.length > 1 && !isMember) {
      // Only appear to guests if they have new uploads
      items.push(
        <Fragment>
          <Button
            theme={BUTTON_THEME.CTA}
            size={BUTTON_SIZE.SMALL}
            onClick={() => setShowUploadSuccessDialog(true)}
          >
            Done
          </Button>
          <PrivateInboxUploadSuccessfullyDialog
            isOpen={showUploadSuccessDialog}
            redirectUrl={space.privateInboxRedirectUrl}
            onClose={() => setShowUploadSuccessDialog(false)}
            spaceOwner={space.createdBy}
          />
        </Fragment>,
      );

      return items;
    }

    if (isMember) {
      items.push(
        <>
          <Button
            className="share"
            size={BUTTON_SIZE.LARGE}
            theme={BUTTON_THEME.ICON}
            onClick={handleOpenShareDialog}
            aria-label={`Open Share Space Details - ${isSpaceShared ? "Share Link enabled" : "Share Link disabled"}`}
          >
            <LinkSVG />
            <div
              className={classNames("space-enabled-indicator", {
                ["space-disabled-indicator"]: !isSpaceShared,
              })}
            />
          </Button>
        </>,
      );
    }

    // Download button should only show on Track pages
    if (canDownload && !!track) {
      items.push(
        <TrackDownloadButton
          className="track-navigation-download-button"
          track={track}
          trackArtworkUrl={trackArtworkUrl}
          showDownloadSpace
        />,
      );
    }

    if (
      hasChatButton &&
      (isAllowed(PERMISSION.TO_COMMENT_IN_SPACE, { space }) ||
        !isPublicView(space))
    ) {
      items.push(<SpaceChatToolbarButton />);
    }

    if (
      onOpenTrackComments &&
      // TODO: What permission should be used here?
      (isAllowed(PERMISSION.TO_COMMENT_IN_SPACE, { space }) ||
        !isPublicView(space))
    ) {
      items.push(
        <TrackCommentsToolbarButton
          onOpenTrackComments={onOpenTrackComments}
        />,
      );
    }

    if (isMember) {
      items.push(<SpaceDetailsButton />);
    }

    if (!isMember && !isInboxEnabled) {
      items.push(joinSpaceButton);
    }
    return items;
  }, [isLoggedIn, space, joinSpaceButton]);

  return (
    <>
      <NavigationToolbar items={toolbarItems} />
      {renderShareDialog}
    </>
  );
};

export const SpaceHomeNavigation = () => {
  const { space } = useSpaceContext();
  const { user } = useAuth();
  const isLoggedIn = !!user;
  const subscribers = getEntitySubscribers(space);
  const isMember = subscribers.includes(user?.id);

  return (
    <Navigation
      className="space-navigation space-home-navigation"
      showIndent
      showLogo={!isLoggedIn}
    >
      <Breadcrumbs
        componentId="breadcrumbs-space-home"
        activeSpace={space}
        hideLibrary={!isMember}
      />
      <SpaceNavigationToolbar hasChatButton={Boolean(space?.chatEnabled)} />
    </Navigation>
  );
};

export const SpaceTrackNavigation = () => {
  const [openCommentSidebar, setOpenCommentSidebar] = useState(false);
  const { space } = useSpaceContext();
  const { track } = useTrack();
  const { currentTrackPageVersion } = useTrackFiles(track);
  const { user } = useAuth();
  const isLoggedIn = !!user;
  const subscribers = getEntitySubscribers(space);
  const isMember = subscribers.includes(user?.id);

  return (
    <Navigation
      className="space-navigation space-track-navigation"
      showLogo={!isLoggedIn}
      showIndent
    >
      <Breadcrumbs
        activeSpace={space}
        activeTrack={track}
        activeTrackVersion={currentTrackPageVersion}
        hideLibrary={!isMember}
        componentId="breadcrumbs-space-track"
      />
      <SpaceNavigationToolbar
        onOpenTrackComments={() => setOpenCommentSidebar(true)}
      />
      <TrackCommentSidebar
        isSidebarOpen={openCommentSidebar}
        onCloseSidebar={() => setOpenCommentSidebar(false)}
      />
    </Navigation>
  );
};
