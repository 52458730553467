import "./styles.scss";
import { Track } from "@highnote/server/src/core/entities";
import { TracksProvider, useTracks } from "App/components/useEntities";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { where } from "firebase/firestore";
import { useAuth } from "App/components/Auth";

type LibraryTracksContextValue = {
  libraryTracks: Track[];
  libraryTracksLoading: boolean;
  totalLimitLibraryTracks: number;
  loadMoreLibraryTracks: () => void;
};

const LibraryTracksContext = createContext<LibraryTracksContextValue>({
  libraryTracks: [],
  libraryTracksLoading: true,
  totalLimitLibraryTracks: 0,
  loadMoreLibraryTracks: () => undefined,
});

const _LibraryTracksContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [libraryTracks, setLibraryTracks] = useState<Track[]>([]);
  const {
    entities: tracks,
    loading: libraryTracksLoading,
    loadMore: loadMoreLibraryTracks,
    totalLimit: totalLimitLibraryTracks,
  } = useTracks();

  useEffect(() => {
    const sortedTracks = tracks.sort((a, b) => (a.title < b.title ? 1 : -1));
    setLibraryTracks(sortedTracks);
  }, [tracks]);

  const value = useMemo(
    () => ({
      libraryTracks,
      libraryTracksLoading,
      loadMoreLibraryTracks,
      totalLimitLibraryTracks,
    }),
    [
      libraryTracks,
      libraryTracksLoading,
      loadMoreLibraryTracks,
      totalLimitLibraryTracks,
    ],
  );

  return (
    <LibraryTracksContext.Provider value={value}>
      {children}
    </LibraryTracksContext.Provider>
  );
};

export const LibraryTracksContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user } = useAuth();
  const constraints = useMemo(() => {
    if (!user?.id) return [];
    return [where("createdBy", "==", user.id)];
  }, [user?.id]);

  return (
    <TracksProvider constraints={constraints} limit={100}>
      <_LibraryTracksContextProvider>{children}</_LibraryTracksContextProvider>
    </TracksProvider>
  );
};

export const useLibraryTracks = () => useContext(LibraryTracksContext);
