import React, { useEffect } from "react";
import { SelectionType } from "@highnote/server/src/functions/handlers/selections/common";
import { createContext, useContext, useState } from "react";

type EntitiesSelectionContext = {
  selectedEntities: Record<
    SelectionType["entityId"],
    SelectionType["entityType"]
  >;
  isSelectionEnabled: boolean;
  addToSelection: (entity: SelectionType) => void;
  removeFromSelection: (entityId: string) => void;
  enableSelection: () => void;
  disableSelection: () => void;
};

export const EntitiesSelectionContext = createContext<EntitiesSelectionContext>(
  {
    selectedEntities: {},
    isSelectionEnabled: false,
    addToSelection: () => {},
    removeFromSelection: () => {},
    enableSelection: () => {},
    disableSelection: () => {},
  },
);

export const EntitiesSelectionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [selectedEntities, setSelectedEntities] = useState<
    Record<SelectionType["entityId"], SelectionType["entityType"]>
  >({});
  const [isSelectionEnabled, setIsSelectionEnabled] = useState(false);

  const addToSelection = (entity: SelectionType) => {
    setSelectedEntities((prev) => ({
      ...prev,
      [entity.entityId]: entity.entityType,
    }));
  };

  const removeFromSelection = (entityId: string) => {
    setSelectedEntities((prev) => {
      const next = { ...prev };
      delete next[entityId];
      return next;
    });
  };

  const enableSelection = () => {
    setIsSelectionEnabled(true);
  };

  const disableSelection = () => {
    setIsSelectionEnabled(false);

    if (Object.keys(selectedEntities).length > 0) setSelectedEntities({});
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let unmounted: boolean;

    const selectionCount = Object.keys(selectedEntities).length;

    if (selectionCount === 0) {
      return disableSelection();
    }

    if (selectionCount > 0) {
      return enableSelection();
    }

    return () => {
      unmounted = true;
    };
  }, [selectedEntities]);

  return (
    <EntitiesSelectionContext.Provider
      value={{
        selectedEntities,
        isSelectionEnabled,
        addToSelection,
        removeFromSelection,
        enableSelection,
        disableSelection,
      }}
    >
      {children}
    </EntitiesSelectionContext.Provider>
  );
};

export const useEntitiesSelection = () => useContext(EntitiesSelectionContext);
