// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".V02Spc3bbl3VFQLrwXrI{margin:0 auto;width:100%}.cBDL1okftpCQSKf8UzZr{margin:0}.eUGdffy13cAlTqOzKbDA{max-width:1147px}@media screen and (max-width: 1051px){.eUGdffy13cAlTqOzKbDA{padding:0 20px}}", "",{"version":3,"sources":["webpack://./src/App/common/Container/Container.module.scss"],"names":[],"mappings":"AAEA,sBACE,aAAA,CACA,UAAA,CAGF,sBACE,QAAA,CAGF,sBACE,gBAAA,CAEA,sCAHF,sBAII,cAAA,CAAA","sourcesContent":["@import \"App/styles/_variables\";\n\n.container {\n  margin: 0 auto;\n  width: 100%;\n}\n\n.container-full {\n  margin: 0;\n}\n\n.container-xl {\n  max-width: calc(1051px + (48px * 2));\n\n  @media screen and (max-width: 1051px) {\n    padding: 0 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "V02Spc3bbl3VFQLrwXrI",
	"container-full": "cBDL1okftpCQSKf8UzZr",
	"container-xl": "eUGdffy13cAlTqOzKbDA"
};
export default ___CSS_LOADER_EXPORT___;
