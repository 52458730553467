import React, { useState } from "react";
import classNames from "classnames";

import { Button, BUTTON_THEME } from "App/core/Button";
import { Dialog, DialogButtons, DialogSection } from "App/common/Dialog";
import styles from "./chooser.module.scss";
import { useDropboxChooser } from "App/common/useDropboxChooser";
import { useHistory } from "react-router";
import { routePaths } from "App/modules/urls";
import { DropboxManagerBase } from "@highnote/server/src/core/shared-util";
import { useAuth } from "App/components/Auth";
import { SUBSCRIPTION_TIER } from "@highnote/server/src/core/entities";

enum DropboxChooserOption {
  SINGLE = "single",
  PLAYLIST = "playlist",
}

export const DropboxChooserDialog = ({
  fileConfig,
  onSubmit,
}: {
  fileConfig?: {
    fileId: string;
    fileName: string;
  };
  onSubmit: (ids: string[]) => Promise<void>;
}) => {
  const { user } = useAuth();
  const history = useHistory();
  const {
    isProcessing: isChooserProcessing,
    dropboxChooserReady,
    openDropboxChooser,
  } = useDropboxChooser();
  const [selectedOption, setSelectedOption] = useState<DropboxChooserOption>(
    DropboxChooserOption.SINGLE,
  );

  const [isProcessing, setIsProceessing] = useState(false);

  const onContinue = () => {
    setIsProceessing(true);
    if (
      !fileConfig?.fileId ||
      selectedOption === DropboxChooserOption.PLAYLIST
    ) {
      openDropboxChooser({
        onCancel: () => {
          setIsProceessing(false);
        },
        onSuccess: async (fileIds: string[]) => {
          await onSubmit(fileIds);
          setIsProceessing(false);
        },
      });
      return;
    }
    setIsProceessing(true);
    onSubmit([fileConfig.fileId]).finally(() => {
      setIsProceessing(false);
    });
  };

  const isFreeTier =
    !user?.subscriptionTier || user.subscriptionTier === SUBSCRIPTION_TIER.FREE;
  const importLabel = `Import up to ${DropboxManagerBase.getNumberOfFilesLimit(user?.subscriptionTier)} tracks ${isFreeTier ? "free" : "at a time"}`;
  const isLoading = isChooserProcessing || isProcessing;

  return (
    <Dialog open={dropboxChooserReady} className="highnote-dropbox-chooser">
      <DialogSection
        className={classNames(styles.DropboxChooserDialog__header, {
          [styles.DropboxChooserDialog__header__nofile]: !fileConfig,
        })}
      >
        <img src="/public/highnote-dropbox.png" />
        <h1>Importing from Dropbox</h1>
        <h2>{fileConfig?.fileName || importLabel}</h2>
      </DialogSection>
      {fileConfig && (
        <DialogButtons className={styles.DropboxChooserDialog__btn__groups}>
          <Button
            className={classNames(styles.DropboxChooserDialog__btn, {
              [styles.DropboxChooserDialog__btn__selected]:
                selectedOption === DropboxChooserOption.SINGLE,
            })}
            disabled={isLoading}
            theme={BUTTON_THEME.SECONDARY}
            onClick={() => setSelectedOption(DropboxChooserOption.SINGLE)}
          >
            <section className={styles.DropboxChooserDialog__option}>
              <span className={styles.DropboxChooserDialog__option__text}>
                Single Track
              </span>
              <span>{fileConfig.fileName}</span>
            </section>
          </Button>
          <Button
            className={classNames(styles.DropboxChooserDialog__btn, {
              [styles.DropboxChooserDialog__btn__selected]:
                selectedOption === DropboxChooserOption.PLAYLIST,
            })}
            disabled={isLoading}
            theme={BUTTON_THEME.SECONDARY}
            onClick={() => setSelectedOption(DropboxChooserOption.PLAYLIST)}
          >
            <section className={styles.DropboxChooserDialog__option}>
              <span className={styles.DropboxChooserDialog__option__text}>
                Full Playlist
              </span>
              <span>{importLabel}</span>
            </section>
          </Button>
        </DialogButtons>
      )}
      <DialogSection>
        <Button
          className={styles.DropboxChooserDialog__submit}
          theme={BUTTON_THEME.CTA}
          onClick={onContinue}
          disabled={isLoading}
        >
          {isLoading ? "Processing" : "Continue"}
        </Button>

        <Button
          className={styles.DropboxChooserDialog__submit}
          theme={BUTTON_THEME.SECONDARY}
          onClick={() => {
            history.replace(routePaths.library);
          }}
          disabled={isLoading}
        >
          Cancel
        </Button>
      </DialogSection>
    </Dialog>
  );
};
