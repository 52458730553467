import React from "react";
import { Radio, RadioProps } from "@mui/material";
import classNames from "classnames";
import styles from "./AppRadio.module.scss";

export const AppRadio = ({ className, ...props }: RadioProps) => {
  return (
    <Radio
      {...props}
      disableRipple
      className={classNames(styles.radio, className)}
    />
  );
};
