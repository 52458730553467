import React, { useRef } from "react";
import classNames from "classnames";

import {
  ARCHIVABLE_ENTITY_TYPES,
  Space,
} from "@highnote/server/src/core/entities";
import { ReactComponent as DownloadSVG } from "App/common/icons-v2/download.svg";
import { LoadingSpinner } from "App/common/icons/LoadingSpinner";
import { useFileDownload } from "App/common/useFileDownload";
import { useUserDownloadRequests } from "App/components/useEntities/useDownloadRequests";
import { PermissionTooltip } from "App/components/PermissionTooltip";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import styles from "./DownloadSpaceV2.module.scss";

export const DownloadSpaceMenu = ({
  className,
  space,
  fullWidth,
}: {
  className?: string;
  space: Space;
  fullWidth?: boolean;
}) => {
  const menuRef = useRef<HTMLButtonElement>();
  const { isDownloading, downloadSpace, renderDownloadOption } =
    useFileDownload();
  const { getDownloadRequest } = useUserDownloadRequests();
  const downloadRequest = getDownloadRequest(
    space.id,
    ARCHIVABLE_ENTITY_TYPES.SPACE,
  );
  const isDownloadInProgress = isDownloading || Boolean(downloadRequest);

  return (
    <PermissionTooltip
      title="We're processing this download."
      hasPermission={!isDownloadInProgress}
    >
      <div className={classNames({ ["full-width"]: fullWidth })}>
        {renderDownloadOption}
        <Button
          disabled={isDownloadInProgress}
          ref={menuRef}
          className={classNames(styles["DownloadSpaceMenuV2-button"], {
            active: isDownloadInProgress,
            [className]: Boolean(className),
          })}
          onClick={() => {
            downloadSpace(space.id);
          }}
          theme={BUTTON_THEME.PRIMARY_INVERSE}
          size={BUTTON_SIZE.MEDIUM}
          startIcon={
            isDownloadInProgress ? <LoadingSpinner /> : <DownloadSVG />
          }
        >
          Download
        </Button>
      </div>
    </PermissionTooltip>
  );
};
