import "./styles.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useSpaceContext } from "App/common/useSpace";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { useAuth } from "App/components/Auth";
import { useSegmentContext } from "App/modules/useSegment";
import { EVENT_ID, Space } from "@highnote/server/src/core/entities";
import { SpaceTrack } from "./SpaceTrack";
import { SpaceHome } from "./SpaceHomeV2";
import { SpaceArchivedBanner } from "./SpaceArchivedBanner";
import { EntityNotFound } from "../EntityNotFound";
import LoadingScreen from "App/common/LoadingScreen/LoadingScreen";
import { useUrlContext } from "../useUrlContext";
import { TrackEditorProvider } from "App/components/useTrackEditor";
import { FileEditorProvider } from "App/components/useFileEditor";
import { useGlobalAudioPlayer } from "App/components/GlobalAudioPlayer";
import { SpaceHomeNavigation } from "./SpaceNavigation";
import { TrackContextProvider } from "App/components/useEntities/useTrack";
import { PasswordRequired } from "./PasswordRequired";
import { highnote } from "@highnote/server/src/sdk";
import {
  SPACE_SHARING_DISABLED,
  SPACE_NOT_FOUND,
  SPACE_PERMISSION_DENIIED,
  SPACE_GUEST_MODE_DISABLED,
  SPACE_INBOX_PERMISSION_DENIED,
} from "App/common/util/ERRORS";
import { RequestSpaceAccess } from "./RequestSpaceAccess";
import { JoinSpaceConfirmation } from "./JoinSpaceConfirmation";
import { InboxAuthRequired } from "./InboxAuthRequired";
import {
  GUEST_MODE_DISABLED_ERR_MESSAGE,
  INBOX_PERMISSION_DENIED_ERR_MESSAGE,
} from "@highnote/server/src/core/shared-util";
import { CreatedTracksContextProvider } from "App/components/useEntities/useCreatedTracks";
import {
  APP_FEATURES,
  AppFeaturesStatus,
} from "@highnote/server/src/core/features";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const alreadyViewed: any = {};

const SpaceUIContent = () => {
  const {
    space,
    spaceLoading,
    spaceName,
    setSpaceName,
    spaceOwner,
    setSpaceOwner,
    spaceArtworkUrl,
    setSpaceArtworkUrl,
    spaceError,
    refreshSubscription,
  } = useSpaceContext();
  const {
    spaceId,
    trackId,
    shareKey: shareKeyViaUrl,
    versionIdPath,
  } = useUrlContext();

  const { user } = useAuth();
  const isLoggedIn = !!user;
  const [guestModeDisabled, setGuestModeDisabled] = useState<boolean>(false);
  const [inboxAuthRequired, setInboxAuthRequired] = useState<boolean>(false);

  const handlePasswordCheck = async (password: string) => {
    try {
      await highnote.checkProtectedEntity({
        entityId: spaceId,
        password,
        shareKey: shareKeyViaUrl,
      });
      // checkProtectedEntity may update your auth session with custom claims,
      // which means you should force-refresh your token to be able to use the new session
      await highnote.__firebaseAuth.currentUser.getIdToken(true);
      refreshSubscription(true, 0);
    } catch (err) {
      if (err.code === "functions/permission-denied") {
        err.message = "Incorrect password";
      }

      // If space has guest mode disabled, we need to show the join space confirmation screen
      if (err.message === GUEST_MODE_DISABLED_ERR_MESSAGE) {
        const details = err.details as {
          owner: string;
          spaceName: string;
          spaceArtworkUrl: string;
        };
        setSpaceName(details.spaceName);
        setSpaceOwner(details.owner);
        setSpaceArtworkUrl(details.spaceArtworkUrl);
        setGuestModeDisabled(true);

        await highnote.__firebaseAuth.currentUser.getIdToken(true);
        refreshSubscription(false, 0);
        return;
      }

      // If space has private inbox enabled, we need to show the inbox auth required screen
      if (err.message === INBOX_PERMISSION_DENIED_ERR_MESSAGE) {
        const details = err.details as {
          ownerId: string;
        };
        setSpaceOwner(details.ownerId);
        setInboxAuthRequired(true);

        await highnote.__firebaseAuth.currentUser.getIdToken(true);
        refreshSubscription(false, 0);
        return;
      }

      throw err;
    }
  };

  const handleJoinSpace = async () => {
    await highnote.joinProtectedEntity({
      entityId: spaceId,
      shareKey: shareKeyViaUrl,
    });
    await highnote.__firebaseAuth.currentUser.getIdToken(true);
    refreshSubscription(true, 0);
  };

  const content = useMemo(() => {
    if (spaceLoading) {
      return <LoadingScreen />;
    }

    if (spaceError || !space) {
      if (spaceError === SPACE_GUEST_MODE_DISABLED || guestModeDisabled) {
        return (
          <JoinSpaceConfirmation
            onSubmit={handleJoinSpace}
            spaceName={spaceName}
            spaceArtworkUrl={spaceArtworkUrl}
            referrerId={spaceOwner}
          />
        );
      }

      if (spaceError === SPACE_INBOX_PERMISSION_DENIED || inboxAuthRequired) {
        return <InboxAuthRequired ownerId={spaceOwner} />;
      }

      if (spaceError === SPACE_PERMISSION_DENIIED) {
        return <PasswordRequired onSubmit={handlePasswordCheck} />;
      }

      if (spaceError === SPACE_SHARING_DISABLED) {
        return <RequestSpaceAccess />;
      }

      if (spaceError === SPACE_NOT_FOUND) {
        return (
          <>
            <SpaceHomeNavigation />
            <EntityNotFound entityType="Space" />
          </>
        );
      }
    }

    if (trackId || versionIdPath) {
      return <SpaceTrack />;
    }

    return <SpaceHome />;
  }, [
    spaceLoading,
    isLoggedIn,
    spaceError,
    !!space,
    trackId,
    guestModeDisabled,
  ]);

  return content;
};

const SpaceUI = () => {
  const { space, spaceId } = useSpaceContext();

  const pageTitle = `${space?.name || spaceId}${
    space?.isArchived ? " (archived)" : ""
  } • Highnote`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {space?.isArchived && <SpaceArchivedBanner />}

      <SpaceUIContent />
    </>
  );
};

const Space = () => {
  const { space, spaceId } = useSpaceContext();
  const { trackId } = useUrlContext();
  const { user } = useAuth();
  const { isActive } = useGlobalAudioPlayer();
  const { trackEvent } = useSegmentContext();
  const lastViewedRef = useRef<Space>(space);

  useEffect(() => {
    if (!space || !user) return;
    if (!alreadyViewed[spaceId]) {
      // highnote.notify({
      //   workflowId: EVENT_TYPE.SPACE_VIEWED,
      //   data: {
      //     spaceId,
      //   },
      // });
      alreadyViewed[spaceId] = true;
    }
  }, [space, user?.id]);

  useEffect(() => {
    if (!space) {
      lastViewedRef.current = undefined;
      return;
    }

    if (space.id === lastViewedRef.current?.id) {
      return;
    }

    trackEvent(EVENT_ID.ENTERED_SPACE, {
      space_id: space.id,
      space_name: space.name,
      num_tracks: (space.tracksOrder || []).length,
    });
    lastViewedRef.current = space;
  }, [space]);

  useEffect(() => {
    if (!spaceId) return;

    return () => {
      trackEvent(EVENT_ID.EXITED_SPACE, {
        space_id: spaceId,
      });
    };
  }, [spaceId]);

  return (
    <ErrorBoundary name="Space">
      <TrackContextProvider id={trackId}>
        <TrackEditorProvider respectQueryParam>
          <FileEditorProvider>
            <Helmet>
              <body data-is-track-page={!!trackId} />
            </Helmet>
            <div
              className="highnote-space"
              data-has-active-audio-player={isActive}
              data-is-track-page={!!trackId}
              data-cypress-id="space"
            >
              {AppFeaturesStatus[APP_FEATURES.PRIVATE_INBOX] ? (
                <CreatedTracksContextProvider>
                  <SpaceUI />
                </CreatedTracksContextProvider>
              ) : (
                <SpaceUI />
              )}
            </div>
          </FileEditorProvider>
        </TrackEditorProvider>
      </TrackContextProvider>
    </ErrorBoundary>
  );
};

export default Space;
