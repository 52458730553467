import "./StorageUsage.scss";
import React from "react";
import { LinearProgress } from "@mui/material";
import { formatFileSize } from "App/modules/utils";
import { BUTTON_SIZE, BUTTON_THEME, Button } from "App/core/Button";
import { useHistory } from "react-router";
import { PLANS } from "../Plans/config";
import { parseSubscription } from "@highnote/server/src/core/shared-util";
import { useAuth } from "../Auth";

export const StorageUsage = () => {
  const history = useHistory();
  const { user, storageLimit } = useAuth();
  const { storageUsed, subscriptions } = user;
  const { tier } = parseSubscription(subscriptions.active);
  const plan = PLANS.find((p) => p.tier === tier);

  return (
    <div className="highnote-storage-usage">
      <div className="storage-bar">
        <LinearProgress
          variant="determinate"
          value={Math.min((storageUsed / storageLimit) * 100, 100)}
        />
      </div>

      <p className="hint">
        {`You are using ${formatFileSize(storageUsed)} out of ${formatFileSize(
          storageLimit,
        )} of storage allowed on your `}
        <Button
          theme={BUTTON_THEME.LINK}
          size={BUTTON_SIZE.AUTO}
          type="button"
          onClick={() => history.push(`/settings/plans`)}
        >
          {plan.name} Plan
        </Button>
        .
      </p>
    </div>
  );
};
