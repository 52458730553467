import { daw } from "@highnote/daw/src";
import { AUDIO_QUALITY, FileEntity } from "@highnote/server/src/core/entities";
import { highnote } from "@highnote/server/src/sdk";
import { isFilePlayable } from "@highnote/server/src/core/shared-util";

const getSourceFromFile = async (file: FileEntity, quality: AUDIO_QUALITY) => {
  if (!file) return undefined;
  try {
    const url = await highnote.getFileUrl({
      id: file.id,
      asProcessedAudio: true,
      quality,
    });
    return url;
  } catch (e) {
    return undefined;
  }
};

const PROCESSING_SOURCE = "/public/empty.wav";
export const loadFileIntoDAW = async (
  id: string,
  file: FileEntity,
  quality: AUDIO_QUALITY,
  startTime?: number,
  muted?: boolean,
) => {
  const dawTrack = daw.getTrack(id);

  let source: string;
  let sourceId = `${file.id}-${quality}`;
  let duration = 0;
  let fetchFromStorage = true;

  if (file.processingErrorV3) {
    sourceId = `${sourceId}-error`;
    fetchFromStorage = false;
  } else if (!isFilePlayable(file)) {
    sourceId = `${sourceId}-processing`;
    source = PROCESSING_SOURCE;
    duration = 1;
    fetchFromStorage = false;
  }

  // If this track has already been loaded, just use the old one.
  if (
    dawTrack &&
    dawTrack.sourceId === sourceId &&
    (startTime === undefined || dawTrack.startTime === startTime)
  ) {
    if (muted) daw.muteTrack(id);
    else daw.unmuteTrack(id);
    return;
  }

  daw.removeTrack(id);

  if (fetchFromStorage) {
    source = await getSourceFromFile(file, quality);
    if (source) {
      duration = file.metadata.duration;
    }
  }

  daw.addTrack({
    id,
    source,
    sourceId,
    startTime: startTime || 0,
    duration,
    ...(muted ? { volume: 0 } : {}),
  });
};
