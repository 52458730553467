import React, { useMemo } from "react";
import { ROLE, SUBSCRIPTION_TIER } from "@highnote/server/src/core/entities";
import { DialogSection } from "App/common/Dialog";
import { usePlanLimitsContext } from "App/common/PlanLimits/usePlanLimits";
import {
  CONTROLLED_SHARE_FEATURES,
  ControlledFeatureCallout,
} from "App/core/ControlledFeatureCallout";
import { InviteByLink } from "../Members/InviteByLink";
import { useShareDialogContext } from "../ShareDialogContext";
import { useAuth } from "App/components/Auth";
import { getEntityShareKeys } from "@highnote/server/src/core/shared-util";

export const ShareDialogLink = () => {
  const { user } = useAuth();
  const { showPlanPickerDialog } = usePlanLimitsContext();
  const { isEntityOwner, entity, canManageDownloadControl, canManageEntity } =
    useShareDialogContext();

  const [shareKey] = getEntityShareKeys(entity);
  const shareRoles = (entity?.rolesV2 || {})[shareKey] || [];

  const isShared = shareRoles.includes(ROLE.VIEW);

  const downloadToggleable =
    isShared && shareRoles.includes(ROLE.DOWNLOAD)
      ? canManageDownloadControl
      : true;

  const calloutMessage = useMemo(() => {
    if (user.subscriptionTier === SUBSCRIPTION_TIER.FREE) {
      return "Enable more security when sharing";
    }

    if (user.subscriptionTier === SUBSCRIPTION_TIER.INDIE) {
      return "Add more security when sharing. Require passwords, accounts, + more.";
    }

    return undefined;
  }, [user.subscriptionTier]);

  return (
    <div className="ShareDialogMembers">
      <DialogSection>
        <InviteByLink />
      </DialogSection>
      {isEntityOwner && (
        <ControlledFeatureCallout
          featuresStatus={{
            [CONTROLLED_SHARE_FEATURES.DOWNLOAD]: {
              enabled: downloadToggleable,
            },
            [CONTROLLED_SHARE_FEATURES.PASSWORD]: {
              enabled:
                canManageEntity &&
                user.subscriptionTier !== SUBSCRIPTION_TIER.FREE,
              ...(!downloadToggleable && {
                label: "passwords",
              }),
            },
          }}
          title="Advanced Security"
          fullMessage={calloutMessage}
          onUpgrade={showPlanPickerDialog}
          theme="monetization"
        />
      )}
    </div>
  );
};
