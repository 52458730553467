import { EntityIcon, EntityIconPlayable } from "./EntityIcon";
import { EntityPreview } from "./EntityPreview";
import { EntityShortcut } from "./EntityShortcut";
import { EntityMetadata } from "./EntityMetadata";
import { EntityActions } from "./EntityActions";
import { ColumnConfig, RowConfig } from "App/common/BaseTable";
import { Entity, FileEntity, Track } from "@highnote/server/src/core/entities";
import { FileUpload } from "../useFiles";
import { Action } from "App/common/Actions";
import { EntityComments } from "./EntityComments";
import { EntityCreatedAt } from "./EntityCreatedAt";
import { Confirmator } from "App/common/useConfirmation";

export const DEFAULT_COLUMNS: Record<string, ColumnConfig> = {
  ICON: {
    name: "Icon",
    id: "icon",
    Component: EntityIcon,
  },
  ICON_PLAYABLE: {
    name: "IconPlayable",
    id: "icon-playable",
    Component: EntityIconPlayable,
  },
  PREVIEW: {
    name: "Preview",
    id: "preview",
    Component: EntityPreview,
  },
  SHORTCUT: {
    name: "Shortcut",
    id: "shortcut",
    Component: EntityShortcut,
  },
  COMMENTS: {
    name: "# Comments",
    id: "comment-count",
    Component: EntityComments,
  },
  CREATED_AT: {
    name: "Created At",
    id: "created-at",
    Component: EntityCreatedAt,
  },
  METADATA: {
    name: "Metadata",
    id: "metadata",
    Component: EntityMetadata,
  },
  ACTIONS: {
    name: "Actions",
    id: "actions",
    Component: EntityActions,
  },
};

export enum ENTITY_TYPE {
  SPACE = "SPACE",
  TRACK = "TRACK",
  TRACK_VERSION = "TRACK_VERSION",
  FILE = "FILE",
  UPLOAD = "UPLOAD",
  SEPARATOR = "SEPARATOR",
}

export type Version = FileEntity & {
  isVersion: true;
  index: number;
  colorId: number;
  isDefaultVersion: boolean;
  commentCount?: number;
  track?: Track;
};

export type SpaceTrack = Track & {
  isSpaceTrack: true;
  index: number;
};

export type EntityData = {
  id: string;
  entity: Entity | FileUpload | Version;
  type: ENTITY_TYPE;
  actions?: Action[] | JSX.Element;
  confirmOnClick?: (confirm: Confirmator) => Promise<unknown>;
  onConfirmCancel?: () => void;
  onClick?: () => void;
};

export type EntityRowConfig = EntityData & RowConfig;
