import "./TrackVersionStar.scss";
import React from "react";
import { ReactComponent as StarFilledSVG } from "App/common/icons/star-filled.svg";
import { useTrack } from "App/components/useEntities/useTrack";
import { FileEntity } from "@highnote/server/src/core/entities";
import { getDefaultVersionId } from "@highnote/server/src/core/shared-util";
import { Tooltip } from "App/core/Tooltip";

export const TrackVersionStar = ({ version }: { version: FileEntity }) => {
  const { track, pinnedTrackVersions } = useTrack();

  // Use the client-side (optimistic) pinned versions so there's no whiplash
  const defaultVersionId = getDefaultVersionId({
    ...track,
    pinnedVersionFiles: pinnedTrackVersions,
  });

  if (defaultVersionId !== version?.id) return null;

  return (
    <Tooltip
      className="TrackVersionStar-tooltip"
      arrow
      title={
        <p>
          <strong>Default Version</strong>
          <br />
          The first track that appears when a Space is viewed.
        </p>
      }
    >
      <div className="TrackVersionStar">
        <StarFilledSVG />
      </div>
    </Tooltip>
  );
};
