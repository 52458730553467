import { HttpsCallableOptions, httpsCallable } from "firebase/functions";
import { ref, getDownloadURL } from "firebase/storage";
import { app, firestore, functions, storage, auth } from "./firebase";
import Stripe from "stripe";

import {
  SpaceCore,
  COLLECTION_ID,
  CommentCore,
  TrackCore,
  FileCore,
  FileEntity,
  Id,
  Space,
  Comment,
  Track,
  UserEntity,
  FileReferences,
  AUDIO_QUALITY,
  KNOCK_WORKFLOW,
  NotificationData,
  UserCore,
  SUBSCRIPTION_TIER,
  SUBSCRIPTION_INTERVAL,
  ARCHIVABLE_ENTITY_TYPES,
  OAUTH_PROVIDERS,
  UserMetadata,
  SelectionType,
} from "../core/entities";
import { FUNCTION_ID } from "../core/constants";
import * as utils from "./utils";
import { getCommentsCount } from "./getCommentsCount";
import { watchComments } from "./watchComments";
import { getCommentsBefore } from "./getCommentsBefore";
import { watchSpace } from "./watchSpace";
import { watchTrack } from "./watchTrack";
import { watchEntities, watchEntity } from "./watchEntities";
import { watchSpaces } from "./watchSpaces";
import { uploadFile } from "./uploadFile";

const callFunction = async (
  functionId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any,
  options?: HttpsCallableOptions,
) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const res = await httpsCallable(functions, functionId, options)(props);
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const highnote = {
  /* TODO: Remove once we have everything in the sdk module */
  __firebaseApp: app,
  __firebaseFirestore: firestore,
  __firebaseFunctions: functions,
  __firebaseStorage: storage,
  __firebaseAuth: auth,
  utils,

  // dropbox
  refreshDropboxToken: async () =>
    callFunction(FUNCTION_ID.REFRESH_DROPBOX_TOKEN, {}) as Promise<string>,
  cloneDropboxEntities: async (props: { fileIds: string[]; spaceId: string }) =>
    callFunction(FUNCTION_ID.CLONE_DROPBOX_ENTITITES, props),
  handleDropboxJob: async (props: { userId: string }) =>
    callFunction(FUNCTION_ID.HANDLE_DROPBOX_JOB, props),
  retryDropboxJob: async (props: { jobId: string }) =>
    callFunction(FUNCTION_ID.RETRY_DROPBOX_JOB, props),
  deleteDropboxJob: async (props: { jobId: string }) =>
    callFunction(FUNCTION_ID.DELETE_DROPBOX_JOB, props),

  associateOAuthAccount: async (props: {
    code: string;
    provider: OAUTH_PROVIDERS;
    redirectUri: string;
  }) => callFunction(FUNCTION_ID.ASSOCIATE_OAUTH_ACCOUNT, props),

  /* TRACKS
---------------------------------- */
  createTrack: (props: { id: Id; data: TrackCore }) =>
    callFunction(FUNCTION_ID.CREATE_TRACK, props) as Promise<Track>,

  updateTrack: (props: { id: Id; data: Partial<TrackCore> }) =>
    httpsCallable(functions, FUNCTION_ID.UPDATE_TRACK)(props),

  deleteTrack: (props: { id: Id }) =>
    httpsCallable(functions, FUNCTION_ID.DELETE_TRACK)(props),

  getTrack: async (props: { id: Id }) =>
    callFunction(FUNCTION_ID.GET_TRACK, props) as Promise<{
      track: Track;
      files: FileEntity[];
    }>,

  watchTrack,

  addTrackPlay: async (props: { userId: string; trackId: string }) =>
    callFunction(FUNCTION_ID.ADD_TRACK_PLAYED, props) as Promise<void>,

  /* SPACES
---------------------------------- */
  createSpace: (props: { id: Id; data: SpaceCore }) =>
    callFunction(FUNCTION_ID.CREATE_SPACE, props) as Promise<Space>,

  updateSpace: (props: { id: Id; data: Partial<SpaceCore> }) =>
    httpsCallable(functions, FUNCTION_ID.UPDATE_SPACE)(props),

  deleteSpace: (props: { id: Id }) =>
    httpsCallable(functions, FUNCTION_ID.DELETE_SPACE)(props),

  getSpace: async (props: { id: Id; fromCache?: boolean }) => {
    return utils.readEntity({
      entityId: props.id,
      collectionId: COLLECTION_ID.SPACE,
      fromCache: props.fromCache,
    }) as Promise<Space>;
  },

  checkProtectedEntity: async (props: {
    entityId: Id;
    password?: string;
    shareKey?: string;
  }) =>
    callFunction(FUNCTION_ID.CHECK_PROTECTED_ENTITY, props) as Promise<{
      redirectUrl: string;
    } | null>,
  watchSpace,
  watchSpaces,

  joinProtectedEntity: async (props: { entityId: Id; shareKey?: string }) =>
    callFunction(FUNCTION_ID.JOIN_PROTECTED_ENTITY, props) as Promise<{
      redirectUrl: string;
    } | null>,

  /* FILES
---------------------------------- */
  createOrUpdateFile: (props: { id: Id; data: Partial<FileCore> }) =>
    callFunction(
      FUNCTION_ID.CREATE_OR_UPDATE_FILE,
      props,
    ) as Promise<FileEntity>,

  processAudioFile: async (props: { id: Id }) =>
    callFunction(FUNCTION_ID.PROCESS_AUDIO_FILE, props) as Promise<void>,
  getFiles: async (props: { ids: Id[] }) =>
    callFunction(FUNCTION_ID.GET_FILES, props) as Promise<FileEntity[]>,
  acknowledgeDownloadRequest: async (props: { requestId: string }) =>
    callFunction(
      FUNCTION_ID.ACKNOWLEDGE_DOWNLOAD_REQUEST,
      props,
    ) as Promise<void>,
  generateDownloadLink: async (
    props: {
      requestId?: string;
      deadline?: number;
      entityType: ARCHIVABLE_ENTITY_TYPES;
      entityId: string;
      fileIds?: string[];
    },
    options?: HttpsCallableOptions,
  ) => {
    const res = (await callFunction(
      FUNCTION_ID.GENERATE_DOWNLOAD_LINK,
      props,
      options,
    )) as { downloadLink: string; requestId?: string };
    if (
      process.env.NODE_ENV === "development" &&
      process.env.DEBUG !== "true"
    ) {
      const { downloadLink, requestId } = res;
      return {
        requestId,
        downloadLink: downloadLink.replace(
          "https://storage.googleapis.com",
          "http://localhost:9199",
        ),
      };
    }
    return res;
  },
  getStorageFiles: async (props?: { type?: "all" | "trackVersions" }) =>
    callFunction(FUNCTION_ID.GET_STORAGE_FILES, props) as Promise<FileEntity[]>,
  deleteStorageFile: async (props: { storagePath: string }) =>
    callFunction(FUNCTION_ID.DELETE_STORAGE_FILE, props) as Promise<void>,
  getFileReferences: async (props: { id: string }) =>
    callFunction(
      FUNCTION_ID.GET_FILE_REFERENCES,
      props,
    ) as Promise<FileReferences>,

  /* COMMENTS
---------------------------------- */
  createComment: (props: { id: Id; data: CommentCore }) =>
    callFunction(FUNCTION_ID.CREATE_COMMENT, props) as Promise<Comment>,

  updateComment: (props: { id: Id; data: Partial<CommentCore> }) =>
    httpsCallable(functions, FUNCTION_ID.UPDATE_COMMENT)(props),

  deleteComment: (props: { id: Id }) =>
    httpsCallable(functions, FUNCTION_ID.DELETE_COMMENT)(props),

  getComment: async (props: { id: Id }) =>
    callFunction(FUNCTION_ID.GET_COMMENT, props) as Promise<{
      comment: Comment;
      files: FileEntity[];
    }>,
  watchComments,
  getCommentsBefore,
  getCommentsCount,

  /* USERS
---------------------------------- */
  getKnockAuthToken: async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      const res = await httpsCallable<never, string>(
        functions,
        FUNCTION_ID.GET_KNOCK_AUTH_TOKEN,
      )();

      return res.data;
    } catch (e) {
      throw e;
    }
  },
  requestUserDeletion: async (props: { email: string }) =>
    callFunction(FUNCTION_ID.REQUEST_USER_DELETION, props),
  updateUser: async (props: { id: string; data: Partial<UserCore> }) =>
    callFunction(FUNCTION_ID.UPDATE_USER, props),
  updateAuth0User: async (props: {
    email?: string;
    name?: string;
    picture?: string;
    app_metadata?: object;
  }) => callFunction(FUNCTION_ID.UPDATE_AUTH0_USER, props),
  verifyAuth0Email: async () =>
    callFunction(FUNCTION_ID.VERIFY_AUTH0_EMAIL, undefined),
  getUserPublic: async (props: {
    id?: string;
    url?: string;
    generateFirebaseToken?: boolean;
  }) =>
    callFunction(FUNCTION_ID.GET_USER_PUBLIC, props) as Promise<
      Partial<UserEntity> & {
        userMetadata: UserMetadata;
        firebaseToken?: string;
        HMACUserId?: string;
      }
    >,
  getUsersPublic: async (props: { ids: string[] }) =>
    callFunction(FUNCTION_ID.GET_USERS_PUBLIC, props) as Promise<
      Record<string, UserEntity>
    >,
  searchUsers: async (props: { email?: string; phone?: string }) =>
    callFunction(FUNCTION_ID.SEARCH_USERS, props) as Promise<
      Partial<UserEntity>[]
    >,
  resetAuth0Password: async (props?: { email?: string }) =>
    callFunction(FUNCTION_ID.RESET_AUTH0_PASSWORD, props),

  /* PAYMENTS
  ---------------------------------- */
  updateUserSubscription: async (props: { priceId: string | null }) =>
    callFunction(FUNCTION_ID.UPDATE_USER_SUBSCRIPTION, props),
  getPrice: async (props: { priceId: string | null }) =>
    callFunction(FUNCTION_ID.GET_PRICE, props) as Promise<Stripe.Price | null>,
  getCustomerPortalUrl: async (props: { source: string }) =>
    callFunction(FUNCTION_ID.GET_PAYMENT_PORTAL_URL, props),
  getCheckoutUrl: async (props: {
    priceId: string;
    source: string;
    coupon_code?: string;
  }) =>
    callFunction(FUNCTION_ID.GET_CHECKOUT_URL, props) as Promise<{
      subscriptionDirection: utils.SUBSCRIPTION_DIRECTION;
      checkoutUrl?: string;
    }>,

  /* AUTH
  ---------------------------------- */
  authenticateUser: async (props: {
    method: "email" | "sms" | "token";
    name?: string;
    identifier?: string;
    password?: string;
    token?: string;
    metadata?: object;
    link?: boolean;
    trigger?: string;
    subscription?: SUBSCRIPTION_TIER;
    coupon_code?: string;
    frequency?: SUBSCRIPTION_INTERVAL;
  }) =>
    callFunction(FUNCTION_ID.AUTHENTICATE_USER, props) as Promise<{
      firebaseToken: string | null;
      userId: string;
      loginCount: number;
      redirectPath?: string;
    }>,
  unlinkUser: async (props: { userId: string }) =>
    callFunction(FUNCTION_ID.UNLINK_ACCOUNT, props) as Promise<void>,
  sendVerificationCode: async (props: { phone: string }) =>
    callFunction(FUNCTION_ID.SEND_VERIFICATION_CODE, props) as Promise<void>,

  /* ALGOLIA
  ---------------------------------- */
  getAlgoliaAuthToken: async () =>
    callFunction(FUNCTION_ID.GET_ALGOLIA_AUTH_TOKEN, undefined) as Promise<{
      publicKey: string;
      validUntil: number;
    }>,

  /* GENERIC
  ---------------------------------- */
  watchEntities,
  watchEntity,
  notify: async (props: {
    workflowId: KNOCK_WORKFLOW;
    data: NotificationData;
  }) => callFunction(FUNCTION_ID.NOTIFY, props),

  getFileUrl: async (props: {
    id: string;
    entityType?: COLLECTION_ID.FILE | COLLECTION_ID.DOWNLOAD_REQUEST;
    asProcessedAudio?: boolean;
    quality?: AUDIO_QUALITY;
  }) => {
    const res = await httpsCallable(functions, FUNCTION_ID.GET_FILE_URL)(props);
    let url = res.data as string;

    if (
      process.env.NODE_ENV === "development" &&
      process.env.DEBUG !== "true"
    ) {
      url = url.replace(
        "https://storage.googleapis.com",
        "http://localhost:9199",
      );
    }
    return url;
  },

  uploadFile,
  getPublicFileUrl: async (props: { storagePath: string }) => {
    const url = await getDownloadURL(ref(storage, props.storagePath));

    if (
      process.env.NODE_ENV === "development" &&
      process.env.DEBUG !== "true"
    ) {
      return url.replace(
        "http://localhost:9199",
        "https://storage.googleapis.com",
      );
    }

    return url;
  },
  getLocalFileUrl: (url: string = "") => {
    if (
      process.env.NODE_ENV === "development" &&
      process.env.DEBUG !== "true"
    ) {
      return (url || "").replace(
        "https://storage.googleapis.com",
        "http://localhost:9199",
      );
    }
    return url;
  },

  deleteOrRemoveEntities: async (props: { entities: SelectionType[] }) =>
    httpsCallable(functions, FUNCTION_ID.DELETE_OR_REMOVE_ENTITIES)(props),

  moveSelectionToEntity: async (props: {
    targetEntity: SelectionType;
    selectedEntities: SelectionType[];
  }) => httpsCallable(functions, FUNCTION_ID.MOVE_SELECTION_TO_ENTITY)(props),
};
