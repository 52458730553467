import "./Tooltip.scss";
import React from "react";
import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from "@mui/material";
import { useTheme } from "App/common/ThemeProvider";

export type TooltipProps = MuiTooltipProps & { isDisabled?: boolean };
export const Tooltip = (props: TooltipProps) => {
  const { theme } = useTheme();

  const { isDisabled, ...rest } = props;

  if (isDisabled) {
    return <>{props.children}</>;
  }

  return (
    <MuiTooltip
      {...rest}
      classes={{ popper: `highnote-tooltip ${props.className}` }}
      PopperProps={
        {
          "data-theme": theme,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any
      }
      arrow
      enterTouchDelay={0}
    />
  );
};
