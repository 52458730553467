import {
  getDoc,
  doc,
  DocumentSnapshot,
  DocumentData,
  getDocFromCache,
} from "firebase/firestore";
import { firestore } from "./firebase";
import {
  COLLECTION_ID,
  COMMENT_BLOCK_TYPE,
  CommentCore,
  CustomBlock,
  FileBlockV2,
  PollBlockV2,
  PollReplyBlock,
  StickerBlock,
} from "../core/entities";

export * from "../core/shared-util";

export const generateCommentData: (
  props: Partial<CommentCore>,
) => CommentCore = (props) => {
  return {
    timestamp: 0,
    duration: 1.5,
    replies: [],
    blocks: [],
    spaceId: props.spaceId,
    trackId: props.trackId,
    ...props,
  };
};

export const generateCustomBlock: (
  props: Partial<CustomBlock>,
) => CustomBlock = (props) => {
  return {
    type: COMMENT_BLOCK_TYPE.CUSTOM,
    text: "",
    id: props.id,
    ...props,
  };
};

export const generateStickerBlock = (props: Partial<StickerBlock>) => {
  return {
    type: COMMENT_BLOCK_TYPE.STICKER,
    ...props,
  };
};

export const generateFileBlock: (props: Partial<FileBlockV2>) => FileBlockV2 = (
  props = {},
) => {
  return {
    type: COMMENT_BLOCK_TYPE.FILE,
    text: "",
    file: props.file,
    ...props,
  };
};

export const generatePollBlock: (props: Partial<PollBlockV2>) => PollBlockV2 = (
  props = {},
) => {
  return {
    type: COMMENT_BLOCK_TYPE.POLL,
    text: "",
    options: [generateTextBlock({}), generateTextBlock({})],
    ...props,
  } as PollBlockV2;
};

export const generatePollReplyBlock: (
  props: Partial<PollReplyBlock>,
) => PollReplyBlock = (props = {}) => {
  return {
    type: COMMENT_BLOCK_TYPE.POLL_REPLY,
    option: props.option,
  };
};

export const generateTextBlock: (props: Partial<TextBlock>) => TextBlock = (
  props = {},
) => {
  return {
    type: COMMENT_BLOCK_TYPE.TEXT,
    text: "",
    ...props,
  };
};

export const readEntity = async ({
  entityId,
  collectionId,
  fromCache,
}: {
  entityId: Id;
  collectionId: COLLECTION_ID;
  fromCache?: boolean;
}) => {
  const ref = doc(firestore, `${collectionId}/${entityId}`);

  let document: DocumentSnapshot<DocumentData>;

  try {
    if (!fromCache) throw "";
    document = await getDocFromCache(ref);
  } catch (e) {
    document = await getDoc(ref);
  }

  return document.data();
};
