import React, { useEffect, useRef, useState } from "react";
import { TextInput } from "App/common/TextInput";
import { Select } from "App/common/Select";
import { MenuItem } from "App/common/Menu";
import { countries, Country } from "country-data";

const allCountries: (Country & { code: string; id: string })[] = [];
countries.all.forEach((country) => {
  country.countryCallingCodes.forEach((code, i) => {
    allCountries.push({
      ...country,
      code,
      id: `${country.alpha3}-${i}`,
    });
  });
});

const sortedCountries = [...allCountries].sort((a, b) => {
  return a.code > b.code ? 1 : -1;
});
const DEFAULT_COUNTRY_ID = `USA-0`;

const getPhoneFromValue = (value?: string) => {
  const parts = value?.split(" ") || [];
  return parts[parts.length - 1];
};

export const InputPhone = ({
  value,
  onChange,
  onSubmit,
}: {
  value: string;
  onChange: (val: string) => void;
  onSubmit?: () => void;
}) => {
  const [phone, setPhone] = useState<string>(getPhoneFromValue(value));
  const [countryId, setCountryId] = useState<string>(DEFAULT_COUNTRY_ID);
  const phoneInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    phoneInputRef.current?.focus();
  }, []);

  useEffect(() => {
    setPhone(getPhoneFromValue(value));
  }, [value]);

  useEffect(() => {
    const country = sortedCountries.find((c) => c.id === countryId);
    onChange(`${country?.code || ""} ${phone || ""}`);
  }, [phone, countryId]);

  return (
    <div className="InputPhone">
      <Select
        className="calling-code-select"
        value={countryId}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
        onChange={(e) => {
          const val = e.target.value || "";
          const country = sortedCountries.find((c) => c.id === val);
          setCountryId(country?.id || DEFAULT_COUNTRY_ID);
        }}
      >
        {sortedCountries.map((country) => {
          return (
            <MenuItem key={country.id} value={country.id}>
              {country.code}
              <span className="country-name">
                &nbsp;-&nbsp;
                {country.emoji} {country.name}
              </span>
            </MenuItem>
          );
        })}
      </Select>
      <TextInput
        placeholder="Phone Number"
        type="phone"
        isSingleLine
        value={phone}
        onChange={setPhone}
        onSubmit={onSubmit}
        inputRef={phoneInputRef}
        required
      />
    </div>
  );
};
