import { firestore } from "./firebase";
import {
  collection,
  onSnapshot,
  query,
  QueryConstraint,
  doc,
  FirestoreError,
} from "firebase/firestore";
import { COLLECTION_ID } from "../core/entities";

export const watchEntities = <EntityType>({
  collectionId,
  constraints,
  onChange,
  onError,
}: {
  collectionId: COLLECTION_ID;
  constraints: QueryConstraint[];
  onChange: (entities: EntityType[]) => void;
  onError?: () => void;
}) => {
  const entitiesQuery = query(
    collection(firestore, collectionId),
    ...constraints,
  );

  return onSnapshot(
    entitiesQuery,
    (snapshot) => {
      const entities = snapshot.docs.map((s) => s.data()).filter((e) => !!e);
      onChange(entities as EntityType[]);
    },
    (error) => {
      console.log(
        `Could not watch [${collectionId}] entities:`,
        constraints,
        error,
      );
      onError && onError();
    },
  );
};

export const watchEntity = <EntityType>({
  entityId,
  collectionId,
  onChange,
  onError,
}: {
  entityId: string;
  collectionId: COLLECTION_ID;
  onChange: (entity: EntityType) => void;
  onError?: (error: FirestoreError) => void;
}) => {
  const entitiesQuery = doc(collection(firestore, collectionId), entityId);

  return onSnapshot(
    entitiesQuery,
    (snapshot) => {
      const entity = snapshot.data();
      onChange(entity as EntityType);
    },
    (error) => {
      console.log(`Could not watch [${collectionId}] entity:`, entityId, error);
      onError && onError(error);
    },
  );
};
