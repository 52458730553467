import { useCallback } from "react";

import {
  COLLECTION_ID,
  SelectionType,
  Track,
} from "@highnote/server/src/core/entities";
import { getDefaultVersionId } from "@highnote/server/src/core/shared-util";
import { getEntityCollectionFromType } from "App/common/BaseTable";
import { useEntitiesSelection } from "App/components/useEntitiesSelection";
import { TrackVersionRowConfig } from "./useVersionRows";

export const useSelectedVersions = () => {
  const {
    addToSelection,
    removeFromSelection,
    selectedEntities,
    isSelectionVisible,
    ...entitiesSelection
  } = useEntitiesSelection();

  const selectedCount = Object.keys(selectedEntities).length;

  const getAllSelectableRows = useCallback(
    (allRowSelections: SelectionType[], track?: Track) => {
      if (!track) return allRowSelections;

      const defaultVersionId = getDefaultVersionId(track);
      return allRowSelections.filter(
        (selection) => selection.entityId !== defaultVersionId,
      );
    },
    [],
  );

  const getIsIndeterminate = useCallback(
    (allRowSelections: SelectionType[], track?: Track) => {
      const selectableRows = getAllSelectableRows(allRowSelections, track);
      return (
        isSelectionVisible &&
        selectedCount > 0 &&
        selectedCount < selectableRows.length
      );
    },
    [isSelectionVisible, selectedCount],
  );

  const getIsAllSelected = useCallback(
    (allRowSelections: SelectionType[], track?: Track) => {
      const selectableRows = getAllSelectableRows(allRowSelections, track);
      return isSelectionVisible && selectedCount === selectableRows.length;
    },
    [isSelectionVisible, selectedCount],
  );

  const onMultiSelectRow = (
    row: TrackVersionRowConfig,
    parentTrackId?: string,
  ) => {
    const entityId = (row.entity as Entity).id;
    const isAlreadySelected = selectedEntities[entityId];

    if (isAlreadySelected) {
      removeFromSelection(entityId);
      return;
    }

    addToSelection({
      entityId,
      entityType: getEntityCollectionFromType(row.type),
      parentEntity: {
        entityId: parentTrackId,
        entityType: COLLECTION_ID.TRACK,
      },
    });
  };

  return {
    ...entitiesSelection,
    isSelectionVisible,
    selectedEntities,
    selectedCount,
    getAllSelectableRows,
    getIsIndeterminate,
    getIsAllSelected,
    onMultiSelectRow,
  };
};
