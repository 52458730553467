import "./RolePicker.scss";
import React, { useRef, useState } from "react";
import { ReactComponent as EllipsisHorizontalSVG } from "App/common/icons/ellipsis-horizontal.svg";
import { ROLE } from "@highnote/server/src/core/entities";
import { Switch } from "App/core/Switch";
import { Menu, MenuItem } from "App/common/Menu";
import { Button, BUTTON_THEME } from "App/core/Button";
import { PermissionTooltip } from "../PermissionTooltip";

const roleOptions: Partial<
  Record<ROLE, { label: string; value: ROLE[] | null }>
> = {
  [ROLE.MANAGE]: {
    label: "Manage",
    value: [ROLE.MANAGE, ROLE.COMMENT, ROLE.UPLOAD, ROLE.DOWNLOAD, ROLE.VIEW],
  },
  [ROLE.COMMENT]: { label: "Comment", value: [ROLE.COMMENT, ROLE.VIEW] },
  [ROLE.UPLOAD]: { label: "Upload", value: [ROLE.UPLOAD, ROLE.VIEW] },
  [ROLE.DOWNLOAD]: { label: "Download", value: [ROLE.DOWNLOAD, ROLE.VIEW] },
};

export const RolePicker = ({
  value,
  options: consumerOptions = [],
  additionalOptions,
  disabledRoles = [],
  onChange,
  isDisabled,
}: {
  value: ROLE[];
  options: ROLE[];
  additionalOptions?: React.ReactNode[];
  disabledRoles?: {
    role: ROLE;
    message?: string;
  }[];
  onChange?: (value: ROLE[]) => void;
  isDisabled?: boolean;
}) => {
  const triggerRef = useRef();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const canManage = (value || []).includes(ROLE.MANAGE);

  const options = consumerOptions.map((option) => ({
    ...roleOptions[option],
    id: option,
  }));

  return (
    <div className="highnote-role-picker">
      <Button
        disabled={!!isDisabled}
        className="RolePicker-edit"
        ref={triggerRef}
        data-is-active={isMenuOpen}
        theme={BUTTON_THEME.ICON}
        onClick={() => {
          if (isDisabled) return;
          setMenuOpen(true);
        }}
      >
        <EllipsisHorizontalSVG />
      </Button>
      <Menu
        className="RolePicker-menu"
        open={isMenuOpen}
        onClose={() => setMenuOpen(false)}
        anchorEl={triggerRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {options.map((option, i) => {
          const isIncludedInManage = [
            ROLE.COMMENT,
            ROLE.UPLOAD,
            ROLE.DOWNLOAD,
          ].includes(option.id as ROLE);
          const disableOption = disabledRoles.find((disabledRole) => {
            return disabledRole.role === option.id;
          });
          const disabled =
            (canManage && isIncludedInManage) || Boolean(disableOption);

          return (
            <PermissionTooltip
              key={`permission-option-${option.id}-${i}`}
              title={disableOption?.message}
              hasPermission={!disableOption}
            >
              <div>
                <MenuItem
                  className="RolePicker-option"
                  key={`item-${option.id}`}
                  disabled={disabled}
                  value={option.value}
                  onClick={(e) => {
                    e.stopPropagation();
                    let newValue: ROLE[] | null;

                    if (!option.value) {
                      newValue = null;
                    } else if (option.value.length === 0) {
                      newValue = [];
                    } else {
                      const selected: Set<ROLE> = new Set(value);
                      const alreadyOn = selected.has(option.id as ROLE);
                      if (alreadyOn) selected.delete(option.id as ROLE);
                      else option.value.forEach((role) => selected.add(role));
                      newValue = Array.from(selected);
                    }

                    onChange && onChange(newValue);
                  }}
                >
                  <span className="title">{option.label}</span>
                  <Switch checked={(value || []).includes(option.id as ROLE)} />
                </MenuItem>
              </div>
            </PermissionTooltip>
          );
        })}

        {options?.length > 0 && additionalOptions?.length > 0 && (
          <div className="divider"></div>
        )}

        {additionalOptions || null}
      </Menu>
    </div>
  );
};
