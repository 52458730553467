import "./JoinEntityDialog.scss";
import { highnote } from "@highnote/server/src/sdk";
import { Avatar, DialogProps } from "@mui/material";
import { Dialog, DialogButtons, DialogSection } from "App/common/Dialog";
import { ReactComponent as UserIcon } from "App/common/icons/user.svg";
import { TextInput } from "App/common/TextInput";
import {
  ConnectedUserAvatar,
  ConnectedUserName,
  USER_AVATAR_SIZE,
} from "App/common/UserAvatar/UserAvatar";
import { Button, BUTTON_THEME } from "App/core/Button";
import React, { useEffect, useState } from "react";
import { getAuthId, useAuth } from ".";
import { AuthForm, AUTH_TYPE } from "./AuthForm";
import { UserProviderRaw, useUserRaw } from "../useEntities";
import {
  SegmentContextProvider,
  useSegmentContext,
} from "App/modules/useSegment";
import { EVENT_ID } from "@highnote/server/src/core/entities";
import { useUrlContext } from "App/routes/Main/useUrlContext";
import {
  JoinEntityConfig,
  JOIN_ENTITY_TRIGGER,
  JOIN_BUTTON_TYPES,
} from "./util";
import { useToast } from "App/common/useToast";
import { useSpaceContext } from "App/common/useSpace";

const optionsByTrigger: Record<
  string,
  {
    title?: React.ReactNode | string;
    authTitle?: React.ReactNode | string;
    notfiyTitle?: React.ReactNode | string;
    referrer_type?: string;
  }
> = {
  [JOIN_ENTITY_TRIGGER.BUTTON]: {},
  [JOIN_ENTITY_TRIGGER.UPLOAD]: {
    title: "know who \n uploaded this file",
    notfiyTitle: "Get notified when your \n files are downloaded",
    authTitle: "Enable Notifications",
    referrer_type: "invite_link_upload",
  },
  [JOIN_ENTITY_TRIGGER.COMMENT]: {
    title: "know who’s commenting",
    notfiyTitle: "Get reply notifications",
    authTitle: "Enable Notifications",
    referrer_type: "invite_link_comment",
  },
  [JOIN_ENTITY_TRIGGER.DOWNLOAD]: {
    title: "know who’s downloading",
    notfiyTitle: "Get notified when \n new files are added",
    authTitle: "Enable Notifications",
    referrer_type: "invite_link_download",
  },
};

const _JoinEntityDialog = ({
  config,
  onDismiss,
  open,
  onClose,
}: {
  config: JoinEntityConfig;
  onDismiss: () => void;
} & DialogProps) => {
  const authId = getAuthId();
  const { shareKey } = useAuth();
  const { refreshSubscription } = useSpaceContext();
  const { trackId } = useUrlContext();
  const { trackEvent } = useSegmentContext();
  const { toasted } = useToast();
  const { entity: user, loading: userLoading } = useUserRaw();
  const [isSubmitting, setSubmitting] = useState<string | null>(null);
  const [step, setStep] = useState<number>(1);
  const [name, setName] = useState<string>("");
  const [showLogin, setShowLogin] = useState<boolean>(!!user?.name);
  const isAnonymous = !authId?.includes("|");

  let shareLink: string;
  if (config) {
    const rootUrl = `${
      window.location.origin
    }/${config.entityType.toLowerCase()}`;
    shareLink = `${rootUrl}/${config.entity.id}`;
    if (trackId) shareLink += `/${trackId}`;
    shareLink += `?shareKey=${shareKey}`;
  }

  useEffect(() => {
    if (open && !user?.name) {
      trackEvent(
        EVENT_ID.RECIPIENT_OPENED_JOIN_DIALOG,
        {
          inviteFlowTrigger: config.trigger,
          entityName: config.entity.name,
          entityId: config.entity.id,
          entityUrl: shareLink,
          recipientUserId: authId,
        },
        config.entity.createdBy,
      );
    }
  }, [open, user?.name, config, shareLink]);

  useEffect(() => {
    if (user?.name && step === 1) {
      setName(user.name);
      setShowLogin(true);
    }
  }, [user?.name]);

  useEffect(() => {
    if (!isAnonymous && config?.trigger === JOIN_ENTITY_TRIGGER.BUTTON) {
      // calling close to prevent showing the dialog on button trigger
      close();
      joinEntity();
    }
  }, [config]);

  const close = () => onClose && onClose(undefined, "escapeKeyDown");

  const saveName = async (buttonType?: string) => {
    setSubmitting(buttonType || null);
    await highnote.updateUser({
      id: authId,
      data: { name },
    });
    setSubmitting(null);

    if (buttonType === JOIN_BUTTON_TYPES.NOTIFY) {
      setShowLogin(true);
    }

    trackEvent(
      EVENT_ID.RECIPIENT_ADDED_NAME,
      {
        inviteFlowTrigger: config.trigger,
        entityName: config.entity.name,
        entityId: config.entity.id,
        entityUrl: shareLink,
        recipientUserId: authId,
      },
      config.entity.createdBy,
    );
  };

  const joinEntity = async () => {
    await toasted({
      promise: highnote
        .joinProtectedEntity({
          entityId: config.entity.id,
          shareKey,
        })
        .then(async () => {
          await highnote.__firebaseAuth.currentUser?.getIdToken(true);
          refreshSubscription(false, 0);
          onDismiss();
        }),
      createMessage: "Joining space...",
      ErrorContent: ({ error }: { error: Error }) => (
        <>Could not join space. {error.message}</>
      ),
    });
  };

  if (!config || userLoading) return null;

  const { entity, trigger } = config;

  const { title, authTitle, notfiyTitle, referrer_type } =
    optionsByTrigger[trigger];

  return (
    <Dialog
      className="JoinEntityDialog"
      open={open}
      onClose={() => {
        if (step === 1) {
          // Step 1: User is required to enter their name.
          // User can't close the dialog until they fill out their name.
          return;
        }
        close();
        if (showLogin) onDismiss();
      }}
      hideCloseButton
    >
      <DialogSection className="JoinEntityDialog-avatar">
        <Avatar alt="anonymous">
          <UserIcon />
        </Avatar>
        <ConnectedUserAvatar
          userId={entity.createdBy}
          size={USER_AVATAR_SIZE.JUMBO}
        />
      </DialogSection>

      {isAnonymous ? (
        !showLogin && title ? (
          <>
            <DialogSection>
              <p className="auth-headline">
                Let{" "}
                <span className="user-name">
                  <ConnectedUserName userId={entity.createdBy} />{" "}
                </span>{" "}
                {title}
              </p>
            </DialogSection>
            <DialogSection />
            {step === 1 && (
              <>
                <DialogSection>
                  <TextInput
                    placeholder="Name"
                    value={name}
                    onChange={setName}
                    onSubmit={() => saveName()}
                  />
                </DialogSection>
                <DialogSection />
              </>
            )}

            <DialogButtons>
              {/* Only show if there's a special title */}
              {step === 1 && (
                <Button
                  loading={isSubmitting === JOIN_BUTTON_TYPES.GUEST}
                  theme={BUTTON_THEME.CTA}
                  onClick={() => {
                    saveName(JOIN_BUTTON_TYPES.GUEST);
                    setStep(2);
                  }}
                  disabled={!name}
                >
                  Continue as Guest
                </Button>
              )}
              {step === 2 && (
                <>
                  <Button
                    style={
                      isSubmitting === JOIN_BUTTON_TYPES.NOTIFY
                        ? { paddingRight: "30px" }
                        : {}
                    }
                    loading={isSubmitting === JOIN_BUTTON_TYPES.NOTIFY}
                    theme={BUTTON_THEME.CTA}
                    onClick={() => {
                      saveName(JOIN_BUTTON_TYPES.NOTIFY);
                    }}
                  >
                    {authTitle}
                  </Button>
                  <Button
                    theme={BUTTON_THEME.SECONDARY}
                    onClick={() => {
                      close();
                      onDismiss();
                    }}
                    disabled={!name}
                  >
                    No thanks
                  </Button>
                </>
              )}
            </DialogButtons>

            <DialogSection>
              <p className="JoinEntityDialog-hint">
                Already have an account?{" "}
                <Button
                  theme={BUTTON_THEME.TEXT}
                  onClick={() => setShowLogin(true)}
                >
                  Log in
                </Button>
              </p>
            </DialogSection>
            <DialogSection />
          </>
        ) : (
          <>
            <DialogSection>
              <p className="auth-headline">{notfiyTitle}</p>
            </DialogSection>
            {notfiyTitle && <DialogSection />}
            <AuthForm
              type={AUTH_TYPE.JOIN}
              getDirection={() =>
                !title &&
                `You'll be able to track activity, get notifications, and participate in the conversation.`
              }
              name={name}
              onAuth={close}
              returnTo={`${location.pathname}${location.search}`}
              trigger={config.trigger}
              hideWelcomeSurvey
              signUpMetadata={{
                referrer_type: referrer_type || "invite_link_join_button",
                referrer_id: entity.createdBy,
              }}
            />
          </>
        )
      ) : (
        <>
          <DialogSection />
          <DialogButtons>
            <Button
              loading={isSubmitting === JOIN_BUTTON_TYPES.JOIN}
              theme={BUTTON_THEME.CTA}
              onClick={() => {
                setSubmitting(JOIN_BUTTON_TYPES.JOIN);
                joinEntity();
                setSubmitting(null);
                close();
              }}
            >
              Join Space
            </Button>
            <Button
              theme={BUTTON_THEME.SECONDARY}
              disabled={name.length < 3}
              onClick={() => {
                close();
                onDismiss();
              }}
            >
              Continue as Guest
            </Button>
          </DialogButtons>
        </>
      )}
    </Dialog>
  );
};

export const JoinEntityDialog = (
  props: {
    config: JoinEntityConfig;
    onDismiss: () => void;
  } & DialogProps,
) => {
  const authId = getAuthId();
  return (
    <SegmentContextProvider>
      <UserProviderRaw id={authId}>
        <_JoinEntityDialog {...props} />
      </UserProviderRaw>
    </SegmentContextProvider>
  );
};
