import React from "react";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as Eyes } from "App/common/icons/eyes.svg";
import { routePaths } from "App/modules/urls";
import { getAuthUrl, useAuth } from "App/components/Auth";
import styles from "./EntityNotFound.module.scss";

export const EntityNotFound = ({
  entityType,
}: {
  entityType: "Space" | "track" | "page";
}) => {
  const history = useHistory();
  const { user } = useAuth();

  const goLogIn = () => {
    history.push(getAuthUrl());
  };

  const isLoggedIn = !!user;

  return (
    <div
      className={styles["entity-not-found"]}
      data-cypress-id="entity-not-found"
    >
      <div className={styles.content}>
        <div className={styles.image}>
          <Eyes />
        </div>

        <h1>Something&apos;s not right</h1>

        <p>
          This {entityType} may not exist, or you{" "}
          {isLoggedIn ? (
            <>
              (<span className={styles.email}>{user.email}</span>){" "}
            </>
          ) : null}
          may not have permission to view&nbsp;it.
        </p>

        <p className="small">
          Verify permissions with the owner,{" "}
          {isLoggedIn ? (
            <>
              <button onClick={goLogIn}>log in</button> to another account
            </>
          ) : (
            <>
              <Link to={routePaths.login}>log in</Link> to your account
            </>
          )}
          , or{" "}
          <a href="mailto:help@highnote.fm" rel="noreferrer" target="_blank">
            contact&nbsp;support.
          </a>
        </p>
      </div>
    </div>
  );
};
