import React from "react";
import classNames from "classnames";
import { ReactComponent as ChatQuestionSVG } from "App/common/icons-v2/chat-question.svg";
import { UnreadDotV2 } from "App/common/UnreadDotV2";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import {
  INTERCOM_CUSTOM_LAUNCHER_SELECTOR,
  useIntercomContext,
} from "App/services/intercom/IntercomContext";
import styles from "./CustomIntercomLauncher.module.scss";

type Props = {
  size?: BUTTON_SIZE;
  className?: string;
};

export const CustomIntercomLauncher = ({ size, className }: Props) => {
  const { unreadMessageCount } = useIntercomContext();

  return (
    <Button
      id={INTERCOM_CUSTOM_LAUNCHER_SELECTOR}
      theme={BUTTON_THEME.ICON}
      size={size || BUTTON_SIZE.LARGE}
      className={classNames(styles.root, className)}
    >
      <ChatQuestionSVG />
      {unreadMessageCount > 0 && (
        <UnreadDotV2>
          {unreadMessageCount > 9 ? "9+" : unreadMessageCount}
        </UnreadDotV2>
      )}
    </Button>
  );
};
