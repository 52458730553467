import React, { useMemo, useState } from "react";

import { BUTTON_SIZE, BUTTON_THEME, Button } from "App/core/Button";
import { ReactComponent as CloseSVG } from "App/common/icons/close.svg";
import {
  SUBSCRIPTION_TIER,
  UserCore,
} from "@highnote/server/src/core/entities";
import { highnote } from "@highnote/server/src/sdk";
import { DialogSection } from "App/common/Dialog";
import { useAuth } from "App/components/Auth";
import styles from "./ControlledFeatureCallout.module.scss";

export enum CONTROLLED_SHARE_FEATURES {
  PASSWORD = "passwordControl",
  DOWNLOAD = "downloadControl",
  AUDIO_QUALITY_LOCK = "audioQualityLock",
}

interface ControlledFeatureCalloutProps {
  className?: string;
  featuresStatus?: {
    [key in CONTROLLED_SHARE_FEATURES]?: {
      enabled: boolean;
      label?: string;
    };
  };
  upgradeSuffix?: string;
  fullMessage?: string;
  onUpgrade: () => void;
}

export const ControlledFeatureTooltipLabel = {
  [CONTROLLED_SHARE_FEATURES.PASSWORD]:
    "Upgrade to Highnote Pro for password protection.",
  [CONTROLLED_SHARE_FEATURES.DOWNLOAD]:
    "Upgrade to Highnote Indie for download control.",
  [CONTROLLED_SHARE_FEATURES.AUDIO_QUALITY_LOCK]:
    "Upgrade to Highnote Studio for audio quality lock.",
};

const controlledShareFeatureLabelMap = {
  [CONTROLLED_SHARE_FEATURES.PASSWORD]: "password protected links",
  [CONTROLLED_SHARE_FEATURES.DOWNLOAD]: "download control",
  [CONTROLLED_SHARE_FEATURES.AUDIO_QUALITY_LOCK]:
    "advanced features and even more storage",
};

const controlledShareFeatureUserKeyMap: {
  [key in CONTROLLED_SHARE_FEATURES]: keyof UserCore;
} = {
  [CONTROLLED_SHARE_FEATURES.PASSWORD]: "passwordProtectionCalloutDismissed",
  [CONTROLLED_SHARE_FEATURES.DOWNLOAD]: "downloadControlCalloutDismissed",
  [CONTROLLED_SHARE_FEATURES.AUDIO_QUALITY_LOCK]:
    "audioQualityLockCalloutDismissed",
};

export const ControlledFeatureCallout = ({
  className = "",
  featuresStatus = {},
  upgradeSuffix = "to enable",
  fullMessage,
  onUpgrade = () => {},
}: ControlledFeatureCalloutProps) => {
  const { user } = useAuth();
  const dismissable =
    user?.subscriptionTier && user?.subscriptionTier !== SUBSCRIPTION_TIER.FREE;

  const [isSaving, setIsSaving] = useState(false);
  const disabledFeatures = useMemo(() => {
    if (!user) {
      return [];
    }
    return Object.keys(featuresStatus).filter((key) => {
      const featureKey = key as CONTROLLED_SHARE_FEATURES;
      return (
        !featuresStatus[featureKey].enabled &&
        !user[controlledShareFeatureUserKeyMap[featureKey]]
      );
    }) as CONTROLLED_SHARE_FEATURES[];
  }, [featuresStatus, user]);
  const handleClose = async () => {
    try {
      setIsSaving(true);
      const payload = disabledFeatures.reduce<Partial<UserCore>>(
        (acc, curr) => {
          (acc[controlledShareFeatureUserKeyMap[curr]] as boolean) = true;
          return acc;
        },
        {} as Partial<UserCore>,
      );
      await highnote.updateUser({
        id: user.id,
        data: payload as Partial<UserCore>,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setIsSaving(false);
    }
  };
  if (Object.keys(disabledFeatures).length < 1 && !fullMessage) {
    return null;
  }
  const labels = disabledFeatures.map(
    (name) =>
      featuresStatus[name].label ||
      controlledShareFeatureLabelMap[name as CONTROLLED_SHARE_FEATURES],
  );
  const formattedLabels =
    labels.length > 2
      ? `${labels.slice(0, labels.length - 1).join(", ")}and ${
          labels[labels.length - 1]
        }`
      : labels.join(" and ");

  const message = fullMessage || `Upgrade ${upgradeSuffix} ${formattedLabels}.`;

  return (
    <DialogSection
      className={className}
      data-cypress-id="highnote-space-editor-callout"
    >
      <div className={styles["share-control-callout-wrapper"]}>
        <div className={styles["share-control-callout-backdrop"]} />
        {dismissable && (
          <Button
            disabled={isSaving}
            className={`${styles["highnote-button"]} ${styles["share-control-callout-close"]}`}
            theme={BUTTON_THEME.CTA}
            size={BUTTON_SIZE.XSMALL}
            type="button"
            onClick={handleClose}
          >
            <CloseSVG />
          </Button>
        )}

        <div className={styles["share-control-callout-inner"]}>
          <div className={styles["share-control-callout-header"]}>
            {message}
          </div>
          <Button
            theme={BUTTON_THEME.CTA}
            size={BUTTON_SIZE.MEDIUM}
            type="button"
            onClick={() => {
              onUpgrade();
            }}
          >
            Upgrade
          </Button>
        </div>
      </div>
    </DialogSection>
  );
};
