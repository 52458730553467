import "./Slider.scss";
import React from "react";
import {
  Slider as MuiSlider,
  SliderProps as MuiSliderProps,
} from "@mui/material";
import { useTheme } from "App/common/ThemeProvider";

export type SliderProps = MuiSliderProps;
export const Slider = (props: SliderProps) => {
  const { theme } = useTheme();

  const { ...rest } = props;

  return (
    <MuiSlider
      {...rest}
      classes={{ root: `highnote-slider ${props.className}` }}
      data-theme={theme}
      disabled={props.disabled}
      data-disabled={props.disabled}
      value={props.value}
      onChange={(e, value) => {
        if (props.disabled) {
          e.preventDefault();
          return;
        }
        props.onChange && props.onChange(e, value, null);
      }}
    />
  );
};
