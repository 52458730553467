import "./EntityLibrary.scss";
import React, { useEffect, useMemo, useState } from "react";
import { Space, Track } from "@highnote/server/src/core/entities";
import { Button, BUTTON_THEME } from "App/core/Button";
import { useHistory } from "react-router";
import { THEME, ThemeProvider } from "App/common/ThemeProvider";
import { UPLOAD_GROUP, useFiles } from "App/components/useFiles";
import { EntityTable, EntityRowConfig } from "App/components/EntityTable";
import {
  ENTITY_TYPE,
  DEFAULT_COLUMNS,
} from "App/components/EntityTable/config";
import {
  TrackEditorProvider,
  useTrackEditor,
} from "App/components/useTrackEditor";
import { FileEditorProvider } from "App/components/useFileEditor";
import { ColumnConfig, TableRowVariant } from "App/common/BaseTable";
import { ReactComponent as CaretRightSVG } from "App/common/icons/caret-right.svg";
import { Breadcrumbs } from "App/common/Breadcrumbs";

const defaultColumns = [
  DEFAULT_COLUMNS.PREVIEW,
  DEFAULT_COLUMNS.COMMENTS,
  DEFAULT_COLUMNS.SHORTCUT,
  DEFAULT_COLUMNS.ACTIONS,
];

const MOBILE_COLUMNS = [
  DEFAULT_COLUMNS.PREVIEW,
  DEFAULT_COLUMNS.COMMENTS,
  DEFAULT_COLUMNS.ACTIONS,
];

const defaultFilter = () => true;
const defaultSort = () => 1;

const EntityLibraryUI = ({
  filter = defaultFilter,
  sort = defaultSort,
  onSelect,
  onChange,
  EmptyComponent,
  columns = defaultColumns,
  spaceRows,
  trackRows,
  hideBreadcrumbs,
  tableRowVariant,
  rootEntity,
  onRootEntityChange,
  processRows,
}: {
  filter?: (row: EntityRowConfig) => boolean;
  sort?: (a: EntityRowConfig, b: EntityRowConfig) => number;
  onSelect?: (row: EntityRowConfig) => void;
  onChange?: (rows: EntityRowConfig[]) => void;
  EmptyComponent?: React.FC;
  columns: ColumnConfig[];
  spaceRows: EntityRowConfig[];
  trackRows: EntityRowConfig[];
  hideBreadcrumbs?: boolean;
  tableRowVariant?: TableRowVariant;
  rootEntity?: Space;
  onRootEntityChange: (entity: Space) => void;
  processRows?: (rows: EntityRowConfig[]) => Promise<EntityRowConfig[]>;
}) => {
  const history = useHistory();
  const { getUploadCache, getUploads } = useFiles();
  const { openTrackEditor } = useTrackEditor();
  const uploadCache = getUploadCache(UPLOAD_GROUP.TRACKS_BY_SPACE, null);
  const uploads = getUploads({ cache: uploadCache });

  const columnsWithNesting = useMemo(() => {
    if (tableRowVariant === "no-nesting") return columns;

    return [
      ...columns,
      {
        // TODO: Is this still needed in the Library?
        // We currently only support click-thru nested spaces in dialogs.
        name: "Change Root",
        id: "change-root",
        Component: ({ row }: { row: EntityRowConfig }) => {
          if (row.type === ENTITY_TYPE.SPACE) {
            return (
              <Button
                theme={BUTTON_THEME.ICON}
                onClick={() => {
                  onRootEntityChange(row.entity as Space);
                }}
                disabled={!row.onClick}
              >
                <CaretRightSVG />
              </Button>
            );
          }

          return null;
        },
      },
    ];
  }, [columns, tableRowVariant]);

  const uploadRows = useMemo(
    () =>
      uploads
        .map((upload) => ({
          id: upload.file.id,
          entity: upload,
          type: ENTITY_TYPE.UPLOAD,
        }))
        .filter(filter),
    [uploads, filter],
  );

  const currentTrackRows = useMemo(() => {
    return trackRows.map((trackRow) => {
      const track = trackRow.entity as Track;
      if (onSelect) {
        trackRow.actions = [];
      }
      if (onSelect || !track.spaceId) {
        delete trackRow.confirmOnClick;
        delete trackRow.onConfirmCancel;
      }
      trackRow.onClick = () => {
        if (onSelect) {
          onSelect(trackRow);
        } else if (track.spaceId) {
          history.push(`/space/${track.spaceId}/${track.id}`);
        } else {
          openTrackEditor({ track });
        }
      };
      return trackRow;
    });
  }, [trackRows, onSelect, history]);

  const currentSpaceRows = useMemo(() => {
    return spaceRows.map((spaceRow) => {
      if (onSelect) {
        spaceRow.actions = [];
      }
      spaceRow.onClick = () => {
        if (onSelect) {
          onSelect(spaceRow);
        } else {
          history.push(`/space/${(spaceRow.entity as Space).id}`);
        }
      };
      return spaceRow;
    });
  }, [spaceRows, onSelect, history]);

  const filteredRows = useMemo(() => {
    return [...currentTrackRows, ...currentSpaceRows].filter(filter);
  }, [currentTrackRows, currentSpaceRows, filter]);

  const sortedRows = useMemo(() => {
    return filteredRows.sort(sort);
  }, [filteredRows, sort]);

  const [tableRows, setTableRows] = useState([]);

  useEffect(() => {
    if (!processRows) {
      setTableRows(sortedRows);
      onChange?.(sortedRows);
      return;
    }
    processRows?.(sortedRows).then((processedRows) => {
      setTableRows(processedRows);
      onChange?.(processedRows);
    });
  }, [sortedRows, onChange]);

  return (
    <div className="highnote-entity-library">
      {tableRowVariant !== "no-nesting" && !hideBreadcrumbs && (
        <Breadcrumbs
          activeSpace={rootEntity}
          onEntityClick={(entity) => {
            onRootEntityChange(entity);
          }}
        />
      )}

      <EntityTable
        rows={[...uploadRows, ...tableRows]}
        columns={columnsWithNesting}
        mobileColumns={MOBILE_COLUMNS}
        EmptyComponent={EmptyComponent}
        tableRowVariant={tableRowVariant}
      />
    </div>
  );
};

export interface EntityLibraryProps {
  sort?: (a: EntityRowConfig, b: EntityRowConfig) => number;
  filter?: (row: EntityRowConfig) => boolean;
  onSelect?: (row: EntityRowConfig) => Promise<void> | void;
  onChange?: (rows: EntityRowConfig[]) => void;
  EmptyComponent?: React.FC;
  columns?: ColumnConfig[];
  spaceRows: EntityRowConfig[];
  trackRows: EntityRowConfig[];
  hideBreadcrumbs?: boolean;
  tableRowVariant?: TableRowVariant;
  disabled?: boolean;
  rootEntity?: Space;
  onRootEntityChange?: (entity: Space) => void;
  processRows?: (rows: EntityRowConfig[]) => Promise<EntityRowConfig[]>;
}

export const EntityLibrary = React.memo(
  ({
    sort,
    filter,
    onSelect,
    onChange,
    EmptyComponent,
    columns,
    spaceRows,
    trackRows,
    hideBreadcrumbs,
    tableRowVariant,
    rootEntity,
    onRootEntityChange,
    processRows,
  }: EntityLibraryProps) => {
    return (
      <TrackEditorProvider>
        <FileEditorProvider>
          <ThemeProvider theme={THEME.DARK}>
            <EntityLibraryUI
              filter={filter}
              sort={sort}
              onSelect={onSelect}
              onChange={onChange}
              columns={columns}
              EmptyComponent={EmptyComponent}
              spaceRows={spaceRows}
              trackRows={trackRows}
              hideBreadcrumbs={hideBreadcrumbs}
              tableRowVariant={tableRowVariant}
              rootEntity={rootEntity}
              onRootEntityChange={onRootEntityChange}
              processRows={processRows}
            />
          </ThemeProvider>
        </FileEditorProvider>
      </TrackEditorProvider>
    );
  },
);
EntityLibrary.displayName = "MemoizedEntityLibrary";
