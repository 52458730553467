import { daw } from "@highnote/daw/src";
import { Logtail } from "@logtail/browser";
import { matchPath } from "react-router";
import { routePaths } from "./urls";

// View logs at: https://logs.betterstack.com/
const logtail = new Logtail("25kxc3zRGXfRHrobDmiz1B8c");

const getView = () => {
  const viewsToTrack = [
    "spaceHome",
    "spaceTrack",
    "library",
    "collectionHome",
    "login",
    "settings",
  ];

  const view = viewsToTrack.find((key) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const path = (routePaths as any)[key];
    return !!matchPath(location.pathname, {
      path,
      exact: true,
      strict: false,
    });
  });

  return view;
};

let fpsCounts: number[] = [];
let start = Date.now();
let frameCount = 1;

const resetCount = () => {
  start = Date.now();
  frameCount = 0;
};

const countFrame = () => {
  const now = Date.now();

  if (now - start >= 1000) {
    fpsCounts.push(frameCount);
    resetCount();
  } else {
    frameCount += 1;
  }

  requestAnimationFrame(countFrame);
};

countFrame();

const lastView = getView();
setInterval(() => {
  if (fpsCounts.length < 1) return;

  // Don't bother firing this stuff when the page is not visible to the user.
  if (document.hidden) {
    resetCount();
    return;
  }

  const view = getView();
  if (!view) return;
  if (view !== lastView) {
    resetCount();
    return;
  }

  const totalFPS = fpsCounts.reduce((sum, count) => sum + count, 0);
  const averageFPS = totalFPS / fpsCounts.length;
  fpsCounts = [];

  logtail.info("Average FPS", {
    view,
    fps: averageFPS,
    branch: process.env.BRANCH || "",
    audioStats: {
      isPlaying: daw.state.isPlaying,
      numTracks: daw.state.tracks.length,
      duration: daw.totalDuration,
      volume: daw.volume,
      quality: localStorage.getItem("hignote-audio-quality"),
    },
  });
}, 10000);
