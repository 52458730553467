import "./Login.scss";
import React, { useEffect, useMemo, useState } from "react";
import { AuthForm, AUTH_TYPE } from "App/components/Auth/AuthForm";
import { routePaths } from "App/modules/urls";
import { matchPath, useHistory } from "react-router";
import {
  getIsInvitedUserLogin,
  linkAccount,
  logIn,
  useAuth,
} from "App/components/Auth";
import { ReactComponent as LogoFullSVG } from "App/common/icons-v2/logo-full-beta.svg";
import { ReactComponent as DopboxLogoFullSVG } from "App/common/icons/login-dropbox.svg";
import LoadingScreen from "App/common/LoadingScreen/LoadingScreen";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  SUBSCRIPTION_INTERVAL,
  SUBSCRIPTION_PRICING,
  SUBSCRIPTION_TIER,
} from "@highnote/server/src/core/entities";
import { highnote } from "@highnote/server/src/sdk";
import { JOIN_ENTITY_TRIGGER } from "App/components/Auth/util";
import { useToast } from "App/common/useToast";
import { useInviteSpaceData } from "App/components/Auth/useInviteSpaceData";

const DEFAULT_REDIRECT_PATH = "/library";
const DEFAULT_IMAGE_URL = "/public/default-track-artwork.png";

enum LoginIntegrations {
  Default = "default",
  Dropbox = "dropbox",
}

const LoginIntegrationFromURLs = {
  [routePaths.dropboxLogin]: LoginIntegrations.Dropbox,
  [routePaths.dropboxClone]: LoginIntegrations.Dropbox,
};

const LoginIntegrationsMap: Record<
  LoginIntegrations,
  {
    title?: string;
    subtitle?: string;
    icon: React.ReactNode;
  }
> = {
  [LoginIntegrations.Default]: {
    icon: <img src={DEFAULT_IMAGE_URL} />,
  },
  [LoginIntegrations.Dropbox]: {
    title: "Use Highnote with Dropbox",
    subtitle: "Login or create a free account to continue",
    icon: <DopboxLogoFullSVG />,
  },
};

export const Login = () => {
  const history = useHistory();
  const { addErrorMessage } = useToast();
  const { refreshAuth, user, authLoading } = useAuth();
  const isInvitedUserLogin = useMemo(() => getIsInvitedUserLogin(), []);
  const { inviteSpaceData, isInviteSpaceLoading } = useInviteSpaceData();

  const isSignup = !!matchPath(location.pathname, {
    path: "/signup",
    exact: true,
    strict: false,
  });
  const isLink = !!matchPath(location.pathname, {
    path: "/link",
    exact: true,
    strict: false,
  });
  const query = new URLSearchParams(location.search);
  let returnTo = query.get("returnTo") || "/";
  const [userLoading, setUserLoading] = useState(false);

  // Don't fall into a weird loop with logout.
  if (returnTo.indexOf("/logout") === 0) returnTo = "/";
  if (returnTo.indexOf("/login") === 0) returnTo = "/";

  const loginIntegrationConfig = useMemo(() => {
    if (isInvitedUserLogin) {
      return {
        icon: (
          <div className="invite-space-media">
            <img src={inviteSpaceData?.spaceArtworkUrl || DEFAULT_IMAGE_URL} />
            <img
              src={inviteSpaceData?.spaceOwner?.picture || DEFAULT_IMAGE_URL}
              className="avatar"
            />
          </div>
        ),
      };
    }

    try {
      const { pathname: returnToPathname } = new URL(
        decodeURIComponent(returnTo),
        location.origin,
      );
      const loginIntegration = LoginIntegrationFromURLs[returnToPathname];
      return LoginIntegrationsMap[
        loginIntegration || LoginIntegrations.Default
      ];
    } catch (e) {
      // return default if returnTo is not a valid URL
      return LoginIntegrationsMap[LoginIntegrations.Default];
    }
  }, [returnTo, inviteSpaceData, isInvitedUserLogin]);

  const banner = query.get("banner") || "";
  const email = query.get("email") || "";
  const mkt_campaign = query.get("mkt_campaign") || undefined;
  const referrer_type = query.get("referrer_type") || undefined;
  const referrer_id = query.get("referrer_id") || undefined;
  const irclickid = query.get("irclickid") || undefined;
  const subscription = query.get("subscription") || undefined;
  const coupon_code = query.get("coupon_code") || undefined;
  const frequency = query.get("frequency") || undefined;
  // Default 'frequencyKey' to monthly when frequency is used without a value
  // to avoid sticker shock on stripe checkout.
  let frequencyKey: SUBSCRIPTION_INTERVAL = SUBSCRIPTION_INTERVAL.MONTHLY;
  if (frequency && frequency.toLowerCase() === SUBSCRIPTION_INTERVAL.ANNUAL) {
    frequencyKey = SUBSCRIPTION_INTERVAL.ANNUAL;
  }

  const trigger = query.get("trigger") || undefined;
  const hideWelcomeSurvey = query.get("hideWelcomeSurvey") || undefined;

  const auth0Params = new URLSearchParams(location.hash.substring(1));
  const accessToken = auth0Params.get("access_token") || undefined;
  const currentSubscription = useMemo(
    () => subscription?.toUpperCase(),
    [subscription],
  );

  useEffect(() => {
    // user is already logged in and there is no accessToken to use for authentication
    if (!accessToken && !authLoading && !!user) {
      setUserLoading(true);
      if (
        subscription &&
        currentSubscription in SUBSCRIPTION_TIER &&
        currentSubscription !== SUBSCRIPTION_TIER.FREE
      ) {
        highnote
          .getCheckoutUrl({
            priceId:
              SUBSCRIPTION_PRICING[currentSubscription as SUBSCRIPTION_TIER][
                frequencyKey
              ],
            source: window.location.pathname,
            coupon_code,
          })
          .then(({ checkoutUrl, subscriptionDirection }) => {
            if (checkoutUrl && typeof checkoutUrl === "string") {
              window.location.href = checkoutUrl;
            } else if (subscriptionDirection < 0) {
              // When downgrading, checkoutUrl will be undefined,
              // but we still want to redirect them to somewhere useful
              history.replace("/settings/plans");
            } else {
              // When the subscription is the same (subscriptionDirection === 0),
              // OR checkoutUrl is undefined, redirect to the library
              history.replace(DEFAULT_REDIRECT_PATH);
            }
          })
          .catch((e) => {
            console.error("[DEBUG] Failed to get checkout URL.", e);
            addErrorMessage(e.message, {
              title: "Failed to get checkout URL.",
            });
            history.replace(DEFAULT_REDIRECT_PATH);
          })
          .finally(() => {
            setUserLoading(false);
          });
      } else {
        history.push(returnTo);
        refreshAuth();
        setUserLoading(false);
      }
    } else {
      setUserLoading(false);
    }
  }, [accessToken, user, authLoading, subscription, coupon_code]);

  useEffect(() => {
    if (accessToken) {
      (isLink ? linkAccount : logIn)({
        method: "token",
        token: accessToken,
        subscription:
          subscription && currentSubscription in SUBSCRIPTION_TIER
            ? (currentSubscription as SUBSCRIPTION_TIER)
            : undefined,
        coupon_code,
        frequency: frequencyKey,
        ...(trigger &&
          Object.values(JOIN_ENTITY_TRIGGER).includes(
            trigger as JOIN_ENTITY_TRIGGER,
          ) && {
            trigger: trigger as JOIN_ENTITY_TRIGGER,
          }),
      })
        .then(({ redirectPath }) => {
          // `redirectPath` starts with `https?` only when the user is being redirected
          // to Stripe checkout page, so we just do the redirect right away.
          if (redirectPath?.startsWith("http")) {
            window.location.href = redirectPath;
            return;
          }
          // this is when the user is being redirected to a different page outside the app
          // so we also just do the redirect right away
          if (returnTo?.startsWith("http")) {
            window.location.href = returnTo;
            return;
          }
          // currently, the only possible value for `redirectPath` is the demo space path,
          // which is populated only when a user signs up for the first time, but this redirect
          // should happen only when `returnToPath` is not a space or dropbox path
          history.push(
            !returnTo.startsWith("/space") &&
              !returnTo.startsWith("/dropbox") &&
              redirectPath
              ? redirectPath
              : returnTo,
          );
          refreshAuth();
        })
        .catch((e) => {
          console.log("[DEBUG] Failed to authenticate via token.", e);
          history.push(
            `${location.pathname}#error=true&error_description=${e.message}`,
          );
        });
    }
  }, [hideWelcomeSurvey, accessToken, isLink]);

  let authType = AUTH_TYPE.LOG_IN;
  if (isSignup) authType = AUTH_TYPE.SIGN_UP;
  if (isLink) authType = AUTH_TYPE.LINK;

  return (
    <div className="highnote-login">
      <Helmet>
        <body data-show-welcome-survey={false} />
      </Helmet>

      {banner && !isInvitedUserLogin && <div className="banner">{banner}</div>}
      {accessToken || userLoading || isInviteSpaceLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <div className="header">
            <Link to="/" data-cypress-id="highnote-logo" className="logo">
              <LogoFullSVG />
            </Link>

            {loginIntegrationConfig.icon}
          </div>

          <AuthForm
            returnTo={returnTo}
            type={authType}
            email={email.replace(" ", "+")}
            signUpMetadata={{
              mkt_campaign,
              referrer_id,
              referrer_type,
              irclickid,
            }}
            subscription={
              subscription && currentSubscription in SUBSCRIPTION_TIER
                ? (currentSubscription as SUBSCRIPTION_TIER)
                : undefined
            }
            couponCode={coupon_code}
            frequency={frequencyKey}
            title={loginIntegrationConfig.title}
            subtitle={loginIntegrationConfig.subtitle}
            inviteSpaceData={inviteSpaceData}
          />
        </>
      )}
    </div>
  );
};
