const binarySearch = (items: number[], value: number): number => {
  // Return the index of the last item that's less than or equal to `value`.
  // If no such item exists, return -1.

  let low = 0;
  let high = items.length - 1;

  while (low <= high) {
    const mid = Math.floor((low + high) / 2);
    if (items[mid] <= value) {
      low = mid + 1;
    } else {
      high = mid - 1;
    }
  }

  return high;
};

export default binarySearch;
