export const FROM_MOBILE_APP_FLAG = "fromMobileApp";
export const MOBILE_TOKEN_FLAG = "mobileToken";
export const SHOULD_REDIRECT_TO_MOBILE_FLAG = "shouldRedirectToMobile";
export const WEB_APP_COMPONENT_FLAG = "webAppComponent";
export const MOBILE_RETURN_TO_ON_ERR_FLAG = "returnToOnError";

const root = "";

export const routePaths = {
  root: `${root}/`,
  download: `${root}/download/:requestId`,
  spaceRoot: `${root}/space`,
  createSpace: `${root}/create-space`,
  spaceHome: `${root}/space/:spaceId`,
  spaceHandoff: `${root}/space-handoff`,
  space: `${root}/space/:spaceId?/:trackId?/:trackVersionId?`,
  spaceTrack: `${root}/space/:spaceId/:trackId`,
  library: `${root}/library`,

  settings: `${root}/settings/:settingsTabId?`,
  checkout: `${root}/checkout`,

  startLoopSubmission: `${root}/start-loop-submission`,

  completeLoopSubmission: `${root}/complete-loop-submission`,

  login: `${root}/login`,
  signup: `${root}/signup`,
  link: `${root}/link`,
  logout: `${root}/logout`,

  plans: `${root}/plans`,
  usage: `${root}/settings/usage`,

  track: `${root}/track/:trackId`,

  share: `${root}/share/:feedbackRequestId`,
  test: `${root}/test`,

  collectionRoot: `${root}/collection`,
  collectionHome: `${root}/collection/:collectionId`,

  dropboxLogin: `${root}/dropbox/login`,
  dropboxCallback: `${root}/dropbox/callback`,
  dropboxClone: `${root}/dropbox/clone`,

  mobilePrompt: `${root}/mobile-prompt`,
};

export const withMobileDeeplinkRoot = (path: string) => {
  const root =
    window.location.origin === "https://on.highnote.fm"
      ? `highnote`
      : `highnote-dev`;
  return `${root}://${path}`;
};

export const withMobileAuthDeeplinkRoot = (path: string) => {
  const root =
    window.location.origin === "https://on.highnote.fm"
      ? `highnote-auth`
      : `highnote-dev-auth`;
  return `${root}://${path}`;
};

export const sanitizeCurrentURL = (keysToRemove: string[]) => {
  const searchParams = new URLSearchParams(window.location.search);
  keysToRemove.forEach((key) => {
    searchParams.delete(key);
  });
  return `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`;
};
