import { chunk } from "lodash";
import {
  COLLECTION_ID,
  DropboxSyncJob,
  Space,
  Track,
} from "@highnote/server/src/core/entities";
import { highnote } from "@highnote/server/src/sdk";
import { collection, where, query, limit, getDocs } from "firebase/firestore";

const getChildTracks = async (spaceId: string, queryLimit: number = 50) => {
  const childTracksQuery = query(
    collection(highnote.__firebaseFirestore, COLLECTION_ID.TRACK),
    where("spaceId", "==", spaceId),
    limit(queryLimit),
  );

  const snapshot = await getDocs(childTracksQuery);
  return snapshot.docs.map((s) => s.data()) as Track[];
};

const getChildSpaces = async (spaceId: string, queryLimit: number = 50) => {
  const childSpacesQuery = query(
    collection(highnote.__firebaseFirestore, COLLECTION_ID.SPACE),
    where("spaceId", "==", spaceId),
    limit(queryLimit),
  );

  const snapshot = await getDocs(childSpacesQuery);
  return snapshot.docs.map((s) => s.data()) as Space[];
};

// TODO: Should we fetch attachments/files as well here?
export const getChildEntities = async (spaceId: string) => {
  const [childTracks, childSpaces] = await Promise.all([
    getChildTracks(spaceId),
    getChildSpaces(spaceId),
  ]);
  return { childTracks, childSpaces };
};

export const getUserDropboxJobsByFileIds = async ({
  fileIds,
  userId,
}: {
  fileIds: string[];
  userId: string;
}) => {
  const fildIdsChunks = chunk(Array.from(new Set(fileIds)), 10);
  const jobs: DropboxSyncJob[] = [];
  for (const fileIds of fildIdsChunks) {
    const existingJobs = await getDocs(
      query(
        collection(highnote.__firebaseFirestore, COLLECTION_ID.DROPBOX_JOBS),
        where("fileId", "in", fileIds),
        where("createdBy", "==", userId),
      ),
    );
    jobs.push(...existingJobs.docs.map((doc) => doc.data() as DropboxSyncJob));
  }
  return jobs;
};
