import React, {
  useState,
  createContext,
  useMemo,
  useContext,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { useHistory } from "react-router";
import {
  COLLECTION_ID,
  Space,
  Track,
} from "@highnote/server/src/core/entities";
import { ReactComponent as WarningSVG } from "App/common/icons/warning-hollow.svg";
import { Button, BUTTON_THEME } from "App/core/Button";
import { DialogButtons, Dialog, DialogSection } from "App/common/Dialog";
import { useHighnote } from "App/common/useHighnote";
import {
  ENTITY_TYPE,
  DEFAULT_COLUMNS,
} from "App/components/EntityTable/config";
import {
  ScrollableContainer,
  SCROLLABLE_CONTAINER_VARIANT,
} from "App/common/ScrollableContainer";
import {
  getMoveToSpaceWarning,
  sortRowByAlphabetical,
} from "App/components/util";
import { Callout, CALLOUT_LAYOUT } from "App/core/Callout";
import { EmptyTable } from "App/components/EntityTable/EmptyTable";
import { useAuth } from "App/components/Auth";
import { PERMISSION } from "@highnote/server/src/core/shared-util";
import { ClickThruSpaceRowComponent } from "App/components/ClickThruSpaceRow";
import { isDescendantRecursiveForSpaces } from "../useLibrarySpaces/util";
import { highnote } from "@highnote/server/src/sdk";
import { useGlobalSpaces } from "App/store/spaces/useGlobalSpaces";
import { useMobileAppParams } from "App/routes/Main/useMobileAppParams";
import { CreateSpaceWithNameDialog } from "App/components/Dialog/CreateSpace/CreateSpaceWithNameDialog";

const MoveToSpaceContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openMoveToSpace: (props: {
    entity: Space | Track;
    entityType: COLLECTION_ID.SPACE | COLLECTION_ID.TRACK;
  }) => {},
});

export const MoveToSpaceProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { showMoveToSpaceDialog, redirectToMobileApp } = useMobileAppParams();
  const { replace, location } = useHistory();
  const { user, isAllowed } = useAuth();
  const [isOpen, setOpen] = useState(false);
  const { getGlobalSpace } = useGlobalSpaces();
  const [entityToMove, setEntityToMove] = useState<Space | Track>();
  const [entityTypeToMove, setEntityTypeToMove] = useState<
    COLLECTION_ID.SPACE | COLLECTION_ID.TRACK
  >();
  const [parentSpace, setParentSpace] = useState<Space | undefined>();
  const [isNewSpaceDialogOpen, setIsNewSpaceDialogOpen] = useState(false);

  const { moveToSpace } = useHighnote();
  const [confirmation, setConfirmation] = useState<React.ReactNode>();
  const [confirmationLoading, setConfirmationLoading] =
    useState<boolean>(false);
  const [isMoving, setIsMoving] = useState<boolean>(false);
  const [currentRoot, setCurrentRoot] = useState<Space>();
  const selectedSpace = useRef<Space>();

  const LIBRARY_COLUMNS = useMemo(
    () => [DEFAULT_COLUMNS.ICON, DEFAULT_COLUMNS.PREVIEW],
    [],
  );

  const close = useCallback(() => {
    if (showMoveToSpaceDialog) {
      redirectToMobileApp();
      return;
    }

    setOpen(false);
    setCurrentRoot(parentSpace);
  }, [showMoveToSpaceDialog, parentSpace]);

  const entityRedirect = (spaceId: string) => {
    // redirect to new track page, with it's new parent space
    if (entityTypeToMove === COLLECTION_ID.TRACK) {
      if (location.pathname.includes(entityToMove.id)) {
        replace(`/space/${spaceId}/${entityToMove.id}`);
      }
    }
  };

  const requestMoveToSpace = async (space: Space) => {
    setConfirmationLoading(true);
    const warning = await getMoveToSpaceWarning({
      entity: entityToMove,
      entityType: entityTypeToMove,
      spaceDestination: space,
      userId: user.id,
    });

    if (!warning) {
      await moveToSpace({
        entityId: entityToMove.id,
        entityType: entityTypeToMove,
        newSpaceId: space?.id,
      });
      setConfirmationLoading(false);
      entityRedirect(space.id);
      close();
      return;
    }

    selectedSpace.current = space;
    setConfirmation(
      <Callout
        layout={CALLOUT_LAYOUT.ROW}
        icon={<WarningSVG />}
        title={warning.title}
        body={warning.description}
      />,
    );
    setConfirmationLoading(false);
  };

  const value = useMemo(
    () => ({
      openMoveToSpace: (props: {
        entity: Space | Track;
        entityType: COLLECTION_ID.TRACK | COLLECTION_ID.SPACE;
      }) => {
        setConfirmation(null);
        selectedSpace.current = undefined;
        // eslint-disable-next-line react/prop-types
        setEntityToMove(props.entity);
        // eslint-disable-next-line react/prop-types
        setEntityTypeToMove(props.entityType);
        setOpen(true);
      },
    }),
    [],
  );

  const filter = useCallback(
    (row) => entityToMove && row.type == ENTITY_TYPE.SPACE,
    [entityToMove],
  );

  let entityName = "Library";
  if (entityToMove && "name" in entityToMove) {
    entityName = entityToMove.name;
  } else if (entityToMove && "title" in entityToMove) {
    entityName = entityToMove.title;
  }

  const dialogTitle = `Move ${entityName} to...`;

  const isEntityInParentSpace = entityToMove?.spaceId === currentRoot?.id;
  const isInLibraryRootSpace = !currentRoot;
  const canMoveIntoLibraryRootSpace =
    entityToMove?.spaceId && // Check if entity is already in Library Root Space. Would be undefined.
    isAllowed(PERMISSION.TO_MANAGE_SPACE, {
      space: parentSpace,
    });

  const getParentSpace = async () => {
    const globalSpace = getGlobalSpace(entityToMove?.spaceId);

    // Always set the parent space, even if it's undefined
    setParentSpace(globalSpace);

    if (globalSpace) {
      return globalSpace;
    }

    try {
      // TODO(globalState): This will rarely be used, when we implement the
      // new global space state in SpaceHome. Can keep as a fallback.
      const space = await highnote.getSpace({
        id: entityToMove?.spaceId,
        fromCache: true,
      });

      // Update the parent space
      setParentSpace(space);
      return space;
    } catch (error) {
      console.error("Error getting parent space", error);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchParentSpace = async () => {
      const parentSpace = await getParentSpace();
      if (isMounted) {
        // Set the current root to the parent space
        // when opening the modal. This is to avoid
        // always opening to the Library root space.
        setCurrentRoot(parentSpace);
      }
    };

    fetchParentSpace();

    return () => {
      isMounted = false;
    };
  }, [entityToMove]);

  return (
    <MoveToSpaceContext.Provider value={value}>
      <Dialog
        className="highnote-add-to-space"
        open={isOpen}
        onClose={close}
        title={dialogTitle}
      >
        <DialogSection />

        <DialogSection data-blurred={!!confirmation || !!confirmationLoading}>
          <ScrollableContainer
            maxHeight={400}
            variant={SCROLLABLE_CONTAINER_VARIANT.FRAMED}
          >
            <ClickThruSpaceRowComponent
              parentSpace={currentRoot}
              tableBaseProps={{
                columns: LIBRARY_COLUMNS,
                tableRowVariant: "nested-click-thru",
                filter,
                sort: sortRowByAlphabetical,
                onSelect: (row) => {
                  const space = row.entity as Space;
                  setCurrentRoot(space);
                },
                EmptyComponent: () => (
                  <EmptyTable action="Create or add content" />
                ),
                rootEntity: currentRoot,
                onRootEntityChange: setCurrentRoot,
                processRows: (rows) => {
                  return Promise.all(
                    rows.map(async (row) => {
                      const { onClick, ...rowData } = row;
                      const rowSpace = rowData.entity as Space;
                      // To avoid circular reference and move to the same space
                      // we disable the click event
                      if (rowSpace.id == entityToMove.id) {
                        return rowData;
                      }
                      if (entityTypeToMove === COLLECTION_ID.SPACE) {
                        if (rowSpace.sharePasswordEnabled) {
                          return rowData;
                        }
                        const isRecursive =
                          await isDescendantRecursiveForSpaces({
                            id: rowSpace.id,
                            ancestorId: entityToMove.id,
                          });
                        return isRecursive
                          ? rowData
                          : {
                              onClick,
                              ...rowData,
                            };
                      }
                      return isAllowed(PERMISSION.TO_ADD_TO_SPACE, {
                        space: rowSpace,
                      })
                        ? { onClick, ...rowData }
                        : rowData;
                    }),
                  );
                },
              }}
            />
          </ScrollableContainer>
        </DialogSection>

        {confirmation && <DialogSection>{confirmation}</DialogSection>}

        <DialogButtons>
          <div className="highnote-dialog-buttons-left">
            <Button
              theme={BUTTON_THEME.SECONDARY}
              onClick={() => {
                setIsNewSpaceDialogOpen(true);
              }}
            >
              New Space
            </Button>
          </div>

          <Button theme={BUTTON_THEME.SECONDARY} onClick={close}>
            Cancel
          </Button>

          {!confirmation &&
            !isEntityInParentSpace &&
            ((isInLibraryRootSpace && canMoveIntoLibraryRootSpace) ||
              !isInLibraryRootSpace) && (
              <Button
                theme={BUTTON_THEME.CTA}
                onClick={async () => {
                  await requestMoveToSpace(currentRoot);
                }}
              >
                Move
              </Button>
            )}

          {!!confirmation && (
            <Button
              theme={BUTTON_THEME.CTA}
              disabled={isMoving}
              onClick={async () => {
                setIsMoving(true);
                await moveToSpace({
                  entityId: entityToMove.id,
                  entityType: entityTypeToMove,
                  newSpaceId: selectedSpace.current?.id,
                });
                entityRedirect(selectedSpace.current?.id);

                setIsMoving(false);
                close();
              }}
            >
              Confirm Move
            </Button>
          )}
        </DialogButtons>
      </Dialog>

      <CreateSpaceWithNameDialog
        isOpen={isNewSpaceDialogOpen}
        close={() => setIsNewSpaceDialogOpen(false)}
        parentSpace={{
          id: currentRoot?.id,
          name: currentRoot?.name || "Library",
        }}
        onConfirm={(newSpace: Space) => {
          // Move user to the newly created space
          setCurrentRoot(newSpace);
        }}
      />

      {children}
    </MoveToSpaceContext.Provider>
  );
};

export const useMoveToSpace = () => useContext(MoveToSpaceContext);
