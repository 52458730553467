import React, { RefObject, useEffect, useMemo, useState } from "react";
import naturalSort from "natural-sort";
import {
  COLLECTION_ID,
  PUBLIC_ID,
  ROLE,
  Space,
  Track,
} from "@highnote/server/src/core/entities";
import {
  getFullRoles,
  hasRole,
  processWaveformData,
} from "@highnote/server/src/core/shared-util";
import { formatCount } from "App/modules/utils";
import { EntityRowConfig } from "./EntityTable";
import { Tooltip } from "App/core/Tooltip";
import { ConnectedUserIdentifier } from "App/common/UserAvatar/UserAvatar";
import { highnote } from "@highnote/server/src/sdk";

type baseEntityForSort = { entity: { name?: string; title?: string } };

export const sortRowByAlphabetical = (
  a: EntityRowConfig | baseEntityForSort,
  b: EntityRowConfig | baseEntityForSort,
) => {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const bName = (b.entity as any).name || (b.entity as any).title;
  const aName = (a.entity as any).name || (a.entity as any).title;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  return naturalSort()(aName, bName);
};

export const sortRowByCreatedAtAscending = (
  a: EntityRowConfig,
  b: EntityRowConfig,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (b.entity as any).createdAt < (a.entity as any).createdAt ? 1 : -1;
};

export const sortRowByCreatedAt = (a: EntityRowConfig, b: EntityRowConfig) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (b.entity as any).createdAt < (a.entity as any).createdAt ? -1 : 1;
};

const AffectedUsers = ({
  users,
  noun = "user",
}: {
  users: string[];
  noun?: string;
}) => {
  if (users.length < 1) {
    return <span>{formatCount(users.length, `other ${noun}`)}</span>;
  }

  const firstThreeUsers = users.slice(0, 3);
  const restOfUsers = users.slice(2);

  return (
    <Tooltip
      // TODO: this is disabled to hide users phone numbers
      isDisabled
      title={
        <p>
          {firstThreeUsers.map((userId) => (
            <span key={userId} style={{ display: "block" }}>
              <ConnectedUserIdentifier userId={userId} />
            </span>
          ))}
          {restOfUsers.length > 0 && <span>+ {restOfUsers.length} more</span>}
        </p>
      }
    >
      <strong style={{ cursor: "pointer", display: "inline-block" }}>
        {formatCount(users.length, `other ${noun}`)}
      </strong>
    </Tooltip>
  );
};

const getMoveTrackToSpaceWarning = async ({
  spaceDestination,
  track,
  userId,
}: {
  spaceDestination?: Space;
  track: Track;
  userId: string;
}) => {
  if (!track) return;

  let oldSpace: Space;
  if (track.spaceId) {
    try {
      oldSpace = (await highnote.getSpace({ id: track.spaceId })) as Space;
    } catch (e) {
      // Space not retrievable, was likely deleted
    }
  }

  if (oldSpace == spaceDestination) return;

  let where = "";
  if (oldSpace && track && spaceDestination) {
    where = `from ${oldSpace.name} to ${spaceDestination.name}`;
  } else if (oldSpace && !spaceDestination) {
    where = `out of ${oldSpace.name}`;
  } else if (!oldSpace && spaceDestination) {
    where = `into ${spaceDestination.name}`;
  }
  const title = `Are you sure you want to move ${track.title} ${where}?`;
  const description = [];

  const oldRoles = getFullRoles(oldSpace);

  const oldUsers = Object.keys(oldRoles).filter(
    (r) => r !== PUBLIC_ID && r !== userId,
  );

  const newRoles = getFullRoles(spaceDestination);

  const newUsers = Object.keys(newRoles).filter(
    (r) => r !== PUBLIC_ID && r !== userId,
  );

  // If [Old Space]
  if (oldSpace) {
    const usersAffected = oldUsers.length > 0;
    // If the track has comments in [Old Collection]
    // OR if [Old Collection] has other members:
    if (usersAffected) {
      description.push(
        <p key="old-space">
          {usersAffected && (
            <>
              The <AffectedUsers users={oldUsers} noun="member" /> in this Space
              may lose access to the Track.
            </>
          )}
        </p>,
      );
    }
  }
  // If [New Space] has users:
  if (newUsers.length > 0) {
    description.push(
      <p key="new-users-affected">
        {track.title} will also be shared with the{" "}
        <AffectedUsers users={newUsers} noun="member" /> of{" "}
        {spaceDestination.name}.
      </p>,
    );
  }
  // If [I don’t manage Item] AND [I don’t manage New Space]:
  if (
    !hasRole(userId, ROLE.MANAGE, track) &&
    !hasRole(userId, ROLE.MANAGE, spaceDestination)
  ) {
    description.push(
      <p key="own-permission-affected">
        Once you move this Track {where}, you will lose the permission required
        to move it again.
      </p>,
    );
  }
  if (!title && !description) return undefined;
  return { title, description };
};

const getMoveSpaceToSpaceWarning = async ({
  spaceDestination,
  prevSpace,
  userId,
}: {
  spaceDestination?: Space;
  prevSpace: Space;
  userId: string;
}) => {
  if (!prevSpace) return;

  let parentSpace: Space;
  if (prevSpace.spaceId) {
    try {
      parentSpace = (await highnote.getSpace({
        id: prevSpace.spaceId,
      })) as Space;
    } catch (e) {
      // Space not retrievable, was likely deleted
    }
  }

  if (prevSpace == spaceDestination) return;
  let where = "";

  // Check for moving directly into a space, applicable when there's no prevSpace or when prevSpace exists but without considering parentSpace.
  if (
    (!prevSpace && spaceDestination) ||
    (prevSpace && spaceDestination && !parentSpace)
  ) {
    where = `into ${spaceDestination.name}`;
  }
  // Check for moving out of a space without moving into a new one.
  else if (prevSpace && !spaceDestination && parentSpace) {
    where = `out of ${parentSpace.name}`;
  }
  // Check for moving from one space to another, considering parentSpace if available.
  else if (prevSpace && spaceDestination) {
    const fromSpaceName = parentSpace ? parentSpace.name : "a space";
    where = `from ${fromSpaceName} to ${spaceDestination.name}`;
  }

  const title = `Are you sure you want to move ${prevSpace.name} ${where}?`;
  const description = [];

  const newRoles = getFullRoles(spaceDestination);
  const newUsers = Object.keys(newRoles).filter(
    (r) => r !== PUBLIC_ID && r !== userId,
  );

  // If [New Space] has users:
  if (newUsers.length > 0) {
    description.push(
      <p key="new-users-affected">
        {prevSpace.name} will also be shared with the{" "}
        <AffectedUsers users={newUsers} noun="member" /> of{" "}
        {spaceDestination.name}.
      </p>,
    );
  }
  // If [I don’t manage Item] AND [I don’t manage New Space]:
  if (spaceDestination && !hasRole(userId, ROLE.MANAGE, spaceDestination)) {
    description.push(
      <p key="own-permission-affected">
        Once you move this Space {where}, you will lose the permission required
        to move it again.
      </p>,
    );
  }
  if (!title && !description) return undefined;
  return { title, description };
};

export const getMoveToSpaceWarning = async ({
  userId,
  entity,
  entityType,
  spaceDestination,
}: {
  userId: string;
  entity: Space | Track;
  entityType: COLLECTION_ID.TRACK | COLLECTION_ID.SPACE;
  spaceDestination?: Space;
}) => {
  if (!entity) return;

  if (entityType === COLLECTION_ID.SPACE) {
    return getMoveSpaceToSpaceWarning({
      spaceDestination,
      prevSpace: entity as Space,
      userId,
    });
  }

  if (entityType === COLLECTION_ID.TRACK) {
    return getMoveTrackToSpaceWarning({
      spaceDestination,
      track: entity as Track,
      userId,
    });
  }

  return null;
};

export const getBlobAudioData = async (source: Blob) => {
  return new Promise<{ waveform: number[]; duration: number }>(
    (resolve, reject) => {
      const context = new AudioContext();
      const fileReader = new FileReader();
      fileReader.onloadend = async () => {
        const arrayBuffer = fileReader.result as ArrayBuffer;
        try {
          const audioBuffer = await context.decodeAudioData(arrayBuffer);
          if (audioBuffer.duration <= 0) {
            return reject(new Error("Could not get duration."));
          }
          const waveformRaw = Array.from(audioBuffer.getChannelData(0));
          if (!waveformRaw.every((w) => !isNaN(w))) {
            return reject(new Error("Could not get waveform data."));
          }
          const waveform = processWaveformData(waveformRaw);
          return resolve({ waveform, duration: audioBuffer.duration });
        } catch (e) {
          return reject(e);
        } finally {
          context.close();
        }
      };
      fileReader.readAsArrayBuffer(source);
    },
  );
};

export const useIsInView = <T = HTMLDivElement,>(
  ref: RefObject<T>,
  keepObserving?: boolean,
) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        const isNowIntersecting = entry.isIntersecting;
        if (keepObserving) {
          setIntersecting(isNowIntersecting);
          return;
        }
        if (!isIntersecting && isNowIntersecting) {
          setIntersecting(isNowIntersecting);
        }
      }),
    [ref, keepObserving],
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current as Element);
      return () => observer.disconnect();
    }
  }, []);

  return isIntersecting;
};
