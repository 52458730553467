import { firestore } from "./firebase";
import {
  collection,
  query,
  where,
  limit,
  getCountFromServer,
} from "firebase/firestore";
import { COLLECTION_ID, COMMENT_BLOCK_TYPE } from "../core/entities";

export const getCommentsCount: (props: {
  spaceId: string;
  trackId: string;
  excludeCommentTypes?: COMMENT_BLOCK_TYPE[];
}) => Promise<number> = async ({
  spaceId,
  trackId,
  excludeCommentTypes = [],
}) => {
  const filters = [
    where("spaceId", "==", spaceId),
    where("trackId", "==", trackId),
    ...(excludeCommentTypes.length > 0
      ? [where("commentType", "not-in", excludeCommentTypes)]
      : []),
    limit(100),
  ];
  const commentQuery = query(
    collection(firestore, COLLECTION_ID.COMMENT),
    ...filters,
  );
  const snapshot = await getCountFromServer(commentQuery);
  return snapshot.data().count;
};
