import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  ReactNode,
} from "react";

interface ViewportContextType {
  vw: number;
  vh: number;
  isMobile: boolean;
  isMediumScreenSize: boolean;
  isLargeScreenSize: boolean;
  isMinLargeScreenSize: boolean;
}

const ViewportContext = createContext<ViewportContextType>({
  vw: 0,
  vh: 0,
  isMobile: false,
  isMediumScreenSize: false,
  isLargeScreenSize: false,
  isMinLargeScreenSize: false,
});

interface ViewportContextProviderProps {
  children: ReactNode;
}

export const ViewportContextProvider = ({
  children,
}: ViewportContextProviderProps) => {
  const [vh, setVh] = useState<number>(0);
  const [vw, setVw] = useState<number>(0);

  useEffect(() => {
    let unmounted = false;

    const onResize = () => {
      if (unmounted) return;
      const newVh = window.innerHeight;
      const newVw = window.innerWidth;

      if (newVh !== vh) setVh(newVh);
      if (newVw !== vw) setVw(newVw);
    };

    onResize();
    window.addEventListener("resize", onResize);

    return () => {
      unmounted = true;
      window.removeEventListener("resize", onResize);
    };
  }, [vh, vw]);

  const value = useMemo<ViewportContextType>(
    () => ({
      vw,
      vh,
      // Screen size values below are based on _variables.scss screen width breakpoints
      isMobile: vw < 450,
      isMediumScreenSize: vw >= 450 && vw < 768,
      isLargeScreenSize: vw >= 768 && vw < 1024,
      isMinLargeScreenSize: vw >= 1024,
    }),
    [vw, vh],
  );

  return (
    <ViewportContext.Provider value={value}>
      {children}
    </ViewportContext.Provider>
  );
};

export const useViewport = () => {
  const context = useContext(ViewportContext);

  if (!context) {
    throw new Error(
      "useViewport must be used within a ViewportContextProvider",
    );
  }

  return context;
};
