import React, {
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { debounce } from "lodash";
import { UserEntity } from "@highnote/server/src/core/entities";
import { highnote } from "@highnote/server/src/sdk";

interface PublicUsersContextType {
  fetchUser: (id: string) => void;
  users: Record<string, UserEntity>;
}

const PublicUsersContext = createContext<PublicUsersContextType>({
  fetchUser: () => {},
  users: {},
});

export const PublicUsersContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [users, setUsers] = useState<Record<string, UserEntity>>({});
  const userIdsQueue = useRef<Set<string>>(new Set());

  const fetchUser = useMemo(
    () =>
      debounce(async () => {
        const userIds = Array.from(userIdsQueue.current);
        userIdsQueue.current.clear();
        const userIdsGroups = userIds.reduce(
          (acc, id) => {
            const lastGroup = acc[acc.length - 1];
            if (lastGroup.length < 50) {
              lastGroup.push(id);
            } else {
              acc.push([id]);
            }
            return acc;
          },
          [[]],
        );
        if (userIdsGroups.length > 0) {
          await Promise.all(
            userIdsGroups.map(async (group) => {
              try {
                const users = await highnote.getUsersPublic({
                  ids: group,
                });
                setUsers((prevUsers) => ({ ...prevUsers, ...users }));
                // eslint-disable-next-line no-empty
              } catch (e) {}
            }),
          );
        }
      }, 500),
    [],
  );

  const values = useMemo(() => {
    return {
      fetchUser: (id: string) => {
        if (!users[id] && !userIdsQueue.current.has(id)) {
          userIdsQueue.current.add(id);
          return fetchUser();
        }
      },
      users,
    };
  }, [fetchUser, users]);

  return (
    <PublicUsersContext.Provider value={values}>
      {children}
    </PublicUsersContext.Provider>
  );
};

export const usePublicUser = () => useContext(PublicUsersContext);
