import "./Autocomplete.scss";
import React from "react";
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps,
} from "@mui/material";
import { Popper } from "App/common/Menu";
import { useTheme } from "App/common/ThemeProvider";
import { ReactComponent as CloseSVG } from "App/common/icons/close.svg";

export const Autocomplete = <
  T,
  Multiple extends boolean = undefined,
  DisableClearable extends boolean = undefined,
  FreeSolo extends boolean = undefined,
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
) => {
  const { theme } = useTheme();

  return (
    <MuiAutocomplete
      {...props}
      clearIcon={null}
      forcePopupIcon={false}
      className={`highnote-autocomplete ${props.className || ""}`}
      classes={{ popper: "highnote-autocomplete-popper" }}
      PopperComponent={Popper}
      data-theme={theme}
      autoHighlight={true}
      open={props.loading || props.options?.length > 0}
      loadingText="Loading..."
    />
  );
};

export const AutoCompleteTag = (props: {
  key: number;
  className: string;
  disabled: boolean;
  "data-tag-index": number;
  tabIndex: -1;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDelete: (event: any) => void;
  children: React.ReactNode;
}) => {
  const { onDelete, children, className, ...divProps } = props;

  return (
    <div
      {...divProps}
      className={`highnote-autocomplete-tag ${className || ""}`}
    >
      {children}
      <div className="close" onClick={onDelete}>
        <CloseSVG />
      </div>
    </div>
  );
};
