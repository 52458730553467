import { useEffect, useMemo } from "react";
import { PublicUser, Space } from "@highnote/server/src/core/entities";
import { usePublicSpace } from "App/modules/usePublicSpace";
import { getIsInvitedUserLogin } from ".";

export type PublicSpaceData = {
  space: Space;
  spaceArtworkUrl?: string;
  spaceOwner?: PublicUser;
};

const getInviteSpaceParams = () => {
  const query = new URLSearchParams(location.search);
  const returnTo = query.get("returnTo");
  const returnToUrl = new URL(returnTo, location.origin);
  const returnToPathname = returnToUrl.pathname;
  const spaceId = returnToPathname.split("/")[2];
  const returnToRecipient = returnToUrl.searchParams.get("recipient");

  return {
    spaceId,
    returnToRecipient,
  };
};

export const useInviteSpaceData = () => {
  const { getPublicSpaceData, isPublicSpaceLoading, publicSpaceData } =
    usePublicSpace();
  const { spaceId, returnToRecipient } = getInviteSpaceParams();

  const isInvitedUserLogin = useMemo(() => getIsInvitedUserLogin(), []);

  useEffect(() => {
    if (spaceId && returnToRecipient && isInvitedUserLogin) {
      const controller = new AbortController();

      const fetchData = async () => {
        try {
          await getPublicSpaceData(spaceId, returnToRecipient, {
            // Send AbortSignal to fetch request to cancel it when component unmounts
            signal: controller.signal,
          });
        } catch (error) {
          console.error("Failed to fetch space data", error);
        }
      };

      fetchData();

      return () => {
        controller.abort();
      };
    }
  }, [spaceId, returnToRecipient, isInvitedUserLogin]);

  return {
    inviteSpaceData: publicSpaceData,
    isInviteSpaceLoading: isPublicSpaceLoading,
  };
};
