import "./Settings.scss";
import React, { useEffect, useState } from "react";
import { useAuth } from "App/components/Auth";
import { highnote } from "@highnote/server/src/sdk";
import { ReactComponent as BellSVG } from "App/common/icons/bell.svg";
import { ConnectedUserIdentifier } from "App/common/UserAvatar/UserAvatar";
import { AuthFormDialogButton, AUTH_TYPE } from "App/components/Auth/AuthForm";
import { ReactComponent as PlusSVG } from "App/common/icons/plus.svg";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { useConfirmation } from "App/common/useConfirmation";
import { AUTH_PREFIX, UserEntity } from "@highnote/server/src/core/entities";
import { getUserContactMethods } from "@highnote/server/src/core/shared-util";
import { useMobileAppParams } from "../Main/useMobileAppParams";

const LinkedAccount = ({ userId }: { userId: string }) => {
  const { confirm, renderConfirmation } = useConfirmation();
  const { user } = useAuth();
  const [cachedUser, setCachedUser] = useState<Partial<UserEntity>>();

  const contactMethods = getUserContactMethods(user);
  const isDefault = user?.id === userId;
  const isReceivingNotifications =
    contactMethods.email === cachedUser?.email ||
    contactMethods.phone === cachedUser?.phone;
  const prefix = userId.split("|")[0];

  useEffect(() => {
    highnote
      .getUserPublic({ id: userId })
      .then((user) => {
        setCachedUser(user);
      })
      .catch(() => {});
  }, []);

  let method: string;
  if (prefix === AUTH_PREFIX.EMAIL) method = "email";
  if (prefix === AUTH_PREFIX.SMS) method = "phone number";
  if (prefix === AUTH_PREFIX.GOOGLE) method = "Google account";

  return (
    <>
      {renderConfirmation}
      <div className="LinkedAccount">
        <p>
          <ConnectedUserIdentifier userId={userId} />
          {isReceivingNotifications && <BellSVG />}
          {isDefault && <span className="default">(Default)</span>}
        </p>
        {!isDefault && (
          <Button
            theme={BUTTON_THEME.SECONDARY}
            size={BUTTON_SIZE.SMALL}
            onClick={() => {
              confirm({
                title: "Remove this sign-in method?",
                body: (
                  <p>
                    You will no longer be able to access this account with the{" "}
                    {method} <ConnectedUserIdentifier userId={userId} inline />.
                  </p>
                ),
              }).then(() => {
                highnote.unlinkUser({ userId });
              });
            }}
          >
            Remove
          </Button>
        )}
      </div>
    </>
  );
};

export const LinkedAccounts = () => {
  const { user } = useAuth();
  const { isAuthorizedMobileComponent } = useMobileAppParams();

  return (
    <section className="linked-accounts">
      <div className="section-header">Sign In Methods</div>
      <p>
        {isAuthorizedMobileComponent
          ? "Manage Sign In Methods on the Web."
          : "You can have up to 2 additional sign in methods linked to your account."}
      </p>
      <LinkedAccount key={user.id} userId={user.id} />

      {!isAuthorizedMobileComponent && (
        <>
          {(user.linkedAccountsV2 || []).map((acct) => {
            return <LinkedAccount key={acct.id} userId={acct.id} />;
          })}
          <AuthFormDialogButton
            disabled={(user.linkedAccountsV2 || []).length >= 2}
            className="link-account"
            theme={BUTTON_THEME.CTA}
            returnTo={location.pathname}
            authType={AUTH_TYPE.LINK}
          >
            <PlusSVG />
            <span>Add Sign In Method</span>
          </AuthFormDialogButton>
        </>
      )}
    </section>
  );
};
