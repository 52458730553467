import React, { useRef, useState } from "react";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { AudioFileSelect } from "App/common/FileSelect";
import { useSpaceContext } from "App/common/useSpace";
import { useAuth } from "App/components/Auth";
import {
  getDefaultVersionId,
  MAX_TRACKS_ERROR,
  PERMISSION,
} from "@highnote/server/src/core/shared-util";
import { Menu, MenuItem } from "App/common/Menu";
import { RecordDialog } from "App/components/RecordDialog";
import { highnote } from "@highnote/server/src/sdk";
import {
  AudioUploadFileSizeErr,
  UPLOAD_GROUP,
  useFiles,
} from "App/components/useFiles";
import { useTrack } from "../../useEntities/useTrack";
import { Button, ButtonProps } from "App/core/Button";
import { MenuProps } from "@mui/material";
import {
  ArrayUnion,
  FileEntity,
  Track,
} from "@highnote/server/src/core/entities";
import { ReactComponent as UploadSVG } from "App/common/icons/upload.svg";
import { ReactComponent as VoiceSVG } from "App/common/icons/voice.svg";
import { PermissionTooltip } from "App/components/PermissionTooltip";
import { JOIN_ENTITY_TRIGGER } from "App/components/Auth/util";
import { useToast } from "App/common/useToast";
import {
  LIMIT_TYPE,
  usePlanLimitsContext,
} from "App/common/PlanLimits/usePlanLimits";

export const AddVersionButton = ({
  pinOnSave,
  multiple,
  menuProps,
  additionalMenuOptions,
  className,
  ...rest
}: {
  pinOnSave?: boolean;
  multiple?: boolean;
  menuProps?: Partial<MenuProps>;
  additionalMenuOptions?: React.ReactNode[];
} & ButtonProps) => {
  const anchorRef = useRef<HTMLButtonElement>();
  const { isAllowed, isPublicView, joinEntity } = useAuth();
  const { track } = useTrack();
  const { addErrorMessage } = useToast();
  const { uploadFiles, getUploadCache } = useFiles();
  const uploadCache = getUploadCache(
    pinOnSave
      ? UPLOAD_GROUP.TRACK_VERSIONS_PINNED
      : UPLOAD_GROUP.TRACK_VERSIONS_UNPINNED,
    track?.id,
  );
  const { space } = useSpaceContext();
  const { showPlanLimitsDialog } = usePlanLimitsContext();
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const [isRecorderOpen, setRecorderOpen] = useState<boolean>(false);

  const canAddToTrack = isAllowed(PERMISSION.TO_ADD_TO_TRACK_IN_SPACE, {
    space,
    track,
  });

  const addVersionToTrack = async (file: FileEntity) => {
    const defaultVersionId = getDefaultVersionId(track);
    const updateData: Partial<Track> = {
      versionFilesV2: new ArrayUnion([file.id]) as unknown as Id[],
    };

    if (pinOnSave) {
      updateData.pinnedVersionFiles = new ArrayUnion([
        defaultVersionId,
        file.id,
      ]) as unknown as Id[];
    }

    try {
      await highnote.updateTrack({
        id: track.id,
        data: updateData,
      });

      joinEntity({
        entity: space,
        entityType: "Space",
        trigger: JOIN_ENTITY_TRIGGER.UPLOAD,
      });
    } catch (e) {
      if (e.message === MAX_TRACKS_ERROR) {
        showPlanLimitsDialog(LIMIT_TYPE.TRACKS);
        return;
      }
      throw e;
    }
  };

  const handleFileSelect = async (files: File[]) => {
    setMenuOpen(false);

    if (!files) {
      return;
    }

    try {
      const uploadPayloads = files.map((file) => {
        return {
          file,
          cache: uploadCache,
        };
      });
      await uploadFiles({
        payloads: uploadPayloads,
        onSuccess: async (_, fileEntity) => {
          await addVersionToTrack(fileEntity);
        },
        onError: (e) => {
          if (e instanceof AudioUploadFileSizeErr) {
            addErrorMessage(e.message, { title: e.title });
            return;
          }
          throw e;
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  if (
    isPublicView(space) &&
    !isAllowed(PERMISSION.TO_ADD_TO_TRACK_IN_SPACE, { space, track })
  ) {
    return null;
  }

  return (
    <ErrorBoundary name="AddVersionMenu">
      <PermissionTooltip hasPermission={canAddToTrack}>
        <Button
          {...rest}
          disabled={!canAddToTrack}
          className={`AddVersionMenu-button ${className || ""}`}
          onClick={() => setMenuOpen(true)}
          ref={anchorRef}
        />
      </PermissionTooltip>

      <Menu
        className={`add-version-menu ${
          menuProps?.className ? `${menuProps?.className}-menu` : ""
        }`}
        anchorEl={anchorRef.current}
        onClose={() => setMenuOpen(false)}
        open={isMenuOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        {...(menuProps || {})}
      >
        {...additionalMenuOptions || []}

        <MenuItem>
          <AudioFileSelect
            // disabled={!!disabled}
            onSelect={handleFileSelect}
            allowMultiple={multiple}
          >
            <div>
              <UploadSVG /> Upload new version
            </div>
          </AudioFileSelect>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setMenuOpen(false);
            setRecorderOpen(true);
          }}
        >
          <VoiceSVG />
          Record new version
        </MenuItem>
      </Menu>

      {isRecorderOpen && (
        <RecordDialog
          cache={uploadCache}
          onClose={() => setRecorderOpen(false)}
          onSave={addVersionToTrack}
        />
      )}
    </ErrorBoundary>
  );
};
