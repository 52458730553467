import React, { useMemo, useRef, useState } from "react";
import "./EntitySelection.scss";
import { BUTTON_SIZE, BUTTON_THEME, Button } from "App/core/Button";
import { useEntitiesSelection } from "../useEntitiesSelection";
// import { useMoveToSpace } from "../useEntities/useLibraryTracks/useMoveToSpace";
import { Menu } from "App/common/Menu";
import { MenuItem } from "@mui/material";
import { ReactComponent as MoveSVG } from "App/common/icons/collection-move.svg";
import { ReactComponent as CaretDownSVG } from "App/common/icons-v2/carat-down.svg";

export const EntitySelection = () => {
  const {
    toggleSelectionToolbar,
    resetSelection,
    isSelectionVisible,
    selectedEntities,
  } = useEntitiesSelection();

  // const { openMoveSelectionToSpace } = useMoveToSpace();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const triggerRef = useRef();

  const entities = useMemo(
    () =>
      Object.entries(selectedEntities).map(([entityId, entityType]) => ({
        entityId,
        entityType,
      })),
    [selectedEntities],
  );

  return (
    <div className="highnote-entity-selection">
      {/** TODO: Add menu for move and delete selected files */}
      {isSelectionVisible && (
        <React.Fragment>
          <Button
            size={BUTTON_SIZE.SMALL}
            theme={BUTTON_THEME.CTA}
            data-is-active={isMenuOpen}
            ref={triggerRef}
            onClick={() => setIsMenuOpen(true)}
          >
            {entities.length} items <CaretDownSVG />
          </Button>
          <Menu
            className="highnote-entity-selection-menu"
            anchorEl={triggerRef.current}
            onClose={() => setIsMenuOpen(false)}
            open={isMenuOpen}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              className="highnote-entity-selection-menu-item"
              onClick={() => {
                // openMoveSelectionToSpace({
                //   entities,
                //   onComplete: resetSelection,
                // });
                setIsMenuOpen(false);
              }}
            >
              <MoveSVG />
              <span>Move to...</span>
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}

      <Button
        size={BUTTON_SIZE.MEDIUM}
        theme={BUTTON_THEME.SECONDARY}
        onClick={() =>
          isSelectionVisible ? resetSelection() : toggleSelectionToolbar()
        }
      >
        {isSelectionVisible ? "Cancel" : "Select"}
      </Button>
    </div>
  );
};
