import React from "react";
import classNames from "classnames";
import { Track } from "@highnote/server/src/core/entities";
import {
  formatDuration,
  isFilePlayable,
} from "@highnote/server/src/core/shared-util";
import { EntityRowConfig, ENTITY_TYPE, Version } from "./config";
import { useGlobalAudioPlayer } from "../GlobalAudioPlayer";

interface TrackMetadataProps {
  entity: Track | Version;
  isTrackVersion?: boolean;
  className?: string;
}

const TrackMetadata = ({
  entity,
  isTrackVersion = false,
  className,
}: TrackMetadataProps) => {
  const { getQueueItem, currentQueueOrder } = useGlobalAudioPlayer();

  // Use the appropriate queue item based on whether it's a Track or TrackVersion
  const queueItem = isTrackVersion
    ? getQueueItem((entity as Version).track.id, (entity as Version).id)
    : getQueueItem((entity as Track).id);

  const isLoading = !isFilePlayable(queueItem?.file);

  // For TrackMetadata, handle the case where there's no queue item and the queue is empty
  if (
    !isTrackVersion &&
    !queueItem?.file &&
    currentQueueOrder.queue.length === 0
  ) {
    return null;
  }

  return (
    <div
      className={classNames("highnote-entity-metadata", className)}
      data-is-loading={isLoading}
    >
      {isLoading
        ? "--:--"
        : formatDuration(queueItem.file.metadata.duration, true)}
    </div>
  );
};

export const EntityMetadata = ({
  row,
  className,
}: {
  row: EntityRowConfig;
  className?: string;
}) => {
  if (row.type === ENTITY_TYPE.TRACK) {
    const track = row.entity as Track;
    return <TrackMetadata entity={track} className={className} />;
  }

  if (row.type === ENTITY_TYPE.TRACK_VERSION) {
    const trackVersion = row.entity as Version;
    return <TrackMetadata entity={trackVersion} isTrackVersion />;
  }

  return null;
};
