// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xFvkqfzOqTlz31jlTf0q{max-width:220px}.sN2cCSoRZcMMINkLqiXH svg{margin:0 !important}", "",{"version":3,"sources":["webpack://./src/App/components/Sidebar/GlobalSidebar/PinToggle/PinToggle.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAIA,0BACE,mBAAA","sourcesContent":[".pin-toggle-tooltip {\n  max-width: 220px;\n}\n\n.pin-toggle {\n  svg {\n    margin: 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pin-toggle-tooltip": "xFvkqfzOqTlz31jlTf0q",
	"pin-toggle": "sN2cCSoRZcMMINkLqiXH"
};
export default ___CSS_LOADER_EXPORT___;
