import React from "react";
import { ReactComponent as HeartSticker } from "App/common/icons/heart-sticker.svg";

export const CustomBlock = (props: {
  isEditing: boolean;
  data: CustomBlock;
  onChange: (block: CustomBlock) => void;
  onSave?: () => void;
}) => {
  const { data } = props;

  if (data.id === "heart-sticker") {
    return (
      <div className="custom-block" data-id={data.id}>
        <HeartSticker />
      </div>
    );
  }
};
