import { Button, BUTTON_THEME } from "App/core/Button";
import React, { useMemo, useRef, useState } from "react";
import { DialogButtons, Dialog, DialogSection } from "./Dialog";

type CTAConfig = {
  label: string;
  theme: BUTTON_THEME;
};

type ConfirmationPayload = {
  title?: string;
  body?: string | React.ReactNode;
  wrapperClassName?: string;
  cancelCTAConfig?: CTAConfig;
  continueCTAConfig?: CTAConfig;
};

export type Confirmator = (payload: ConfirmationPayload) => Promise<unknown>;

const defaultCancelCTAConfig = {
  label: "Cancel",
  theme: BUTTON_THEME.SECONDARY,
};

const defaultContinueCTAConfig = {
  label: "Continue",
  theme: BUTTON_THEME.WARN,
};

export const useConfirmation = () => {
  const [dialogConfig, setDialogConfig] = useState<ConfirmationPayload>({
    cancelCTAConfig: defaultCancelCTAConfig,
    continueCTAConfig: defaultContinueCTAConfig,
  });

  const [isOpen, setOpen] = useState<boolean>(false);
  const onConfirmRef =
    useRef<(evt: React.MouseEvent<HTMLButtonElement>) => void>();
  const onCancelRef =
    useRef<(evt: React.MouseEvent<HTMLButtonElement>) => void>();

  const confirm = ({
    title: _title,
    body: _body,
    wrapperClassName,
    cancelCTAConfig = defaultCancelCTAConfig,
    continueCTAConfig = defaultContinueCTAConfig,
  }: ConfirmationPayload) => {
    return new Promise((resolve, reject) => {
      setOpen(true);

      const reset = () => {
        setOpen(false);
      };

      onConfirmRef.current = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        reset();
        resolve(undefined);
      };

      onCancelRef.current = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        reset();
        reject();
      };

      setDialogConfig({
        title: _title,
        body: _body,
        wrapperClassName,
        cancelCTAConfig,
        continueCTAConfig,
      });
    });
  };

  const renderConfirmation = useMemo(
    () => (
      <Dialog
        title={dialogConfig.title}
        className={`highnote-dialog${dialogConfig.wrapperClassName ? ` ${dialogConfig.wrapperClassName}` : ""}`}
        open={isOpen}
        onClose={onCancelRef.current}
      >
        {dialogConfig.body && (
          <DialogSection>
            {typeof dialogConfig.body === "string" ? (
              <p>{dialogConfig.body}</p>
            ) : (
              dialogConfig.body
            )}
          </DialogSection>
        )}
        <DialogButtons>
          <Button
            theme={dialogConfig.cancelCTAConfig.theme}
            onClick={onCancelRef.current}
          >
            {dialogConfig.cancelCTAConfig.label}
          </Button>
          <Button
            theme={dialogConfig.continueCTAConfig.theme}
            onClick={onConfirmRef.current}
          >
            {dialogConfig.continueCTAConfig.label}
          </Button>
        </DialogButtons>
      </Dialog>
    ),
    [dialogConfig, isOpen],
  );

  return {
    renderConfirmation,
    confirm,
  };
};
