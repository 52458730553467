import React, { useState } from "react";
import { Callout, CALLOUT_LAYOUT } from "App/core/Callout";
import { ReactComponent as WarningSVG } from "App/common/icons/warning-hollow.svg";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { useAuth } from "App/components/Auth";

import { highnote } from "@highnote/server/src/sdk";

export const TOSCallout = () => {
  const { user } = useAuth();
  const [optimisticAccept, setOptimisticAccept] = useState<boolean>(false);
  const acceptedTOS = !!user.hasAcceptedTOS;

  if (acceptedTOS || optimisticAccept) return null;

  return (
    <Callout
      className="TOS-callout"
      layout={CALLOUT_LAYOUT.ROW}
      icon={<WarningSVG />}
      title="Updates to our user agreements"
      body={
        <span>
          {"We've updated our "}
          <a
            target="_blank"
            href="https://www.highnote.fm/terms"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>
          {" and "}
          <a
            target="_blank"
            href="https://www.highnote.fm/privacy-policy"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          .
        </span>
      }
      actionButton={
        <Button
          theme={BUTTON_THEME.CTA}
          size={BUTTON_SIZE.MEDIUM}
          type="button"
          onClick={async () => {
            setOptimisticAccept(true);
            await highnote.updateAuth0User({
              app_metadata: {
                acceptedTOS: true,
              },
            });
            // refetchUser();
          }}
        >
          I accept
        </Button>
      }
    />
  );
};
