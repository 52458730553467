import { isEqual } from "lodash";
import { v4 as uuidv4 } from "uuid";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Comment, CommentCore } from "@highnote/server/src/core/entities";
import { StatefulComment, useSpaceCommentsContext } from "./useSpaceComments";
import { useSpaceContext } from "./useSpace";
import { useConfirmation } from "./useConfirmation";
import { CommentContent } from "./Comment/CommentContent";
import { getAuthId, useAuth } from "App/components/Auth";
import { useSystemEditing } from "./useSystemEditing";
import { COMMENT_BLOCKS } from "./Comment/blocks";
import { useTrack } from "App/components/useEntities/useTrack";
import { JOIN_ENTITY_TRIGGER } from "App/components/Auth/util";

type CommentContextValue = {
  comment: StatefulComment;
  replies: StatefulComment[];
  isEditing: boolean;
  isPublished: boolean;
  updateComment: (data: Partial<CommentCore>) => void;
  saveComment: (data?: Partial<Comment>) => Promise<void>;
  deleteComment: () => void;
  resetComment: () => void;
  setEditing: (val: boolean) => void;
};

const CommentContext = createContext<CommentContextValue>({
  comment: undefined,
  replies: [],
  isEditing: false,
  isPublished: false,
  updateComment: () => {},
  deleteComment: () => {},
  resetComment: () => {},
  saveComment: () => Promise.resolve(),
  setEditing: () => {},
});

export const getValidCommentBlock = (comment: Comment) => {
  const allBlocks = comment?.blocks || [];
  const blockData = allBlocks.find((b) => !!COMMENT_BLOCKS[b?.type]);
  return blockData;
};

export const CommentContextProvider = ({
  id,
  initialState,
  children,
}: {
  id: Id;
  initialState?: Partial<Comment>;
  children: React.ReactNode;
}) => {
  const {
    getCommentById,
    addSpaceComment,
    updateSpaceComment,
    deleteSpaceComment,
  } = useSpaceCommentsContext();
  const { track, currentTrackVersion } = useTrack();
  const { space, spaceId } = useSpaceContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user: currentUser, joinEntity } = useAuth();
  const { setSystemEditing } = useSystemEditing();
  const [resetTrigger, setResetTrigger] = useState<string>();
  const [commentState, setCommentState] = useState<Comment>();
  const originalCommentRef = useRef<Comment>();
  const commentStateRef = useRef<Comment>(commentState);
  const [isPublished, setPublished] = useState<boolean>(false);
  const [isEditing, setEditing] = useState<boolean>(false);
  const { confirm, renderConfirmation } = useConfirmation();

  useEffect(() => {
    if (!id) {
      setPublished(false);
      setEditing(false);
      commentStateRef.current = undefined;
      originalCommentRef.current = undefined;
      setCommentState(undefined);
      return;
    }

    const existingComment = getCommentById(id);

    if (existingComment) {
      setPublished(true);
      commentStateRef.current = existingComment;
      originalCommentRef.current = existingComment;
      setCommentState(commentStateRef.current);
      return;
    }

    setPublished(false);
    setEditing(true);
    originalCommentRef.current = null;
    commentStateRef.current = {
      id,
      createdBy: getAuthId(),
      createdAt: Date.now(),
      spaceId,
      trackId: track?.id,
      trackVersionIds: currentTrackVersion ? [currentTrackVersion.id] : [],
      blocks: [],
      ...(initialState || {}),
    };
    setCommentState(commentStateRef.current);
  }, [id, initialState, getCommentById, resetTrigger]);

  const updateComment = (data: Partial<CommentCore>) => {
    commentStateRef.current = {
      ...commentStateRef.current,
      ...data,
    };
    setCommentState(commentStateRef.current);
    setSystemEditing(
      `comment-${id}`,
      isEditing &&
        !isEqual(commentStateRef.current, originalCommentRef.current),
    );
  };

  const saveComment = async (data?: Partial<Comment>) => {
    setEditing(false);

    joinEntity({
      entity: space,
      entityType: "Space",
      trigger: JOIN_ENTITY_TRIGGER.COMMENT,
    });

    try {
      if (isPublished) {
        await updateSpaceComment({
          id,
          data: { ...commentStateRef.current, ...(data || {}) },
        });
      } else {
        await addSpaceComment({
          id,
          data: { ...commentStateRef.current, ...(data || {}) },
        });
      }

      setSystemEditing(`comment-${id}`, false);
    } catch (e) {
      // setEditing(lastEditingState);
    }
  };

  const deleteComment = async () => {
    try {
      const numReplies = (commentState.replies || []).length;
      await confirm({
        title: "Delete comment?",
        wrapperClassName: "comment-delete-confirmation",
        body: (
          <p>
            <div className="preview-box">
              <CommentContent />
            </div>
            {numReplies > 0 && (
              <>
                This comment has {numReplies}{" "}
                {numReplies === 1 ? "reply" : "replies"} which would no longer
                be visible.
                <br />
                <br />
              </>
            )}
            <strong>Are you sure you want to delete this comment?</strong> This
            cannot be undone.
          </p>
        ),
      });

      await deleteSpaceComment(id);
      setSystemEditing(`comment-${id}`, false);
    } catch (e) {
      console.log("Could not confirm & delete comment", e);
    }
  };

  const resetComment = () => {
    setResetTrigger(uuidv4());
    setSystemEditing(`comment-${id}`, false);
  };

  const replies = useMemo(() => {
    return (commentState?.replies || [])
      .map((replyId) => getCommentById(replyId))
      .filter((r) => !!r)
      .sort((a, b) => a.createdAt - b.createdAt);
  }, [commentState?.replies, getCommentById]);

  const value = useMemo(
    () => ({
      comment: commentState,
      replies,
      isEditing,
      isPublished,
      updateComment,
      saveComment,
      deleteComment,
      resetComment,
      setEditing,
    }),
    [
      commentState,
      replies,
      isEditing,
      isPublished,
      updateComment,
      saveComment,
      deleteComment,
      resetComment,
      setEditing,
    ],
  );

  return (
    <CommentContext.Provider value={value}>
      {children}
      {renderConfirmation}
    </CommentContext.Provider>
  );
};

export const useCommentContext = () => useContext(CommentContext);
