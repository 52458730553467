import "./Plans.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, BUTTON_THEME } from "App/core/Button";
import { highnote } from "@highnote/server/src/sdk";
import {
  STRIPE_INTERVAL_FORMATTED,
  SUBSCRIPTION_TIER,
  UserSubscriptions,
} from "@highnote/server/src/core/entities";
import Stripe from "stripe";
import { StatusIndicator } from "App/common/StatusIndicator";
import moment from "moment";
import { ReactComponent as CheckSVG } from "App/common/icons/check-filled.svg";
import { useConfirmation } from "App/common/useConfirmation";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import {
  formatStripeDate,
  getSubscriptionDirection,
  parseScheduledSubscription,
  parseSubscription,
  SUBSCRIPTION_DIRECTION,
} from "@highnote/server/src/core/shared-util";
import { PlanConfig } from "./config";
import { useAuth } from "../Auth";

const getPlanState = (
  subscriptions: UserSubscriptions,
  tier: SUBSCRIPTION_TIER,
  interval: Stripe.Price.Recurring.Interval,
  priceId: string,
) => {
  const active = parseSubscription(subscriptions?.active);
  const scheduled = parseScheduledSubscription(subscriptions?.scheduled);

  const isMatch = (sub: {
    tier: SUBSCRIPTION_TIER;
    interval: Stripe.Price.Recurring.Interval;
  }) =>
    tier === sub.tier &&
    (tier === SUBSCRIPTION_TIER.FREE || interval === sub.interval);

  const isActiveCancelling = Boolean(subscriptions?.active?.cancel_at);
  const activeSubscription = isActiveCancelling ? scheduled : active;

  const isActive = isMatch(activeSubscription);
  const isScheduled = isActiveCancelling && isMatch(scheduled);
  const formattedInterval = STRIPE_INTERVAL_FORMATTED[interval];
  const direction = getSubscriptionDirection(
    activeSubscription.priceId,
    priceId,
  );

  const base = {
    headerText: "",
    hideButton: false,
    buttonText:
      direction === SUBSCRIPTION_DIRECTION.DOWNGRADE ? "Downgrade" : "Upgrade",
    subscriptionDirection: direction,
    shouldHighlight: false,
    shouldHighlightButton:
      direction === SUBSCRIPTION_DIRECTION.UPGRADE ||
      direction === SUBSCRIPTION_DIRECTION.SWITCH,
  };

  if (isActive || isScheduled) {
    return {
      ...base,
      headerText: isScheduled
        ? `Starting ${
            formatStripeDate(subscriptions.active.cancel_at)?.split(", ")[0]
          }`
        : "Current Plan",
      shouldHighlight: true,
      hideButton: true,
    };
  }
  if (
    direction === SUBSCRIPTION_DIRECTION.SWITCH &&
    activeSubscription.interval !== interval
  ) {
    return {
      ...base,
      buttonText: `Switch to ${formattedInterval}`,
      shouldHighlightButton: true,
    };
  }

  return base;
};

export const PlanCard = ({
  plan,
  interval,
}: {
  plan: PlanConfig;
  interval: Stripe.Price.Recurring.Interval;
}) => {
  const { confirm, renderConfirmation } = useConfirmation();
  const { user } = useAuth();

  const cardRef = useRef<HTMLSelectElement>();
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [prices, setPrices] = useState<Stripe.Price[]>([]);

  const price = prices?.find((p) => p.recurring.interval === interval);
  const priceId = price?.id || null;
  const { integer, decimal } = useMemo(() => {
    const priceUnitAmount = price?.unit_amount || 0;
    if (priceUnitAmount === 0)
      return {
        integer: "0",
      };
    const fixed = ((priceUnitAmount || 0) / 100).toFixed(2).split(".");
    return {
      integer: fixed[0],
      decimal: fixed[1],
    };
  }, [price]);

  const {
    buttonText,
    headerText,
    hideButton,
    shouldHighlightButton,
    shouldHighlight,
  } = getPlanState(user.subscriptions, plan.tier, interval, priceId);

  const onClick = async () => {
    try {
      setUpdating(true);
      const { subscriptionDirection, checkoutUrl } =
        await highnote.getCheckoutUrl({
          priceId,
          source: window.location.pathname,
        });
      if (
        subscriptionDirection === SUBSCRIPTION_DIRECTION.UPGRADE &&
        checkoutUrl
      ) {
        window.location.href = checkoutUrl;
        return;
      }

      if (subscriptionDirection !== SUBSCRIPTION_DIRECTION.SWITCH) {
        const endDate = moment(
          user.subscriptions.active?.current_period_end * 1000,
        ).format("MMMM Do, YYYY");
        await confirm({
          title: `Are you sure you want to downgrade?`,
          body: (
            <p>
              Your new Highnote {plan.name} Plan will activate on {endDate} and
              your default payment method will be charged ${integer} per{" "}
              {interval}. Before continuing, be sure to review the limitations
              of your new plan.
            </p>
          ),
        });
      }

      setUpdating(true);
      await highnote.updateUserSubscription({
        priceId,
      });
    } catch (error) {
      alert("Could not downgrade plan. Please try again or contact support.");
    } finally {
      setUpdating(false);
    }
  };

  useEffect(() => {
    if (!plan.prices) {
      setPrices([]);
      setLoading(false);
      return;
    }

    Promise.all(
      plan.prices.map((priceId) => highnote.getPrice({ priceId })),
    ).then((prices) => {
      setPrices(prices);
      setLoading(false);
    });
  }, [plan]);

  useEffect(() => {
    if (!loading && shouldHighlight && cardRef.current?.parentElement) {
      const { width: childWidth, x } = cardRef.current.getBoundingClientRect();
      const { width: parentWidth } =
        cardRef.current.parentElement.getBoundingClientRect();
      cardRef.current.parentElement.scrollTo(
        x - (parentWidth - childWidth) / 2,
        0,
      );
    }
  }, [shouldHighlight, loading, cardRef, plan]);

  if (loading) {
    return (
      <section className="highnote-plans-card">
        <div className="highnote-plans-card-wrapper">
          <div className="highnote-plans-card-content">
            <div className="loading-header" />
            <div className="loading-body" />
            <div className="loading-body" />
          </div>
        </div>
      </section>
    );
  }

  return (
    <ErrorBoundary name="HighnotePlansCard">
      <section
        data-status={shouldHighlight ? "active" : "inactive"}
        className="highnote-plans-card"
        ref={cardRef}
      >
        {Boolean(headerText) && <h3>{headerText}</h3>}
        <div className="highnote-plans-card-wrapper">
          <div className="highnote-plans-card-content">
            <h4>
              {plan.name}
              <StatusIndicator isLoading={!prices} />
            </h4>

            <div className="price-container">
              <div className="price-value">
                ${integer}
                {decimal && <span>.{decimal}</span>}
              </div>
              <div className="price-details">per {interval}</div>
            </div>

            <div className="additional-items">
              {plan.features.map((item, i) => (
                <div className="additional-item" key={`additional-item-${i}`}>
                  <CheckSVG />
                  {item}
                </div>
              ))}
            </div>
          </div>
          {!hideButton && !loading && (
            <Button
              data-cypress-id={"plans-cta-button"}
              theme={
                shouldHighlightButton
                  ? BUTTON_THEME.PRIMARY
                  : BUTTON_THEME.SECONDARY
              }
              loading={updating}
              className="cta-button"
              onClick={onClick}
            >
              {buttonText}
            </Button>
          )}
        </div>
      </section>
      {renderConfirmation}
    </ErrorBoundary>
  );
};
