import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { QueryConstraint } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

import { COLLECTION_ID } from "@highnote/server/src/core/entities";
import { highnote } from "@highnote/server/src/sdk";
import { ENTITY_NOT_FOUND, ENTITY_NOT_LOADING } from "App/common/util/ERRORS";

type WatcherAction =
  | { type: "ATTACH_LISTENER"; key: string; watcher: () => void }
  | { type: "DETACH_LISTENER"; key: string }
  | { type: "SET_LOADING"; key: string; loading: boolean }
  | { type: "SET_ERROR"; key: string; error: string };

export type WatcherState = Map<
  string,
  { watcher?: () => void; loading?: boolean; error?: string }
>;

// Reducer function for managing watchers
function watcherReducer(
  state: WatcherState,
  action: WatcherAction,
): WatcherState {
  switch (action.type) {
    case "ATTACH_LISTENER": {
      const existingWatcher = state.get(action.key);
      let error = "";
      let loading = false;
      if (existingWatcher) {
        error = existingWatcher.error;
        loading = existingWatcher.loading;
      }
      state.set(action.key, { error, loading, watcher: action.watcher });
      return new Map(state);
    }
    case "DETACH_LISTENER": {
      state.get(action.key)?.watcher?.();
      state.delete(action.key);
      return new Map(state);
    }
    case "SET_LOADING": {
      const existingWatcher = state.get(action.key);
      if (existingWatcher) {
        state.set(action.key, {
          ...existingWatcher,
          loading: action.loading,
        });
      } else {
        state.set(action.key, {
          loading: action.loading,
          watcher: undefined,
          error: "",
        });
      }
      return new Map(state);
    }
    case "SET_ERROR": {
      const existingWatcher = state.get(action.key);
      if (existingWatcher) {
        state.set(action.key, {
          ...existingWatcher,
          error: action.error,
        });
      } else {
        state.set(action.key, {
          error: action.error,
          watcher: undefined,
          loading: false,
        });
      }
      return new Map(state);
    }
    default:
      return state;
  }
}

export type WatcherActionType = "attach" | "detach";

export type ManageEntityWatchers<EntityType> = ({
  collectionId,
  entityId,
  action,
  addEntity,
  removeEntity,
}: {
  collectionId: COLLECTION_ID;
  entityId: string;
  action: WatcherActionType;
  addEntity: (entity: EntityType) => void;
  removeEntity: (entityId: string) => void;
}) => void;

export type ManageEntitiesWatchers<EntityType> = ({
  watcherKey,
  collectionId,
  action,
  constraints,
  setEntities,
  limit,
}: {
  watcherKey: string;
  collectionId: COLLECTION_ID;
  action: WatcherActionType;
  constraints?: QueryConstraint[];
  limit?: number;
  setEntities: (entities: EntityType[]) => void;
}) => Promise<void>;

// Used to manage listeners for entities
export const useWatcherManagement = <EntityType,>() => {
  const [totalLimit, setTotalLimit] = useState<number>(0);
  const [requestedLimit, setRequestedLimit] = useState<number>(0);
  const [retryTrigger, setRetryTrigger] = useState<string>();
  const retryCountRef = useRef<number>(0);
  // TODO: Still needed? Is this being used correctly?
  const unmountedRef = useRef<boolean>(false);

  const [activeWatchers, setActiveWatchers] = useReducer(
    watcherReducer,
    new Map(),
  );

  const resetWatcher = useCallback((watcherKey: string) => {
    setActiveWatchers({
      type: "SET_ERROR",
      key: watcherKey,
      error: "",
    });
    setActiveWatchers({
      type: "SET_LOADING",
      key: watcherKey,
      loading: false,
    });
  }, []);

  const manageEntityWatchers = useCallback(
    ({
      collectionId,
      entityId,
      action,
      addEntity,
      removeEntity,
    }: {
      collectionId: COLLECTION_ID;
      entityId: string;
      action: WatcherActionType;
      addEntity: (entity: EntityType) => void;
      removeEntity: (entityId: string) => void;
    }) => {
      let timeout: number;
      if (action === "attach") {
        if (!entityId) {
          resetWatcher(entityId);
          return;
        }

        if (!activeWatchers.has(entityId)) {
          setActiveWatchers({
            type: "SET_LOADING",
            key: entityId,
            loading: true,
          });

          const unsubscribe = highnote.watchEntity({
            entityId,
            collectionId,
            onChange: (_entity: EntityType) => {
              if (unmountedRef.current) return;
              addEntity(_entity);
              resetWatcher(entityId);
            },
            onError: (error) => {
              if (unmountedRef.current) return;
              console.error("Error watching entity:", error);
              if (retryCountRef.current > 2) {
                removeEntity(entityId);
                setActiveWatchers({
                  type: "SET_ERROR",
                  key: entityId,
                  error: `Error loading entity: ${error.message || ENTITY_NOT_LOADING}`,
                });
                setActiveWatchers({
                  type: "SET_LOADING",
                  key: entityId,
                  loading: false,
                });
              } else {
                // Schedule a retry after 5 seconds
                timeout = window.setTimeout(() => {
                  if (unmountedRef.current) return;
                  retryCountRef.current += 1;
                  setRetryTrigger(uuidv4());
                }, 5000);
              }
            },
          });

          setActiveWatchers({
            type: "ATTACH_LISTENER",
            key: entityId,
            watcher: unsubscribe,
          });
        }
      } else if (action === "detach" && activeWatchers.has(entityId)) {
        const unsubscribe = activeWatchers.get(entityId).watcher;
        if (unsubscribe) {
          unsubscribe();
          setActiveWatchers({ type: "DETACH_LISTENER", key: entityId });
        }
      }

      return () => {
        activeWatchers.forEach((unsubscribe) => unsubscribe.watcher?.());
        clearTimeout(timeout);
      };
    },
    [retryTrigger, activeWatchers],
  );

  // Function to manage listeners based on user interaction
  const manageEnititiesWatchers = useCallback(
    async ({
      watcherKey,
      collectionId,
      action,
      constraints,
      setEntities,
      limit,
    }: {
      watcherKey: string;
      collectionId: COLLECTION_ID;
      action: WatcherActionType;
      constraints?: QueryConstraint[];
      setEntities: (entities: EntityType[]) => void;
      limit?: number;
    }) => {
      if (limit) {
        setRequestedLimit(limit);
      }

      if (action === "attach" && !activeWatchers.has(watcherKey)) {
        if (!constraints || constraints.length < 1) {
          resetWatcher(watcherKey);
          return;
        }

        setActiveWatchers({
          type: "SET_LOADING",
          key: watcherKey,
          loading: true,
        });

        const unsubscribe = highnote.watchEntities({
          collectionId,
          // TODO(GLOBAL SPACE): The limit can be added back in when needed.
          // constraints: limit
          //   ? [...constraints, firestoreLimit(limit)]
          //   : constraints,
          constraints,
          onChange: (_entities: EntityType[]) => {
            if (unmountedRef.current) return;
            setEntities(_entities);
            resetWatcher(watcherKey);
          },
          onError: () => {
            // setEntities([]); // TODO: Not needed?
            setActiveWatchers({
              type: "SET_LOADING",
              key: watcherKey,
              loading: false,
            });
            setActiveWatchers({
              type: "SET_ERROR",
              key: watcherKey,
              error: ENTITY_NOT_FOUND,
            });

            // Retry after 5 seconds.
            setTimeout(() => {
              setRetryTrigger(uuidv4());
            }, 5000);
          },
        });
        setActiveWatchers({
          type: "ATTACH_LISTENER",
          key: watcherKey, // This needs to be a unique key
          watcher: unsubscribe,
        });
      } else if (action === "detach" && activeWatchers.has(watcherKey)) {
        const unsubscribe = activeWatchers.get(watcherKey).watcher;
        if (unsubscribe) {
          unsubscribe();
          setActiveWatchers({
            type: "DETACH_LISTENER",
            key: watcherKey,
          });
        }
      }
    },
    [totalLimit, retryTrigger],
  );

  useEffect(() => {
    if (requestedLimit) {
      // Ability to set the totalLimit via the manageEnititiesWatchers args
      // We use requestedLimit to set the initial totalLimit.
      setTotalLimit((prev) => prev + requestedLimit);
    }
  }, [requestedLimit]);

  return {
    activeWatchers,
    manageEntityWatchers,
    manageEnititiesWatchers,
    // TODO: (GLOBAL SPACE): This is not used now, but may be used in the future.
    // Reconsider current implementation, and maybe adding into each watcher object.
    loadMore: () => setTotalLimit((prev) => prev + requestedLimit),
  };
};
