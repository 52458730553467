import "./EntityTable.scss";
import React, { useMemo } from "react";
import {
  BaseTable,
  ColumnConfig,
  TableRowVariant,
} from "../../common/BaseTable";
import { EntityRowConfig, DEFAULT_COLUMNS } from "./config";

export { EntityRowConfig, DEFAULT_COLUMNS };

export const EntityTable = ({
  rows,
  columns,
  mobileColumns,
  sort,
  onDragEnd,
  EmptyComponent,
  showDragHandle,
  isLoading,
  className,
  tableRowVariant,
}: {
  rows: EntityRowConfig[];
  columns: ColumnConfig[];
  mobileColumns?: ColumnConfig[];
  sort?: (a: EntityRowConfig, b: EntityRowConfig) => number;
  onDragEnd?: (newOrder: EntityRowConfig[]) => void;
  EmptyComponent?: React.FC;
  showDragHandle?: boolean;
  isLoading?: boolean;
  className?: string;
  tableRowVariant?: TableRowVariant; // TODO: remove when support added for dialog dropdowns
}) => {
  const sortedRows = useMemo(() => {
    return sort ? [...rows].sort(sort) : rows;
  }, [rows, sort]);

  return (
    <BaseTable
      EmptyComponent={EmptyComponent}
      columns={columns}
      mobileColumns={mobileColumns}
      rows={sortedRows}
      className={`entity-table ${className || ""}`}
      onDragEnd={onDragEnd}
      showDragHandle={showDragHandle}
      isLoading={isLoading}
      tableRowVariant={tableRowVariant}
    />
  );
};
