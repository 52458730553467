import React, { useEffect, useState } from "react";

import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { Callout, CALLOUT_LAYOUT } from "App/core/Callout";
import { Dialog, DialogButtons, DialogSection } from "App/common/Dialog";
import { TextInput } from "App/common/TextInput";
import { useAuth } from "App/components/Auth";
import { useToast } from "App/common/useToast";
import { highnote } from "@highnote/server/src/sdk";

export const UserDeletionRequest = () => {
  const { user } = useAuth();
  const { toasted } = useToast();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [email, setEmail] = useState(user.email || "");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isDialogOpen && user?.email) {
      setEmail(user.email);
    }
  }, [user?.email, isDialogOpen]);

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await toasted({
        promise: highnote.requestUserDeletion({ email }),
        successMessage: "Account deletion request submitted",
        ErrorContent: ({ error }: { error: Error }) => <>{error.message}</>,
      });
      // eslint-disable-next-line no-empty
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const { requestedAccountDeletionAt } = user || {};
  const requestedAccountDeletionAtFormatted =
    requestedAccountDeletionAt &&
    new Date(requestedAccountDeletionAt).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

  return (
    <>
      {!requestedAccountDeletionAt && isDialogOpen && (
        <Dialog
          className="deletion-submit-dialog"
          open
          title="Delete Account"
          onClose={() => {
            setIsDialogOpen(false);
          }}
        >
          <DialogSection>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <p>
                Clicking “Delete Account” will immediately notify our team to
                delete your account within 30 days. Please provide an email so
                we can notify you upon completion.
              </p>
              <TextInput
                placeholder="Email"
                type="email"
                isSingleLine
                value={email}
                onChange={setEmail}
                onSubmit={onSubmit}
                required
              />
            </form>
          </DialogSection>
          <DialogButtons>
            <Button
              disabled={isLoading}
              theme={BUTTON_THEME.SECONDARY}
              onClick={async () => {
                setIsDialogOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="deletion-submit-btn"
              loading={isLoading}
              disabled={!email}
              theme={BUTTON_THEME.WARN}
              onClick={onSubmit}
            >
              Delete Account?
            </Button>
          </DialogButtons>
        </Dialog>
      )}
      {requestedAccountDeletionAt ? (
        <section className="deletion-request">
          <div className="section-header">
            <Callout
              icon={null}
              title="Account Deletion"
              layout={CALLOUT_LAYOUT.ROW}
              body={`A request to delete this account was submitted on ${requestedAccountDeletionAtFormatted}. This account is being processed for deletion. Please contact help@highnote.fm for support.`}
            />
          </div>
        </section>
      ) : (
        <section className="deletion-request">
          <div className="section-header">Delete Account</div>
          <p>
            Notify our team immediately to permanently delete your Highnote
            account, including all content and data. To downgrade an active
            subscription instead, manage billing on the Web, on iOS Subscription
            settings, or on Google Play, depending on where you purchased your
            subscription.
          </p>
          <Button
            className="submit-deletion-request"
            theme={BUTTON_THEME.WARN}
            size={BUTTON_SIZE.MEDIUM}
            onClick={() => {
              setIsDialogOpen(true);
            }}
          >
            Delete Account
          </Button>
        </section>
      )}
    </>
  );
};
