// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MrEaLsaQ1bXcbpytM0sw{order:5}.MrEaLsaQ1bXcbpytM0sw.hprYldc4HXohPZFsHsCz{background-color:#000 !important}", "",{"version":3,"sources":["webpack://./src/App/common/AuthButtons.module.scss"],"names":[],"mappings":"AAEA,sBACE,OAAA,CAEA,2CACE,gCAAA","sourcesContent":["@import \"App/styles/_variables.scss\";\n\n.join-space-button {\n  order: 5;\n\n  &.public-view {\n    background-color: black !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"join-space-button": "MrEaLsaQ1bXcbpytM0sw",
	"public-view": "hprYldc4HXohPZFsHsCz"
};
export default ___CSS_LOADER_EXPORT___;
