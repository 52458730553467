import "./ToggleQualityDialog.scss";
import { daw } from "@highnote/daw/src";
import { AUDIO_QUALITY, Space } from "@highnote/server/src/core/entities";
import { ReactComponent as CheckSVG } from "App/common/icons/check.svg";
import { Dialog, DialogButtons, DialogSection } from "App/common/Dialog";
import { Button, BUTTON_THEME } from "App/core/Button";
import React, { useEffect, useRef, useState } from "react";
import { useGlobalAudioPlayer } from ".";
import { useAudioQualityStats } from "./ToggleAudioQuality";
import { useRouteMatch } from "react-router";
import { routePaths } from "App/modules/urls";
import { useSpaceContext } from "App/common/useSpace";
import { useGlobalSpaces } from "App/store/spaces/useGlobalSpaces";
import { highnote } from "@highnote/server/src/sdk";

const LS_DISABLE_PROMPT_ID = "hignote-disable-audio-loading-prompt";

export const ToggleQualityDialog = React.memo(
  ({ useHTMLAudioPlayer }: { useHTMLAudioPlayer: boolean }) => {
    const { space } = useSpaceContext();
    const matchTrackPage = useRouteMatch(routePaths.spaceTrack);
    const audioQualityStats = useAudioQualityStats();
    const { isActive, quality, setAudioQuality, nowPlaying, getQueueItem } =
      useGlobalAudioPlayer();
    const [ignore, setIgnore] = useState(
      !!localStorage.getItem(LS_DISABLE_PROMPT_ID),
    );
    const [open, setOpen] = useState<boolean>(false);
    const timeoutRef = useRef<number>();

    const { globalSpaces, hasGlobalSpace } = useGlobalSpaces();
    const [currentSpace, setCurrentSpace] = useState<Space | undefined>();

    useEffect(() => {
      let isMounted = true;

      if (isMounted && nowPlaying?.track?.spaceId) {
        if (space) {
          setCurrentSpace(space);
        } else if (hasGlobalSpace(nowPlaying.track.spaceId)) {
          setCurrentSpace(globalSpaces.spaces.get(nowPlaying.track.spaceId));
        } else {
          highnote
            .getSpace({ id: nowPlaying.track.spaceId, fromCache: true })
            .then((_space) => {
              setCurrentSpace(_space);
            })
            .catch((error) => {
              console.error("Error getting space", error);
            });
        }
      }

      return () => {
        isMounted = false;
      };
    }, [nowPlaying?.track?.spaceId, space]);

    useEffect(() => {
      if (ignore) return;
      if (quality === AUDIO_QUALITY.LOW) return;
      if (!nowPlaying?.id) return;

      // If the user never clicked "play," the global player is hidden
      // you don't need to bother with this. The only exception is on the
      // Space Track page, where it's always shown regardless of interaction.
      if (!isActive && !matchTrackPage) return;

      clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(() => {
        if (useHTMLAudioPlayer) {
          const audioQueueItem = getQueueItem(nowPlaying.id);
          if (!audioQueueItem?.file) {
            setOpen(true);
          }
          return;
        }
        const dawTrack = daw.getTrack(nowPlaying.id);
        if (!dawTrack?.audioBuffer) {
          setOpen(true);
        }
      }, 5000);
    }, [isActive, quality, nowPlaying?.id, ignore, !!matchTrackPage]);

    const saveAndClose = () => {
      ignore
        ? localStorage.setItem(LS_DISABLE_PROMPT_ID, "true")
        : localStorage.removeItem(LS_DISABLE_PROMPT_ID);
      setOpen(false);
    };

    if (!nowPlaying?.track) return null;
    if (quality === AUDIO_QUALITY.LOW) return null;
    const currentQuality = currentSpace?.audioQualityLocked
      ? AUDIO_QUALITY.ORIGINAL
      : quality;

    return (
      <Dialog
        className="ToggleQualityDialog"
        open={open}
        title={
          <span style={{ textTransform: "capitalize" }}>
            Loading {currentQuality} Quality
          </span>
        }
        onClose={saveAndClose}
      >
        <DialogSection>
          {currentSpace?.audioQualityLocked ? (
            <p>
              A manager of this space has locked {nowPlaying.track.title} at
              original quality ({audioQualityStats.detail}), which is taking
              longer than expected to load.
            </p>
          ) : (
            <p>
              It&apos;s taking longer than expected to load{" "}
              {nowPlaying.track.title} (3MB) at {quality} quality (
              {audioQualityStats.detail}). Try streaming standard quality for
              now.
            </p>
          )}
        </DialogSection>

        <DialogButtons>
          <div
            role="button"
            className="set-ignore"
            onClick={() => {
              setIgnore(!ignore);
            }}
          >
            <div className="fake-checkbox" data-checked={ignore}>
              {ignore && <CheckSVG />}
            </div>
            Don&apos;t remind me again
          </div>

          {!currentSpace?.audioQualityLocked ? (
            <>
              <Button
                theme={BUTTON_THEME.SECONDARY}
                onClick={() => {
                  saveAndClose();
                }}
              >
                Keep Waiting
              </Button>
              <Button
                theme={BUTTON_THEME.CTA}
                onClick={() => {
                  setAudioQuality(AUDIO_QUALITY.LOW);
                  saveAndClose();
                }}
              >
                Use Standard Quality
              </Button>
            </>
          ) : (
            <>
              <Button
                theme={BUTTON_THEME.CTA}
                onClick={() => {
                  saveAndClose();
                }}
              >
                Okay
              </Button>
            </>
          )}
        </DialogButtons>
      </Dialog>
    );
  },
);

ToggleQualityDialog.displayName = "ToggleQualityDialog (Memoized)";
