import React, { createContext, useCallback, useContext, useMemo } from "react";
import { where } from "firebase/firestore";

import {
  ARCHIVABLE_ENTITY_TYPES,
  DownloadRequest,
} from "@highnote/server/src/core/entities";
import {
  DownloadRequestsProvider,
  useDownloadRequests,
} from "App/components/useEntities";
import { getAuthId } from "App/components/Auth";

type UserDownloadRequestsContextValue = {
  downloadRequests: DownloadRequest[];
  downloadRequestsLoading: boolean;
  getDownloadRequest: (
    entityId: string,
    entityType:
      | ARCHIVABLE_ENTITY_TYPES.SPACE
      | ARCHIVABLE_ENTITY_TYPES.TRACK
      | ARCHIVABLE_ENTITY_TYPES.TRACK_FILES,
  ) => DownloadRequest | undefined;
};

const UserDownloadRequestsContext =
  createContext<UserDownloadRequestsContextValue>({
    downloadRequests: [],
    downloadRequestsLoading: true,
    getDownloadRequest: () => undefined,
  });

const _DownloadRequestsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { entities: downloadRequests, loading: downloadRequestsLoading } =
    useDownloadRequests();

  const getDownloadRequest = useCallback(
    (
      entityId: string,
      entityType:
        | ARCHIVABLE_ENTITY_TYPES.SPACE
        | ARCHIVABLE_ENTITY_TYPES.TRACK
        | ARCHIVABLE_ENTITY_TYPES.TRACK_FILES,
    ) => {
      return downloadRequests.find((dr) => {
        return dr.entityId === entityId && dr.entityType === entityType;
      });
    },
    [downloadRequests],
  );

  const value = useMemo(() => {
    return {
      downloadRequests,
      downloadRequestsLoading,
      getDownloadRequest,
    };
  }, [downloadRequests, downloadRequestsLoading, getDownloadRequest]);

  return (
    <UserDownloadRequestsContext.Provider value={value}>
      {children}
    </UserDownloadRequestsContext.Provider>
  );
};

export const DownloadRequestsContextProvider = ({
  isProcessing,
  children,
}: {
  isProcessing?: boolean;
  children: React.ReactNode;
}) => {
  const authId = getAuthId();
  const constraints = useMemo(() => {
    return [
      where("subscribers", "array-contains", authId),
      typeof isProcessing === "boolean" &&
        where("isProcessing", "==", isProcessing),
    ].filter(Boolean);
  }, [authId, isProcessing]);

  return (
    <DownloadRequestsProvider constraints={constraints}>
      <_DownloadRequestsContextProvider>
        {children}
      </_DownloadRequestsContextProvider>
    </DownloadRequestsProvider>
  );
};

export const useUserDownloadRequests = () =>
  useContext(UserDownloadRequestsContext);
