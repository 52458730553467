import { signInWithCustomToken } from "firebase/auth";

import { highnote } from "@highnote/server/src/sdk";
import {
  FROM_MOBILE_APP_FLAG,
  MOBILE_TOKEN_FLAG,
  SHOULD_REDIRECT_TO_MOBILE_FLAG,
  withMobileDeeplinkRoot,
} from "../urls";

// this is to handle mobile app related authentication prior to rendering the app
// to reduce the already complex authentication logic inside the app.
export const checkMobileRedirectAndAuth = async () => {
  const searchParams = new URLSearchParams(window.location.search);
  const fromMobileApp = searchParams.get(FROM_MOBILE_APP_FLAG) === "true";
  const mobileToken = searchParams.get(MOBILE_TOKEN_FLAG);

  // this is when we need to make sure that a user coming from the mobile app
  // has a valid authentication token
  if (fromMobileApp && mobileToken) {
    try {
      await signInWithCustomToken(highnote.__firebaseAuth, mobileToken);
      // authentication succeeded, so delete the token,
      searchParams.delete(MOBILE_TOKEN_FLAG);
      // update the URL in place to prevent it from being leaked,
      window.history.replaceState(
        {},
        document.title,
        `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`,
      );
    } catch (err) {
      console.error("Error during authentication from mobile: ", err);
      window.location.href = withMobileDeeplinkRoot(
        searchParams.get("returnToOnError") ||
          searchParams.get("returnTo") ||
          window.location.pathname,
      );
      throw err;
    }
    return;
  }
  if (fromMobileApp) {
    searchParams.set(SHOULD_REDIRECT_TO_MOBILE_FLAG, "true");
    window.history.replaceState(
      {},
      document.title,
      `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`,
    );
  }
};
