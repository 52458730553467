import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Measure, { ContentRect } from "react-measure";
import { DraggableCore, DraggableEventHandler } from "react-draggable";
import "./styles.scss";
import { getTimeFromPosition } from "../util/getTimeFromPosition";
import {
  formatDuration,
  isFilePlayable,
  processWaveformData,
} from "@highnote/server/src/core/shared-util";
import { AudioPlayToggleButton } from "./AudioPlayToggle";
import { useTheme } from "../ThemeProvider";
import { ControlledAudioElement } from "@highnote/daw/src/ControlledAudioElement";
import { AUDIO_QUALITY, FileEntity } from "@highnote/server/src/core/entities";
import { highnote } from "@highnote/server/src/sdk";
import { isEqual } from "lodash";
import { AudioProcessingIcon } from "App/components/AudioProcessingIcon";

const BAR_WIDTH = 1;
const BAR_MARGIN = 1;

const WaveformBars = ({
  waveformData,
  width,
  height,
  duration,
  currentTime,
}: {
  waveformData: number[];
  width: number;
  height: number;
  duration: number;
  currentTime: number;
}) => {
  const hasData = waveformData && waveformData.length > 0;

  const averages = React.useMemo(() => {
    if (!width) return [];
    const data = waveformData;
    const numBars = Math.floor(width / (BAR_WIDTH + BAR_MARGIN));
    return processWaveformData(data, numBars);
  }, [width, waveformData]);

  if (!averages.length) return null;
  const secPerBar = duration / averages.length;

  return (
    <>
      {averages.map((a, i) => {
        let percentPlayed;
        const barStart = secPerBar * i;
        const diff = currentTime - barStart;
        if (barStart > currentTime) percentPlayed = 0;
        else if (diff > secPerBar) percentPlayed = 1;
        else percentPlayed = diff / secPerBar;
        const isFull = percentPlayed >= 1;

        return (
          <div
            className="bar"
            data-cypress-id="audio-player-bar"
            key={i}
            data-is-full={isFull}
            style={{
              width: `${BAR_WIDTH}px`,
              borderRadius: `${BAR_WIDTH / 2}px`,
              marginRight: `${BAR_MARGIN}px`,
              height: `${hasData ? a * height : 2}px`,
            }}
          >
            {!isFull && (
              <div
                className="bar-inner"
                style={{ width: `${percentPlayed * 100}%` }}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

type SeekableComponentProps = {
  currentTime: number;
  duration: number;
  bounds: ContentRect["client"];
};

type SeekableProps = {
  onSeek?: (time: number) => void;
  currentTime?: number;
  duration?: number;
  Component: React.FC<SeekableComponentProps>;
};

const Seekable = (props: SeekableProps) => {
  const [bounds, setBounds] = useState<ContentRect["bounds"]>();
  const unmountedRef = useRef<boolean>(false);
  const [dragTime, _setDragTime] = useState<number>(undefined);
  const dragRef = useRef<HTMLDivElement>();
  const dragTimeRef = useRef<number>(dragTime);
  const resizeTimeoutRef = useRef<number>();

  // We need to use a ref here because some code needs the latest `dragTime` immediately, rather
  // than waiting on React to update next cycle.
  const setDragTime = (dragTime: number) => {
    dragTimeRef.current = dragTime;
    _setDragTime(dragTime);
  };

  useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  const onDrag: DraggableEventHandler = useCallback(
    (e, data) => {
      const time = getTimeFromPosition({
        position: data.x,
        width: bounds?.width,
        duration: props.duration,
      });

      setDragTime(time);
    },
    [bounds?.width, bounds?.left, props.duration],
  );

  const onDragStop: DraggableEventHandler = useCallback(
    (e, data) => {
      onDrag(e, data);
      props.onSeek && props.onSeek(dragTimeRef.current);

      // Wait until currentTime has been updated to reset drag time. An arbitrary time is OK here
      // because it's more important that we reset AFTER currentTime as been updated than to be
      // exact.
      setTimeout(() => setDragTime(undefined), 50);
    },
    [props.onSeek, onDrag],
  );

  const onResize = useCallback((contentRect) => {
    clearTimeout(resizeTimeoutRef.current);
    resizeTimeoutRef.current = window.setTimeout(() => {
      if (unmountedRef.current) return;
      setBounds(contentRect.entry || contentRect.bounds);
    }, 50);
  }, []);

  return (
    <DraggableCore
      offsetParent={dragRef.current}
      onDrag={onDrag}
      onStop={onDragStop}
    >
      <div className="highnote-seekable" ref={dragRef}>
        <Measure bounds onResize={onResize}>
          {({ measureRef }) => {
            return (
              <div className="highnote-seekable-component" ref={measureRef}>
                <props.Component
                  duration={props.duration}
                  currentTime={dragTime ?? props.currentTime}
                  bounds={bounds}
                />
              </div>
            );
          }}
        </Measure>
      </div>
    </DraggableCore>
  );
};

export const SeekableWaveform = (
  props: Omit<SeekableProps, "Component"> & { waveformData?: number[] },
) => {
  const { waveformData, ...seekableProps } = props;

  const WaveformFC = useMemo(() => {
    const WaveformComponent = (componentProps: SeekableComponentProps) => {
      const { theme } = useTheme();
      const { bounds, duration, currentTime } = componentProps;

      return (
        <div className="highnote-waveform" data-theme={theme}>
          <WaveformBars
            waveformData={waveformData}
            width={bounds?.width}
            height={bounds?.height}
            duration={duration}
            currentTime={currentTime}
          />
        </div>
      );
    };
    WaveformComponent.displayName = "WaveformComponent";
    return WaveformComponent;
  }, [waveformData]);

  return <Seekable {...seekableProps} Component={WaveformFC} />;
};

export const SeekableLineform = (
  seekableProps: Omit<SeekableProps, "Component">,
) => {
  const LineformFC = useMemo(() => {
    const LineformComponent = (componentProps: SeekableComponentProps) => {
      const { theme } = useTheme();
      const { duration, currentTime } = componentProps;
      const currentPercentStr = () =>
        duration ? `${(currentTime / duration) * 100}%` : "0%";

      return (
        <div className="highnote-lineform" data-theme={theme}>
          <div className="inner">
            <div className="duration" style={{ width: "100%" }} />
            <div
              className="current-time"
              style={{ width: currentPercentStr() }}
            />
            <div className="scrubber" style={{ left: currentPercentStr() }} />
          </div>
        </div>
      );
    };
    LineformComponent.displayName = "LineformComponent";
    return LineformComponent;
  }, []);

  return <Seekable {...seekableProps} Component={LineformFC} />;
};

enum VARIANT {
  DEFAULT = "default",
  COMPACT = "compact",
}

export type AudioPlayerProps = {
  file: Partial<FileEntity>;
  variant?: VARIANT;
};

export const getSourceUrl = async (
  file: Partial<FileEntity>,
  quality?: AUDIO_QUALITY,
) => {
  if (!file) return;
  if (file.url) return file.url;
  if (!file.id) return;
  const url = await highnote.getFileUrl({
    id: file.id,
    asProcessedAudio: true,
    quality,
  });
  return url;
};

type PlayerState = { currentTime: number; isPlaying: boolean; error?: string };

export const AudioPlayer = ({
  file,
  variant = VARIANT.DEFAULT,
}: AudioPlayerProps) => {
  const [playerState, setPlayerState] = useState<PlayerState>({
    currentTime: 0,
    isPlaying: false,
  });
  const playerStateRef = useRef<PlayerState>(playerState);
  const audioElementRef = useRef<ControlledAudioElement>();
  const audioElement = audioElementRef.current;
  const duration = file?.metadata?.duration || 0;
  const isPlayable = isFilePlayable(file as FileEntity);

  useEffect(() => {
    let unmounted: boolean;

    audioElementRef.current?.pause();

    if (!isFilePlayable(file as FileEntity)) return;

    getSourceUrl(file, AUDIO_QUALITY.LOW).then((_url) => {
      if (unmounted) return;
      if (!_url) return;
      audioElementRef.current = new ControlledAudioElement(_url);
    });

    return () => {
      unmounted = true;
      audioElementRef.current?.destroy();
    };
  }, [file]);

  useEffect(() => {
    let unmounted: boolean;
    const interval = setInterval(() => {
      const audioElement = audioElementRef.current;
      const newState = audioElement
        ? {
            currentTime: audioElement.currentTime,
            isPlaying: !audioElement.raw.paused,
            error: audioElement.error?.message,
          }
        : {
            currentTime: 0,
            isPlaying: false,
          };

      if (!isEqual(newState, playerStateRef.current)) {
        if (unmounted) return;
        playerStateRef.current = newState;
        setPlayerState(newState);
      }
    }, 50);

    return () => {
      unmounted = true;
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      className="highnote-audio-player"
      data-variant={variant}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="waveform">
        {isPlayable ? (
          <SeekableWaveform
            onSeek={(time) => {
              if (!audioElement) return;
              audioElement.raw.currentTime = time;
            }}
            currentTime={playerState.currentTime}
            duration={file.metadata.duration}
            waveformData={file.metadata.waveform}
          />
        ) : (
          <SeekableWaveform currentTime={0} duration={0} />
        )}
      </div>
      <div className="controls">
        {isPlayable && (
          <AudioPlayToggleButton
            disabled={!audioElement}
            isPlaying={playerState.isPlaying}
            onTogglePlay={() => {
              if (!audioElement) return;
              if (playerState.isPlaying) audioElement.pause();
              else audioElement.play();
            }}
          />
        )}

        {file && !isPlayable && (
          <AudioProcessingIcon file={file as FileEntity} dawTrackId={file.id} />
        )}
      </div>
      <div className="time" data-is-seeked={playerState.currentTime > 0}>
        <span className="current-time">
          {formatDuration(playerState.currentTime)}
        </span>
        <span className="divider">/</span>
        <span className="duration">{formatDuration(duration, true)}</span>
      </div>
    </div>
  );
};

AudioPlayer.VARIANT = VARIANT;
