import { COLLECTION_ID } from "@highnote/server/src/core/entities";
import React, { useEffect } from "react";
import { createContext, useContext, useState } from "react";

export type SelectionType = {
  entityId: string;
  entityType: COLLECTION_ID;
  parentEntity?: {
    entityId: string;
    entityType: COLLECTION_ID;
  };
};

type EntitiesSelectionContext = {
  selectedEntities: Record<string, SelectionType>;
  isSelectionVisible: boolean;
  addToSelection: (entity: SelectionType) => void;
  bulkAddToSelection: (entities: SelectionType[]) => void;
  removeFromSelection: (entityId: string) => void;
  toggleSelectionToolbar: (value?: boolean) => void;
  resetSelection: () => void;
};

export const EntitiesSelectionContext = createContext<EntitiesSelectionContext>(
  {
    selectedEntities: {},
    isSelectionVisible: false,
    addToSelection: () => {},
    bulkAddToSelection: () => {},
    removeFromSelection: () => {},
    toggleSelectionToolbar: () => {},
    resetSelection: () => {},
  },
);

export const EntitiesSelectionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [selectedEntities, setSelectedEntities] = useState<
    Record<SelectionType["entityId"], SelectionType>
  >({});
  const [isSelectionVisible, setIsSelectionVisible] = useState(false);

  const addToSelection = (selection: SelectionType) => {
    setSelectedEntities((prev) => {
      if (selection.entityId in prev) {
        return prev;
      }
      return {
        ...prev,
        [selection.entityId]: selection,
      };
    });
  };

  const bulkAddToSelection = (selections: SelectionType[]) => {
    setSelectedEntities((prev) => {
      const newSelections = selections.filter((selection) => {
        return !(selection.entityId in prev);
      });
      if (newSelections.length < 1) {
        return prev;
      }
      return {
        ...prev,
        ...newSelections.reduce<Record<string, SelectionType>>(
          (acc, selection) => {
            acc[selection.entityId] = selection;
            return acc;
          },
          {},
        ),
      };
    });
  };

  // parseParentsToDeselect returns a list of parent entities to deselect when
  // a child entity is deselected. This is to ensure that the parent entity is
  // NOT selected unless all its children entities are selected. This allows
  // us to move/delete a subset of children entities.
  const parseParentsToDeselect = (
    selections: Record<SelectionType["entityId"], SelectionType>,
    entityId: string,
  ) => {
    const parentsToDeselect = new Set<string>();
    let cursor = selections[entityId]?.parentEntity?.entityId;
    while (cursor) {
      parentsToDeselect.add(cursor);
      cursor = selections[cursor]?.parentEntity?.entityId;
    }
    return parentsToDeselect;
  };

  // parseChildrenToDeselect returns a list of children entities to deselect when
  // a parent entity is deselected. The reason for this is that a parent entity
  // won't be selected unless all its children entities are selected, which
  // means if a parent entity is deselected, all its children should be
  // deselected (including all nested children).
  const parseChildrenToDeselect = (
    selections: Record<SelectionType["entityId"], SelectionType>,
    parentEntityId: string,
  ) => {
    const result = new Set<string>();
    const queue: string[] = [parentEntityId];

    while (queue.length > 0) {
      const currentParentId = queue.shift()!;
      const children = Object.values(selections).filter(
        (selection) => selection.parentEntity?.entityId === currentParentId,
      );
      for (const child of children) {
        result.add(child.entityId);
        queue.push(child.entityId);
      }
    }

    return result;
  };

  const removeFromSelection = (entityId: string) => {
    setSelectedEntities((prev) => {
      const selected = prev[entityId];
      if (!selected) {
        return prev;
      }
      const parentsToDeselect = parseParentsToDeselect(prev, entityId);
      const childrenToDeselect = parseChildrenToDeselect(prev, entityId);
      return Object.keys(prev).reduce<Record<string, SelectionType>>(
        (acc, key) => {
          // deselect the selection itself
          if (key === entityId) {
            return acc;
          }
          // if a parent entity needs to be deselected because a child entity
          // was deselected, deselect the parent entity
          if (parentsToDeselect.has(key)) {
            return acc;
          }
          // if a parent entity was deselected, deselect all its children
          if (childrenToDeselect.has(key)) {
            return acc;
          }
          acc[key] = prev[key];
          return acc;
        },
        {},
      );
    });
  };

  const toggleSelectionToolbar = (value?: boolean) => {
    setIsSelectionVisible(value ?? !isSelectionVisible);
  };

  const resetSelection = () => {
    setIsSelectionVisible(false);

    if (Object.keys(selectedEntities).length > 0) setSelectedEntities({});
  };

  useEffect(() => {
    const selectionCount = Object.keys(selectedEntities).length;

    if (selectionCount === 0) {
      return resetSelection();
    }

    if (selectionCount > 0) {
      return toggleSelectionToolbar(true);
    }
  }, [selectedEntities]);

  return (
    <EntitiesSelectionContext.Provider
      value={{
        selectedEntities,
        isSelectionVisible,
        addToSelection,
        bulkAddToSelection,
        removeFromSelection,
        toggleSelectionToolbar,
        resetSelection,
      }}
    >
      {children}
    </EntitiesSelectionContext.Provider>
  );
};

export const useEntitiesSelection = () => useContext(EntitiesSelectionContext);
