import "./InboxAuthRequired.scss";
import React from "react";
import {
  ConnectedUserAvatar,
  ConnectedUserName,
} from "App/common/UserAvatar/UserAvatar";
import { AUTH_TYPE, AuthForm } from "App/components/Auth/AuthForm";
import { JOIN_ENTITY_TRIGGER } from "App/components/Auth/util";

export const InboxAuthRequired = ({ ownerId }: { ownerId?: string }) => {
  return (
    <div className="inbox-auth-required">
      <div className="inbox-auth-required-content">
        <ConnectedUserAvatar userId={ownerId} />
        <div className="inbox-auth-required-section">
          <p className="inbox-auth-required-section-header">
            Send files to{" "}
            <span className="user-name">
              <ConnectedUserName userId={ownerId} />
            </span>
          </p>
          <p className="inbox-auth-required-section-description">
            <span className="user-name">
              <ConnectedUserName userId={ownerId} />
            </span>{" "}
            is using Highnote to accept files securely.
          </p>
          <AuthForm
            type={AUTH_TYPE.SIGN_UP}
            returnTo={`${location.pathname}${location.search}`}
            trigger={JOIN_ENTITY_TRIGGER.INBOX}
            hideWelcomeSurvey
          />
        </div>
      </div>
    </div>
  );
};
