import React, { useCallback, useState } from "react";
import classNames from "classnames";

import { ROLE, Space } from "@highnote/server/src/core/entities";
import { Button, BUTTON_THEME } from "App/core/Button";
import { DialogButtons, Dialog, DialogSection } from "App/common/Dialog";
import {
  ENTITY_TYPE,
  DEFAULT_COLUMNS,
  EntityRowConfig,
} from "App/components/EntityTable/config";
import {
  ScrollableContainer,
  SCROLLABLE_CONTAINER_VARIANT,
} from "App/common/ScrollableContainer";
import { sortRowByCreatedAt } from "App/components/util";
import { ClickThruSpaceRowComponent } from "App/components/ClickThruSpaceRow";
import { hasRole } from "@highnote/server/src/core/shared-util";
import { useAuth } from "App/components/Auth";
import { EmptyTable } from "App/components/EntityTable/EmptyTable";
import { CreateSpaceWithNameDialog } from "App/components/Dialog/CreateSpace/CreateSpaceWithNameDialog";
import styles from "./dropboxSpacePicker.module.scss";

export const DropboxSpacePicker = ({
  onSubmit,
  onCancel,
}: {
  onSubmit: (spaceId: string) => Promise<void>;
  onCancel: () => void;
}) => {
  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [isNewSpaceDialogOpen, setIsNewSpaceDialogOpen] = useState(false);
  const [currentRoot, setCurrentRoot] = useState<Space>();

  const filter = useCallback(
    (row: EntityRowConfig) => {
      return (
        row?.type === ENTITY_TYPE.SPACE &&
        hasRole(user.id, ROLE.UPLOAD, row.entity as Space)
      );
    },
    [user?.id],
  );

  const handleOnSelect = async (row: EntityRowConfig) => {
    setCurrentRoot(row.entity as Space);
  };

  const handleOnSubmit = async () => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      await onSubmit(currentRoot.id);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog open onClose={onCancel} title="Import to...">
        <DialogSection />
        <DialogSection>
          <ScrollableContainer
            maxHeight={400}
            variant={SCROLLABLE_CONTAINER_VARIANT.FRAMED}
          >
            <ClickThruSpaceRowComponent
              parentSpace={currentRoot}
              tableBaseProps={{
                EmptyComponent: () => (
                  <EmptyTable action="Create or add space" />
                ),
                columns: [DEFAULT_COLUMNS.ICON, DEFAULT_COLUMNS.PREVIEW],
                disabled: isLoading,
                filter,
                tableRowVariant: "nested-click-thru",
                sort: sortRowByCreatedAt,
                rootEntity: currentRoot,
                onRootEntityChange: setCurrentRoot,
                onSelect: handleOnSelect,
              }}
            />
          </ScrollableContainer>
        </DialogSection>
        <DialogSection className={styles.dropboxSpacePicker__buttons}>
          <DialogButtons className={styles.dropboxSpacePicker__button}>
            <Button
              disabled={isLoading || isNewSpaceDialogOpen}
              theme={BUTTON_THEME.SECONDARY}
              onClick={() => {
                setIsNewSpaceDialogOpen(true);
              }}
            >
              New Space
            </Button>
          </DialogButtons>
          <DialogButtons className={styles.dropboxSpacePicker__button}>
            <Button
              disabled={Boolean(isLoading)}
              theme={BUTTON_THEME.SECONDARY}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              className={classNames({
                [styles.dropboxSpacePicker__button__loading]: isLoading,
              })}
              data-testid="dropbox-space-picker-import-button"
              disabled={Boolean(isLoading || !currentRoot)}
              theme={BUTTON_THEME.CTA}
              loading={isLoading}
              onClick={handleOnSubmit}
            >
              Import
            </Button>
          </DialogButtons>
        </DialogSection>
      </Dialog>
      <CreateSpaceWithNameDialog
        isOpen={isNewSpaceDialogOpen}
        close={() => setIsNewSpaceDialogOpen(false)}
        parentSpace={{
          id: currentRoot?.id,
          name: currentRoot?.name,
        }}
        onConfirm={(newSpace) => {
          setCurrentRoot(newSpace);
        }}
      />
    </>
  );
};
