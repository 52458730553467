import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import "./styles.scss";

const LoadingScreen = ({ fixed }: { fixed?: boolean }) => {
  return (
    <div className={"LoadingScreen"} style={fixed ? { position: "fixed" } : {}}>
      <div className="LoadingScreen-container">
        <div className="iconWrapper">
          <Player
            autoplay={true}
            loop={true}
            src="/public/icons/lottie_loader_1_alt_alpha.json"
            style={{ width: "110px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
