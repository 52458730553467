import { useState, useEffect } from "react";
import { history_NORENDER } from "App/modules/history";

export const usePinnedCommentId = () => {
  const [commentId, setCommentId] = useState<string>();

  useEffect(() => {
    let unmounted: boolean;
    const params = new URLSearchParams(location.search);
    const _commentId = params.get("commentId");
    setCommentId(_commentId);
    if (!_commentId) return;

    const timeout = setTimeout(() => {
      if (unmounted) return;
      params.delete("commentId");
      history_NORENDER.push(`${location.pathname}?${params.toString()}`);
      setCommentId(undefined);
    }, 6000);

    return () => {
      unmounted = true;
      clearTimeout(timeout);
    };
  }, [location]);

  return commentId;
};
