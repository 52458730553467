import {
  AUTH_PREFIX,
  Comment,
  FileEntity,
  Space,
  SUBSCRIPTION_INTERVAL,
  SUBSCRIPTION_TIER,
  SUBSCRIPTION_TIER_FORMATTED,
  Track,
  UserEntity,
  UserMetadata,
} from "@highnote/server/src/core/entities";
import {
  getActiveSpacesLimitByTier,
  getFullRoles,
  getInviteEmail,
  getStorageLimitByTier,
  getTrackVersionsLimitByTier,
  isAllowed,
  isInviteId,
  isSpacePrivateInboxEnabled,
  PERMISSION,
  TYPEFORM_WELCOME_SURVEY_ID,
} from "@highnote/server/src/core/shared-util";
import { highnote } from "@highnote/server/src/sdk";
import { Widget } from "@typeform/embed-react";
import LoadingScreen from "App/common/LoadingScreen/LoadingScreen";
import { ToastMessageContent, useToast } from "App/common/useToast";
import {
  FROM_MOBILE_APP_FLAG,
  MOBILE_TOKEN_FLAG,
  routePaths,
  sanitizeCurrentURL,
  withMobileAuthDeeplinkRoot,
} from "App/modules/urls";
import { formatFileSize, setTestProperty } from "App/modules/utils";
import {
  indexedDBLocalPersistence,
  setPersistence,
  signInAnonymously,
  signInWithCustomToken,
} from "firebase/auth";
import { debounce } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import { v4 as uuidv4 } from "uuid";
import { UserContextProvider, useUser } from "../useEntities/useUser";
import { JoinEntityDialog } from "./JoinEntityDialog";
import { JOIN_ENTITY_TRIGGER, JoinEntityConfig } from "./util";
import { useMobileAppParams } from "App/routes/Main/useMobileAppParams";
import { AUTH_TYPE } from "./AuthForm";

export const getAuthUrl = (params?: Record<string, string>) => {
  let url = "/login?";

  if (params) {
    const sp = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      if (value) sp.set(key, value);
    });
    url += `${sp.toString()}&`;
  }

  const existingParams = new URLSearchParams(location.search);
  const returnTo =
    existingParams.get("returnTo") ||
    encodeURIComponent(`${location.pathname}${location.search}`);
  return `${url}returnTo=${returnTo}`;
};

export const getIsInvitedUserLogin = () => {
  const query = new URLSearchParams(location.search);
  const queryHasReferrerType = query.has("referrer_type");
  const queryHasReferredId = query.has("referrer_id");
  const queryHasEmail = query.has("email");
  const queryHasReturnTo = query.has("returnTo");
  if (
    queryHasEmail &&
    queryHasReferredId &&
    queryHasReferrerType &&
    queryHasReturnTo
  ) {
    return true;
  }

  return false;
};

export const linkAccount = async ({
  method,
  identifier,
  password,
  token,
}: {
  method: "email" | "sms" | "token";
  identifier?: string;
  password?: string;
  token?: string;
}) => {
  return highnote.authenticateUser({
    method,
    identifier,
    password,
    token,
    link: true,
  });
};

// Array of param keys that are mapped directly
const AUTH_PARAM_KEYS = ["subscription", "coupon_code", "returnTo", "trigger"];

export const loginWithProvider = async ({
  connection,
  type = AUTH_TYPE.LOG_IN,
  returnTo,
}: {
  connection: AUTH_PREFIX.APPLE | AUTH_PREFIX.GOOGLE;
  type: AUTH_TYPE;
  returnTo?: string;
}) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  if (!domain || !clientId) {
    throw new Error("Missing Auth0 configuration");
  }

  // Get existing query params from the current URL
  const currentParams = new URLSearchParams(window.location.search);

  const redirectParams = AUTH_PARAM_KEYS.reduce((acc, key) => {
    const currentParamVal = currentParams.get(key);
    if (currentParamVal) {
      acc.set(key, currentParamVal);
    }
    return acc;
  }, new URLSearchParams());

  if (currentParams.get("frequency")) {
    redirectParams.set(
      "frequency",
      currentParams.get("frequency").toLowerCase(),
    );
  }

  if (currentParams.get("hideWelcomeSurvey")) {
    redirectParams.set("hideWelcomeSurvey", "true");
  }
  if (returnTo) {
    redirectParams.set("returnTo", returnTo);
  }

  const auth0Params = new URLSearchParams({
    audience: `https://dev-bdp0xr9n.auth0.com/api/v2/`,
    response_type: "token",
    connection,
    client_id: clientId,
    redirect_uri:
      encodeURI(
        `${location.origin}${type === AUTH_TYPE.LINK ? "/link" : "/login"}`,
      ) + `${redirectParams.toString() ? `?${redirectParams.toString()}` : ""}`, // redirectParams already encoded via URLSearchParams
    blockDuplicates: String(type !== AUTH_TYPE.LINK),
  });

  // Redirect to Auth0 login page
  location.href = `https://${domain}/authorize?${auth0Params.toString()}`;
};

export const logIn = async ({
  method,
  name,
  identifier,
  password,
  token,
  metadata,
  trigger,
  subscription,
  coupon_code,
  frequency,
  hasAuthError,
}: {
  method: "email" | "sms" | "token";
  name?: string;
  identifier?: string;
  password?: string;
  token?: string;
  metadata?: object;
  trigger?: JOIN_ENTITY_TRIGGER;
  subscription?: SUBSCRIPTION_TIER;
  coupon_code?: string;
  frequency?: SUBSCRIPTION_INTERVAL;
  hasAuthError?: boolean;
}): Promise<{
  uid: string;
  loginCount: number;
  redirectPath?: string;
}> => {
  // get access token & refresh token
  const { firebaseToken, loginCount, redirectPath } =
    await highnote.authenticateUser({
      method,
      name,
      identifier,
      password,
      token,
      metadata,
      trigger,
      subscription: subscription,
      coupon_code: coupon_code || "",
      frequency:
        (frequency?.toLowerCase() as SUBSCRIPTION_INTERVAL) ||
        SUBSCRIPTION_INTERVAL.MONTHLY,
    });

  if (!firebaseToken) {
    console.log("AUTH FAILED");
    return;
  }

  await signInWithCustomToken(highnote.__firebaseAuth, firebaseToken as string);

  const fromMobileApp = sessionStorage.getItem(FROM_MOBILE_APP_FLAG) === "true";
  if (fromMobileApp) {
    // Get user data to check welcome survey status
    const user = await highnote.getUserPublic({
      id: highnote.__firebaseAuth.currentUser.uid,
    });

    // Only redirect if welcome survey is completed
    if (user?.completedWelcomeSurvey) {
      handleMobileAppRedirect(firebaseToken, hasAuthError);
    }
    // If survey not completed, keep FROM_MOBILE_APP_FLAG in sessionStorage
    // so WelcomeSurvey component can handle redirect after completion
  }

  console.log("AUTH SUCCEEDED", highnote.__firebaseAuth.currentUser);
  return {
    uid: highnote.__firebaseAuth.currentUser?.uid,
    loginCount,
    redirectPath,
  };
};

const handleMobileAppRedirect = (
  firebaseToken: string,
  hasAuthError?: boolean,
) => {
  try {
    const pathname = location.pathname;
    const isLogout = pathname.startsWith("/logout");

    if (isLogout || hasAuthError) {
      return;
    }

    const deepLinkUrl = `${withMobileAuthDeeplinkRoot(`login?token=${encodeURIComponent(firebaseToken)}`)}`;
    window.location.href = deepLinkUrl;
    sessionStorage.removeItem(FROM_MOBILE_APP_FLAG);
  } catch (error) {
    console.log("Error in handleMobileAppRedirect: " + error);
  }
};

type AuthContextProps = {
  user: UserEntity;
  userMetadata: UserMetadata;
  authLoading: boolean;
  refreshAuth: () => void;
  refreshShareKey: () => Promise<void>;
  storageLimit: number;
  activeSpacesLimit: number;
  trackVersionsLimit: number;
  hideWelcomeSurvey: boolean;
  setHideWelcomeSurvey: (hideWelcomeSurvey: boolean) => void;
  shareKey?: string;
  isPublicView: (entity: Space) => boolean;
  isAllowed: (
    key: PERMISSION,
    entities: {
      space?: Space;
      comment?: Comment;
      track?: Track;
      file?: FileEntity;
    },
  ) => boolean;
  joinEntity: (props: JoinEntityConfig, force?: boolean) => void;
};

const AuthContext = React.createContext<AuthContextProps>({
  user: undefined,
  userMetadata: undefined,
  authLoading: true,
  refreshAuth: () => {},
  refreshShareKey: () => Promise.resolve(),
  shareKey: undefined,
  storageLimit: Infinity,
  activeSpacesLimit: Infinity,
  trackVersionsLimit: Infinity,
  hideWelcomeSurvey: false,
  setHideWelcomeSurvey: () => {},
  joinEntity: () => {},
  isPublicView: () => false,
  isAllowed: () => false,
});

const WelcomeSurvey = React.memo(
  ({
    userId,
    welcomeBack,
    userSubscriptionTier,
  }: {
    userId: string;
    welcomeBack: boolean;
    userSubscriptionTier?: SUBSCRIPTION_TIER;
  }) => {
    return (
      <Widget
        id={TYPEFORM_WELCOME_SURVEY_ID}
        key={userId}
        hidden={{
          user_id: userId,
          welcome_back: String(!!welcomeBack),
          ...(userSubscriptionTier && {
            subscription: String(
              SUBSCRIPTION_TIER_FORMATTED[userSubscriptionTier],
            ),
          }),
          ...(window.location.pathname.startsWith("/dropbox") && {
            referrer: "dropbox",
          }),
        }}
        className={`typeform-welcome-survey`}
        onSubmit={async () => {
          await highnote.updateUser({
            id: userId,
            data: { completedWelcomeSurvey: true },
          });

          const fromMobileApp =
            sessionStorage.getItem(FROM_MOBILE_APP_FLAG) === "true";

          if (fromMobileApp) {
            const publicMe = await highnote.getUserPublic({
              id: userId,
              generateFirebaseToken: fromMobileApp,
            });
            const token = publicMe?.firebaseToken;

            if (token) {
              handleMobileAppRedirect(token);
            }
          }
        }}
        inlineOnMobile={true}
      />
    );
  },
);
WelcomeSurvey.displayName = "WelcomeSurvey";

const _AuthContextProvider = ({
  loading,
  userMetadata,
  shareKey,
  hideWelcomeSurvey,
  refreshAuth,
  refreshShareKey,
  children,
  setHideWelcomeSurvey,
}: {
  loading: boolean;
  shareKey?: string;
  userMetadata?: UserMetadata;
  hideWelcomeSurvey: boolean;
  refreshAuth: (options?: { hideWelcomeSurvey?: boolean }) => void;
  refreshShareKey: () => Promise<void>;
  children: React.ReactNode;
  setHideWelcomeSurvey: (hideWelcomeSurvey: boolean) => void;
}) => {
  const history = useHistory();
  const authId = getAuthId();
  const { addMessage } = useToast();
  const { user, userLoading } = useUser();
  const { isAuthorizedMobileComponent } = useMobileAppParams();
  const [showJoinEntity, setShowJoinEntity] = useState<boolean>(false);
  const joinEntityConfigRef = useRef<JoinEntityConfig>();
  const dismissedJoinEntityRef = useRef<boolean>(false);
  const [isCheckingInvite, setCheckingInvite] = useState<boolean>(true);
  const firstLoginRef = useRef<boolean>(false);

  const storageLimit = getStorageLimitByTier(user?.subscriptionTier);
  const activeSpacesLimit = getActiveSpacesLimitByTier(user?.subscriptionTier);
  const trackVersionsLimit = getTrackVersionsLimitByTier(
    user?.subscriptionTier,
  );

  useEffect(() => {
    // For Cypress (see cypress/support/commands.js)
    setTestProperty("logIn", logIn);
  }, []);

  useEffect(() => {
    if (!user) return;

    try {
      const remaining = formatFileSize(storageLimit - user.storageUsed);
      console.log(`[DEBUG] User Subscription Tier: ${user.subscriptionTier}`);
      console.log(
        `[DEBUG] User Storage: ${user.storageUsed} / ${storageLimit}; ${remaining} remaining`,
      );
      console.log(
        `[DEBUG] User Spaces: ${user.spacesUsed} / ${activeSpacesLimit}`,
      );
      console.log(`[DEBUG] User Track: ${user.tracksUsed} / ${Infinity}`);
      console.log(
        `[DEBUG] User Track Versions: ${user.trackVersionsUsed} / ${trackVersionsLimit}`,
      );
    } catch (e) {
      console.log("[DEBUG] Could not log user storage stats.");
    }

    if (user.stripeCustomerId) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const tap = (window as any).tap;

      if (tap) {
        try {
          tap("trial", user.stripeCustomerId);
        } catch (e) {
          // empty
        }

        if (user.firstStripeChargeId) {
          try {
            tap(
              "conversion",
              user.firstStripeChargeId,
              // stripe gives us cents, tapfiliate wants dollars
              user.firstStripeChargeAmount / 100,
              { customer_id: user.stripeCustomerId },
            );
          } catch (e) {
            // empty
          }
        }
      }
    }

    if (user.deletedAuth0) {
      history.push(routePaths.logout);
    }
  }, [user]);

  useEffect(() => {
    if (!user?.firstLogIn) return;
    firstLoginRef.current = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataLayer = (window as any).dataLayer;
    if (dataLayer) {
      dataLayer.push({ event: "Signed_Up" });
      highnote.updateUser({ id: user.id, data: { firstLogIn: false } });
    }
  }, [user?.firstLogIn]);

  const showWelcomeSurvey = useMemo(() => {
    const isFromMobileApp =
      sessionStorage.getItem(FROM_MOBILE_APP_FLAG) === "true";
    const exceptions = ["/signup", "/login", "/link", "/logout"];

    if (
      exceptions.some((p) => location.pathname.startsWith(p)) &&
      !isFromMobileApp
    )
      return false;

    // Intermediary page for mobile app to handoff to a space, share key, and track id
    if (location.pathname.startsWith("/space-handoff")) return false;

    // If the current page is an authorized mobile component, don't show the welcome survey
    if (isAuthorizedMobileComponent) return false;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as any).Cypress) return false;
    if (!user) return false;
    if (hideWelcomeSurvey) return false;

    return !user.completedWelcomeSurvey;
  }, [
    hideWelcomeSurvey,
    userLoading,
    user,
    shareKey,
    isAuthorizedMobileComponent,
  ]);

  useEffect(() => {
    if (userLoading) return;
    const query = new URLSearchParams(location.search);
    const pushToAuth = query.has("auth");

    const recipientId = (query.get("recipient") || "").replace(" ", "+");
    const isSpace = location.pathname.includes("/space/");
    const isCollection = location.pathname.includes("/collection/");

    if (!isSpace && !isCollection) {
      setCheckingInvite(false);
      return;
    }

    let redirectUrl = getAuthUrl();

    // If you land on a space or a collection page with a `recipient` specified,
    // this is an entity member invite link. Get the relevant redirect URL.
    if (recipientId) {
      const senderName = (query.get("senderName") || "").replace(" ", "+");
      const senderId = (query.get("senderId") || "").replace(" ", "+");
      const entityType = "Space";
      const referrer_type = "space_member_email";

      const banner = `${
        senderName || "Someone"
      } invited you to join their ${entityType} on Highnote.`;

      let recipientEmail: string;

      if (isInviteId(recipientId)) {
        recipientEmail = getInviteEmail(recipientId);
        if (recipientEmail === user?.email) {
          setCheckingInvite(false);
          return;
        }
      }

      redirectUrl = getAuthUrl({
        banner,
        referrer_type,
        referrer_id: senderId,
        email: recipientEmail,
      });
      // If the invitation is for a different user, have the user sign-in again
      if (user && user.id !== recipientId) {
        const toastId = uuidv4();
        addMessage({
          id: toastId,
          children: (
            <ToastMessageContent
              type="error"
              id={toastId}
              text="Invalid invitation. Please sign in again."
            />
          ),
          expireAt: Date.now() + 10000,
        });
        setCheckingInvite(false);
        history.push(redirectUrl);
        return;
      }
    }

    // If there is an '?auth' param and you're not logged in
    // you will be redirected to the login page regardless of whether
    // or not it's an invite link.
    if (pushToAuth) {
      if (!user) {
        setCheckingInvite(false);
        history.push(redirectUrl);
        return;
      }
      query.delete("auth");
      history.replace(`${location.pathname}?${query.toString()}`);
    }

    if (!recipientId) {
      setCheckingInvite(false);
      return;
    }

    // If it's not a new user invite OR the invited user is already
    // logged in, we don't need to do anything further.
    if (recipientId === user?.id) {
      setCheckingInvite(false);
      return;
    }

    history.push(redirectUrl);
    setCheckingInvite(false);
  }, [userLoading, user?.id, user?.email]);

  const isPublicView = useCallback(
    (entity: Space) => {
      if (!entity) return false;
      const roles = getFullRoles(entity);

      // If you don't have access through your user ID or your share key,
      // it must be publicly visible.
      return (
        !Object.keys(roles).includes(user?.id) &&
        !Object.keys(roles).includes(shareKey)
      );
    },
    [user, shareKey],
  );

  const joinEntity = useCallback(
    (config: JoinEntityConfig, force?: boolean) => {
      // TODO: Many places we are passing an undefined entity to joinEntity.
      // This is due to the fact we are trying to pass a space via useSpaceContext()
      // which is not available outside of SpaceHomeV2.
      // We would need to migrate to the new useGlobalSpace context to avoid this issue.
      // https://www.notion.so/highnotefm/Speed-Optimization-Update-SpaceHomeV2-to-use-new-global-state-management-instead-of-useSpaceContext-1ab15dd1482880b19b2dcb5d0bb74e01?pvs=4
      if (!config.entity) return;

      // If user is already a member, or guest on private inbox, don't show dialog
      if (user && config.entity.readableByV2?.includes(user?.id)) return;
      if (isSpacePrivateInboxEnabled(config.entity)) return;
      if (!force && dismissedJoinEntityRef.current) return;
      if (isPublicView(config.entity)) return;
      joinEntityConfigRef.current = config;
      setShowJoinEntity(true);
    },
    [!!user, isPublicView],
  );

  const value = useMemo(() => {
    const authLoading = loading || userLoading;

    // For Cypress (see cypress/support/commands.js)
    setTestProperty("authUser", user);

    return {
      user,
      userMetadata,
      shareKey,
      authLoading,
      refreshAuth,
      refreshShareKey,
      joinEntity,
      storageLimit,
      activeSpacesLimit,
      trackVersionsLimit,
      hideWelcomeSurvey,
      setHideWelcomeSurvey,
      isPublicView,
      isAllowed: (
        key: PERMISSION,
        entities: {
          space?: Space;
          comment?: Comment;
          track?: Track;
          file?: FileEntity;
        },
      ) =>
        isAllowed(key, authId, entities) || isAllowed(key, shareKey, entities),
    };
  }, [
    user,
    userMetadata,
    loading,
    userLoading,
    refreshAuth,
    refreshShareKey,
    joinEntity,
    storageLimit,
    activeSpacesLimit,
    trackVersionsLimit,
    hideWelcomeSurvey,
    setHideWelcomeSurvey,
    isPublicView,
    shareKey,
  ]);

  return (
    <AuthContext.Provider value={value}>
      <Helmet>
        <body data-is-logged-in={!!user} />
      </Helmet>

      {loading || userLoading || isCheckingInvite ? (
        <LoadingScreen />
      ) : showWelcomeSurvey ? (
        <WelcomeSurvey
          userId={user.id}
          welcomeBack={!firstLoginRef.current}
          userSubscriptionTier={
            user.subscriptionTier &&
            user.subscriptionTier !== SUBSCRIPTION_TIER.FREE
              ? user.subscriptionTier
              : undefined
          }
        />
      ) : (
        children
      )}

      <JoinEntityDialog
        config={joinEntityConfigRef.current}
        open={showJoinEntity}
        onClose={() => {
          setShowJoinEntity(false);
        }}
        onDismiss={() => (dismissedJoinEntityRef.current = true)}
      />
    </AuthContext.Provider>
  );
};

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { shouldRedirectToMobile } = useMobileAppParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [shareKey, setShareKey] = useState<string>();
  const [userMetadata, setUserMetadata] = useState<UserMetadata>();
  const [currentUserId, setCurrentUserId] = useState<string>();
  const [hideWelcomeSurvey, setHideWelcomeSurvey] = useState<boolean>(
    new URLSearchParams(location.search).has("hideWelcomeSurvey"),
  );

  // Ideally, this should be called only once when auth state changes.
  // However, we have limited access to other parts and states of the app inside
  // the onAuthStateChanged callback, so we instead rely on debouncing this function
  // for the time being.
  const refreshAuth = useMemo(
    () =>
      debounce(async () => {
        try {
          setLoading(true);
          checkIfComesFromMobileApp();
          const firebaseUser = highnote.__firebaseAuth.currentUser;
          const isFromMobileApp =
            sessionStorage.getItem(FROM_MOBILE_APP_FLAG) === "true";
          const publicMe = await highnote.getUserPublic({
            id: firebaseUser.uid,
            url: sanitizeCurrentURL([MOBILE_TOKEN_FLAG]),
            generateFirebaseToken: isFromMobileApp,
          });
          setUserMetadata(publicMe?.userMetadata);

          if (
            shouldRedirectToMobile &&
            publicMe?.firebaseToken &&
            publicMe?.completedWelcomeSurvey
          ) {
            handleMobileAppRedirect(publicMe.firebaseToken);
          }
          firebaseUser.getIdTokenResult(true).then((idTokenResult) => {
            const _shareKey = idTokenResult?.claims?.shareKey;
            setShareKey(_shareKey);
          });
        } catch (e) {
          console.error("Error refreshing auth", e);
        } finally {
          setLoading(false);
        }
      }, 200),
    [],
  );

  useEffect(() => {
    const unsubscribe = highnote.__firebaseAuth.onAuthStateChanged(() => {
      setLoading(true);
      const firebaseUser = highnote.__firebaseAuth.currentUser;

      checkIfComesFromMobileApp();

      if (!firebaseUser) {
        signInAnonymously(highnote.__firebaseAuth);
        return;
      }

      if (firebaseUser.isAnonymous) {
        setCurrentUserId(undefined);
        setUserMetadata(undefined);
      } else {
        setCurrentUserId(firebaseUser.uid);
      }

      setPersistence(highnote.__firebaseAuth, indexedDBLocalPersistence);
      refreshAuth();
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const checkIfComesFromMobileApp = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const comesFromMobile = urlParams.get(FROM_MOBILE_APP_FLAG) === "true";
    if (comesFromMobile) {
      sessionStorage.setItem(FROM_MOBILE_APP_FLAG, "true");
    }
  };

  const refreshShareKey = async () => {
    const firebaseUser = highnote.__firebaseAuth.currentUser;
    const idTokenResult = await firebaseUser.getIdTokenResult(true);
    const _shareKey = idTokenResult?.claims?.shareKey;
    setShareKey(_shareKey);
  };

  const readyToUseContext = !loading || currentUserId;
  const baseComponent = (
    <_AuthContextProvider
      loading={loading}
      userMetadata={userMetadata}
      hideWelcomeSurvey={hideWelcomeSurvey}
      shareKey={shareKey}
      refreshAuth={refreshAuth}
      refreshShareKey={refreshShareKey}
      setHideWelcomeSurvey={setHideWelcomeSurvey}
    >
      {children}
    </_AuthContextProvider>
  );

  return readyToUseContext ? (
    <UserContextProvider id={currentUserId}>
      {baseComponent}
    </UserContextProvider>
  ) : (
    baseComponent
  );
};

export const useAuth = () => useContext(AuthContext);

export const getAuthId = () => {
  return highnote.__firebaseAuth.currentUser?.uid;
};
