import React from "react";

interface FileListSkeletonProps {
  numFiles?: number;
}

export const FileListSkeleton = ({ numFiles = 5 }: FileListSkeletonProps) => {
  return (
    <React.Fragment>
      {Array(numFiles)
        .fill(null)
        .map((_, i) => {
          return (
            <div key={i} className="skeleton">
              <div className="skeleton-box" />
              <ul className="skeleon-list-wrapper">
                <div className="skeleton-box" />
                <div className="skeleton-box" />
              </ul>
              <div className="skeleton-box skeleton-timestamp" />
            </div>
          );
        })}
    </React.Fragment>
  );
};
