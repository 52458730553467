// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s5En24nDJt3INMVSuDEc{position:relative;width:100%;cursor:pointer;transition:all .2s ease}.s5En24nDJt3INMVSuDEc .gJ1f4pXha1zKRB7D3nDf{margin-right:8px}.s5En24nDJt3INMVSuDEc .gJ1f4pXha1zKRB7D3nDf>svg{width:100%;height:100%}", "",{"version":3,"sources":["webpack://./src/App/routes/Main/Space/DownloadSpaceV2.module.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CACA,UAAA,CACA,cAAA,CACA,uBAAA,CAEA,4CACE,gBAAA,CAEA,gDACE,UAAA,CACA,WAAA","sourcesContent":["@import \"App/styles/_variables\";\n\n.DownloadSpaceMenuV2-button {\n  position: relative;\n  width: 100%;\n  cursor: pointer;\n  transition: all 0.2s ease;\n\n  .icon {\n    margin-right: 8px;\n\n    > svg {\n      width: 100%;\n      height: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DownloadSpaceMenuV2-button": "s5En24nDJt3INMVSuDEc",
	"icon": "gJ1f4pXha1zKRB7D3nDf"
};
export default ___CSS_LOADER_EXPORT___;
