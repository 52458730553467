import { highnote } from "@highnote/server/src/sdk";

export const isDescendantRecursiveForSpaces: (props: {
  id: string;
  ancestorId: string;
}) => Promise<boolean> = async ({ ancestorId, id }) => {
  if (!id || !ancestorId) return false;

  const space = await highnote.getSpace({ id, fromCache: true }).catch((e) => {
    // if I don't have permission to read this space, it means I do not have access
    // to the parent space, so just return null. but it is something else, we throw it
    if (e.code === "permission-denied") {
      return null;
    }
    throw e;
  });

  // If the I don't exist, or I don't have a parent collection,
  // I cannot be a descendant of this ancestor
  if (!space?.spaceId) return false;

  const parent = await highnote
    .getSpace({
      id: space.spaceId,
      fromCache: true,
    })
    .catch((e) => {
      // if I don't have permission to read this space, it means I do not have access
      // to the parent space, so just return null. but it is something else, we throw it
      if (e.code === "permission-denied") {
        return null;
      }
      throw e;
    });

  // If my parent doesn't exist, I cannot be a descendant of this ancestor
  if (!parent) return false;

  // If my parent (or my parent's parent) IS the ancestor, I am a descendant of this ancestor
  if (parent.id === ancestorId || parent.spaceId === ancestorId) return true;

  // If my parent does not have a parent, I cannot be a descendant of this ancestor
  if (!parent.spaceId) return false;

  // If my parent is a descendant of this ancestor, I am a descendant of this ancestor
  return isDescendantRecursiveForSpaces({
    id: parent.id,
    ancestorId,
  });
};
