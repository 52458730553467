import React, { ReactElement } from "react";
import { ReactComponent as HeartSticker } from "App/common/icons/heart-sticker.svg";
import {
  StickerBlock as StickerBlockType,
  StickerIds,
} from "@highnote/server/src/core/entities";

export const STICKER_IDS: Record<StickerIds, ReactElement> = {
  "heart-sticker": <HeartSticker />,
};

export const StickerBlock = (props: {
  isEditing: boolean;
  data: StickerBlockType;
  onChange: (block: StickerBlockType) => void;
  onSave?: () => void;
}) => {
  const { data } = props;

  if (STICKER_IDS[data.id]) {
    return (
      <div className="custom-block" data-id={data.id}>
        {STICKER_IDS[data.id]}
      </div>
    );
  }

  if (data.imageUrl) {
    return (
      <div className="sticker-block" data-id={data.id}>
        <img src={data.imageUrl} alt="Sticker" />
      </div>
    );
  }

  return null;
};
