import "./TrackVersionTabs.scss";
import without from "lodash/without";
import React, { useEffect, useMemo, useRef } from "react";
import classNames from "classnames";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { useTrack } from "App/components/useEntities/useTrack";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { ReactComponent as CloseSVG } from "App/common/icons/close.svg";
import { PreviewText } from "@highnote/preview-text/src";
import {
  MAX_PINNED_VERSIONS,
  MAX_PINNED_VERSIONS_FREE,
  PERMISSION,
} from "@highnote/server/src/core/shared-util";
import {
  FileEntity,
  SUBSCRIPTION_TIER,
} from "@highnote/server/src/core/entities";
import { AddVersionButton } from "App/components/TrackEditor/TrackVersions/AddVersionButton";
import { FileUpload, UPLOAD_GROUP, useFiles } from "App/components/useFiles";
import { ReactComponent as PinSVG } from "App/common/icons/pin.svg";
import { ReactComponent as PlusSVG } from "App/common/icons/plus.svg";
import { ReactComponent as GiftFillSVG } from "App/common/icons-v2/gift-fill.svg";
import { useTrackEditor } from "App/components/useTrackEditor";
import { TRACK_EDITOR_TAB } from "App/components/TrackEditor";
import { useAuth } from "App/components/Auth";
import { useSpaceContext } from "App/common/useSpace";
import {
  DEFAULT_TRACK_VERSION_ACTIONS,
  TRACK_VERSION_ACTIONS,
  TrackVersionActions,
} from "App/components/TrackEditor/TrackVersions/TrackVersionActions";
import { DEFAULT_VERSION_COLOR_ID } from "App/components/TrackEditor/TrackVersions";
import { MenuItem } from "App/common/Menu";
import { ProgressBar } from "App/common/ProgressBar";
import { TrackVersionStar } from "App/components/TrackEditor/TrackVersions/TrackVersionStar";
import { usePlanLimitsContext } from "App/common/PlanLimits/usePlanLimits";
import { useTrackVersionEditorContext } from "App/components/TrackVersionEditor/utils/context/TrackVersionEditorContext";
import { Skeleton } from "App/common/Skeleton/Skeleton";
import { useViewport } from "App/common/useViewport";

enum TAB_TYPE {
  VERSION_TAB = "VERSION",
  UPLOAD_TAB = "UPLOAD",
}

const UploadProgressLabel = ({ upload }: { upload: FileUpload }) => {
  return (
    <div className="UploadProgressLabel">
      <div className="info">
        <PreviewText>{upload.file.fileName}</PreviewText>
        <ProgressBar progress={upload.progress} />
      </div>
      <Button
        size={BUTTON_SIZE.XSMALL}
        theme={BUTTON_THEME.ICON}
        className="cancel"
        type="button"
        onClick={upload.cancelUpload}
      >
        <CloseSVG />
      </Button>
    </div>
  );
};

const VersionLabel = ({ version }: { version: FileEntity }) => {
  const tabRef = useRef<HTMLDivElement>();
  const { currentTrackVersion, trackFilesLoading } = useTrack();
  const { isMobile } = useViewport();

  if (!version) {
    return <>Version not found.</>;
  }

  const isLoading = version.name === "[File Not Found]" && trackFilesLoading;
  const isActive = currentTrackVersion?.id === version?.id;

  return (
    <div
      className="VersionLabel"
      ref={tabRef}
      data-version-color-id={DEFAULT_VERSION_COLOR_ID}
    >
      <div className={classNames("info", { loading: isLoading })}>
        {isLoading ? (
          // The total height of the version label + track version actions
          // is 14px on mobile and 30px on desktop
          <Skeleton width="100%" height={isMobile ? 14 : 30} />
        ) : (
          <PreviewText>{version.name}</PreviewText>
        )}
      </div>
      {!isLoading && <TrackVersionStar version={version} />}
      {!isLoading && (
        <TrackVersionActions
          actions={without(
            [...DEFAULT_TRACK_VERSION_ACTIONS, TRACK_VERSION_ACTIONS.SWAP],
            TRACK_VERSION_ACTIONS.REMOVE,
          )}
          version={version}
          menuProps={{
            className: `highnote-track-version-tab-menu ${
              isActive ? "active" : ""
            }`,
            anchorEl: tabRef.current,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            transformOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }}
        />
      )}
    </div>
  );
};

export const TrackVersionTabs = () => {
  const { track, currentTrackVersion, setCurrentTrackVersion } = useTrack();
  const { localPinnedTrackVersions, localTrackVersions } =
    useTrackVersionEditorContext();

  const { getUploadCache, getUploads } = useFiles();
  const uploadCache = getUploadCache(
    UPLOAD_GROUP.TRACK_VERSIONS_PINNED,
    track?.id,
  );
  const uploads = getUploads({ cache: uploadCache });
  // Leaving this so it's easy uncommenting for local testing
  // const uploads: FileUpload[] = [
  //   {
  //     file: {
  //       id: "123124",
  //       fileName: "adfadadfadfadfadfadfadsfadfaadflongadfadfadsff.awdf",
  //     },
  //     progress: 0.5,
  //     cancelUpload: () => {},
  //   },
  // ];
  const lastUpload = uploads[uploads.length - 1];
  const { openTrackEditor } = useTrackEditor();
  const { showPlanPickerDialog } = usePlanLimitsContext();
  const { isAllowed, user } = useAuth();
  const { space } = useSpaceContext();
  const isUserSpaceOwner = space?.createdBy === user?.id;
  const canAddToTrack = isAllowed(PERMISSION.TO_ADD_TO_TRACK_IN_SPACE, {
    space,
    track,
  });
  const canAddPinnedVersion = isAllowed(
    PERMISSION.TO_ADD_PINNED_TRACK_VERSION,
    {
      track,
      space,
    },
  );

  useEffect(() => {
    if (lastUpload?.file?.id) {
      setCurrentTrackVersion(lastUpload.file as FileEntity);
    }
  }, [lastUpload?.file?.id]);

  const pinnedVersionTabs = useMemo(() => {
    return localPinnedTrackVersions.map((version) => {
      return {
        type: TAB_TYPE.VERSION_TAB,
        data: localTrackVersions.find((v) => v.id === version),
      };
    });
  }, [localPinnedTrackVersions, localTrackVersions]);

  const uploadTabs = useMemo(() => {
    return uploads.map((upload) => {
      return {
        type: TAB_TYPE.UPLOAD_TAB,
        data: upload,
      };
    });
  }, [uploads]);

  const allTabs = useMemo(
    () => [...pinnedVersionTabs, ...uploadTabs],
    [uploadTabs, pinnedVersionTabs],
  );

  const showTabs = canAddToTrack || localPinnedTrackVersions.length > 1;

  if (!showTabs) return null;

  const isSpaceFreeSubscription =
    space?.subscriptionTier === SUBSCRIPTION_TIER.FREE;

  const cannotAddPinnedVersionForAnonymous = !user && !canAddPinnedVersion;
  const showMonetizationCallout = isSpaceFreeSubscription && isUserSpaceOwner;

  const isFreeSubscriptionAndNotOwner =
    isSpaceFreeSubscription && !isUserSpaceOwner;

  return (
    <ErrorBoundary name="TrackVersionTabs">
      <div className="highnote-track-version-tabs">
        <div className="tabs">
          {new Array(
            isFreeSubscriptionAndNotOwner
              ? MAX_PINNED_VERSIONS_FREE
              : MAX_PINNED_VERSIONS,
          )
            .fill("")
            .map((_, i) => {
              const tab = allTabs[i];

              let tabId: string;
              if (tab?.type == TAB_TYPE.VERSION_TAB) {
                tabId = (tab.data as FileEntity)?.id;
              } else if (tab?.type == TAB_TYPE.UPLOAD_TAB) {
                tabId = (tab.data as FileUpload)?.file?.id;
              }

              const isActive = currentTrackVersion?.id === tabId;
              const isLastFreeTab =
                MAX_PINNED_VERSIONS -
                  (MAX_PINNED_VERSIONS - MAX_PINNED_VERSIONS_FREE) ===
                i;

              return (
                <div
                  key={tabId || i}
                  data-type={tab?.type || "ADD"}
                  data-is-active={isActive}
                  className="highnote-track-version-tab"
                  role={tab ? "button" : ""}
                  onClick={() => {
                    if (!tab) return;

                    if (tab.type == TAB_TYPE.VERSION_TAB) {
                      setCurrentTrackVersion(tab.data as FileEntity);
                    }

                    if (tab.type == TAB_TYPE.UPLOAD_TAB) {
                      setCurrentTrackVersion(
                        (tab.data as FileUpload).file as FileEntity,
                      );
                    }
                  }}
                >
                  {!tab && (
                    <>
                      {showMonetizationCallout && isLastFreeTab ? (
                        <Button
                          startIcon={<PlusSVG />}
                          endIcon={<GiftFillSVG />}
                          className="add-version-button monetization"
                          data-cypress-id="add-version-btn-monetization"
                          theme={BUTTON_THEME.MONETIZATION_INVERSE}
                          onClick={showPlanPickerDialog}
                        >
                          Version
                        </Button>
                      ) : (
                        <AddVersionButton
                          space={space}
                          disabled={
                            cannotAddPinnedVersionForAnonymous ||
                            !canAddPinnedVersion ||
                            (isFreeSubscriptionAndNotOwner && isLastFreeTab)
                          }
                          pinOnSave
                          className="add-version-button add-version"
                          data-cypress-id="add-version-btn"
                          theme={BUTTON_THEME.SECONDARY}
                          size={BUTTON_SIZE.XSMALL}
                          additionalMenuOptions={
                            localTrackVersions.length >
                            localPinnedTrackVersions.length
                              ? [
                                  // eslint-disable-next-line react/jsx-key
                                  <MenuItem
                                    onClick={() => {
                                      openTrackEditor({
                                        track,
                                        initialTab: TRACK_EDITOR_TAB.VERSIONS,
                                      });
                                    }}
                                  >
                                    <PinSVG />
                                    Pin existing version
                                  </MenuItem>,
                                ]
                              : []
                          }
                          menuProps={{
                            className: "highnote-track-version-dropdown-menu",
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "right",
                            },
                            transformOrigin: {
                              vertical: "bottom",
                              horizontal: "right",
                            },
                          }}
                        >
                          <PlusSVG /> <span>Version</span>
                        </AddVersionButton>
                      )}
                    </>
                  )}

                  {tab?.type == TAB_TYPE.VERSION_TAB && (
                    <VersionLabel key={i} version={tab.data as FileEntity} />
                  )}

                  {tab?.type == TAB_TYPE.UPLOAD_TAB && (
                    <UploadProgressLabel
                      key={i}
                      upload={tab.data as FileUpload}
                    />
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </ErrorBoundary>
  );
};
