import React from "react";
import styles from "./ErrorBoundary.module.scss";

type ErrorBoundaryProps = {
  children: React.ReactNode;
  name: string;
};

const GenericErrorMsg = () => {
  return (
    <div className={styles["highnote-error-boundary"]}>
      Something went wrong. Please try again or{" "}
      <a href="mailto:help@highnote.fm" rel="noreferrer" target="_blank">
        contact&nbsp;support.
      </a>
    </div>
  );
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state: {
    hasError: boolean;
  };

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidCatch(error: any) {
    console.log(`⛔️ Caught via ErrorBoundary [${this.props.name}]:`, error);
  }

  render() {
    if (this.state.hasError) {
      return <GenericErrorMsg />;
    }

    return this.props.children;
  }
}
