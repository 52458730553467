import React, { RefObject, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { ReactComponent as HighnoteAddSVG } from "App/common/icons/highnote-add.svg";
import styles from "./FileDrop.module.scss";

const getDraggedFiles = (e: DragEvent | React.DragEvent) => {
  if (e.dataTransfer.items) {
    const allItems = Array.from(e.dataTransfer.items);

    const files = allItems
      .map((item) => {
        if (item.kind === "file") {
          return item.getAsFile();
        }

        if (item.kind === "string") {
          console.log("Not a valid file type. TODO better error messaging");
        }
      })
      .filter((f) => !!f);

    return files;
  }

  const files = Array.from(e.dataTransfer.files);
  return files;
};

export const FileDrop = ({
  dragRef,
  onDrop,
  instruction,
}: {
  dragRef: RefObject<HTMLDivElement>;
  onDrop?: (files: File[]) => void;
  instruction?: string;
}) => {
  const [isDragging, setDragging] = useState(false);
  const [showInstructionIcon, setShowInstructionIcon] = useState(false);
  const [showInstruction, setShowInstruction] = useState(false);
  const dropRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!dragRef.current || !dropRef.current) return;

    const onDragEnter = (e: DragEvent) => {
      e.preventDefault();

      // Sometimes the drop area is so small that it doesn't make sense
      // to show the icon + instruction on hover.
      const canFitInstruction =
        dragRef.current.clientWidth > 200 &&
        dragRef.current.clientHeight >= 100;
      const canFitInstructionIcon =
        dragRef.current.clientWidth > 200 &&
        dragRef.current.clientHeight >= 200;
      setShowInstruction(canFitInstruction);
      setShowInstructionIcon(canFitInstructionIcon);

      setDragging(true);
    };

    const onDragLeave = (e: DragEvent) => {
      e.preventDefault();
      setDragging(false);
      setShowInstruction(false);
      setShowInstructionIcon(false);
    };

    dragRef.current.addEventListener("dragenter", onDragEnter);
    dropRef.current.addEventListener("dragleave", onDragLeave);

    return () => {
      dragRef.current &&
        dragRef.current.removeEventListener("dragenter", onDragEnter);
      dropRef.current &&
        dropRef.current.removeEventListener("dragleave", onDragLeave);
    };
  }, [dragRef.current, dropRef.current]);

  return (
    <div
      ref={dropRef}
      className={classNames(styles["highnote-file-drop"], styles["drop-area"])}
      data-cypress-id="file-drop"
      data-is-dragging={isDragging}
      onDragEnter={(e) => e.preventDefault()}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => {
        e.preventDefault();
        const files = getDraggedFiles(e);
        setDragging(false);

        if (files.length < 1) {
          return;
        }

        onDrop(files);
      }}
    >
      {showInstructionIcon && <HighnoteAddSVG />}
      {showInstruction && (
        <p>{instruction || "Upload files by dropping them here"}</p>
      )}
    </div>
  );
};
