import "./styles.scss";
import React from "react";
import {
  Menu as MuiMenu,
  MenuProps,
  MenuItem as MuiMenuItem,
  MenuItemProps,
  Popper as MuiPopper,
  PopperProps,
} from "@mui/material";
import { useTheme } from "../ThemeProvider";

export const Menu = (props: MenuProps) => {
  const { theme } = useTheme();
  return (
    <MuiMenu
      {...props}
      className={`highnote-menu ${props.className}`}
      data-theme={theme}
    />
  );
};

export const MenuItem = (props: MenuItemProps) => {
  const { theme } = useTheme();
  return (
    <MuiMenuItem
      {...props}
      className={`highnote-menu-item ${props.className}`}
      data-theme={theme}
    />
  );
};

export const Popper = (props: PopperProps) => {
  const { theme } = useTheme();

  return (
    <MuiPopper
      {...props}
      className={`highnote-menu ${props.className || ""}`}
      data-theme={theme}
    />
  );
};
