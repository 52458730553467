import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router";
import {
  collection,
  where,
  query,
  getCountFromServer,
  getDocs,
} from "firebase/firestore";
import * as Sentry from "@sentry/browser";

import LoadingScreen from "App/common/LoadingScreen/LoadingScreen";
import { useAuth } from "App/components/Auth";
import { highnote } from "@highnote/server/src/sdk";
import {
  AUDIO_UPLOAD_SIZE_LIMIT_IN_BYTES,
  DropboxManagerBase,
} from "@highnote/server/src/core/shared-util";
import {
  AUDIO_ACCEPT_TYPES_LIST,
  COLLECTION_ID,
} from "@highnote/server/src/core/entities";
import { useToast } from "App/common/useToast";
import { DropboxChooserDialog } from "./chooser";
import {
  LIMIT_TYPE,
  usePlanLimitsContext,
} from "App/common/PlanLimits/usePlanLimits";
import { routePaths } from "App/modules/urls";
import { DropboxSpacePicker } from "./dropboxSpacePicker";
import { getUserDropboxJobsByFileIds } from "App/services/firebase/entities/helpers";
import { DEMO_ENTITY_PREFIX } from "@highnote/server/src/core/shared-util";

export const DropboxClone = () => {
  const history = useHistory();
  const { authLoading, user } = useAuth();
  const { addErrorMessage } = useToast();
  const { showPlanLimitsDialog } = usePlanLimitsContext();

  const [shouldRenderSpacePicker, setShouldRenderSpacePicker] = useState(false);
  const [chooserDialogOptions, setChooserDialogOptions] = useState<{
    fileId: string;
    fileName: string;
  }>(undefined);
  const [currentFileIds, setCurrentFileIds] = useState<string[]>([]);

  const fileId = new URLSearchParams(window.location.search).get("file_id");

  const handleInitialization = async () => {
    if (!fileId) {
      return;
    }
    try {
      const dropboxManager = new DropboxManagerBase({ fetcher: fetch });

      const token = await highnote.refreshDropboxToken();
      const metadata = await dropboxManager.getFileMetadata(token, fileId);
      // dropbox extension flow should prevent users landing here with a file that is too big,
      // but it is also possible that a user manually enters the URL with a file that is too big
      if (metadata.size > AUDIO_UPLOAD_SIZE_LIMIT_IN_BYTES) {
        showPlanLimitsDialog(LIMIT_TYPE.STORAGE);
        return;
      }
      // make sure the file is an audio file that we support
      if (!AUDIO_ACCEPT_TYPES_LIST.some((ext) => metadata.name.endsWith(ext))) {
        addErrorMessage("The file type is not supported.");
        history.replace(routePaths.library);
        return;
      }
      // ensure that the file has not been cloned previously.
      const existingJobs = await getUserDropboxJobsByFileIds({
        fileIds: [fileId],
        userId: user.id,
      });
      if (existingJobs.length > 0) {
        addErrorMessage("The file has already been cloned.");
        history.replace(routePaths.library);
        return;
      }

      const paths = metadata.path_display.split("/").filter(Boolean);
      const folderContents = await dropboxManager.listFolder(token, {
        path:
          paths.length > 1
            ? `/${paths.slice(0, paths.length - 1).join("/")}`
            : "",
        // we only care about files that are downloadable
        include_non_downloadable_files: false,
      });
      const validEntries = folderContents.entries.filter((entry) => {
        return (
          entry[".tag"] === "file" &&
          AUDIO_ACCEPT_TYPES_LIST.some((ext) => entry.name.endsWith(ext))
        );
      });
      // if the folder has more than one valid file, let the user choose more files
      // via DropboxChooserDialog component
      if (validEntries.length > 1) {
        setChooserDialogOptions({
          fileId: metadata.id,
          fileName: metadata.name,
        });
        return;
      }
      await handleChooserOnSubmit([fileId]);
    } catch (err) {
      Sentry.captureException(err);
      addErrorMessage("Something went wrong");
      console.error(`Failed to initiate file cloning: ${err.message || err}`);
      history.replace(routePaths.library);
    }
  };

  const createNewSpaceForCloning = async (
    spaceId: string,
    fileIds: string[],
  ) => {
    await highnote.createSpace({
      id: spaceId,
      data: { name: "New Space" },
    });
    await handleSpacePickerSubmit(spaceId, fileIds);
  };

  const handleChooserOnSubmit = async (fileIds: string[]) => {
    try {
      const spaceQuery = query(
        collection(highnote.__firebaseFirestore, COLLECTION_ID.SPACE),
        where("createdBy", "==", user.id),
      );
      const mySpaceCountSnapshot = await getCountFromServer(spaceQuery);
      const mySpaceCount = mySpaceCountSnapshot.data().count;
      // if the user has no spaces
      // 1) create a new space
      // 2) clone the files to the new space
      // 3) redirect the user to the new space
      if (mySpaceCount < 1) {
        await createNewSpaceForCloning(uuidv4(), fileIds);
        return;
      }
      // if the user has exactly one space
      // 1) fetch the space
      // 2) clone the files to the space
      // 3) redirect the user to the new space
      if (mySpaceCount === 1) {
        const mySpacesSnapshot = await getDocs(spaceQuery);
        const spaceId = mySpacesSnapshot.docs[0].id;
        // if the user has only one space and it is a demo space,
        // create a new space and clone the files to the new space
        if (!spaceId.startsWith(DEMO_ENTITY_PREFIX)) {
          await handleSpacePickerSubmit(spaceId, fileIds);
          return;
        }
        await createNewSpaceForCloning(uuidv4(), fileIds);
        return;
      }
      // if the user has more than one space, let the user pick a space
      // to clone the files to

      // close chooser dialog
      setChooserDialogOptions(undefined);
      setCurrentFileIds(fileIds);
      setShouldRenderSpacePicker(true);
    } catch (err) {
      Sentry.captureException(err);
      addErrorMessage(`Failed to clone files: ${err.message || err}`);
    }
  };

  const handleSpacePickerSubmit = async (
    spaceId: string,
    fileIds: string[],
  ) => {
    try {
      await highnote.cloneDropboxEntities({
        fileIds,
        spaceId,
      });
      history.push(`/space/${spaceId}`);
    } catch (err) {
      Sentry.captureException(err);
      addErrorMessage(`Failed to clone files: ${err.message || err}`);
    }
  };

  useEffect(() => {
    if (authLoading || !user) {
      return;
    }
    if (!authLoading && !user.dropboxAccountId) {
      const redirectTo = `${window.location.pathname}${window.location.search}`;
      sessionStorage.setItem(
        DropboxManagerBase.redirectToSessionStorageKey,
        redirectTo,
      );
      history.replace(routePaths.dropboxLogin);
      return;
    }
    handleInitialization();
  }, [authLoading, user?.dropboxAccountId]);

  if (shouldRenderSpacePicker) {
    return (
      <DropboxSpacePicker
        onCancel={() => {
          setCurrentFileIds([]);
          setShouldRenderSpacePicker(false);
        }}
        onSubmit={(spaceId: string) =>
          handleSpacePickerSubmit(spaceId, currentFileIds)
        }
      />
    );
  }

  if (chooserDialogOptions || !fileId) {
    return (
      <DropboxChooserDialog
        fileConfig={chooserDialogOptions}
        onSubmit={handleChooserOnSubmit}
      />
    );
  }

  return <LoadingScreen />;
};
