import "./styles.scss";
import React from "react";
import { useSpaceContext } from "App/common/useSpace";
import { useAuth } from "App/components/Auth";

import { useToast } from "App/common/useToast";
import { ReactComponent as CloseSVG } from "App/common/icons/close-circle.svg";
import { highnote } from "@highnote/server/src/sdk";
import {
  MAX_ACTIVE_SPACES_ERROR,
  PERMISSION,
} from "@highnote/server/src/core/shared-util";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import {
  LIMIT_TYPE,
  usePlanLimitsContext,
} from "App/common/PlanLimits/usePlanLimits";

export const SpaceArchivedBanner = () => {
  const { toasted } = useToast();
  const { space, spaceId } = useSpaceContext();
  const { isAllowed } = useAuth();
  const { showPlanLimitsDialog } = usePlanLimitsContext();

  const canUnarchive = isAllowed(PERMISSION.TO_ARCHIVE_SPACE, { space });

  if (!space) return null;

  return (
    <div className="highnote-space-archived-banner">
      <p>
        You are viewing {space.name}, an archived Space. The messages, tracks,
        and attachments are still browsable and&nbsp;downloadable.
      </p>

      {canUnarchive && (
        <Button
          size={BUTTON_SIZE.XSMALL}
          theme={BUTTON_THEME.PRIMARY}
          onClick={() => {
            toasted({
              promise: highnote
                .updateSpace({
                  id: spaceId,
                  data: { isArchived: false },
                })
                .catch((err) => {
                  if (err.message === MAX_ACTIVE_SPACES_ERROR) {
                    showPlanLimitsDialog(LIMIT_TYPE.SPACES);
                    return;
                  }
                  throw err;
                }),
              createMessage: `Unarchiving ${space.name}...`,
              errorMessage: `Could not unarchive ${space.name}. Please try again.`,
            });
          }}
        >
          <CloseSVG />
          <span>Unarchive</span>
        </Button>
      )}
    </div>
  );
};
