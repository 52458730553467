import { highnote } from "@highnote/server/sdk";
import { TextBlock } from "./TextBlock";
import { CustomBlock } from "./CustomBlock";
import { FileBlock } from "./FileBlock";
import { PollBlock } from "./PollBlock";
import { ReactComponent as VoiceIcon } from "App/common/icons/voice.svg";
import { ReactComponent as HeartIcon } from "App/common/icons/heart.svg";
import { ReactComponent as PollIcon } from "App/common/icons/poll.svg";
import { ReactComponent as TextIcon } from "App/common/icons/text.svg";
import React from "react";
import { PollReplyBlock } from "./PollReplyBlock";
import {
  Comment,
  FileBlockV2,
  PollBlockV2,
} from "@highnote/server/src/core/entities";

export type CommentBlockConfig<T> = {
  id: string;
  displayName: string;
  Component?: React.FC<{
    id?: string;
    replies?: Comment[];
    spaceId?: Id;
    timestamp?: number;
    createdBy?: string;
    createdByAlias?: string;
    isEditing?: boolean;
    data: T;
    onChange?: (block: T) => void;
    onSave?: (block: T) => void;
  }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Icon?: React.FC<any>;
  generateNew: () => T;
  isEditable: boolean;
  validate: (data: T) => string | undefined;
  inToolbar: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const COMMENT_BLOCKS: Record<string, CommentBlockConfig<any>> = {
  text: {
    id: "text",
    displayName: "Text",
    Component: TextBlock,
    Icon: TextIcon,
    generateNew: () => highnote.utils.generateTextBlock(),
    isEditable: true,
    inToolbar: true,
    validate: (block: TextBlock) => {
      if (typeof block.text !== "string" || !block.text.trim()) {
        return "Text must not be empty.";
      }
      return undefined;
    },
  } as CommentBlockConfig<TextBlock>,
  custom: {
    id: "custom",
    displayName: "Sticker",
    Component: CustomBlock,
    Icon: HeartIcon,
    generateNew: () =>
      highnote.utils.generateCustomBlock({ id: "heart-sticker" }),
    isEditable: false,
    inToolbar: true,
    validate: (block: CustomBlock) => {
      if (block.id !== "heart-sticker") {
        return "Invalid custom block id.";
      }
      return undefined;
    },
  } as CommentBlockConfig<CustomBlock>,
  "file-v2": {
    id: "file-v2",
    displayName: "Voice",
    Component: FileBlock,
    Icon: VoiceIcon,
    generateNew: () => highnote.utils.generateFileBlock(),
    isEditable: true,
    inToolbar: true,
    validate: (block: FileBlockV2) => {
      if (!block.file) {
        return "Valid file required.";
      }
      return undefined;
    },
  } as CommentBlockConfig<FileBlockV2>,
  "poll-v2": {
    id: "poll-v2",
    displayName: "Poll",
    Component: PollBlock,
    generateNew: () => highnote.utils.generatePollBlock(),
    Icon: PollIcon,
    isEditable: true,
    inToolbar: true,
    validate: (block: PollBlockV2) => {
      if (!block.options || block.options.length !== 2) {
        return "Two poll options required.";
      }
      if (block.options.find((o) => !o.text && !(o as FileBlockV2).file)) {
        return "Every poll option must have either text or a file attached";
      }
      return undefined;
    },
  } as CommentBlockConfig<PollBlockV2>,
  "poll-reply": {
    id: "poll-reply",
    displayName: "Poll Reply",
    Component: PollReplyBlock,
    generateNew: () => highnote.utils.generatePollReplyBlock(),
    Icon: PollIcon,
    isEditable: false,
    inToolbar: false,
    validate: (block: PollReplyBlock) => {
      if (block.option === undefined) {
        return "Every poll reply must specify a selected option.";
      }
      return undefined;
    },
  } as CommentBlockConfig<PollReplyBlock>,
};
