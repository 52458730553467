import React, { useEffect } from "react";
import { useHistory } from "react-router";
import {
  SUBSCRIPTION_INTERVAL,
  SUBSCRIPTION_PRICING,
  SUBSCRIPTION_TIER,
} from "@highnote/server/src/core/entities";
import { highnote } from "@highnote/server/src/sdk";
import LoadingScreen from "App/common/LoadingScreen/LoadingScreen";
import { THEME, ThemeProvider } from "App/common/ThemeProvider";
import { useToast } from "App/common/useToast";
import { useAuth } from "App/components/Auth";

const DEFAULT_REDIRECT_PATH = "/library";

/**
 * This component is used to redirect users to the external stripe checkout page.
 * The same logic is done in the Login page. This separate route just allows for a cleaner implemenation.
 * ENSURE TO ALSO UPDATE THE CODE IN Login.tsx if any changes here.
 */
export const Checkout = () => {
  const history = useHistory();
  const { user, authLoading } = useAuth();
  const { addErrorMessage } = useToast();
  const query = new URLSearchParams(location.search);
  const returnToQuery = query.get("returnTo");

  const subscription = query.get("subscription") || undefined;
  const coupon_code = query.get("coupon_code") || undefined;
  const frequency = query.get("frequency") || undefined;

  // Default 'frequencyKey' to monthly when frequency is used without a value
  // to avoid sticker shock on stripe checkout.
  let frequencyKey: SUBSCRIPTION_INTERVAL = SUBSCRIPTION_INTERVAL.MONTHLY;
  if (frequency && frequency.toLowerCase() === SUBSCRIPTION_INTERVAL.ANNUAL) {
    frequencyKey = SUBSCRIPTION_INTERVAL.ANNUAL;
  }

  useEffect(() => {
    let mounted = true;

    if (authLoading) return;

    const currentSub = subscription?.toUpperCase();

    if (
      mounted &&
      !!user &&
      subscription &&
      currentSub in SUBSCRIPTION_TIER &&
      currentSub !== SUBSCRIPTION_TIER.FREE
    ) {
      highnote
        .getCheckoutUrl({
          priceId:
            SUBSCRIPTION_PRICING[currentSub as SUBSCRIPTION_TIER][frequencyKey],
          source: DEFAULT_REDIRECT_PATH,
          coupon_code,
        })
        .then(({ checkoutUrl, subscriptionDirection }) => {
          if (checkoutUrl && typeof checkoutUrl === "string") {
            window.location.href = checkoutUrl;
            return;
          }
          if (subscriptionDirection < 0) {
            // When downgrading, checkoutUrl will be undefined,
            // but we still want to redirect them to somewhere useful
            history.replace("/settings/plans");
            return;
          }
          // When the subscription is the same (subscriptionDirection === 0),
          // OR checkoutUrl is undefined, redirect to the library
          history.replace(DEFAULT_REDIRECT_PATH);
        })
        .catch((e) => {
          console.error("[DEBUG] Failed to get checkout URL.", e);
          addErrorMessage(e.message, { title: "Failed to get checkout URL." });
          history.replace(DEFAULT_REDIRECT_PATH);
        });
    }

    const queryParams = query.toString();
    if (!user) {
      const returnTo =
        returnToQuery ??
        `${window.location.pathname}${queryParams ? `?${queryParams}` : ""}`;
      history.push(`/login?returnTo=${returnTo}`);
      return;
    }

    if (!queryParams) {
      // If the user is already logged in, redirect them to the library
      // as a worst case scenario.
      history.replace(DEFAULT_REDIRECT_PATH);
    }

    return () => {
      mounted = false;
    };
  }, [user, authLoading]);

  // This will always be a loading screen as this route is used to redirect
  // users to the external stripe checkout page, when they are logged in.
  return (
    <ThemeProvider theme={THEME.DARK}>
      <LoadingScreen />
    </ThemeProvider>
  );
};
