import "./UserAutocomplete.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Autocomplete, AutoCompleteTag } from "App/core/Autocomplete";
import { TextField } from "@mui/material";
import {
  ConnectedUserAvatar,
  ConnectedUserIdentifier,
  UserInfo,
} from "./UserAvatar/UserAvatar";
import { highnote } from "@highnote/server/src/sdk";
import { debounce } from "lodash";
import { UserEntity } from "@highnote/server/src/core/entities";
import { isInviteId } from "@highnote/server/src/core/shared-util";

const isEmailValid = (str: string) => {
  return !!str.match(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,253}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,253}[a-zA-Z0-9])?)*$/,
  );
};

export const UserAutocomplete = ({
  value,
  placeholder,
  onChange,
}: {
  value: UserEntity[];
  placeholder: string;
  onChange?: (value: UserEntity[]) => void;
}) => {
  const lastSearchRef = useRef<string>("");
  const [existingUsers, setExistingUsers] = useState<Partial<UserEntity>[]>([]);
  const [newUser, setNewUser] = useState<Partial<UserEntity>>();
  const [options, setOptions] = useState<Partial<UserEntity>[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isLoading) {
      setOptions([]);
      return;
    }

    setOptions(
      existingUsers.length > 0 ? existingUsers : newUser ? [newUser] : [],
    );
  }, [existingUsers, newUser, isLoading]);

  const fetchNewUsersByEmail = useMemo(
    () =>
      debounce(async () => {
        const email = lastSearchRef.current;
        let users: Partial<UserEntity>[];

        try {
          if (isEmailValid(email)) {
            users = await highnote.searchUsers({ email });
          }
        } catch (e) {
          // empty
        }

        if (lastSearchRef.current !== email) return;
        setExistingUsers(users || []);
        setLoading(false);
      }, 600),
    [],
  );

  const onInputChange = async (newInputValue: string) => {
    lastSearchRef.current = newInputValue;
    setExistingUsers([]);

    const email = newInputValue.trim().toLowerCase();

    if (!isEmailValid(email)) {
      setNewUser(undefined);
      setLoading(false);
      return;
    }

    const _newUser = email
      ? {
          id: `invite:${email}`,
          email: email,
        }
      : undefined;
    setNewUser(_newUser);

    setLoading(true);
    fetchNewUsersByEmail();
  };

  return (
    <Autocomplete
      className="highnote-user-autocomplete"
      multiple
      // autoSelect and autoHighlight allow Tab to automatically add a user chip before blurring the input
      autoSelect={true}
      autoHighlight={true}
      value={value}
      filterOptions={(x) => x}
      onChange={(event, newValue: UserEntity[]) => {
        onChange(newValue);
      }}
      onInputChange={(event, newInputValue: string) => {
        onInputChange(newInputValue);
      }}
      loading={isLoading}
      options={options}
      getOptionLabel={(option) => option.id}
      renderOption={(props, option) => {
        const accountId = option.primaryAccountId || option.id;
        const isInvite = isInviteId(option.id);

        return (
          <li {...props}>
            <UserInfo userId={accountId} alias={option.email}>
              {isInvite ? "Invite to Highnote" : ""}
            </UserInfo>
          </li>
        );
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const accountId = option.primaryAccountId || option.id;
          const props = getTagProps({ index });

          return (
            <AutoCompleteTag key={index} {...props}>
              <ConnectedUserAvatar userId={accountId} alias={option.email} />
              <ConnectedUserIdentifier
                userId={accountId}
                identifier={option.email}
              />
            </AutoCompleteTag>
          );
        })
      }
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={
            value.length === 0
              ? placeholder || "Enter email address"
              : undefined
          }
        />
      )}
    />
  );
};
