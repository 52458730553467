import React from "react";
import { Button, BUTTON_THEME } from "App/core/Button";
import { ReactComponent as CaretLeftSVG } from "App/common/icons/caret-left.svg";

export const WithBackButton = ({
  onBack,
  children,
}: {
  onBack: () => void;
  children: React.ReactNode;
}) => {
  return (
    <div className="WithBackButton">
      <Button
        className="WithBackButton-back"
        type="button"
        theme={BUTTON_THEME.PRIMARY}
        onClick={onBack}
      >
        <CaretLeftSVG />
      </Button>
      {children}
    </div>
  );
};
