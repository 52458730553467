const constants = {
  longMaxLength: 500,
  mediumMaxLength: 120,
  shortMaxLength: 50,
  cache: {
    volumeLevelKey: "highnote-volume-level",
    volumeMutedKey: "highnote-volume-muted",
  },
};

export default constants;
