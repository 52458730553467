import { v4 as uuidv4 } from "uuid";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";

type SystemEditingContextProps = {
  setSystemEditing: (key: string, value: boolean) => void;
  continueDespiteUnsavedChanges: () => boolean;
};

const SystemEditingContext = createContext<SystemEditingContextProps>({
  setSystemEditing: () => {},
  continueDespiteUnsavedChanges: () => true,
});

export const SystemEditingContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const history = useHistory();
  const lastPathnameRef = useRef<string>(location.pathname);
  const editState = useRef<Record<string, boolean>>({});
  const [updateTrigger, setUpdateTrigger] = useState(uuidv4());

  const isEditing = useCallback(() => {
    return Object.keys(editState.current).some(
      (key) => !!editState.current[key],
    );
  }, []);

  const continueDespiteUnsavedChanges = useCallback(() => {
    if (!isEditing()) return true;
    const confirmed = window.confirm(
      "Changes you made may not be saved. Continue?",
    );

    if (!confirmed) return false;
    editState.current = {};
    return true;
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const unblock = history.block((location: any) => {
      const isNewPath = lastPathnameRef.current !== location.pathname;

      if (!isNewPath) {
        return undefined;
      }

      const shouldContinue = continueDespiteUnsavedChanges();
      if (!shouldContinue) return false;

      unblock();
      setUpdateTrigger(uuidv4());
      editState.current = {};
      return undefined;
    });

    const handleUnload = (e: BeforeUnloadEvent) => {
      if (!isEditing()) return;

      // Show system prompt
      e.preventDefault();
      e.returnValue = "";
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      unblock();
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [history, updateTrigger]);

  const setSystemEditing = useCallback((key: string, value: boolean) => {
    editState.current[key] = value;
  }, []);

  const value = useMemo(
    () => ({
      setSystemEditing,
      continueDespiteUnsavedChanges,
    }),
    [setSystemEditing, continueDespiteUnsavedChanges],
  );

  return (
    <SystemEditingContext.Provider value={value}>
      {children}
    </SystemEditingContext.Provider>
  );
};

export const useSystemEditing = () => useContext(SystemEditingContext);
