import "./Library.scss";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { AuthRedirectRequired } from "App/modules/Auth/AuthRedirectRequired";
import {
  AUDIO_ACCEPT_TYPES,
  COLLECTION_ID,
  EVENT_ID,
  Track,
} from "@highnote/server/src/core/entities";
import { useSegmentContext } from "App/modules/useSegment";
import { EntityLibrary } from "App/components/EntityLibrary/EntityLibrary";
import { FileDrop } from "App/common/FileDrop";
import { PlanLimitCallout } from "App/components/Plans/PlanLimitCallout";
import { NewEntityMenu } from "App/common/NewEntityMenu/NewEntityMenu";
import { UploadTrackButton } from "App/components/UploadTrackButton";
import { ReactComponent as FilterSlidersSVG } from "App/common/icons/filter-sliders.svg";
import { ReactComponent as UploadSVG } from "App/common/icons/upload.svg";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { UPLOAD_GROUP, useFiles } from "App/components/useFiles";
import { highnote } from "@highnote/server/src/sdk";
import { stripExtension } from "@highnote/server/src/core/shared-util";
import { Select } from "App/common/Select";
import { MenuItem } from "App/common/Menu";
import { EntityRowConfig } from "App/components/EntityTable";
import { useAuth } from "App/components/Auth";

import { sortRowByAlphabetical, sortRowByCreatedAt } from "App/components/util";
import { EmptyTable } from "App/components/EntityTable/EmptyTable";
import {
  GlobalAudioPlayer,
  GlobalAudioTrackPreview,
} from "App/components/GlobalAudioPlayer/GlobalAudioPlayer";
import { useHistory, useLocation } from "react-router";
import { useTrackEditor } from "App/components/useTrackEditor";
import { useGlobalAudioPlayer } from "App/components/GlobalAudioPlayer";
import { FILTERS, FILTER_TYPE, SORT_TYPE, TAB_ID } from "./config";
import { ENTITY_TYPE } from "App/components/EntityTable/config";
import {
  LibraryTracksContextProvider,
  useLibraryTracks,
} from "App/components/useEntities/useLibraryTracks";
import { Navigation } from "App/components/Navigation";
import {
  LibrarySpacesContextProvider,
  useLibrarySpaces,
} from "App/components/useEntities/useSpaces";
import { StatusIndicator } from "App/common/StatusIndicator";
import { TabbedView } from "App/core/TabbedView";
import { LoadingSpinner } from "App/common/icons/LoadingSpinner";
import { useTopLevelSpaces } from "App/components/useEntities/useTopLevelSpaces";
import { TableRowVariant } from "App/common/BaseTable";
import {
  useFormatSpaceToBaseEntityRow,
  useFormatTrackToBaseEntityRow,
} from "App/components/EntityTable/ExpandableSpace/ExpandableSpaceRow";

const LibraryPlayer = () => {
  const history = useHistory();
  const { openTrackEditor } = useTrackEditor();
  const { nowPlaying } = useGlobalAudioPlayer();
  const track = nowPlaying.track;

  return (
    <GlobalAudioPlayer
      preview={
        <GlobalAudioTrackPreview
          track={track}
          onClick={() => {
            if (!track) return;
            if (track.spaceId) {
              history.push(`/space/${track.spaceId}/${track.id}`);
            } else {
              openTrackEditor({ track });
            }
          }}
        />
      }
    />
  );
};

const EmptyLibrary = () => <EmptyTable action="Drop Files or Click New" />;

type LoadHelperProps = {
  isMaxedOut: boolean;
  loading: boolean;
  loadMore: () => void;
};

const LoadHelper = ({ isMaxedOut, loading, loadMore }: LoadHelperProps) => {
  const showHelper = !loading && isMaxedOut;

  return (
    <>
      <span className="highnote-load-helper">
        {showHelper && (
          <>
            Some results hidden to improve load time.{" "}
            <span onClick={loadMore}>Load more</span>
          </>
        )}
      </span>

      {showHelper && (
        <div className="highnote-load-helper-button">
          <Button
            theme={BUTTON_THEME.PRIMARY}
            size={BUTTON_SIZE.XSMALL}
            onClick={loadMore}
          >
            Load more
          </Button>
        </div>
      )}
    </>
  );
};

type ActiveTabId = {
  prev: TAB_ID | undefined;
  current: TAB_ID;
};

type TabContentProps = {
  spaceRows: EntityRowConfig[];
  trackRows: EntityRowConfig[];
  tableRowVariant?: TableRowVariant;
  onChange?: (rows: EntityRowConfig[]) => void;
  sortFn?: (a: EntityRowConfig, b: EntityRowConfig) => number;
  filterFn: (row: EntityRowConfig) => boolean;
};

type AllTabProps = Pick<TabContentProps, "sortFn" | "filterFn" | "onChange"> & {
  // Used for the Sort Select Options Dropdown
  selectSortOptionsConfig?: {
    sortType: SORT_TYPE;
    setSortType: React.Dispatch<React.SetStateAction<SORT_TYPE>>;
  };
};

const TabContent = ({
  spaceRows,
  trackRows,
  tableRowVariant,
  onChange,
  sortFn,
  filterFn,
}: TabContentProps) => {
  return (
    <EntityLibrary
      hideBreadcrumbs
      sort={sortFn}
      spaceRows={spaceRows}
      trackRows={trackRows}
      filter={filterFn}
      onChange={onChange}
      EmptyComponent={EmptyLibrary}
      tableRowVariant={tableRowVariant}
    />
  );
};

const TableInfo = ({
  rowsLength,
  children,
  ...loadHelperProps
}: {
  rowsLength: number;
  children?: React.ReactNode;
} & Omit<LoadHelperProps, "currentTab">) => {
  return (
    <div className="table-info">
      <LoadHelper {...loadHelperProps} />
      <span className="row-count">
        {rowsLength} Results{" "}
        <StatusIndicator isLoading={loadHelperProps.loading} />
      </span>
      {children}
    </div>
  );
};

const SelectSortOptions = ({
  sortType,
  setSortType,
}: {
  sortType: SORT_TYPE;
  setSortType: React.Dispatch<React.SetStateAction<SORT_TYPE>>;
}) => {
  return (
    <span className="sort-select-container">
      <Select
        className="sort-select"
        MenuProps={{
          className: "highnote-sort-menu",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }}
        value={sortType}
        size="small"
        onChange={(e) => setSortType(e.target.value as SORT_TYPE)}
      >
        {[SORT_TYPE.ALPHABETICAL, SORT_TYPE.CREATED_AT].map((type) => {
          return (
            <MenuItem key={`sort-${type}`} value={type}>
              <span className="title">{type}</span>
            </MenuItem>
          );
        })}
      </Select>
    </span>
  );
};

const HomeTab = ({
  ...helperFunctions
}: Omit<AllTabProps, "selectSortOptionsConfig">) => {
  const {
    topLevelSpaces,
    topLevelSpacesLoading,
    totalLimitTopLevelSpaces,
    loadMoreTopLevelSpaces,
  } = useTopLevelSpaces();

  const { formatSpaceToBaseEntityRow } = useFormatSpaceToBaseEntityRow();

  const spaceRows = useMemo(() => {
    return topLevelSpaces.map((topLevelSpace) =>
      formatSpaceToBaseEntityRow({
        space: topLevelSpace,
        options: {
          tableRowVariant: "expanded-row",
        },
      }),
    );
  }, [topLevelSpaces]);

  return (
    <>
      <TableInfo
        isMaxedOut={topLevelSpaces.length === totalLimitTopLevelSpaces}
        loadMore={loadMoreTopLevelSpaces}
        loading={topLevelSpacesLoading}
        rowsLength={spaceRows.length}
      />
      <TabContent
        spaceRows={spaceRows}
        trackRows={[]}
        tableRowVariant="expanded-row"
        {...helperFunctions}
      />
    </>
  );
};

const SpacesTab = ({
  selectSortOptionsConfig,
  ...helperFunctions
}: AllTabProps) => {
  const {
    librarySpaces,
    librarySpacesLoading,
    loadMoreLibrarySpaces,
    totalLimitLibrarySpaces,
  } = useLibrarySpaces();

  const { formatSpaceToBaseEntityRow } = useFormatSpaceToBaseEntityRow();

  const spaceRows = useMemo(() => {
    return librarySpaces.map((librarySpace) =>
      formatSpaceToBaseEntityRow({
        space: librarySpace,
        options: {
          tableRowVariant: "no-nesting",
          hasPlayableTracks: false,
        },
      }),
    );
  }, [librarySpaces]);

  return (
    <>
      <TableInfo
        isMaxedOut={librarySpaces.length === totalLimitLibrarySpaces}
        loadMore={loadMoreLibrarySpaces}
        loading={librarySpacesLoading}
        rowsLength={spaceRows.length}
      >
        <SelectSortOptions {...selectSortOptionsConfig} />
      </TableInfo>
      <TabContent
        spaceRows={spaceRows}
        trackRows={[]}
        tableRowVariant="no-nesting"
        {...helperFunctions}
      />
    </>
  );
};

const TracksTab = ({
  selectSortOptionsConfig,
  ...helperFunctions
}: AllTabProps) => {
  const {
    libraryTracks,
    libraryTracksLoading,
    loadMoreLibraryTracks,
    totalLimitLibraryTracks,
  } = useLibraryTracks();

  const { formatTrackToBaseEntityRow } = useFormatTrackToBaseEntityRow();

  const trackRows = useMemo(() => {
    return libraryTracks.map((libraryTrack) =>
      formatTrackToBaseEntityRow({
        track: libraryTrack,
        options: {
          hasPlayableTracks: true,
          tableRowVariant: "no-nesting",
        },
      }),
    );
  }, [libraryTracks]);

  return (
    <>
      <TableInfo
        isMaxedOut={libraryTracks.length === totalLimitLibraryTracks}
        loadMore={loadMoreLibraryTracks}
        loading={libraryTracksLoading}
        rowsLength={trackRows.length}
      >
        <SelectSortOptions {...selectSortOptionsConfig} />
      </TableInfo>
      <TabContent
        trackRows={trackRows}
        spaceRows={[]}
        tableRowVariant="no-nesting"
        {...helperFunctions}
      />
    </>
  );
};

const LibraryUI = () => {
  const { pathname } = useLocation();
  const { getUploadCache, uploadFiles, removeUpload, getUploads } = useFiles();
  const dragRef = useRef<HTMLDivElement>();
  const [activeTabId, setActiveTabId] = useState<ActiveTabId>({
    prev: undefined,
    current: TAB_ID.HOME,
  });
  const subFilters = FILTERS[activeTabId.current].subFilters || [];
  const [activeSubFilters, setActiveSubFilters] = useState<FILTER_TYPE[]>([]);
  const inactiveSubFilters = activeSubFilters.length
    ? activeSubFilters[activeSubFilters.length - 1]?.subFilters || []
    : subFilters;
  const [sortType, setSortType] = useState<SORT_TYPE>(SORT_TYPE.CREATED_AT);
  const { user } = useAuth();
  const uploadCache = getUploadCache(UPLOAD_GROUP.TRACKS_BY_SPACE, null);
  const uploads = getUploads({ cache: uploadCache }) || [];
  const isUploading = uploads.length && uploads.some((upload) => !upload.error);

  const { setQueue } = useGlobalAudioPlayer();

  const setTrackQueue = (rows: EntityRowConfig[]) => {
    if (activeTabId.current === TAB_ID.TRACKS) {
      const tracksQueue = rows.reduce((acc, curr) => {
        if (curr.type === ENTITY_TYPE.TRACK) {
          acc.push(curr.entity as Track);
        }
        return acc;
      }, [] as Track[]);

      setQueue({
        type: "SET",
        _tracks: tracksQueue,
        currentPageId: pathname,
      });
    }
  };

  useEffect(() => {
    setActiveSubFilters([]);
  }, [activeTabId.current]);

  const tabs = Object.entries(FILTERS).map(
    ([id, filter]: [TAB_ID, FILTER_TYPE]) => ({
      id,
      name: filter.name,
      onClick: () =>
        setActiveTabId((prev) => ({ prev: prev.current, current: id })),
    }),
  );

  const createFilterButton = ({
    filter,
    index,
    active,
  }: {
    filter: FILTER_TYPE;
    index: number;
    active: boolean;
  }) => (
    <Button
      key={`filter-${filter.name}`}
      data-active={active}
      theme={BUTTON_THEME.SECONDARY}
      onClick={() =>
        setActiveSubFilters(
          active
            ? activeSubFilters.slice(0, index)
            : [...activeSubFilters, filter],
        )
      }
    >
      {filter.name}
    </Button>
  );

  const activeFilterButtons = activeSubFilters.map((filter, index) =>
    createFilterButton({ filter, index, active: true }),
  );
  const inactiveFilterButtons = (inactiveSubFilters || []).map(
    (filter, index) => createFilterButton({ filter, index, active: false }),
  );

  const filterFn = useCallback(
    (row: EntityRowConfig) => {
      // Must pass the predicate for the tab
      let passesAll = FILTERS[activeTabId.current].predicate(row, user);
      // and all subfilters
      activeSubFilters.forEach(
        ({ predicate, ignoreRootPredicate }) =>
          (passesAll = ignoreRootPredicate
            ? predicate(row, user)
            : passesAll && predicate(row, user)),
      );
      return passesAll;
    },
    [activeTabId.current, activeSubFilters, user],
  );

  const sortFn =
    activeTabId.current !== TAB_ID.HOME
      ? sortType === SORT_TYPE.ALPHABETICAL
        ? sortRowByAlphabetical
        : sortRowByCreatedAt
      : undefined;

  const tabProps = {
    sortFn,
    filterFn,
    onChange: setTrackQueue,
  };

  return (
    <>
      <div className="inner" ref={dragRef}>
        <FileDrop
          dragRef={dragRef}
          onDrop={async (files: File[]) => {
            if (!files) {
              return;
            }
            const trackFiles = files.filter((file) =>
              AUDIO_ACCEPT_TYPES.includes(file.type),
            );
            if (trackFiles.length > 0) {
              const uploadPayloads = files.map((file) => {
                return {
                  cache: getUploadCache(UPLOAD_GROUP.TRACKS_BY_SPACE, null),
                  file,
                };
              });
              await uploadFiles({
                payloads: uploadPayloads,
                onSuccess: async (_, fileEntity) => {
                  await highnote.createTrack({
                    id: uuidv4(),
                    data: {
                      title: stripExtension(fileEntity.fileName),
                      versionFilesV2: [fileEntity.id],
                    },
                  });

                  removeUpload(fileEntity.id);
                },
              });
            }
          }}
          instruction={"Upload files to create tracks by dropping them here"}
        />

        <section className="header">
          <Navigation showIndent hideBackButton>
            <h1>Library</h1>
            <div className="library-right-controls">
              <NewEntityMenu showEntityTypes={[COLLECTION_ID.SPACE]} />

              <UploadTrackButton>
                <Button size={BUTTON_SIZE.SMALL} theme={BUTTON_THEME.CTA}>
                  {isUploading ? <LoadingSpinner /> : <UploadSVG />}
                  Upload
                </Button>
              </UploadTrackButton>
            </div>
          </Navigation>
        </section>

        <div className="callout-container">
          <PlanLimitCallout />
        </div>

        <div className="tabs">
          <TabbedView
            activeTabId={activeTabId.current || TAB_ID.HOME}
            tabs={tabs}
          />
        </div>

        <div className="filters">
          {(!!activeFilterButtons.length || !!inactiveFilterButtons.length) && (
            <FilterSlidersSVG />
          )}
          {activeFilterButtons}
          {inactiveFilterButtons}
        </div>

        {activeTabId.current === TAB_ID.SPACES ? (
          <LibrarySpacesContextProvider>
            <SpacesTab
              {...tabProps}
              selectSortOptionsConfig={{
                sortType,
                setSortType,
              }}
            />
          </LibrarySpacesContextProvider>
        ) : activeTabId.current === TAB_ID.TRACKS ? (
          <LibraryTracksContextProvider>
            <TracksTab
              {...tabProps}
              selectSortOptionsConfig={{
                sortType,
                setSortType,
              }}
            />
          </LibraryTracksContextProvider>
        ) : (
          <HomeTab {...tabProps} />
        )}
      </div>

      <LibraryPlayer />
    </>
  );
};

export const Library = () => {
  const { isActive } = useGlobalAudioPlayer();
  const { trackEvent } = useSegmentContext();

  useEffect(() => {
    trackEvent(EVENT_ID.ENTERED_LIBRARY);

    return () => {
      trackEvent(EVENT_ID.EXITED_LIBRARY);
    };
  }, []);

  return (
    <AuthRedirectRequired>
      <div
        className="highnote-library"
        data-cypress-id="highnote-library"
        data-has-active-audio-player={isActive}
      >
        <LibraryUI />
      </div>
    </AuthRedirectRequired>
  );
};
