import React from "react";

import "./EntitySelection.scss";
import { BUTTON_SIZE, BUTTON_THEME, Button } from "App/core/Button";
import {
  sanitizeSelections,
  useEntitiesSelection,
} from "../useEntitiesSelection";
import { ReactComponent as MoveSVG } from "App/common/icons/collection-move.svg";
import { ReactComponent as CaretDownSVG } from "App/common/icons-v2/carat-down.svg";
import { ReactComponent as DeleteSVG } from "App/common/icons/trash.svg";
import { Actions } from "App/common/Actions";
import { useHighnote } from "App/common/useHighnote";
import { useConfirmation } from "App/common/useConfirmation";
import { useMoveSelectionsToTarget } from "../useEntities/useMoveSelectionsToTarget";

export const EntitySelection = () => {
  const {
    toggleSelectionToolbar,
    resetSelection,
    isSelectionVisible,
    selectedEntities,
  } = useEntitiesSelection();
  const { confirm, renderConfirmation } = useConfirmation();
  const { deleteOrRemoveSelections } = useHighnote();
  const { openMoveSelectionsToTarget } = useMoveSelectionsToTarget();

  const deleteSelections = async () => {
    await confirm({
      title: `Delete ${Object.keys(selectedEntities).length} items?`,
      body: "All items and their children will be permanently deleted or removed. This cannot be undone.",
    });
    await deleteOrRemoveSelections({
      entities: sanitizeSelections(selectedEntities),
    });
    resetSelection();
  };

  const entitiesLength = Object.keys(selectedEntities).length;

  return (
    <div className="highnote-entity-selection">
      {renderConfirmation}
      {isSelectionVisible && (
        <Actions
          actions={[
            {
              name: "Move to...",
              icon: <MoveSVG />,
              onClick: () => {
                openMoveSelectionsToTarget({
                  selectedEntities,
                  onSuccess: resetSelection,
                });
              },
            },
            {
              name: "Delete",
              icon: <DeleteSVG />,
              onClick: deleteSelections,
              warn: true,
            },
          ]}
          triggerLabel={
            <>
              {entitiesLength} items <CaretDownSVG />
            </>
          }
          triggerProps={{
            disabled: entitiesLength === 0,
            size: BUTTON_SIZE.XSMALL,
            theme: BUTTON_THEME.CTA,
          }}
        />
      )}
      <Button
        size={BUTTON_SIZE.XSMALL}
        theme={BUTTON_THEME.PRIMARY}
        onClick={() =>
          isSelectionVisible ? resetSelection() : toggleSelectionToolbar()
        }
      >
        {isSelectionVisible ? "Cancel" : "Select"}
      </Button>
    </div>
  );
};
