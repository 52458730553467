import "./NewCommentButton.scss";
import { v4 as uuidv4 } from "uuid";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CommentBlockConfig, COMMENT_BLOCKS } from "App/common/Comment/blocks";
import { Card, useCommentCards } from "App/common/useCommentCards";
import { ReactComponent as PlusSVG } from "App/common/icons/plus.svg";
import { ReactComponent as CaretDownSVG } from "App/common/icons/caret-down.svg";
import { daw } from "@highnote/daw/src";
import { useSpaceContext } from "App/common/useSpace";
import {
  formatDuration,
  PERMISSION,
} from "@highnote/server/src/core/shared-util";
import { getAuthId, useAuth } from "App/components/Auth";

import { useSpaceCommentsContext } from "App/common/useSpaceComments";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { useTrack } from "App/components/useEntities/useTrack";
import { PermissionTooltip } from "App/components/PermissionTooltip";
import { Stats, StickyDraggable } from "App/core/StickyDraggable";
import { useViewport } from "App/common/useViewport";
import { Menu, MenuItem } from "App/common/Menu";
import { useFocusOnCard } from "App/common/useFocusedCard";
import { useDAWTrackVersionId } from "./SpaceTrack";
import { useGlobalAudioPlayer } from "App/components/GlobalAudioPlayer";
import { JOIN_ENTITY_TRIGGER } from "App/components/Auth/util";
import { useSeekRef } from "App/routes/Main/Space/Carousel/useSeekRef";

const useNewCard = () => {
  const { focusOnCard } = useFocusOnCard();
  const { orderedCards } = useCommentCards();
  const { track, currentTrackVersion } = useTrack();
  const { spaceId, space } = useSpaceContext();
  const { joinEntity } = useAuth();
  const { addSpaceComment } = useSpaceCommentsContext();
  const orderedCardsRef = useRef<Card[]>(orderedCards);
  const seekRef = useSeekRef();

  useEffect(() => {
    orderedCardsRef.current = orderedCards;
  }, [orderedCards]);

  const addCard = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (config: CommentBlockConfig<any>, timestamp?: number) => {
      const generateCardAt = (timestamp: number) => {
        const cardId = uuidv4();
        return {
          id: cardId,
          draft: true,
          hidden: false,
          timestamp,
          createdAt: Date.now(),
          comment: {
            id: cardId,
            createdAt: Date.now(),
            timestamp,
            blocks: [config.generateNew()],
            spaceId,
            trackId: track.id,
            trackVersionIds: [currentTrackVersion.id],
            createdBy: getAuthId(),
          },
        };
      };

      const newCard = generateCardAt(
        timestamp ?? seekRef.current.getCurrentTime(),
      );

      if (config.id === "custom") {
        await addSpaceComment({
          id: newCard.id,
          data: newCard.comment,
        });

        joinEntity({
          entity: space,
          entityType: "Space",
          trigger: JOIN_ENTITY_TRIGGER.COMMENT,
        });
        return;
      }

      focusOnCard(newCard.id, newCard, { edit: true });
    },
    [track?.id, currentTrackVersion?.id, space, joinEntity, addSpaceComment],
  );

  return {
    addCard,
  };
};

const NewCommentButtonDraggable = ({
  config,
  targetEl,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: CommentBlockConfig<any>;
  targetEl: HTMLDivElement;
}) => {
  const { addCard } = useNewCard();
  const { space } = useSpaceContext();
  const { isAllowed } = useAuth();
  const canCommentInSpace = isAllowed(PERMISSION.TO_COMMENT_IN_SPACE, {
    space,
  });
  const { Icon, displayName } = config;
  const [dragTime, setDragTime] = useState<number>();
  const { seek, pause } = useGlobalAudioPlayer();
  const dawTrackId = useDAWTrackVersionId();

  const onStop = ({ isOnTarget }: Stats) => {
    if (isOnTarget) addCard(config, dragTime);
    setDragTime(undefined);
  };

  const onDrag = ({ xPercent, isOnTarget }: Stats) => {
    if (!isOnTarget) return;
    if (daw.state.isPlaying) pause();
    const dawTrack = daw.getTrack(dawTrackId);
    const timestamp = dawTrack.duration * xPercent;
    seek(timestamp);
    setDragTime(timestamp);
  };

  const onClick = () => {
    addCard(config);
  };

  return (
    <StickyDraggable
      targetEl={targetEl}
      onStop={onStop}
      onDrag={onDrag}
      onStart={onDrag}
      onClick={onClick}
      disabled={!canCommentInSpace}
    >
      <PermissionTooltip hasPermission={canCommentInSpace}>
        <Button
          className="highnote-new-comment-button"
          disabled={!canCommentInSpace}
          size={BUTTON_SIZE.SMALL}
          theme={BUTTON_THEME.SECONDARY}
        >
          <Icon />
          <span className="label">{displayName}</span>
          {dragTime && (
            <span className="timestamp">{formatDuration(dragTime)}</span>
          )}
        </Button>
      </PermissionTooltip>
    </StickyDraggable>
  );
};

const NewCommentButtonSelect = () => {
  const { addCard } = useNewCard();
  const { space } = useSpaceContext();
  const { isAllowed } = useAuth();
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const canCommentInSpace = isAllowed(PERMISSION.TO_COMMENT_IN_SPACE, {
    space,
  });
  const anchorRef = useRef<HTMLButtonElement>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const addComment = (block: CommentBlockConfig<any>) => {
    addCard(block);
    setMenuOpen(false);
  };

  return (
    <>
      <PermissionTooltip hasPermission={canCommentInSpace}>
        <Button
          ref={anchorRef}
          className="hignote-new-comment-button-select"
          disabled={!canCommentInSpace || isMenuOpen}
          onClick={() => {
            setMenuOpen(true);
          }}
        >
          <PlusSVG />
          <span>Add Comment</span>
          <CaretDownSVG />
        </Button>
      </PermissionTooltip>
      <Menu
        onClose={() => setMenuOpen(false)}
        className="hignote-new-comment-button-select-menu"
        anchorEl={anchorRef.current}
        open={isMenuOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {Object.values(COMMENT_BLOCKS).map((block) => {
          if (!block.inToolbar) return null;

          return (
            <MenuItem
              key={block.id}
              value={block.id}
              onClick={() => addComment(block)}
            >
              <block.Icon />
              <span className="label">{block.displayName}</span>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export const NewCommentButtons = ({
  targetEl,
}: {
  targetEl: HTMLDivElement;
}) => {
  const { isAllowed, isPublicView } = useAuth();
  const { vw } = useViewport();
  const { track } = useTrack();
  const { space } = useSpaceContext();
  const isMobile = vw <= 768;
  const hideButtons =
    isPublicView(space) &&
    !isAllowed(PERMISSION.TO_COMMENT_IN_SPACE, { space });

  const memoizedRender = useMemo(() => {
    if (!track) return null;

    if (hideButtons) {
      return null;
    }

    if (isMobile) {
      return (
        <div className="highnote-new-comment-buttons">
          <NewCommentButtonSelect />
        </div>
      );
    }

    return (
      <div className="highnote-new-comment-buttons">
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {Object.values(COMMENT_BLOCKS).map((block: any) => {
          if (!block.inToolbar) return null;
          return (
            <NewCommentButtonDraggable
              targetEl={targetEl}
              key={block.id}
              config={block}
            />
          );
        })}
      </div>
    );
  }, [!!track, hideButtons, isMobile, targetEl]);

  return memoizedRender;
};
