import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Intercom, {
  onUnreadCountChange,
  shutdown,
} from "@intercom/messenger-js-sdk";
import { useAuth } from "App/components/Auth";
import { useViewport } from "App/common/useViewport";

type IntercomContextType = {
  isGlobalAudioPlayerActive: boolean;
  setIsGlobalAudioPlayerActive: (isActive: boolean) => void;
  showLauncher: boolean;
  setShowLauncher: (show: boolean) => void;
  unreadMessageCount: number;
};

const IntercomContext = createContext({} as IntercomContextType);

const DEFAULT_INTERCOM_BUTTON_BOTTOM = 20;
const DEFAULT_INTERCOM_APP_ID = "gtxv83ig";
export const INTERCOM_CUSTOM_LAUNCHER_SELECTOR =
  "highnote_custom_intercom_launcher";
const USE_CUSTOM_LAUNCHER = true;

export const IntercomContextProvider = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const { isMobile } = useViewport();

  const { user, userMetadata } = useAuth();
  const [isGlobalAudioPlayerActive, setIsGlobalAudioPlayerActive] =
    useState(false);
  const [showLauncher, setShowLauncher] = useState(true);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);

  // This state is used for Default Launcher, and does not effect the custom launcher
  const [launcherVerticalPadding, setLauncherVerticalPadding] = useState(
    DEFAULT_INTERCOM_BUTTON_BOTTOM,
  );

  const isIntercomInitialized = useRef(false);

  useEffect(() => {
    if (user && userMetadata?.HMACUserId && !isIntercomInitialized.current) {
      Intercom({
        app_id: DEFAULT_INTERCOM_APP_ID,
        email: user.email,
        user_hash: userMetadata.HMACUserId,
        user_id: user.id,
        name: user.name,
        created_at: user.createdAt,
        vertical_padding: launcherVerticalPadding, // This only effects the desktop launcher positioning, not mobile.
        // custom launcher settings below, override any default launcher settings/styling
        custom_launcher_selector: USE_CUSTOM_LAUNCHER
          ? `#${INTERCOM_CUSTOM_LAUNCHER_SELECTOR}`
          : undefined,
        hide_default_launcher: USE_CUSTOM_LAUNCHER
          ? true
          : !user || !showLauncher,
      });
      isIntercomInitialized.current = true;

      // Set up unread message listener
      onUnreadCountChange(setUnreadMessageCount);
    }

    return () => {
      if (isIntercomInitialized.current) {
        // Clean up the listener when component unmounts or user changes
        shutdown();
        isIntercomInitialized.current = false;
      }
      setUnreadMessageCount(0);
    };
  }, [user, userMetadata?.HMACUserId, showLauncher, launcherVerticalPadding]);

  // Please note: This useEffect only works when we use the default Intercom Launcher
  // and NOT the custom launcher.
  // *****
  // *** Current Status: Custom Launcher Being Used ***
  // *****
  // The 2 keys that indicate a custom launcher being used:
  // 1. 'custom_launcher_selecter' - string
  // 2. 'hide_default_launcher' - boolean
  useEffect(() => {
    if (!USE_CUSTOM_LAUNCHER) {
      const getIntercomButtonElement = () => {
        // Default Intercom bubble
        const intercomNoiFrameElement = document.querySelector(
          ".intercom-lightweight-app > .intercom-lightweight-app-launcher.intercom-launcher",
        ) as HTMLElement;

        // When the chat bubble is clicked, another element is created
        // TODO: Check if we can create our own element #id to avoid these
        // generated class names
        const intercomiFrameMobileElement = document.querySelector(
          ".intercom-with-namespace-52k34s.edrs4yi0",
        ) as HTMLElement;

        // When the chat bubble is clicked, another element is created
        // TODO: Check if we can create our own element #id to avoid these
        // generated class names
        const intercomiFrameDesktopElement = document.querySelector(
          ".intercom-launcher.intercom-zi3hra.e11rlguj4",
        ) as HTMLElement;

        return (
          intercomNoiFrameElement ||
          intercomiFrameDesktopElement ||
          intercomiFrameMobileElement
        );
      };

      /**
       * Custom positioning of the Intercom chat bubble to avoid overlapping with the global audio player,
       * mobile footer, and track tabs.
       */
      const updateIntercomButtonPosition = () => {
        const intercomButtonElement = getIntercomButtonElement();

        if (!intercomButtonElement) {
          return;
        }

        if (!user || !showLauncher) {
          intercomButtonElement.style.display = "none";
          return;
        }

        // Query the other elements for their heights
        const mobileFooterHeight =
          document.querySelector(".highnote-bottom-nav")?.clientHeight ?? 0;
        const globalAudioPlayerHeight =
          document.querySelector(".highnote-global-audio-player")
            ?.clientHeight ?? 0;
        const trackTabsHeight =
          document.querySelector(".highnote-track-version-tabs")
            ?.clientHeight ?? 0;

        const baseHeight = mobileFooterHeight + DEFAULT_INTERCOM_BUTTON_BOTTOM;
        const activeHeight =
          baseHeight + globalAudioPlayerHeight + trackTabsHeight;

        if (isGlobalAudioPlayerActive) {
          if (isMobile) {
            // Mobile Intercom button doesn't effect the vertical_padding in the Intercom SDK
            intercomButtonElement.style.bottom = `${activeHeight}px`;
          } else {
            // Desktop Intercom button does effect the vertical_padding in the Intercom SDK
            setLauncherVerticalPadding(activeHeight);
          }
        } else {
          if (isMobile) {
            // Mobile Intercom button doesn't effect the vertical_padding in the Intercom SDK
            intercomButtonElement.style.bottom = `${baseHeight}px`;
          } else {
            // Desktop Intercom button does effect the vertical_padding in the Intercom SDK
            setLauncherVerticalPadding(baseHeight);
          }
        }
      };

      updateIntercomButtonPosition();

      // Listen for DOM changes if Intercom re-renders
      const observer = new MutationObserver(updateIntercomButtonPosition);
      observer.observe(document.body, { childList: true, subtree: true });

      return () => {
        observer.disconnect();
      };
    }
  }, [isGlobalAudioPlayerActive, user, showLauncher, isMobile]);

  return (
    <IntercomContext.Provider
      value={{
        isGlobalAudioPlayerActive,
        setIsGlobalAudioPlayerActive,
        showLauncher,
        setShowLauncher,
        unreadMessageCount,
      }}
    >
      {children}
    </IntercomContext.Provider>
  );
};

export const useIntercomContext = () => {
  const context = useContext(IntercomContext);

  if (!context) {
    throw new Error("useIntercomContext must be used within a IntercomContext");
  }

  return context;
};
