import "./TrackWaveform.scss";
import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { daw, useDAW } from "@highnote/daw";
import "moment-duration-format";
import { Card, useCommentCards } from "App/common/useCommentCards";
import { SeekableWaveform } from "App/common/AudioPlayer";
import { ThemeProvider, THEME } from "App/common/ThemeProvider";
import { useCarouselSync } from "App/routes/Main/Space/Carousel/useCarouselSync";
import { ReactComponent as StarFillSVG } from "App/common/icons-v2/small-star-fill.svg";
import { useTrack } from "App/components/useEntities/useTrack";
import {
  getDefaultVersionId,
  isFilePlayable,
} from "@highnote/server/src/core/shared-util";
import { useDAWTrackVersionId, useTrackVersionInDAW } from "./SpaceTrack";
import { useFocusedCard, useFocusOnCard } from "App/common/useFocusedCard";
import {
  useGlobalAudioPlayer,
  useHTMLAudioPlayer,
} from "App/components/GlobalAudioPlayer";
import { DEFAULT_VERSION_COLOR_ID } from "App/components/TrackEditor/TrackVersions";
import { useSpaceContext } from "App/common/useSpace";
import { useGlobalTracks } from "App/store/tracks/useGlobalTracks";
import { useUrlContext } from "../useUrlContext";
import {
  ConnectedUserAvatar,
  USER_AVATAR_SIZE,
} from "App/common/UserAvatar/UserAvatar";

const COMMENT_MARKER_LIMIT = 3;

const CommentMarkers = () => {
  const { currentTrackVersion } = useTrack();
  const { newCard } = useFocusedCard();
  const sync = useCarouselSync();
  const { orderedCards } = useCommentCards();
  const { focusedCardId } = useFocusedCard();
  const { focusOnCard } = useFocusOnCard();
  const dawTrackId = useDAWTrackVersionId();
  const dawTrack = daw.getTrack(dawTrackId);
  const duration =
    dawTrack?.duration || currentTrackVersion?.metadata?.duration || 0;
  const centeredCardId = sync.getCenteredCardId();

  const cardsToMark = useMemo(() => {
    const cards = orderedCards.filter((card) => !!card.comment);
    if (newCard) {
      cards.push(newCard);
    }
    return cards;
  }, [orderedCards, newCard]);

  // Group cards by timestamp
  const groupedMarkers = useMemo(() => {
    const markersMap = new Map<number, Card[]>();
    cardsToMark.forEach((card) => {
      // Currently the markers are grouped by exact timestamp
      // TODO: Do we want these to be grouped by a rounded timestamp? ie. 1second vs 1.2345seconds
      // https://www.notion.so/highnotefm/Visual-Reskin-Track-Track-Waveform-grouping-comment-markers-by-rounded-timestamps-for-longer-track-13715dd148288063a8cfd26549d08611?pvs=4
      const cardTimestamp = card.timestamp;
      if (!markersMap.has(cardTimestamp)) {
        markersMap.set(cardTimestamp, []);
      }
      markersMap.get(cardTimestamp).push(card);
    });
    return markersMap;
  }, [cardsToMark]);

  return (
    <div className="highnote-comment-markers">
      {Array.from(groupedMarkers.entries()).map(([timestamp, cards]) => {
        const leftPosition = `${(timestamp / duration) * 100}%`;

        return (
          <div
            key={timestamp}
            className="marker-group"
            style={{ left: leftPosition }}
          >
            {cards.slice(0, COMMENT_MARKER_LIMIT).map((card) => (
              <button
                key={card.id}
                className={classNames("marker", {
                  "marker-is-focused": centeredCardId === card.id,
                })}
                onClick={() => {
                  if (daw.state.isPlaying) {
                    focusOnCard(card.id);
                  } else {
                    sync.onCardIdClick(card.id);
                  }
                }}
                data-is-active={
                  focusedCardId
                    ? card.id === focusedCardId
                    : card.id === centeredCardId
                }
                data-is-draft={card.id === newCard?.id}
              >
                <ConnectedUserAvatar
                  userId={card.comment.createdBy}
                  size={USER_AVATAR_SIZE.SMALL}
                />
              </button>
            ))}
            {cards.length > COMMENT_MARKER_LIMIT && (
              <button
                className={classNames("marker group-marker", {
                  "marker-is-focused":
                    centeredCardId === cards[COMMENT_MARKER_LIMIT].id,
                })}
                onClick={() => {
                  if (daw.state.isPlaying) {
                    focusOnCard(cards[COMMENT_MARKER_LIMIT].id); // Focus on the first grouped card
                  } else {
                    sync.onCardIdClick(cards[COMMENT_MARKER_LIMIT].id);
                  }
                }}
              >
                +{cards.length - COMMENT_MARKER_LIMIT}
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};

export const TrackWaveform = () => {
  useDAW(["currentTime"]);
  const sync = useCarouselSync();
  const { versionIdPath } = useUrlContext();
  const { currentTrackVersion, track } = useTrack();
  const { seek, nowPlaying } = useGlobalAudioPlayer();
  const { currentTime, useHTMLPlayer, onSeek } = useHTMLAudioPlayer();

  const { spaceId } = useSpaceContext();
  const { globalTracks } = useGlobalTracks();

  const _dawTrack = useTrackVersionInDAW();
  const [time, setTime] = useState<number>(
    useHTMLPlayer ? currentTime : daw.getTrackTime(_dawTrack?.id),
  );

  useEffect(() => {
    if (!_dawTrack || useHTMLPlayer) return;
    const trackTime = daw.getTrackTime(_dawTrack?.id);
    setTime(trackTime);
    sync.onAudioSeek(trackTime);
  }, [_dawTrack, daw.state.currentTime, useHTMLPlayer, sync]);

  useEffect(() => {
    if (!useHTMLPlayer) return;
    setTime(currentTime);
    sync.onAudioSeek(currentTime);
  }, [currentTime, useHTMLPlayer, sync]);

  const nowPlayingFile = nowPlaying?.file;
  const defaultVersionId = getDefaultVersionId(track);
  const isDefaultVersion = currentTrackVersion?.id === defaultVersionId;

  return (
    <ThemeProvider theme={THEME.DARK}>
      {!versionIdPath && currentTrackVersion?.name && (
        <div className="track-wave-form-version-label">
          {currentTrackVersion.name}
          {isDefaultVersion && <StarFillSVG />}
        </div>
      )}
      <div
        data-cypress-id="space-player"
        data-has-track={!!currentTrackVersion}
        data-has-playlist={globalTracks.childTracks.get(spaceId).length > 1}
        data-version-color-id={DEFAULT_VERSION_COLOR_ID}
      >
        {isFilePlayable(nowPlayingFile) ? (
          <SeekableWaveform
            onSeek={(time) => {
              const dawTrack = daw.getTrack(_dawTrack?.id);
              const newTime = time + (dawTrack?.startTime || 0);
              (useHTMLPlayer ? onSeek : seek)(newTime);
            }}
            currentTime={time}
            waveformData={nowPlayingFile.metadata.waveform}
            duration={nowPlayingFile.metadata.duration}
          />
        ) : (
          <SeekableWaveform currentTime={0} duration={0} />
        )}
        <CommentMarkers />
      </div>
    </ThemeProvider>
  );
};
