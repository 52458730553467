import { firestore } from "./firebase";
import { collection, onSnapshot, Unsubscribe, doc } from "firebase/firestore";
import { COLLECTION_ID, Track } from "../core/entities";

export const watchTrack: (props: {
  trackId: Id;
  onChange: (tracks: Track) => void;
}) => Unsubscribe = ({ trackId, onChange }) => {
  const trackQuery = doc(collection(firestore, COLLECTION_ID.TRACK), trackId);

  return onSnapshot(
    trackQuery,
    (snapshot) => {
      const track = snapshot.data();
      onChange(track as Track);
    },
    (error) => {
      console.log("Could not watch track.", error);
    },
  );
};
