import React, { useEffect, useState } from "react";
import copy from "copy-to-clipboard";
import { Button, ButtonProps } from "App/core/Button";
import { Tooltip } from "App/core/Tooltip";

export const CopyButton = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref, // throw out
  className,
  value,
  children,
  onClick,
  ...rest
}: ButtonProps & {
  value: string;
  children?: string | React.ReactNode;
}) => {
  const [copied, setCopied] = useState<boolean>();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let unmounted: boolean;

    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }

    return () => {
      unmounted = true;
    };
  }, [copied]);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    copy(value);
    setCopied(true);
    onClick && onClick(e);
  };

  return (
    <Tooltip isDisabled={!copied} title="Copied!" open>
      <Button
        {...rest}
        className={`highnote-copy-button ${className || ""}`}
        onClick={handleClick}
      >
        {children || "Copy"}
      </Button>
    </Tooltip>
  );
};
