import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router";
import { EVENT_ID, Space } from "@highnote/server/src/core/entities";
import { ReactComponent as ArrowUpRightSVG } from "App/common/icons/arrow-up-right.svg";
import { getAuthId, getAuthUrl, useAuth } from "App/components/Auth";
import { JOIN_ENTITY_TRIGGER } from "App/components/Auth/util";
import { BUTTON_SIZE, BUTTON_THEME, Button } from "App/core/Button";
import { useSegmentContext } from "App/modules/useSegment";
import styles from "./AuthButtons.module.scss";

export const useJoinEntityButton = (entity: Space, entityType: "Space") => {
  const history = useHistory();
  const { trackEvent } = useSegmentContext();
  const { user, joinEntity, isPublicView } = useAuth();
  const isPublic = isPublicView(entity);

  if (isPublic) {
    if (user) return null;

    return (
      <Button
        key="join-space-public"
        className={classNames(
          styles["join-space-button"],
          styles["public-view"],
        )}
        size={BUTTON_SIZE.SMALL}
        theme={BUTTON_THEME.CTA}
        type="button"
        onClick={() => {
          history.push(
            getAuthUrl({
              referrer_type: "public_page_join_button",
              referrer_id: entity.createdBy,
            }),
          );
        }}
      >
        <span>Create a Playlist</span> <ArrowUpRightSVG />
      </Button>
    );
  }

  return (
    <Button
      key="join-space"
      className="join-space-button"
      size={BUTTON_SIZE.SMALL}
      theme={BUTTON_THEME.CTA}
      type="button"
      onClick={() => {
        trackEvent(
          EVENT_ID.RECIPIENT_CLICKED_JOIN,
          {
            entityName: entity.name,
            entityId: entity.id,
            entityUrl: location.href,
            recipientUserId: getAuthId(),
          },
          entity.createdBy,
        );

        joinEntity(
          {
            entity,
            entityType,
            trigger: JOIN_ENTITY_TRIGGER.BUTTON,
          },
          true,
        );
      }}
    >
      Join {entityType}
    </Button>
  );
};
