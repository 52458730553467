import React from "react";
import { ConnectedUserAvatar, USER_AVATAR_SIZE } from "./UserAvatar";
import { ReactComponent as AvatarSVG } from "App/common/icons-v2/avatar.svg";
import "./UserAvatarGroup.scss";

export const UserAvatarGroup = ({
  users,
  size,
  showMinimalVersion,
}: {
  users: { id: Id; alias?: string }[];
  size?: USER_AVATAR_SIZE;
  showMinimalVersion?: boolean;
}) => {
  const uniqueIds: Record<string, boolean> = {};
  const dedupedUsers: typeof users = [];
  users.forEach((u) => {
    if (uniqueIds[u.id]) return;
    dedupedUsers.push(u);
    uniqueIds[u.id] = true;
  });
  const lastThreeUsers = dedupedUsers.slice(0, 3);
  const overflow = users.length - 3;

  if (showMinimalVersion) {
    return (
      <div className="user-avatar-group-minimal">
        <span className="user-avatar-group-minimal-icon">
          <AvatarSVG />
        </span>
        {users.length}
      </div>
    );
  }

  return (
    <div className="user-avatar-group">
      <div className="avatars">
        {lastThreeUsers.map(({ id, alias }) => (
          <ConnectedUserAvatar
            key={id}
            userId={id}
            alias={alias}
            size={size || USER_AVATAR_SIZE.MEDIUM}
          />
        ))}
      </div>
      {overflow > 0 && <span className="overflow">+{overflow}</span>}
    </div>
  );
};
