import React, { createContext, useContext, useState } from "react";

import { Space } from "@highnote/server/src/core/entities";
import { SPACE_EDITOR_TAB, SpaceEditor } from "./SpaceEditor";
import { SpaceProviderRaw } from "./useEntities";

const SpaceEditorContext = createContext({
  closeSpaceEditor: () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openSpaceEditor: (_space: Space, tabId?: SPACE_EDITOR_TAB) => {},
  space: {} as Space,
});

export const SpaceEditorProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [space, setSpace] = useState<Space | null>(null);
  const [activeTabId, setActiveTabId] = useState<SPACE_EDITOR_TAB>(
    SPACE_EDITOR_TAB.DETAILS,
  );

  const openSpaceEditor = (
    spaceToEdit: Space,
    tabId = SPACE_EDITOR_TAB.DETAILS,
  ) => {
    setSpace(spaceToEdit);
    setActiveTabId(tabId);
  };
  const closeSpaceEditor = () => {
    setActiveTabId(null);
    setSpace(null);
  };

  const values = {
    closeSpaceEditor,
    openSpaceEditor,
    space,
  };

  return (
    <SpaceEditorContext.Provider value={values}>
      {space && (
        <SpaceProviderRaw id={space.id}>
          <SpaceEditor
            activeTabId={activeTabId}
            setActiveTabId={setActiveTabId}
          />
        </SpaceProviderRaw>
      )}
      {children}
    </SpaceEditorContext.Provider>
  );
};

export const useSpaceEditor = () => useContext(SpaceEditorContext);
