import { useContainer } from "./Container";
import { DAW } from "./DAW";
import { showVisualDAW } from "./VisualDAW";
import Microphone from "./Microphone";
import { DAWState } from "./types";
import { unlockSilentMode } from "./util";

unlockSilentMode();
export const daw = new DAW();
export const mic = new Microphone();

const VISUAL_DAW_KEY = "showVisualDAW";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).showVisualDAW = () => {
  localStorage.setItem(VISUAL_DAW_KEY, "true");
  showVisualDAW();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).hideVisualDAW = () => {
  localStorage.removeItem(VISUAL_DAW_KEY);
};

if (localStorage.getItem(VISUAL_DAW_KEY)) {
  showVisualDAW();
}

export const useDAW = (props?: string[]) => useContainer<DAWState>(daw, props);
export const useDAWTrack = (id: string) => {
  useDAW();
  const { tracks } = daw.state;
  return tracks.find((t) => t.id === id);
};
