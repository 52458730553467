import React, { MouseEvent, useCallback } from "react";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { daw } from "@highnote/daw";
import {
  CommentCard,
  FOCUSED_COMMENT_CARD_ID,
} from "App/common/Comment/CommentCard";
import { CommentCardPreview } from "App/common/Comment/CommentCardPreview";
import { useCarouselSync } from "./useCarouselSync";
import "./Carousel.scss";
import { useFocusedCard, useFocusOnCard } from "App/common/useFocusedCard";

const Carousel = () => {
  const sync = useCarouselSync();
  const { focusedCardId, newCard } = useFocusedCard();
  const { focusOnCard } = useFocusOnCard();

  const onCarouselClickCapture: React.MouseEventHandler<HTMLDivElement> =
    useCallback(
      (e: MouseEvent) => {
        if (!focusedCardId) return;

        // the following logic ensures that any click event that occurs
        // outside the currently focused coment card resets current state
        // (e.g., closing reply comment form if it's currently open)
        const nodes = Array.from(e.nativeEvent.composedPath());
        const isCardClick = nodes.some((n: HTMLElement) => {
          return (
            n?.id === FOCUSED_COMMENT_CARD_ID ||
            // exxclude comment reply action menu dropdown
            // as well as delete confirmation dialog from this logic
            n?.classList?.contains("comment-actions-menu") ||
            n?.classList?.contains("comment-delete-confirmation")
          );
        });
        if (isCardClick) return;

        e.stopPropagation();
        focusOnCard();
      },
      [focusOnCard, focusedCardId],
    );

  return (
    <ErrorBoundary name="Carousel">
      <div
        className="carousel-outer"
        data-is-playing={daw.state.isPlaying}
        data-is-focused={!!focusedCardId || !!newCard}
        data-has-comments={sync.cards.length > 0 || !!newCard}
        onClickCapture={onCarouselClickCapture}
      >
        {/* FOCUSED CARD */}
        <CommentCard containerClassName="carousel-highnote-comment-card" />

        {/* CAROUSEL */}
        <div
          className="carousel"
          onScroll={sync.onCarouselScroll}
          onTouchStart={sync.onCarouselTouchStart}
          onTouchEnd={sync.onCarouselTouchEnd}
          onTouchCancel={sync.onCarouselTouchEnd}
          ref={sync.carouselScrollerRef}
        >
          {/* Since the cards are absolutely positioned, this div exists just
            to take up enough space to allow scrolling past the cards */}
          <div
            className="scrollable-width"
            style={{
              width: sync.scrollableWidth,
            }}
          />
          {sync.cards.map((card) => {
            const isCentered = sync.centeredCardIndex === card.index;
            const diff = sync.centeredCardIndex - card.index;

            return (
              <div
                key={card.id}
                className="item-wrapper"
                data-is-centered={isCentered}
                data-queue-id={diff * -1}
                data-card-index={card.index}
                onClick={() => sync.onCardClick(card.index)}
                style={{
                  left: card.position - sync.cardWidth / 2,
                  width: sync.cardWidth,
                }}
              >
                {card.renderComponent ? (
                  card.renderComponent()
                ) : (
                  <CommentCardPreview {...card} />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Carousel;
