import React, {
  useState,
  createContext,
  useMemo,
  useContext,
  useRef,
  useEffect,
} from "react";

import {
  COLLECTION_ID,
  SelectionType,
  Space,
  Track,
} from "@highnote/server/src/core/entities";
import { ReactComponent as WarningSVG } from "App/common/icons/warning-hollow.svg";
import { Button, BUTTON_THEME } from "App/core/Button";
import { DialogButtons, Dialog, DialogSection } from "App/common/Dialog";
import { useHighnote } from "App/common/useHighnote";
import {
  ENTITY_TYPE,
  DEFAULT_COLUMNS,
  EntityRowConfig,
} from "App/components/EntityTable/config";
import {
  ScrollableContainer,
  SCROLLABLE_CONTAINER_VARIANT,
} from "App/common/ScrollableContainer";
import { sortRowByAlphabetical } from "App/components/util";
import { Callout, CALLOUT_LAYOUT } from "App/core/Callout";
import { EmptyTable } from "App/components/EntityTable/EmptyTable";
import { ClickThruSpaceRowComponent } from "App/components/ClickThruSpaceRow";
import { PermissionTooltip } from "../PermissionTooltip";
import { useAuth } from "../Auth";
import { PERMISSION } from "@highnote/server/src/core/shared-util";
import { useGlobalSpaces } from "App/store/spaces/useGlobalSpaces";
import { sanitizeSelections } from "../useEntitiesSelection";
import { useCreateSpaceDialog } from "../Dialog/CreateSpace/useCreateSpaceDialog";

type MoveSelectionsToTargetContextType = {
  openMoveSelectionsToTarget: (props: {
    selectedEntities: Record<string, SelectionType>;
    onSuccess: () => void;
  }) => void;
};

const MoveSelectionsToTargetContext =
  createContext<MoveSelectionsToTargetContextType>({
    openMoveSelectionsToTarget: () => {},
  });

export const MoveSelectionsToTargetProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isOpen, setOpen] = useState(false);
  const [entitiesToMove, setEntitiesToMove] = useState<
    Record<string, SelectionType>
  >({});
  const [confirmation, setConfirmation] = useState<React.ReactNode>();
  const [isMoving, setIsMoving] = useState<boolean>(false);
  const [currentRoot, setCurrentRoot] = useState<Space>();

  const onSuccessRef = useRef<() => void>();
  const selectedTarget = useRef<Space | Track>();
  const selectedTargetType = useRef<
    COLLECTION_ID.TRACK | COLLECTION_ID.SPACE
  >();

  const { openCreateSpaceDialog, renderCreateSpaceDialog } =
    useCreateSpaceDialog();
  const { moveSelectionToEntity } = useHighnote();
  const { getGlobalSpace } = useGlobalSpaces();
  const { isAllowed, user } = useAuth();

  const spaceSelections = useMemo(
    () =>
      Object.values(entitiesToMove).filter(
        (entity) => entity.entityType === COLLECTION_ID.SPACE,
      ),
    [entitiesToMove],
  );

  const hasSpaceSelections = Object.values(spaceSelections).length > 0;

  const resetStatus = () => {
    setIsMoving(false);
    setEntitiesToMove({});
    setConfirmation(null);
    setCurrentRoot(undefined);
    selectedTarget.current = undefined;
    selectedTargetType.current = undefined;
  };

  useEffect(() => {
    if (!isOpen) {
      resetStatus();
    }
  }, [isOpen]);

  const requestMoveToTarget = (
    target: Space | Track,
    targetType: COLLECTION_ID.TRACK | COLLECTION_ID.SPACE,
  ) => {
    selectedTarget.current = target;
    selectedTargetType.current = targetType;
    setConfirmation(
      <Callout
        layout={CALLOUT_LAYOUT.ROW}
        icon={<WarningSVG />}
        title="Are you sure you want to move this selection?"
        body="Moving this selection to a new location may change access for current users and/or grant access to new users."
      />,
    );
  };

  const filter = (row: EntityRowConfig) => {
    // filter out a row if it's in the list of entities to move
    if ((row.entity as Entity).id in entitiesToMove) {
      return false;
    }
    return hasSpaceSelections
      ? // if you are moving a space(s), you can only move selections
        // to a space, so we hide anything but spaces
        row.type === ENTITY_TYPE.SPACE
      : // but if you did not select any spaces, you can move selections
        // to either a space or a track, so we hide anything but spaces & tracks
        row.type === ENTITY_TYPE.SPACE || row.type === ENTITY_TYPE.TRACK;
  };

  const onSelect = (row: EntityRowConfig) => {
    if (row.type === ENTITY_TYPE.TRACK) {
      requestMoveToTarget(row.entity as Track, COLLECTION_ID.TRACK);
      return;
    }
    setCurrentRoot(row.entity as Space);
  };

  const onCancel = () => {
    // close the dialog only when the confirmation is not being shown
    // to make sure that the cancel button cancels the confirmation,
    // not the dialog itself.
    if (!selectedTarget.current) {
      setOpen(false);
    }
    setConfirmation(null);
    selectedTarget.current = undefined;
    selectedTargetType.current = undefined;
  };

  const value = useMemo(
    () => ({
      openMoveSelectionsToTarget: ({
        selectedEntities,
        onSuccess,
      }: {
        selectedEntities: Record<string, SelectionType>;
        onSuccess: () => void;
      }) => {
        resetStatus();
        setEntitiesToMove(selectedEntities);
        setOpen(true);
        onSuccessRef.current = onSuccess;
      },
    }),
    [],
  );

  const canAddToSpace = useMemo(() => {
    return (
      currentRoot &&
      isAllowed(PERMISSION.TO_ADD_TO_SPACE, {
        space: currentRoot,
      })
    );
  }, [currentRoot, user]);

  return (
    <MoveSelectionsToTargetContext.Provider value={value}>
      <Dialog
        className="highnote-add-selections-to-target"
        open={isOpen}
        onClose={() => {
          setOpen(false);
        }}
        title="Move selection to..."
      >
        <DialogSection />
        <DialogSection data-blurred={!!confirmation}>
          <ScrollableContainer
            maxHeight={400}
            variant={SCROLLABLE_CONTAINER_VARIANT.FRAMED}
          >
            <ClickThruSpaceRowComponent
              parentSpace={currentRoot}
              tableBaseProps={{
                columns: [DEFAULT_COLUMNS.ICON, DEFAULT_COLUMNS.PREVIEW],
                tableRowVariant: "nested-click-thru",
                filter,
                onSelect,
                sort: sortRowByAlphabetical,
                EmptyComponent: () => (
                  <EmptyTable action="Create or add content" />
                ),
                rootEntity: currentRoot,
                onRootEntityChange: setCurrentRoot,
                processRows: (rows) => {
                  return Promise.resolve(
                    rows.map((row) => {
                      const { onClick, ...rowData } = row;
                      // disable the row's click event depending on the type of
                      // entity and the user's permissions
                      const isClickable =
                        row.type === ENTITY_TYPE.TRACK
                          ? // if it's a track, you should have permission to add to
                            // a track in its parent space.
                            isAllowed(PERMISSION.TO_ADD_TO_TRACK_IN_SPACE, {
                              track: row.entity as Track,
                              space: getGlobalSpace(
                                (row.entity as Track).spaceId,
                              ),
                            })
                          : // you may want to add to child spaces, so we always
                            // let the user click through if it's a space row.
                            // we prevent user to move selections to a space
                            // they don't have permission by disabling the Move
                            // button in the dialog.
                            true;
                      return {
                        ...rowData,
                        // omitting the onClick event will automatically disable
                        // the row's click event
                        ...(isClickable ? { onClick } : {}),
                      };
                    }),
                  );
                },
              }}
            />
          </ScrollableContainer>
        </DialogSection>
        {confirmation && <DialogSection>{confirmation}</DialogSection>}
        <DialogButtons>
          <div className="highnote-dialog-buttons-left">
            <Button
              disabled={isMoving}
              theme={BUTTON_THEME.SECONDARY}
              onClick={() => {
                openCreateSpaceDialog({
                  parentSpace: {
                    id: currentRoot?.id,
                    name: currentRoot?.name || "Library",
                  },
                  onConfirm: (newSpace) => {
                    setCurrentRoot(newSpace);
                  },
                });
              }}
            >
              New Space
            </Button>
          </div>
          <Button
            disabled={isMoving}
            theme={BUTTON_THEME.SECONDARY}
            onClick={onCancel}
          >
            Cancel
          </Button>
          {confirmation ? (
            <Button
              loading
              className="highnote-dialog-confirm-move"
              theme={BUTTON_THEME.CTA}
              disabled={isMoving}
              onClick={async () => {
                setIsMoving(true);
                try {
                  await moveSelectionToEntity({
                    targetEntity: {
                      entityId: selectedTarget.current.id,
                      entityType: selectedTargetType.current,
                    },
                    selectedEntities: sanitizeSelections(entitiesToMove),
                  });
                  onSuccessRef.current?.();
                  setOpen(false);
                  // eslint-disable-next-line no-empty
                } catch (e) {
                } finally {
                  setIsMoving(false);
                }
              }}
            >
              Confirm Move
            </Button>
          ) : (
            <PermissionTooltip hasPermission={canAddToSpace}>
              <Button
                disabled={!canAddToSpace}
                theme={BUTTON_THEME.CTA}
                onClick={() => {
                  requestMoveToTarget(currentRoot, COLLECTION_ID.SPACE);
                }}
              >
                Move
              </Button>
            </PermissionTooltip>
          )}
        </DialogButtons>
      </Dialog>
      {renderCreateSpaceDialog}
      {children}
    </MoveSelectionsToTargetContext.Provider>
  );
};

export const useMoveSelectionsToTarget = () =>
  useContext(MoveSelectionsToTargetContext);
