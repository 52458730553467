import "./MobileNavigation.scss";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ReactComponent as HomeSVG } from "App/common/icons/home.svg";
import { ReactComponent as LibrarySVG } from "App/common/icons/library.svg";
import { ReactComponent as UserSVG } from "App/common/icons/user.svg";
import { routePaths } from "App/modules/urls";
import { THEME, ThemeProvider } from "App/common/ThemeProvider";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { useAuth } from "App/components/Auth";

import { TAB_ID } from "App/routes/Settings";

export const MobileNavigation = () => {
  const history = useHistory();
  const matchLibraryPage = useRouteMatch(routePaths.library);
  const matchSettingsPage = useRouteMatch(routePaths.settings);
  const matchTrackPage = useRouteMatch(routePaths.spaceTrack);
  const matchRoot = useRouteMatch({ path: routePaths.root, exact: true });
  const { user: currentUser } = useAuth();

  if (matchTrackPage) return null;

  if (!currentUser) {
    return null;
  }

  return (
    <ErrorBoundary name="MobileNavigation">
      <ThemeProvider theme={THEME.DARK}>
        <footer className="highnote-bottom-nav">
          <div className="inner">
            <button
              className={`nav-button home-button ${matchRoot ? "active" : ""}`}
              onClick={() => {
                history.push(`/`);
              }}
            >
              <div className="nav-button-contents">
                <HomeSVG />
                <div className="nav-button-text">Home</div>
              </div>
            </button>

            <button
              className={`nav-button library-button ${
                matchLibraryPage ? "active" : ""
              }`}
              onClick={() => {
                history.push(`/library`);
              }}
            >
              <div className="nav-button-contents">
                <LibrarySVG />
                <div className="nav-button-text">Library</div>
              </div>
            </button>

            <button
              className={`nav-button home-button ${
                matchSettingsPage ? "active" : ""
              }`}
              onClick={() => {
                history.push(`/settings/${TAB_ID.PROFILE}`);
              }}
            >
              <div className="nav-button-contents">
                <UserSVG />
                <div className="nav-button-text">Account</div>
              </div>
            </button>
          </div>
        </footer>
      </ThemeProvider>
    </ErrorBoundary>
  );
};
