import { PublicUser } from "@highnote/server/src/core/entities";
import {
  getInviteEmail,
  isInviteId,
} from "@highnote/server/src/core/shared-util";
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "App/components/Auth";
import { usePublicUser } from "App/components/PublicUsersContext";

export const useUserCached = ({
  id,
}: {
  id: Id;
}): {
  user: PublicUser | undefined;
  userLoading: boolean;
} => {
  const { fetchUser, users } = usePublicUser();
  const { user: currentUser } = useAuth();
  const [user, setUser] = useState<PublicUser>();
  const [userLoading, setUserLoading] = useState<boolean>(true);

  const publicUser = useMemo(() => {
    return users[id];
  }, [users, id]);

  useEffect(() => {
    if (!id) {
      setUserLoading(false);
      setUser(undefined);
      return;
    }

    if (currentUser?.id === id) {
      setUser(currentUser as PublicUser);
      setUserLoading(false);
      return;
    }

    if (isInviteId(id)) {
      setUser({
        id,
        name: getInviteEmail(id),
      });
      setUserLoading(false);
      return;
    }

    if (publicUser) {
      setUser(publicUser);
      setUserLoading(false);
      return;
    }

    fetchUser(id);
  }, [id, currentUser, publicUser]);

  return { user, userLoading };
};
