import "./RecordDialog.scss";
import React, { useEffect, useState } from "react";
import { DialogButtons, Dialog, DialogSection } from "App/common/Dialog";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { RecordingPlayer } from "App/common/AudioPlayer/RecordingPlayer";
import { Button, BUTTON_THEME } from "App/core/Button";
import { UploadCache, useFiles } from "App/components/useFiles";
import { FileEntity } from "@highnote/server/src/core/entities";
import { watchAudioFiles } from "../watchAudioFiles";

export const RecordDialog = ({
  onClose,
  onSave,
  cache,
}: {
  onClose?: () => void;
  onSave?: (file: FileEntity) => void;
  cache?: UploadCache;
}) => {
  const [file, setFile] = useState<FileEntity>();
  const [fileId, setFileId] = useState<string>();
  const { uploadFile } = useFiles();

  useEffect(() => {
    let unmounted = false;
    if (!fileId) return;
    const unwatch = watchAudioFiles([fileId], (files) => {
      if (unmounted) return;
      const _file = files.find((f) => f.id === fileId);
      setFile(_file);
    });

    return () => {
      unmounted = true;
      unwatch();
    };
  }, [fileId]);

  const onCancel = () => {
    onClose && onClose();

    if (!file) {
      return;
    }
  };

  const onSubmit = async () => {
    onClose && onClose();

    if (cache) {
      // Manually add the file to the uploads cache so you don't get a File Not Found flash
      cache[file.id] = true;
    }

    onSave && onSave(file);
  };

  const onRecordingChange = async (file: File) => {
    if (!file) {
      setFile(undefined);
      return;
    }

    const fileEntity = await uploadFile({ file });
    setFileId(fileEntity.id);
  };

  return (
    <ErrorBoundary name="RecordDialog">
      <Dialog
        title="Record a Track"
        open
        onClose={onClose}
        className="highnote-record-dialog"
      >
        <DialogSection className="recording-player-wrapper">
          <RecordingPlayer
            isEditing={true}
            onChange={onRecordingChange}
            file={file}
          />
        </DialogSection>
        <DialogButtons>
          <Button
            theme={BUTTON_THEME.SECONDARY}
            className="cancel"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            theme={BUTTON_THEME.CTA}
            disabled={!file}
            className="save"
            onClick={onSubmit}
          >
            Save as Track
          </Button>
        </DialogButtons>
      </Dialog>
    </ErrorBoundary>
  );
};
