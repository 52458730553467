import React from "react";
import classNames from "classnames";
import { useTheme } from "./ThemeProvider";
import "./ProgressBar.scss";

export const ProgressBar = ({
  progress = 0,
  className,
  hasError,
}: {
  progress: number;
  className?: string;
  hasError?: boolean;
}) => {
  const { theme } = useTheme();

  return (
    <div className={classNames("progress-bar", className)} data-theme={theme}>
      <div className="outer">
        <div
          className={classNames("inner", {
            success: progress === 1,
            error: hasError,
          })}
          style={{ width: `${progress * 100}%` }}
        ></div>
      </div>
    </div>
  );
};
