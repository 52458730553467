import React, { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import { FileEntity, Track } from "@highnote/server/src/core/entities";
import { getDefaultVersionId } from "@highnote/server/src/core/shared-util";
import { BaseRow, BaseTableRowProps } from "App/common/BaseTable";
import {
  ALT_TRACK_VERSION_ACTIONS,
  TrackVersionActions,
} from "App/components/TrackEditor/TrackVersions/TrackVersionActions";
import { useTrackFiles } from "App/components/useEntities/useTrack";
import { useTrackComments } from "App/components/useEntities/useTrackComments/useTrackComments";
import { SORT_TYPE } from "App/routes/Main/Library/config";
import {
  EntityTableRowOptions,
  useFormatFileToBaseEntityRow,
} from "../ExpandableSpace/ExpandableSpaceRow";
import { ENTITY_TYPE, EntityRowConfig, Version } from "../config";
import { LoadingSpinner } from "App/common/icons/LoadingSpinner";
import { useGlobalAudioPlayer } from "App/components/GlobalAudioPlayer";

export const transformFileToVersion = ({
  file,
  track,
  isDefaultVersion,
  index,
  commentCount,
}: {
  file: FileEntity;
  track: Track;
  isDefaultVersion: boolean;
  index: number;
  commentCount?: number;
}) => {
  return {
    ...file,
    index,
    isDefaultVersion,
    isVersion: true,
    track,
    commentCount,
  } as Version;
};

export const useFormatTrackVersionToBaseEntityRow = () => {
  const { push } = useHistory();

  const formatTrackVersionToBaseEntityRow = ({
    track,
    version,
    defaultVersionId,
    index,
    commentCount,
    options,
  }: {
    track: Track;
    version: FileEntity;
    defaultVersionId: string;
    index: number;
    commentCount?: number;
    options?: EntityTableRowOptions;
  }): EntityRowConfig => {
    const isDefaultVersion = defaultVersionId === version.id;
    const entityVersion = transformFileToVersion({
      file: version,
      track,
      isDefaultVersion,
      commentCount,
      index,
    });

    return {
      key: version.id,
      id: version.id,
      entity: entityVersion,
      type: ENTITY_TYPE.TRACK_VERSION,
      actions: (
        <TrackVersionActions
          version={entityVersion}
          actions={ALT_TRACK_VERSION_ACTIONS}
        />
      ),
      createdAt: version.createdAt,
      name: version.name,
      onClick: () => {
        push(`/space/${track.spaceId}/${track.id}/${version.id}`);
      },
      isPlayable: options?.hasPlayableTracks,
    };
  };

  return {
    formatTrackVersionToBaseEntityRow,
  };
};

export const useTrackChildItemRows = (track: Track) => {
  const {
    versions,
    attachments,
    loading: trackVersionsLoading,
  } = useTrackFiles(track);
  const defaultVersionId = getDefaultVersionId(track);
  const { fetchedComments, commentsLoading } = useTrackComments(track);

  const { formatTrackVersionToBaseEntityRow } =
    useFormatTrackVersionToBaseEntityRow();
  const { formatFileToBaseEntityRow } = useFormatFileToBaseEntityRow();

  const sortTrackVersions = useCallback(
    (a: FileEntity, b: FileEntity) => {
      const versionFilesV2 = track.versionFilesV2 || [];
      const versionFilesV2Length = versionFilesV2.length || 0;

      // Sort based on the index of version.id in versionFilesV2
      const indexA = versionFilesV2.indexOf(a.id);
      const indexB = versionFilesV2.indexOf(b.id);

      // If a version id is not found in versionFilesV2, it will return -1, placing it at the end.
      const adjustedIndexA = indexA === -1 ? versionFilesV2Length : indexA;
      const adjustedIndexB = indexB === -1 ? versionFilesV2Length : indexB;

      return adjustedIndexA - adjustedIndexB;
    },
    [track.versionFilesV2],
  );

  const sortedTrackVersions = useMemo(
    () =>
      versions.sort(sortTrackVersions).map((version, index) => {
        return formatTrackVersionToBaseEntityRow({
          version,
          defaultVersionId,
          track,
          commentCount: fetchedComments[version.id]?.length,
          options: {
            hasPlayableTracks: true,
            tableRowVariant: "expanded-row",
          },
          index,
        });
      }) || [],
    [versions, fetchedComments],
  );

  const allTrackChildEntityRows = useMemo(() => {
    const childTrackAttachments = attachments.map((attachment) => {
      return formatFileToBaseEntityRow(attachment);
    });

    return [...sortedTrackVersions, ...childTrackAttachments];
  }, [sortedTrackVersions, attachments]);

  return {
    allTrackChildEntityRows,
    allTrackChildRowsLoading: trackVersionsLoading || commentsLoading,
    sortedTrackVersions,
    trackVersionsLoading,
  };
};

const _ExpandableParentTrackRow = ({
  track,
  tableRowProps,
}: {
  track: Track;
  tableRowProps: Omit<BaseTableRowProps, "row"> & {
    hasPlayableTracks?: boolean;
  };
  sortType?: SORT_TYPE;
}) => {
  const { preloadTrackVersions } = useGlobalAudioPlayer();
  const { allTrackChildEntityRows, allTrackChildRowsLoading } =
    useTrackChildItemRows(track);

  useEffect(() => {
    // Preload here instead of "handleOnTogglePlay" to avoid unnecessary preload
    // on each track click. They all get preloaded when the track is expanded.
    preloadTrackVersions(track.id, track.versionFilesV2);
  }, [allTrackChildEntityRows]);

  if (allTrackChildRowsLoading) {
    return (
      <div className="highnote-base-table">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      {allTrackChildEntityRows.map((childRow, i) => {
        const baseProps = {
          ...tableRowProps,
          indentation: tableRowProps.indentation + 1,
        };

        return (
          <BaseRow
            key={`expandable-track-row-${childRow.key}-${i}`}
            {...baseProps}
            row={childRow}
          />
        );
      })}
    </>
  );
};

export const ExpandableParentTrackRow = ({
  parentRow,
  tableRowProps,
  sortType,
}: {
  parentRow: EntityRowConfig;
  tableRowProps: Omit<BaseTableRowProps, "row">;
  sortType?: SORT_TYPE;
}) => {
  const parentTrack = parentRow.entity as Track;

  return (
    <_ExpandableParentTrackRow
      track={parentTrack}
      tableRowProps={tableRowProps}
      sortType={sortType}
    />
  );
};
