import React from "react";
import classNames from "classnames";
import moment from "moment";

import { formatDuration } from "@highnote/server/src/core/shared-util";
import { ReactComponent as SpaceOwnerStarSVG } from "App/common/icons-v2/star-fill.svg";
import { ReactComponent as CheckSVG } from "App/common/icons/check.svg";
import { useAuth } from "App/components/Auth";
import { useCarouselSync } from "App/routes/Main/Space/Carousel/useCarouselSync";
import { Button, BUTTON_THEME } from "../../core/Button";
import { StatusIndicator } from "../StatusIndicator";
import { getValidCommentBlock, useCommentContext } from "../useComment";
import {
  ConnectedUserAvatar,
  ConnectedUserName,
  USER_AVATAR_SIZE,
} from "../UserAvatar/UserAvatar";
import { useSpaceContext } from "../useSpace";
import { COMMENT_BLOCKS } from "./blocks";
import "./CommentMeta.scss";

enum COMMENT_META_VARIANT {
  DEFAULT = "default",
  INLINE = "inline",
}

export const CommentMeta = ({
  variant,
  hideCreatedAtTimestamp,
  hideUsername,
  type = "default",
}: {
  variant?: COMMENT_META_VARIANT;
  type?: "default" | "space-chat";
  hideCreatedAtTimestamp?: boolean;
  hideUsername?: boolean;
}) => {
  const { user } = useAuth();
  const { space } = useSpaceContext();
  const { isPublished, comment, saveComment } = useCommentContext();
  const isCurrentUser = comment?.createdBy === user?.id;
  const isSpaceOwner = comment?.createdBy === space?.createdBy;
  const sync = useCarouselSync();
  const blockData = getValidCommentBlock(comment);

  if (!blockData) return null;

  const blockType = blockData.type as keyof typeof COMMENT_BLOCKS;
  const blockConfig = COMMENT_BLOCKS[blockType];

  if (!blockConfig) {
    console.log(
      "[CommentMeta] Could not find matching block config:",
      blockType,
    );
    return null;
  }

  if (!blockConfig.Component || !blockConfig.Icon) {
    return null;
  }

  return (
    <div
      className={classNames("highnote-comment-meta", {
        "is-current-user": type === "space-chat" && isCurrentUser,
        "highnote-comment-meta-space-chat": type === "space-chat",
      })}
      data-variant={variant}
    >
      {variant !== COMMENT_META_VARIANT.INLINE && (
        <>
          <ConnectedUserAvatar
            userId={comment.createdBy}
            size={USER_AVATAR_SIZE.MEDIUM}
          />
          <div className="user-name">
            {!hideUsername && <ConnectedUserName userId={comment.createdBy} />}
            {isSpaceOwner && (
              <div className="highnote-comment-meta-space-owner-icon">
                <SpaceOwnerStarSVG />
              </div>
            )}
            {!hideCreatedAtTimestamp && (
              <div className="created-at">
                {moment(comment.createdAt).fromNow()}
              </div>
            )}
          </div>
        </>
      )}

      <div className="tools">
        {comment.timestamp !== undefined && (
          <>
            {comment.isResolved ? <CheckSVG /> : <blockConfig.Icon />}
            <Button
              theme={BUTTON_THEME.PLAIN}
              className="timestamp"
              onClick={() => {
                sync.onCardIdClick(comment.id);
              }}
            >
              {formatDuration(comment.timestamp)}
            </Button>
          </>
        )}

        <StatusIndicator
          isLoading={comment.isSaving}
          errorMessage={comment.updateError}
          retryCallback={!isPublished ? saveComment : undefined}
          className="space-chat-comment-status-indicator"
        />
      </div>
    </div>
  );
};
