import "./EmptyContainer.scss";
import React, { forwardRef, RefObject } from "react";
import { useTheme } from "App/common/ThemeProvider";

type EmptyContainerProps = {
  className?: string;
  children?: React.ReactNode;
  ref?: RefObject<HTMLDivElement>;
};

export const EmptyContainer = forwardRef<HTMLDivElement, EmptyContainerProps>(
  ({ className, children }: EmptyContainerProps, ref) => {
    const { theme } = useTheme();
    return (
      <div
        ref={ref}
        data-cypress-id="empty-container"
        className={`highnote-empty-container ${className || ""}`}
        data-theme={theme}
      >
        {children}
      </div>
    );
  },
);
EmptyContainer.displayName = "EmptyContainer";
