import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as RetrySVG } from "App/common/icons/retry.svg";
import { BUTTON_SIZE, BUTTON_THEME, Button } from "App/core/Button";
import { TopBanner } from "../TopBanner/TopBanner";
import styles from "./RefreshAppBanner.module.scss";

const checkForUpdates = async () => {
  const response = await fetch("/public/version.json");
  const data = await response.json();
  return data as { identifier: number };
};

export const RefreshAppBanner = () => {
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);

  const checkAndUpdate = useCallback(async () => {
    const versionInfo = await checkForUpdates();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const storedVersion = (window as any).highnoteCurrentTimestamp;
    if (versionInfo.identifier > storedVersion) {
      setShowUpdateDialog(true);
    }
  }, []);

  useEffect(() => {
    // Immediate check on component mount
    checkAndUpdate();
  }, []);

  // Separate useEffect for managing the interval based on showUpdateDialog and after initial check.
  useEffect(() => {
    if (!showUpdateDialog) {
      const intervalId = setInterval(checkAndUpdate, 300000); // Polling every 5 minutes
      return () => clearInterval(intervalId);
    }
  }, [showUpdateDialog]);

  const handleRefreshClick = () => {
    window.location.reload();
  };

  if (!showUpdateDialog) return null;

  return (
    <TopBanner>
      <p>Refresh to update Highnote</p>
      <Button
        size={BUTTON_SIZE.XSMALL}
        theme={BUTTON_THEME.PRIMARY}
        onClick={handleRefreshClick}
        className={styles["refresh-button"]}
      >
        Refresh App{" "}
        <span>
          <RetrySVG />
        </span>
      </Button>
    </TopBanner>
  );
};
