import { AudioLoader } from "./AudioLoader";
import { Container } from "./Container";
import { AudioConfig, AudioSource, DAWState } from "./types";

const FPS = 2;
const frameLength = 60 / FPS;
const FADE_DUR = 0.05;
const TIME_CONST = 0.015;

type AudioNodes = {
  sourceNode?: AudioScheduledSourceNode;
  gainNode?: GainNode;
};

const elegantlyRemoveNodes = (
  nodes: AudioNodes,
  ctx: AudioContext,
): Promise<void> => {
  return new Promise((resolve) => {
    if (!nodes) {
      resolve();
      return;
    }
    if (!nodes.gainNode) {
      nodes.sourceNode.stop(ctx.currentTime);
      nodes.sourceNode.disconnect();
      resolve();
      return;
    }

    nodes.gainNode.gain.setTargetAtTime(0, ctx.currentTime, TIME_CONST);
    nodes.sourceNode.stop(ctx.currentTime + FADE_DUR);
    setTimeout(() => {
      nodes.gainNode.disconnect();
      nodes.sourceNode.disconnect();
      resolve();
    }, 1000 * FADE_DUR);
  });
};

export class DAW extends Container<DAWState> {
  __ctx: AudioContext;
  __lastTick: number;
  __mutedTracks: Record<string, boolean>;
  __isPlaying: boolean;
  __audioLoader: AudioLoader;
  __nodesById: Record<string, AudioNodes>;
  __lastCurrentTimestamp: number;
  __closed: boolean;
  __animationFrame: number;

  /* So that you can update `currentTime` (and persist it) even before any tracks load */
  __minDuration?: number;
  volume: number;
  relativeZero: number;
  state: DAWState;

  constructor() {
    super();
    this.__ctx = new AudioContext();
    this.__mutedTracks = {};
    this.__isPlaying = false;
    this.__audioLoader = new AudioLoader({ onChange: this.onChange });
    this.__nodesById = {};
    this.__lastCurrentTimestamp = 0;
    this.__closed = false;
    this.volume = 100;
    this.relativeZero = 0;
    this.state = {
      currentTime: 0,
      isPlaying: false,
      tracks: [],
      volume: this.volume,
      isLoading: false,
    };
    this.tick();
  }

  async close() {
    if (this.__closed) return;
    await this.ensurePause();
    cancelAnimationFrame(this.__animationFrame);
    this.onTick();
    this.__lastCurrentTimestamp = this.state.currentTime;
    await this.__ctx.close();
  }

  resume() {
    this.setState({
      currentTime: this.__lastCurrentTimestamp,
    });
    if (!this.__ctx || this.__ctx.state === "closed") {
      this.__ctx = new AudioContext();
      this.tick();
    }
  }

  get sources() {
    return this.__audioLoader.audioSources;
  }

  tick() {
    const now = Date.now();

    if (!this.__lastTick || now - this.__lastTick > frameLength) {
      this.__lastTick = now;

      if (this.__ctx.state !== "running") this.__ctx.resume();
      this.onTick();
    }

    this.__animationFrame = requestAnimationFrame(() => {
      this.tick();
    });
  }

  onTick = () => {
    let currentTime = this.state.currentTime;

    if (this.__isPlaying) {
      currentTime = this.__ctx.currentTime - this.relativeZero;
    }

    if (currentTime >= this.totalDuration) {
      currentTime = this.totalDuration;
      this.pause();
    }

    const isLoading = this.isLoading();

    if (this.__isPlaying && isLoading) {
      this.pause();
    }

    this.setState({
      currentTime,
      isPlaying: this.__isPlaying,
      tracks: this.sources,
      volume: this.volume,
      isLoading,
    });
  };

  isLoading = () => {
    const { currentTime } = this.state;
    return this.sources.some((source) => {
      const endTime = source.startTime + source.duration;
      if (this.isTrackMuted(source.id)) return false;
      if (source.startTime > currentTime || endTime < currentTime) return false;
      return !source.audioBuffer;
    });
  };

  setVolume = (volume?: number) => {
    this.volume = volume;
    this.updateVolume();
  };

  onChange = () => {
    this.sources.forEach((source) => {
      if (this.__isPlaying) {
        this.playSource(source);
      }
    });

    this.updateVolume();
  };

  addTrack = (audio: AudioConfig) => {
    this.__audioLoader.loadAudio(audio);
  };

  muteTrack = (id: string) => {
    this.__mutedTracks[id] = true;
    this.updateVolume();
  };

  unmuteTrack = (id: string) => {
    this.__mutedTracks[id] = false;
    this.updateVolume();
  };

  isTrackMuted = (id: string) => {
    return !!this.__mutedTracks[id];
  };

  get totalDuration() {
    let duration = this.__minDuration || 0;
    this.sources.forEach((source) => {
      if (this.isTrackMuted(source.id)) return;
      const trackEnd = source.startTime + source.duration;
      if (trackEnd > duration) duration = trackEnd;
    });
    return duration;
  }

  getTrack = (id: string) => {
    const track = this.sources.find((t) => t.id === id);
    return track;
  };

  getTrackTime = (id: string) => {
    const track = this.sources.find((t) => t.id === id);
    if (!track) return 0;
    const rawTime = this.state.currentTime - track.startTime;
    return Math.min(Math.max(0, rawTime), track.duration);
  };

  getTrackStartTime = (source: AudioSource) => {
    const startTime = this.relativeZero + source.startTime;
    const offset =
      startTime < this.__ctx.currentTime
        ? this.__ctx.currentTime - startTime
        : 0;
    const adjustedStartTime = Math.max(startTime, 0);

    return { startTime: adjustedStartTime, offset };
  };

  getTrackVolume = (id: string) => {
    const isMuted = this.__mutedTracks[id];
    if (isMuted) return 0;
    const percent = (n: number) => n / 100;
    const masterVolume = this.volume ?? 100;
    const gainLevel = percent(masterVolume);
    return gainLevel;
  };

  removeTrack = (id: string) => {
    const nodes = this.__nodesById[id];
    if (nodes) {
      elegantlyRemoveNodes(nodes, this.__ctx);
    }

    delete this.__nodesById[id];
    this.__audioLoader.removeAudio(id);
  };

  removeAllTracks = (except?: string[]) => {
    this.sources.forEach((source) => {
      if (except && except.includes(source.id)) return;
      this.removeTrack(source.id);
    });
  };

  playSource = (source: AudioSource) => {
    if (!source.audioBuffer) return;
    if (this.__nodesById[source.id]) return;

    const { startTime, offset } = this.getTrackStartTime(source);
    const gainLevel = this.getTrackVolume(source.id);

    // Create the source node.
    const sourceNode = this.__ctx.createBufferSource();
    sourceNode.buffer = source.audioBuffer;
    sourceNode.start(startTime, offset);

    const gainNode = this.__ctx.createGain();
    gainNode.gain.value = gainLevel;

    // Connect the two nodes together, then to the audio output.
    sourceNode.connect(gainNode);
    gainNode.connect(this.__ctx.destination);

    this.__nodesById[source.id] = {
      sourceNode,
      gainNode,
    };
  };

  updateVolume = () => {
    this.sources.forEach((source) => {
      const trackVolume = this.getTrackVolume(source.id);
      const nodes = this.__nodesById[source.id];

      if (nodes) {
        nodes.gainNode.gain.setTargetAtTime(
          trackVolume,
          this.__ctx.currentTime,
          TIME_CONST,
        );
      }

      if (source.audioElement) {
        source.audioElement.setVolume(trackVolume);
      }
    });
  };

  seekTo = (time: number) => {
    const cleanTime = Math.max(0, time);
    if (this.__isPlaying) {
      this.play(cleanTime);
    } else {
      this.setState({ currentTime: cleanTime });
    }
  };

  play = (time?: number) => {
    if (this.__isPlaying && time === undefined) return;
    const cleanTime = Math.max(0, time ?? this.state.currentTime);
    this.state.currentTime = cleanTime;
    this.pause();
    this.relativeZero = this.__ctx.currentTime - this.state.currentTime;

    this.sources.forEach((source) => {
      this.playSource(source);
    });

    this.__isPlaying = true;
  };

  pause = () => {
    if (!this.__isPlaying) return;
    this.sources.forEach((source) => {
      const nodes = this.__nodesById[source.id];

      if (nodes) {
        elegantlyRemoveNodes(nodes, this.__ctx);
        delete this.__nodesById[source.id];
      }

      if (source.audioElement) {
        source.audioElement.pause();
      }
    });

    this.__isPlaying = false;
  };

  ensurePause = async () => {
    if (!this.__isPlaying) return;
    await Promise.all(
      this.sources.map(async (source) => {
        const nodes = this.__nodesById[source.id];

        if (nodes) {
          await elegantlyRemoveNodes(nodes, this.__ctx);
          delete this.__nodesById[source.id];
        }

        if (source.audioElement) {
          source.audioElement.pause();
        }
      }),
    );
    this.__isPlaying = false;
  };
}
