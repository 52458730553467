import React, { useEffect, useState } from "react";
import classNames from "classnames";

import {
  ArrayRemove,
  ArrayUnion,
  Space,
} from "@highnote/server/src/core/entities";
import { highnote } from "@highnote/server/src/sdk";
import { ReactComponent as UnpinSVG } from "App/common/icons-v2/no-pushpin.svg";
import { ReactComponent as PinSVG } from "App/common/icons-v2/pushpin-line.svg";
import { useToast } from "App/common/useToast";
import { useAuth } from "App/components/Auth";
import { Button, BUTTON_THEME } from "App/core/Button";
import { Tooltip } from "App/core/Tooltip";
import styles from "./PinToggle.module.scss";

export const PinToggle = ({
  entity,
  className,
}: {
  entity: Space;
  className?: string;
}) => {
  const { user } = useAuth();
  const { toasted } = useToast();
  const [pinnedSpaces, setPinnedSpaces] = useState(user?.pinnedSpaces || []);
  const pinned = pinnedSpaces.includes(entity.id);

  useEffect(() => {
    setPinnedSpaces(user?.pinnedSpaces || []);
  }, [user?.pinnedSpaces]);

  return (
    <>
      <Tooltip
        enterDelay={600}
        className={classNames(styles["pin-toggle-tooltip"], className)}
        title={
          pinned ? "This Space is pinned. Click to unpin." : "Pin this Space"
        }
      >
        <Button
          className={styles["pin-toggle"]}
          data-pinned={pinned}
          theme={BUTTON_THEME.ICON}
          onClick={async (e) => {
            e.stopPropagation();

            setPinnedSpaces(
              pinned
                ? [...pinnedSpaces].filter((id) => id !== entity.id)
                : [...pinnedSpaces, entity.id],
            );

            await toasted({
              promise: highnote.updateUser({
                id: user.id,
                data: {
                  pinnedSpaces: pinned
                    ? (new ArrayRemove([entity.id]) as unknown as string[])
                    : (new ArrayUnion([entity.id]) as unknown as string[]),
                },
              }),
              errorMessage: `Could not pin ${entity.name}. Please try again.`,
            });
          }}
        >
          {pinned ? <UnpinSVG /> : <PinSVG />}
        </Button>
      </Tooltip>
    </>
  );
};
