import "./AudioPlayToggle.scss";
import React, { PropsWithChildren } from "react";
import { ReactComponent as PauseSvg } from "../icons/pause.svg";
import { ReactComponent as PlaySvg } from "../icons/play.svg";
import { LoadingSpinner } from "../icons/LoadingSpinner";

export interface AudioPlayToggleProps extends AudioPlayToggleButtonProps {
  cypressId?: string;
  isNowPlaying?: boolean;
  isPlayable?: boolean;
  renderToggleButton: boolean;
  wrapperClassName: string;
  playIcon?: React.ReactNode;
  asDiv?: boolean;
}

export const AudioPlayToggle = React.memo(
  (props: PropsWithChildren<AudioPlayToggleProps>) => {
    const {
      children,
      cypressId,
      isNowPlaying,
      isPlayable,
      isPlaying,
      renderToggleButton,
      wrapperClassName,
      ...buttonProps
    } = props;

    return (
      <div
        className={wrapperClassName}
        data-cypress-id={cypressId}
        data-is-now-playing={isNowPlaying}
        data-is-playable={Boolean(isPlayable)}
        data-is-playing={isPlaying}
      >
        {renderToggleButton && (
          <AudioPlayToggleButton {...buttonProps} isPlaying={isPlaying} />
        )}
        {children}
      </div>
    );
  },
);

AudioPlayToggle.displayName = "AudioPlayToggle";

export interface AudioPlayToggleButtonProps {
  disabled?: boolean;
  isLoading?: boolean;
  isPlaying?: boolean;
  onTogglePlay?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  pauseIcon?: React.ReactNode;
  playIcon?: React.ReactNode;
  asDiv?: boolean;
}

export const AudioPlayToggleButton = React.memo(
  ({
    disabled,
    isLoading,
    isPlaying,
    onTogglePlay,
    pauseIcon = <PauseSvg />,
    playIcon = <PlaySvg />,
    asDiv,
  }: AudioPlayToggleButtonProps) => {
    if (asDiv) {
      return (
        <div
          className="highnote-audio-play-toggle"
          data-is-playing={!!isPlaying}
        >
          {isLoading ? <LoadingSpinner /> : isPlaying ? pauseIcon : playIcon}
        </div>
      );
    }

    return (
      <button
        className="highnote-audio-play-toggle"
        data-is-playing={!!isPlaying}
        disabled={!!disabled}
        onClick={(e) => {
          onTogglePlay(e);
        }}
      >
        {isLoading ? <LoadingSpinner /> : isPlaying ? pauseIcon : playIcon}
      </button>
    );
  },
);

AudioPlayToggleButton.displayName = "AudioPlayToggleButton";
