import React, { useCallback, useEffect, useMemo } from "react";
import {
  COLLECTION_ID,
  FileEntity,
  Track,
} from "@highnote/server/src/core/entities";
import { getDefaultVersionId } from "@highnote/server/src/core/shared-util";
import {
  BaseRow,
  BaseTableRowProps,
  calculateChildIndentation,
  EXPAND_COLUMN_WIDTH,
  EXPAND_COLUMN_WIDTH_MOBILE,
} from "App/common/BaseTable";
import { useGlobalAudioPlayer } from "App/components/GlobalAudioPlayer";
import { useTrackFiles } from "App/components/useEntities/useTrack";
import { useTrackComments } from "App/components/useEntities/useTrackComments/useTrackComments";
import { useEntitiesSelection } from "App/components/useEntitiesSelection";
import { SORT_TYPE } from "App/routes/Main/Library/config";
import {
  childItemRowsToBulkSelections,
  useFormatFileToBaseEntityRow,
} from "../ExpandableSpace/ExpandableSpaceRow";
import { EntityRowConfig } from "../config";
import { useFormatTrackVersionToBaseEntityRow } from "./helpers";
import { Skeleton } from "App/common/Skeleton/Skeleton";
import { useViewport } from "App/common/useViewport";
import styles from "./ExpandableTrackRow.module.scss";
// TODO: Potential circular dependency between ExpandableTrackRow and ExpandableSpaceRow
// through childItemRowsToBulkSelections. This should be moved to a shared utility file
// to prevent circular imports and improve code organization.
// NOTE: Do not move this hook to helpers.ts as it would create another circular dependency and
// the other helper functions will cause an error wherever they are used.
export const useTrackChildItemRows = (track: Track) => {
  const {
    versions,
    attachments,
    loading: trackVersionsLoading,
  } = useTrackFiles(track);
  const defaultVersionId = getDefaultVersionId(track);
  const { fetchedComments, commentsLoading } = useTrackComments(track);

  const { formatTrackVersionToBaseEntityRow } =
    useFormatTrackVersionToBaseEntityRow();
  const { formatFileToBaseEntityRow } = useFormatFileToBaseEntityRow();

  const sortTrackVersions = useCallback(
    (a: FileEntity, b: FileEntity) => {
      const versionFilesV2 = track.versionFilesV2 || [];
      const versionFilesV2Length = versionFilesV2.length || 0;

      // Sort based on the index of version.id in versionFilesV2
      const indexA = versionFilesV2.indexOf(a.id);
      const indexB = versionFilesV2.indexOf(b.id);

      // If a version id is not found in versionFilesV2, it will return -1, placing it at the end.
      const adjustedIndexA = indexA === -1 ? versionFilesV2Length : indexA;
      const adjustedIndexB = indexB === -1 ? versionFilesV2Length : indexB;

      return adjustedIndexA - adjustedIndexB;
    },
    [track.versionFilesV2],
  );

  const sortedTrackVersions = useMemo(
    () =>
      versions.sort(sortTrackVersions).map((version, index) => {
        return formatTrackVersionToBaseEntityRow({
          version,
          defaultVersionId,
          track,
          commentCount: fetchedComments[version.id]?.length,
          options: {
            hasPlayableTracks: true,
            tableRowVariant: "expanded-row",
          },
          index,
        });
      }) || [],
    [versions, fetchedComments],
  );

  const allTrackChildEntityRows = useMemo(() => {
    const childTrackAttachments = attachments.map((attachment) => {
      return formatFileToBaseEntityRow(attachment);
    });

    return [...sortedTrackVersions, ...childTrackAttachments];
  }, [sortedTrackVersions, attachments]);

  return {
    allTrackChildEntityRows,
    allTrackChildRowsLoading: trackVersionsLoading || commentsLoading,
    sortedTrackVersions,
    trackVersionsLoading,
  };
};

const _ExpandableParentTrackRow = ({
  track,
  parentRow,
  tableRowProps,
}: {
  track: Track;
  parentRow: EntityRowConfig;
  tableRowProps: Omit<BaseTableRowProps, "row"> & {
    hasPlayableTracks?: boolean;
  };
  sortType?: SORT_TYPE;
}) => {
  const { addToSelection, bulkAddToSelection, selectedEntities } =
    useEntitiesSelection();
  const { preloadTrackVersions } = useGlobalAudioPlayer();
  const { allTrackChildEntityRows, allTrackChildRowsLoading } =
    useTrackChildItemRows(track);
  const { isMobile } = useViewport();

  useEffect(() => {
    // Preload here instead of "handleOnTogglePlay" to avoid unnecessary preload
    // on each track click. They all get preloaded when the track is expanded.
    preloadTrackVersions(track.id, track.versionFilesV2);
  }, [allTrackChildEntityRows]);

  const isTrackSelected = parentRow.id in selectedEntities;
  const allChildrenSelected = useMemo(() => {
    return (
      allTrackChildEntityRows.length > 0 &&
      allTrackChildEntityRows.every((itemRow) => itemRow.id in selectedEntities)
    );
  }, [allTrackChildEntityRows, selectedEntities]);

  useEffect(() => {
    if (isTrackSelected) {
      const bulkSelections = childItemRowsToBulkSelections(
        allTrackChildEntityRows,
        track.id,
        COLLECTION_ID.TRACK,
      );
      bulkAddToSelection(bulkSelections);
      return;
    }
    if (allChildrenSelected) {
      addToSelection({
        entityId: track.id,
        entityType: COLLECTION_ID.TRACK,
        ...(track.spaceId && {
          parentEntity: {
            entityId: track.spaceId,
            entityType: COLLECTION_ID.SPACE,
          },
        }),
      });
    }
  }, [isTrackSelected, allChildrenSelected, allTrackChildEntityRows]);

  if (allTrackChildRowsLoading && allTrackChildEntityRows.length === 0) {
    const childIndentation = calculateChildIndentation(
      tableRowProps.indentation,
      isMobile,
    );

    const defaultColumnWidth = isMobile
      ? EXPAND_COLUMN_WIDTH_MOBILE
      : EXPAND_COLUMN_WIDTH;

    return (
      <div
        className={styles["skeleton-container"]}
        style={{
          paddingLeft: `${childIndentation + defaultColumnWidth}px`,
        }}
      >
        {Array.from({ length: 3 }).map((_, index) => (
          <Skeleton key={`skeleton-${index}`} height={40} />
        ))}
      </div>
    );
  }

  return (
    <>
      {allTrackChildEntityRows.map((childRow, i) => {
        const baseProps = {
          ...tableRowProps,
          parentRow,
          indentation: tableRowProps.indentation + 1,
        };

        return (
          <BaseRow
            key={`expandable-track-row-${childRow.key}-${i}`}
            {...baseProps}
            row={{
              ...childRow,
              selected: childRow.id in selectedEntities,
            }}
          />
        );
      })}
    </>
  );
};

export const ExpandableParentTrackRow = ({
  parentRow,
  tableRowProps,
  sortType,
}: {
  parentRow: EntityRowConfig;
  tableRowProps: Omit<BaseTableRowProps, "row">;
  sortType?: SORT_TYPE;
}) => {
  const parentTrack = parentRow.entity as Track;

  return (
    <_ExpandableParentTrackRow
      track={parentTrack}
      parentRow={parentRow}
      tableRowProps={tableRowProps}
      sortType={sortType}
    />
  );
};
