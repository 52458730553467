import React from "react";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { ReactComponent as CaretLeftSVG } from "App/common/icons/caret-left.svg";
import { ReactComponent as CaretRightSVG } from "App/common/icons/caret-right.svg";
import { useSidebarContext } from ".";

export const ToggleGlobalSidebar = () => {
  const { openSidebar, closeSidebar, isSidebarOpen } = useSidebarContext();

  return (
    <Button
      className="toggle-global-sidebar"
      data-cypress-id="toggle-global-sidebar"
      size={BUTTON_SIZE.XSMALL}
      theme={BUTTON_THEME.ICON}
      onClick={() => {
        isSidebarOpen ? closeSidebar(true) : openSidebar();
      }}
    >
      {isSidebarOpen ? <CaretLeftSVG /> : <CaretRightSVG />}
    </Button>
  );
};
