import React, { createContext, useContext, useState } from "react";

import { FileEntity } from "@highnote/server/src/core/entities";
import { useSelectedVersions } from "../hooks/useSelectedVersions";
import { TrackVersionRowConfig } from "../hooks/useVersionRows";

type TrackVersionSelectionContext = ReturnType<typeof useSelectedVersions> & {
  singleSelectedVersion: TrackVersionRowConfig | null;
  onSingleSelectRow: (row: TrackVersionRowConfig) => void;
};

const TrackVersionSelectionContext =
  createContext<TrackVersionSelectionContext>(null);

type TrackVersionSelectionProviderProps = {
  children: React.ReactNode;
};

export const TrackVersionSelectionProvider = ({
  children,
}: TrackVersionSelectionProviderProps) => {
  const selectedVersions = useSelectedVersions();
  const [singleSelectedVersion, setSingleSelectedVersion] =
    useState<TrackVersionRowConfig | null>(null);

  const onSingleSelectRow = (row: TrackVersionRowConfig) => {
    const entityId = (row.entity as FileEntity).id;
    const isAlreadySelected =
      (singleSelectedVersion?.entity as FileEntity)?.id === entityId;

    if (isAlreadySelected) {
      setSingleSelectedVersion(null);
      return;
    }

    setSingleSelectedVersion(row);
  };

  return (
    <TrackVersionSelectionContext.Provider
      value={{ singleSelectedVersion, onSingleSelectRow, ...selectedVersions }}
    >
      {children}
    </TrackVersionSelectionContext.Provider>
  );
};

export const useTrackVersionSelectionContext = () => {
  const context = useContext(TrackVersionSelectionContext);
  if (!context) {
    throw new Error(
      "useTrackVersionSelection must be used within TrackVersionSelectionProvider",
    );
  }
  return context;
};
