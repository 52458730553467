// TODO: Update other places that use this path to use the new constant
export const DEFAULT_TRACK_ARTWORK_PATH = "/public/default-track-artwork.png";
export const BETA_APP_URL = "https://www.highnote.fm/app/install-beta";

const constants = {
  longMaxLength: 500,
  mediumMaxLength: 120,
  shortMaxLength: 50,
  cache: {
    volumeLevelKey: "highnote-volume-level",
    volumeMutedKey: "highnote-volume-muted",
  },
};

export default constants;
