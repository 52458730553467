import "./ShareDialog.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DialogProps } from "@mui/material";
import {
  COLLECTION_ID,
  DELETE_FIRESTORE_FIELD,
  PASSWORD_TYPE,
  ROLE,
  Roles,
  Space,
  SUBSCRIPTION_TIER,
} from "@highnote/server/src/core/entities";
import { Dialog } from "App/common/Dialog";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { TabbedView } from "App/core/TabbedView";
import {
  ShareDialogContext,
  useShareDialogContext,
} from "./ShareDialogContext";
import { ShareDialogMembers } from "./Members";
import { useToast } from "App/common/useToast";
import { highnote } from "@highnote/server/src/sdk";
import {
  getFullRoles,
  hasRole,
  isInviteId,
  getEntityShareKeys,
  MAX_ACTIVE_SPACES_ERROR,
  PERMISSION,
} from "@highnote/server/src/core/shared-util";
import { useAuth } from "../Auth";
import { useConfirmation } from "App/common/useConfirmation";
import {
  LIMIT_TYPE,
  usePlanLimitsContext,
} from "App/common/PlanLimits/usePlanLimits";
import { ShareDialogLink } from "./Link";
import { useGlobalSpaces } from "App/store/spaces/useGlobalSpaces";
import { useChildEntityWatchers } from "App/store/helpers/useChildEntityWatchers";
import { useSpaceContext } from "App/common/useSpace";
import {
  ValidMobileWebAppComponents,
  useMobileAppParams,
} from "App/routes/Main/useMobileAppParams";

export enum SHARE_DIALOG_TAB {
  LINK = "link",
  MEMBERS = "members",
  PUBLIC = "public-page",
}

const ShareDialog = ({ open, onClose, className, ...rest }: DialogProps) => {
  const { showShareDialog, validMobileWebAppComponent } = useMobileAppParams();
  const { entity } = useShareDialogContext();
  const [activeTabId, setActiveTabId] = useState<SHARE_DIALOG_TAB>(() => {
    if (showShareDialog) {
      return validMobileWebAppComponent ===
        ValidMobileWebAppComponents.ShareDialogLink
        ? SHARE_DIALOG_TAB.LINK
        : SHARE_DIALOG_TAB.MEMBERS;
    }

    return SHARE_DIALOG_TAB.LINK;
  });

  const tabs = useMemo(() => {
    return [
      {
        id: SHARE_DIALOG_TAB.LINK,
        name: "Link",
        view: <ShareDialogLink />,
        onClick: () => setActiveTabId(SHARE_DIALOG_TAB.LINK),
      },
      {
        id: SHARE_DIALOG_TAB.MEMBERS,
        name: "Members",
        view: <ShareDialogMembers />,
        onClick: () => setActiveTabId(SHARE_DIALOG_TAB.MEMBERS),
      },
      // We removed the "Public Page" option in this PR: https://github.com/highnotefm/highnote/pull/835
    ];
  }, [entity]);

  return (
    <ErrorBoundary name="ShareDialog">
      <Dialog
        open={open}
        className={`ShareDialog ${className || ""}`}
        title={`Share ${entity?.name || ""}`}
        onClose={onClose}
        {...rest}
      >
        <TabbedView
          activeTabId={activeTabId || SHARE_DIALOG_TAB.MEMBERS}
          tabs={tabs}
        />
      </Dialog>
    </ErrorBoundary>
  );
};

const ContextualShareDialog = ({
  open,
  onClose,
  space,
}: DialogProps & { space: Space }) => {
  const { user, isAllowed } = useAuth();
  const { toasted, addErrorMessage } = useToast();

  const { globalSpaces } = useGlobalSpaces();
  const { manageGlobalChildEntityWatchers } = useChildEntityWatchers();
  const childSpaces = useMemo(
    () => globalSpaces.childSpaces.get(space?.id),
    [globalSpaces.childSpaces.get(space?.id), space?.id],
  );
  const { confirm, renderConfirmation } = useConfirmation();
  const { showPlanLimitsDialog } = usePlanLimitsContext();

  useEffect(() => {
    if (space?.id) {
      manageGlobalChildEntityWatchers({
        collectionIdsToWatch: [COLLECTION_ID.SPACE, COLLECTION_ID.TRACK],
        spaceId: space.id,
        componentId: "share-dialog",
      }).attach();
    }

    return () => {
      // Avoid closing watcher if it was started elsewhere (ie. existingSpaceWatcher)
      if (space?.id) {
        manageGlobalChildEntityWatchers({
          collectionIdsToWatch: [COLLECTION_ID.SPACE, COLLECTION_ID.TRACK],
          spaceId: space.id,
          componentId: "share-dialog",
        }).detach();
      }
    };
  }, [space?.id]);

  const updateEntityPassword = async ({
    passwordType,
    password,
    enabled,
  }: {
    passwordType: PASSWORD_TYPE;
    password?: string;
    enabled?: boolean;
  }) => {
    await highnote.updateSpace({
      id: space.id,
      data: {
        ...(typeof enabled === "boolean" && {
          [passwordType === PASSWORD_TYPE.Share
            ? "sharePasswordEnabled"
            : "publicPasswordEnabled"]: enabled,
        }),
        ...(typeof password === "string" && {
          [passwordType === PASSWORD_TYPE.Share
            ? "sharePassword"
            : "publicPassword"]: password,
        }),
      },
    });
  };

  const updateEntityRoles = async (
    roles: Roles,
    shouldThrowError?: boolean,
  ) => {
    let hasInvites = false;
    const oldRoles = getFullRoles(space);
    const newRolesAsUpdateData: Roles = {};

    Object.entries(roles).forEach(([userId, userRoles]) => {
      if (isInviteId(userId) && !!userRoles && !oldRoles[userId]) {
        hasInvites = true;
      }

      newRolesAsUpdateData[`rolesV2,${userId}`] =
        userRoles || (DELETE_FIRESTORE_FIELD as unknown as ROLE[]);
    });

    const newEntity = {
      ...space,
      rolesV2: { ...(space.rolesV2 || {}), ...roles },
    };

    if (
      hasRole(user?.id, ROLE.MANAGE, space) &&
      !hasRole(user?.id, ROLE.MANAGE, newEntity)
    ) {
      const canView = hasRole(user?.id, ROLE.VIEW, newEntity);
      await confirm({
        title: "Remove Access",
        body: `By proceeding with this change, you will no longer have${
          canView ? " management" : ""
        } access to this space. Are you sure you want to continue?`,
      });
    }

    try {
      await toasted({
        promise: highnote.updateSpace({
          id: space.id,
          data: newRolesAsUpdateData,
        }),
        ...(hasInvites
          ? {
              successMessage: "Invites sent!",
            }
          : {}),
      });
    } catch (e) {
      if (e.message === MAX_ACTIVE_SPACES_ERROR) {
        showPlanLimitsDialog(LIMIT_TYPE.SPACES);
        if (shouldThrowError) {
          throw e;
        }
        return;
      }

      addErrorMessage(
        `Could not ${
          hasInvites ? "invite users" : "update permissions"
        }. Please try again.`,
      );
      if (shouldThrowError) {
        throw e;
      }
    }
  };

  const canManageEntity = isAllowed(PERMISSION.TO_MANAGE_SPACE, {
    space,
  });
  const canManageDownloadControl = isAllowed(PERMISSION.TO_DISABLE_DOWNLOAD, {
    space,
  });

  // password protection can be enabled only for spaces, nothing else,
  // and the space owner has to be on a paid plan
  const entityShareKeys = space ? getEntityShareKeys(space) : [];
  const canManagePassword =
    entityShareKeys.length > 0 &&
    space?.subscriptionTier &&
    space.subscriptionTier !== SUBSCRIPTION_TIER.FREE;

  const value = useMemo(
    () => ({
      updateEntityRoles,
      updateEntityPassword,
      entityHasChildren: childSpaces?.length > 0,
      entity: space,
      isEntityOwner: user && space && user.id === space.createdBy,
      defaultFeatureAlertMessage: `Contact the space owner.`,
      canManageEntity,
      canManageDownloadControl,
      canManagePassword,
    }),
    [
      user,
      updateEntityRoles,
      updateEntityPassword,
      childSpaces,
      space,
      canManageEntity,
      canManagePassword,
      canManageDownloadControl,
    ],
  );

  return (
    <ShareDialogContext.Provider value={value}>
      <ShareDialog open={open} onClose={onClose} />
      {renderConfirmation}
    </ShareDialogContext.Provider>
  );
};

export const useShareDialog = () => {
  const { showShareDialog, redirectToMobileApp } = useMobileAppParams();
  const { globalSpacesWatchers, getGlobalSpace } = useGlobalSpaces();
  const { getChildSpaces } = useChildEntityWatchers();
  const { space, spaceId } = useSpaceContext();

  const [entity, setEntity] = useState<Space>();

  // this assumes that a given space for the share dialog will always be available
  // in the global state.
  const shareEntity = useMemo(() => {
    if (!entity) {
      return null;
    }
    if (spaceId) {
      if (entity.id && spaceId === entity.id) {
        return space;
      }
      const childSpaces = getChildSpaces(spaceId);
      const targetChildSpace = childSpaces.find(
        (childSpace) => childSpace.id === entity.id,
      );
      if (targetChildSpace) {
        return targetChildSpace;
      }
    }

    return getGlobalSpace(entity.id) || getGlobalSpace(entity.spaceId);
  }, [entity, space, spaceId, getChildSpaces, getGlobalSpace]);

  const openShareDialog = useCallback(
    (_entity: Space) => {
      setEntity(_entity);
    },
    [globalSpacesWatchers],
  );

  const closeShareDialog = useCallback(() => {
    if (showShareDialog) {
      // redirect to the native deep link via the browser
      redirectToMobileApp();
      return;
    }

    setEntity(undefined);
  }, [showShareDialog]);

  const renderShareDialog = useMemo(() => {
    const baseComponent = (
      <ContextualShareDialog
        open={Boolean(entity && shareEntity)}
        onClose={closeShareDialog}
        space={shareEntity}
      />
    );
    return baseComponent;
  }, [entity, shareEntity, closeShareDialog]);

  return {
    openShareDialog,
    renderShareDialog: <>{renderShareDialog}</>,
  };
};
